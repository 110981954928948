import {useNavigate} from "react-router-dom";

import cryptoLogo from "assets/crypto/crypto-logo.png";

interface Props {
    open: boolean;
    onClose?: () => void;
}

const Crypto = ({open, onClose}: Props) => {
    const navigate = useNavigate();
    return (
        <div className={`${open ? "px-2" : ""} mb-5 sm:mt-0 mt-2`} onClick={onClose}>
            <div
                onClick={() => navigate("/crypto")}
                className={`flex items-center bg-[#192C38] rounded cursor-pointer ${open ? "h-12 pl-4 pr-2 justify-between" : "h-11 justify-center"}`}>
                {open ? (
                    <>
                        <div className="flex items-center">
                            <img src={cryptoLogo} alt="" className="w-6 h-6" />
                            <div className="pl-2 text-white text-base font-normal">Crypto Stake</div>
                        </div>
                        <div
                            className="h-8 px-2 text-[#5EFB65] text-xs font-bold rounded-[0.625rem] flex flex-row sm:flex-col items-center justify-center"
                            style={{background: "rgba(94, 251, 101, 0.20)"}}>
                            <div>up tp</div>
                            <div className="sm:hidden">&nbsp;</div>
                            <div>120%</div>
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <img src={cryptoLogo} alt="" className="w-6 h-6" />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Crypto;
