import React, {useState, useMemo, useEffect, useCallback} from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import {useAppSelector} from "store";

export enum Side {
    top = "top",
    bottom = "bottom",
    left = "left",
    right = "right",
}
export enum Align {
    start = "start",
    end = "end",
    center = "center",
}

interface Props {
    children: React.ReactElement;
    content: React.ReactElement;
    side?: Side;
    align?: Align;
    alignOffset?: number;
    rootEl?: HTMLElement | null;
}
const UITooltip = (props: Props) => {
    const isMobile = useAppSelector((state) => state.config.isMobile);
    const [open, setOpen] = useState(false);
    const {rootEl = document.body, children, content, side = "bottom", align = "center", alignOffset = 0} = props;
    const Son = useMemo(() => {
        const AdditionalEventChildren = {
            ...children,
            props: {
                ...children.props,
                onClick: (e: any) => {
                    setOpen(true);
                    e.stopPropagation();
                    e.preventDefault();
                },
            },
        };
        return isMobile ? AdditionalEventChildren : children;
    }, [isMobile, children]);
    const hide = useCallback(() => {
        setOpen(false);
    }, []);
    useEffect(() => {
        if (!rootEl) return;
        rootEl.addEventListener("click", hide, false);
        return () => {
            rootEl.removeEventListener("click", hide, false);
        };
    }, [rootEl, hide]);
    return (
        <Tooltip.Provider delayDuration={100}>
            <div className="tooltip-text-wrap">
                <Tooltip.Root open={open} onOpenChange={(open) => setOpen(open)}>
                    <Tooltip.Trigger asChild>{Son}</Tooltip.Trigger>
                    <Tooltip.Content className="TooltipContent" sideOffset={3} side={side} align={align} alignOffset={alignOffset}>
                        <div className="tool-tip-wrap">{content}</div>
                        <Tooltip.Arrow className="TooltipArrow" />
                    </Tooltip.Content>
                </Tooltip.Root>
            </div>
        </Tooltip.Provider>
    );
};

export default UITooltip;
