import {useCallback, useEffect, useState} from "react";

const useVisibility = () => {
    const [documentVisible, setVisible] = useState(!document.hidden);

    const setVisibleFn = useCallback(() => {
        setVisible(!document.hidden);
    }, []);

    useEffect(() => {
        if (document.hidden !== undefined) {
            document.addEventListener("visibilitychange", setVisibleFn);
        }
        return () => {
            if (document.hidden !== undefined) {
                document.removeEventListener("visibilitychange", setVisibleFn);
            }
        };
    }, [setVisibleFn]);

    return {documentVisible};
};

export default useVisibility;
