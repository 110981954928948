import {useEffect} from "react";
import {useMount, useSearchParam} from "react-use";
import {useAppDispatch} from "store";
import {setInviteCode, setPromoCode} from "store/slices/config";

const useInitApp = () => {
    const promoCode = useSearchParam("promoCode");
    const inviteCode = useSearchParam("inviteCode");
    const dispatch = useAppDispatch();

    useMount(() => {
        if (!window.LiveChatWidget) return;
        window.LiveChatWidget.on("ready", () => {
            window.LiveChatWidget.call("hide");
        });
        window.LiveChatWidget.on("visibility_changed", (data: {visibility: string}) => {
            switch (data.visibility) {
                case "minimized":
                case "hidden":
                    window.LiveChatWidget.call("hide");
            }
        });
        window.LiveChatWidget.init();
    });

    useEffect(() => {
        if (promoCode) {
            dispatch(setPromoCode(promoCode));
        }
        if (inviteCode) {
            dispatch(setInviteCode(inviteCode));
        }
    }, [dispatch, inviteCode, promoCode]);

    return {};
};

export default useInitApp;
