import {PayloadAction, createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import apiVipCheck from "api/vipcheck";

// types
import {ConfigProps, PlatformConfig} from "types/config";
import {MoneyType} from "types/index";

const initialState: ConfigProps = {
    platformConfig: undefined,
    locale: localStorage.getItem("locale") ?? "en",
    odds: "decimal",
    isMobile: false,
    cashBackData: 0,
    menuOpen: true,
    walletCurrency: MoneyType.BTC,
    inviteCode: "",
    promoCode: "",
    walletType: "",
    maintainTime: 0,
};

export const refreshCashBackData = createAsyncThunk("refreshCashBackData", async () => {
    const {status, data} = await apiVipCheck.getVipCashbackData();
    if (status === 200 && data.code === 0) {
        return data.data;
    }
});

// ==============================|| SLICE - SNACKBAR ||============================== //

const config = createSlice({
    name: "config",
    initialState,
    reducers: {
        setPlatformConfig(state, action: PayloadAction<PlatformConfig>) {
            state.platformConfig = action.payload;
        },
        setLocale(state, action: PayloadAction<string>) {
            state.locale = action.payload;
            localStorage.setItem("locale", action.payload);
        },
        setOdds(state, action: PayloadAction<string>) {
            state.odds = action.payload;
        },
        setIsMobile(state, action: PayloadAction<boolean>) {
            state.isMobile = action.payload;
        },
        setMenuOpen(state, action: PayloadAction<boolean>) {
            state.menuOpen = action.payload;
        },
        setWalletCurrency: (state, action: PayloadAction<number>) => {
            state.walletCurrency = action.payload;
        },
        setInviteCode: (state, action: PayloadAction<string>) => {
            state.inviteCode = action.payload;
        },
        setPromoCode: (state, action: PayloadAction<string>) => {
            state.promoCode = action.payload;
        },
        setWalletType: (state, action: PayloadAction<string>) => {
            state.walletType = action.payload;
        },
        setMaintainTime: (state, action: PayloadAction<number>) => {
            state.maintainTime = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(refreshCashBackData.fulfilled, (state, action: PayloadAction<number>) => {
            state.cashBackData = action.payload;
        });
    },
});

export default config.reducer;

export const {setMaintainTime, setPlatformConfig, setLocale, setOdds, setIsMobile, setMenuOpen, setWalletCurrency, setInviteCode, setPromoCode, setWalletType} =
    config.actions;
