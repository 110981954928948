import {GuardProps, MessageType} from "types";
import {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "store";
import Loading from "ui-component/Loading";
import apiUser from "api/user";
import {Coin} from "types/user";
import {setBalance} from "store/slices/user";
import client from "api/client";
import useWebSocket from "react-use-websocket";
import apiAuth from "api/auth";
import {setPlatformConfig} from "store/slices/config";
import {load} from "protobufjs";
const protoFile = require("../utils/message.proto");

import {base64Encode, uint8ArrayToString} from "utils/help";
import useMessage from "hooks/useMessage";
import {showDialog} from "store/slices/dialog";
import useDocumentVisibility from "hooks/useDocumentVisibility";
import {useSearchParam} from "react-use";

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({children}: GuardProps) => {
    const platformConfig = useAppSelector((state) => state.config.platformConfig);
    const token = useAppSelector((state) => state.user.user?.token);
    const reconnectWebSocket = useAppSelector((state) => state.user.reconnectWebSocket);
    const dispatch = useAppDispatch();
    const {documentVisible} = useDocumentVisibility();

    const [socketUrl, setSocketUrl] = useState<string | null>(null);
    const heartbeatTimer = useRef<NodeJS.Timer>();
    const {handleReceiveMsg} = useMessage();

    const humpInviteCode = useSearchParam("inviteCode");
    const lowerInviteCode = useSearchParam("invitecode");
    const inviteCode = humpInviteCode || lowerInviteCode;
    const promoCode = useSearchParam("promoCode");

    useEffect(() => {
        if (documentVisible) {
            apiAuth.getWebSocketUrl();
        }
    }, [documentVisible]);

    useEffect(() => {
        const requestBalance = async () => {
            const {status, data} = await apiUser.getBalance();
            if (status === 200 && data.code === 0) {
                try {
                    const result = JSON.parse(data.data);
                    const list: Coin[] = result?.data || [];
                    dispatch(setBalance(list));
                } catch (error) {
                    console.log(error);
                }
            }
        };
        if (token) {
            client.setToken(token);
            requestBalance();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        const loadConfig = async () => {
            const {status, data} = await apiAuth.requestPlatformConfig({
                inviteCode: inviteCode || "",
                promoCode: promoCode || "",
            });
            if (status === 200 && data.code === 0) {
                const result = data.data;
                setSocketUrl(result.webSocketUrl);
                dispatch(setPlatformConfig(result));
            }
        };
        loadConfig();
    }, []);

    // ==============================|| WebSocket ||============================== //

    const {sendMessage, lastMessage, readyState} = useWebSocket(socketUrl, {
        share: true,
        shouldReconnect: () => {
            return reconnectWebSocket;
        },
    });

    useEffect(() => {
        if (reconnectWebSocket === false) {
            dispatch(showDialog("reconnectSocket"));
        }
    }, [dispatch, reconnectWebSocket]);

    useEffect(() => {
        handleReceiveMsg(lastMessage?.data);
    }, [lastMessage]);

    const send = (msgType: number, data: object) => {
        if (msgType === MessageType.PLAYER_LOGIN_REQ) {
            load(protoFile, function (err, root) {
                if (!err && root) {
                    // get message Type
                    const AwesomeMessage = root.lookupType("base.PlayerLoginReq");
                    const message = AwesomeMessage.create(data);
                    // Converting message to Uint8Array (browser) or Buffer (node)
                    const buffer = AwesomeMessage.encode(message).finish();
                    const data_str = uint8ArrayToString(buffer);
                    const base64_str = base64Encode(data_str);
                    const params = {
                        msgtype: msgType,
                        msg: base64_str,
                    };
                    sendMessage(JSON.stringify(params));
                }
            });
        } else if (msgType === MessageType.PING) {
            const params = {
                msgtype: msgType,
                msg: "",
            };
            sendMessage(JSON.stringify(params));
        }
    };

    useEffect(() => {
        const loginWebsocket = async () => {
            heartbeatTimer.current && clearInterval(heartbeatTimer.current);
            let userToken = token;
            if (!token) {
                const {data} = await apiAuth.ghostLogin();
                if (data.code === 0) {
                    userToken = data.data.token;
                }
            }
            send(MessageType.PLAYER_LOGIN_REQ, {token: userToken});
            heartbeatTimer.current = setInterval(() => {
                send(MessageType.PING, {});
            }, 1000);
        };
        if (readyState === 1) {
            loginWebsocket();
        }
        return () => {
            heartbeatTimer.current && clearInterval(heartbeatTimer.current);
        };
    }, [readyState, token]);

    if (!platformConfig) {
        return <Loading />;
    }

    return children;
};

export default AuthGuard;
