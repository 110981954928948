enum logLvl {
    heartbeat = -1,
    log = 0,
    info = 1,
    debug = 2,
    assert = 3,
    warn = 4,
    error = 5,
    clear = 6,
}

export default class SampleLog {
    public static HeartBeat(...data: any[]) {
        let lvl = parseInt(process.env.REACT_APP_gameLog);
        if (lvl > logLvl.heartbeat) {
            return;
        }
        let timeStamp = new Date().getTime();
        console.log(`${(new Date()).toLocaleString()}  ${timeStamp}`+'[##HEARTBEAT##]'+data)
    }

    public static Log(...data: any[]) {
        let lvl = parseInt(process.env.REACT_APP_gameLog);
        if (lvl > logLvl.log) {
            return;
        }
        let timeStamp = new Date().getTime();
        console.log(`${(new Date()).toLocaleString()}  ${timeStamp}`+'[##LOG##]'+data)
    }

    public static Info(...data: any[]) {
        let lvl = parseInt(process.env.REACT_APP_gameLog);
        if (lvl > logLvl.info) {
            return;
        }
        let timeStamp = new Date().getTime();
        console.log("%c " +`${(new Date()).toLocaleString()}  ${timeStamp}`+'[##Info##]' + data ,  "color:green");
    }

    public static Debug(...data: any[]) {
        let lvl = parseInt(process.env.REACT_APP_gameLog);
        if (lvl > logLvl.debug) {
            return;
        }
        let timeStamp = new Date().getTime();
        console.log("%c " + `${(new Date()).toLocaleString()}  ${timeStamp}`+'[##Debug##]' + data ,  "color:bule");
    }

    public static Assert(condition: boolean, ...data: any[]) {
        let lvl = parseInt(process.env.REACT_APP_gameLog);
        if (lvl > logLvl.assert) {
            return;
        }
        let timeStamp = new Date().getTime();
        console.assert(condition, `${new Date().toLocaleString()}  ${timeStamp}` + "[##Assert##]" + data);
    }

    public static Warn(...data: any[]) {
        let lvl = parseInt(process.env.REACT_APP_gameLog);
        if (lvl > logLvl.warn) {
            return;
        }
        let timeStamp = new Date().getTime();
        console.log("%c " + `${(new Date()).toLocaleString()}  ${timeStamp}`+'[##Warn##]' + data ,  "color:red;text-decoration: underline;");
    }

    public static Error(...data: any[]) {
        let lvl = parseInt(process.env.REACT_APP_gameLog);
        if (lvl > logLvl.error) {
            return;
        }
        let timeStamp = new Date().getTime();
        console.log("%c " + `${(new Date()).toLocaleString()}  ${timeStamp}`+'[##Error##]'+ data ,  "color:red;font-weight: bold;");
    }
}
