import {useState, useEffect} from "react";
import * as Switch from "@radix-ui/react-switch";
import * as RadioGroup from "@radix-ui/react-radio-group";
import AnimateButton from "ui-component/AnimateButton";
import useCurrencyList from "hooks/useCurrency";
import SvgIcon from "ui-component/SvgIcon";
import {useAppSelector, dispatch} from "store";
import {showDialog, closeDialog} from "store/slices/dialog";
import {setUserMainToken, setUserHiddenBalanceInZero, setUserIsLegalCurrency} from "store/slices/user";
import {useIntl} from "react-intl";
import {getCurrencyIcon} from "utils/help";

const WalletSettings = () => {
    const {$t} = useIntl();
    const isMobile = useAppSelector((state) => state.config.isMobile);
    const userHiddenBalanceInZero = useAppSelector((state) => state.user.userHiddenBalanceInZero);
    const userIsLegalCurrency = useAppSelector((state) => state.user.userIsLegalCurrency);
    const userMainToken = useAppSelector((state) => state.user.userMainToken);
    const {mainTokenOptions} = useCurrencyList();
    const [isHiddenBalanceInZero, setIsHiddenBalanceInZero] = useState(userHiddenBalanceInZero);
    const [isLegalCurrency, setIsLegalCurrency] = useState(userIsLegalCurrency);
    const [legalCurrency, setLegalCurrency] = useState(userMainToken || mainTokenOptions[0].tokenId);

    const handleHiddenBalanceInSwitchChange = () => {
        setIsHiddenBalanceInZero(!isHiddenBalanceInZero);
    };
    const handleLegalCurrencySwitchChange = () => {
        setIsLegalCurrency(!isLegalCurrency);
    };
    const handleWalletSettingsSave = () => {
        dispatch(setUserHiddenBalanceInZero(isHiddenBalanceInZero));
        dispatch(setUserIsLegalCurrency(isLegalCurrency));
        if (isLegalCurrency) {
            dispatch(setUserMainToken(legalCurrency));
        } else {
            dispatch(setUserMainToken(0));
        }
    };
    return (
        <div className="bg-[#1a2c38] text-[#b1bad3] rounded-lg relative mx-4">
            <div
                className="p-4 flex items-center justify-between cursor-pointer hover:fill-white"
                onClick={() => {
                    dispatch(showDialog(null));
                }}>
                <div className="flex justify-start items-center">
                    <svg fill="currentColor" viewBox="0 0 64 64" className="svg-icon" style={{width: 16, height: 16, marginRight: 8}}>
                        <title></title>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M31.2 9.013c.56.854 1.013 1.76 1.333 2.72l4.64 1.147v6.053l-4.613 1.174a12.429 12.429 0 0 1-2.4 4.106l1.333 4.587-5.253 3.013-3.307-3.44c-.773.16-1.573.24-2.373.24-.8 0-1.6-.08-2.373-.24l-3.334 3.44L9.627 28.8l1.306-4.587a12.3 12.3 0 0 1-2.346-4.106L3.92 18.933V12.88l4.667-1.147a9.913 9.913 0 0 1 1.333-2.72c.293-.48.64-.96 1.013-1.386L9.627 3.013 14.853 0l3.334 3.44c.773-.16 1.573-.24 2.373-.24.8 0 1.6.08 2.373.24L26.24 0l5.253 3.013L30.16 7.6a9.19 9.19 0 0 1 1.04 1.413ZM15.627 15.92a4.94 4.94 0 0 0 4.933 4.933 4.94 4.94 0 0 0 4.933-4.933 4.94 4.94 0 0 0-4.933-4.933 4.94 4.94 0 0 0-4.933 4.933Zm23.44 17.866V32.24c0-4.24 3.44-7.68 7.68-7.68H64V12.64c0-2-1.627-3.627-3.627-3.627H38.16c1.76.427 3.013 2.027 3.013 3.867v6.053c0 1.84-1.253 3.44-3.04 3.867l-2.773.72c-.24.48-.533.96-.827 1.413l.8 2.773c.507 1.76-.266 3.627-1.84 4.56L28.24 35.28c-.613.373-1.307.533-2 .533-1.04 0-2.107-.4-2.88-1.2l-1.947-2.026c-.586.026-1.146.026-1.733 0l-1.947 2.026a3.998 3.998 0 0 1-4.88.667l-5.226-3.014a3.986 3.986 0 0 1-1.84-4.56l.773-2.773C6.267 24.48 6 24 5.733 23.52L2.96 22.8A3.99 3.99 0 0 1 0 19.706v33.68c0 2 1.627 3.627 3.627 3.627h56.746c2 0 3.627-1.627 3.627-3.627v-11.92H46.747c-4.24 0-7.68-3.44-7.68-7.68Zm7.68-5.226a3.687 3.687 0 0 0-3.68 3.68v1.547a3.687 3.687 0 0 0 3.68 3.68H64V28.56H46.746Z"></path>
                    </svg>
                    <span className="text-white font-bold">{$t({id: "WalletSettings"})}</span>
                </div>
                <AnimateButton>
                    <SvgIcon dataKey="icon-close" className="hover:fill-white fill-[#b1bad3]" style={{width: 16, height: 16}} />
                </AnimateButton>
            </div>
            <div className="px-4 pb-4 grid grid-flow-row gap-4">
                <div>
                    <div className="flex items-center px-4 py-2">
                        <Switch.Root
                            checked={isHiddenBalanceInZero}
                            onCheckedChange={handleHiddenBalanceInSwitchChange}
                            className="w-[35px] h-[21px] bg-gray-400 outline-none rounded-full relative focus:outline-offset-[0] focus:outline-gray-200  data-[state=checked]:bg-green-600 data-[state=checked]:border-green-600 cursor-pointer"
                            id="hidden-balance-in">
                            <Switch.Thumb className="block w-[17px] h-[17px] bg-white rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
                        </Switch.Root>
                        <label className="text-white text-[14px] ml-[0.75rem]" htmlFor="hidden-balance-in">
                            <div>{$t({id: "HideZeroBalances"})}</div>
                            <div className="text-gray-200 font-normal">{$t({id: "HideZeroBalancesDesc"})}</div>
                        </label>
                    </div>
                    <div className={`flex items-center px-4 py-2 ${mainTokenOptions && mainTokenOptions.length ? "block" : "hidden"}`}>
                        <Switch.Root
                            checked={isLegalCurrency}
                            onCheckedChange={handleLegalCurrencySwitchChange}
                            className="w-[35px] h-[21px] bg-gray-400 outline-none rounded-full relative focus:outline-offset-[0] focus:outline-gray-200  data-[state=checked]:bg-green-600 data-[state=checked]:border-green-600 cursor-pointer"
                            id="legal-currency">
                            <Switch.Thumb className="block w-[17px] h-[17px] bg-white rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
                        </Switch.Root>
                        <label className="text-white text-[14px] ml-[0.75rem]" htmlFor="legal-currency">
                            <div>{$t({id: "DisplayCryptoInFiat"})}</div>
                            <div className="text-gray-200 font-normal">{$t({id: "DisplayCryptoInFiatDesc"})}</div>
                        </label>
                    </div>
                </div>
                <div className={`p-4 ${mainTokenOptions && mainTokenOptions.length ? "block" : "hidden"}`}>
                    <RadioGroup.Root
                        defaultValue={String(legalCurrency)}
                        className={`grid grid-flow-row ${isMobile ? "grid-cols-3" : "grid-cols-4"} gap-x-9 gap-y-4`}>
                        {mainTokenOptions &&
                            mainTokenOptions.length > 0 &&
                            mainTokenOptions.map((item) => {
                                return (
                                    <div className={`flex justify-start items-center ${!isLegalCurrency ? "opacity-50" : ""}`} key={item.tokenId}>
                                        <RadioGroup.Item
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setLegalCurrency(item.tokenId);
                                            }}
                                            className={`bg-gray-600 w-[21px] h-[21px] rounded-full border-[0.125rem] border-gray-400 hover:border-gray-300 focus:border-gray-300 focus:bg-gray-400 ${
                                                isLegalCurrency ? "cursor-pointer" : "cursor-not-allowed"
                                            }`}
                                            value={String(item.tokenId)}
                                            disabled={!isLegalCurrency}
                                            id={String(item.tokenId)}>
                                            <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[11px] after:h-[11px] after:rounded-[50%] after:bg-white" />
                                        </RadioGroup.Item>
                                        <label
                                            className="text-gray-200 text-[0.875rem] leading-none ml-[8px] cursor-pointer flex flex-row justify-start items-center gap-2"
                                            htmlFor={String(item.tokenId)}>
                                            <div>{item.tokenName}</div>
                                            <img src={getCurrencyIcon(item.tokenId)} className="w-[0.875rem] h-[0.875rem] ml-1 flex-shrink-0" alt="" />
                                        </label>
                                    </div>
                                );
                            })}
                    </RadioGroup.Root>
                </div>
                <hr className="bg-gray-400 h-[0.125rem] border-t-0" />
                <div className="flex justify-between">
                    <div className="text-gray-200 font-normal text-[0.875rem]">{$t({id: "WalletSettingsTips"})}</div>
                    <AnimateButton>
                        <div
                            className="bg-green-500 hover:bg-green-400 text-black text-[0.875rem] py-[0.8125rem] px-4 text-center leading-none rounded-[0.25rem] cursor-pointer"
                            style={{boxShadow: "0rem .0625rem .1875rem #00000033 , 0rem .0625rem .125rem #0000001f;"}}
                            onClick={() => {
                                handleWalletSettingsSave();
                                dispatch(closeDialog());
                            }}>
                            {$t({id: "WalletSettingsSave"})}
                        </div>
                    </AnimateButton>
                </div>
            </div>
        </div>
    );
};

export default WalletSettings;
