import React, {ReactNode, FC, CSSProperties, useEffect} from "react";
import {createSignal, useReaction} from "utils/reaction";
import {createPortal} from "react-dom";
import {motion, LayoutGroup, AnimatePresence} from "framer-motion";
import SvgIcon from "./SvgIcon";
import {useAppSelector} from "store";
import AnimateButton from "ui-component/AnimateButton";

interface ModalProps {
    title?: string | ReactNode;
    extra?: string | ReactNode;
    content?: ReactNode;
    icon?: ReactNode | null;
    onCancel?: () => void;
    onOk?: (e?: MouseEvent) => Promise<any> | void;
    onBack?: () => void;
    visible?: boolean;
    loading?: boolean;
    mobileFullHeight?: boolean;
    okText?: string;
    cancelText?: string;
    style?: CSSProperties;
    mobileStyle?: CSSProperties;
    titleStyle?: CSSProperties;
    mobileTitleStyle?: CSSProperties;
    closeStyle?: CSSProperties;
    mobileCloseStyle?: CSSProperties;
    closeIconStyle?: CSSProperties;
    mobileAlign?: "start" | "center" | "end";
    align?: "start" | "center" | "end";
    modalId?: string;
    bgStyle?: CSSProperties;
    mobileBgStyle?: CSSProperties;
}

const [modalList, setModalList] = createSignal<(() => ModalProps)[]>([]);

let rafId: number;
const portalNodes: Set<ModalProps> = new Set();

const run = () => {
    const overflow = window.getComputedStyle(document.body).overflow;
    if (overflow === "auto") document.body.style.overflow = "hidden";
    rafId = window.requestAnimationFrame(run);
};

function observePortal(portalNode: ModalProps) {
    return {
        observe() {
            const wasEmpty = portalNodes.size === 0;
            if (!portalNodes.has(portalNode)) portalNodes.add(portalNode);
            if (wasEmpty) run();
        },
        unobserve() {
            portalNodes.delete(portalNode);
            if (!portalNodes.size) {
                cancelAnimationFrame(rafId);
                document.body.style.overflow = "auto";
            }
        },
    };
}

function Portal(props: {
    visible: boolean;
    children?: ReactNode;
    animate: {x?: string; y?: string};
    isFullHeight?: boolean;
    bgStyle?: CSSProperties;
    align?: "start" | "center" | "end";
}) {
    const {align = "center"} = props;
    return createPortal(
        <AnimatePresence>
            {props.visible && (
                <motion.div
                    className={`fixed flex flex-col justify-${align} items-center top-0 left-0 w-full h-full z-[100] overflow-hidden`}
                    style={props.bgStyle}
                    initial={{opacity: 0}}
                    animate={{
                        opacity: 1,
                        transition: {duration: 0.4, ease: [0.36, 0.66, 0.04, 1]},
                    }}
                    exit={{
                        opacity: 0,
                        transition: {duration: 0.3, ease: [0.36, 0.66, 0.04, 1]},
                    }}>
                    <motion.div
                        initial={{y: "50%"}}
                        animate={{
                            y: 0,
                            transition: {duration: 0.4, ease: [0.36, 0.66, 0.04, 1]},
                        }}
                        exit={{
                            y: "50%",
                            transition: {duration: 0.3, ease: [0.36, 0.66, 0.04, 1]},
                        }}
                        className={`flex flex-col justify-center ${props.isFullHeight ? "h-full" : ""}`}>
                        {props.children}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>,
        document.body,
    );
}

function Panel(props: ModalProps) {
    useEffect(() => {
        const {observe, unobserve} = observePortal(props);
        observe();
        return () => unobserve();
    }, []);
    return (
        <LayoutGroup>
            <motion.div className="relative">
                <motion.button
                    layout
                    className="absolute right-0 top-0 z-10"
                    style={{...props.closeStyle, width: "2rem", height: "4rem"}}
                    onClick={() => props.onCancel?.()}>
                    <AnimateButton>
                        <SvgIcon dataKey="icon-cross" className="hover:fill-white fill-[#b1bad3] w-4 h-4" style={{...props.closeIconStyle}} />
                    </AnimateButton>
                </motion.button>
                <AnimatePresence>
                    {props.title && (
                        <motion.div layout className="w-full px-5 box-border flex h-16 items-center bg-gray-600 sm:rounded-t-md" style={{...props.titleStyle}}>
                            {props.onBack && (
                                <motion.button layout className="mr-2 rotate-180 text-xs" onClick={props.onBack}>
                                    <SvgIcon dataKey="icon_Arrow" />
                                </motion.button>
                            )}
                            <motion.div layout className="text-white text-base font-bold">
                                {props.title || ""}
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>
            <motion.div className="relative flex" style={props.style}>
                {props.content}
            </motion.div>
        </LayoutGroup>
    );
}

const ModalPanel: FC<{config: () => ModalProps}> = ({config}) => {
    const isMobile = useAppSelector((state) => state.config.isMobile);
    const props = useReaction(() => config());
    const animate = isMobile ? {x: "50%"} : {y: "-50%"};
    const {visible, mobileFullHeight, mobileStyle, mobileTitleStyle, mobileCloseStyle, mobileBgStyle, mobileAlign, ...rest} = props;
    const style = isMobile ? mobileStyle : rest.style;
    const closeStyle = isMobile ? mobileCloseStyle : rest.closeStyle;
    const titleStyle = isMobile ? mobileTitleStyle : rest.titleStyle;
    const align = isMobile ? mobileAlign : rest.align;
    const bgStyle = isMobile ? mobileBgStyle || rest.bgStyle : rest.bgStyle;
    rest.style = style;
    rest.titleStyle = titleStyle;
    rest.closeStyle = closeStyle;
    return (
        <Portal visible={visible} animate={animate} isFullHeight={isMobile && mobileFullHeight} bgStyle={bgStyle} align={align}>
            <Panel {...rest} />
        </Portal>
    );
};

const Modal: FC = () => {
    const modalData = useReaction(() => modalList());
    return (
        <>
            {modalData.map((item) => (
                <ModalPanel config={item} key={item().modalId} />
            ))}
        </>
    );
};

export function portal(config: ModalProps) {
    let modalConfig: ModalProps = {
        ...config,
        visible: true,
        modalId: Math.floor(Math.random() * 10000).toString(),
        onCancel,
        onOk,
    };

    const [modalSignal, setModalSignal] = createSignal(modalConfig);
    setModalList((m) => [...m, modalSignal]);

    function onOk() {
        let ret;
        const _onOk = config.onOk;
        if (_onOk) {
            ret = _onOk();
        }
        if (ret && ret.then) {
            modalConfig.loading = true;
            setModalSignal(modalConfig);
            ret.then(
                () => {
                    onCancel(true);
                },
                (e: Error) => {
                    console.error(e);
                    modalConfig.loading = false;
                    setModalSignal(modalConfig);
                },
            );
        }
        if (!ret) {
            onCancel(true);
        }
    }

    function destroy() {
        setModalList((m) => m.filter((item) => item !== modalSignal));
    }

    function onCancel(isOnOk?: boolean) {
        !isOnOk && config.onCancel && config.onCancel();
        modalConfig.visible = false;
        setModalSignal(modalConfig);
        destroy();
    }

    function update(newConfig: ModalProps) {
        modalConfig = {
            ...modalConfig,
            title: config.title,
            ...newConfig,
        };
        setModalSignal(modalConfig);
    }

    function close() {
        modalConfig.visible = false;
        setModalSignal(modalConfig);
        destroy();
    }

    return {
        close,
        update,
    };
}

export default Modal;
