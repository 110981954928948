import React, {useState, useRef, useEffect} from "react";
import SvgIcon from "ui-component/SvgIcon";
import {portal} from "ui-component/Modal";
import Menu from "ui-component/Menu";
import AnimateButton from "ui-component/AnimateButton";
import {dispatch, useAppSelector} from "store";
import {openMyBets, useOpenMyBets, setIsOpenMyBets} from "views/lottery/MyBets";
import {setMenuOpen} from "store/slices/config";
import {useLocation, useNavigate} from "react-router-dom";
import TabbarProvider from "./TabbarContext";
import RoulettePanel from "../Sidebar/RoulettePanel";
import Crypto from "../Sidebar/Crypto";
import LeftPanel from "../Sidebar/LeftPanel";

const menuList = [
    {
        title: "Menu",
        icon: "icon-tab-menu",
        id: "browse",
    },
    {
        title: "Casino",
        icon: "icon-tab-casino",
        id: "casino",
    },
    {
        title: "Bets",
        icon: "icon-tab-bets",
        id: "bets",
    },
    // {
    //     title: "Lottery",
    //     icon: "icon-tab-lottery",
    //     id: "lottery",
    // },
];

const SideBar: React.FC<{onClose: () => void}> = ({onClose}) => {
    const location = useLocation();
    const platformConfig = useAppSelector((state) => state.config.platformConfig);
    let menuType;
    if (location.pathname.includes("casino") || location.pathname.includes("game-detail")) menuType = "casino";
    if (location.pathname.includes("sports")) menuType = "sports";
    if (location.pathname.includes("lottery-home")) menuType = "lottery";
    return (
        <TabbarProvider onClose={onClose}>
            <div className="w-full h-full overflow-auto pt-4">
                <div className="h-16 pl-2" onClick={onClose}>
                    <LeftPanel />
                </div>
                {platformConfig?.isPddEnable === 1 && <RoulettePanel open onClose={onClose} />}
                {/*
                <Crypto open onClose={onClose} />
                */}
                <Menu menuType={menuType} open />
            </div>
        </TabbarProvider>
    );
};

const Tabbar: React.FC = () => {
    const [activeMenu, setActiveMenu] = useState([]);
    const menuOpen = useAppSelector((state) => state.config.menuOpen);
    const browsePanel = useRef<any>();
    const navigate = useNavigate();
    const location = useLocation();
    const isOpenMyBets = useOpenMyBets();

    useEffect(() => {
        if (location.pathname.includes("/casino") || location.pathname.includes("game-detail")) setActiveMenu(["casino"]);
        if (location.pathname.includes("/sports")) setActiveMenu(["sports"]);
        if (location.pathname.includes("/lottery-home")) setActiveMenu(["lottery"]);
    }, [location.pathname]);

    useEffect(() => {
        if (!isOpenMyBets && menuOpen) {
            // browsePanel.current = portal({
            //     content: <SideBar onClose={closeBrowse} />,
            //     mobileStyle: {width: "100vw", height: "100%"},
            //     bgStyle: {background: "#0f212e", top: "60px", bottom: "67px", height: "auto"},
            //     mobileFullHeight: true,
            //     mobileCloseStyle: {display: "none"},
            // });
            closeBrowse();
        }
        return () => {
            browsePanel.current?.close();
            browsePanel.current = null;
        };
    }, []);

    const closeBrowse = () => {
        browsePanel.current?.close();
        browsePanel.current = null;
        dispatch(setMenuOpen(false));
        setIsOpenMyBets(false);
    };
    const handleSelect = (menuId: string) => {
        if (menuId === "browse") {
            if (menuOpen) closeBrowse();
            else {
                browsePanel.current = portal({
                    content: <SideBar onClose={closeBrowse} />,
                    mobileStyle: {width: "100vw", height: "100%"},
                    bgStyle: {background: "#0f212e", top: "60px", bottom: "67px", height: "auto"},
                    mobileFullHeight: true,
                    mobileCloseStyle: {display: "none"},
                });
                dispatch(setMenuOpen(true));
            }
        } else {
            closeBrowse();
            if (menuId === "bets") {
                if (isOpenMyBets) {
                    setIsOpenMyBets(false);
                } else {
                    if (location.pathname.includes("/lottery-home")) openMyBets();
                    else openMyBets(1);
                }
            } else {
                setIsOpenMyBets(false);
                if (!activeMenu.includes(menuId)) {
                    setActiveMenu([menuId]);
                    if (menuId === "casino") !location.pathname.includes("/casino") && navigate("/casino/home");
                    if (menuId === "sports") !location.pathname.includes("/sports") && navigate("/sports/home");
                    if (menuId === "lottery") !location.pathname.includes("/lottery-home") && navigate("/lottery-home");
                }
            }
        }
    };
    return (
        <div className="w-full h-[68px] bg-gray-700 fixed z-10 bottom-0 px-4" style={{boxShadow: "0 5px 5px #0a1c2766"}}>
            <div className="grid grid-cols-3 justify-items-center h-full">
                {menuList.map((item) => {
                    const isActive = menuOpen ? item.id === "browse" : isOpenMyBets ? item.id === "bets" : activeMenu.includes(item.id);
                    return (
                        <div className="flex relative w-full justify-center" key={item.id} onClick={() => handleSelect(item.id)}>
                            {isActive && <span className="absolute w-[3.75rem] h-[3.75rem] bg-[#007CEF] top-1 rounded-[0.3125rem]" />}
                            <button className="inline-flex cursor-pointer justify-center relative z-10">
                                <AnimateButton>
                                    <div className="flex flex-col items-center justify-center font-bold gap-1 text-[0.75rem] h-[68px]">
                                        <SvgIcon dataKey={item.icon} className="w-7 h-7" style={{fill: isActive ? "#fff" : "#9DB8C6"}} />
                                        <span className={isActive ? "text-white" : "text-[#9DB8C6]"}>{item.title}</span>
                                    </div>
                                </AnimateButton>
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Tabbar;
