import {motion} from "framer-motion";
import {useLocation} from "react-router-dom";
import {dispatch, useAppSelector} from "store";
import {setMenuOpen} from "store/slices/config";
import AnimateButton from "ui-component/AnimateButton";
import Menu from "ui-component/Menu";
import SvgIcon from "ui-component/SvgIcon";
import LeftPanel from "./LeftPanel";
import RoulettePanel from "./RoulettePanel";
import Crypto from "./Crypto";

const Sidebar = () => {
    const menuOpen = useAppSelector((state) => state.config.menuOpen);
    const platformConfig = useAppSelector((state) => state.config.platformConfig);
    const location = useLocation();
    const toggleSidebar = () => {
        dispatch(setMenuOpen(!menuOpen));
    };

    let menuType;
    if (location.pathname.includes("casino") || location.pathname.includes("game-detail")) menuType = "casino";
    if (location.pathname.includes("sports")) menuType = "sports";
    if (location.pathname.includes("lottery-home")) menuType = "lottery";

    return (
        <motion.div
            animate={{width: menuOpen ? "240px" : "60px"}}
            className="h-screen bg-gray-700 flex flex-col  shrink-0 z-20"
            style={{filter: "drop-shadow(0 0 5px rgba(25,25,25,.25))"}}>
            <div
                className="h-[60px] bg-gray-700 w-full flex items-center"
                style={{boxShadow: "0 10px 15px -3px rgba(0, 0, 0, .2), 0 4px 6px -2px rgba(0, 0, 0, .1)"}}>
                <AnimateButton>
                    <button onClick={toggleSidebar} className="w-[60px] h-[60px] group flex justify-center items-center">
                        <SvgIcon dataKey="icon-menu" className="w-4 h-4 hover:fill-white fill-gray-200" />
                    </button>
                </AnimateButton>
                {menuOpen && <LeftPanel />}
            </div>
            <div className="px-2 py-4 flex-1 flex flex-col overflow-hidden">
                {!menuOpen && <LeftPanel small />}
                {platformConfig?.isPddEnable === 1 && <RoulettePanel open={menuOpen} />}
                <Crypto open={menuOpen} />
                <div className="scrollY flex flex-col -mr-1.5">
                    <Menu open={menuOpen} menuType={menuType} />
                </div>
            </div>
        </motion.div>
    );
};

export default Sidebar;
