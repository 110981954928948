export class MsgPackage{
    id  : number;
    msg : Object;
}

export enum ERROR_CODE {
    NONE = 0,                   //
    TOKEN_INVALID = 100,        //token无效
    PLAYER_NOT_FOUND = 101,     //playerid不存在
    REPLACE = 102,              //被别人顶替
    KICK = 103,                 //被踢下线

    GAME_ID_INVALID = 200,      //gameid无效
    ROOM_ID_INVALID = 201,      //roomid无效
    ALREADY_IN_ROOM = 202,      //已经在游戏中
    NOT_IN_ROOM = 203,          //未进入房间
    NO_BALANCE = 204,           //余额不足
    ROBOT_ENOUGH = 205,         //不需要机器人进场
    CAN_NOT_EXIT = 206,         //不能退出
    OPERATION_INVALID = 207,    //操作无效
    CLUB_ID_INVALID = 208,      //clubid无效
    TABLE_ID_INVALID = 209,     //table_id无效
    ENTER_FAIL = 210,
    SERVER_MAINTAIN = 211,
    TABLE_NO_INVALID = 213,
    BALANCE_NOT_ENOUGH = 214,
    PRIVATE_TABLE_INIT_BET_ERROR = 215,
    BET_AMOUNT_LIMIT = 216,
    GAME_REMOVED = 217
};

export enum CMD_COMMON_C2S{
    PING = 3,
    PLAYER_LOGIN_REQ = 100,
    PLAYER_LOGOUT_REQ = 102,
    ROOM_LIST_REQ = 200,
    ENTER_ROOM_REQ = 202,
    EXIT_ROOM_REQ = 204,
    SWITCH_COIN_REQ = 207,
    PLAYER_CURRENCY_CHECK_REQ = 111,
}

export enum CMD_COMMON_S2C{
    CONNECTED = 1,
    PONG = 4,
    PLAYER_LOGIN_REPLY = 101,
    PLAYER_LOGOUT_REPLY = 103,
    PLAYER_BALANCE_EVENT = 104,
    ROOM_LIST_REPLY = 201,
    ENTER_ROOM_REPLY = 203,
    EXIT_ROOM_REPLY = 205,
    SWITCH_COIN_REPLY = 208,
    SYNC_GAME_PAYOUT = 209, //同步game服务器数据

    TABLE_PLAYERS_EVENT = 300,
    TABLE_TIME_EVENT = 301,
    GAME_BALANCE_EVENT = 302,
    PLAYER_CURRENCY_CHECK_REPLY = 112,
}

export enum CMD_CRASH_C2S{
    CRASH_BET_REQ = 10005,//下注请求
    CRASH_CASH_OUT_REQ = 10007,//游戏Cash out
    CRASH_PLAYER_LIST_REQ = 10008,//玩家列表请求
}

export enum CMD_CRASH_S2C{
    CRASH_HISTORY_RESULT_EVENT = 10001,//历史记录
    CRASH_GAME_START_EVENT = 10002,//游戏开始
    CRASH_PLAYERS_EVEVT = 10003,//游戏玩家信息
    CRASH_STATE_EVENT = 10004,//座位状态事件
    CRASH_BET_EVENT = 10006,//下注事件
    CRASH_PLAYER_LIST_EVENT = 10009,//玩家列表事件
    CRASH_FLY_END_EVENT = 10010,//飞行事件
    CRASH_WIN_EVENT = 10011,//玩家赢事件
    CRASH_LOSE_EVENT = 10012,//玩家赢事件
    CRASH_FLY_TICK_EVENT = 10013,//飞行计时事件
}

export enum CMD_FURYWHEEL_C2S{
    WHEEL_STATE_REQ = 10101, //转盘状态请求
    WHEEL_RESULT_REQ = 10103, //转盘请求
}

export enum CMD_FURYWHEEL_S2C{
    WHEEL_STATE_REPLY = 10102, //转盘状态回应
    WHEEL_RESULT_REPLY = 10104, //转盘回应
}

export enum CMD_WHEEL_C2S{
    WHEEL_STATE_REQ = 10201, //转盘状态请求
    WHEEL_RESULT_REQ = 10203, //转盘请求
}

export enum CMD_WHEEL_S2C {
    WHEEL_STATE_REPLY = 10202,
    WHEEL_RESULT_REPLY = 10204,
}

export enum CMD_PLINKO_C2S{
    PLINKO_STATE_REQ = 10301, //状态请求
    PLINKO_RESULT_REQ = 10303, //结果请求
}

export enum CMD_PLINKO_S2C {
    PLINKO_STATE_REPLY = 10302,
    PLINKO_RESULT_REPLY = 10304,
}

export enum CMD_CRYPTOS_C2S{
    CRYPTOS_STATE_REQ = 10401, //状态请求s
    CRYPTOS_RESULT_REQ = 10403, //结果请求
}

export enum CMD_CRYPTOS_S2C{
    CRYPTOS_STATE_REPLY = 10402, //状态回应
    CRYPTOS_RESULT_REPLY = 10404, //结果回应
}

export enum CMD_LIMBO_C2S{
    LIMBO_STATE_REQ = 10501, //状态请求
    LIMBO_RESULT_REQ = 10503, //结果请求
}

export enum CMD_LIMBO_S2C{
    LIMBO_STATE_REPLY = 10502, //状态回应
    LIMBO_RESULT_REPLY = 10504, //结果回应
}

export enum CMD_MINES_C2S{
    MINES_STATE_REQ = 10601, //状态请求
    MINES_BET_REQ = 10603, //下注请求
    MINES_OPEN_REQ = 10605, //打开卡片请求
    MINES_AUTO_REQ = 10608, //自动请求
    MINES_CASHOUT_REQ = 10609, //cashOut
}

export enum CMD_MINES_S2C{
    MINES_STATE_REPLY = 10602, //状态回应
    MINES_BET_REPLY = 10604, //下注回应
    MINES_OPEN_REPLY = 10606, //打开卡片回应
    MINES_RESULT_REPLY = 10607, //结果回应
}

export enum CMD_HILO_C2S{
    HILO_STATE_REQ = 10701,
    HILO_BET_REQ = 10703,
    HILO_GUESS_REQ = 10705,
    HILO_SKIP_REQ = 10708,
    HILO_CASHOUT_REQ = 10710
}

export enum CMD_HILO_S2C{
    HILO_STATE_REPLY = 10702,
    HILO_BET_REPLY = 10704,
    HILO_GUESS_REPLY = 10706,
    HILO_RESULT_REPLY = 10707,
    HILO_SKIP_REPLY = 10709,
}

export enum CMD_CLASSICDICE_C2S{
    CLASSICDICE_STATE_REQ = 11601, //classicdice状态请求
    CLASSICDICE_RESULT_REQ = 11603, //classicdice请求
}

export enum CMD_CLASSICDICE_S2C{
    CLASSICDICE_STATE_REPLY = 11602, //classicdice状态回应
    CLASSICDICE_RESULT_REPLY = 11604, //classicdice回应
}

export enum CMD_STAIRS_C2S{
    STAIRS_STATE_REQ = 10901,
    STAIRS_BET_REQ = 10903,
    STAIRS_PICK_REQ = 10905,
    STAIRS_CASHOUT_REQ = 10908
}

export enum CMD_STAIRS_S2C{
    STAIRS_STATE_REPLY = 10902,
    STAIRS_BET_REPLY = 10904,
    STAIRS_PICK_REPLY = 10906,
    STAIRS_RESULT_REPLY = 10907,
}

export enum CMD_COINFLIP_C2S{
    COINFLIP_STATE_REQ = 11101,
    COINFLIP_BET_REQ = 11103,
    COINFLIP_GUESS_REQ = 11105,
    COINFLIP_CASH_OUT_REQ = 11107,
}

export enum CMD_COINFLIP_S2C{
    COINFLIP_STATE_REPLY = 11102,
    COINFLIP_BET_REPLY = 11104,
    COINFLIP_GUESS_REPLY = 11106,
    COINFLIP_CASH_OUT_REPLY = 11108,
}

export enum CMD_VIDEOPOKER_C2S{
    VIDEOPOKER_STATE_REQ = 11401,
    VIDEOPOKER_BET_REQ = 11403,
    VIDEOPOKER_DEAL_REQ = 11405,
}

export enum CMD_VIDEOPOKER_S2C{
    VIDEOPOKER_STATE_REPLY = 11402,
    VIDEOPOKER_BET_REPLY = 11404,
    VIDEOPOKER_DEAL_REPLY = 11406
}

export enum CMD_KENO_C2S{
    KENO_STATE_REQ = 11501, //keno状态请求
    KENO_RESULT_REQ = 11503, //keno请求
}

export enum CMD_KENO_S2C{
    KENO_STATE_REPLY = 11502, //keno状态回应
    KENO_RESULT_REPLY = 11504, //keno回应
}

export enum CMD_RINGOFFORTUNE_C2S{
    RINGOFFORTUNE_STATE_REQ = 11701, //ringoffortune状态请求
    RINGOFFORTUNE_RESULT_REQ = 11703, //ringoffortune请求
}

export enum CMD_RINGOFFORTUNE_S2C {
    RINGOFFORTUNE_STATE_REPLY = 11702, //ringoffortune状态回应
    RINGOFFORTUNE_RESULT_REPLY = 11704, //ringoffortune回应
}

export enum CMD_DOUBLE_S2C {
    DOUBLE_HISTORY_RESULT_EVENT = 11801,//历史记录
    DOUBLE_GAME_START_EVENT = 11802,//游戏开始
    DOUBLE_PLAYERS_EVEVT = 11803,//游戏玩家信息
    DOUBLE_STATE_EVENT = 11804,//座位状态事件
    DOUBLE_BET_EVENT = 11806,//下注事件
    DOUBLE_PLAYER_LIST_EVENT = 11809,//玩家列表事件
    DOUBLE_GAME_END_EVENT = 11810,  //结束事件
    DOUBLE_GAME_RESULT_EVENT = 11811,  //赢钱事件
}

export enum CMD_DOUBLE_C2S {
    DOUBLE_BET_REQ = 11805,//下注请求
    DOUBLE_PLAYER_LIST_REQ = 11808,//玩家列表请求
}

export enum CMD_ALLSTAR_S2C {
    ALLSTAR_HISTORY_RESULT_EVENT = 12001,//历史记录
    ALLSTAR_GAME_START_EVENT = 12002,//游戏开始
    ALLSTAR_PLAYERS_EVEVT = 12003,//游戏玩家信息
    ALLSTAR_STATE_EVENT = 12004,//座位状态事件
    ALLSTAR_BET_EVENT = 12006,//下注事件
    ALLSTAR_PLAYER_LIST_EVENT = 12009,//玩家列表事件
    ALLSTAR_GAME_END_EVENT = 12010,  //结束事件
    ALLSTAR_GAME_RESULT_EVENT = 12011,  //赢钱事件
}

export enum CMD_ALLSTAR_C2S {
    ALLSTAR_BET_REQ = 12005,//下注请求
    ALLSTAR_PLAYER_LIST_REQ = 12008,//玩家列表请求
}

export enum CMD_TOWERLEGEND_C2S{
    TOWERLEGEND_STATE_REQ = 11901,
    TOWERLEGEND_BET_REQ = 11903,
    TOWERLEGEND_DEAL_REQ = 11905,
    TOWERLEGEND_CASH_OUT_REQ = 11908,
}

export enum CMD_TOWERLEGEND_S2C{
    TOWERLEGEND_STATE_REPLY = 11902,
    TOWERLEGEND_BET_REPLY = 11904,
    TOWERLEGEND_DEAL_REPLY = 11906,
    TOWERLEGEND_RESULT_REPLY = 11907,
    TOWERLEGEND_CASH_OUT_REPLY = 11909,
}

export enum CMD_DRAGONTIGER_S2C {
    DRAGONTIGER_HISTORY_RESULT_EVENT = 12101,//历史记录
    DRAGONTIGER_GAME_START_EVENT = 12102,//游戏开始
    DRAGONTIGER_PLAYERS_EVEVT = 12103,//游戏玩家信息
    DRAGONTIGER_STATE_EVENT = 12104,//座位状态事件
    DRAGONTIGER_BET_EVENT = 12106,//下注事件
    DRAGONTIGER_PLAYER_LIST_EVENT = 12109,//玩家列表事件
    DRAGONTIGER_GAME_END_EVENT = 12110,  //结束事件
    DRAGONTIGER_GAME_RESULT_EVENT = 12111,  //赢钱事件
}

export enum CMD_DRAGONTIGER_C2S {
    DRAGONTIGER_BET_REQ = 12105,//下注请求
    DRAGONTIGER_PLAYER_LIST_REQ = 12108,//玩家列表请求
}

export enum CMD_TRUCO_S2C {
    TRUCO_HISTORY_RESULT_EVENT = 12201,//历史记录
    TRUCO_GAME_START_EVENT = 12202,//游戏开始
    TRUCO_PLAYERS_EVEVT = 12203,//游戏玩家信息
    TRUCO_STATE_EVENT = 12204,//座位状态事件
    TRUCO_BET_EVENT = 12206,//下注事件
    TRUCO_PLAYER_LIST_EVENT = 12209,//玩家列表事件
    TRUCO_GAME_END_EVENT = 12210,  //结束事件
    TRUCO_GAME_RESULT_EVENT = 12211,  //赢钱事件
    TRUCO_BET_FAILED_EVENT = 12212
}

export enum CMD_TRUCO_C2S {
    TRUCO_BET_REQ = 12205,//下注请求
    TRUCO_PLAYER_LIST_REQ = 12208,//玩家列表请求
}