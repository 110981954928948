/* eslint-disable prefer-const */
import {GameInfo} from "types/game";
export const toFixedSignificantDigit = (amount: number | string, size: number) => {
    const value = Number(amount || 0).toFixed(size);
    const index = value.indexOf(".");
    const high = index > 0 ? value.substring(0, index) : value;
    if (high.length > size + 1) {
        return value.substring(0, size + 1);
    }
    return `${Number(value).toFixed(Math.max(0, size - high.length))}`;
};

export const getGameLogoUrl = (game: GameInfo) => {
    if (game.gameInlineType === 1) {
        return window.$config.image_base_url + game.logoUrl;
    }
    return game.logoUrl && game.logoUrl.startsWith("http") ? game.logoUrl : window.$config.thirdparty_image_base_url + game.logoUrl;
};

export const PixToRem = (pix) => {
    const font = 16 * (window.innerWidth <= 660 ? window.innerWidth / 440 : 1);
    return pix / font;
};

export const getCurrencyIcon = (type: number) => {
    return `${window.$config.image_base_url}images/platform/coin/${window.$config.platform_id}/${type}.png`;
};

export const formatNumber = (v: number) => v.toFixed(8);

export const base64Encode = (code: string) => {
    return window.btoa(code);
};

export const base64Decode = (code: string) => {
    return window.atob(code);
};

export const uint8ArrayToString = (fileData: Uint8Array) => {
    let dataString = "";
    for (let i = 0; i < fileData.length; i++) {
        dataString += String.fromCharCode(fileData[i]);
    }
    return dataString;
};

export const toLocaleNumber = (
    num = 0,
    opt: any = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
    },
) => {
    return num.toLocaleString("en-US", opt);
};

export const stringToUint8Array = (str: string) => {
    let arr = [];
    for (let i = 0, j = str.length; i < j; ++i) {
        arr.push(str.charCodeAt(i));
    }

    let tmpUint8Array = new Uint8Array(arr);
    return tmpUint8Array;
};

export function compareTime(startTime: number, endTime: number) {
    const retValue = Object.create(null);

    const compareTime = endTime - startTime;

    const days = Math.floor(compareTime / (24 * 3600 * 1000));
    retValue.Days = days < 10 ? `0${days}` : days;

    const years = Math.floor(days / 365);
    retValue.Years = years;

    const months = Math.floor(days / 30);
    retValue.Months = months < 10 ? `0${months}` : months;

    const leaveHours = compareTime % (24 * 3600 * 1000);
    const hours = Math.floor(leaveHours / (3600 * 1000));

    const leaveMinutes = leaveHours % (3600 * 1000);
    const minutes = Math.floor(leaveMinutes / (60 * 1000));
    const getHours = minutes === 60 ? hours + 1 : hours;
    retValue.Hours = getHours < 10 ? `0${getHours}` : getHours;

    const leaveSeconds = leaveMinutes % (60 * 1000);
    const seconds = Math.round(leaveSeconds / 1000);
    retValue.Seconds = seconds < 10 ? `0${seconds}` : seconds === 60 ? "00" : seconds;
    const getMinutes = seconds === 60 ? minutes + 1 : minutes;
    retValue.Minutes = getMinutes < 10 ? `0${getMinutes}` : getMinutes;

    return retValue;
}

type ValueOf<T> = T[keyof T];
export function getEnumKeyByEnumValue<R extends string | number, T extends {[key: string]: R}>(myEnum: T, enumValue: ValueOf<T>): string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : "";
}

export const getDDHHmmssFromMilSec = (milsec: number): number[] => {
    const d = Math.floor(milsec / (24 * 60 * 60 * 1000));
    milsec %= 24 * 60 * 60 * 1000;
    const h = Math.floor(milsec / (3600 * 1000));
    milsec %= 3600 * 1000;
    const m = Math.floor(milsec / (60 * 1000));
    milsec %= 60 * 1000;
    const s = Math.floor(milsec / 1000);
    return [d, h, m, s];
};

export const addZeroToNum = (num: number): string => (String(num).length < 2 ? "0" + String(num) : String(num));

export function easeInOutQuart(t: any) {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
}

export function Animate(start: number, end: number, time: number, callback: (val: any) => void, timing = (t: any) => t) {
    const startTime = performance.now();
    const differ = end - start;
    return new Promise<void>((resolve) => {
        function loop() {
            raf = requestAnimationFrame(loop);
            const passTime = performance.now() - startTime;
            let per = passTime / time;
            if (per >= 1) {
                per = 1;
                cancelAnimationFrame(raf);
                resolve();
            }
            const pass = differ * timing(per);
            callback(pass);
        }
        let raf = requestAnimationFrame(loop);
    });
}

export const secondFormat = (second: number) => {
    let h: any = Math.floor(second / 3600);
    let m: any = Math.floor((second / 60) % 60);
    let s: any = Math.floor(second % 60);
    if (h < 10) {
        h = `0${h}`;
    }
    if (m < 10) {
        m = `0${m}`;
    }
    if (s < 10) {
        s = `0${s}`;
    }
    return `${h}:${m}:${s}`;
};

export const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
};
