// ==============================|| Empty ||============================== //
import SvgIcon from "ui-component/SvgIcon";
interface EmptyProps {
    descText?: string;
}
const Empty = ({descText = "暂无条目"}: EmptyProps) => {
    return (
        <div className="empty-list p-4 mt-3 spacing-normal w-full h-full flex flex-col justify-center items-center">
            <div className="pb-4">
                <SvgIcon dataKey="icon-no-stats" className={`w-[6rem] h-[6rem] fill-current text-gray-200 }`} />
            </div>
            <p className="font-normal text-[0.875rem]">{descText}</p>
        </div>
    );
};

export default Empty;
