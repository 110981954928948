import telegram from "assets/login/telegram.png";
import apiAuth from "api/auth";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";
import client from "api/client";
import {useAppDispatch, useAppSelector} from "store";
import {loginSuccess} from "store/slices/user";
import {closeDialog} from "store/slices/dialog";

const TelegramLogin = () => {
    const inviteCode = useAppSelector((state) => state.config.inviteCode);
    const promoCode = useAppSelector((state) => state.config.promoCode);
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const handleLogin = () => {
        window.Telegram?.Login.auth({bot_id: "6890886812", request_access: "write", embed: 1}, async (data) => {
            if (data) {
                const res = await apiAuth.tgLogin({...(promoCode ? {promoCode} : {inviteCode: inviteCode || "-1"}), ...data});
                if (res.status === 200 && res.data.code === 0) {
                    client.setToken(res.data.data.token);
                    dispatch(loginSuccess(res.data.data));
                    dispatch(closeDialog());
                    return;
                }
                if (res.data.code === 101) {
                    toast.error(intl.$t({id: "SystemError"}));
                    return;
                }
                toast.error(intl.$t({id: `Google_LOGIN_ERROR_${res.data.code}`}));
            }
        });
    };

    return (
        <button className="w-full cursor-pointer flex justify-center py-[0.8125rem] px-4 bg-gray-400 hover:bg-gray-300 rounded-[0.25rem]" onClick={handleLogin}>
            <img src={telegram} className="w-4 h-4" />
        </button>
    );
};

export default TelegramLogin;
