import React, {useRef} from "react";
import {GoogleLoginButton} from "ui-component/GoogleLoginButton";
import SvgIcon from "ui-component/SvgIcon";

function GoogleLogin() {
    const buttonRef = useRef(null);
    return (
        <div className="relative [&>button:hover]:bg-gray-300">
            <button ref={buttonRef} className="absolute top-0 left-0 w-full flex justify-center py-[0.8125rem] px-4 bg-gray-400 rounded-[0.25rem]">
                <SvgIcon dataKey="icon-google" className="w-4 h-4 fill-white" />
            </button>

            <GoogleLoginButton
                onMouseEnter={() => {
                    buttonRef.current.classList.add("bg-gray-300");
                    buttonRef.current.classList.remove("bg-gray-400");
                }}
                onMouseLeave={() => {
                    buttonRef.current.classList.add("bg-gray-400");
                    buttonRef.current.classList.remove("bg-gray-300");
                }}
                onClick={() => {
                    buttonRef.current.classList.add("bg-gray-400");
                    buttonRef.current.classList.remove("bg-gray-300");
                }}
            />
        </div>
    );
}

export default GoogleLogin;
