import axios from "axios";
import {store} from "store";
import {setMaintainTime} from "store/slices/config";
import {logout} from "store/slices/user";
import {InvalidTokenCode, ShutdownCode} from "utils/constants";

axios.defaults.baseURL = window.$config.base_url;
axios.defaults.headers.common["X-Token"] = "";
axios.defaults.headers.common["X-Lang"] = "2";
axios.defaults.headers.common["X-Platform-Id"] = window.$config.platform_id;

const client = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,

    setToken: (token: string) => {
        axios.defaults.headers.common["X-Token"] = token;
    },
};

axios.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            if (response.data.code === InvalidTokenCode) {
                store.dispatch(logout());
            } else if (response.data.code === ShutdownCode) {
                store.dispatch(setMaintainTime(response.data.data));
            }
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export default client;
