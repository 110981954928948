import React from "react";

export interface Cols {
    title: React.ReactNode | string;
    dataIndex: string;
    render?: (text, row: any) => React.ReactNode;
    align?: "left" | "right" | "center";
}

interface Props {
    columns: Cols[];
    dataSource: object[];
    rowClassName?: (record: any, index) => string;
    renderNoData?: () => React.ReactNode;
    pageSize?: number;
    headerCls?: string;
    isLoading?: boolean;
}

const alignDict = {
    left: "justify-start",
    right: "justify-end",
    center: "justify-center",
};

const Table: React.FC<Props> = (props) => {
    const {columns, dataSource, rowClassName, isLoading, headerCls, renderNoData, pageSize = 10} = props;
    const tableData = isLoading ? Array.from({length: pageSize}) : dataSource;
    const renderTable = () => {
        if (!isLoading && dataSource.length === 0) {
            return renderNoData ? renderNoData() : null;
        }
        return tableData.map((row, index) => {
            return (
                <tr key={index} className={`${rowClassName?.(row, index)} flex justify-between rounded-md`}>
                    {columns.map((col, colIndex) => (
                        <td key={col.dataIndex} className={`p-4 w-full inline-flex text-sm ${col.align ? alignDict[col.align] : "justify-start"}`}>
                            {isLoading ? (
                                <span
                                    className="bg-gray-200 h-4 inline-flex rounded-[0.25rem]"
                                    style={{width: `${3 + Math.floor(Math.random() * colIndex * 10) / 10}rem`}}></span>
                            ) : col.render ? (
                                col.render(row[col.dataIndex], row)
                            ) : (
                                <span>{row[col.dataIndex]}</span>
                            )}
                        </td>
                    ))}
                </tr>
            );
        });
    };
    return (
        <section className="w-full">
            <div className="flex flex-col w-full">
                <div className="overflow-x-auto w-full">
                    <table className="min-w-full">
                        <thead>
                            <tr className={`${headerCls} flex justify-between rounded-md`}>
                                {columns.map((col) => (
                                    <th className={`p-4 w-full inline-flex text-sm ${col.align ? alignDict[col.align] : "justify-start"}`}>{col.title}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>{renderTable()}</tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default Table;
