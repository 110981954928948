import {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import dayjs from "dayjs";
import {useIntl} from "react-intl";
import {getCurrencyIcon, toFixedSignificantDigit} from "utils/help";
import {useAppSelector} from "store";
import SvgIcon from "ui-component/SvgIcon";
import AnimateButton from "ui-component/AnimateButton";
import Loader from "ui-component/Loader";
import Empty from "ui-component/Empty";
import transactionsApi from "api/transactions";
const Bets = () => {
    const navigate = useNavigate();
    const {search} = useLocation();
    const isMobile = useAppSelector((state) => state.config.isMobile);
    const params = new URLSearchParams(search);
    const page = params.get("page");
    const {$t} = useIntl();
    const [betList, setBetList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isClick, setIsClick] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const formatAmount = (amount: string) => {
        return amount.trimEnd();
    };

    const getDataList = async (dataParam: any) => {
        setIsClick(false);
        setIsLoading(true);
        try {
            const {status, data} = await transactionsApi.queryUserBetHistoryList({pageId: dataParam.pageId});
            if (status === 200 && data.code === 0) {
                setBetList(data.data.list || []);
                setTotalCount(data.data.totalCount || 0);
                dataParam.action && navigate(`?page=${dataParam.pageId}`);
            }
        } finally {
            setIsClick(true);
            setIsLoading(false);
        }
    };
    const handleAdd = () => {
        let num = currentPage;
        num < Math.ceil(totalCount / 10) ? num++ : Math.ceil(totalCount / 10);
        setCurrentPage(num);
        getDataList({pageId: num, action: "Add"});
    };
    const handleDescend = () => {
        let num = currentPage;
        if (num > 1) {
            num--;
        } else {
            num = 1;
        }
        setCurrentPage(num);
        getDataList({pageId: num, action: "Descend"});
    };
    useEffect(() => {
        if (page) {
            setCurrentPage(Number(page));
            getDataList({pageId: Number(page)});
        } else {
            getDataList({pageId: 1});
        }
    }, []);
    return (
        <div className={`p-6 bg-gray-700 rounded-[0.5rem] relative min-h-[16rem]`}>
            <ul className="tableWrap text-gray-200 text-[0.875rem]">
                {betList &&
                    betList.length > 0 &&
                    betList.map((item, index) => {
                        return (
                            <li
                                key={item.betId}
                                className={`grid grid-flow-col justify-between items-center ${!(index % 2) ? "bg-gray-500 rounded-[0.25rem]" : ""}`}>
                                <div className="grid grid-flow-col justify-start items-center p-4">
                                    <SvgIcon dataKey="icon-stake-game-limbo" className="mr-2 w-[1rem] h-[1.18rem] fill-current text-gray-200" />
                                    <div className="grid grid-flow-row justify-start items-center gap-1">
                                        <div className="text-white leading-[1.313rem]">{item.gameName}</div>
                                        <div className="font-normal leading-none">{dayjs(item.time).format($t({id: "TransactionsTimeFormat"}))}</div>
                                    </div>
                                </div>
                                <div className="p-4 grid grid-flow-row justify-end items-center">
                                    <div className="grid grid-flow-col justify-end items-center">
                                        <span className="font-normal">{formatAmount(toFixedSignificantDigit(item.betAmount, 8))}</span>
                                        <img src={getCurrencyIcon(item.tokenId)} className="w-3.5 h-3.5 ml-1" alt="" />
                                    </div>
                                    <div className="grid grid-flow-col justify-end items-center gap-1">
                                        <span className="grid grid-flow-col justify-end items-center leading-none text-white">
                                            <SvgIcon dataKey="icon-list" className="mr-2 w-[0.875rem] h-[0.875rem] fill-current text-gray-200" />
                                            <div className={`${isMobile ? "max-w-[5.59rem] overflow-hidden whitespace-nowrap text-ellipsis" : "w-auto"}`}>
                                                {item.betId}
                                            </div>
                                        </span>
                                        <span className={`font-normal ${item.winAmount > 0 ? "text-[#3bc117]" : "text-[#ed6300]"}`}>
                                            {item.winAmount > 0
                                                ? `+${formatAmount(toFixedSignificantDigit(item.winAmount, 4))}`
                                                : formatAmount(toFixedSignificantDigit(item.winAmount, 4))}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
            </ul>
            <div
                className={`mt-6 grid grid-flow-col justify-center text-[0.875rem] items-center gap-4 text-white ${
                    betList && betList.length > 0 ? "block" : "hidden"
                }`}>
                <AnimateButton>
                    <div
                        onClick={() => {
                            isClick && currentPage > 1 && handleDescend();
                        }}
                        className={`${currentPage <= 1 ? "opacity-[0.5] hover:cursor-not-allowed" : "hover:cursor-pointer"}`}>
                        {$t({id: "Previous"})}
                    </div>
                </AnimateButton>
                <AnimateButton>
                    <div
                        onClick={() => {
                            isClick && currentPage < Math.ceil(totalCount / 10) && handleAdd();
                        }}
                        className={`${currentPage >= Math.ceil(totalCount / 10) ? "opacity-[0.5] hover:cursor-not-allowed" : "hover:cursor-pointer"}`}>
                        {$t({id: "Next"})}
                    </div>
                </AnimateButton>
            </div>
            {isLoading && (
                <div className="absolute top-0 left-0 bottom-0 right-0 bg-gray-900 opacity-80">
                    <div className="absolute bottom-[30%] left-[50%]">
                        <Loader />
                    </div>
                </div>
            )}
            {betList.length === 0 && !isLoading && <Empty descText={$t({id: "NoBankingBets"})} />}
        </div>
    );
};
export default Bets;
