import React, {FC, useEffect, useRef, useState, createContext, useContext, memo, useMemo} from "react";
import {useSignal, useReaction, SetterOrUpdater, SetValueType} from "utils/reaction";
import {useIntl, FormattedMessage} from "react-intl";
import {getUserMessageCountList} from "store/slices/message";
import dayjs from "dayjs";
import {motion, LayoutGroup, AnimatePresence} from "framer-motion";
import noData from "assets/notification/noData.png";
import apiMessage from "api/message";
import {getCurrencyIcon} from "utils/help";
import LoadMore from "ui-component/LoadMore";
import Loading from "ui-component/Loader";
import {portal} from "ui-component/Modal";
import AnimateButton from "ui-component/AnimateButton";
import {useAppSelector, useAppDispatch} from "store";
import reward from "assets/notification/reward.png";
import unreward from "assets/notification/unreward.png";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import SvgIcon from "../../ui-component/SvgIcon";

const fontSizeStyle = Quill.import("attributors/style/size");
fontSizeStyle.whitelist = ["12px", "14px", "16px", "18px", "20px", "22px", "24px", "26px", "28px", "30px"];
Quill.register(fontSizeStyle, true);

const msgTypeIds = [1, 4];

interface MessageProps {
    content: string;
    title: string;
    imageUrl: string;
    msgtype: number;
    rid: number;
    userid: number;
    ctime: number;
    rtime: number;
    src_userid: number;
    coinType: number;
    rewardType: number;
    rewardAmount: number;
    rewardUsingMsg: number;
    withdrawBetRate: number;
    rewardReceive: number;
}

interface MessageCellProps extends MessageProps {
    sortIndex: number | string;
}

const translateContext = createContext<{
    messageCell?: () => MessageCellProps | undefined;
    setMessageCell?: SetterOrUpdater<SetValueType<MessageCellProps | undefined>>;
}>({});
const useTranslateContext = () => useContext(translateContext);

const TranslateProvider: FC<{children: React.ReactNode}> = memo(({children}) => {
    const [messageCell, setMessageCell] = useSignal<MessageCellProps | undefined>(undefined);
    return <translateContext.Provider value={{messageCell, setMessageCell}}>{children}</translateContext.Provider>;
});

TranslateProvider.displayName = "TranslateProvider";

const RichText: FC<{content: string; extra?: string}> = ({content, extra}) => {
    const [text, setText] = useState("");
    const quillRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const reg = /\$\{(\d+)\}/g;
        setText(quillRef.current.innerText.replace(reg, ""));
    }, []);

    return (
        <div className="flex items-center py-2 pr-4">
            <div className="flex-grow truncate pl-4 text-[#98A7B5] text-sm font-normal mr-5">{text}</div>
            {extra && <div className="invisible h-2 pl-11">{extra}</div>}
            <div ref={quillRef} className="hidden" dangerouslySetInnerHTML={{__html: content}}></div>
        </div>
    );
};

const RichContent: FC<{content: string}> = ({content}) => {
    const quillRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const reg = /\$\{(\d+)\}/g;
        const newContent = content.replace(reg, (_, con) => `<img alt="" src="${getCurrencyIcon(Number(con))}">`);
        const quill = new Quill(quillRef.current, {
            modules: {
                toolbar: false, // Snow includes toolbar by default
            },
            theme: "snow",
            readOnly: true,
        });
        quill.clipboard.dangerouslyPasteHTML(newContent);
        const editor = quillRef.current.querySelector(".ql-editor") as any;
        editor.classList.add("!px-0");
    }, []);
    return (
        <div className="quill-wrap">
            <div ref={quillRef} style={{border: "none"}} />
        </div>
    );
};

const MessagePc: FC<MessageCellProps & {isActive: boolean}> = ({isActive, ...props}) => {
    const {rid, title, ctime, rtime, sortIndex, content} = props;
    const {messageCell, setMessageCell} = useTranslateContext();
    const dispatch = useAppDispatch();
    const status = useRef(rtime > 0 ? "read" : "unread");

    useEffect(() => {
        status.current = rtime > 0 ? "read" : "unread";
    }, [rtime]);
    const handleClick = () => {
        const preMessageCell = messageCell();
        if (preMessageCell?.rid !== rid) {
            setMessageCell(props);
            if (rtime === 0) makeAsRead([rid]);
        }
    };

    const makeAsRead = async (ids: number[]) => {
        const res = await apiMessage.updateUserMessageVo(ids);
        if (res.status === 200 && res.data.code === 0) {
            dispatch(getUserMessageCountList());
        }
    };

    return (
        <div
            className={`relative ${isActive || status.current === "read" ? "bg-[#263E4B]" : "bg-[#2F4553]"} rounded-[0.5rem] mb-4`}
            onClick={handleClick}
            style={{border: isActive ? "1px solid #007CEF" : ""}}>
            <div className="absolute left-2 top-2 w-6 h-4">
                <span
                    className={`absolute top-0 w-full h-full flex justify-center items-center text-sm font-bold text-[#0E212E] rounded ${
                        status.current === "unread" ? "bg-white" : "bg-[#9DB8C6]"
                    }`}>
                    {sortIndex}
                </span>
            </div>
            <div className="text-right text-[#98A7B5] text-sm leading-10 mx-4">{dayjs(ctime).format("YYYY/MM/DD HH:mm:ss")}</div>
            <div className={`${isActive ? "text-white" : "text-[#98A7B5]"} text-base font-bold px-4`}>{title}</div>
            <RichText content={content} />
        </div>
    );
};

const ContentPc: FC = memo(() => {
    const intl = useIntl();
    const {messageCell, setMessageCell} = useTranslateContext();
    const content = useReaction(() => messageCell());
    const currentUserId = useAppSelector((state) => state.user.user.userId);
    const receiveRewardByMsg = async () => {
        const res = await apiMessage.receiveRewardByMsg({
            messageId: content.rid,
            userid: currentUserId,
            rewardType: content.rewardType,
            rewardAmount: content.rewardAmount,
            coinType: content.coinType,
            withdrawBetRate: content.withdrawBetRate,
        });
        if (res.status === 200 && res.data.code === 0) {
            setMessageCell({...content, rewardReceive: 1});
        }
    };
    return (
        <motion.div layout className={`bg-[#1C313F] h-full rounded-[0.5rem] ${content ? "w-[23.5rem] p-4 ml-4" : "w-0"}`}>
            <div className="relative flex w-full border-solid border-b-[1px] border-[#263E4B]">
                {content && (
                    <>
                        <div className="absolute left-0 top-0 w-6 h-4">
                            <span
                                className={`absolute top-0 w-full h-full flex justify-center items-center text-sm font-bold text-[#0E212E] rounded ${
                                    content.rtime > 0 ? "bg-[#9DB8C6]" : "bg-white"
                                }`}>
                                {content.sortIndex}
                            </span>
                        </div>
                        <div className="w-full text-right text-[#98A7B5] text-sm h-8">{dayjs(content.ctime).format("YYYY/MM/DD HH:mm:ss")}</div>
                    </>
                )}
            </div>
            {content && (
                <div className="mt-4 max-h-[37rem] overflow-auto -mr-4 pr-4">
                    <div className="text-white text-base font-bold">{content.title}</div>
                    <div className="my-2">{content.imageUrl && <img src={`${window.$config.image_base_url}${content.imageUrl}`} alt="" />}</div>
                    <RichContent content={content.content} key={content.rid} />
                    {content.rewardAmount > 0 && content.rewardUsingMsg > 0 && (
                        <div className="mt-6 flex flex-col justify-between w-full">
                            <div className="w-[10.5rem] relative pb-2">
                                {content.rewardReceive > 0 ? <img src={unreward} alt="" /> : <img src={reward} alt="" />}
                                <div className="absolute top-1/2 -translate-y-1/2 right-4 flex items-center gap-2">
                                    <span className={`${content.rewardReceive > 0 ? "text-[#98A7B5]" : "text-[#2E2F34]"} text-base font-bold`}>
                                        {content.rewardAmount}
                                    </span>
                                    <img src={getCurrencyIcon(Number(content.coinType))} alt="" className="w-4" />
                                </div>
                            </div>
                            <div className="h-[1px] bg-[#42454E] w-full mt-4 mb-8"></div>
                            <div className="flex justify-center items-center">
                                {content.rewardReceive > 0 ? (
                                    <button disabled className="rounded-md w-[10rem] h-11 flex justify-center items-center text-[#98A7B5] bg-[#49485A]">
                                        {intl.$t({id: "ReceiveReward"})}
                                    </button>
                                ) : (
                                    <button
                                        onClick={receiveRewardByMsg}
                                        className="rounded-md w-[10rem] h-11 flex justify-center items-center text-white"
                                        style={{background: "linear-gradient(34deg, #4481EB 0.86%, #04BEFE 93.78%)"}}>
                                        {intl.$t({id: "ReceiveReward"})}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </motion.div>
    );
});

ContentPc.displayName = "ContentPc";

const MessageMobile: FC<MessageCellProps & {isActive: boolean}> = ({isActive, ...props}) => {
    const [showAll, setShowAll] = useState(false);
    const {rid, title, ctime, rtime, sortIndex, content, imageUrl} = props;
    const {messageCell, setMessageCell} = useTranslateContext();
    const currentUserId = useAppSelector((state) => state.user.user.userId);
    const dispatch = useAppDispatch();
    const status = useRef(rtime > 0 ? "read" : "unread");
    const intl = useIntl();
    const handleClick = () => {
        const preMessageCell = messageCell();
        if (preMessageCell?.rid !== rid) {
            setMessageCell(props);
            if (rtime === 0) makeAsRead([rid]);
        }
        if (!showAll) setShowAll(true);
    };

    useEffect(() => {
        status.current = rtime > 0 ? "read" : "unread";
    }, [rtime]);

    useEffect(() => {
        if (!isActive) setShowAll(false);
    }, [isActive]);

    const makeAsRead = async (ids: number[]) => {
        const res = await apiMessage.updateUserMessageVo(ids);
        if (res.status === 200 && res.data.code === 0) {
            dispatch(getUserMessageCountList());
        }
    };

    const receiveRewardByMsg = async () => {
        const res = await apiMessage.receiveRewardByMsg({
            messageId: props.rid,
            userid: currentUserId,
            rewardType: props.rewardType,
            rewardAmount: props.rewardAmount,
            coinType: props.coinType,
            withdrawBetRate: props.withdrawBetRate,
        });
        if (res.status === 200 && res.data.code === 0) {
            setMessageCell({...props, rewardReceive: 1});
        }
    };
    return (
        <div className={`relative ${isActive || status.current === "read" ? "bg-[#263E4B]" : "bg-[#2F4553]"} rounded-[0.5rem] mb-4`} onClick={handleClick}>
            <div className="absolute left-2 top-2 w-6 h-4">
                <span
                    className={`absolute top-0 w-full h-full flex justify-center items-center text-sm font-bold text-[#0E212E] rounded ${
                        status.current === "unread" ? "bg-white" : "bg-[#9DB8C6]"
                    }`}>
                    {sortIndex}
                </span>
            </div>
            <div className="text-right text-[#9DB8C6] text-sm leading-10 mx-4">{dayjs(ctime).format("YYYY/MM/DD HH:mm:ss")}</div>
            <div className="relative py-2">
                <div className={`${showAll ? "text-white" : "text-[#9DB8C6]"} text-base font-bold px-4`}>{title}</div>
                <AnimatePresence>
                    {!showAll && (
                        <motion.div initial={{opacity: 0, height: 0}} animate={{opacity: 1, height: "auto"}} exit={{opacity: 0, height: 0}}>
                            <RichText content={content} extra={intl.$t({id: "ShowMore"})} />
                        </motion.div>
                    )}
                    {showAll && (
                        <motion.div
                            className="mb-6 px-4 overflow-hidden"
                            initial={{opacity: 0, height: 0}}
                            animate={{opacity: 1, height: "auto"}}
                            exit={{opacity: 0, height: 0}}
                            transition={{type: "spring", duration: 0.8}}>
                            <div className="my-2">{imageUrl && <img src={`${window.$config.image_base_url}${imageUrl}`} alt="" />}</div>
                            <RichContent content={content} key={rid} />
                            {props.rewardAmount > 0 && props.rewardUsingMsg > 0 && (
                                <div className="mt-6 flex flex-col justify-between w-full">
                                    <div className="w-[10.5rem] relative pb-2">
                                        {props.rewardReceive > 0 ? <img src={unreward} alt="" /> : <img src={reward} alt="" />}
                                        <div className="absolute top-1/2 -translate-y-1/2 right-4 flex items-center gap-2">
                                            <span className={`${props.rewardReceive > 0 ? "text-[#98A7B5]" : "text-[#2E2F34]"} text-base font-bold`}>
                                                {props.rewardAmount}
                                            </span>
                                            <img src={getCurrencyIcon(Number(props.coinType))} alt="" className="w-4" />
                                        </div>
                                    </div>
                                    <div className="h-[1px] bg-[#42454E] w-full mt-4 mb-8"></div>
                                    <div className="flex justify-center items-center">
                                        {props.rewardReceive > 0 ? (
                                            <button disabled className="rounded-md w-[10rem] h-11 flex justify-center items-center text-[#98A7B5] bg-[#49485A]">
                                                {intl.$t({id: "ReceiveReward"})}
                                            </button>
                                        ) : (
                                            <button
                                                onClick={receiveRewardByMsg}
                                                className="rounded-md w-[10rem] h-11 flex justify-center items-center text-white"
                                                style={{background: "linear-gradient(34deg, #4481EB 0.86%, #04BEFE 93.78%)"}}>
                                                {intl.$t({id: "ReceiveReward"})}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </motion.div>
                    )}
                </AnimatePresence>
                <div
                    className="absolute bottom-4 right-2 flex border-solid border-[1px] border-white rounded px-2 py-1"
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowAll((c) => !c);
                        if (!showAll) handleClick();
                    }}>
                    <span className="text-sm text-white">{showAll ? intl.$t({id: "Hide"}) : intl.$t({id: "ShowMore"})}</span>
                </div>
            </div>
        </div>
    );
};

const MessagePanel: FC<{
    messageType: number;
    isMobile: boolean;
    isActive?: boolean;
    id?: number;
}> = ({messageType, isMobile, isActive, id}) => {
    const intl = useIntl();
    const [data, setData] = useState<MessageProps[]>([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);

    const {messageCell} = useTranslateContext();
    const currentMessageCell = useReaction(() => messageCell());
    const getUserMessageList = async (msgType: number, pageId = 1, pageCount = 10) => {
        const res = await apiMessage.getStationMessageList(msgType, pageId, pageCount);
        if (res.status === 200 && res.data.code === 0) {
            const result = res.data.data;
            setPage(pageId);
            setTotal(result.totalCount);
            setData((l) => [...l, ...result.list]);
        }
        setLoading(false);
    };

    useEffect(() => {
        getUserMessageList(messageType);
    }, [messageType]);
    return (
        <AnimatePresence>
            {isActive && (
                <motion.div layout>
                    {loading && <Loading />}
                    {!loading &&
                        (data.length > 0 ? (
                            <motion.div layout className="flex flex-col w-full">
                                <motion.div layout>
                                    {data.map((item, index) => {
                                        const isActive = currentMessageCell?.rid === item.rid;
                                        const sortIndex = index + 1;
                                        if (isActive) {
                                            if (item.rtime === 0) item.rtime = Date.now();
                                            if (currentMessageCell.rewardReceive) item.rewardReceive = currentMessageCell.rewardReceive;
                                        }
                                        if (isMobile) return <MessageMobile {...item} sortIndex={sortIndex} key={item.rid} isActive={isActive} />;
                                        return <MessagePc {...item} sortIndex={sortIndex} key={item.rid} isActive={isActive} />;
                                    })}
                                </motion.div>
                                <motion.div layout>
                                    <LoadMore nextPage={() => getUserMessageList(messageType, page + 1)} hasMore={page * pageSize < total} />
                                </motion.div>
                            </motion.div>
                        ) : (
                            <div className="flex justify-center items-center w-full h-[26rem]">
                                <div className="flex flex-col items-center">
                                    <img src={noData} width={140} />
                                    <p className="text-[#99A4B0]">{intl.$t({id: "EmptyText"})}</p>
                                </div>
                            </div>
                        ))}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

const Tabs: FC<{activeKey: number; children: React.ReactElement[]}> = memo(({activeKey, children}) => {
    const [tabIndex, setTabIndex] = useState(activeKey);
    const intl = useIntl();
    const preActiveKey = useRef(activeKey);
    const actKeys = useRef([activeKey]);
    const {setMessageCell} = useTranslateContext();
    const messageList = useAppSelector((state) => state.message.messageCount);
    useEffect(() => {
        if (preActiveKey.current !== tabIndex) {
            setMessageCell(undefined);
        }
        preActiveKey.current = tabIndex;
    });
    const messageDict = messageList.reduce((pre, cur) => {
        if (!pre[cur.msgtype]) pre[cur.msgtype] = 0;
        pre[cur.msgtype] += cur.count;
        return pre;
    }, Object.create(null));
    const [systemTypeId, activityTypeId] = msgTypeIds;
    const systemCount = messageDict[systemTypeId] || 0;
    const activityCount = messageDict[activityTypeId] || 0;

    if (!actKeys.current.includes(tabIndex)) {
        actKeys.current = [...actKeys.current, tabIndex];
    }

    return (
        <motion.div layout className="flex flex-col w-full h-full">
            <div className="flex bg-gray-700 p-1.5 rounded-[3rem] flex-shrink-0 mx-auto">
                <AnimateButton>
                    <button
                        className={`hover:bg-gray-400 ${
                            tabIndex === systemTypeId ? "bg-gray-400" : ""
                        } relative w-[8.5rem] py-3 px-4 rounded-[4rem] text-white text-sm cursor-pointer justify-center flex items-center font-bold z-50`}
                        onClick={() => setTabIndex(systemTypeId)}>
                        <span className={tabIndex === systemTypeId ? "font-bold text-white" : "text-[#98A7B5] font-medium"}>
                            {intl.$t({id: "SystemNotice"})}
                        </span>
                        {systemCount > 0 && (
                            <span className="absolute py-1 px-2 bg-[#007CEF] rounded-[0.5rem] right-1.5 -top-3 text-xs font-normal text-white  z-50">
                                {systemCount}
                                {systemCount > 99 ? " +" : ""}
                            </span>
                        )}
                    </button>
                </AnimateButton>
                <AnimateButton>
                    <button
                        className={`hover:bg-gray-400 ${
                            tabIndex === activityTypeId ? "bg-gray-400" : ""
                        } relative w-[8.5rem] py-3 px-4 rounded-[4rem] text-white text-sm cursor-pointer flex items-center justify-center font-bold`}
                        onClick={() => setTabIndex(activityTypeId)}>
                        <span className={tabIndex === activityTypeId ? "font-bold text-white" : "text-[#98A7B5] font-medium"}>
                            {intl.$t({id: "Activities"})}
                        </span>
                        {activityCount > 0 && (
                            <span className="absolute py-1 px-2 bg-[#007CEF] rounded-[0.5rem] right-1.5 -top-3 text-xs font-normal text-white">
                                {activityCount}
                                {activityCount > 99 ? " +" : ""}
                            </span>
                        )}
                    </button>
                </AnimateButton>
            </div>
            <div className="flex-grow overflow-auto mt-4 -mx-3 px-3">
                {React.Children.map(children, (ele) => {
                    const {id} = ele.props;
                    if (!actKeys.current.includes(id)) return null;
                    return React.cloneElement(ele, {isActive: id === tabIndex});
                })}
            </div>
        </motion.div>
    );
});

Tabs.displayName = "Tabs";

const Notification: FC<{activeKey?: number}> = ({activeKey = msgTypeIds[0]}) => {
    const isMobile = useAppSelector((state) => state.config.isMobile);
    return (
        <TranslateProvider>
            <motion.div className="flex items-center bg-[#192C38] px-4 w-full rounded-b-[0.5rem] h-full py-2">
                <LayoutGroup>
                    <motion.div layout className="flex flex-col w-full sm:w-[23.5rem] h-full bg-[#1C313F] rounded-[0.5rem] p-4">
                        <Tabs activeKey={activeKey}>
                            {msgTypeIds.map((item) => (
                                <MessagePanel messageType={item} key={item} isMobile={isMobile} id={item} />
                            ))}
                        </Tabs>
                    </motion.div>
                    {!isMobile && <ContentPc />}
                </LayoutGroup>
            </motion.div>
        </TranslateProvider>
    );
};

export const openNotification = (activeKey = msgTypeIds[0]) => {
    portal({
        title: (
            <div className="flex items-center">
                <SvgIcon dataKey="icon-notification" className="fill-white w-4 h-4 mr-2" />
                <FormattedMessage id="Notification" />
            </div>
        ),
        content: <Notification activeKey={activeKey} />,
        titleStyle: {borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem"},
        mobileTitleStyle: {borderTopLeftRadius: "0.5rem", borderTopRightRadius: "0.5rem"},
        style: {minWidth: "370px", height: "680px", maxHeight: "calc(100vh - 4rem)", overflow: "auto", marginTop: "-1rem"},
        mobileStyle: {width: "calc(100vw - 48px)", height: "calc(100vh - 12rem)", maxHeight: "calc(100vh - 12rem)", overflow: "auto", marginTop: "-1rem"},
        bgStyle: {background: "rgba(14, 33, 46, 0.7)"},
    });
};

export default Notification;
