import React from "react";
import {portal} from "ui-component/Modal";
import partnerIcons from "assets/roulette/partnerIcons.png";
import wheelCover from "assets/roulette/wheelCover.png";
import {useIntl} from "react-intl";
import Wheel from "./Wheel";
import {openCollectDialog} from "./WinsDialog";
// import {useAppSelector} from "store";

const Dialog: React.FC<{handleClose: () => void}> = ({handleClose}) => {
    const {$t} = useIntl();
    // const isMobile = useAppSelector((state) => state.config.isMobile);
    return (
        <div className="w-[450px] m-auto flex justify-center flex-col items-center relative select-none">
            <Wheel
                afterCollectClick={() => {
                    openCollectDialog();
                }}
                onResult={handleClose}
            />
            <img src={wheelCover} className="w-[416px] absolute top-[120px] left-0 pointer-events-none" />
            <div className="text-lg font-bold text-[#5EFB65]">{$t({id: "SpinPromoWord"})}</div>
            <img src={partnerIcons} alt="" className="w-[408px] object-contain" />
        </div>
    );
};

export const openRoll = () => {
    const widget = portal({
        content: (
            <Dialog
                handleClose={() => {
                    widget.close();
                }}
            />
        ),
        style: {width: "600px", height: "549px"},
        mobileStyle: {width: "calc(100vw - 24px)", height: "auto"},
        closeStyle: {zIndex: 99, right: "80px"},
        closeIconStyle: {width: "30px", height: "30px"},
        bgStyle: {background: "rgba(14,18,36,.7)"},
    });
};
