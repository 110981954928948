import React, {useState, useEffect} from "react";
import SvgIcon from "./SvgIcon";

interface Props {
    total: number;
    pageSize?: number;
    initialPage: number;
    onChange?: (page: number, pageSize: number) => void;
}

const Pagination: React.FC<Props> = ({total, pageSize = 10, initialPage, onChange}) => {
    const [page, setPage] = useState(initialPage);
    const [pageCount, setPageCount] = useState(pageSize);
    const totalPages = Math.ceil(total / pageSize);
    const previousnextDisabled = page <= 1;
    const nextDisabled = page >= totalPages;

    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(1);

    useEffect(() => {
        if (totalPages <= 5) {
            setStartPage(1);
            setEndPage(totalPages);
        } else {
            if (page <= 3) {
                setStartPage(1);
                setEndPage(5);
            } else if (page + 1 >= totalPages) {
                setStartPage(totalPages - 4);
                setEndPage(totalPages);
            } else {
                setStartPage(page - 2);
                setEndPage(page + 2);
            }
        }
    }, [page, totalPages]);

    const previous = () => {
        const p = page - 1;
        setPage(p);
        onChange(p, pageCount);
    };
    const next = () => {
        const p = page + 1;
        setPage(p);
        onChange(p, pageCount);
    };
    const handlePageChange = (p: number) => {
        setPage(p);
        onChange(p, pageCount);
    };

    const pageDots = endPage - startPage + 1;
    return (
        <div className="inline-flex gap-4 justify-between items-center h-6">
            <button disabled={previousnextDisabled} onClick={previous}>
                <SvgIcon dataKey="icon-arrowleft" className="fill-[#9DB8C6] w-5 cursor-pointer" />
            </button>
            {Array.from({length: pageDots}).map((_, pIndex) => {
                const currentPage = startPage + pIndex;
                if (page === currentPage) {
                    return <button className="text-white bg-[#007CEF] rounded-[10rem] w-5 h-5 flex items-center justify-center text-sm">{currentPage}</button>;
                }
                return (
                    <button className="text-[#9DB8C6] w-5 h-5 flex items-center justify-center text-sm" onClick={() => handlePageChange(currentPage)}>
                        {currentPage}
                    </button>
                );
            })}
            <button disabled={nextDisabled} onClick={next}>
                <SvgIcon dataKey="icon-arrowright" className="fill-[#9DB8C6] w-5 cursor-pointer" />
            </button>
        </div>
    );
};

export default Pagination;
