import stake_loading from "assets/images/stake_loading.gif";

const Loading = () => {
    return (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-[#1a2c38]">
            <img src={stake_loading} className="w-[200px]" />
        </div>
    );
};

export default Loading;
