import {useEffect, ReactElement, useCallback} from "react";
import {useLocation} from "react-router-dom";
import {useAppDispatch} from "store";
import {setIsMobile} from "store/slices/config";

// ==============================|| NAVIGATION SCROLL TO TOP ||============================== //

const NavigationScroll = ({children}: {children: ReactElement | null}) => {
    const {pathname} = useLocation();
    const dispatch = useAppDispatch();

    const handleResize = useCallback(() => {
        dispatch(setIsMobile(innerWidth <= 640));
    }, [dispatch]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("load", handleResize);
        };
    }, [handleResize]);

    useEffect(() => {
        const mainElement = document.querySelector("main");
        if (mainElement) {
            mainElement.scrollTop = 0;
        }
        // mainElement.scrollTo({
        //     top: 0,
        //     behavior: "smooth",
        // });
    }, [pathname]);

    return children || null;
};

export default NavigationScroll;
