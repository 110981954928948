import client from "./client";

const getUserCheckInData = (data = {}) => client.post("/getUserCheckInData", data);

const getCheckInRewards = (data = {}) => client.post("/getCheckInRewards", data);

const getVipCashbackData = (data = {}) => client.post("/getVipCashbackData", data);

const getVipCashbackRewards = (data = {}) => client.post("/getVipCashbackRewards", data);

const apiVipCheck = {
    getUserCheckInData,
    getCheckInRewards,
    getVipCashbackData,
    getVipCashbackRewards,
};

export default apiVipCheck;
