import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import apiMessage from "api/message";
import {RootState} from "store";

interface MessageCount {
    msgtype: number;
    count: number;
}

export interface MessageState {
    messageCount: MessageCount[];
}

const initialState: MessageState = {
    messageCount: [],
};

export const getUserMessageCountList = createAsyncThunk("user/getUserMessageCountList", async () => {
    const {status, data} = await apiMessage.getUserMessageCountList();
    if (status === 200 && data.code === 0) {
        return data.data;
    }
});

export const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserMessageCountList.fulfilled, (state, action) => {
            state.messageCount = action.payload || [];
        });
    },
});

export const messageCount = (state: RootState) => state.message.messageCount?.map((x) => x.count).reduce((a, b) => a + b, 0);

export default messageSlice.reducer;
