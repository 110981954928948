import {useState} from "react";
import {useNavigate} from "react-router-dom";
import * as Popover from "@radix-ui/react-popover";
import AnimateButton from "ui-component/AnimateButton";
import SvgIcon from "ui-component/SvgIcon";
import {dispatch} from "store";
import {showDialog} from "store/slices/dialog";
import {useIntl} from "react-intl";
import useLiveChat, {showLiveChat} from "hooks/useLiveChat";
import {WalletType} from "types/config";
import {setWalletType} from "store/slices/config";

const User = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {$t} = useIntl();
    const navigate = useNavigate();
    useLiveChat();

    const tabList = [
        {
            iconKey: "icon-coins",
            title: $t({id: "Wallet"}),
            clickHandler: () => {
                dispatch(showDialog("wallet"));
                dispatch(setWalletType(WalletType.DEPOSIT));
            },
        },
        {
            iconKey: "icon-list",
            title: $t({id: "Transactions"}),
            clickHandler: () => {
                navigate("/transactions");
            },
        },
        // {
        //     iconKey: "icon-vault",
        //     title: $t({id: "Vault"}),
        //     clickHandler: () => {
        //         navigate("/");
        //     },
        // },
        // {
        //     iconKey: "icon-trophy",
        //     title: $t({id: "VIP Club"}),
        //     clickHandler: () => {
        //         navigate("/vip-club");
        //     },
        // },
        // {
        //     iconKey: "icon-trophy",
        //     title: $t({id: "Refer & Earn"}),
        //     clickHandler: () => {
        //         navigate("/");
        //     },
        // },
        // {
        //     iconKey: "icon-user",
        //     title: $t({id: "User Information"}),
        //     clickHandler: () => {
        //         navigate("/");
        //     },
        // },
        // {
        //     iconKey: "icon-affiliate",
        //     title: $t({id: "Affiliate"}),
        //     clickHandler: () => {
        //         navigate("affiliate/overview");
        //     },
        // },
        // {
        //     iconKey: "icon-stats",
        //     title: $t({id: "Statistics"}),
        //     clickHandler: () => {
        //         navigate("/");
        //     },
        // },
        // {
        //     iconKey: "icon-sports",
        //     title: $t({id: "SportBets"}),
        //     clickHandler: () => {
        //         navigate("/");
        //     },
        // },
        // {
        //     iconKey: "icon-verify",
        //     title: $t({id: "StakeSafe"}),
        //     clickHandler: () => {
        //         navigate("/");
        //     },
        // },
        {
            iconKey: "icon-support",
            title: $t({id: "LiveSupport"}),
            clickHandler: showLiveChat,
        },
        {
            iconKey: "icon-gear",
            title: $t({id: "Settings"}),
            clickHandler: () => {
                navigate("/settings/general");
            },
        },
        {
            iconKey: "icon-exit",
            title: $t({id: "Logout"}),
            clickHandler: () => {
                dispatch(showDialog("logout"));
            },
        },
    ];

    return (
        <div>
            <div className="w-full h-full">
                <Popover.Root
                    onOpenChange={(open) => {
                        setIsOpen(open);
                    }}
                    open={isOpen}>
                    <Popover.Trigger>
                        <div className="w-full h-full flex items-center p-3 cursor-pointer">
                            <AnimateButton>
                                <SvgIcon dataKey="icon-user" style={{fill: "#ffffff", width: 14, height: 14}} />
                            </AnimateButton>
                        </div>
                    </Popover.Trigger>
                    <Popover.Portal>
                        <Popover.Content className="bg-white rounded overflow-hidden z-[101]" sideOffset={10}>
                            <Popover.Arrow className="fill-white" />
                            <div className="w-full h-full flex flex-col text-[#2f4553] font-semibold py-1">
                                <div>
                                    {tabList.map((v, key) => {
                                        return (
                                            <div key={key}>
                                                <AnimateButton>
                                                    <div
                                                        className="w-full h-10 px-3 flex items-center cursor-pointer hover:bg-[#b1bad3] transition-all duration-200"
                                                        onClick={() => {
                                                            v.clickHandler && v.clickHandler();
                                                            setIsOpen(false);
                                                        }}>
                                                        <SvgIcon dataKey={v.iconKey} className="mr-2" style={{fill: "#2f4553", width: 14, height: 14}} />
                                                        <span className="text-sm font-bold"> {v.title}</span>
                                                    </div>
                                                </AnimateButton>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
        </div>
    );
};

export default User;
