import {useState, useEffect, useMemo} from "react";
import CurrencyList from "./currencyList";
import SvgIcon from "ui-component/SvgIcon";
import {useAppSelector} from "store";
import {getCurrencyIcon, toLocaleNumber} from "utils/help";
import * as Popover from "@radix-ui/react-popover";
import AnimateButton from "ui-component/AnimateButton";
import {CurrencyType} from "types/config";
import notice from "assets/crypto/notice.png";
import apiWallet from "api/wallet";
import {useIntl} from "react-intl";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {dispatch} from "store";
import {showDialog} from "store/slices/dialog";
import useAllow from "hooks/useAllow";

const Withdraw = () => {
    const {$t} = useIntl();
    const navigate = useNavigate();
    const {allowWithdraw} = useAllow();
    const isMobile = useAppSelector((state) => state.config.isMobile);
    const platformConfig = useAppSelector((state) => state.config.platformConfig);
    const turnoverData = useAppSelector((state) => state.user.turnoverData);
    const [currentCurrency, setCurrentCurrency] = useState(null);
    const [isNetworkOpen, setIsNetworkOpen] = useState(false);
    const [networkList, setNetworkList] = useState([]);
    const [selNetwork, setSelNetwork] = useState("");
    const [address, setAddress] = useState("");
    const [amount, setAmount] = useState(0);

    const currencyChangeHandler = (selCurrency) => {
        setCurrentCurrency(selCurrency);
    };

    useEffect(() => {
        if (currentCurrency) {
            console.log(currentCurrency, "currentCurrency");
            const list = currentCurrency.payChannel.split("/");
            setNetworkList(list);
            setSelNetwork(list[0]);
        }
    }, [currentCurrency]);

    const turnoverDict = useMemo(() => {
        return turnoverData
            .map((x) => {
                const maxDigits = platformConfig.currencyList.find((c) => c?.tokenId === x?.type)?.categoryId === CurrencyType.Fiat ? 2 : 5;
                return {
                    type: x.type,
                    amount: toLocaleNumber(x.amount, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: maxDigits,
                        useGrouping: true,
                    }),
                    wager: toLocaleNumber(x.wager, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: maxDigits,
                        useGrouping: true,
                    }),
                };
            })
            .reduce((pre, cur) => {
                pre[cur.type] = cur;
                return pre;
            }, Object.create(null));
    }, [turnoverData]);

    const feeAmount = useMemo(() => {
        if (currentCurrency) {
            const c = platformConfig.payProviderList.find((x) => x.tokenId === currentCurrency.tokenId && x.providerName === selNetwork);
            if (!c) {
                return 0;
            }
            return Math.max(c.minWithdrawGasAmount, c.gasWithdraw * Number(amount)).toFixed(2);
        }
    }, [platformConfig.payProviderList, currentCurrency, selNetwork, amount]);

    const cryptoMinAmount = useMemo(() => {
        if (currentCurrency) {
            const c = platformConfig.payProviderList.find((x) => x.tokenId === currentCurrency.tokenId && x.providerName === selNetwork);
            if (!c) {
                return 0;
            }
            return c.minWithdraw;
        }
        return 0;
    }, [platformConfig.payProviderList, currentCurrency, selNetwork]);

    useEffect(() => {
        setAmount(cryptoMinAmount);
    }, [cryptoMinAmount]);

    const checkWithdrawResult = (res: {code: number; data: string}) => {
        if (res.code === 7) {
            toast.error("WithdrawError_7");
            return;
        }
        if (res.code === -1) {
            toast.success("WithdrawSuccessMsg");
            return;
        }
        if (res.code === 0) {
            try {
                const jsonData: {result: string; errorcode: number} = JSON.parse(res.data);
                if (jsonData.errorcode === 0) {
                    toast.success(`Withdraw success`);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        }

        toast.error("Withdraw failed");
    };

    const withdrawHandler = async () => {
        const {status, data} = await apiWallet.withDrawByCrypto(currentCurrency.tokenId, selNetwork, Number(amount), address);
        if (status === 200) {
            checkWithdrawResult(data);
            return;
        }
    };

    const goTransactions = () => {
        dispatch(showDialog(null));
        navigate("/transactions/withdrawals");
    };

    const hasTurnover = () =>
        Number(turnoverDict[currentCurrency.tokenId]?.wager.replace(/,/g, "")) < Number(turnoverDict[currentCurrency.tokenId]?.amount.replace(/,/g, ""));

    if (!allowWithdraw) {
        return (
            <div className="bg-[#203743] rounded p-3 flex flex-col items-center mx-6 mt-4">
                <div>
                    <img src={notice} alt="" className="w-28 object-contain" />
                </div>
                <div className="text-[#9DB8C6] text-xs font-bold text-center my-6">
                    Please complete account kyc verification first before withdrawing funds.
                </div>
                <button
                    onClick={() => {
                        dispatch(showDialog(null));
                        navigate("/settings/general");
                    }}
                    className="w-full bg-[#007CEF] rounded-[5px] h-12 text-base text-white font-bold cursor-pointer">
                    Verify at once
                </button>
            </div>
        );
    }

    return (
        <div className="text-sm pt-3 sm:pt-4">
            {!currentCurrency && (
                <>
                    <div className="text-base text-white font-bold">{$t({id: "WithdrawOptions"})}</div>
                    <div className="w-full mt-2">
                        <CurrencyList currencyChange={currencyChangeHandler} type="withdraw" />
                    </div>
                </>
            )}
            {currentCurrency && (
                <>
                    <div>
                        <div className="flex items-center">
                            <SvgIcon
                                dataKey="icon-back1"
                                className="fill-[#B1BAD2] w-4 h-4 mr-1 cursor-pointer"
                                onClick={() => {
                                    setCurrentCurrency(null);
                                }}
                            />
                            <img src={getCurrencyIcon(currentCurrency.tokenId)} alt="" className="w-5 h-5" />
                            <div className="ml-1 text-white text-base font-bold">
                                {$t({id: "Withdraw"})}&nbsp;{currentCurrency.tokenName}
                            </div>
                            {!isMobile && (
                                <div className="flex-1 text-end text-[#5EFB65] text-sm font-normal cursor-pointer" onClick={goTransactions}>
                                    {$t({id: "ViewTransactions"})}
                                </div>
                            )}
                        </div>
                        {isMobile && (
                            <div className="mt-3 text-end text-[#5EFB65] text-sm font-normal" onClick={goTransactions}>
                                {$t({id: "ViewTransactions"})}
                            </div>
                        )}
                    </div>
                    {hasTurnover() && (
                        <div className="bg-[#FF6056] rounded flex justify-center items-center w-full h-6 mt-4">
                            <SvgIcon dataKey="icon-warn" className="fill-white w-4 h-4 mr-1 cursor-pointer" />
                            <div className="text-xs text-white font-bold">{`You still need ${toLocaleNumber(
                                (turnoverDict[currentCurrency.tokenId]?.amount.replace(/,/g, "") || 0) -
                                    (turnoverDict[currentCurrency.tokenId]?.wager.replace(/,/g, "") || 0),
                                {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2,
                                    useGrouping: true,
                                },
                            )} ${currentCurrency.tokenName} valid turnover to withdraw money`}</div>
                        </div>
                    )}
                    <div className="w-full relative">
                        {hasTurnover() && <div className="absolute left-0 top-0 w-full h-full bg-[#203743] opacity-50 z-10"></div>}
                        <div className="w-full bg-[#203743] rounded h-9 mt-2 sm:mt-4 px-2 sm:px-3 py-1 flex items-center justify-between">
                            <div className="text-white text-sm font-semibold">{$t({id: "ChooseNetwork"})}</div>
                            <div className="w-40 sm:w-52 h-[1.625rem] bg-[#0E212E] rounded">
                                <div className="w-full h-full">
                                    <Popover.Root
                                        onOpenChange={(open) => {
                                            setIsNetworkOpen(open);
                                        }}
                                        open={isNetworkOpen}>
                                        <Popover.Trigger className="w-full h-full">
                                            <div className="w-full flex items-center justify-between px-2 cursor-pointer">
                                                <div className="text-[#9DB8C6] text-sm font-normal uppercase">{selNetwork}</div>
                                                <SvgIcon dataKey="icon-down1" className="w-3 h-3" />
                                            </div>
                                        </Popover.Trigger>
                                        <Popover.Portal>
                                            <Popover.Content className="bg-[#304553] rounded overflow-hidden z-[101] w-full" style={{width: "100%"}}>
                                                <Popover.Arrow className="fill-[#304553]" />
                                                <div className="w-40 sm:w-52 flex flex-col gap-3 text-white font-semibold p-3">
                                                    {networkList.map((v, key) => {
                                                        return (
                                                            <div
                                                                key={key}
                                                                className="w-full h-5 flex items-center cursor-pointer"
                                                                onClick={() => {
                                                                    setSelNetwork(v);
                                                                    setIsNetworkOpen(false);
                                                                }}>
                                                                {v}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Popover.Content>
                                        </Popover.Portal>
                                    </Popover.Root>
                                </div>
                            </div>
                        </div>
                        <div className="w-full bg-[#203743] rounded mt-2 p-2 sm:p-3">
                            <div>
                                <div className="text-white text-sm font-semibold">
                                    {$t({id: "WithdrawAmount"})}{" "}
                                    <span className="ml-1 text-[#9DB8C6] text-xs font-normal">{$t({id: "MinWithdraw"}, {A: cryptoMinAmount})}</span>
                                </div>
                                <div className="w-full mt-2">
                                    <div className="flex-1 h-8 sm:h-10 bg-[#0E212E] rounded flex items-center pl-2 flex-shrink-0">
                                        <img src={getCurrencyIcon(currentCurrency.tokenId)} alt="" className="w-5 h-5 flex-shrink-0" />
                                        <input
                                            type="text"
                                            className="px-2 flex-1 text-white text-sm font-semibold bg-transparent outline-none min-w-0 h-full"
                                            value={amount}
                                            onChange={(e) => {
                                                if (/^[0-9]*$/.test(e.target.value)) {
                                                    setAmount(Number(e.target.value));
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="mt-2 text-[#9DB8C6] text-sm font-normal">
                                        {$t({id: "NetworkFee"})}&nbsp;{feeAmount}
                                        {currentCurrency.tokenName}
                                    </div>
                                    <div className="text-[#5EFB65] text-xs font-normal">
                                        {$t({id: "ActualReceived"})}&nbsp;{Math.max(0, Number(amount) - Number(feeAmount)).toFixed(2)}
                                        {currentCurrency.tokenName}
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mt-2">
                                <div className="text-[#9DB8C6] text-xs font-normal">{$t({id: "PleaseEnterWalletAddress"}, {A: currentCurrency.tokenName})}</div>
                            </div>

                            <div className="mt-4">
                                <div className="text-white text-sm font-semibold">{$t({id: "ReceivingAddress"})}</div>
                                <div className="mt-2 w-full h-8 sm:h-10 flex justify-between">
                                    <input
                                        type="text"
                                        className="flex-1 h-full bg-[#0E212E] rounded flex items-center pl-2 text-white text-sm font-bold min-w-0 outline-none"
                                        value={address}
                                        placeholder={$t({id: "InputWalletAddressHere"}, {A: currentCurrency.tokenName})}
                                        onChange={(e) => {
                                            setAddress(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div
                                    className="w-full h-12 mt-2 text-white text-base font-bold rounded bg-[#007CEF] flex items-center justify-center cursor-pointer"
                                    onClick={withdrawHandler}>
                                    <AnimateButton>
                                        {$t({id: "Request"})}&nbsp;{$t({id: "Withdrawal"})}
                                    </AnimateButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Withdraw;
