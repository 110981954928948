import client from "./client";
const promotionHost = window.$config.promotion_url;

const getActInfo = () => client.post("/wheelpdd/getPddActInfo");

const getShareNumbers = () => client.post("/wheelpdd/getShareNumbers");
const claimReward = () => client.post("/wheelpdd/claimPddReward");
const getRewardRecord = (params: {pageCount: number; pageId: number}) => client.post("/wheelpdd/queryPddRewardRecord", params);
const getWheelData = () => client.get(`${promotionHost}/api/cash-wheel/data`);
const playRoll = (params: {wheel_id: number; version: string}) => client.post(`${promotionHost}/api/cash-wheel/play`, params);

const apiWheel = {
    getActInfo,
    getWheelData,
    playRoll,
    claimReward,
    getRewardRecord,
    getShareNumbers,
};

export default apiWheel;
