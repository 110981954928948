import React, {useEffect, useMemo, useState, CSSProperties} from "react";
import {portal} from "ui-component/Modal";
// import {useAppSelector} from "store";
import "styles/roulette.scss";
import AnimateButton from "ui-component/AnimateButton";
import {getCurrencyIcon, getEnumKeyByEnumValue} from "utils/help";
import Progress, {AnimateValue} from "./components/progress";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {MoneyType} from "utils/constants";
import {ActInfo} from "types/roulette";
import apiWheel from "api/wheel";
import {ReportTable} from "./ReportTable";
import {dispatch} from "store";
import {setPddActStatus} from "store/slices/user";
import lose from "assets/roulette/lose.png";

const customStyle: CSSProperties & {
    "--start": string;
    "--stop": string;
} = {
    "--start": "#FBFF39",
    "--stop": "#FF8A00",
};

const CollectDialog: React.FC<{handleClose: () => void}> = ({handleClose}) => {
    const {$t} = useIntl();
    const navigate = useNavigate();
    const [pddActInfo, setPddActInfo] = useState<ActInfo>({
        batchNo: "",
        leftTime: 0,
        rewardAmount: 0,
        targetAmount: 0,
        tokenId: 0,
    });
    useEffect(() => {
        const fetchActInfo = async () => {
            const res = await apiWheel.getActInfo();
            if (res?.data?.code === 0 && res?.data?.data) {
                setPddActInfo(res?.data?.data);
            }
        };
        fetchActInfo();
    }, []);

    const stillAmount = useMemo(() => {
        if (pddActInfo?.rewardAmount && pddActInfo?.targetAmount) {
            return Math.max(0, pddActInfo.targetAmount - pddActInfo.rewardAmount).toFixed(2);
        }
        return 0;
    }, [pddActInfo.rewardAmount, pddActInfo.targetAmount]);

    const processPercentage = useMemo(() => {
        if (pddActInfo?.rewardAmount && pddActInfo?.targetAmount) {
            return Math.min(parseFloat((pddActInfo.rewardAmount / pddActInfo.targetAmount).toFixed(4)), 1);
        }
        return 0;
    }, [pddActInfo.rewardAmount, pddActInfo.targetAmount]);

    const currencyName = useMemo(() => {
        if (pddActInfo?.tokenId) {
            return getEnumKeyByEnumValue(MoneyType, pddActInfo.tokenId);
        }
        return "";
    }, [pddActInfo.tokenId]);

    return (
        <div className="w-full h-full bg-[#1A2C38] rounded-lg sm:px-6 px-4 -mt-4 pb-8">
            <div className="py-2">
                <div
                    data-content={"Congratulations on good luck,"}
                    className="shadowContent uppercase glory_text font-bold text-base"
                    style={{
                        ...customStyle,
                        textShadow: "-2px 2px 0px rgba(0, 0, 0, 0.25)",
                    }}>
                    Congratulations on good luck,
                </div>
                <div
                    data-content={"You Won !"}
                    className="shadowContent uppercase glory_text font-bold text-base"
                    style={{
                        ...customStyle,
                        textShadow: "-2px 2px 0px rgba(0, 0, 0, 0.25)",
                    }}>
                    You Won !
                </div>
            </div>
            <div className="flex flex-col rounded-md bg-[#203743] px-2 py-5 items-center mt-3">
                <div className="flex gap-2 items-center">
                    <img src={getCurrencyIcon(pddActInfo.tokenId || 1)} className="w-5 h-5" alt="" />
                    <div className="text-2xl font-bold text-white">
                        <AnimateValue value={pddActInfo.rewardAmount} during={500} /> {currencyName}
                    </div>
                </div>
                <div className="w-full px-4 py-3">
                    <Progress value={processPercentage} />
                </div>
                <div className="font-bold text-sm text-white mt-3">
                    {$t(
                        {id: "WithdrawReq"},
                        {
                            num: (
                                <span className="text-[#5EFB65]">
                                    {stillAmount} {currencyName}
                                </span>
                            ),
                        },
                    )}
                </div>
                <AnimateButton>
                    <div
                        className="mt-6 w-[190px] h-11 text-white flex items-center justify-center cursor-pointer bg-[#1475e1] rounded hover:bg-[#105eb4]"
                        style={{transition: "background-color 0.1s ease-in-out"}}
                        onClick={() => {
                            handleClose();
                            navigate("/roulette");
                        }}>
                        {$t({id: "ClaimMore"})}
                    </div>
                </AnimateButton>
            </div>
            <ReportTable actInfo={pddActInfo} length={3} />
        </div>
    );
};

const WinDialog: React.FC<{handleClose: () => void; amount: number; tokenId: number; handleBtnClick: () => void}> = ({
    handleClose,
    amount,
    tokenId,
    handleBtnClick,
}) => {
    const {$t} = useIntl();
    const currencyName = useMemo(() => {
        if (tokenId) {
            return getEnumKeyByEnumValue(MoneyType, tokenId);
        }
        return "";
    }, [tokenId]);

    return (
        <div className="w-full h-full bg-[#1A2C38] rounded-b-lg sm:px-6 px-4 pb-8">
            <div className="flex flex-col justify-center items-center">
                {amount > 0 ? (
                    <>
                        <div
                            data-content={"Congratulations!"}
                            className="shadowContent uppercase glory_text font-bold text-2xl"
                            style={{
                                ...customStyle,
                                textShadow: "-2px 2px 0px rgba(0, 0, 0, 0.25)",
                            }}>
                            Congratulations!
                        </div>
                        <div className="white_text">You Won !</div>
                        <div className="glory_board pt-8">
                            <div className="glory_text2 text-[48px] font-extrabold pt-6">
                                <AnimateValue value={amount} during={500} />
                            </div>
                            <div className="glory_text2 text-[32px] font-extrabold -mt-4">{currencyName}</div>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            data-content={"What a paity"}
                            className="grayShadow uppercase glory_text font-bold text-2xl"
                            style={{
                                ...customStyle,
                                textShadow: "-2px 2px 0px rgba(0, 0, 0, 0.25)",
                            }}>
                            What a paity
                        </div>
                        <div className="uppercase text-white font-bold text-2xl" style={{textShadow: "-2px 2px 0px rgba(0, 0, 0, 0.25)"}}>
                            You lose ！
                        </div>
                        <img src={lose} className="w-32 h-32" />
                    </>
                )}
                <AnimateButton>
                    <div
                        className="mt-4 w-[190px] h-11 text-white flex items-center justify-center cursor-pointer bg-[#1475e1] rounded hover:bg-[#105eb4]"
                        style={{transition: "background-color 0.1s ease-in-out"}}
                        onClick={() => {
                            handleClose();
                            handleBtnClick();
                        }}>
                        {$t({id: "Collect"})}
                    </div>
                </AnimateButton>
            </div>
        </div>
    );
};

const InviTitle = () => {
    const {$t} = useIntl();
    return <div className="sm:text-[1.125rem] text-base font-bold text-white">{$t({id: "InvitationRoulette"})}</div>;
};
export const openWinDialog = ({amount, tokenId, handleBtnClick}: {amount: number; tokenId: number; handleBtnClick: () => void}) => {
    const widget = portal({
        title: <div className="sm:text-[1.125rem] text-base font-bold"></div>,
        content: (
            <WinDialog
                handleClose={() => {
                    widget.close();
                }}
                amount={amount}
                tokenId={tokenId}
                handleBtnClick={handleBtnClick}
            />
        ),
        onCancel: () => {
            // this dialog only show after Wheel play, need set user.pddEnable
            dispatch(setPddActStatus(1));
        },
        style: {width: "366px"},
        mobileStyle: {width: "calc(100vw - 24px)", height: "auto"},
        bgStyle: {background: "rgba(14,18,36,.7)"},
    });
};

export const openCollectDialog = () => {
    const widget = portal({
        title: <InviTitle />,
        content: (
            <CollectDialog
                handleClose={() => {
                    widget.close();
                }}
            />
        ),
        onCancel: () => {
            // this dialog only show after Wheel play, need set user.pddEnable
            dispatch(setPddActStatus(1));
        },
        style: {width: "366px"},
        mobileStyle: {width: "calc(100vw - 24px)", height: "auto"},
        bgStyle: {background: "rgba(14,18,36,.7)"},
    });
};
