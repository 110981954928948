import I18NMgr, { Languages } from "./I18NMgr";
import * as PIXI from "pixi.js";
import { IDestroyOptions } from "pixi.js";

export default class I18NSprite extends PIXI.Sprite {
    private frames:PIXI.Texture[] = [];
    
    constructor(textures:string[]) {
        super(PIXI.Texture.from(textures[I18NMgr.Instance.Langs]));
        textures.forEach(t => this.frames.push(PIXI.Texture.from(t)));
        let lang = I18NMgr.Instance.Langs;
        this.texture = this.frames[lang];
        I18NMgr.Instance.add(this);
    }

    public destroy(options?: IDestroyOptions | boolean): void {
        super.destroy(options);
        I18NMgr.Instance.remove(this);
    }

    refresh(lang:Languages){
        this.texture = this.frames[lang];
    }
}
