import React, {useEffect, useState} from "react";
import {portal} from "ui-component/Modal";
import SvgIcon from "ui-component/SvgIcon";
import noData from "assets/lottery/noData.png";
import AnimateButton from "ui-component/AnimateButton";
import dayjs from "dayjs";
import Bets from "views/transactions/components/bets";
import {createSignal, useReaction} from "utils/reaction";
import Tab from "ui-component/Tab";
import Loader from "ui-component/Loader";
import apiLottery from "api/lottery";
import {useAppSelector} from "store";
import {CurrencyType} from "types/config";
import {CurrencySymbol} from "utils/constants";
import Table, {Cols} from "ui-component/Table";
import Pagination from "ui-component/Pagination";
import LoadMore from "ui-component/LoadMore";

export const [isOpenMyBets, setIsOpenMyBets] = createSignal(false);

const TableBets: React.FC<{activeId: string | number}> = ({activeId}) => {
    const [dataList, setDataList] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const userIsLegalCurrency = useAppSelector((state) => state.user.userIsLegalCurrency);
    const userMainToken = useAppSelector((state) => state.user.userMainToken);
    const formatAmount = (objParam: any, amount: number) => {
        const categroy = objParam?.categroy;
        if (categroy === CurrencyType.Fiat) {
            return CurrencySymbol[objParam.tokenId] && userIsLegalCurrency ? CurrencySymbol[objParam.tokenId] + amount.toFixed(2) : amount.toFixed(2);
        }
        return CurrencySymbol[userMainToken] && userIsLegalCurrency ? CurrencySymbol[userMainToken] + amount.toFixed(8) : amount.toFixed(8);
    };
    const getLotteryBets = async (pageIndex) => {
        try {
            setIsLoading(true);
            const {status, data} = await apiLottery.getLotteryBethis({page: pageIndex, per_page: 7});
            if (status === 200 && data.code === 0) {
                setDataList(data.data.bets);
                setTotal(data.data.total * 7);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (activeId === 2) {
            getLotteryBets(1);
        }
    }, [activeId]);

    const columns: Cols[] = [
        {
            title: <span className="text-gray-200">Block</span>,
            dataIndex: "block",
            align: "left",
        },
        {
            title: <span className="text-gray-200">Time</span>,
            dataIndex: "create_time",
            render: (text) => <span className="text-gray-200 text-[0.875rem]">{dayjs(text).format("h:m A")}</span>,
            align: "center",
        },
        {
            title: <span className="text-gray-200">Reward</span>,
            dataIndex: "reward",
            align: "center",
        },
        {
            title: <span className="text-gray-200">Status</span>,
            dataIndex: "status",
            align: "right",
        },
        {
            title: <span className="text-gray-200">Number</span>,
            dataIndex: "number",
            align: "right",
        },
    ];
    const handlePage = (page: number, pageSize: number) => {
        getLotteryBets(page);
    };
    return (
        <div className="relative pb-12">
            <Table
                pageSize={7}
                isLoading={isLoading}
                renderNoData={() => (
                    <div className="flex justify-center items-center h-[22rem]">
                        <img src={noData} alt="" className="w-[12rem] object-contain" />
                    </div>
                )}
                dataSource={dataList}
                columns={columns}
                rowClassName={(record, index) => (!(index % 2) ? "bg-gray-500" : "")}
                headerCls="bg-[#0E212E]"
            />
            {total > 0 && (
                <div className="fixed bottom-3 left-0 w-full flex justify-center">
                    <Pagination initialPage={1} total={total} pageSize={7} onChange={handlePage} />
                </div>
            )}
        </div>
    );
};

const NormalBets: React.FC<{activeId: string | number}> = ({activeId}) => {
    const [dataList, setDataList] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const getLotteryBets = async (pageIndex) => {
        setPage(pageIndex);
        try {
            setIsLoading(true);
            const {status, data} = await apiLottery.getLotteryBethis({page: pageIndex, per_page: 5});
            if (status === 200 && data.code === 0) {
                setDataList((l) => [...l, ...data.data.bets]);
                setTotal(data.data.total);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (activeId === 2) {
            getLotteryBets(1);
        }
    }, [activeId]);
    if (isLoading) {
        return (
            <div className="absolute top-36 left-0 bottom-0 right-0 bg-gray-900 opacity-80">
                <div className="absolute bottom-[50%] left-[50%]">
                    <Loader />
                </div>
            </div>
        );
    }
    if (!dataList.length) {
        return (
            <div className="flex justify-center items-center h-full">
                <img src={noData} alt="" className="w-[12rem] object-contain" />
            </div>
        );
    }
    return (
        <div className="flex flex-col gap-4">
            {dataList.map((item) => (
                <div className="bg-[#203743] rounded-[0.25rem] px-4 pb-4">
                    <div className="flex justify-between items-center h-10">
                        <div className="text-sm font-normal text-white">{item.block}</div>
                        <div className="text-sm font-normal text-[#9DB8C6]">{dayjs(item.create_time).format("YYYY/MM/DD HH:mm:ss")}</div>
                    </div>
                    <div className="bg-[#192C38] rounded-[0.25rem] flex py-4">
                        <div className="flex-grow flex flex-col items-center gap-4">
                            <div className="text-sm font-normal text-[#B1BAD2]">Reward</div>
                            <div className="text-sm font-normal text-white">{item.reward}</div>
                        </div>
                        <div className="flex-grow flex flex-col items-center gap-4">
                            <div className="text-sm font-normal text-[#B1BAD2]">Number</div>
                            <div className="text-sm font-normal text-[#5EFB65]">+{item.number}</div>
                        </div>
                    </div>
                </div>
            ))}
            <div>
                <LoadMore hasMore={total > page} nextPage={() => getLotteryBets(page + 1)} />
            </div>
        </div>
    );
};

const MyBets: React.FC<{onClose: () => void; defaultActiveId?: number | string}> = ({onClose, defaultActiveId = 2}) => {
    const isMobile = useAppSelector((state) => state.config.isMobile);
    const [activeId, setActiveId] = useState<string | number>(defaultActiveId);
    const tabList = [
        {title: () => <div className="sm:w-36 w-32 flex justify-center items-center">Casino</div>, id: 1},
        {title: () => <div className="sm:w-36 w-32 flex justify-center items-center">Lottery</div>, id: 2},
    ];
    const openMyBets = useOpenMyBets();
    useEffect(() => {
        if (!openMyBets) {
            onClose();
            setIsOpenMyBets(false);
        }
    }, [openMyBets]);
    return (
        <div className="relative bg-[#1A2C38] w-full h-full flex flex-col rounded-[0.5rem]">
            {!isMobile && (
                <button className="absolute right-0 top-0 w-[3rem] h-[3rem] flex justify-center items-center" onClick={onClose}>
                    <AnimateButton>
                        <SvgIcon dataKey="icon-cross" className="hover:fill-white fill-[#b1bad3] w-4 h-4" />
                    </AnimateButton>
                </button>
            )}
            <div className="flex sm:justify-center justify-start items-center h-[3rem] text-[1.125rem] font-bold text-white mt-2 px-4">My Bets</div>
            {/*
            <div className="flex sm:justify-center justify-start px-4 mb-4">
                <Tab
                    defaultActiveId={activeId}
                    tabList={tabList}
                    onChange={(activeId) => {
                        setActiveId(activeId);
                    }}
                />
            </div>
            */}
            <div className="w-full px-4 flex-1 overflow-auto">
                {activeId === 1 ? <Bets /> : isMobile ? <NormalBets activeId={activeId} key={activeId} /> : <TableBets activeId={activeId} key={activeId} />}
            </div>
        </div>
    );
};

export const openMyBets = (activeId?: string | number) => {
    setTimeout(() => setIsOpenMyBets(true));
    const modal = portal({
        content: <MyBets onClose={() => modal.close()} defaultActiveId={activeId} />,
        style: {width: "736px", height: "612px"},
        mobileStyle: {width: "100vw", height: "100%"},
        bgStyle: {background: "rgba(14,18,36,.7)"},
        mobileBgStyle: {background: "#0f212e", top: "60px", bottom: "67px", height: "auto"},
        closeStyle: {display: "none"},
        mobileFullHeight: true,
    });
};

export const useOpenMyBets = () => useReaction(() => isOpenMyBets());
