// third-party
import {combineReducers} from "redux";

// project imports
import configReducer from "./slices/config";
import userReducer from "./slices/user";
import dialogReducer from "./slices/dialog";
import gameReducer from "./slices/game";
import messageReducer from "./slices/message";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    config: configReducer,
    user: persistReducer(
        {
            key: "user",
            storage,
            keyPrefix: "stake-",
        },
        userReducer,
    ),
    message: messageReducer,
    dialog: dialogReducer,
    game: gameReducer,
});

export default reducer;
