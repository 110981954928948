import React from "react";
import logo from "assets/roulette/roulette_logo.png";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "store";
import {openRoll} from "views/roulette/RollDialog";

const RoulettePanel: React.FC<{open: boolean; onClose?: () => void}> = ({open, onClose}) => {
    const navigate = useNavigate();
    const showPddRoll = useAppSelector((state) => state.user.user?.pddActEnable);
    const isRoulette = location.pathname.includes("/roulette");
    return (
        <div className={`${open ? "px-2" : ""} mb-5 sm:mt-0 mt-2`} onClick={onClose}>
            <div
                onClick={() => {
                    if (showPddRoll === 0 && !isRoulette) {
                        openRoll();
                        return;
                    }
                    navigate("/roulette");
                }}
                style={{background: open ? "linear-gradient(90deg, rgba(246, 118, 0, 0.64) 0%, rgba(246, 167, 48, 0.00) 105.53%)" : ""}}
                className="flex items-center rounded sm:h-9 h-12 relative cursor-pointer">
                {open && <span className="text-sm font-bold text-white sm:ml-16 ml-24">Invitation Roulette</span>}
                <img
                    src={logo}
                    alt=""
                    className={`absolute top-1/2 ${open ? "left-2" : "left-0"} -translate-y-1/2 sm:w-[3.125rem] w-[3.875rem] object-contain`}
                />
            </div>
        </div>
    );
};

export default RoulettePanel;
