import {ReactElement} from "react";

export type GuardProps = {
    children: ReactElement | null;
};

export enum MoneyType {
    USDT = 1,
    ETH = 2,
    BTC = 3,
    IB = 4,
    GB = 5,
    BRL = 6,
    TRX = 7,
    JPY = 8,
    NGN = 9,
    USD = 10,
    BNB = 11,
    INR = 12,
    PHP = 13,
    TT = 14,
    IDR = 15,
}

export enum MessageType {
    PING = 3,
    PING_REPLY = 4,
    CLOSE = 5,
    PLATFORM_MESSAGE = 6,

    PLAYER_LOGIN_REQ = 100,
    PLAYER_LOGIN_REPLY = 101,
}
