import SampleLog from "../Common/SampleLog";

export interface IWsMsgHandler{
    onopen:(ev:Event)=>void;
    onmessage:(ev:MessageEvent)=>void;
    onclose:(ev:Event)=>void;
    onerror:(ev:Event)=>void;
};

export class WebSocketCore{
    url:string = null;
    ws:WebSocket = null;
    handler:IWsMsgHandler = null;

    connect(url:string)
    {
        this.url = url;
        if(this.ws) this.ws.close();
        this.ws = null;
        this.ws = new WebSocket(this.url);
        this.ws.onopen = (event:Event)=>{
            this.handler && this.handler.onopen(event);
        }

        this.ws.onmessage = (message:MessageEvent)=>{
            this.handler && this.handler.onmessage(message);
        }

        this.ws.onclose = (closeEvent:Event)=>{
            this.handler && this.handler.onclose(closeEvent);
        }

        this.ws.onerror = (event:Event)=>{
            this.handler && this.handler.onerror(event);
        }
    }

    close()
    {
        if(!!this.ws) 
        {
            try {
                this.ws.close();
                delete this.ws;
                this.ws = null;
            } catch (error) {
                
            }

            this.ws = null;
            this.url = null;
        }
    }

    send(msg:string | ArrayBufferLike | Blob | ArrayBufferView)
    {
        if(null == this.ws 
            || WebSocket.CONNECTING == this.ws.readyState
            || WebSocket.CLOSED == this.ws.readyState 
            || WebSocket.CLOSING == this.ws.readyState)
        {
            SampleLog.Warn("####WebSocketCore: ws is not valid, please check the websocket status");
            return;
        }

        this.ws.send(msg)
    }

    registerMsgHandler(handler:IWsMsgHandler)
    {
        if(!handler) return;
        this.removeMsgHandler();
        this.handler = handler;
    }

    removeMsgHandler()
    {
        this.handler = null;
    }
}