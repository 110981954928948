export const InvalidTokenCode = 98;

export const ShutdownCode = 100;

export enum CurrencyType {
    Crypto = 1,
    Fiat = 2,
    Platform = 3,
}

export enum MoneyType {
    USDT = 1,
    ETH = 2,
    BTC = 3,
    IB = 4,
    GB = 5,
    BRL = 6,
    TRX = 7,
    JPY = 8,
    NGN = 9,
    USD = 10,
    BNB = 11,
    INR = 12,
    PHP = 13,
    TT = 14,
    IDR = 15,
    MYR = 26,
    GHC = 27,
    VND = 28,
    CAD = 29,
    MXN = 30,
    BONUS = 45,
}

export const CurrencySymbol = {
    0: "$",
    1: "$",
    2: "$",
    3: "$",
    4: "$",
    5: "$",
    6: "R$", //BR
    7: "$",
    8: "￥", //JYP
    9: "₦", //NGN
    10: "$",
    11: "$",
    12: "₹", //INR
    13: "₱", //Php
    14: "$",
    15: "Rp", //IDR
};

export const backdropVariants = {
    hidden: {opacity: 0},
    visible: {opacity: 1},
};

export const modalVariants = {
    hidden: {y: "10%", opacity: 0},
    visible: {y: "0", opacity: 1, transition: {duration: 0.1}},
};

// eslint-disable-next-line no-useless-escape
export const EmailExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
