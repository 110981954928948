import client from "./client";

const getTypeGameList = (data: object) => client.post("/getGameList", data);
const getCollectList = (data: object) => client.post("/getCollectList", data);
const getPlayGameHistoryList = (data: object) => client.post("/getPlayGameHistoryList", data);

const getGameByIdApiFn = (id: number) =>
    client.post("/getGameById", {
        gameId: id,
    });

const getCollectStatus = (gameId: number, langId: number) => client.post("/getGameIsCollectById", {gameId, langId});

const addCollect = (gameId: number, langId: number) => client.post("/addGameCollection", {gameId, langId});

const removeCollect = (gameId: number, langId: number) => client.post("/removeGameCollection", {gameId, langId});

const casinoGame = {
    getTypeGameList,
    getGameByIdApiFn,
    getCollectStatus,
    addCollect,
    removeCollect,
    getCollectList,
    getPlayGameHistoryList,
};

export default casinoGame;
