// routing
import Routes from "routes";
import {useAppSelector} from "store";

import useInitApp from "hooks/useInitApp";
import useInitData from "hooks/useInitData";

// project imports
import Locales from "ui-component/Locales";
import NavigationScroll from "layout/NavigationScroll";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "ui-component/Dialog";
import NiceModal from "ui-component/Modal";
import MaintainPage from "MaintainPage";

// ==============================|| APP ||============================== //

const App = () => {
    const currentDialogID = useAppSelector((state) => state.dialog.currentDialogID);
    const maintainTime = useAppSelector((state) => state.config.maintainTime);

    useInitApp();
    useInitData();

    if (maintainTime > 0) {
        return (
            <Locales>
                <NavigationScroll>
                    <MaintainPage />
                </NavigationScroll>
            </Locales>
        );
    }

    return (
        <Locales>
            <NavigationScroll>
                <>
                    <Routes />
                    <ToastContainer
                        position="top-left"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    <Dialog isOpen={!!currentDialogID}></Dialog>
                    <NiceModal />
                </>
            </NavigationScroll>
        </Locales>
    );
};

export default App;
