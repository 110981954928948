import {createSignal} from "utils/reaction";

export interface LatestBetOrder {
    userid: number;
    gameid: number;
    type: number;
    in: number;
    out: number;
    multiple: number;
    time: number;
    rid: number;
    orderid: string;
    username: string;
    gamename: string;
}

export const [latestBetOrder, setLatestBetOrder] = createSignal<LatestBetOrder | null>(null);
