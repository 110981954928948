import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import AnimateButton from "ui-component/AnimateButton";
import SvgIcon from "ui-component/SvgIcon";
import {useAppSelector, dispatch} from "store";
import {showDialog, closeDialog} from "store/slices/dialog";
import {logout} from "store/slices/user";
import {useIntl} from "react-intl";

const Logout = () => {
    const {$t} = useIntl();
    const navigate = useNavigate();
    return (
        <div className="bg-[#1a2c38] text-[#b1bad3] rounded-lg relative">
            <div
                className="p-4 flex items-center justify-between cursor-pointer hover:fill-white"
                onClick={() => {
                    dispatch(showDialog(null));
                }}>
                <div className="flex justify-start items-center">
                    <AnimateButton>
                        <SvgIcon dataKey="icon-exit" className="hover:fill-white fill-[#b1bad3] mr-[0.5rem]" style={{width: 16, height: 16}} />
                    </AnimateButton>
                    <span className="text-white font-bold">{$t({id: "Logout"})}</span>
                </div>
                <AnimateButton>
                    <SvgIcon dataKey="icon-close" className="hover:fill-white fill-[#b1bad3]" style={{width: 16, height: 16}} />
                </AnimateButton>
            </div>
            <div className="px-4 pb-4 grid grid-flow-row gap-4">
                <div className="text-[0.875rem] font-semibold">{$t({id: "LogoutConfirmDesc"})}</div>
                <AnimateButton>
                    <div
                        className="w-full bg-red-500 hover:bg-red-600 text-white py-4 px-5 text-center leading-none rounded-[0.25rem] cursor-pointer"
                        style={{boxShadow: "0rem .0625rem .1875rem #00000033 , 0rem .0625rem .125rem #0000001f;"}}
                        onClick={() => {
                            dispatch(logout());
                            navigate("/");
                            dispatch(closeDialog());
                        }}>
                        {$t({id: "Logout"})}
                    </div>
                </AnimateButton>
            </div>
        </div>
    );
};

export default Logout;
