import apiAuth from "api/auth";
import {showLiveChat} from "hooks/useLiveChat";
import {useState} from "react";
import {useInterval} from "react-use";
import {useAppSelector} from "store";
import shutdownIcon from "assets/home/shutdown.png";
import logo from "assets/images/logo.png";
import SvgIcon from "ui-component/SvgIcon";
import share_1 from "assets/share/icon_telegram.png";
import share_3 from "assets/share/icon_discord.png";
import share_6 from "assets/share/icon_twitter.png";
import {secondFormat} from "utils/help";

const MaintainPage = () => {
    const maintainTime = useAppSelector((state) => state.config.maintainTime);
    const isMobile = useAppSelector((state) => state.config.isMobile);
    const [leftTime, setLeftTime] = useState(maintainTime);

    useInterval(() => {
        setLeftTime((t) => t - 1);
    }, 1000);

    useInterval(async () => {
        const {status, data} = await apiAuth.getWebSocketUrl();
        if (status === 200 && data.code === 0) {
            window.location.reload();
        }
    }, 60000);

    if (isMobile) {
        return (
            <div className="w-full h-full fixed top-0 bottom-0 left-0 bg-[#192C38] flex flex-col justify-center items-center">
                <img src={shutdownIcon} alt="" className="w-[310px] h-[310px]" />
                <img src={logo} alt="" className="h-14 object-contain mt-8" />
                <div className="mt-8 text-[#98A7B5] text-base font-bold">
                    Site upgrading, launching in
                    <b className="ml-1 text-white inline-block font-black text-xl">{leftTime > 0 ? secondFormat(leftTime) : "00:00:00"}</b>
                </div>
                <div className="mt-2 text-[#98A7B5] text-base font-bold">Thank you for your support and patience!</div>
                <div className="cursor-pointer mt-12 bg-[#2F4553] flex items-center justify-center rounded-[5px] w-[200px] h-14" onClick={showLiveChat}>
                    <SvgIcon dataKey="icon_Support" className="w-[30px]" style={{fill: "#5EFB65"}} />
                    <span className="ml-[10px] text-[#9DB8C6] text-lg">Live Support</span>
                </div>
                <div className="mt-8 flex">
                    <a className="w-10" href="https://t.me/a9gamechannel" target="_blank" rel="noopener noreferrer">
                        <img src={share_1} alt="telegram" />
                    </a>
                    <a className="w-10 ml-6" href="https://discord.com/invite/C3Umswbgd3" target="_blank" rel="noopener noreferrer">
                        <img src={share_3} alt="discord" />
                    </a>
                    <a className="w-10 ml-6" href="https://twitter.com/A9GameOfficial" target="_blank" rel="noopener noreferrer">
                        <img src={share_6} alt="twitter" />
                    </a>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full h-full fixed top-0 bottom-0 left-0 bg-[#192C38] flex justify-center items-center">
            <img src={shutdownIcon} alt="" className="w-[400px] h-[400px]" />
            <div className="ml-10">
                <img src={logo} alt="" className="h-14 object-contain" />
                <div className="mt-8 text-[#98A7B5] text-xl font-bold">
                    Site upgrading, launching in
                    <b className="ml-1 text-white inline-block font-black text-3xl w-[120px]">{leftTime > 0 ? secondFormat(leftTime) : "00:00:00"}</b>
                </div>
                <div className="mt-3 text-[#98A7B5] text-xl font-bold">Thank you for your support and patience!</div>
                <div className="cursor-pointer mt-12 bg-[#2F4553] flex items-center justify-center rounded-[5px] w-[200px] h-14" onClick={showLiveChat}>
                    <SvgIcon dataKey="icon_Support" className="w-[30px]" style={{fill: "#5EFB65"}} />
                    <span className="ml-[10px] text-[#9DB8C6] text-lg">Live Support</span>
                </div>
                <div className="mt-8 flex">
                    <a className="w-10" href="https://t.me/a9gamechannel" target="_blank" rel="noopener noreferrer">
                        <img src={share_1} alt="telegram" />
                    </a>
                    <a className="w-10 ml-6" href="https://discord.com/invite/C3Umswbgd3" target="_blank" rel="noopener noreferrer">
                        <img src={share_3} alt="discord" />
                    </a>
                    <a className="w-10 ml-6" href="https://twitter.com/A9GameOfficial" target="_blank" rel="noopener noreferrer">
                        <img src={share_6} alt="twitter" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default MaintainPage;
