import {FC, useCallback, useEffect, useState} from "react";
import PddWheelApp from "game-source/src/PddWheel/PddWheelApp";
import {PostMessageData} from "types/game";
import apiWheel from "api/wheel";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";
import {dispatch, useAppSelector} from "store";
import {showDialog} from "store/slices/dialog";
import {LoginType} from "types/dialog";
import {openWinDialog} from "./WinsDialog";
import {setPddActStatus} from "store/slices/user";

const Wheel: FC<{
    afterCollectClick: () => void; // 第二个弹窗点击collect按钮执行
    onResult: (amount: number) => void; //出结果是即执行
    onNoCountClick?: () => void; //无次数点击
}> = ({afterCollectClick, onResult, onNoCountClick}) => {
    const {$t} = useIntl();
    const isLogin = useAppSelector((state) => state.user.isLogin);
    const [loadCompleted, setLoadCompleted] = useState(false);
    const [wheelData, setWheelData] = useState(null);
    const [tokenId, setTokenId] = useState(1);
    const [onPlay, setOnPlay] = useState(false);
    const gameId = 9000;

    const postMessage = (data: PostMessageData) => {
        const myEvent = new CustomEvent("pageMessage", {detail: {...data, gameId}});
        window.dispatchEvent(myEvent);
    };

    const fetchWheelData = async () => {
        const res = await apiWheel.getWheelData();
        if (res?.data?.code === 0) {
            setWheelData(res.data.data);
        } else {
            toast.error($t({id: `WHEEL_ERROR_${res.data.code}`}));
        }
    };

    useEffect(() => {
        if (loadCompleted) {
            fetchWheelData();
        }
    }, [loadCompleted, isLogin]);

    useEffect(() => {
        if (wheelData) {
            postMessage({
                action: "wheelData",
                param: wheelData,
            });
        }
    }, [wheelData]);

    useEffect(() => {
        if (onPlay) {
            setOnPlay(false);
            if (!isLogin) {
                dispatch(showDialog(LoginType.SIGNIN));
                postMessage({
                    action: "spinFail",
                    param: {},
                });
                return;
            }
            const playWheelData = async () => {
                const res = await apiWheel.playRoll({
                    wheel_id: wheelData.wheel_id,
                    version: wheelData.version,
                });
                if (res?.data?.code === 0) {
                    const playRollData: {
                        award_id: number;
                        count: number;
                        currency_id: number;
                        wheel_id: number;
                        wheel_type_id: number;
                    } = res.data.data;
                    setTokenId(playRollData?.currency_id || 1);
                    postMessage({
                        action: "spinWheelReply",
                        param: playRollData,
                    });
                } else {
                    postMessage({
                        action: "spinFail",
                        param: {},
                    });
                    toast.error($t({id: `WHEEL_ERROR_${res.data.code}`}));
                    // wheel config changed
                    if (res?.data?.code === 2005) {
                        fetchWheelData();
                    }
                }
            };
            playWheelData();
        }
    }, [onPlay, isLogin]);

    const receivedMessage = useCallback(
        async (e) => {
            if (!e || !e.detail) return;
            let msgData: Record<string, any> = {};
            try {
                msgData = e.detail;
                if (!msgData?.action) return;
            } catch {
                return;
            }
            switch (msgData.action) {
                case "inited":
                    postMessage({
                        action: "loadGame",
                        param: {},
                    });
                    break;
                case "loadCompleted":
                    setLoadCompleted(true);
                    break;
                case "spinWheel":
                    setOnPlay(true);
                    break;
                case "noCount":
                    onNoCountClick && onNoCountClick();
                    break;
                case "result":
                    onResult(msgData?.param?.amount || 0);
                    dispatch(setPddActStatus(1));
                    openWinDialog({
                        amount: msgData?.param?.amount || 0,
                        tokenId,
                        handleBtnClick: afterCollectClick,
                    });
                    break;

                default:
                    break;
            }
        },
        [afterCollectClick, onNoCountClick, onResult, tokenId],
    );

    useEffect(() => {
        let app;
        setTimeout(() => {
            app = new PddWheelApp();
            app.start();
        }, 0);
        return () => {
            app.release();
        };
    }, []);

    useEffect(() => {
        window.addEventListener("gameMessage", receivedMessage);
        return () => {
            window.removeEventListener("gameMessage", receivedMessage);
        };
    }, [receivedMessage]);

    return <canvas id="PddWheel" width="410" height="402" className="w-full" />;
};

export default Wheel;
