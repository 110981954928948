/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

/**
 * ERROR_CODE enum.
 * @exports ERROR_CODE
 * @enum {number}
 * @property {number} NONE=0 NONE value
 * @property {number} TOKEN_INVALID=100 TOKEN_INVALID value
 * @property {number} PLAYER_NOT_FOUND=101 PLAYER_NOT_FOUND value
 * @property {number} REPLACE=102 REPLACE value
 * @property {number} KICK=103 KICK value
 * @property {number} GAME_ID_INVALID=200 GAME_ID_INVALID value
 * @property {number} ROOM_ID_INVALID=201 ROOM_ID_INVALID value
 * @property {number} ALREADY_IN_ROOM=202 ALREADY_IN_ROOM value
 * @property {number} NOT_IN_ROOM=203 NOT_IN_ROOM value
 * @property {number} NO_BALANCE=204 NO_BALANCE value
 * @property {number} CAN_NOT_EXIT=206 CAN_NOT_EXIT value
 * @property {number} OPERATION_INVALID=207 OPERATION_INVALID value
 * @property {number} TABLE_ID_INVALID=209 TABLE_ID_INVALID value
 * @property {number} ENTER_FAIL=210 ENTER_FAIL value
 * @property {number} SERVER_MAINTAIN=211 SERVER_MAINTAIN value
 * @property {number} TABLE_NO_INVALID=213 TABLE_NO_INVALID value
 * @property {number} BALANCE_NOT_ENOUGH=214 BALANCE_NOT_ENOUGH value
 * @property {number} PRIVATE_TABLE_INIT_BET_ERROR=215 PRIVATE_TABLE_INIT_BET_ERROR value
 * @property {number} BET_AMOUNT_LIMIT=216 BET_AMOUNT_LIMIT value
 * @property {number} GAME_REMOVED=217 GAME_REMOVED value
 * @property {number} CURRENCY_LIMIT=218 CURRENCY_LIMIT value
 */
$root.ERROR_CODE = (function() {
    var valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "NONE"] = 0;
    values[valuesById[100] = "TOKEN_INVALID"] = 100;
    values[valuesById[101] = "PLAYER_NOT_FOUND"] = 101;
    values[valuesById[102] = "REPLACE"] = 102;
    values[valuesById[103] = "KICK"] = 103;
    values[valuesById[200] = "GAME_ID_INVALID"] = 200;
    values[valuesById[201] = "ROOM_ID_INVALID"] = 201;
    values[valuesById[202] = "ALREADY_IN_ROOM"] = 202;
    values[valuesById[203] = "NOT_IN_ROOM"] = 203;
    values[valuesById[204] = "NO_BALANCE"] = 204;
    values[valuesById[206] = "CAN_NOT_EXIT"] = 206;
    values[valuesById[207] = "OPERATION_INVALID"] = 207;
    values[valuesById[209] = "TABLE_ID_INVALID"] = 209;
    values[valuesById[210] = "ENTER_FAIL"] = 210;
    values[valuesById[211] = "SERVER_MAINTAIN"] = 211;
    values[valuesById[213] = "TABLE_NO_INVALID"] = 213;
    values[valuesById[214] = "BALANCE_NOT_ENOUGH"] = 214;
    values[valuesById[215] = "PRIVATE_TABLE_INIT_BET_ERROR"] = 215;
    values[valuesById[216] = "BET_AMOUNT_LIMIT"] = 216;
    values[valuesById[217] = "GAME_REMOVED"] = 217;
    values[valuesById[218] = "CURRENCY_LIMIT"] = 218;
    return values;
})();

/**
 * MSG_TYPE enum.
 * @exports MSG_TYPE
 * @enum {number}
 * @property {number} DEFAULT=0 DEFAULT value
 * @property {number} CONNECTED=1 CONNECTED value
 * @property {number} UNLOGIN=2 UNLOGIN value
 * @property {number} PING=3 PING value
 * @property {number} PONG=4 PONG value
 * @property {number} CLOSE=5 CLOSE value
 * @property {number} SEND_COMMAND=6 SEND_COMMAND value
 * @property {number} SERVER_AUTH=10 SERVER_AUTH value
 * @property {number} SYNC_ONLINE_STATUS=11 SYNC_ONLINE_STATUS value
 * @property {number} SYNC_PLAYER_BALANCE=12 SYNC_PLAYER_BALANCE value
 * @property {number} GAME_PLAYER_SETTLED=13 GAME_PLAYER_SETTLED value
 * @property {number} REGISTRY_SYNC_SERVER_DATA=20 REGISTRY_SYNC_SERVER_DATA value
 * @property {number} SYNC_GAME_SERVER_DATA=21 SYNC_GAME_SERVER_DATA value
 * @property {number} SYNC_GAME_STATUS=22 SYNC_GAME_STATUS value
 * @property {number} PLAYER_LOGIN_REQ=100 PLAYER_LOGIN_REQ value
 * @property {number} PLAYER_LOGIN_REPLY=101 PLAYER_LOGIN_REPLY value
 * @property {number} PLAYER_LOGOUT_REQ=102 PLAYER_LOGOUT_REQ value
 * @property {number} PLAYER_LOGOUT_REPLY=103 PLAYER_LOGOUT_REPLY value
 * @property {number} PLAYER_BALANCE_EVENT=104 PLAYER_BALANCE_EVENT value
 * @property {number} PLAYER_BALANCE_REQ=109 PLAYER_BALANCE_REQ value
 * @property {number} PLAYER_INFO_CHANGE=110 PLAYER_INFO_CHANGE value
 * @property {number} PLAYER_CURRENCY_CHECK_REQ=111 PLAYER_CURRENCY_CHECK_REQ value
 * @property {number} PLAYER_CURRENCY_CHECK_REPLY=112 PLAYER_CURRENCY_CHECK_REPLY value
 * @property {number} ROOM_LIST_REQ=200 ROOM_LIST_REQ value
 * @property {number} ROOM_LIST_REPLY=201 ROOM_LIST_REPLY value
 * @property {number} ENTER_ROOM_REQ=202 ENTER_ROOM_REQ value
 * @property {number} ENTER_ROOM_REPLY=203 ENTER_ROOM_REPLY value
 * @property {number} EXIT_ROOM_REQ=204 EXIT_ROOM_REQ value
 * @property {number} EXIT_ROOM_REPLY=205 EXIT_ROOM_REPLY value
 * @property {number} SWITCH_TABLE_REQ=206 SWITCH_TABLE_REQ value
 * @property {number} SWITCH_COIN_REQ=207 SWITCH_COIN_REQ value
 * @property {number} SWITCH_COIN_REPLY=208 SWITCH_COIN_REPLY value
 * @property {number} SYNC_GAME_PAYOUT=209 SYNC_GAME_PAYOUT value
 * @property {number} TABLE_PLAYERS_EVENT=300 TABLE_PLAYERS_EVENT value
 * @property {number} TABLE_TIME_EVENT=301 TABLE_TIME_EVENT value
 * @property {number} GAME_BALANCE_EVENT=302 GAME_BALANCE_EVENT value
 * @property {number} CHAT_EXPRESSION_REQ=303 CHAT_EXPRESSION_REQ value
 * @property {number} CHAT_EXPRESSION_EVENT=304 CHAT_EXPRESSION_EVENT value
 * @property {number} CHAT_VOICE_REQ=305 CHAT_VOICE_REQ value
 * @property {number} CHAT_VOICE_EVENT=306 CHAT_VOICE_EVENT value
 * @property {number} PRIVATE_TABLE_READY_REQ=307 PRIVATE_TABLE_READY_REQ value
 * @property {number} PRIVATE_TABLE_READY_EVENT=308 PRIVATE_TABLE_READY_EVENT value
 * @property {number} GAME_OFFLINE=309 GAME_OFFLINE value
 * @property {number} AB_HISTORY_RESULT_EVENT=4000 AB_HISTORY_RESULT_EVENT value
 * @property {number} AB_GAME_START_EVENT=4001 AB_GAME_START_EVENT value
 * @property {number} AB_STATE_EVENT=4002 AB_STATE_EVENT value
 * @property {number} AB_OPERATION_REQ=4003 AB_OPERATION_REQ value
 * @property {number} AB_OPERATION_EVENT=4004 AB_OPERATION_EVENT value
 * @property {number} AB_DEAL_CARDS_EVENT=4005 AB_DEAL_CARDS_EVENT value
 * @property {number} AB_GAME_END_EVENT=4006 AB_GAME_END_EVENT value
 * @property {number} AB_TOP_LIST_EVENT=4007 AB_TOP_LIST_EVENT value
 * @property {number} AB_ROOM_INFO=4008 AB_ROOM_INFO value
 * @property {number} AB_RANK_PLAYERS_EVEVT=4009 AB_RANK_PLAYERS_EVEVT value
 * @property {number} AB_STATE_EVENT_2=4011 AB_STATE_EVENT_2 value
 * @property {number} SEVEN_HISTORY_EVENT=5000 SEVEN_HISTORY_EVENT value
 * @property {number} SEVEN_STATE_EVENT=5001 SEVEN_STATE_EVENT value
 * @property {number} SEVEN_BET_REQ=5002 SEVEN_BET_REQ value
 * @property {number} SEVEN_BET_EVENT=5003 SEVEN_BET_EVENT value
 * @property {number} SEVEN_TABLE_PLAYERS_EVENT=5004 SEVEN_TABLE_PLAYERS_EVENT value
 * @property {number} SEVEN_TOP_PLAYERS_REQ=5005 SEVEN_TOP_PLAYERS_REQ value
 * @property {number} SEVEN_TOP_PLAYERS_REPLY=5006 SEVEN_TOP_PLAYERS_REPLY value
 * @property {number} SEVEN_GAME_END_EVENT=5007 SEVEN_GAME_END_EVENT value
 * @property {number} SEVEN_GAME_START_EVENT=5008 SEVEN_GAME_START_EVENT value
 * @property {number} SEVEN_BATCH_BET_EVENT=5009 SEVEN_BATCH_BET_EVENT value
 * @property {number} DT_HISTORY_EVENT=5100 DT_HISTORY_EVENT value
 * @property {number} DT_STATE_EVENT=5101 DT_STATE_EVENT value
 * @property {number} DT_BET_REQ=5102 DT_BET_REQ value
 * @property {number} DT_BET_EVENT=5103 DT_BET_EVENT value
 * @property {number} DT_TABLE_PLAYERS_EVENT=5104 DT_TABLE_PLAYERS_EVENT value
 * @property {number} DT_TOP_PLAYERS_REQ=5105 DT_TOP_PLAYERS_REQ value
 * @property {number} DT_TOP_PLAYERS_REPLY=5106 DT_TOP_PLAYERS_REPLY value
 * @property {number} DT_GAME_END_EVENT=5107 DT_GAME_END_EVENT value
 * @property {number} DT_GAME_START_EVENT=5108 DT_GAME_START_EVENT value
 * @property {number} DT_BATCH_BET_EVENT=5109 DT_BATCH_BET_EVENT value
 * @property {number} WINGO_HISTORY_EVENT=5200 WINGO_HISTORY_EVENT value
 * @property {number} WINGO_STATE_EVENT=5201 WINGO_STATE_EVENT value
 * @property {number} WINGO_BET_REQ=5202 WINGO_BET_REQ value
 * @property {number} WINGO_TABLE_PLAYERS_EVENT=5204 WINGO_TABLE_PLAYERS_EVENT value
 * @property {number} WINGO_TOP_PLAYERS_REQ=5205 WINGO_TOP_PLAYERS_REQ value
 * @property {number} WINGO_TOP_PLAYERS_REPLY=5206 WINGO_TOP_PLAYERS_REPLY value
 * @property {number} WINGO_GAME_END_EVENT=5207 WINGO_GAME_END_EVENT value
 * @property {number} WINGO_GAME_START_EVENT=5208 WINGO_GAME_START_EVENT value
 * @property {number} WINGO_BATCH_BET_EVENT=5209 WINGO_BATCH_BET_EVENT value
 * @property {number} FP_HISTORY_EVENT=5300 FP_HISTORY_EVENT value
 * @property {number} FP_STATE_EVENT=5301 FP_STATE_EVENT value
 * @property {number} FP_BET_REQ=5302 FP_BET_REQ value
 * @property {number} FP_TABLE_PLAYERS_EVENT=5304 FP_TABLE_PLAYERS_EVENT value
 * @property {number} FP_TOP_PLAYERS_REQ=5305 FP_TOP_PLAYERS_REQ value
 * @property {number} FP_TOP_PLAYERS_REPLY=5306 FP_TOP_PLAYERS_REPLY value
 * @property {number} FP_GAME_END_EVENT=5307 FP_GAME_END_EVENT value
 * @property {number} FP_GAME_START_EVENT=5308 FP_GAME_START_EVENT value
 * @property {number} FP_BATCH_BET_EVENT=5309 FP_BATCH_BET_EVENT value
 * @property {number} BTP_HISTORY_EVENT=5400 BTP_HISTORY_EVENT value
 * @property {number} BTP_STATE_EVENT=5401 BTP_STATE_EVENT value
 * @property {number} BTP_BET_REQ=5402 BTP_BET_REQ value
 * @property {number} BTP_TABLE_PLAYERS_EVENT=5404 BTP_TABLE_PLAYERS_EVENT value
 * @property {number} BTP_TOP_PLAYERS_REQ=5405 BTP_TOP_PLAYERS_REQ value
 * @property {number} BTP_TOP_PLAYERS_REPLY=5406 BTP_TOP_PLAYERS_REPLY value
 * @property {number} BTP_GAME_END_EVENT=5407 BTP_GAME_END_EVENT value
 * @property {number} BTP_GAME_START_EVENT=5408 BTP_GAME_START_EVENT value
 * @property {number} BTP_BATCH_BET_EVENT=5409 BTP_BATCH_BET_EVENT value
 * @property {number} CRASH_HISTORY_RESULT_EVENT=10001 CRASH_HISTORY_RESULT_EVENT value
 * @property {number} CRASH_GAME_START_EVENT=10002 CRASH_GAME_START_EVENT value
 * @property {number} CRASH_PLAYERS_EVEVT=10003 CRASH_PLAYERS_EVEVT value
 * @property {number} CRASH_STATE_EVENT=10004 CRASH_STATE_EVENT value
 * @property {number} CRASH_BET_REQ=10005 CRASH_BET_REQ value
 * @property {number} CRASH_BET_EVENT=10006 CRASH_BET_EVENT value
 * @property {number} CRASH_CASH_OUT_REQ=10007 CRASH_CASH_OUT_REQ value
 * @property {number} CRASH_PLAYER_LIST_REQ=10008 CRASH_PLAYER_LIST_REQ value
 * @property {number} CRASH_PLAYER_LIST_EVENT=10009 CRASH_PLAYER_LIST_EVENT value
 * @property {number} CRASH_FLY_END_EVENT=10010 CRASH_FLY_END_EVENT value
 * @property {number} CRASH_WIN_EVENT=10011 CRASH_WIN_EVENT value
 * @property {number} CRASH_LOSE_EVENT=10012 CRASH_LOSE_EVENT value
 * @property {number} CRASH_FLY_TICK_EVENT=10013 CRASH_FLY_TICK_EVENT value
 * @property {number} WHEEL_STATE_REQ=10101 WHEEL_STATE_REQ value
 * @property {number} WHEEL_STATE_REPLY=10102 WHEEL_STATE_REPLY value
 * @property {number} WHEEL_RESULT_REQ=10103 WHEEL_RESULT_REQ value
 * @property {number} WHEEL_RESULT_REPLY=10104 WHEEL_RESULT_REPLY value
 * @property {number} RING_STATE_REQ=10201 RING_STATE_REQ value
 * @property {number} RING_STATE_REPLY=10202 RING_STATE_REPLY value
 * @property {number} RING_RESULT_REQ=10203 RING_RESULT_REQ value
 * @property {number} RING_RESULT_REPLY=10204 RING_RESULT_REPLY value
 * @property {number} PLINKO_STATE_REQ=10301 PLINKO_STATE_REQ value
 * @property {number} PLINKO_STATE_REPLY=10302 PLINKO_STATE_REPLY value
 * @property {number} PLINKO_RESULT_REQ=10303 PLINKO_RESULT_REQ value
 * @property {number} PLINKO_RESULT_REPLY=10304 PLINKO_RESULT_REPLY value
 * @property {number} CRYPTOS_STATE_REQ=10401 CRYPTOS_STATE_REQ value
 * @property {number} CRYPTOS_STATE_REPLY=10402 CRYPTOS_STATE_REPLY value
 * @property {number} CRYPTOS_RESULT_REQ=10403 CRYPTOS_RESULT_REQ value
 * @property {number} CRYPTOS_RESULT_REPLY=10404 CRYPTOS_RESULT_REPLY value
 * @property {number} LIMBO_STATE_REQ=10501 LIMBO_STATE_REQ value
 * @property {number} LIMBO_STATE_REPLY=10502 LIMBO_STATE_REPLY value
 * @property {number} LIMBO_RESULT_REQ=10503 LIMBO_RESULT_REQ value
 * @property {number} LIMBO_RESULT_REPLY=10504 LIMBO_RESULT_REPLY value
 * @property {number} MINES_STATE_REQ=10601 MINES_STATE_REQ value
 * @property {number} MINES_STATE_REPLY=10602 MINES_STATE_REPLY value
 * @property {number} MINES_BET_REQ=10603 MINES_BET_REQ value
 * @property {number} MINES_BET_REPLY=10604 MINES_BET_REPLY value
 * @property {number} MINES_OPEN_REQ=10605 MINES_OPEN_REQ value
 * @property {number} MINES_OPEN_REPLY=10606 MINES_OPEN_REPLY value
 * @property {number} MINES_RESULT_REPLY=10607 MINES_RESULT_REPLY value
 * @property {number} MINES_AUTO_REQ=10608 MINES_AUTO_REQ value
 * @property {number} MINES_CASHOUT_REQ=10609 MINES_CASHOUT_REQ value
 * @property {number} HILO_STATE_REQ=10701 HILO_STATE_REQ value
 * @property {number} HILO_STATE_REPLY=10702 HILO_STATE_REPLY value
 * @property {number} HILO_BET_REQ=10703 HILO_BET_REQ value
 * @property {number} HILO_BET_REPLY=10704 HILO_BET_REPLY value
 * @property {number} HILO_GUESS_REQ=10705 HILO_GUESS_REQ value
 * @property {number} HILO_GUESS_REPLY=10706 HILO_GUESS_REPLY value
 * @property {number} HILO_RESULT_REPLY=10707 HILO_RESULT_REPLY value
 * @property {number} HILO_SKIP_REQ=10708 HILO_SKIP_REQ value
 * @property {number} HILO_SKIP_REPLY=10709 HILO_SKIP_REPLY value
 * @property {number} HILO_CASHOUT_REQ=10710 HILO_CASHOUT_REQ value
 * @property {number} DICE_STATE_REQ=10801 DICE_STATE_REQ value
 * @property {number} DICE_STATE_REPLY=10802 DICE_STATE_REPLY value
 * @property {number} DICE_RESULT_REQ=10803 DICE_RESULT_REQ value
 * @property {number} DICE_RESULT_REPLY=10804 DICE_RESULT_REPLY value
 * @property {number} STAIRS_STATE_REQ=10901 STAIRS_STATE_REQ value
 * @property {number} STAIRS_STATE_REPLY=10902 STAIRS_STATE_REPLY value
 * @property {number} STAIRS_BET_REQ=10903 STAIRS_BET_REQ value
 * @property {number} STAIRS_BET_REPLY=10904 STAIRS_BET_REPLY value
 * @property {number} STAIRS_PICK_REQ=10905 STAIRS_PICK_REQ value
 * @property {number} STAIRS_PICK_REPLY=10906 STAIRS_PICK_REPLY value
 * @property {number} STAIRS_RESULT_REPLY=10907 STAIRS_RESULT_REPLY value
 * @property {number} STAIRS_CASHOUT_REQ=10908 STAIRS_CASHOUT_REQ value
 * @property {number} SLOTS1_STATE_REQ=11001 SLOTS1_STATE_REQ value
 * @property {number} SLOTS1_STATE_REPLY=11002 SLOTS1_STATE_REPLY value
 * @property {number} SLOTS1_BET_REQ=11003 SLOTS1_BET_REQ value
 * @property {number} SLOTS1_BET_REPLY=11004 SLOTS1_BET_REPLY value
 * @property {number} COINFLIP_STATE_REQ=11101 COINFLIP_STATE_REQ value
 * @property {number} COINFLIP_STATE_REPLY=11102 COINFLIP_STATE_REPLY value
 * @property {number} COINFLIP_BET_REQ=11103 COINFLIP_BET_REQ value
 * @property {number} COINFLIP_BET_REPLY=11104 COINFLIP_BET_REPLY value
 * @property {number} COINFLIP_GUESS_REQ=11105 COINFLIP_GUESS_REQ value
 * @property {number} COINFLIP_GUESS_REPLY=11106 COINFLIP_GUESS_REPLY value
 * @property {number} COINFLIP_CASH_OUT_REQ=11107 COINFLIP_CASH_OUT_REQ value
 * @property {number} COINFLIP_CASH_OUT_REPLY=11108 COINFLIP_CASH_OUT_REPLY value
 * @property {number} SLOTS2_STATE_REQ=11201 SLOTS2_STATE_REQ value
 * @property {number} SLOTS2_STATE_REPLY=11202 SLOTS2_STATE_REPLY value
 * @property {number} SLOTS2_BET_REQ=11203 SLOTS2_BET_REQ value
 * @property {number} SLOTS2_BET_REPLY=11204 SLOTS2_BET_REPLY value
 * @property {number} VIDEOPOKER_STATE_REQ=11401 VIDEOPOKER_STATE_REQ value
 * @property {number} VIDEOPOKER_STATE_REPLY=11402 VIDEOPOKER_STATE_REPLY value
 * @property {number} VIDEOPOKER_BET_REQ=11403 VIDEOPOKER_BET_REQ value
 * @property {number} VIDEOPOKER_BET_REPLY=11404 VIDEOPOKER_BET_REPLY value
 * @property {number} VIDEOPOKER_DEAL_REQ=11405 VIDEOPOKER_DEAL_REQ value
 * @property {number} VIDEOPOKER_DEAL_REPLY=11406 VIDEOPOKER_DEAL_REPLY value
 * @property {number} KENO_STATE_REQ=11501 KENO_STATE_REQ value
 * @property {number} KENO_STATE_REPLY=11502 KENO_STATE_REPLY value
 * @property {number} KENO_RESULT_REQ=11503 KENO_RESULT_REQ value
 * @property {number} KENO_RESULT_REPLY=11504 KENO_RESULT_REPLY value
 * @property {number} CLASSICDICE_STATE_REQ=11601 CLASSICDICE_STATE_REQ value
 * @property {number} CLASSICDICE_STATE_REPLY=11602 CLASSICDICE_STATE_REPLY value
 * @property {number} CLASSICDICE_RESULT_REQ=11603 CLASSICDICE_RESULT_REQ value
 * @property {number} CLASSICDICE_RESULT_REPLY=11604 CLASSICDICE_RESULT_REPLY value
 * @property {number} RINGOFFORTUNE_STATE_REQ=11701 RINGOFFORTUNE_STATE_REQ value
 * @property {number} RINGOFFORTUNE_STATE_REPLY=11702 RINGOFFORTUNE_STATE_REPLY value
 * @property {number} RINGOFFORTUNE_RESULT_REQ=11703 RINGOFFORTUNE_RESULT_REQ value
 * @property {number} RINGOFFORTUNE_RESULT_REPLY=11704 RINGOFFORTUNE_RESULT_REPLY value
 * @property {number} DOUBLE_HISTORY_RESULT_EVENT=11801 DOUBLE_HISTORY_RESULT_EVENT value
 * @property {number} DOUBLE_GAME_START_EVENT=11802 DOUBLE_GAME_START_EVENT value
 * @property {number} DOUBLE_PLAYERS_EVEVT=11803 DOUBLE_PLAYERS_EVEVT value
 * @property {number} DOUBLE_STATE_EVENT=11804 DOUBLE_STATE_EVENT value
 * @property {number} DOUBLE_BET_REQ=11805 DOUBLE_BET_REQ value
 * @property {number} DOUBLE_BET_EVENT=11806 DOUBLE_BET_EVENT value
 * @property {number} DOUBLE_PLAYER_LIST_REQ=11808 DOUBLE_PLAYER_LIST_REQ value
 * @property {number} DOUBLE_PLAYER_LIST_EVENT=11809 DOUBLE_PLAYER_LIST_EVENT value
 * @property {number} DOUBLE_GAME_END_EVENT=11810 DOUBLE_GAME_END_EVENT value
 * @property {number} DOUBLE_GAME_RESULT_EVENT=11811 DOUBLE_GAME_RESULT_EVENT value
 * @property {number} TOWERLEGEND_STATE_REQ=11901 TOWERLEGEND_STATE_REQ value
 * @property {number} TOWERLEGEND_STATE_REPLY=11902 TOWERLEGEND_STATE_REPLY value
 * @property {number} TOWERLEGEND_BET_REQ=11903 TOWERLEGEND_BET_REQ value
 * @property {number} TOWERLEGEND_BET_REPLY=11904 TOWERLEGEND_BET_REPLY value
 * @property {number} TOWERLEGEND_DEAL_REQ=11905 TOWERLEGEND_DEAL_REQ value
 * @property {number} TOWERLEGEND_DEAL_REPLY=11906 TOWERLEGEND_DEAL_REPLY value
 * @property {number} TOWERLEGEND_RESULT_REPLY=11907 TOWERLEGEND_RESULT_REPLY value
 * @property {number} TOWERLEGEND_CASH_OUT_REQ=11908 TOWERLEGEND_CASH_OUT_REQ value
 * @property {number} TOWERLEGEND_CASH_OUT_REPLY=11909 TOWERLEGEND_CASH_OUT_REPLY value
 * @property {number} ALLSTAR_HISTORY_RESULT_EVENT=12001 ALLSTAR_HISTORY_RESULT_EVENT value
 * @property {number} ALLSTAR_GAME_START_EVENT=12002 ALLSTAR_GAME_START_EVENT value
 * @property {number} ALLSTAR_PLAYERS_EVEVT=12003 ALLSTAR_PLAYERS_EVEVT value
 * @property {number} ALLSTAR_STATE_EVENT=12004 ALLSTAR_STATE_EVENT value
 * @property {number} ALLSTAR_BET_REQ=12005 ALLSTAR_BET_REQ value
 * @property {number} ALLSTAR_BET_EVENT=12006 ALLSTAR_BET_EVENT value
 * @property {number} ALLSTAR_PLAYER_LIST_REQ=12008 ALLSTAR_PLAYER_LIST_REQ value
 * @property {number} ALLSTAR_PLAYER_LIST_EVENT=12009 ALLSTAR_PLAYER_LIST_EVENT value
 * @property {number} ALLSTAR_GAME_END_EVENT=12010 ALLSTAR_GAME_END_EVENT value
 * @property {number} ALLSTAR_GAME_RESULT_EVENT=12011 ALLSTAR_GAME_RESULT_EVENT value
 * @property {number} DRAGONTIGER_HISTORY_RESULT_EVENT=12101 DRAGONTIGER_HISTORY_RESULT_EVENT value
 * @property {number} DRAGONTIGER_GAME_START_EVENT=12102 DRAGONTIGER_GAME_START_EVENT value
 * @property {number} DRAGONTIGER_PLAYERS_EVEVT=12103 DRAGONTIGER_PLAYERS_EVEVT value
 * @property {number} DRAGONTIGER_STATE_EVENT=12104 DRAGONTIGER_STATE_EVENT value
 * @property {number} DRAGONTIGER_BET_REQ=12105 DRAGONTIGER_BET_REQ value
 * @property {number} DRAGONTIGER_BET_EVENT=12106 DRAGONTIGER_BET_EVENT value
 * @property {number} DRAGONTIGER_PLAYER_LIST_REQ=12108 DRAGONTIGER_PLAYER_LIST_REQ value
 * @property {number} DRAGONTIGER_PLAYER_LIST_EVENT=12109 DRAGONTIGER_PLAYER_LIST_EVENT value
 * @property {number} DRAGONTIGER_GAME_END_EVENT=12110 DRAGONTIGER_GAME_END_EVENT value
 * @property {number} DRAGONTIGER_GAME_RESULT_EVENT=12111 DRAGONTIGER_GAME_RESULT_EVENT value
 * @property {number} DRAGONTIGER_BET_FAILED_EVENT=12112 DRAGONTIGER_BET_FAILED_EVENT value
 * @property {number} TRUCO_HISTORY_RESULT_EVENT=12201 TRUCO_HISTORY_RESULT_EVENT value
 * @property {number} TRUCO_GAME_START_EVENT=12202 TRUCO_GAME_START_EVENT value
 * @property {number} TRUCO_PLAYERS_EVEVT=12203 TRUCO_PLAYERS_EVEVT value
 * @property {number} TRUCO_STATE_EVENT=12204 TRUCO_STATE_EVENT value
 * @property {number} TRUCO_BET_REQ=12205 TRUCO_BET_REQ value
 * @property {number} TRUCO_BET_EVENT=12206 TRUCO_BET_EVENT value
 * @property {number} TRUCO_PLAYER_LIST_REQ=12208 TRUCO_PLAYER_LIST_REQ value
 * @property {number} TRUCO_PLAYER_LIST_EVENT=12209 TRUCO_PLAYER_LIST_EVENT value
 * @property {number} TRUCO_GAME_END_EVENT=12210 TRUCO_GAME_END_EVENT value
 * @property {number} TRUCO_GAME_RESULT_EVENT=12211 TRUCO_GAME_RESULT_EVENT value
 * @property {number} TRUCO_BET_FAILED_EVENT=12212 TRUCO_BET_FAILED_EVENT value
 */
$root.MSG_TYPE = (function() {
    var valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "DEFAULT"] = 0;
    values[valuesById[1] = "CONNECTED"] = 1;
    values[valuesById[2] = "UNLOGIN"] = 2;
    values[valuesById[3] = "PING"] = 3;
    values[valuesById[4] = "PONG"] = 4;
    values[valuesById[5] = "CLOSE"] = 5;
    values[valuesById[6] = "SEND_COMMAND"] = 6;
    values[valuesById[10] = "SERVER_AUTH"] = 10;
    values[valuesById[11] = "SYNC_ONLINE_STATUS"] = 11;
    values[valuesById[12] = "SYNC_PLAYER_BALANCE"] = 12;
    values[valuesById[13] = "GAME_PLAYER_SETTLED"] = 13;
    values[valuesById[20] = "REGISTRY_SYNC_SERVER_DATA"] = 20;
    values[valuesById[21] = "SYNC_GAME_SERVER_DATA"] = 21;
    values[valuesById[22] = "SYNC_GAME_STATUS"] = 22;
    values[valuesById[100] = "PLAYER_LOGIN_REQ"] = 100;
    values[valuesById[101] = "PLAYER_LOGIN_REPLY"] = 101;
    values[valuesById[102] = "PLAYER_LOGOUT_REQ"] = 102;
    values[valuesById[103] = "PLAYER_LOGOUT_REPLY"] = 103;
    values[valuesById[104] = "PLAYER_BALANCE_EVENT"] = 104;
    values[valuesById[109] = "PLAYER_BALANCE_REQ"] = 109;
    values[valuesById[110] = "PLAYER_INFO_CHANGE"] = 110;
    values[valuesById[111] = "PLAYER_CURRENCY_CHECK_REQ"] = 111;
    values[valuesById[112] = "PLAYER_CURRENCY_CHECK_REPLY"] = 112;
    values[valuesById[200] = "ROOM_LIST_REQ"] = 200;
    values[valuesById[201] = "ROOM_LIST_REPLY"] = 201;
    values[valuesById[202] = "ENTER_ROOM_REQ"] = 202;
    values[valuesById[203] = "ENTER_ROOM_REPLY"] = 203;
    values[valuesById[204] = "EXIT_ROOM_REQ"] = 204;
    values[valuesById[205] = "EXIT_ROOM_REPLY"] = 205;
    values[valuesById[206] = "SWITCH_TABLE_REQ"] = 206;
    values[valuesById[207] = "SWITCH_COIN_REQ"] = 207;
    values[valuesById[208] = "SWITCH_COIN_REPLY"] = 208;
    values[valuesById[209] = "SYNC_GAME_PAYOUT"] = 209;
    values[valuesById[300] = "TABLE_PLAYERS_EVENT"] = 300;
    values[valuesById[301] = "TABLE_TIME_EVENT"] = 301;
    values[valuesById[302] = "GAME_BALANCE_EVENT"] = 302;
    values[valuesById[303] = "CHAT_EXPRESSION_REQ"] = 303;
    values[valuesById[304] = "CHAT_EXPRESSION_EVENT"] = 304;
    values[valuesById[305] = "CHAT_VOICE_REQ"] = 305;
    values[valuesById[306] = "CHAT_VOICE_EVENT"] = 306;
    values[valuesById[307] = "PRIVATE_TABLE_READY_REQ"] = 307;
    values[valuesById[308] = "PRIVATE_TABLE_READY_EVENT"] = 308;
    values[valuesById[309] = "GAME_OFFLINE"] = 309;
    values[valuesById[4000] = "AB_HISTORY_RESULT_EVENT"] = 4000;
    values[valuesById[4001] = "AB_GAME_START_EVENT"] = 4001;
    values[valuesById[4002] = "AB_STATE_EVENT"] = 4002;
    values[valuesById[4003] = "AB_OPERATION_REQ"] = 4003;
    values[valuesById[4004] = "AB_OPERATION_EVENT"] = 4004;
    values[valuesById[4005] = "AB_DEAL_CARDS_EVENT"] = 4005;
    values[valuesById[4006] = "AB_GAME_END_EVENT"] = 4006;
    values[valuesById[4007] = "AB_TOP_LIST_EVENT"] = 4007;
    values[valuesById[4008] = "AB_ROOM_INFO"] = 4008;
    values[valuesById[4009] = "AB_RANK_PLAYERS_EVEVT"] = 4009;
    values[valuesById[4011] = "AB_STATE_EVENT_2"] = 4011;
    values[valuesById[5000] = "SEVEN_HISTORY_EVENT"] = 5000;
    values[valuesById[5001] = "SEVEN_STATE_EVENT"] = 5001;
    values[valuesById[5002] = "SEVEN_BET_REQ"] = 5002;
    values[valuesById[5003] = "SEVEN_BET_EVENT"] = 5003;
    values[valuesById[5004] = "SEVEN_TABLE_PLAYERS_EVENT"] = 5004;
    values[valuesById[5005] = "SEVEN_TOP_PLAYERS_REQ"] = 5005;
    values[valuesById[5006] = "SEVEN_TOP_PLAYERS_REPLY"] = 5006;
    values[valuesById[5007] = "SEVEN_GAME_END_EVENT"] = 5007;
    values[valuesById[5008] = "SEVEN_GAME_START_EVENT"] = 5008;
    values[valuesById[5009] = "SEVEN_BATCH_BET_EVENT"] = 5009;
    values[valuesById[5100] = "DT_HISTORY_EVENT"] = 5100;
    values[valuesById[5101] = "DT_STATE_EVENT"] = 5101;
    values[valuesById[5102] = "DT_BET_REQ"] = 5102;
    values[valuesById[5103] = "DT_BET_EVENT"] = 5103;
    values[valuesById[5104] = "DT_TABLE_PLAYERS_EVENT"] = 5104;
    values[valuesById[5105] = "DT_TOP_PLAYERS_REQ"] = 5105;
    values[valuesById[5106] = "DT_TOP_PLAYERS_REPLY"] = 5106;
    values[valuesById[5107] = "DT_GAME_END_EVENT"] = 5107;
    values[valuesById[5108] = "DT_GAME_START_EVENT"] = 5108;
    values[valuesById[5109] = "DT_BATCH_BET_EVENT"] = 5109;
    values[valuesById[5200] = "WINGO_HISTORY_EVENT"] = 5200;
    values[valuesById[5201] = "WINGO_STATE_EVENT"] = 5201;
    values[valuesById[5202] = "WINGO_BET_REQ"] = 5202;
    values[valuesById[5204] = "WINGO_TABLE_PLAYERS_EVENT"] = 5204;
    values[valuesById[5205] = "WINGO_TOP_PLAYERS_REQ"] = 5205;
    values[valuesById[5206] = "WINGO_TOP_PLAYERS_REPLY"] = 5206;
    values[valuesById[5207] = "WINGO_GAME_END_EVENT"] = 5207;
    values[valuesById[5208] = "WINGO_GAME_START_EVENT"] = 5208;
    values[valuesById[5209] = "WINGO_BATCH_BET_EVENT"] = 5209;
    values[valuesById[5300] = "FP_HISTORY_EVENT"] = 5300;
    values[valuesById[5301] = "FP_STATE_EVENT"] = 5301;
    values[valuesById[5302] = "FP_BET_REQ"] = 5302;
    values[valuesById[5304] = "FP_TABLE_PLAYERS_EVENT"] = 5304;
    values[valuesById[5305] = "FP_TOP_PLAYERS_REQ"] = 5305;
    values[valuesById[5306] = "FP_TOP_PLAYERS_REPLY"] = 5306;
    values[valuesById[5307] = "FP_GAME_END_EVENT"] = 5307;
    values[valuesById[5308] = "FP_GAME_START_EVENT"] = 5308;
    values[valuesById[5309] = "FP_BATCH_BET_EVENT"] = 5309;
    values[valuesById[5400] = "BTP_HISTORY_EVENT"] = 5400;
    values[valuesById[5401] = "BTP_STATE_EVENT"] = 5401;
    values[valuesById[5402] = "BTP_BET_REQ"] = 5402;
    values[valuesById[5404] = "BTP_TABLE_PLAYERS_EVENT"] = 5404;
    values[valuesById[5405] = "BTP_TOP_PLAYERS_REQ"] = 5405;
    values[valuesById[5406] = "BTP_TOP_PLAYERS_REPLY"] = 5406;
    values[valuesById[5407] = "BTP_GAME_END_EVENT"] = 5407;
    values[valuesById[5408] = "BTP_GAME_START_EVENT"] = 5408;
    values[valuesById[5409] = "BTP_BATCH_BET_EVENT"] = 5409;
    values[valuesById[10001] = "CRASH_HISTORY_RESULT_EVENT"] = 10001;
    values[valuesById[10002] = "CRASH_GAME_START_EVENT"] = 10002;
    values[valuesById[10003] = "CRASH_PLAYERS_EVEVT"] = 10003;
    values[valuesById[10004] = "CRASH_STATE_EVENT"] = 10004;
    values[valuesById[10005] = "CRASH_BET_REQ"] = 10005;
    values[valuesById[10006] = "CRASH_BET_EVENT"] = 10006;
    values[valuesById[10007] = "CRASH_CASH_OUT_REQ"] = 10007;
    values[valuesById[10008] = "CRASH_PLAYER_LIST_REQ"] = 10008;
    values[valuesById[10009] = "CRASH_PLAYER_LIST_EVENT"] = 10009;
    values[valuesById[10010] = "CRASH_FLY_END_EVENT"] = 10010;
    values[valuesById[10011] = "CRASH_WIN_EVENT"] = 10011;
    values[valuesById[10012] = "CRASH_LOSE_EVENT"] = 10012;
    values[valuesById[10013] = "CRASH_FLY_TICK_EVENT"] = 10013;
    values[valuesById[10101] = "WHEEL_STATE_REQ"] = 10101;
    values[valuesById[10102] = "WHEEL_STATE_REPLY"] = 10102;
    values[valuesById[10103] = "WHEEL_RESULT_REQ"] = 10103;
    values[valuesById[10104] = "WHEEL_RESULT_REPLY"] = 10104;
    values[valuesById[10201] = "RING_STATE_REQ"] = 10201;
    values[valuesById[10202] = "RING_STATE_REPLY"] = 10202;
    values[valuesById[10203] = "RING_RESULT_REQ"] = 10203;
    values[valuesById[10204] = "RING_RESULT_REPLY"] = 10204;
    values[valuesById[10301] = "PLINKO_STATE_REQ"] = 10301;
    values[valuesById[10302] = "PLINKO_STATE_REPLY"] = 10302;
    values[valuesById[10303] = "PLINKO_RESULT_REQ"] = 10303;
    values[valuesById[10304] = "PLINKO_RESULT_REPLY"] = 10304;
    values[valuesById[10401] = "CRYPTOS_STATE_REQ"] = 10401;
    values[valuesById[10402] = "CRYPTOS_STATE_REPLY"] = 10402;
    values[valuesById[10403] = "CRYPTOS_RESULT_REQ"] = 10403;
    values[valuesById[10404] = "CRYPTOS_RESULT_REPLY"] = 10404;
    values[valuesById[10501] = "LIMBO_STATE_REQ"] = 10501;
    values[valuesById[10502] = "LIMBO_STATE_REPLY"] = 10502;
    values[valuesById[10503] = "LIMBO_RESULT_REQ"] = 10503;
    values[valuesById[10504] = "LIMBO_RESULT_REPLY"] = 10504;
    values[valuesById[10601] = "MINES_STATE_REQ"] = 10601;
    values[valuesById[10602] = "MINES_STATE_REPLY"] = 10602;
    values[valuesById[10603] = "MINES_BET_REQ"] = 10603;
    values[valuesById[10604] = "MINES_BET_REPLY"] = 10604;
    values[valuesById[10605] = "MINES_OPEN_REQ"] = 10605;
    values[valuesById[10606] = "MINES_OPEN_REPLY"] = 10606;
    values[valuesById[10607] = "MINES_RESULT_REPLY"] = 10607;
    values[valuesById[10608] = "MINES_AUTO_REQ"] = 10608;
    values[valuesById[10609] = "MINES_CASHOUT_REQ"] = 10609;
    values[valuesById[10701] = "HILO_STATE_REQ"] = 10701;
    values[valuesById[10702] = "HILO_STATE_REPLY"] = 10702;
    values[valuesById[10703] = "HILO_BET_REQ"] = 10703;
    values[valuesById[10704] = "HILO_BET_REPLY"] = 10704;
    values[valuesById[10705] = "HILO_GUESS_REQ"] = 10705;
    values[valuesById[10706] = "HILO_GUESS_REPLY"] = 10706;
    values[valuesById[10707] = "HILO_RESULT_REPLY"] = 10707;
    values[valuesById[10708] = "HILO_SKIP_REQ"] = 10708;
    values[valuesById[10709] = "HILO_SKIP_REPLY"] = 10709;
    values[valuesById[10710] = "HILO_CASHOUT_REQ"] = 10710;
    values[valuesById[10801] = "DICE_STATE_REQ"] = 10801;
    values[valuesById[10802] = "DICE_STATE_REPLY"] = 10802;
    values[valuesById[10803] = "DICE_RESULT_REQ"] = 10803;
    values[valuesById[10804] = "DICE_RESULT_REPLY"] = 10804;
    values[valuesById[10901] = "STAIRS_STATE_REQ"] = 10901;
    values[valuesById[10902] = "STAIRS_STATE_REPLY"] = 10902;
    values[valuesById[10903] = "STAIRS_BET_REQ"] = 10903;
    values[valuesById[10904] = "STAIRS_BET_REPLY"] = 10904;
    values[valuesById[10905] = "STAIRS_PICK_REQ"] = 10905;
    values[valuesById[10906] = "STAIRS_PICK_REPLY"] = 10906;
    values[valuesById[10907] = "STAIRS_RESULT_REPLY"] = 10907;
    values[valuesById[10908] = "STAIRS_CASHOUT_REQ"] = 10908;
    values[valuesById[11001] = "SLOTS1_STATE_REQ"] = 11001;
    values[valuesById[11002] = "SLOTS1_STATE_REPLY"] = 11002;
    values[valuesById[11003] = "SLOTS1_BET_REQ"] = 11003;
    values[valuesById[11004] = "SLOTS1_BET_REPLY"] = 11004;
    values[valuesById[11101] = "COINFLIP_STATE_REQ"] = 11101;
    values[valuesById[11102] = "COINFLIP_STATE_REPLY"] = 11102;
    values[valuesById[11103] = "COINFLIP_BET_REQ"] = 11103;
    values[valuesById[11104] = "COINFLIP_BET_REPLY"] = 11104;
    values[valuesById[11105] = "COINFLIP_GUESS_REQ"] = 11105;
    values[valuesById[11106] = "COINFLIP_GUESS_REPLY"] = 11106;
    values[valuesById[11107] = "COINFLIP_CASH_OUT_REQ"] = 11107;
    values[valuesById[11108] = "COINFLIP_CASH_OUT_REPLY"] = 11108;
    values[valuesById[11201] = "SLOTS2_STATE_REQ"] = 11201;
    values[valuesById[11202] = "SLOTS2_STATE_REPLY"] = 11202;
    values[valuesById[11203] = "SLOTS2_BET_REQ"] = 11203;
    values[valuesById[11204] = "SLOTS2_BET_REPLY"] = 11204;
    values[valuesById[11401] = "VIDEOPOKER_STATE_REQ"] = 11401;
    values[valuesById[11402] = "VIDEOPOKER_STATE_REPLY"] = 11402;
    values[valuesById[11403] = "VIDEOPOKER_BET_REQ"] = 11403;
    values[valuesById[11404] = "VIDEOPOKER_BET_REPLY"] = 11404;
    values[valuesById[11405] = "VIDEOPOKER_DEAL_REQ"] = 11405;
    values[valuesById[11406] = "VIDEOPOKER_DEAL_REPLY"] = 11406;
    values[valuesById[11501] = "KENO_STATE_REQ"] = 11501;
    values[valuesById[11502] = "KENO_STATE_REPLY"] = 11502;
    values[valuesById[11503] = "KENO_RESULT_REQ"] = 11503;
    values[valuesById[11504] = "KENO_RESULT_REPLY"] = 11504;
    values[valuesById[11601] = "CLASSICDICE_STATE_REQ"] = 11601;
    values[valuesById[11602] = "CLASSICDICE_STATE_REPLY"] = 11602;
    values[valuesById[11603] = "CLASSICDICE_RESULT_REQ"] = 11603;
    values[valuesById[11604] = "CLASSICDICE_RESULT_REPLY"] = 11604;
    values[valuesById[11701] = "RINGOFFORTUNE_STATE_REQ"] = 11701;
    values[valuesById[11702] = "RINGOFFORTUNE_STATE_REPLY"] = 11702;
    values[valuesById[11703] = "RINGOFFORTUNE_RESULT_REQ"] = 11703;
    values[valuesById[11704] = "RINGOFFORTUNE_RESULT_REPLY"] = 11704;
    values[valuesById[11801] = "DOUBLE_HISTORY_RESULT_EVENT"] = 11801;
    values[valuesById[11802] = "DOUBLE_GAME_START_EVENT"] = 11802;
    values[valuesById[11803] = "DOUBLE_PLAYERS_EVEVT"] = 11803;
    values[valuesById[11804] = "DOUBLE_STATE_EVENT"] = 11804;
    values[valuesById[11805] = "DOUBLE_BET_REQ"] = 11805;
    values[valuesById[11806] = "DOUBLE_BET_EVENT"] = 11806;
    values[valuesById[11808] = "DOUBLE_PLAYER_LIST_REQ"] = 11808;
    values[valuesById[11809] = "DOUBLE_PLAYER_LIST_EVENT"] = 11809;
    values[valuesById[11810] = "DOUBLE_GAME_END_EVENT"] = 11810;
    values[valuesById[11811] = "DOUBLE_GAME_RESULT_EVENT"] = 11811;
    values[valuesById[11901] = "TOWERLEGEND_STATE_REQ"] = 11901;
    values[valuesById[11902] = "TOWERLEGEND_STATE_REPLY"] = 11902;
    values[valuesById[11903] = "TOWERLEGEND_BET_REQ"] = 11903;
    values[valuesById[11904] = "TOWERLEGEND_BET_REPLY"] = 11904;
    values[valuesById[11905] = "TOWERLEGEND_DEAL_REQ"] = 11905;
    values[valuesById[11906] = "TOWERLEGEND_DEAL_REPLY"] = 11906;
    values[valuesById[11907] = "TOWERLEGEND_RESULT_REPLY"] = 11907;
    values[valuesById[11908] = "TOWERLEGEND_CASH_OUT_REQ"] = 11908;
    values[valuesById[11909] = "TOWERLEGEND_CASH_OUT_REPLY"] = 11909;
    values[valuesById[12001] = "ALLSTAR_HISTORY_RESULT_EVENT"] = 12001;
    values[valuesById[12002] = "ALLSTAR_GAME_START_EVENT"] = 12002;
    values[valuesById[12003] = "ALLSTAR_PLAYERS_EVEVT"] = 12003;
    values[valuesById[12004] = "ALLSTAR_STATE_EVENT"] = 12004;
    values[valuesById[12005] = "ALLSTAR_BET_REQ"] = 12005;
    values[valuesById[12006] = "ALLSTAR_BET_EVENT"] = 12006;
    values[valuesById[12008] = "ALLSTAR_PLAYER_LIST_REQ"] = 12008;
    values[valuesById[12009] = "ALLSTAR_PLAYER_LIST_EVENT"] = 12009;
    values[valuesById[12010] = "ALLSTAR_GAME_END_EVENT"] = 12010;
    values[valuesById[12011] = "ALLSTAR_GAME_RESULT_EVENT"] = 12011;
    values[valuesById[12101] = "DRAGONTIGER_HISTORY_RESULT_EVENT"] = 12101;
    values[valuesById[12102] = "DRAGONTIGER_GAME_START_EVENT"] = 12102;
    values[valuesById[12103] = "DRAGONTIGER_PLAYERS_EVEVT"] = 12103;
    values[valuesById[12104] = "DRAGONTIGER_STATE_EVENT"] = 12104;
    values[valuesById[12105] = "DRAGONTIGER_BET_REQ"] = 12105;
    values[valuesById[12106] = "DRAGONTIGER_BET_EVENT"] = 12106;
    values[valuesById[12108] = "DRAGONTIGER_PLAYER_LIST_REQ"] = 12108;
    values[valuesById[12109] = "DRAGONTIGER_PLAYER_LIST_EVENT"] = 12109;
    values[valuesById[12110] = "DRAGONTIGER_GAME_END_EVENT"] = 12110;
    values[valuesById[12111] = "DRAGONTIGER_GAME_RESULT_EVENT"] = 12111;
    values[valuesById[12112] = "DRAGONTIGER_BET_FAILED_EVENT"] = 12112;
    values[valuesById[12201] = "TRUCO_HISTORY_RESULT_EVENT"] = 12201;
    values[valuesById[12202] = "TRUCO_GAME_START_EVENT"] = 12202;
    values[valuesById[12203] = "TRUCO_PLAYERS_EVEVT"] = 12203;
    values[valuesById[12204] = "TRUCO_STATE_EVENT"] = 12204;
    values[valuesById[12205] = "TRUCO_BET_REQ"] = 12205;
    values[valuesById[12206] = "TRUCO_BET_EVENT"] = 12206;
    values[valuesById[12208] = "TRUCO_PLAYER_LIST_REQ"] = 12208;
    values[valuesById[12209] = "TRUCO_PLAYER_LIST_EVENT"] = 12209;
    values[valuesById[12210] = "TRUCO_GAME_END_EVENT"] = 12210;
    values[valuesById[12211] = "TRUCO_GAME_RESULT_EVENT"] = 12211;
    values[valuesById[12212] = "TRUCO_BET_FAILED_EVENT"] = 12212;
    return values;
})();

$root.base = (function() {

    /**
     * Namespace base.
     * @exports base
     * @namespace
     */
    var base = {};

    base.Connected = (function() {

        /**
         * Properties of a Connected.
         * @memberof base
         * @interface IConnected
         * @property {number|Long|null} [nowTime] Connected nowTime
         */

        /**
         * Constructs a new Connected.
         * @memberof base
         * @classdesc Represents a Connected.
         * @implements IConnected
         * @constructor
         * @param {base.IConnected=} [properties] Properties to set
         */
        function Connected(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Connected nowTime.
         * @member {number|Long} nowTime
         * @memberof base.Connected
         * @instance
         */
        Connected.prototype.nowTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Connected instance using the specified properties.
         * @function create
         * @memberof base.Connected
         * @static
         * @param {base.IConnected=} [properties] Properties to set
         * @returns {base.Connected} Connected instance
         */
        Connected.create = function create(properties) {
            return new Connected(properties);
        };

        /**
         * Encodes the specified Connected message. Does not implicitly {@link base.Connected.verify|verify} messages.
         * @function encode
         * @memberof base.Connected
         * @static
         * @param {base.IConnected} message Connected message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Connected.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nowTime != null && Object.hasOwnProperty.call(message, "nowTime"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.nowTime);
            return writer;
        };

        /**
         * Encodes the specified Connected message, length delimited. Does not implicitly {@link base.Connected.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.Connected
         * @static
         * @param {base.IConnected} message Connected message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Connected.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Connected message from the specified reader or buffer.
         * @function decode
         * @memberof base.Connected
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.Connected} Connected
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Connected.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.Connected();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.nowTime = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Connected message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.Connected
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.Connected} Connected
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Connected.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Connected message.
         * @function verify
         * @memberof base.Connected
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Connected.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nowTime != null && message.hasOwnProperty("nowTime"))
                if (!$util.isInteger(message.nowTime) && !(message.nowTime && $util.isInteger(message.nowTime.low) && $util.isInteger(message.nowTime.high)))
                    return "nowTime: integer|Long expected";
            return null;
        };

        /**
         * Creates a Connected message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.Connected
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.Connected} Connected
         */
        Connected.fromObject = function fromObject(object) {
            if (object instanceof $root.base.Connected)
                return object;
            var message = new $root.base.Connected();
            if (object.nowTime != null)
                if ($util.Long)
                    (message.nowTime = $util.Long.fromValue(object.nowTime)).unsigned = false;
                else if (typeof object.nowTime === "string")
                    message.nowTime = parseInt(object.nowTime, 10);
                else if (typeof object.nowTime === "number")
                    message.nowTime = object.nowTime;
                else if (typeof object.nowTime === "object")
                    message.nowTime = new $util.LongBits(object.nowTime.low >>> 0, object.nowTime.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Connected message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.Connected
         * @static
         * @param {base.Connected} message Connected
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Connected.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.nowTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nowTime = options.longs === String ? "0" : 0;
            if (message.nowTime != null && message.hasOwnProperty("nowTime"))
                if (typeof message.nowTime === "number")
                    object.nowTime = options.longs === String ? String(message.nowTime) : message.nowTime;
                else
                    object.nowTime = options.longs === String ? $util.Long.prototype.toString.call(message.nowTime) : options.longs === Number ? new $util.LongBits(message.nowTime.low >>> 0, message.nowTime.high >>> 0).toNumber() : message.nowTime;
            return object;
        };

        /**
         * Converts this Connected to JSON.
         * @function toJSON
         * @memberof base.Connected
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Connected.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Connected;
    })();

    base.ServerAuth = (function() {

        /**
         * Properties of a ServerAuth.
         * @memberof base
         * @interface IServerAuth
         * @property {number|null} [handlerType] ServerAuth handlerType
         * @property {string|null} [sign] ServerAuth sign
         * @property {number|null} [gameId] ServerAuth gameId
         * @property {string|null} [url] ServerAuth url
         * @property {Array.<number>|null} [roomIds] ServerAuth roomIds
         */

        /**
         * Constructs a new ServerAuth.
         * @memberof base
         * @classdesc Represents a ServerAuth.
         * @implements IServerAuth
         * @constructor
         * @param {base.IServerAuth=} [properties] Properties to set
         */
        function ServerAuth(properties) {
            this.roomIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServerAuth handlerType.
         * @member {number} handlerType
         * @memberof base.ServerAuth
         * @instance
         */
        ServerAuth.prototype.handlerType = 0;

        /**
         * ServerAuth sign.
         * @member {string} sign
         * @memberof base.ServerAuth
         * @instance
         */
        ServerAuth.prototype.sign = "";

        /**
         * ServerAuth gameId.
         * @member {number} gameId
         * @memberof base.ServerAuth
         * @instance
         */
        ServerAuth.prototype.gameId = 0;

        /**
         * ServerAuth url.
         * @member {string} url
         * @memberof base.ServerAuth
         * @instance
         */
        ServerAuth.prototype.url = "";

        /**
         * ServerAuth roomIds.
         * @member {Array.<number>} roomIds
         * @memberof base.ServerAuth
         * @instance
         */
        ServerAuth.prototype.roomIds = $util.emptyArray;

        /**
         * Creates a new ServerAuth instance using the specified properties.
         * @function create
         * @memberof base.ServerAuth
         * @static
         * @param {base.IServerAuth=} [properties] Properties to set
         * @returns {base.ServerAuth} ServerAuth instance
         */
        ServerAuth.create = function create(properties) {
            return new ServerAuth(properties);
        };

        /**
         * Encodes the specified ServerAuth message. Does not implicitly {@link base.ServerAuth.verify|verify} messages.
         * @function encode
         * @memberof base.ServerAuth
         * @static
         * @param {base.IServerAuth} message ServerAuth message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerAuth.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.handlerType != null && Object.hasOwnProperty.call(message, "handlerType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.handlerType);
            if (message.sign != null && Object.hasOwnProperty.call(message, "sign"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.sign);
            if (message.gameId != null && Object.hasOwnProperty.call(message, "gameId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.gameId);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.url);
            if (message.roomIds != null && message.roomIds.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (var i = 0; i < message.roomIds.length; ++i)
                    writer.int32(message.roomIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified ServerAuth message, length delimited. Does not implicitly {@link base.ServerAuth.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.ServerAuth
         * @static
         * @param {base.IServerAuth} message ServerAuth message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerAuth.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ServerAuth message from the specified reader or buffer.
         * @function decode
         * @memberof base.ServerAuth
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.ServerAuth} ServerAuth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerAuth.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.ServerAuth();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.handlerType = reader.int32();
                    break;
                case 2:
                    message.sign = reader.string();
                    break;
                case 3:
                    message.gameId = reader.int32();
                    break;
                case 4:
                    message.url = reader.string();
                    break;
                case 5:
                    if (!(message.roomIds && message.roomIds.length))
                        message.roomIds = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.roomIds.push(reader.int32());
                    } else
                        message.roomIds.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ServerAuth message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.ServerAuth
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.ServerAuth} ServerAuth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerAuth.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ServerAuth message.
         * @function verify
         * @memberof base.ServerAuth
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServerAuth.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.handlerType != null && message.hasOwnProperty("handlerType"))
                if (!$util.isInteger(message.handlerType))
                    return "handlerType: integer expected";
            if (message.sign != null && message.hasOwnProperty("sign"))
                if (!$util.isString(message.sign))
                    return "sign: string expected";
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                if (!$util.isInteger(message.gameId))
                    return "gameId: integer expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.roomIds != null && message.hasOwnProperty("roomIds")) {
                if (!Array.isArray(message.roomIds))
                    return "roomIds: array expected";
                for (var i = 0; i < message.roomIds.length; ++i)
                    if (!$util.isInteger(message.roomIds[i]))
                        return "roomIds: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a ServerAuth message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.ServerAuth
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.ServerAuth} ServerAuth
         */
        ServerAuth.fromObject = function fromObject(object) {
            if (object instanceof $root.base.ServerAuth)
                return object;
            var message = new $root.base.ServerAuth();
            if (object.handlerType != null)
                message.handlerType = object.handlerType | 0;
            if (object.sign != null)
                message.sign = String(object.sign);
            if (object.gameId != null)
                message.gameId = object.gameId | 0;
            if (object.url != null)
                message.url = String(object.url);
            if (object.roomIds) {
                if (!Array.isArray(object.roomIds))
                    throw TypeError(".base.ServerAuth.roomIds: array expected");
                message.roomIds = [];
                for (var i = 0; i < object.roomIds.length; ++i)
                    message.roomIds[i] = object.roomIds[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a ServerAuth message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.ServerAuth
         * @static
         * @param {base.ServerAuth} message ServerAuth
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServerAuth.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.roomIds = [];
            if (options.defaults) {
                object.handlerType = 0;
                object.sign = "";
                object.gameId = 0;
                object.url = "";
            }
            if (message.handlerType != null && message.hasOwnProperty("handlerType"))
                object.handlerType = message.handlerType;
            if (message.sign != null && message.hasOwnProperty("sign"))
                object.sign = message.sign;
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                object.gameId = message.gameId;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.roomIds && message.roomIds.length) {
                object.roomIds = [];
                for (var j = 0; j < message.roomIds.length; ++j)
                    object.roomIds[j] = message.roomIds[j];
            }
            return object;
        };

        /**
         * Converts this ServerAuth to JSON.
         * @function toJSON
         * @memberof base.ServerAuth
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServerAuth.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ServerAuth;
    })();

    base.RegistrySyncServerData = (function() {

        /**
         * Properties of a RegistrySyncServerData.
         * @memberof base
         * @interface IRegistrySyncServerData
         * @property {Array.<base.IServerData>|null} [servers] RegistrySyncServerData servers
         */

        /**
         * Constructs a new RegistrySyncServerData.
         * @memberof base
         * @classdesc Represents a RegistrySyncServerData.
         * @implements IRegistrySyncServerData
         * @constructor
         * @param {base.IRegistrySyncServerData=} [properties] Properties to set
         */
        function RegistrySyncServerData(properties) {
            this.servers = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegistrySyncServerData servers.
         * @member {Array.<base.IServerData>} servers
         * @memberof base.RegistrySyncServerData
         * @instance
         */
        RegistrySyncServerData.prototype.servers = $util.emptyArray;

        /**
         * Creates a new RegistrySyncServerData instance using the specified properties.
         * @function create
         * @memberof base.RegistrySyncServerData
         * @static
         * @param {base.IRegistrySyncServerData=} [properties] Properties to set
         * @returns {base.RegistrySyncServerData} RegistrySyncServerData instance
         */
        RegistrySyncServerData.create = function create(properties) {
            return new RegistrySyncServerData(properties);
        };

        /**
         * Encodes the specified RegistrySyncServerData message. Does not implicitly {@link base.RegistrySyncServerData.verify|verify} messages.
         * @function encode
         * @memberof base.RegistrySyncServerData
         * @static
         * @param {base.IRegistrySyncServerData} message RegistrySyncServerData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegistrySyncServerData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.servers != null && message.servers.length)
                for (var i = 0; i < message.servers.length; ++i)
                    $root.base.ServerData.encode(message.servers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RegistrySyncServerData message, length delimited. Does not implicitly {@link base.RegistrySyncServerData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.RegistrySyncServerData
         * @static
         * @param {base.IRegistrySyncServerData} message RegistrySyncServerData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RegistrySyncServerData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RegistrySyncServerData message from the specified reader or buffer.
         * @function decode
         * @memberof base.RegistrySyncServerData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.RegistrySyncServerData} RegistrySyncServerData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegistrySyncServerData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.RegistrySyncServerData();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.servers && message.servers.length))
                        message.servers = [];
                    message.servers.push($root.base.ServerData.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RegistrySyncServerData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.RegistrySyncServerData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.RegistrySyncServerData} RegistrySyncServerData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RegistrySyncServerData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RegistrySyncServerData message.
         * @function verify
         * @memberof base.RegistrySyncServerData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RegistrySyncServerData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.servers != null && message.hasOwnProperty("servers")) {
                if (!Array.isArray(message.servers))
                    return "servers: array expected";
                for (var i = 0; i < message.servers.length; ++i) {
                    var error = $root.base.ServerData.verify(message.servers[i]);
                    if (error)
                        return "servers." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RegistrySyncServerData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.RegistrySyncServerData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.RegistrySyncServerData} RegistrySyncServerData
         */
        RegistrySyncServerData.fromObject = function fromObject(object) {
            if (object instanceof $root.base.RegistrySyncServerData)
                return object;
            var message = new $root.base.RegistrySyncServerData();
            if (object.servers) {
                if (!Array.isArray(object.servers))
                    throw TypeError(".base.RegistrySyncServerData.servers: array expected");
                message.servers = [];
                for (var i = 0; i < object.servers.length; ++i) {
                    if (typeof object.servers[i] !== "object")
                        throw TypeError(".base.RegistrySyncServerData.servers: object expected");
                    message.servers[i] = $root.base.ServerData.fromObject(object.servers[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RegistrySyncServerData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.RegistrySyncServerData
         * @static
         * @param {base.RegistrySyncServerData} message RegistrySyncServerData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RegistrySyncServerData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.servers = [];
            if (message.servers && message.servers.length) {
                object.servers = [];
                for (var j = 0; j < message.servers.length; ++j)
                    object.servers[j] = $root.base.ServerData.toObject(message.servers[j], options);
            }
            return object;
        };

        /**
         * Converts this RegistrySyncServerData to JSON.
         * @function toJSON
         * @memberof base.RegistrySyncServerData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RegistrySyncServerData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RegistrySyncServerData;
    })();

    base.ServerData = (function() {

        /**
         * Properties of a ServerData.
         * @memberof base
         * @interface IServerData
         * @property {number|null} [handlerType] ServerData handlerType
         * @property {string|null} [url] ServerData url
         * @property {number|null} [gameId] ServerData gameId
         * @property {Array.<number>|null} [roomIds] ServerData roomIds
         */

        /**
         * Constructs a new ServerData.
         * @memberof base
         * @classdesc Represents a ServerData.
         * @implements IServerData
         * @constructor
         * @param {base.IServerData=} [properties] Properties to set
         */
        function ServerData(properties) {
            this.roomIds = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServerData handlerType.
         * @member {number} handlerType
         * @memberof base.ServerData
         * @instance
         */
        ServerData.prototype.handlerType = 0;

        /**
         * ServerData url.
         * @member {string} url
         * @memberof base.ServerData
         * @instance
         */
        ServerData.prototype.url = "";

        /**
         * ServerData gameId.
         * @member {number} gameId
         * @memberof base.ServerData
         * @instance
         */
        ServerData.prototype.gameId = 0;

        /**
         * ServerData roomIds.
         * @member {Array.<number>} roomIds
         * @memberof base.ServerData
         * @instance
         */
        ServerData.prototype.roomIds = $util.emptyArray;

        /**
         * Creates a new ServerData instance using the specified properties.
         * @function create
         * @memberof base.ServerData
         * @static
         * @param {base.IServerData=} [properties] Properties to set
         * @returns {base.ServerData} ServerData instance
         */
        ServerData.create = function create(properties) {
            return new ServerData(properties);
        };

        /**
         * Encodes the specified ServerData message. Does not implicitly {@link base.ServerData.verify|verify} messages.
         * @function encode
         * @memberof base.ServerData
         * @static
         * @param {base.IServerData} message ServerData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.handlerType != null && Object.hasOwnProperty.call(message, "handlerType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.handlerType);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            if (message.gameId != null && Object.hasOwnProperty.call(message, "gameId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.gameId);
            if (message.roomIds != null && message.roomIds.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.roomIds.length; ++i)
                    writer.int32(message.roomIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified ServerData message, length delimited. Does not implicitly {@link base.ServerData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.ServerData
         * @static
         * @param {base.IServerData} message ServerData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ServerData message from the specified reader or buffer.
         * @function decode
         * @memberof base.ServerData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.ServerData} ServerData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.ServerData();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.handlerType = reader.int32();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                case 3:
                    message.gameId = reader.int32();
                    break;
                case 4:
                    if (!(message.roomIds && message.roomIds.length))
                        message.roomIds = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.roomIds.push(reader.int32());
                    } else
                        message.roomIds.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ServerData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.ServerData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.ServerData} ServerData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ServerData message.
         * @function verify
         * @memberof base.ServerData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServerData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.handlerType != null && message.hasOwnProperty("handlerType"))
                if (!$util.isInteger(message.handlerType))
                    return "handlerType: integer expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                if (!$util.isInteger(message.gameId))
                    return "gameId: integer expected";
            if (message.roomIds != null && message.hasOwnProperty("roomIds")) {
                if (!Array.isArray(message.roomIds))
                    return "roomIds: array expected";
                for (var i = 0; i < message.roomIds.length; ++i)
                    if (!$util.isInteger(message.roomIds[i]))
                        return "roomIds: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a ServerData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.ServerData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.ServerData} ServerData
         */
        ServerData.fromObject = function fromObject(object) {
            if (object instanceof $root.base.ServerData)
                return object;
            var message = new $root.base.ServerData();
            if (object.handlerType != null)
                message.handlerType = object.handlerType | 0;
            if (object.url != null)
                message.url = String(object.url);
            if (object.gameId != null)
                message.gameId = object.gameId | 0;
            if (object.roomIds) {
                if (!Array.isArray(object.roomIds))
                    throw TypeError(".base.ServerData.roomIds: array expected");
                message.roomIds = [];
                for (var i = 0; i < object.roomIds.length; ++i)
                    message.roomIds[i] = object.roomIds[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a ServerData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.ServerData
         * @static
         * @param {base.ServerData} message ServerData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServerData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.roomIds = [];
            if (options.defaults) {
                object.handlerType = 0;
                object.url = "";
                object.gameId = 0;
            }
            if (message.handlerType != null && message.hasOwnProperty("handlerType"))
                object.handlerType = message.handlerType;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                object.gameId = message.gameId;
            if (message.roomIds && message.roomIds.length) {
                object.roomIds = [];
                for (var j = 0; j < message.roomIds.length; ++j)
                    object.roomIds[j] = message.roomIds[j];
            }
            return object;
        };

        /**
         * Converts this ServerData to JSON.
         * @function toJSON
         * @memberof base.ServerData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServerData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ServerData;
    })();

    base.GameServerData = (function() {

        /**
         * Properties of a GameServerData.
         * @memberof base
         * @interface IGameServerData
         * @property {number|null} [gameId] GameServerData gameId
         * @property {string|null} [url] GameServerData url
         * @property {Array.<base.IRoomInfo>|null} [roomInfos] GameServerData roomInfos
         * @property {boolean|null} [running] GameServerData running
         */

        /**
         * Constructs a new GameServerData.
         * @memberof base
         * @classdesc Represents a GameServerData.
         * @implements IGameServerData
         * @constructor
         * @param {base.IGameServerData=} [properties] Properties to set
         */
        function GameServerData(properties) {
            this.roomInfos = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameServerData gameId.
         * @member {number} gameId
         * @memberof base.GameServerData
         * @instance
         */
        GameServerData.prototype.gameId = 0;

        /**
         * GameServerData url.
         * @member {string} url
         * @memberof base.GameServerData
         * @instance
         */
        GameServerData.prototype.url = "";

        /**
         * GameServerData roomInfos.
         * @member {Array.<base.IRoomInfo>} roomInfos
         * @memberof base.GameServerData
         * @instance
         */
        GameServerData.prototype.roomInfos = $util.emptyArray;

        /**
         * GameServerData running.
         * @member {boolean} running
         * @memberof base.GameServerData
         * @instance
         */
        GameServerData.prototype.running = false;

        /**
         * Creates a new GameServerData instance using the specified properties.
         * @function create
         * @memberof base.GameServerData
         * @static
         * @param {base.IGameServerData=} [properties] Properties to set
         * @returns {base.GameServerData} GameServerData instance
         */
        GameServerData.create = function create(properties) {
            return new GameServerData(properties);
        };

        /**
         * Encodes the specified GameServerData message. Does not implicitly {@link base.GameServerData.verify|verify} messages.
         * @function encode
         * @memberof base.GameServerData
         * @static
         * @param {base.IGameServerData} message GameServerData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameServerData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameId != null && Object.hasOwnProperty.call(message, "gameId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gameId);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            if (message.roomInfos != null && message.roomInfos.length)
                for (var i = 0; i < message.roomInfos.length; ++i)
                    $root.base.RoomInfo.encode(message.roomInfos[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.running != null && Object.hasOwnProperty.call(message, "running"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.running);
            return writer;
        };

        /**
         * Encodes the specified GameServerData message, length delimited. Does not implicitly {@link base.GameServerData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.GameServerData
         * @static
         * @param {base.IGameServerData} message GameServerData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameServerData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameServerData message from the specified reader or buffer.
         * @function decode
         * @memberof base.GameServerData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.GameServerData} GameServerData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameServerData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.GameServerData();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameId = reader.int32();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                case 3:
                    if (!(message.roomInfos && message.roomInfos.length))
                        message.roomInfos = [];
                    message.roomInfos.push($root.base.RoomInfo.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.running = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameServerData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.GameServerData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.GameServerData} GameServerData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameServerData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameServerData message.
         * @function verify
         * @memberof base.GameServerData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameServerData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                if (!$util.isInteger(message.gameId))
                    return "gameId: integer expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.roomInfos != null && message.hasOwnProperty("roomInfos")) {
                if (!Array.isArray(message.roomInfos))
                    return "roomInfos: array expected";
                for (var i = 0; i < message.roomInfos.length; ++i) {
                    var error = $root.base.RoomInfo.verify(message.roomInfos[i]);
                    if (error)
                        return "roomInfos." + error;
                }
            }
            if (message.running != null && message.hasOwnProperty("running"))
                if (typeof message.running !== "boolean")
                    return "running: boolean expected";
            return null;
        };

        /**
         * Creates a GameServerData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.GameServerData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.GameServerData} GameServerData
         */
        GameServerData.fromObject = function fromObject(object) {
            if (object instanceof $root.base.GameServerData)
                return object;
            var message = new $root.base.GameServerData();
            if (object.gameId != null)
                message.gameId = object.gameId | 0;
            if (object.url != null)
                message.url = String(object.url);
            if (object.roomInfos) {
                if (!Array.isArray(object.roomInfos))
                    throw TypeError(".base.GameServerData.roomInfos: array expected");
                message.roomInfos = [];
                for (var i = 0; i < object.roomInfos.length; ++i) {
                    if (typeof object.roomInfos[i] !== "object")
                        throw TypeError(".base.GameServerData.roomInfos: object expected");
                    message.roomInfos[i] = $root.base.RoomInfo.fromObject(object.roomInfos[i]);
                }
            }
            if (object.running != null)
                message.running = Boolean(object.running);
            return message;
        };

        /**
         * Creates a plain object from a GameServerData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.GameServerData
         * @static
         * @param {base.GameServerData} message GameServerData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameServerData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.roomInfos = [];
            if (options.defaults) {
                object.gameId = 0;
                object.url = "";
                object.running = false;
            }
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                object.gameId = message.gameId;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.roomInfos && message.roomInfos.length) {
                object.roomInfos = [];
                for (var j = 0; j < message.roomInfos.length; ++j)
                    object.roomInfos[j] = $root.base.RoomInfo.toObject(message.roomInfos[j], options);
            }
            if (message.running != null && message.hasOwnProperty("running"))
                object.running = message.running;
            return object;
        };

        /**
         * Converts this GameServerData to JSON.
         * @function toJSON
         * @memberof base.GameServerData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameServerData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameServerData;
    })();

    /**
     * GAME_ID enum.
     * @name base.GAME_ID
     * @enum {number}
     * @property {number} GID_NONE=0 GID_NONE value
     * @property {number} GID_WHEEL=8000 GID_WHEEL value
     * @property {number} GID_CRASH=8001 GID_CRASH value
     * @property {number} GID_RING=8002 GID_RING value
     * @property {number} GID_PLINKO=8003 GID_PLINKO value
     * @property {number} GID_VIDEOPOKER=8004 GID_VIDEOPOKER value
     * @property {number} GID_LIMBO=8005 GID_LIMBO value
     * @property {number} GID_MINES=8006 GID_MINES value
     * @property {number} GID_HILO=8007 GID_HILO value
     * @property {number} GID_CLASSICDICE=8008 GID_CLASSICDICE value
     * @property {number} GID_COINFLIP=8009 GID_COINFLIP value
     * @property {number} GID_KENO=8010 GID_KENO value
     * @property {number} GID_SLOTS_1=8011 GID_SLOTS_1 value
     * @property {number} GID_STAIRS=8012 GID_STAIRS value
     * @property {number} GID_SLOTS_2=8013 GID_SLOTS_2 value
     * @property {number} GID_CRYPTOS=8014 GID_CRYPTOS value
     * @property {number} GID_RINGOFFORTUNE=8015 GID_RINGOFFORTUNE value
     * @property {number} GID_TOWERLEGEND=8016 GID_TOWERLEGEND value
     * @property {number} GID_DOUBLE=8017 GID_DOUBLE value
     * @property {number} GID_ALLSTAR=8018 GID_ALLSTAR value
     * @property {number} GID_DRAGONTIGER=8019 GID_DRAGONTIGER value
     * @property {number} GID_TRUCO=8021 GID_TRUCO value
     * @property {number} GID_AB=108 GID_AB value
     * @property {number} GID_SEVEN=109 GID_SEVEN value
     * @property {number} GID_DRAGON_TIGER=110 GID_DRAGON_TIGER value
     * @property {number} GID_WINGO=111 GID_WINGO value
     * @property {number} GID_FOREST_PARTY=112 GID_FOREST_PARTY value
     */
    base.GAME_ID = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "GID_NONE"] = 0;
        values[valuesById[8000] = "GID_WHEEL"] = 8000;
        values[valuesById[8001] = "GID_CRASH"] = 8001;
        values[valuesById[8002] = "GID_RING"] = 8002;
        values[valuesById[8003] = "GID_PLINKO"] = 8003;
        values[valuesById[8004] = "GID_VIDEOPOKER"] = 8004;
        values[valuesById[8005] = "GID_LIMBO"] = 8005;
        values[valuesById[8006] = "GID_MINES"] = 8006;
        values[valuesById[8007] = "GID_HILO"] = 8007;
        values[valuesById[8008] = "GID_CLASSICDICE"] = 8008;
        values[valuesById[8009] = "GID_COINFLIP"] = 8009;
        values[valuesById[8010] = "GID_KENO"] = 8010;
        values[valuesById[8011] = "GID_SLOTS_1"] = 8011;
        values[valuesById[8012] = "GID_STAIRS"] = 8012;
        values[valuesById[8013] = "GID_SLOTS_2"] = 8013;
        values[valuesById[8014] = "GID_CRYPTOS"] = 8014;
        values[valuesById[8015] = "GID_RINGOFFORTUNE"] = 8015;
        values[valuesById[8016] = "GID_TOWERLEGEND"] = 8016;
        values[valuesById[8017] = "GID_DOUBLE"] = 8017;
        values[valuesById[8018] = "GID_ALLSTAR"] = 8018;
        values[valuesById[8019] = "GID_DRAGONTIGER"] = 8019;
        values[valuesById[8021] = "GID_TRUCO"] = 8021;
        values[valuesById[108] = "GID_AB"] = 108;
        values[valuesById[109] = "GID_SEVEN"] = 109;
        values[valuesById[110] = "GID_DRAGON_TIGER"] = 110;
        values[valuesById[111] = "GID_WINGO"] = 111;
        values[valuesById[112] = "GID_FOREST_PARTY"] = 112;
        return values;
    })();

    base.SyncOnlineStatus = (function() {

        /**
         * Properties of a SyncOnlineStatus.
         * @memberof base
         * @interface ISyncOnlineStatus
         * @property {number|null} [online] SyncOnlineStatus online
         * @property {number|null} [currencyType] SyncOnlineStatus currencyType
         */

        /**
         * Constructs a new SyncOnlineStatus.
         * @memberof base
         * @classdesc Represents a SyncOnlineStatus.
         * @implements ISyncOnlineStatus
         * @constructor
         * @param {base.ISyncOnlineStatus=} [properties] Properties to set
         */
        function SyncOnlineStatus(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SyncOnlineStatus online.
         * @member {number} online
         * @memberof base.SyncOnlineStatus
         * @instance
         */
        SyncOnlineStatus.prototype.online = 0;

        /**
         * SyncOnlineStatus currencyType.
         * @member {number} currencyType
         * @memberof base.SyncOnlineStatus
         * @instance
         */
        SyncOnlineStatus.prototype.currencyType = 0;

        /**
         * Creates a new SyncOnlineStatus instance using the specified properties.
         * @function create
         * @memberof base.SyncOnlineStatus
         * @static
         * @param {base.ISyncOnlineStatus=} [properties] Properties to set
         * @returns {base.SyncOnlineStatus} SyncOnlineStatus instance
         */
        SyncOnlineStatus.create = function create(properties) {
            return new SyncOnlineStatus(properties);
        };

        /**
         * Encodes the specified SyncOnlineStatus message. Does not implicitly {@link base.SyncOnlineStatus.verify|verify} messages.
         * @function encode
         * @memberof base.SyncOnlineStatus
         * @static
         * @param {base.ISyncOnlineStatus} message SyncOnlineStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncOnlineStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.online != null && Object.hasOwnProperty.call(message, "online"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.online);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            return writer;
        };

        /**
         * Encodes the specified SyncOnlineStatus message, length delimited. Does not implicitly {@link base.SyncOnlineStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.SyncOnlineStatus
         * @static
         * @param {base.ISyncOnlineStatus} message SyncOnlineStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncOnlineStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyncOnlineStatus message from the specified reader or buffer.
         * @function decode
         * @memberof base.SyncOnlineStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.SyncOnlineStatus} SyncOnlineStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncOnlineStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.SyncOnlineStatus();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.online = reader.int32();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyncOnlineStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.SyncOnlineStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.SyncOnlineStatus} SyncOnlineStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncOnlineStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyncOnlineStatus message.
         * @function verify
         * @memberof base.SyncOnlineStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyncOnlineStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.online != null && message.hasOwnProperty("online"))
                if (!$util.isInteger(message.online))
                    return "online: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            return null;
        };

        /**
         * Creates a SyncOnlineStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.SyncOnlineStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.SyncOnlineStatus} SyncOnlineStatus
         */
        SyncOnlineStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.base.SyncOnlineStatus)
                return object;
            var message = new $root.base.SyncOnlineStatus();
            if (object.online != null)
                message.online = object.online | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            return message;
        };

        /**
         * Creates a plain object from a SyncOnlineStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.SyncOnlineStatus
         * @static
         * @param {base.SyncOnlineStatus} message SyncOnlineStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyncOnlineStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.online = 0;
                object.currencyType = 0;
            }
            if (message.online != null && message.hasOwnProperty("online"))
                object.online = message.online;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            return object;
        };

        /**
         * Converts this SyncOnlineStatus to JSON.
         * @function toJSON
         * @memberof base.SyncOnlineStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyncOnlineStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SyncOnlineStatus;
    })();

    base.SyncGameStatus = (function() {

        /**
         * Properties of a SyncGameStatus.
         * @memberof base
         * @interface ISyncGameStatus
         * @property {number|null} [gameId] SyncGameStatus gameId
         */

        /**
         * Constructs a new SyncGameStatus.
         * @memberof base
         * @classdesc Represents a SyncGameStatus.
         * @implements ISyncGameStatus
         * @constructor
         * @param {base.ISyncGameStatus=} [properties] Properties to set
         */
        function SyncGameStatus(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SyncGameStatus gameId.
         * @member {number} gameId
         * @memberof base.SyncGameStatus
         * @instance
         */
        SyncGameStatus.prototype.gameId = 0;

        /**
         * Creates a new SyncGameStatus instance using the specified properties.
         * @function create
         * @memberof base.SyncGameStatus
         * @static
         * @param {base.ISyncGameStatus=} [properties] Properties to set
         * @returns {base.SyncGameStatus} SyncGameStatus instance
         */
        SyncGameStatus.create = function create(properties) {
            return new SyncGameStatus(properties);
        };

        /**
         * Encodes the specified SyncGameStatus message. Does not implicitly {@link base.SyncGameStatus.verify|verify} messages.
         * @function encode
         * @memberof base.SyncGameStatus
         * @static
         * @param {base.ISyncGameStatus} message SyncGameStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncGameStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameId != null && Object.hasOwnProperty.call(message, "gameId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gameId);
            return writer;
        };

        /**
         * Encodes the specified SyncGameStatus message, length delimited. Does not implicitly {@link base.SyncGameStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.SyncGameStatus
         * @static
         * @param {base.ISyncGameStatus} message SyncGameStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncGameStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyncGameStatus message from the specified reader or buffer.
         * @function decode
         * @memberof base.SyncGameStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.SyncGameStatus} SyncGameStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncGameStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.SyncGameStatus();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyncGameStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.SyncGameStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.SyncGameStatus} SyncGameStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncGameStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyncGameStatus message.
         * @function verify
         * @memberof base.SyncGameStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyncGameStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                if (!$util.isInteger(message.gameId))
                    return "gameId: integer expected";
            return null;
        };

        /**
         * Creates a SyncGameStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.SyncGameStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.SyncGameStatus} SyncGameStatus
         */
        SyncGameStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.base.SyncGameStatus)
                return object;
            var message = new $root.base.SyncGameStatus();
            if (object.gameId != null)
                message.gameId = object.gameId | 0;
            return message;
        };

        /**
         * Creates a plain object from a SyncGameStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.SyncGameStatus
         * @static
         * @param {base.SyncGameStatus} message SyncGameStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyncGameStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.gameId = 0;
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                object.gameId = message.gameId;
            return object;
        };

        /**
         * Converts this SyncGameStatus to JSON.
         * @function toJSON
         * @memberof base.SyncGameStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyncGameStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SyncGameStatus;
    })();

    base.SyncPlayerBalance = (function() {

        /**
         * Properties of a SyncPlayerBalance.
         * @memberof base
         * @interface ISyncPlayerBalance
         * @property {boolean|null} [result] SyncPlayerBalance result
         * @property {number|Long|null} [balance] SyncPlayerBalance balance
         * @property {number|null} [currencyType] SyncPlayerBalance currencyType
         * @property {number|Long|null} [betAmount] SyncPlayerBalance betAmount
         * @property {number|Long|null} [winAmount] SyncPlayerBalance winAmount
         * @property {string|null} [detail] SyncPlayerBalance detail
         */

        /**
         * Constructs a new SyncPlayerBalance.
         * @memberof base
         * @classdesc Represents a SyncPlayerBalance.
         * @implements ISyncPlayerBalance
         * @constructor
         * @param {base.ISyncPlayerBalance=} [properties] Properties to set
         */
        function SyncPlayerBalance(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SyncPlayerBalance result.
         * @member {boolean} result
         * @memberof base.SyncPlayerBalance
         * @instance
         */
        SyncPlayerBalance.prototype.result = false;

        /**
         * SyncPlayerBalance balance.
         * @member {number|Long} balance
         * @memberof base.SyncPlayerBalance
         * @instance
         */
        SyncPlayerBalance.prototype.balance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SyncPlayerBalance currencyType.
         * @member {number} currencyType
         * @memberof base.SyncPlayerBalance
         * @instance
         */
        SyncPlayerBalance.prototype.currencyType = 0;

        /**
         * SyncPlayerBalance betAmount.
         * @member {number|Long} betAmount
         * @memberof base.SyncPlayerBalance
         * @instance
         */
        SyncPlayerBalance.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SyncPlayerBalance winAmount.
         * @member {number|Long} winAmount
         * @memberof base.SyncPlayerBalance
         * @instance
         */
        SyncPlayerBalance.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SyncPlayerBalance detail.
         * @member {string} detail
         * @memberof base.SyncPlayerBalance
         * @instance
         */
        SyncPlayerBalance.prototype.detail = "";

        /**
         * Creates a new SyncPlayerBalance instance using the specified properties.
         * @function create
         * @memberof base.SyncPlayerBalance
         * @static
         * @param {base.ISyncPlayerBalance=} [properties] Properties to set
         * @returns {base.SyncPlayerBalance} SyncPlayerBalance instance
         */
        SyncPlayerBalance.create = function create(properties) {
            return new SyncPlayerBalance(properties);
        };

        /**
         * Encodes the specified SyncPlayerBalance message. Does not implicitly {@link base.SyncPlayerBalance.verify|verify} messages.
         * @function encode
         * @memberof base.SyncPlayerBalance
         * @static
         * @param {base.ISyncPlayerBalance} message SyncPlayerBalance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncPlayerBalance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.result);
            if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.balance);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.betAmount);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.winAmount);
            if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.detail);
            return writer;
        };

        /**
         * Encodes the specified SyncPlayerBalance message, length delimited. Does not implicitly {@link base.SyncPlayerBalance.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.SyncPlayerBalance
         * @static
         * @param {base.ISyncPlayerBalance} message SyncPlayerBalance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncPlayerBalance.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyncPlayerBalance message from the specified reader or buffer.
         * @function decode
         * @memberof base.SyncPlayerBalance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.SyncPlayerBalance} SyncPlayerBalance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncPlayerBalance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.SyncPlayerBalance();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.bool();
                    break;
                case 2:
                    message.balance = reader.int64();
                    break;
                case 3:
                    message.currencyType = reader.int32();
                    break;
                case 4:
                    message.betAmount = reader.int64();
                    break;
                case 5:
                    message.winAmount = reader.int64();
                    break;
                case 6:
                    message.detail = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyncPlayerBalance message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.SyncPlayerBalance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.SyncPlayerBalance} SyncPlayerBalance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncPlayerBalance.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyncPlayerBalance message.
         * @function verify
         * @memberof base.SyncPlayerBalance
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyncPlayerBalance.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (typeof message.result !== "boolean")
                    return "result: boolean expected";
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (!$util.isInteger(message.balance) && !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high)))
                    return "balance: integer|Long expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.detail != null && message.hasOwnProperty("detail"))
                if (!$util.isString(message.detail))
                    return "detail: string expected";
            return null;
        };

        /**
         * Creates a SyncPlayerBalance message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.SyncPlayerBalance
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.SyncPlayerBalance} SyncPlayerBalance
         */
        SyncPlayerBalance.fromObject = function fromObject(object) {
            if (object instanceof $root.base.SyncPlayerBalance)
                return object;
            var message = new $root.base.SyncPlayerBalance();
            if (object.result != null)
                message.result = Boolean(object.result);
            if (object.balance != null)
                if ($util.Long)
                    (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
                else if (typeof object.balance === "string")
                    message.balance = parseInt(object.balance, 10);
                else if (typeof object.balance === "number")
                    message.balance = object.balance;
                else if (typeof object.balance === "object")
                    message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.detail != null)
                message.detail = String(object.detail);
            return message;
        };

        /**
         * Creates a plain object from a SyncPlayerBalance message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.SyncPlayerBalance
         * @static
         * @param {base.SyncPlayerBalance} message SyncPlayerBalance
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyncPlayerBalance.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.result = false;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.balance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.balance = options.longs === String ? "0" : 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                object.detail = "";
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (typeof message.balance === "number")
                    object.balance = options.longs === String ? String(message.balance) : message.balance;
                else
                    object.balance = options.longs === String ? $util.Long.prototype.toString.call(message.balance) : options.longs === Number ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber() : message.balance;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.detail != null && message.hasOwnProperty("detail"))
                object.detail = message.detail;
            return object;
        };

        /**
         * Converts this SyncPlayerBalance to JSON.
         * @function toJSON
         * @memberof base.SyncPlayerBalance
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyncPlayerBalance.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SyncPlayerBalance;
    })();

    base.GamePlayerSettled = (function() {

        /**
         * Properties of a GamePlayerSettled.
         * @memberof base
         * @interface IGamePlayerSettled
         * @property {number|null} [updateType] GamePlayerSettled updateType
         * @property {number|null} [currencyType] GamePlayerSettled currencyType
         * @property {number|Long|null} [amount] GamePlayerSettled amount
         * @property {number|Long|null} [betAmount] GamePlayerSettled betAmount
         * @property {number|Long|null} [winAmount] GamePlayerSettled winAmount
         * @property {number|Long|null} [fee] GamePlayerSettled fee
         * @property {number|null} [gameId] GamePlayerSettled gameId
         * @property {string|null} [gameCode] GamePlayerSettled gameCode
         * @property {string|null} [detail] GamePlayerSettled detail
         */

        /**
         * Constructs a new GamePlayerSettled.
         * @memberof base
         * @classdesc Represents a GamePlayerSettled.
         * @implements IGamePlayerSettled
         * @constructor
         * @param {base.IGamePlayerSettled=} [properties] Properties to set
         */
        function GamePlayerSettled(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GamePlayerSettled updateType.
         * @member {number} updateType
         * @memberof base.GamePlayerSettled
         * @instance
         */
        GamePlayerSettled.prototype.updateType = 0;

        /**
         * GamePlayerSettled currencyType.
         * @member {number} currencyType
         * @memberof base.GamePlayerSettled
         * @instance
         */
        GamePlayerSettled.prototype.currencyType = 0;

        /**
         * GamePlayerSettled amount.
         * @member {number|Long} amount
         * @memberof base.GamePlayerSettled
         * @instance
         */
        GamePlayerSettled.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GamePlayerSettled betAmount.
         * @member {number|Long} betAmount
         * @memberof base.GamePlayerSettled
         * @instance
         */
        GamePlayerSettled.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GamePlayerSettled winAmount.
         * @member {number|Long} winAmount
         * @memberof base.GamePlayerSettled
         * @instance
         */
        GamePlayerSettled.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GamePlayerSettled fee.
         * @member {number|Long} fee
         * @memberof base.GamePlayerSettled
         * @instance
         */
        GamePlayerSettled.prototype.fee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GamePlayerSettled gameId.
         * @member {number} gameId
         * @memberof base.GamePlayerSettled
         * @instance
         */
        GamePlayerSettled.prototype.gameId = 0;

        /**
         * GamePlayerSettled gameCode.
         * @member {string} gameCode
         * @memberof base.GamePlayerSettled
         * @instance
         */
        GamePlayerSettled.prototype.gameCode = "";

        /**
         * GamePlayerSettled detail.
         * @member {string} detail
         * @memberof base.GamePlayerSettled
         * @instance
         */
        GamePlayerSettled.prototype.detail = "";

        /**
         * Creates a new GamePlayerSettled instance using the specified properties.
         * @function create
         * @memberof base.GamePlayerSettled
         * @static
         * @param {base.IGamePlayerSettled=} [properties] Properties to set
         * @returns {base.GamePlayerSettled} GamePlayerSettled instance
         */
        GamePlayerSettled.create = function create(properties) {
            return new GamePlayerSettled(properties);
        };

        /**
         * Encodes the specified GamePlayerSettled message. Does not implicitly {@link base.GamePlayerSettled.verify|verify} messages.
         * @function encode
         * @memberof base.GamePlayerSettled
         * @static
         * @param {base.IGamePlayerSettled} message GamePlayerSettled message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GamePlayerSettled.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.updateType != null && Object.hasOwnProperty.call(message, "updateType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.updateType);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.amount);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.betAmount);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.winAmount);
            if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.fee);
            if (message.gameId != null && Object.hasOwnProperty.call(message, "gameId"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.gameId);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.gameCode);
            if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.detail);
            return writer;
        };

        /**
         * Encodes the specified GamePlayerSettled message, length delimited. Does not implicitly {@link base.GamePlayerSettled.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.GamePlayerSettled
         * @static
         * @param {base.IGamePlayerSettled} message GamePlayerSettled message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GamePlayerSettled.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GamePlayerSettled message from the specified reader or buffer.
         * @function decode
         * @memberof base.GamePlayerSettled
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.GamePlayerSettled} GamePlayerSettled
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GamePlayerSettled.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.GamePlayerSettled();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.updateType = reader.int32();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.amount = reader.int64();
                    break;
                case 4:
                    message.betAmount = reader.int64();
                    break;
                case 5:
                    message.winAmount = reader.int64();
                    break;
                case 6:
                    message.fee = reader.int64();
                    break;
                case 7:
                    message.gameId = reader.int32();
                    break;
                case 8:
                    message.gameCode = reader.string();
                    break;
                case 9:
                    message.detail = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GamePlayerSettled message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.GamePlayerSettled
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.GamePlayerSettled} GamePlayerSettled
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GamePlayerSettled.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GamePlayerSettled message.
         * @function verify
         * @memberof base.GamePlayerSettled
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GamePlayerSettled.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.updateType != null && message.hasOwnProperty("updateType"))
                if (!$util.isInteger(message.updateType))
                    return "updateType: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                    return "amount: integer|Long expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.fee != null && message.hasOwnProperty("fee"))
                if (!$util.isInteger(message.fee) && !(message.fee && $util.isInteger(message.fee.low) && $util.isInteger(message.fee.high)))
                    return "fee: integer|Long expected";
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                if (!$util.isInteger(message.gameId))
                    return "gameId: integer expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.detail != null && message.hasOwnProperty("detail"))
                if (!$util.isString(message.detail))
                    return "detail: string expected";
            return null;
        };

        /**
         * Creates a GamePlayerSettled message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.GamePlayerSettled
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.GamePlayerSettled} GamePlayerSettled
         */
        GamePlayerSettled.fromObject = function fromObject(object) {
            if (object instanceof $root.base.GamePlayerSettled)
                return object;
            var message = new $root.base.GamePlayerSettled();
            if (object.updateType != null)
                message.updateType = object.updateType | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.amount != null)
                if ($util.Long)
                    (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
                else if (typeof object.amount === "string")
                    message.amount = parseInt(object.amount, 10);
                else if (typeof object.amount === "number")
                    message.amount = object.amount;
                else if (typeof object.amount === "object")
                    message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.fee != null)
                if ($util.Long)
                    (message.fee = $util.Long.fromValue(object.fee)).unsigned = false;
                else if (typeof object.fee === "string")
                    message.fee = parseInt(object.fee, 10);
                else if (typeof object.fee === "number")
                    message.fee = object.fee;
                else if (typeof object.fee === "object")
                    message.fee = new $util.LongBits(object.fee.low >>> 0, object.fee.high >>> 0).toNumber();
            if (object.gameId != null)
                message.gameId = object.gameId | 0;
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.detail != null)
                message.detail = String(object.detail);
            return message;
        };

        /**
         * Creates a plain object from a GamePlayerSettled message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.GamePlayerSettled
         * @static
         * @param {base.GamePlayerSettled} message GamePlayerSettled
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GamePlayerSettled.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.updateType = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.fee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fee = options.longs === String ? "0" : 0;
                object.gameId = 0;
                object.gameCode = "";
                object.detail = "";
            }
            if (message.updateType != null && message.hasOwnProperty("updateType"))
                object.updateType = message.updateType;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (typeof message.amount === "number")
                    object.amount = options.longs === String ? String(message.amount) : message.amount;
                else
                    object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber() : message.amount;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.fee != null && message.hasOwnProperty("fee"))
                if (typeof message.fee === "number")
                    object.fee = options.longs === String ? String(message.fee) : message.fee;
                else
                    object.fee = options.longs === String ? $util.Long.prototype.toString.call(message.fee) : options.longs === Number ? new $util.LongBits(message.fee.low >>> 0, message.fee.high >>> 0).toNumber() : message.fee;
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                object.gameId = message.gameId;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.detail != null && message.hasOwnProperty("detail"))
                object.detail = message.detail;
            return object;
        };

        /**
         * Converts this GamePlayerSettled to JSON.
         * @function toJSON
         * @memberof base.GamePlayerSettled
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GamePlayerSettled.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GamePlayerSettled;
    })();

    base.PlayerLoginReq = (function() {

        /**
         * Properties of a PlayerLoginReq.
         * @memberof base
         * @interface IPlayerLoginReq
         * @property {string|null} [token] PlayerLoginReq token
         */

        /**
         * Constructs a new PlayerLoginReq.
         * @memberof base
         * @classdesc Represents a PlayerLoginReq.
         * @implements IPlayerLoginReq
         * @constructor
         * @param {base.IPlayerLoginReq=} [properties] Properties to set
         */
        function PlayerLoginReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerLoginReq token.
         * @member {string} token
         * @memberof base.PlayerLoginReq
         * @instance
         */
        PlayerLoginReq.prototype.token = "";

        /**
         * Creates a new PlayerLoginReq instance using the specified properties.
         * @function create
         * @memberof base.PlayerLoginReq
         * @static
         * @param {base.IPlayerLoginReq=} [properties] Properties to set
         * @returns {base.PlayerLoginReq} PlayerLoginReq instance
         */
        PlayerLoginReq.create = function create(properties) {
            return new PlayerLoginReq(properties);
        };

        /**
         * Encodes the specified PlayerLoginReq message. Does not implicitly {@link base.PlayerLoginReq.verify|verify} messages.
         * @function encode
         * @memberof base.PlayerLoginReq
         * @static
         * @param {base.IPlayerLoginReq} message PlayerLoginReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerLoginReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified PlayerLoginReq message, length delimited. Does not implicitly {@link base.PlayerLoginReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.PlayerLoginReq
         * @static
         * @param {base.IPlayerLoginReq} message PlayerLoginReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerLoginReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerLoginReq message from the specified reader or buffer.
         * @function decode
         * @memberof base.PlayerLoginReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.PlayerLoginReq} PlayerLoginReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerLoginReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.PlayerLoginReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerLoginReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.PlayerLoginReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.PlayerLoginReq} PlayerLoginReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerLoginReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerLoginReq message.
         * @function verify
         * @memberof base.PlayerLoginReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerLoginReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates a PlayerLoginReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.PlayerLoginReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.PlayerLoginReq} PlayerLoginReq
         */
        PlayerLoginReq.fromObject = function fromObject(object) {
            if (object instanceof $root.base.PlayerLoginReq)
                return object;
            var message = new $root.base.PlayerLoginReq();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from a PlayerLoginReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.PlayerLoginReq
         * @static
         * @param {base.PlayerLoginReq} message PlayerLoginReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerLoginReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this PlayerLoginReq to JSON.
         * @function toJSON
         * @memberof base.PlayerLoginReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerLoginReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerLoginReq;
    })();

    base.PlayerLoginReply = (function() {

        /**
         * Properties of a PlayerLoginReply.
         * @memberof base
         * @interface IPlayerLoginReply
         * @property {base.IPlayerBaseInfo|null} [baseInfo] PlayerLoginReply baseInfo
         * @property {Array.<base.IBalanceInfo>|null} [balances] PlayerLoginReply balances
         * @property {Array.<number>|null} [inGame] PlayerLoginReply inGame
         */

        /**
         * Constructs a new PlayerLoginReply.
         * @memberof base
         * @classdesc Represents a PlayerLoginReply.
         * @implements IPlayerLoginReply
         * @constructor
         * @param {base.IPlayerLoginReply=} [properties] Properties to set
         */
        function PlayerLoginReply(properties) {
            this.balances = [];
            this.inGame = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerLoginReply baseInfo.
         * @member {base.IPlayerBaseInfo|null|undefined} baseInfo
         * @memberof base.PlayerLoginReply
         * @instance
         */
        PlayerLoginReply.prototype.baseInfo = null;

        /**
         * PlayerLoginReply balances.
         * @member {Array.<base.IBalanceInfo>} balances
         * @memberof base.PlayerLoginReply
         * @instance
         */
        PlayerLoginReply.prototype.balances = $util.emptyArray;

        /**
         * PlayerLoginReply inGame.
         * @member {Array.<number>} inGame
         * @memberof base.PlayerLoginReply
         * @instance
         */
        PlayerLoginReply.prototype.inGame = $util.emptyArray;

        /**
         * Creates a new PlayerLoginReply instance using the specified properties.
         * @function create
         * @memberof base.PlayerLoginReply
         * @static
         * @param {base.IPlayerLoginReply=} [properties] Properties to set
         * @returns {base.PlayerLoginReply} PlayerLoginReply instance
         */
        PlayerLoginReply.create = function create(properties) {
            return new PlayerLoginReply(properties);
        };

        /**
         * Encodes the specified PlayerLoginReply message. Does not implicitly {@link base.PlayerLoginReply.verify|verify} messages.
         * @function encode
         * @memberof base.PlayerLoginReply
         * @static
         * @param {base.IPlayerLoginReply} message PlayerLoginReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerLoginReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.baseInfo != null && Object.hasOwnProperty.call(message, "baseInfo"))
                $root.base.PlayerBaseInfo.encode(message.baseInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.balances != null && message.balances.length)
                for (var i = 0; i < message.balances.length; ++i)
                    $root.base.BalanceInfo.encode(message.balances[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.inGame != null && message.inGame.length) {
                writer.uint32(/* id 6, wireType 2 =*/50).fork();
                for (var i = 0; i < message.inGame.length; ++i)
                    writer.int32(message.inGame[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified PlayerLoginReply message, length delimited. Does not implicitly {@link base.PlayerLoginReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.PlayerLoginReply
         * @static
         * @param {base.IPlayerLoginReply} message PlayerLoginReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerLoginReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerLoginReply message from the specified reader or buffer.
         * @function decode
         * @memberof base.PlayerLoginReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.PlayerLoginReply} PlayerLoginReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerLoginReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.PlayerLoginReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.baseInfo = $root.base.PlayerBaseInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.balances && message.balances.length))
                        message.balances = [];
                    message.balances.push($root.base.BalanceInfo.decode(reader, reader.uint32()));
                    break;
                case 6:
                    if (!(message.inGame && message.inGame.length))
                        message.inGame = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.inGame.push(reader.int32());
                    } else
                        message.inGame.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerLoginReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.PlayerLoginReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.PlayerLoginReply} PlayerLoginReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerLoginReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerLoginReply message.
         * @function verify
         * @memberof base.PlayerLoginReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerLoginReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo")) {
                var error = $root.base.PlayerBaseInfo.verify(message.baseInfo);
                if (error)
                    return "baseInfo." + error;
            }
            if (message.balances != null && message.hasOwnProperty("balances")) {
                if (!Array.isArray(message.balances))
                    return "balances: array expected";
                for (var i = 0; i < message.balances.length; ++i) {
                    var error = $root.base.BalanceInfo.verify(message.balances[i]);
                    if (error)
                        return "balances." + error;
                }
            }
            if (message.inGame != null && message.hasOwnProperty("inGame")) {
                if (!Array.isArray(message.inGame))
                    return "inGame: array expected";
                for (var i = 0; i < message.inGame.length; ++i)
                    if (!$util.isInteger(message.inGame[i]))
                        return "inGame: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a PlayerLoginReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.PlayerLoginReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.PlayerLoginReply} PlayerLoginReply
         */
        PlayerLoginReply.fromObject = function fromObject(object) {
            if (object instanceof $root.base.PlayerLoginReply)
                return object;
            var message = new $root.base.PlayerLoginReply();
            if (object.baseInfo != null) {
                if (typeof object.baseInfo !== "object")
                    throw TypeError(".base.PlayerLoginReply.baseInfo: object expected");
                message.baseInfo = $root.base.PlayerBaseInfo.fromObject(object.baseInfo);
            }
            if (object.balances) {
                if (!Array.isArray(object.balances))
                    throw TypeError(".base.PlayerLoginReply.balances: array expected");
                message.balances = [];
                for (var i = 0; i < object.balances.length; ++i) {
                    if (typeof object.balances[i] !== "object")
                        throw TypeError(".base.PlayerLoginReply.balances: object expected");
                    message.balances[i] = $root.base.BalanceInfo.fromObject(object.balances[i]);
                }
            }
            if (object.inGame) {
                if (!Array.isArray(object.inGame))
                    throw TypeError(".base.PlayerLoginReply.inGame: array expected");
                message.inGame = [];
                for (var i = 0; i < object.inGame.length; ++i)
                    message.inGame[i] = object.inGame[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a PlayerLoginReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.PlayerLoginReply
         * @static
         * @param {base.PlayerLoginReply} message PlayerLoginReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerLoginReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.balances = [];
                object.inGame = [];
            }
            if (options.defaults)
                object.baseInfo = null;
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo"))
                object.baseInfo = $root.base.PlayerBaseInfo.toObject(message.baseInfo, options);
            if (message.balances && message.balances.length) {
                object.balances = [];
                for (var j = 0; j < message.balances.length; ++j)
                    object.balances[j] = $root.base.BalanceInfo.toObject(message.balances[j], options);
            }
            if (message.inGame && message.inGame.length) {
                object.inGame = [];
                for (var j = 0; j < message.inGame.length; ++j)
                    object.inGame[j] = message.inGame[j];
            }
            return object;
        };

        /**
         * Converts this PlayerLoginReply to JSON.
         * @function toJSON
         * @memberof base.PlayerLoginReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerLoginReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerLoginReply;
    })();

    base.PlayerBalanceEvent = (function() {

        /**
         * Properties of a PlayerBalanceEvent.
         * @memberof base
         * @interface IPlayerBalanceEvent
         * @property {Array.<base.IBalanceInfo>|null} [balances] PlayerBalanceEvent balances
         */

        /**
         * Constructs a new PlayerBalanceEvent.
         * @memberof base
         * @classdesc Represents a PlayerBalanceEvent.
         * @implements IPlayerBalanceEvent
         * @constructor
         * @param {base.IPlayerBalanceEvent=} [properties] Properties to set
         */
        function PlayerBalanceEvent(properties) {
            this.balances = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerBalanceEvent balances.
         * @member {Array.<base.IBalanceInfo>} balances
         * @memberof base.PlayerBalanceEvent
         * @instance
         */
        PlayerBalanceEvent.prototype.balances = $util.emptyArray;

        /**
         * Creates a new PlayerBalanceEvent instance using the specified properties.
         * @function create
         * @memberof base.PlayerBalanceEvent
         * @static
         * @param {base.IPlayerBalanceEvent=} [properties] Properties to set
         * @returns {base.PlayerBalanceEvent} PlayerBalanceEvent instance
         */
        PlayerBalanceEvent.create = function create(properties) {
            return new PlayerBalanceEvent(properties);
        };

        /**
         * Encodes the specified PlayerBalanceEvent message. Does not implicitly {@link base.PlayerBalanceEvent.verify|verify} messages.
         * @function encode
         * @memberof base.PlayerBalanceEvent
         * @static
         * @param {base.IPlayerBalanceEvent} message PlayerBalanceEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerBalanceEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.balances != null && message.balances.length)
                for (var i = 0; i < message.balances.length; ++i)
                    $root.base.BalanceInfo.encode(message.balances[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PlayerBalanceEvent message, length delimited. Does not implicitly {@link base.PlayerBalanceEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.PlayerBalanceEvent
         * @static
         * @param {base.IPlayerBalanceEvent} message PlayerBalanceEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerBalanceEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerBalanceEvent message from the specified reader or buffer.
         * @function decode
         * @memberof base.PlayerBalanceEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.PlayerBalanceEvent} PlayerBalanceEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerBalanceEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.PlayerBalanceEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.balances && message.balances.length))
                        message.balances = [];
                    message.balances.push($root.base.BalanceInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerBalanceEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.PlayerBalanceEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.PlayerBalanceEvent} PlayerBalanceEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerBalanceEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerBalanceEvent message.
         * @function verify
         * @memberof base.PlayerBalanceEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerBalanceEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.balances != null && message.hasOwnProperty("balances")) {
                if (!Array.isArray(message.balances))
                    return "balances: array expected";
                for (var i = 0; i < message.balances.length; ++i) {
                    var error = $root.base.BalanceInfo.verify(message.balances[i]);
                    if (error)
                        return "balances." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PlayerBalanceEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.PlayerBalanceEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.PlayerBalanceEvent} PlayerBalanceEvent
         */
        PlayerBalanceEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.base.PlayerBalanceEvent)
                return object;
            var message = new $root.base.PlayerBalanceEvent();
            if (object.balances) {
                if (!Array.isArray(object.balances))
                    throw TypeError(".base.PlayerBalanceEvent.balances: array expected");
                message.balances = [];
                for (var i = 0; i < object.balances.length; ++i) {
                    if (typeof object.balances[i] !== "object")
                        throw TypeError(".base.PlayerBalanceEvent.balances: object expected");
                    message.balances[i] = $root.base.BalanceInfo.fromObject(object.balances[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PlayerBalanceEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.PlayerBalanceEvent
         * @static
         * @param {base.PlayerBalanceEvent} message PlayerBalanceEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerBalanceEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.balances = [];
            if (message.balances && message.balances.length) {
                object.balances = [];
                for (var j = 0; j < message.balances.length; ++j)
                    object.balances[j] = $root.base.BalanceInfo.toObject(message.balances[j], options);
            }
            return object;
        };

        /**
         * Converts this PlayerBalanceEvent to JSON.
         * @function toJSON
         * @memberof base.PlayerBalanceEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerBalanceEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerBalanceEvent;
    })();

    base.PlayerBaseInfo = (function() {

        /**
         * Properties of a PlayerBaseInfo.
         * @memberof base
         * @interface IPlayerBaseInfo
         * @property {number|Long|null} [playerId] PlayerBaseInfo playerId
         * @property {string|null} [nickname] PlayerBaseInfo nickname
         * @property {string|null} [headUrl] PlayerBaseInfo headUrl
         * @property {number|null} [level] PlayerBaseInfo level
         */

        /**
         * Constructs a new PlayerBaseInfo.
         * @memberof base
         * @classdesc Represents a PlayerBaseInfo.
         * @implements IPlayerBaseInfo
         * @constructor
         * @param {base.IPlayerBaseInfo=} [properties] Properties to set
         */
        function PlayerBaseInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerBaseInfo playerId.
         * @member {number|Long} playerId
         * @memberof base.PlayerBaseInfo
         * @instance
         */
        PlayerBaseInfo.prototype.playerId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PlayerBaseInfo nickname.
         * @member {string} nickname
         * @memberof base.PlayerBaseInfo
         * @instance
         */
        PlayerBaseInfo.prototype.nickname = "";

        /**
         * PlayerBaseInfo headUrl.
         * @member {string} headUrl
         * @memberof base.PlayerBaseInfo
         * @instance
         */
        PlayerBaseInfo.prototype.headUrl = "";

        /**
         * PlayerBaseInfo level.
         * @member {number} level
         * @memberof base.PlayerBaseInfo
         * @instance
         */
        PlayerBaseInfo.prototype.level = 0;

        /**
         * Creates a new PlayerBaseInfo instance using the specified properties.
         * @function create
         * @memberof base.PlayerBaseInfo
         * @static
         * @param {base.IPlayerBaseInfo=} [properties] Properties to set
         * @returns {base.PlayerBaseInfo} PlayerBaseInfo instance
         */
        PlayerBaseInfo.create = function create(properties) {
            return new PlayerBaseInfo(properties);
        };

        /**
         * Encodes the specified PlayerBaseInfo message. Does not implicitly {@link base.PlayerBaseInfo.verify|verify} messages.
         * @function encode
         * @memberof base.PlayerBaseInfo
         * @static
         * @param {base.IPlayerBaseInfo} message PlayerBaseInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerBaseInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.playerId != null && Object.hasOwnProperty.call(message, "playerId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.playerId);
            if (message.nickname != null && Object.hasOwnProperty.call(message, "nickname"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.nickname);
            if (message.headUrl != null && Object.hasOwnProperty.call(message, "headUrl"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.headUrl);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.level);
            return writer;
        };

        /**
         * Encodes the specified PlayerBaseInfo message, length delimited. Does not implicitly {@link base.PlayerBaseInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.PlayerBaseInfo
         * @static
         * @param {base.IPlayerBaseInfo} message PlayerBaseInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerBaseInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerBaseInfo message from the specified reader or buffer.
         * @function decode
         * @memberof base.PlayerBaseInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.PlayerBaseInfo} PlayerBaseInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerBaseInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.PlayerBaseInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.playerId = reader.uint64();
                    break;
                case 2:
                    message.nickname = reader.string();
                    break;
                case 3:
                    message.headUrl = reader.string();
                    break;
                case 4:
                    message.level = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerBaseInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.PlayerBaseInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.PlayerBaseInfo} PlayerBaseInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerBaseInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerBaseInfo message.
         * @function verify
         * @memberof base.PlayerBaseInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerBaseInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.playerId != null && message.hasOwnProperty("playerId"))
                if (!$util.isInteger(message.playerId) && !(message.playerId && $util.isInteger(message.playerId.low) && $util.isInteger(message.playerId.high)))
                    return "playerId: integer|Long expected";
            if (message.nickname != null && message.hasOwnProperty("nickname"))
                if (!$util.isString(message.nickname))
                    return "nickname: string expected";
            if (message.headUrl != null && message.hasOwnProperty("headUrl"))
                if (!$util.isString(message.headUrl))
                    return "headUrl: string expected";
            if (message.level != null && message.hasOwnProperty("level"))
                if (!$util.isInteger(message.level))
                    return "level: integer expected";
            return null;
        };

        /**
         * Creates a PlayerBaseInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.PlayerBaseInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.PlayerBaseInfo} PlayerBaseInfo
         */
        PlayerBaseInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.base.PlayerBaseInfo)
                return object;
            var message = new $root.base.PlayerBaseInfo();
            if (object.playerId != null)
                if ($util.Long)
                    (message.playerId = $util.Long.fromValue(object.playerId)).unsigned = true;
                else if (typeof object.playerId === "string")
                    message.playerId = parseInt(object.playerId, 10);
                else if (typeof object.playerId === "number")
                    message.playerId = object.playerId;
                else if (typeof object.playerId === "object")
                    message.playerId = new $util.LongBits(object.playerId.low >>> 0, object.playerId.high >>> 0).toNumber(true);
            if (object.nickname != null)
                message.nickname = String(object.nickname);
            if (object.headUrl != null)
                message.headUrl = String(object.headUrl);
            if (object.level != null)
                message.level = object.level | 0;
            return message;
        };

        /**
         * Creates a plain object from a PlayerBaseInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.PlayerBaseInfo
         * @static
         * @param {base.PlayerBaseInfo} message PlayerBaseInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerBaseInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.playerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.playerId = options.longs === String ? "0" : 0;
                object.nickname = "";
                object.headUrl = "";
                object.level = 0;
            }
            if (message.playerId != null && message.hasOwnProperty("playerId"))
                if (typeof message.playerId === "number")
                    object.playerId = options.longs === String ? String(message.playerId) : message.playerId;
                else
                    object.playerId = options.longs === String ? $util.Long.prototype.toString.call(message.playerId) : options.longs === Number ? new $util.LongBits(message.playerId.low >>> 0, message.playerId.high >>> 0).toNumber(true) : message.playerId;
            if (message.nickname != null && message.hasOwnProperty("nickname"))
                object.nickname = message.nickname;
            if (message.headUrl != null && message.hasOwnProperty("headUrl"))
                object.headUrl = message.headUrl;
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = message.level;
            return object;
        };

        /**
         * Converts this PlayerBaseInfo to JSON.
         * @function toJSON
         * @memberof base.PlayerBaseInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerBaseInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerBaseInfo;
    })();

    /**
     * TAG_TYPE enum.
     * @name base.TAG_TYPE
     * @enum {number}
     * @property {number} TT_NORMAL=0 TT_NORMAL value
     * @property {number} TT_ROBOT=99 TT_ROBOT value
     */
    base.TAG_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TT_NORMAL"] = 0;
        values[valuesById[99] = "TT_ROBOT"] = 99;
        return values;
    })();

    base.BalanceInfo = (function() {

        /**
         * Properties of a BalanceInfo.
         * @memberof base
         * @interface IBalanceInfo
         * @property {number|null} [type] BalanceInfo type
         * @property {number|Long|null} [amount] BalanceInfo amount
         */

        /**
         * Constructs a new BalanceInfo.
         * @memberof base
         * @classdesc Represents a BalanceInfo.
         * @implements IBalanceInfo
         * @constructor
         * @param {base.IBalanceInfo=} [properties] Properties to set
         */
        function BalanceInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BalanceInfo type.
         * @member {number} type
         * @memberof base.BalanceInfo
         * @instance
         */
        BalanceInfo.prototype.type = 0;

        /**
         * BalanceInfo amount.
         * @member {number|Long} amount
         * @memberof base.BalanceInfo
         * @instance
         */
        BalanceInfo.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new BalanceInfo instance using the specified properties.
         * @function create
         * @memberof base.BalanceInfo
         * @static
         * @param {base.IBalanceInfo=} [properties] Properties to set
         * @returns {base.BalanceInfo} BalanceInfo instance
         */
        BalanceInfo.create = function create(properties) {
            return new BalanceInfo(properties);
        };

        /**
         * Encodes the specified BalanceInfo message. Does not implicitly {@link base.BalanceInfo.verify|verify} messages.
         * @function encode
         * @memberof base.BalanceInfo
         * @static
         * @param {base.IBalanceInfo} message BalanceInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BalanceInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.amount);
            return writer;
        };

        /**
         * Encodes the specified BalanceInfo message, length delimited. Does not implicitly {@link base.BalanceInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.BalanceInfo
         * @static
         * @param {base.IBalanceInfo} message BalanceInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BalanceInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BalanceInfo message from the specified reader or buffer.
         * @function decode
         * @memberof base.BalanceInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.BalanceInfo} BalanceInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BalanceInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.BalanceInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.amount = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BalanceInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.BalanceInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.BalanceInfo} BalanceInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BalanceInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BalanceInfo message.
         * @function verify
         * @memberof base.BalanceInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BalanceInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isInteger(message.type))
                    return "type: integer expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                    return "amount: integer|Long expected";
            return null;
        };

        /**
         * Creates a BalanceInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.BalanceInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.BalanceInfo} BalanceInfo
         */
        BalanceInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.base.BalanceInfo)
                return object;
            var message = new $root.base.BalanceInfo();
            if (object.type != null)
                message.type = object.type | 0;
            if (object.amount != null)
                if ($util.Long)
                    (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
                else if (typeof object.amount === "string")
                    message.amount = parseInt(object.amount, 10);
                else if (typeof object.amount === "number")
                    message.amount = object.amount;
                else if (typeof object.amount === "object")
                    message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a BalanceInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.BalanceInfo
         * @static
         * @param {base.BalanceInfo} message BalanceInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BalanceInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.type = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amount = options.longs === String ? "0" : 0;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (typeof message.amount === "number")
                    object.amount = options.longs === String ? String(message.amount) : message.amount;
                else
                    object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber() : message.amount;
            return object;
        };

        /**
         * Converts this BalanceInfo to JSON.
         * @function toJSON
         * @memberof base.BalanceInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BalanceInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BalanceInfo;
    })();

    /**
     * MONEY_TYPE enum.
     * @name base.MONEY_TYPE
     * @enum {number}
     * @property {number} MT_NONE=0 MT_NONE value
     * @property {number} MT_USDT=1 MT_USDT value
     * @property {number} MT_ETH=2 MT_ETH value
     * @property {number} MT_BTC=3 MT_BTC value
     * @property {number} MT_IB=4 MT_IB value
     * @property {number} MT_GB=5 MT_GB value
     * @property {number} MT_BRL=6 MT_BRL value
     */
    base.MONEY_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MT_NONE"] = 0;
        values[valuesById[1] = "MT_USDT"] = 1;
        values[valuesById[2] = "MT_ETH"] = 2;
        values[valuesById[3] = "MT_BTC"] = 3;
        values[valuesById[4] = "MT_IB"] = 4;
        values[valuesById[5] = "MT_GB"] = 5;
        values[valuesById[6] = "MT_BRL"] = 6;
        return values;
    })();

    /**
     * BALANCE_UPDATE_TYPE enum.
     * @name base.BALANCE_UPDATE_TYPE
     * @enum {number}
     * @property {number} BU_NONE=0 BU_NONE value
     * @property {number} BU_GAME=1 BU_GAME value
     * @property {number} SOURCE_PRIVATE_TABLE_CREATE=1001 SOURCE_PRIVATE_TABLE_CREATE value
     */
    base.BALANCE_UPDATE_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "BU_NONE"] = 0;
        values[valuesById[1] = "BU_GAME"] = 1;
        values[valuesById[1001] = "SOURCE_PRIVATE_TABLE_CREATE"] = 1001;
        return values;
    })();

    base.PlayerInfoChange = (function() {

        /**
         * Properties of a PlayerInfoChange.
         * @memberof base
         * @interface IPlayerInfoChange
         * @property {number|null} [vipLevel] PlayerInfoChange vipLevel
         * @property {number|null} [vipLevelBefore] PlayerInfoChange vipLevelBefore
         * @property {string|null} [headId] PlayerInfoChange headId
         * @property {string|null} [headUrl] PlayerInfoChange headUrl
         * @property {number|null} [tag] PlayerInfoChange tag
         * @property {number|null} [avatarFrame] PlayerInfoChange avatarFrame
         * @property {number|null} [cardBack] PlayerInfoChange cardBack
         */

        /**
         * Constructs a new PlayerInfoChange.
         * @memberof base
         * @classdesc Represents a PlayerInfoChange.
         * @implements IPlayerInfoChange
         * @constructor
         * @param {base.IPlayerInfoChange=} [properties] Properties to set
         */
        function PlayerInfoChange(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerInfoChange vipLevel.
         * @member {number} vipLevel
         * @memberof base.PlayerInfoChange
         * @instance
         */
        PlayerInfoChange.prototype.vipLevel = 0;

        /**
         * PlayerInfoChange vipLevelBefore.
         * @member {number} vipLevelBefore
         * @memberof base.PlayerInfoChange
         * @instance
         */
        PlayerInfoChange.prototype.vipLevelBefore = 0;

        /**
         * PlayerInfoChange headId.
         * @member {string} headId
         * @memberof base.PlayerInfoChange
         * @instance
         */
        PlayerInfoChange.prototype.headId = "";

        /**
         * PlayerInfoChange headUrl.
         * @member {string} headUrl
         * @memberof base.PlayerInfoChange
         * @instance
         */
        PlayerInfoChange.prototype.headUrl = "";

        /**
         * PlayerInfoChange tag.
         * @member {number} tag
         * @memberof base.PlayerInfoChange
         * @instance
         */
        PlayerInfoChange.prototype.tag = 0;

        /**
         * PlayerInfoChange avatarFrame.
         * @member {number} avatarFrame
         * @memberof base.PlayerInfoChange
         * @instance
         */
        PlayerInfoChange.prototype.avatarFrame = 0;

        /**
         * PlayerInfoChange cardBack.
         * @member {number} cardBack
         * @memberof base.PlayerInfoChange
         * @instance
         */
        PlayerInfoChange.prototype.cardBack = 0;

        /**
         * Creates a new PlayerInfoChange instance using the specified properties.
         * @function create
         * @memberof base.PlayerInfoChange
         * @static
         * @param {base.IPlayerInfoChange=} [properties] Properties to set
         * @returns {base.PlayerInfoChange} PlayerInfoChange instance
         */
        PlayerInfoChange.create = function create(properties) {
            return new PlayerInfoChange(properties);
        };

        /**
         * Encodes the specified PlayerInfoChange message. Does not implicitly {@link base.PlayerInfoChange.verify|verify} messages.
         * @function encode
         * @memberof base.PlayerInfoChange
         * @static
         * @param {base.IPlayerInfoChange} message PlayerInfoChange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerInfoChange.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.vipLevel != null && Object.hasOwnProperty.call(message, "vipLevel"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.vipLevel);
            if (message.vipLevelBefore != null && Object.hasOwnProperty.call(message, "vipLevelBefore"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.vipLevelBefore);
            if (message.headId != null && Object.hasOwnProperty.call(message, "headId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.headId);
            if (message.headUrl != null && Object.hasOwnProperty.call(message, "headUrl"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.headUrl);
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.tag);
            if (message.avatarFrame != null && Object.hasOwnProperty.call(message, "avatarFrame"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.avatarFrame);
            if (message.cardBack != null && Object.hasOwnProperty.call(message, "cardBack"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.cardBack);
            return writer;
        };

        /**
         * Encodes the specified PlayerInfoChange message, length delimited. Does not implicitly {@link base.PlayerInfoChange.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.PlayerInfoChange
         * @static
         * @param {base.IPlayerInfoChange} message PlayerInfoChange message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerInfoChange.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerInfoChange message from the specified reader or buffer.
         * @function decode
         * @memberof base.PlayerInfoChange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.PlayerInfoChange} PlayerInfoChange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerInfoChange.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.PlayerInfoChange();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.vipLevel = reader.int32();
                    break;
                case 2:
                    message.vipLevelBefore = reader.int32();
                    break;
                case 3:
                    message.headId = reader.string();
                    break;
                case 4:
                    message.headUrl = reader.string();
                    break;
                case 5:
                    message.tag = reader.int32();
                    break;
                case 6:
                    message.avatarFrame = reader.int32();
                    break;
                case 7:
                    message.cardBack = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerInfoChange message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.PlayerInfoChange
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.PlayerInfoChange} PlayerInfoChange
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerInfoChange.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerInfoChange message.
         * @function verify
         * @memberof base.PlayerInfoChange
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerInfoChange.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.vipLevel != null && message.hasOwnProperty("vipLevel"))
                if (!$util.isInteger(message.vipLevel))
                    return "vipLevel: integer expected";
            if (message.vipLevelBefore != null && message.hasOwnProperty("vipLevelBefore"))
                if (!$util.isInteger(message.vipLevelBefore))
                    return "vipLevelBefore: integer expected";
            if (message.headId != null && message.hasOwnProperty("headId"))
                if (!$util.isString(message.headId))
                    return "headId: string expected";
            if (message.headUrl != null && message.hasOwnProperty("headUrl"))
                if (!$util.isString(message.headUrl))
                    return "headUrl: string expected";
            if (message.tag != null && message.hasOwnProperty("tag"))
                if (!$util.isInteger(message.tag))
                    return "tag: integer expected";
            if (message.avatarFrame != null && message.hasOwnProperty("avatarFrame"))
                if (!$util.isInteger(message.avatarFrame))
                    return "avatarFrame: integer expected";
            if (message.cardBack != null && message.hasOwnProperty("cardBack"))
                if (!$util.isInteger(message.cardBack))
                    return "cardBack: integer expected";
            return null;
        };

        /**
         * Creates a PlayerInfoChange message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.PlayerInfoChange
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.PlayerInfoChange} PlayerInfoChange
         */
        PlayerInfoChange.fromObject = function fromObject(object) {
            if (object instanceof $root.base.PlayerInfoChange)
                return object;
            var message = new $root.base.PlayerInfoChange();
            if (object.vipLevel != null)
                message.vipLevel = object.vipLevel | 0;
            if (object.vipLevelBefore != null)
                message.vipLevelBefore = object.vipLevelBefore | 0;
            if (object.headId != null)
                message.headId = String(object.headId);
            if (object.headUrl != null)
                message.headUrl = String(object.headUrl);
            if (object.tag != null)
                message.tag = object.tag | 0;
            if (object.avatarFrame != null)
                message.avatarFrame = object.avatarFrame | 0;
            if (object.cardBack != null)
                message.cardBack = object.cardBack | 0;
            return message;
        };

        /**
         * Creates a plain object from a PlayerInfoChange message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.PlayerInfoChange
         * @static
         * @param {base.PlayerInfoChange} message PlayerInfoChange
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerInfoChange.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.vipLevel = 0;
                object.vipLevelBefore = 0;
                object.headId = "";
                object.headUrl = "";
                object.tag = 0;
                object.avatarFrame = 0;
                object.cardBack = 0;
            }
            if (message.vipLevel != null && message.hasOwnProperty("vipLevel"))
                object.vipLevel = message.vipLevel;
            if (message.vipLevelBefore != null && message.hasOwnProperty("vipLevelBefore"))
                object.vipLevelBefore = message.vipLevelBefore;
            if (message.headId != null && message.hasOwnProperty("headId"))
                object.headId = message.headId;
            if (message.headUrl != null && message.hasOwnProperty("headUrl"))
                object.headUrl = message.headUrl;
            if (message.tag != null && message.hasOwnProperty("tag"))
                object.tag = message.tag;
            if (message.avatarFrame != null && message.hasOwnProperty("avatarFrame"))
                object.avatarFrame = message.avatarFrame;
            if (message.cardBack != null && message.hasOwnProperty("cardBack"))
                object.cardBack = message.cardBack;
            return object;
        };

        /**
         * Converts this PlayerInfoChange to JSON.
         * @function toJSON
         * @memberof base.PlayerInfoChange
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerInfoChange.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerInfoChange;
    })();

    base.RoomListReq = (function() {

        /**
         * Properties of a RoomListReq.
         * @memberof base
         * @interface IRoomListReq
         * @property {number|null} [gameId] RoomListReq gameId
         */

        /**
         * Constructs a new RoomListReq.
         * @memberof base
         * @classdesc Represents a RoomListReq.
         * @implements IRoomListReq
         * @constructor
         * @param {base.IRoomListReq=} [properties] Properties to set
         */
        function RoomListReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RoomListReq gameId.
         * @member {number} gameId
         * @memberof base.RoomListReq
         * @instance
         */
        RoomListReq.prototype.gameId = 0;

        /**
         * Creates a new RoomListReq instance using the specified properties.
         * @function create
         * @memberof base.RoomListReq
         * @static
         * @param {base.IRoomListReq=} [properties] Properties to set
         * @returns {base.RoomListReq} RoomListReq instance
         */
        RoomListReq.create = function create(properties) {
            return new RoomListReq(properties);
        };

        /**
         * Encodes the specified RoomListReq message. Does not implicitly {@link base.RoomListReq.verify|verify} messages.
         * @function encode
         * @memberof base.RoomListReq
         * @static
         * @param {base.IRoomListReq} message RoomListReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoomListReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameId != null && Object.hasOwnProperty.call(message, "gameId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gameId);
            return writer;
        };

        /**
         * Encodes the specified RoomListReq message, length delimited. Does not implicitly {@link base.RoomListReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.RoomListReq
         * @static
         * @param {base.IRoomListReq} message RoomListReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoomListReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RoomListReq message from the specified reader or buffer.
         * @function decode
         * @memberof base.RoomListReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.RoomListReq} RoomListReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoomListReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.RoomListReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RoomListReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.RoomListReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.RoomListReq} RoomListReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoomListReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RoomListReq message.
         * @function verify
         * @memberof base.RoomListReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RoomListReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                if (!$util.isInteger(message.gameId))
                    return "gameId: integer expected";
            return null;
        };

        /**
         * Creates a RoomListReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.RoomListReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.RoomListReq} RoomListReq
         */
        RoomListReq.fromObject = function fromObject(object) {
            if (object instanceof $root.base.RoomListReq)
                return object;
            var message = new $root.base.RoomListReq();
            if (object.gameId != null)
                message.gameId = object.gameId | 0;
            return message;
        };

        /**
         * Creates a plain object from a RoomListReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.RoomListReq
         * @static
         * @param {base.RoomListReq} message RoomListReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RoomListReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.gameId = 0;
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                object.gameId = message.gameId;
            return object;
        };

        /**
         * Converts this RoomListReq to JSON.
         * @function toJSON
         * @memberof base.RoomListReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RoomListReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RoomListReq;
    })();

    base.RoomListReply = (function() {

        /**
         * Properties of a RoomListReply.
         * @memberof base
         * @interface IRoomListReply
         * @property {number|null} [gameId] RoomListReply gameId
         * @property {Array.<base.IRoomInfo>|null} [roomInfos] RoomListReply roomInfos
         */

        /**
         * Constructs a new RoomListReply.
         * @memberof base
         * @classdesc Represents a RoomListReply.
         * @implements IRoomListReply
         * @constructor
         * @param {base.IRoomListReply=} [properties] Properties to set
         */
        function RoomListReply(properties) {
            this.roomInfos = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RoomListReply gameId.
         * @member {number} gameId
         * @memberof base.RoomListReply
         * @instance
         */
        RoomListReply.prototype.gameId = 0;

        /**
         * RoomListReply roomInfos.
         * @member {Array.<base.IRoomInfo>} roomInfos
         * @memberof base.RoomListReply
         * @instance
         */
        RoomListReply.prototype.roomInfos = $util.emptyArray;

        /**
         * Creates a new RoomListReply instance using the specified properties.
         * @function create
         * @memberof base.RoomListReply
         * @static
         * @param {base.IRoomListReply=} [properties] Properties to set
         * @returns {base.RoomListReply} RoomListReply instance
         */
        RoomListReply.create = function create(properties) {
            return new RoomListReply(properties);
        };

        /**
         * Encodes the specified RoomListReply message. Does not implicitly {@link base.RoomListReply.verify|verify} messages.
         * @function encode
         * @memberof base.RoomListReply
         * @static
         * @param {base.IRoomListReply} message RoomListReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoomListReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameId != null && Object.hasOwnProperty.call(message, "gameId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gameId);
            if (message.roomInfos != null && message.roomInfos.length)
                for (var i = 0; i < message.roomInfos.length; ++i)
                    $root.base.RoomInfo.encode(message.roomInfos[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RoomListReply message, length delimited. Does not implicitly {@link base.RoomListReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.RoomListReply
         * @static
         * @param {base.IRoomListReply} message RoomListReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoomListReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RoomListReply message from the specified reader or buffer.
         * @function decode
         * @memberof base.RoomListReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.RoomListReply} RoomListReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoomListReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.RoomListReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameId = reader.int32();
                    break;
                case 2:
                    if (!(message.roomInfos && message.roomInfos.length))
                        message.roomInfos = [];
                    message.roomInfos.push($root.base.RoomInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RoomListReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.RoomListReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.RoomListReply} RoomListReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoomListReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RoomListReply message.
         * @function verify
         * @memberof base.RoomListReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RoomListReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                if (!$util.isInteger(message.gameId))
                    return "gameId: integer expected";
            if (message.roomInfos != null && message.hasOwnProperty("roomInfos")) {
                if (!Array.isArray(message.roomInfos))
                    return "roomInfos: array expected";
                for (var i = 0; i < message.roomInfos.length; ++i) {
                    var error = $root.base.RoomInfo.verify(message.roomInfos[i]);
                    if (error)
                        return "roomInfos." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RoomListReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.RoomListReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.RoomListReply} RoomListReply
         */
        RoomListReply.fromObject = function fromObject(object) {
            if (object instanceof $root.base.RoomListReply)
                return object;
            var message = new $root.base.RoomListReply();
            if (object.gameId != null)
                message.gameId = object.gameId | 0;
            if (object.roomInfos) {
                if (!Array.isArray(object.roomInfos))
                    throw TypeError(".base.RoomListReply.roomInfos: array expected");
                message.roomInfos = [];
                for (var i = 0; i < object.roomInfos.length; ++i) {
                    if (typeof object.roomInfos[i] !== "object")
                        throw TypeError(".base.RoomListReply.roomInfos: object expected");
                    message.roomInfos[i] = $root.base.RoomInfo.fromObject(object.roomInfos[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RoomListReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.RoomListReply
         * @static
         * @param {base.RoomListReply} message RoomListReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RoomListReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.roomInfos = [];
            if (options.defaults)
                object.gameId = 0;
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                object.gameId = message.gameId;
            if (message.roomInfos && message.roomInfos.length) {
                object.roomInfos = [];
                for (var j = 0; j < message.roomInfos.length; ++j)
                    object.roomInfos[j] = $root.base.RoomInfo.toObject(message.roomInfos[j], options);
            }
            return object;
        };

        /**
         * Converts this RoomListReply to JSON.
         * @function toJSON
         * @memberof base.RoomListReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RoomListReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RoomListReply;
    })();

    base.RoomInfo = (function() {

        /**
         * Properties of a RoomInfo.
         * @memberof base
         * @interface IRoomInfo
         * @property {number|null} [roomId] RoomInfo roomId
         * @property {string|null} [roomName] RoomInfo roomName
         * @property {number|null} [initBet] RoomInfo initBet
         * @property {number|null} [minEnter] RoomInfo minEnter
         * @property {number|null} [minPlayerNum] RoomInfo minPlayerNum
         * @property {number|null} [maxPlayerNum] RoomInfo maxPlayerNum
         * @property {number|null} [playerNumLimit] RoomInfo playerNumLimit
         * @property {number|null} [playerNum] RoomInfo playerNum
         * @property {string|null} [roomParams] RoomInfo roomParams
         */

        /**
         * Constructs a new RoomInfo.
         * @memberof base
         * @classdesc Represents a RoomInfo.
         * @implements IRoomInfo
         * @constructor
         * @param {base.IRoomInfo=} [properties] Properties to set
         */
        function RoomInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RoomInfo roomId.
         * @member {number} roomId
         * @memberof base.RoomInfo
         * @instance
         */
        RoomInfo.prototype.roomId = 0;

        /**
         * RoomInfo roomName.
         * @member {string} roomName
         * @memberof base.RoomInfo
         * @instance
         */
        RoomInfo.prototype.roomName = "";

        /**
         * RoomInfo initBet.
         * @member {number} initBet
         * @memberof base.RoomInfo
         * @instance
         */
        RoomInfo.prototype.initBet = 0;

        /**
         * RoomInfo minEnter.
         * @member {number} minEnter
         * @memberof base.RoomInfo
         * @instance
         */
        RoomInfo.prototype.minEnter = 0;

        /**
         * RoomInfo minPlayerNum.
         * @member {number} minPlayerNum
         * @memberof base.RoomInfo
         * @instance
         */
        RoomInfo.prototype.minPlayerNum = 0;

        /**
         * RoomInfo maxPlayerNum.
         * @member {number} maxPlayerNum
         * @memberof base.RoomInfo
         * @instance
         */
        RoomInfo.prototype.maxPlayerNum = 0;

        /**
         * RoomInfo playerNumLimit.
         * @member {number} playerNumLimit
         * @memberof base.RoomInfo
         * @instance
         */
        RoomInfo.prototype.playerNumLimit = 0;

        /**
         * RoomInfo playerNum.
         * @member {number} playerNum
         * @memberof base.RoomInfo
         * @instance
         */
        RoomInfo.prototype.playerNum = 0;

        /**
         * RoomInfo roomParams.
         * @member {string} roomParams
         * @memberof base.RoomInfo
         * @instance
         */
        RoomInfo.prototype.roomParams = "";

        /**
         * Creates a new RoomInfo instance using the specified properties.
         * @function create
         * @memberof base.RoomInfo
         * @static
         * @param {base.IRoomInfo=} [properties] Properties to set
         * @returns {base.RoomInfo} RoomInfo instance
         */
        RoomInfo.create = function create(properties) {
            return new RoomInfo(properties);
        };

        /**
         * Encodes the specified RoomInfo message. Does not implicitly {@link base.RoomInfo.verify|verify} messages.
         * @function encode
         * @memberof base.RoomInfo
         * @static
         * @param {base.IRoomInfo} message RoomInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoomInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.roomId);
            if (message.roomName != null && Object.hasOwnProperty.call(message, "roomName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.roomName);
            if (message.initBet != null && Object.hasOwnProperty.call(message, "initBet"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.initBet);
            if (message.minEnter != null && Object.hasOwnProperty.call(message, "minEnter"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.minEnter);
            if (message.minPlayerNum != null && Object.hasOwnProperty.call(message, "minPlayerNum"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.minPlayerNum);
            if (message.maxPlayerNum != null && Object.hasOwnProperty.call(message, "maxPlayerNum"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.maxPlayerNum);
            if (message.playerNumLimit != null && Object.hasOwnProperty.call(message, "playerNumLimit"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.playerNumLimit);
            if (message.playerNum != null && Object.hasOwnProperty.call(message, "playerNum"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.playerNum);
            if (message.roomParams != null && Object.hasOwnProperty.call(message, "roomParams"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.roomParams);
            return writer;
        };

        /**
         * Encodes the specified RoomInfo message, length delimited. Does not implicitly {@link base.RoomInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.RoomInfo
         * @static
         * @param {base.IRoomInfo} message RoomInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoomInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RoomInfo message from the specified reader or buffer.
         * @function decode
         * @memberof base.RoomInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.RoomInfo} RoomInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoomInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.RoomInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.int32();
                    break;
                case 2:
                    message.roomName = reader.string();
                    break;
                case 3:
                    message.initBet = reader.int32();
                    break;
                case 4:
                    message.minEnter = reader.int32();
                    break;
                case 5:
                    message.minPlayerNum = reader.int32();
                    break;
                case 6:
                    message.maxPlayerNum = reader.int32();
                    break;
                case 7:
                    message.playerNumLimit = reader.int32();
                    break;
                case 8:
                    message.playerNum = reader.int32();
                    break;
                case 9:
                    message.roomParams = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RoomInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.RoomInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.RoomInfo} RoomInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoomInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RoomInfo message.
         * @function verify
         * @memberof base.RoomInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RoomInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                if (!$util.isInteger(message.roomId))
                    return "roomId: integer expected";
            if (message.roomName != null && message.hasOwnProperty("roomName"))
                if (!$util.isString(message.roomName))
                    return "roomName: string expected";
            if (message.initBet != null && message.hasOwnProperty("initBet"))
                if (!$util.isInteger(message.initBet))
                    return "initBet: integer expected";
            if (message.minEnter != null && message.hasOwnProperty("minEnter"))
                if (!$util.isInteger(message.minEnter))
                    return "minEnter: integer expected";
            if (message.minPlayerNum != null && message.hasOwnProperty("minPlayerNum"))
                if (!$util.isInteger(message.minPlayerNum))
                    return "minPlayerNum: integer expected";
            if (message.maxPlayerNum != null && message.hasOwnProperty("maxPlayerNum"))
                if (!$util.isInteger(message.maxPlayerNum))
                    return "maxPlayerNum: integer expected";
            if (message.playerNumLimit != null && message.hasOwnProperty("playerNumLimit"))
                if (!$util.isInteger(message.playerNumLimit))
                    return "playerNumLimit: integer expected";
            if (message.playerNum != null && message.hasOwnProperty("playerNum"))
                if (!$util.isInteger(message.playerNum))
                    return "playerNum: integer expected";
            if (message.roomParams != null && message.hasOwnProperty("roomParams"))
                if (!$util.isString(message.roomParams))
                    return "roomParams: string expected";
            return null;
        };

        /**
         * Creates a RoomInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.RoomInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.RoomInfo} RoomInfo
         */
        RoomInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.base.RoomInfo)
                return object;
            var message = new $root.base.RoomInfo();
            if (object.roomId != null)
                message.roomId = object.roomId | 0;
            if (object.roomName != null)
                message.roomName = String(object.roomName);
            if (object.initBet != null)
                message.initBet = object.initBet | 0;
            if (object.minEnter != null)
                message.minEnter = object.minEnter | 0;
            if (object.minPlayerNum != null)
                message.minPlayerNum = object.minPlayerNum | 0;
            if (object.maxPlayerNum != null)
                message.maxPlayerNum = object.maxPlayerNum | 0;
            if (object.playerNumLimit != null)
                message.playerNumLimit = object.playerNumLimit | 0;
            if (object.playerNum != null)
                message.playerNum = object.playerNum | 0;
            if (object.roomParams != null)
                message.roomParams = String(object.roomParams);
            return message;
        };

        /**
         * Creates a plain object from a RoomInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.RoomInfo
         * @static
         * @param {base.RoomInfo} message RoomInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RoomInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.roomId = 0;
                object.roomName = "";
                object.initBet = 0;
                object.minEnter = 0;
                object.minPlayerNum = 0;
                object.maxPlayerNum = 0;
                object.playerNumLimit = 0;
                object.playerNum = 0;
                object.roomParams = "";
            }
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                object.roomId = message.roomId;
            if (message.roomName != null && message.hasOwnProperty("roomName"))
                object.roomName = message.roomName;
            if (message.initBet != null && message.hasOwnProperty("initBet"))
                object.initBet = message.initBet;
            if (message.minEnter != null && message.hasOwnProperty("minEnter"))
                object.minEnter = message.minEnter;
            if (message.minPlayerNum != null && message.hasOwnProperty("minPlayerNum"))
                object.minPlayerNum = message.minPlayerNum;
            if (message.maxPlayerNum != null && message.hasOwnProperty("maxPlayerNum"))
                object.maxPlayerNum = message.maxPlayerNum;
            if (message.playerNumLimit != null && message.hasOwnProperty("playerNumLimit"))
                object.playerNumLimit = message.playerNumLimit;
            if (message.playerNum != null && message.hasOwnProperty("playerNum"))
                object.playerNum = message.playerNum;
            if (message.roomParams != null && message.hasOwnProperty("roomParams"))
                object.roomParams = message.roomParams;
            return object;
        };

        /**
         * Converts this RoomInfo to JSON.
         * @function toJSON
         * @memberof base.RoomInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RoomInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RoomInfo;
    })();

    base.EnterRoomReq = (function() {

        /**
         * Properties of an EnterRoomReq.
         * @memberof base
         * @interface IEnterRoomReq
         * @property {number|null} [gameId] EnterRoomReq gameId
         * @property {number|null} [roomId] EnterRoomReq roomId
         * @property {number|null} [roomType] EnterRoomReq roomType
         * @property {number|null} [matchType] EnterRoomReq matchType
         * @property {number|null} [tableId] EnterRoomReq tableId
         * @property {number|null} [tableNo] EnterRoomReq tableNo
         * @property {number|null} [initBet] EnterRoomReq initBet
         * @property {number|null} [minPlayer] EnterRoomReq minPlayer
         * @property {number|null} [maxPlayer] EnterRoomReq maxPlayer
         */

        /**
         * Constructs a new EnterRoomReq.
         * @memberof base
         * @classdesc Represents an EnterRoomReq.
         * @implements IEnterRoomReq
         * @constructor
         * @param {base.IEnterRoomReq=} [properties] Properties to set
         */
        function EnterRoomReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnterRoomReq gameId.
         * @member {number} gameId
         * @memberof base.EnterRoomReq
         * @instance
         */
        EnterRoomReq.prototype.gameId = 0;

        /**
         * EnterRoomReq roomId.
         * @member {number} roomId
         * @memberof base.EnterRoomReq
         * @instance
         */
        EnterRoomReq.prototype.roomId = 0;

        /**
         * EnterRoomReq roomType.
         * @member {number} roomType
         * @memberof base.EnterRoomReq
         * @instance
         */
        EnterRoomReq.prototype.roomType = 0;

        /**
         * EnterRoomReq matchType.
         * @member {number} matchType
         * @memberof base.EnterRoomReq
         * @instance
         */
        EnterRoomReq.prototype.matchType = 0;

        /**
         * EnterRoomReq tableId.
         * @member {number} tableId
         * @memberof base.EnterRoomReq
         * @instance
         */
        EnterRoomReq.prototype.tableId = 0;

        /**
         * EnterRoomReq tableNo.
         * @member {number} tableNo
         * @memberof base.EnterRoomReq
         * @instance
         */
        EnterRoomReq.prototype.tableNo = 0;

        /**
         * EnterRoomReq initBet.
         * @member {number} initBet
         * @memberof base.EnterRoomReq
         * @instance
         */
        EnterRoomReq.prototype.initBet = 0;

        /**
         * EnterRoomReq minPlayer.
         * @member {number} minPlayer
         * @memberof base.EnterRoomReq
         * @instance
         */
        EnterRoomReq.prototype.minPlayer = 0;

        /**
         * EnterRoomReq maxPlayer.
         * @member {number} maxPlayer
         * @memberof base.EnterRoomReq
         * @instance
         */
        EnterRoomReq.prototype.maxPlayer = 0;

        /**
         * Creates a new EnterRoomReq instance using the specified properties.
         * @function create
         * @memberof base.EnterRoomReq
         * @static
         * @param {base.IEnterRoomReq=} [properties] Properties to set
         * @returns {base.EnterRoomReq} EnterRoomReq instance
         */
        EnterRoomReq.create = function create(properties) {
            return new EnterRoomReq(properties);
        };

        /**
         * Encodes the specified EnterRoomReq message. Does not implicitly {@link base.EnterRoomReq.verify|verify} messages.
         * @function encode
         * @memberof base.EnterRoomReq
         * @static
         * @param {base.IEnterRoomReq} message EnterRoomReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterRoomReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameId != null && Object.hasOwnProperty.call(message, "gameId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gameId);
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.roomId);
            if (message.roomType != null && Object.hasOwnProperty.call(message, "roomType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.roomType);
            if (message.matchType != null && Object.hasOwnProperty.call(message, "matchType"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.matchType);
            if (message.tableId != null && Object.hasOwnProperty.call(message, "tableId"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.tableId);
            if (message.tableNo != null && Object.hasOwnProperty.call(message, "tableNo"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.tableNo);
            if (message.initBet != null && Object.hasOwnProperty.call(message, "initBet"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.initBet);
            if (message.minPlayer != null && Object.hasOwnProperty.call(message, "minPlayer"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.minPlayer);
            if (message.maxPlayer != null && Object.hasOwnProperty.call(message, "maxPlayer"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.maxPlayer);
            return writer;
        };

        /**
         * Encodes the specified EnterRoomReq message, length delimited. Does not implicitly {@link base.EnterRoomReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.EnterRoomReq
         * @static
         * @param {base.IEnterRoomReq} message EnterRoomReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterRoomReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnterRoomReq message from the specified reader or buffer.
         * @function decode
         * @memberof base.EnterRoomReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.EnterRoomReq} EnterRoomReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterRoomReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.EnterRoomReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameId = reader.int32();
                    break;
                case 2:
                    message.roomId = reader.int32();
                    break;
                case 4:
                    message.roomType = reader.int32();
                    break;
                case 5:
                    message.matchType = reader.int32();
                    break;
                case 6:
                    message.tableId = reader.int32();
                    break;
                case 7:
                    message.tableNo = reader.int32();
                    break;
                case 8:
                    message.initBet = reader.int32();
                    break;
                case 9:
                    message.minPlayer = reader.int32();
                    break;
                case 10:
                    message.maxPlayer = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnterRoomReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.EnterRoomReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.EnterRoomReq} EnterRoomReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterRoomReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnterRoomReq message.
         * @function verify
         * @memberof base.EnterRoomReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnterRoomReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                if (!$util.isInteger(message.gameId))
                    return "gameId: integer expected";
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                if (!$util.isInteger(message.roomId))
                    return "roomId: integer expected";
            if (message.roomType != null && message.hasOwnProperty("roomType"))
                if (!$util.isInteger(message.roomType))
                    return "roomType: integer expected";
            if (message.matchType != null && message.hasOwnProperty("matchType"))
                if (!$util.isInteger(message.matchType))
                    return "matchType: integer expected";
            if (message.tableId != null && message.hasOwnProperty("tableId"))
                if (!$util.isInteger(message.tableId))
                    return "tableId: integer expected";
            if (message.tableNo != null && message.hasOwnProperty("tableNo"))
                if (!$util.isInteger(message.tableNo))
                    return "tableNo: integer expected";
            if (message.initBet != null && message.hasOwnProperty("initBet"))
                if (!$util.isInteger(message.initBet))
                    return "initBet: integer expected";
            if (message.minPlayer != null && message.hasOwnProperty("minPlayer"))
                if (!$util.isInteger(message.minPlayer))
                    return "minPlayer: integer expected";
            if (message.maxPlayer != null && message.hasOwnProperty("maxPlayer"))
                if (!$util.isInteger(message.maxPlayer))
                    return "maxPlayer: integer expected";
            return null;
        };

        /**
         * Creates an EnterRoomReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.EnterRoomReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.EnterRoomReq} EnterRoomReq
         */
        EnterRoomReq.fromObject = function fromObject(object) {
            if (object instanceof $root.base.EnterRoomReq)
                return object;
            var message = new $root.base.EnterRoomReq();
            if (object.gameId != null)
                message.gameId = object.gameId | 0;
            if (object.roomId != null)
                message.roomId = object.roomId | 0;
            if (object.roomType != null)
                message.roomType = object.roomType | 0;
            if (object.matchType != null)
                message.matchType = object.matchType | 0;
            if (object.tableId != null)
                message.tableId = object.tableId | 0;
            if (object.tableNo != null)
                message.tableNo = object.tableNo | 0;
            if (object.initBet != null)
                message.initBet = object.initBet | 0;
            if (object.minPlayer != null)
                message.minPlayer = object.minPlayer | 0;
            if (object.maxPlayer != null)
                message.maxPlayer = object.maxPlayer | 0;
            return message;
        };

        /**
         * Creates a plain object from an EnterRoomReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.EnterRoomReq
         * @static
         * @param {base.EnterRoomReq} message EnterRoomReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnterRoomReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameId = 0;
                object.roomId = 0;
                object.roomType = 0;
                object.matchType = 0;
                object.tableId = 0;
                object.tableNo = 0;
                object.initBet = 0;
                object.minPlayer = 0;
                object.maxPlayer = 0;
            }
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                object.gameId = message.gameId;
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                object.roomId = message.roomId;
            if (message.roomType != null && message.hasOwnProperty("roomType"))
                object.roomType = message.roomType;
            if (message.matchType != null && message.hasOwnProperty("matchType"))
                object.matchType = message.matchType;
            if (message.tableId != null && message.hasOwnProperty("tableId"))
                object.tableId = message.tableId;
            if (message.tableNo != null && message.hasOwnProperty("tableNo"))
                object.tableNo = message.tableNo;
            if (message.initBet != null && message.hasOwnProperty("initBet"))
                object.initBet = message.initBet;
            if (message.minPlayer != null && message.hasOwnProperty("minPlayer"))
                object.minPlayer = message.minPlayer;
            if (message.maxPlayer != null && message.hasOwnProperty("maxPlayer"))
                object.maxPlayer = message.maxPlayer;
            return object;
        };

        /**
         * Converts this EnterRoomReq to JSON.
         * @function toJSON
         * @memberof base.EnterRoomReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnterRoomReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EnterRoomReq;
    })();

    base.EnterRoomReply = (function() {

        /**
         * Properties of an EnterRoomReply.
         * @memberof base
         * @interface IEnterRoomReply
         * @property {number|null} [gameId] EnterRoomReply gameId
         * @property {number|null} [roomId] EnterRoomReply roomId
         * @property {base.IRoomInfo|null} [roomInfo] EnterRoomReply roomInfo
         * @property {number|null} [tableId] EnterRoomReply tableId
         * @property {number|null} [seat] EnterRoomReply seat
         * @property {number|null} [enterReason] EnterRoomReply enterReason
         * @property {number|null} [roomType] EnterRoomReply roomType
         * @property {string|null} [gameUrl] EnterRoomReply gameUrl
         * @property {number|null} [tableNo] EnterRoomReply tableNo
         */

        /**
         * Constructs a new EnterRoomReply.
         * @memberof base
         * @classdesc Represents an EnterRoomReply.
         * @implements IEnterRoomReply
         * @constructor
         * @param {base.IEnterRoomReply=} [properties] Properties to set
         */
        function EnterRoomReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnterRoomReply gameId.
         * @member {number} gameId
         * @memberof base.EnterRoomReply
         * @instance
         */
        EnterRoomReply.prototype.gameId = 0;

        /**
         * EnterRoomReply roomId.
         * @member {number} roomId
         * @memberof base.EnterRoomReply
         * @instance
         */
        EnterRoomReply.prototype.roomId = 0;

        /**
         * EnterRoomReply roomInfo.
         * @member {base.IRoomInfo|null|undefined} roomInfo
         * @memberof base.EnterRoomReply
         * @instance
         */
        EnterRoomReply.prototype.roomInfo = null;

        /**
         * EnterRoomReply tableId.
         * @member {number} tableId
         * @memberof base.EnterRoomReply
         * @instance
         */
        EnterRoomReply.prototype.tableId = 0;

        /**
         * EnterRoomReply seat.
         * @member {number} seat
         * @memberof base.EnterRoomReply
         * @instance
         */
        EnterRoomReply.prototype.seat = 0;

        /**
         * EnterRoomReply enterReason.
         * @member {number} enterReason
         * @memberof base.EnterRoomReply
         * @instance
         */
        EnterRoomReply.prototype.enterReason = 0;

        /**
         * EnterRoomReply roomType.
         * @member {number} roomType
         * @memberof base.EnterRoomReply
         * @instance
         */
        EnterRoomReply.prototype.roomType = 0;

        /**
         * EnterRoomReply gameUrl.
         * @member {string} gameUrl
         * @memberof base.EnterRoomReply
         * @instance
         */
        EnterRoomReply.prototype.gameUrl = "";

        /**
         * EnterRoomReply tableNo.
         * @member {number} tableNo
         * @memberof base.EnterRoomReply
         * @instance
         */
        EnterRoomReply.prototype.tableNo = 0;

        /**
         * Creates a new EnterRoomReply instance using the specified properties.
         * @function create
         * @memberof base.EnterRoomReply
         * @static
         * @param {base.IEnterRoomReply=} [properties] Properties to set
         * @returns {base.EnterRoomReply} EnterRoomReply instance
         */
        EnterRoomReply.create = function create(properties) {
            return new EnterRoomReply(properties);
        };

        /**
         * Encodes the specified EnterRoomReply message. Does not implicitly {@link base.EnterRoomReply.verify|verify} messages.
         * @function encode
         * @memberof base.EnterRoomReply
         * @static
         * @param {base.IEnterRoomReply} message EnterRoomReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterRoomReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameId != null && Object.hasOwnProperty.call(message, "gameId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gameId);
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.roomId);
            if (message.roomInfo != null && Object.hasOwnProperty.call(message, "roomInfo"))
                $root.base.RoomInfo.encode(message.roomInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.tableId != null && Object.hasOwnProperty.call(message, "tableId"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.tableId);
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.seat);
            if (message.enterReason != null && Object.hasOwnProperty.call(message, "enterReason"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.enterReason);
            if (message.roomType != null && Object.hasOwnProperty.call(message, "roomType"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.roomType);
            if (message.gameUrl != null && Object.hasOwnProperty.call(message, "gameUrl"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.gameUrl);
            if (message.tableNo != null && Object.hasOwnProperty.call(message, "tableNo"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.tableNo);
            return writer;
        };

        /**
         * Encodes the specified EnterRoomReply message, length delimited. Does not implicitly {@link base.EnterRoomReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.EnterRoomReply
         * @static
         * @param {base.IEnterRoomReply} message EnterRoomReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnterRoomReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnterRoomReply message from the specified reader or buffer.
         * @function decode
         * @memberof base.EnterRoomReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.EnterRoomReply} EnterRoomReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterRoomReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.EnterRoomReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameId = reader.int32();
                    break;
                case 2:
                    message.roomId = reader.int32();
                    break;
                case 3:
                    message.roomInfo = $root.base.RoomInfo.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.tableId = reader.int32();
                    break;
                case 5:
                    message.seat = reader.int32();
                    break;
                case 6:
                    message.enterReason = reader.int32();
                    break;
                case 7:
                    message.roomType = reader.int32();
                    break;
                case 9:
                    message.gameUrl = reader.string();
                    break;
                case 10:
                    message.tableNo = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnterRoomReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.EnterRoomReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.EnterRoomReply} EnterRoomReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnterRoomReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnterRoomReply message.
         * @function verify
         * @memberof base.EnterRoomReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnterRoomReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                if (!$util.isInteger(message.gameId))
                    return "gameId: integer expected";
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                if (!$util.isInteger(message.roomId))
                    return "roomId: integer expected";
            if (message.roomInfo != null && message.hasOwnProperty("roomInfo")) {
                var error = $root.base.RoomInfo.verify(message.roomInfo);
                if (error)
                    return "roomInfo." + error;
            }
            if (message.tableId != null && message.hasOwnProperty("tableId"))
                if (!$util.isInteger(message.tableId))
                    return "tableId: integer expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.enterReason != null && message.hasOwnProperty("enterReason"))
                if (!$util.isInteger(message.enterReason))
                    return "enterReason: integer expected";
            if (message.roomType != null && message.hasOwnProperty("roomType"))
                if (!$util.isInteger(message.roomType))
                    return "roomType: integer expected";
            if (message.gameUrl != null && message.hasOwnProperty("gameUrl"))
                if (!$util.isString(message.gameUrl))
                    return "gameUrl: string expected";
            if (message.tableNo != null && message.hasOwnProperty("tableNo"))
                if (!$util.isInteger(message.tableNo))
                    return "tableNo: integer expected";
            return null;
        };

        /**
         * Creates an EnterRoomReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.EnterRoomReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.EnterRoomReply} EnterRoomReply
         */
        EnterRoomReply.fromObject = function fromObject(object) {
            if (object instanceof $root.base.EnterRoomReply)
                return object;
            var message = new $root.base.EnterRoomReply();
            if (object.gameId != null)
                message.gameId = object.gameId | 0;
            if (object.roomId != null)
                message.roomId = object.roomId | 0;
            if (object.roomInfo != null) {
                if (typeof object.roomInfo !== "object")
                    throw TypeError(".base.EnterRoomReply.roomInfo: object expected");
                message.roomInfo = $root.base.RoomInfo.fromObject(object.roomInfo);
            }
            if (object.tableId != null)
                message.tableId = object.tableId | 0;
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.enterReason != null)
                message.enterReason = object.enterReason | 0;
            if (object.roomType != null)
                message.roomType = object.roomType | 0;
            if (object.gameUrl != null)
                message.gameUrl = String(object.gameUrl);
            if (object.tableNo != null)
                message.tableNo = object.tableNo | 0;
            return message;
        };

        /**
         * Creates a plain object from an EnterRoomReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.EnterRoomReply
         * @static
         * @param {base.EnterRoomReply} message EnterRoomReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnterRoomReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameId = 0;
                object.roomId = 0;
                object.roomInfo = null;
                object.tableId = 0;
                object.seat = 0;
                object.enterReason = 0;
                object.roomType = 0;
                object.gameUrl = "";
                object.tableNo = 0;
            }
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                object.gameId = message.gameId;
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                object.roomId = message.roomId;
            if (message.roomInfo != null && message.hasOwnProperty("roomInfo"))
                object.roomInfo = $root.base.RoomInfo.toObject(message.roomInfo, options);
            if (message.tableId != null && message.hasOwnProperty("tableId"))
                object.tableId = message.tableId;
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.enterReason != null && message.hasOwnProperty("enterReason"))
                object.enterReason = message.enterReason;
            if (message.roomType != null && message.hasOwnProperty("roomType"))
                object.roomType = message.roomType;
            if (message.gameUrl != null && message.hasOwnProperty("gameUrl"))
                object.gameUrl = message.gameUrl;
            if (message.tableNo != null && message.hasOwnProperty("tableNo"))
                object.tableNo = message.tableNo;
            return object;
        };

        /**
         * Converts this EnterRoomReply to JSON.
         * @function toJSON
         * @memberof base.EnterRoomReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnterRoomReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EnterRoomReply;
    })();

    /**
     * ENTER_REASON enum.
     * @name base.ENTER_REASON
     * @enum {number}
     * @property {number} ENTER_NONE=0 ENTER_NONE value
     * @property {number} ENTER_PLAYER=1 ENTER_PLAYER value
     * @property {number} ENTER_SWITCH=2 ENTER_SWITCH value
     * @property {number} ENTER_ONLINE=3 ENTER_ONLINE value
     */
    base.ENTER_REASON = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ENTER_NONE"] = 0;
        values[valuesById[1] = "ENTER_PLAYER"] = 1;
        values[valuesById[2] = "ENTER_SWITCH"] = 2;
        values[valuesById[3] = "ENTER_ONLINE"] = 3;
        return values;
    })();

    base.ExitRoomReq = (function() {

        /**
         * Properties of an ExitRoomReq.
         * @memberof base
         * @interface IExitRoomReq
         * @property {number|null} [gameId] ExitRoomReq gameId
         * @property {number|null} [roomId] ExitRoomReq roomId
         */

        /**
         * Constructs a new ExitRoomReq.
         * @memberof base
         * @classdesc Represents an ExitRoomReq.
         * @implements IExitRoomReq
         * @constructor
         * @param {base.IExitRoomReq=} [properties] Properties to set
         */
        function ExitRoomReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExitRoomReq gameId.
         * @member {number} gameId
         * @memberof base.ExitRoomReq
         * @instance
         */
        ExitRoomReq.prototype.gameId = 0;

        /**
         * ExitRoomReq roomId.
         * @member {number} roomId
         * @memberof base.ExitRoomReq
         * @instance
         */
        ExitRoomReq.prototype.roomId = 0;

        /**
         * Creates a new ExitRoomReq instance using the specified properties.
         * @function create
         * @memberof base.ExitRoomReq
         * @static
         * @param {base.IExitRoomReq=} [properties] Properties to set
         * @returns {base.ExitRoomReq} ExitRoomReq instance
         */
        ExitRoomReq.create = function create(properties) {
            return new ExitRoomReq(properties);
        };

        /**
         * Encodes the specified ExitRoomReq message. Does not implicitly {@link base.ExitRoomReq.verify|verify} messages.
         * @function encode
         * @memberof base.ExitRoomReq
         * @static
         * @param {base.IExitRoomReq} message ExitRoomReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExitRoomReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameId != null && Object.hasOwnProperty.call(message, "gameId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gameId);
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.roomId);
            return writer;
        };

        /**
         * Encodes the specified ExitRoomReq message, length delimited. Does not implicitly {@link base.ExitRoomReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.ExitRoomReq
         * @static
         * @param {base.IExitRoomReq} message ExitRoomReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExitRoomReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExitRoomReq message from the specified reader or buffer.
         * @function decode
         * @memberof base.ExitRoomReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.ExitRoomReq} ExitRoomReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExitRoomReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.ExitRoomReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameId = reader.int32();
                    break;
                case 2:
                    message.roomId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExitRoomReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.ExitRoomReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.ExitRoomReq} ExitRoomReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExitRoomReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExitRoomReq message.
         * @function verify
         * @memberof base.ExitRoomReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExitRoomReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                if (!$util.isInteger(message.gameId))
                    return "gameId: integer expected";
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                if (!$util.isInteger(message.roomId))
                    return "roomId: integer expected";
            return null;
        };

        /**
         * Creates an ExitRoomReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.ExitRoomReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.ExitRoomReq} ExitRoomReq
         */
        ExitRoomReq.fromObject = function fromObject(object) {
            if (object instanceof $root.base.ExitRoomReq)
                return object;
            var message = new $root.base.ExitRoomReq();
            if (object.gameId != null)
                message.gameId = object.gameId | 0;
            if (object.roomId != null)
                message.roomId = object.roomId | 0;
            return message;
        };

        /**
         * Creates a plain object from an ExitRoomReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.ExitRoomReq
         * @static
         * @param {base.ExitRoomReq} message ExitRoomReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExitRoomReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameId = 0;
                object.roomId = 0;
            }
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                object.gameId = message.gameId;
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                object.roomId = message.roomId;
            return object;
        };

        /**
         * Converts this ExitRoomReq to JSON.
         * @function toJSON
         * @memberof base.ExitRoomReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExitRoomReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ExitRoomReq;
    })();

    base.ExitRoomReply = (function() {

        /**
         * Properties of an ExitRoomReply.
         * @memberof base
         * @interface IExitRoomReply
         * @property {number|null} [gameId] ExitRoomReply gameId
         * @property {number|null} [roomId] ExitRoomReply roomId
         * @property {number|null} [exitReason] ExitRoomReply exitReason
         * @property {Array.<number>|null} [gameList] ExitRoomReply gameList
         * @property {number|null} [totalRounds] ExitRoomReply totalRounds
         */

        /**
         * Constructs a new ExitRoomReply.
         * @memberof base
         * @classdesc Represents an ExitRoomReply.
         * @implements IExitRoomReply
         * @constructor
         * @param {base.IExitRoomReply=} [properties] Properties to set
         */
        function ExitRoomReply(properties) {
            this.gameList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExitRoomReply gameId.
         * @member {number} gameId
         * @memberof base.ExitRoomReply
         * @instance
         */
        ExitRoomReply.prototype.gameId = 0;

        /**
         * ExitRoomReply roomId.
         * @member {number} roomId
         * @memberof base.ExitRoomReply
         * @instance
         */
        ExitRoomReply.prototype.roomId = 0;

        /**
         * ExitRoomReply exitReason.
         * @member {number} exitReason
         * @memberof base.ExitRoomReply
         * @instance
         */
        ExitRoomReply.prototype.exitReason = 0;

        /**
         * ExitRoomReply gameList.
         * @member {Array.<number>} gameList
         * @memberof base.ExitRoomReply
         * @instance
         */
        ExitRoomReply.prototype.gameList = $util.emptyArray;

        /**
         * ExitRoomReply totalRounds.
         * @member {number} totalRounds
         * @memberof base.ExitRoomReply
         * @instance
         */
        ExitRoomReply.prototype.totalRounds = 0;

        /**
         * Creates a new ExitRoomReply instance using the specified properties.
         * @function create
         * @memberof base.ExitRoomReply
         * @static
         * @param {base.IExitRoomReply=} [properties] Properties to set
         * @returns {base.ExitRoomReply} ExitRoomReply instance
         */
        ExitRoomReply.create = function create(properties) {
            return new ExitRoomReply(properties);
        };

        /**
         * Encodes the specified ExitRoomReply message. Does not implicitly {@link base.ExitRoomReply.verify|verify} messages.
         * @function encode
         * @memberof base.ExitRoomReply
         * @static
         * @param {base.IExitRoomReply} message ExitRoomReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExitRoomReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameId != null && Object.hasOwnProperty.call(message, "gameId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gameId);
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.roomId);
            if (message.exitReason != null && Object.hasOwnProperty.call(message, "exitReason"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.exitReason);
            if (message.gameList != null && message.gameList.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.gameList.length; ++i)
                    writer.int32(message.gameList[i]);
                writer.ldelim();
            }
            if (message.totalRounds != null && Object.hasOwnProperty.call(message, "totalRounds"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.totalRounds);
            return writer;
        };

        /**
         * Encodes the specified ExitRoomReply message, length delimited. Does not implicitly {@link base.ExitRoomReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.ExitRoomReply
         * @static
         * @param {base.IExitRoomReply} message ExitRoomReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExitRoomReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExitRoomReply message from the specified reader or buffer.
         * @function decode
         * @memberof base.ExitRoomReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.ExitRoomReply} ExitRoomReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExitRoomReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.ExitRoomReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameId = reader.int32();
                    break;
                case 2:
                    message.roomId = reader.int32();
                    break;
                case 3:
                    message.exitReason = reader.int32();
                    break;
                case 4:
                    if (!(message.gameList && message.gameList.length))
                        message.gameList = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.gameList.push(reader.int32());
                    } else
                        message.gameList.push(reader.int32());
                    break;
                case 5:
                    message.totalRounds = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExitRoomReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.ExitRoomReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.ExitRoomReply} ExitRoomReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExitRoomReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExitRoomReply message.
         * @function verify
         * @memberof base.ExitRoomReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExitRoomReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                if (!$util.isInteger(message.gameId))
                    return "gameId: integer expected";
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                if (!$util.isInteger(message.roomId))
                    return "roomId: integer expected";
            if (message.exitReason != null && message.hasOwnProperty("exitReason"))
                if (!$util.isInteger(message.exitReason))
                    return "exitReason: integer expected";
            if (message.gameList != null && message.hasOwnProperty("gameList")) {
                if (!Array.isArray(message.gameList))
                    return "gameList: array expected";
                for (var i = 0; i < message.gameList.length; ++i)
                    if (!$util.isInteger(message.gameList[i]))
                        return "gameList: integer[] expected";
            }
            if (message.totalRounds != null && message.hasOwnProperty("totalRounds"))
                if (!$util.isInteger(message.totalRounds))
                    return "totalRounds: integer expected";
            return null;
        };

        /**
         * Creates an ExitRoomReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.ExitRoomReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.ExitRoomReply} ExitRoomReply
         */
        ExitRoomReply.fromObject = function fromObject(object) {
            if (object instanceof $root.base.ExitRoomReply)
                return object;
            var message = new $root.base.ExitRoomReply();
            if (object.gameId != null)
                message.gameId = object.gameId | 0;
            if (object.roomId != null)
                message.roomId = object.roomId | 0;
            if (object.exitReason != null)
                message.exitReason = object.exitReason | 0;
            if (object.gameList) {
                if (!Array.isArray(object.gameList))
                    throw TypeError(".base.ExitRoomReply.gameList: array expected");
                message.gameList = [];
                for (var i = 0; i < object.gameList.length; ++i)
                    message.gameList[i] = object.gameList[i] | 0;
            }
            if (object.totalRounds != null)
                message.totalRounds = object.totalRounds | 0;
            return message;
        };

        /**
         * Creates a plain object from an ExitRoomReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.ExitRoomReply
         * @static
         * @param {base.ExitRoomReply} message ExitRoomReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExitRoomReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.gameList = [];
            if (options.defaults) {
                object.gameId = 0;
                object.roomId = 0;
                object.exitReason = 0;
                object.totalRounds = 0;
            }
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                object.gameId = message.gameId;
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                object.roomId = message.roomId;
            if (message.exitReason != null && message.hasOwnProperty("exitReason"))
                object.exitReason = message.exitReason;
            if (message.gameList && message.gameList.length) {
                object.gameList = [];
                for (var j = 0; j < message.gameList.length; ++j)
                    object.gameList[j] = message.gameList[j];
            }
            if (message.totalRounds != null && message.hasOwnProperty("totalRounds"))
                object.totalRounds = message.totalRounds;
            return object;
        };

        /**
         * Converts this ExitRoomReply to JSON.
         * @function toJSON
         * @memberof base.ExitRoomReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExitRoomReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ExitRoomReply;
    })();

    /**
     * EXIT_REASON enum.
     * @name base.EXIT_REASON
     * @enum {number}
     * @property {number} EXIT_NONE=0 EXIT_NONE value
     * @property {number} EXIT_PLAYER=1 EXIT_PLAYER value
     * @property {number} EXIT_SWITCH=2 EXIT_SWITCH value
     * @property {number} EXIT_BALANCE=3 EXIT_BALANCE value
     * @property {number} EXIT_ERROR=4 EXIT_ERROR value
     * @property {number} EXIT_OFFLINE=5 EXIT_OFFLINE value
     * @property {number} EXIT_TIMEOUT=6 EXIT_TIMEOUT value
     * @property {number} EXIT_CLUB_FORBID=7 EXIT_CLUB_FORBID value
     * @property {number} EXIT_GAME_OVER=8 EXIT_GAME_OVER value
     * @property {number} EXIT_SERVER_MAINTAIN=9 EXIT_SERVER_MAINTAIN value
     */
    base.EXIT_REASON = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "EXIT_NONE"] = 0;
        values[valuesById[1] = "EXIT_PLAYER"] = 1;
        values[valuesById[2] = "EXIT_SWITCH"] = 2;
        values[valuesById[3] = "EXIT_BALANCE"] = 3;
        values[valuesById[4] = "EXIT_ERROR"] = 4;
        values[valuesById[5] = "EXIT_OFFLINE"] = 5;
        values[valuesById[6] = "EXIT_TIMEOUT"] = 6;
        values[valuesById[7] = "EXIT_CLUB_FORBID"] = 7;
        values[valuesById[8] = "EXIT_GAME_OVER"] = 8;
        values[valuesById[9] = "EXIT_SERVER_MAINTAIN"] = 9;
        return values;
    })();

    base.SwitchTableReq = (function() {

        /**
         * Properties of a SwitchTableReq.
         * @memberof base
         * @interface ISwitchTableReq
         * @property {number|null} [gameId] SwitchTableReq gameId
         * @property {number|null} [roomId] SwitchTableReq roomId
         */

        /**
         * Constructs a new SwitchTableReq.
         * @memberof base
         * @classdesc Represents a SwitchTableReq.
         * @implements ISwitchTableReq
         * @constructor
         * @param {base.ISwitchTableReq=} [properties] Properties to set
         */
        function SwitchTableReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SwitchTableReq gameId.
         * @member {number} gameId
         * @memberof base.SwitchTableReq
         * @instance
         */
        SwitchTableReq.prototype.gameId = 0;

        /**
         * SwitchTableReq roomId.
         * @member {number} roomId
         * @memberof base.SwitchTableReq
         * @instance
         */
        SwitchTableReq.prototype.roomId = 0;

        /**
         * Creates a new SwitchTableReq instance using the specified properties.
         * @function create
         * @memberof base.SwitchTableReq
         * @static
         * @param {base.ISwitchTableReq=} [properties] Properties to set
         * @returns {base.SwitchTableReq} SwitchTableReq instance
         */
        SwitchTableReq.create = function create(properties) {
            return new SwitchTableReq(properties);
        };

        /**
         * Encodes the specified SwitchTableReq message. Does not implicitly {@link base.SwitchTableReq.verify|verify} messages.
         * @function encode
         * @memberof base.SwitchTableReq
         * @static
         * @param {base.ISwitchTableReq} message SwitchTableReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SwitchTableReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameId != null && Object.hasOwnProperty.call(message, "gameId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gameId);
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.roomId);
            return writer;
        };

        /**
         * Encodes the specified SwitchTableReq message, length delimited. Does not implicitly {@link base.SwitchTableReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.SwitchTableReq
         * @static
         * @param {base.ISwitchTableReq} message SwitchTableReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SwitchTableReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SwitchTableReq message from the specified reader or buffer.
         * @function decode
         * @memberof base.SwitchTableReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.SwitchTableReq} SwitchTableReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SwitchTableReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.SwitchTableReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameId = reader.int32();
                    break;
                case 2:
                    message.roomId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SwitchTableReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.SwitchTableReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.SwitchTableReq} SwitchTableReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SwitchTableReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SwitchTableReq message.
         * @function verify
         * @memberof base.SwitchTableReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SwitchTableReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                if (!$util.isInteger(message.gameId))
                    return "gameId: integer expected";
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                if (!$util.isInteger(message.roomId))
                    return "roomId: integer expected";
            return null;
        };

        /**
         * Creates a SwitchTableReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.SwitchTableReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.SwitchTableReq} SwitchTableReq
         */
        SwitchTableReq.fromObject = function fromObject(object) {
            if (object instanceof $root.base.SwitchTableReq)
                return object;
            var message = new $root.base.SwitchTableReq();
            if (object.gameId != null)
                message.gameId = object.gameId | 0;
            if (object.roomId != null)
                message.roomId = object.roomId | 0;
            return message;
        };

        /**
         * Creates a plain object from a SwitchTableReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.SwitchTableReq
         * @static
         * @param {base.SwitchTableReq} message SwitchTableReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SwitchTableReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameId = 0;
                object.roomId = 0;
            }
            if (message.gameId != null && message.hasOwnProperty("gameId"))
                object.gameId = message.gameId;
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                object.roomId = message.roomId;
            return object;
        };

        /**
         * Converts this SwitchTableReq to JSON.
         * @function toJSON
         * @memberof base.SwitchTableReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SwitchTableReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SwitchTableReq;
    })();

    base.SwitchCoinReq = (function() {

        /**
         * Properties of a SwitchCoinReq.
         * @memberof base
         * @interface ISwitchCoinReq
         * @property {number|null} [coinType] SwitchCoinReq coinType
         * @property {number|Long|null} [balance] SwitchCoinReq balance
         */

        /**
         * Constructs a new SwitchCoinReq.
         * @memberof base
         * @classdesc Represents a SwitchCoinReq.
         * @implements ISwitchCoinReq
         * @constructor
         * @param {base.ISwitchCoinReq=} [properties] Properties to set
         */
        function SwitchCoinReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SwitchCoinReq coinType.
         * @member {number} coinType
         * @memberof base.SwitchCoinReq
         * @instance
         */
        SwitchCoinReq.prototype.coinType = 0;

        /**
         * SwitchCoinReq balance.
         * @member {number|Long} balance
         * @memberof base.SwitchCoinReq
         * @instance
         */
        SwitchCoinReq.prototype.balance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new SwitchCoinReq instance using the specified properties.
         * @function create
         * @memberof base.SwitchCoinReq
         * @static
         * @param {base.ISwitchCoinReq=} [properties] Properties to set
         * @returns {base.SwitchCoinReq} SwitchCoinReq instance
         */
        SwitchCoinReq.create = function create(properties) {
            return new SwitchCoinReq(properties);
        };

        /**
         * Encodes the specified SwitchCoinReq message. Does not implicitly {@link base.SwitchCoinReq.verify|verify} messages.
         * @function encode
         * @memberof base.SwitchCoinReq
         * @static
         * @param {base.ISwitchCoinReq} message SwitchCoinReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SwitchCoinReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.coinType != null && Object.hasOwnProperty.call(message, "coinType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.coinType);
            if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.balance);
            return writer;
        };

        /**
         * Encodes the specified SwitchCoinReq message, length delimited. Does not implicitly {@link base.SwitchCoinReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.SwitchCoinReq
         * @static
         * @param {base.ISwitchCoinReq} message SwitchCoinReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SwitchCoinReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SwitchCoinReq message from the specified reader or buffer.
         * @function decode
         * @memberof base.SwitchCoinReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.SwitchCoinReq} SwitchCoinReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SwitchCoinReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.SwitchCoinReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.coinType = reader.int32();
                    break;
                case 2:
                    message.balance = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SwitchCoinReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.SwitchCoinReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.SwitchCoinReq} SwitchCoinReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SwitchCoinReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SwitchCoinReq message.
         * @function verify
         * @memberof base.SwitchCoinReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SwitchCoinReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.coinType != null && message.hasOwnProperty("coinType"))
                if (!$util.isInteger(message.coinType))
                    return "coinType: integer expected";
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (!$util.isInteger(message.balance) && !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high)))
                    return "balance: integer|Long expected";
            return null;
        };

        /**
         * Creates a SwitchCoinReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.SwitchCoinReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.SwitchCoinReq} SwitchCoinReq
         */
        SwitchCoinReq.fromObject = function fromObject(object) {
            if (object instanceof $root.base.SwitchCoinReq)
                return object;
            var message = new $root.base.SwitchCoinReq();
            if (object.coinType != null)
                message.coinType = object.coinType | 0;
            if (object.balance != null)
                if ($util.Long)
                    (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
                else if (typeof object.balance === "string")
                    message.balance = parseInt(object.balance, 10);
                else if (typeof object.balance === "number")
                    message.balance = object.balance;
                else if (typeof object.balance === "object")
                    message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a SwitchCoinReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.SwitchCoinReq
         * @static
         * @param {base.SwitchCoinReq} message SwitchCoinReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SwitchCoinReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.coinType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.balance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.balance = options.longs === String ? "0" : 0;
            }
            if (message.coinType != null && message.hasOwnProperty("coinType"))
                object.coinType = message.coinType;
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (typeof message.balance === "number")
                    object.balance = options.longs === String ? String(message.balance) : message.balance;
                else
                    object.balance = options.longs === String ? $util.Long.prototype.toString.call(message.balance) : options.longs === Number ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber() : message.balance;
            return object;
        };

        /**
         * Converts this SwitchCoinReq to JSON.
         * @function toJSON
         * @memberof base.SwitchCoinReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SwitchCoinReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SwitchCoinReq;
    })();

    /**
     * ROOM_TYPE enum.
     * @name base.ROOM_TYPE
     * @enum {number}
     * @property {number} NORMAL=0 NORMAL value
     * @property {number} IN_CLUB=1 IN_CLUB value
     */
    base.ROOM_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NORMAL"] = 0;
        values[valuesById[1] = "IN_CLUB"] = 1;
        return values;
    })();

    /**
     * MATCH_TYPE enum.
     * @name base.MATCH_TYPE
     * @enum {number}
     * @property {number} AUTO=0 AUTO value
     * @property {number} ENTER_TABLE=1 ENTER_TABLE value
     * @property {number} CREATE_TABLE=2 CREATE_TABLE value
     */
    base.MATCH_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AUTO"] = 0;
        values[valuesById[1] = "ENTER_TABLE"] = 1;
        values[valuesById[2] = "CREATE_TABLE"] = 2;
        return values;
    })();

    /**
     * NOTIFY_TYPE enum.
     * @name base.NOTIFY_TYPE
     * @enum {number}
     * @property {number} NONE=0 NONE value
     * @property {number} INIT=1 INIT value
     * @property {number} UPDATE=2 UPDATE value
     * @property {number} DELETE=3 DELETE value
     */
    base.NOTIFY_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NONE"] = 0;
        values[valuesById[1] = "INIT"] = 1;
        values[valuesById[2] = "UPDATE"] = 2;
        values[valuesById[3] = "DELETE"] = 3;
        return values;
    })();

    base.TablePlayersEvent = (function() {

        /**
         * Properties of a TablePlayersEvent.
         * @memberof base
         * @interface ITablePlayersEvent
         * @property {number|null} [notifyType] TablePlayersEvent notifyType
         * @property {Array.<base.ISeatPlayer>|null} [tablePlayers] TablePlayersEvent tablePlayers
         */

        /**
         * Constructs a new TablePlayersEvent.
         * @memberof base
         * @classdesc Represents a TablePlayersEvent.
         * @implements ITablePlayersEvent
         * @constructor
         * @param {base.ITablePlayersEvent=} [properties] Properties to set
         */
        function TablePlayersEvent(properties) {
            this.tablePlayers = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TablePlayersEvent notifyType.
         * @member {number} notifyType
         * @memberof base.TablePlayersEvent
         * @instance
         */
        TablePlayersEvent.prototype.notifyType = 0;

        /**
         * TablePlayersEvent tablePlayers.
         * @member {Array.<base.ISeatPlayer>} tablePlayers
         * @memberof base.TablePlayersEvent
         * @instance
         */
        TablePlayersEvent.prototype.tablePlayers = $util.emptyArray;

        /**
         * Creates a new TablePlayersEvent instance using the specified properties.
         * @function create
         * @memberof base.TablePlayersEvent
         * @static
         * @param {base.ITablePlayersEvent=} [properties] Properties to set
         * @returns {base.TablePlayersEvent} TablePlayersEvent instance
         */
        TablePlayersEvent.create = function create(properties) {
            return new TablePlayersEvent(properties);
        };

        /**
         * Encodes the specified TablePlayersEvent message. Does not implicitly {@link base.TablePlayersEvent.verify|verify} messages.
         * @function encode
         * @memberof base.TablePlayersEvent
         * @static
         * @param {base.ITablePlayersEvent} message TablePlayersEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TablePlayersEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.notifyType != null && Object.hasOwnProperty.call(message, "notifyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.notifyType);
            if (message.tablePlayers != null && message.tablePlayers.length)
                for (var i = 0; i < message.tablePlayers.length; ++i)
                    $root.base.SeatPlayer.encode(message.tablePlayers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TablePlayersEvent message, length delimited. Does not implicitly {@link base.TablePlayersEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.TablePlayersEvent
         * @static
         * @param {base.ITablePlayersEvent} message TablePlayersEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TablePlayersEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TablePlayersEvent message from the specified reader or buffer.
         * @function decode
         * @memberof base.TablePlayersEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.TablePlayersEvent} TablePlayersEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TablePlayersEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.TablePlayersEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.notifyType = reader.int32();
                    break;
                case 2:
                    if (!(message.tablePlayers && message.tablePlayers.length))
                        message.tablePlayers = [];
                    message.tablePlayers.push($root.base.SeatPlayer.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TablePlayersEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.TablePlayersEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.TablePlayersEvent} TablePlayersEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TablePlayersEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TablePlayersEvent message.
         * @function verify
         * @memberof base.TablePlayersEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TablePlayersEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.notifyType != null && message.hasOwnProperty("notifyType"))
                if (!$util.isInteger(message.notifyType))
                    return "notifyType: integer expected";
            if (message.tablePlayers != null && message.hasOwnProperty("tablePlayers")) {
                if (!Array.isArray(message.tablePlayers))
                    return "tablePlayers: array expected";
                for (var i = 0; i < message.tablePlayers.length; ++i) {
                    var error = $root.base.SeatPlayer.verify(message.tablePlayers[i]);
                    if (error)
                        return "tablePlayers." + error;
                }
            }
            return null;
        };

        /**
         * Creates a TablePlayersEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.TablePlayersEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.TablePlayersEvent} TablePlayersEvent
         */
        TablePlayersEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.base.TablePlayersEvent)
                return object;
            var message = new $root.base.TablePlayersEvent();
            if (object.notifyType != null)
                message.notifyType = object.notifyType | 0;
            if (object.tablePlayers) {
                if (!Array.isArray(object.tablePlayers))
                    throw TypeError(".base.TablePlayersEvent.tablePlayers: array expected");
                message.tablePlayers = [];
                for (var i = 0; i < object.tablePlayers.length; ++i) {
                    if (typeof object.tablePlayers[i] !== "object")
                        throw TypeError(".base.TablePlayersEvent.tablePlayers: object expected");
                    message.tablePlayers[i] = $root.base.SeatPlayer.fromObject(object.tablePlayers[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a TablePlayersEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.TablePlayersEvent
         * @static
         * @param {base.TablePlayersEvent} message TablePlayersEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TablePlayersEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tablePlayers = [];
            if (options.defaults)
                object.notifyType = 0;
            if (message.notifyType != null && message.hasOwnProperty("notifyType"))
                object.notifyType = message.notifyType;
            if (message.tablePlayers && message.tablePlayers.length) {
                object.tablePlayers = [];
                for (var j = 0; j < message.tablePlayers.length; ++j)
                    object.tablePlayers[j] = $root.base.SeatPlayer.toObject(message.tablePlayers[j], options);
            }
            return object;
        };

        /**
         * Converts this TablePlayersEvent to JSON.
         * @function toJSON
         * @memberof base.TablePlayersEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TablePlayersEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TablePlayersEvent;
    })();

    base.SeatPlayer = (function() {

        /**
         * Properties of a SeatPlayer.
         * @memberof base
         * @interface ISeatPlayer
         * @property {number|null} [seat] SeatPlayer seat
         * @property {base.IPlayerBaseInfo|null} [baseInfo] SeatPlayer baseInfo
         * @property {boolean|null} [online] SeatPlayer online
         * @property {number|null} [balance] SeatPlayer balance
         * @property {boolean|null} [ready] SeatPlayer ready
         * @property {boolean|null} [open] SeatPlayer open
         */

        /**
         * Constructs a new SeatPlayer.
         * @memberof base
         * @classdesc Represents a SeatPlayer.
         * @implements ISeatPlayer
         * @constructor
         * @param {base.ISeatPlayer=} [properties] Properties to set
         */
        function SeatPlayer(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeatPlayer seat.
         * @member {number} seat
         * @memberof base.SeatPlayer
         * @instance
         */
        SeatPlayer.prototype.seat = 0;

        /**
         * SeatPlayer baseInfo.
         * @member {base.IPlayerBaseInfo|null|undefined} baseInfo
         * @memberof base.SeatPlayer
         * @instance
         */
        SeatPlayer.prototype.baseInfo = null;

        /**
         * SeatPlayer online.
         * @member {boolean} online
         * @memberof base.SeatPlayer
         * @instance
         */
        SeatPlayer.prototype.online = false;

        /**
         * SeatPlayer balance.
         * @member {number} balance
         * @memberof base.SeatPlayer
         * @instance
         */
        SeatPlayer.prototype.balance = 0;

        /**
         * SeatPlayer ready.
         * @member {boolean} ready
         * @memberof base.SeatPlayer
         * @instance
         */
        SeatPlayer.prototype.ready = false;

        /**
         * SeatPlayer open.
         * @member {boolean} open
         * @memberof base.SeatPlayer
         * @instance
         */
        SeatPlayer.prototype.open = false;

        /**
         * Creates a new SeatPlayer instance using the specified properties.
         * @function create
         * @memberof base.SeatPlayer
         * @static
         * @param {base.ISeatPlayer=} [properties] Properties to set
         * @returns {base.SeatPlayer} SeatPlayer instance
         */
        SeatPlayer.create = function create(properties) {
            return new SeatPlayer(properties);
        };

        /**
         * Encodes the specified SeatPlayer message. Does not implicitly {@link base.SeatPlayer.verify|verify} messages.
         * @function encode
         * @memberof base.SeatPlayer
         * @static
         * @param {base.ISeatPlayer} message SeatPlayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeatPlayer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            if (message.baseInfo != null && Object.hasOwnProperty.call(message, "baseInfo"))
                $root.base.PlayerBaseInfo.encode(message.baseInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.online != null && Object.hasOwnProperty.call(message, "online"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.online);
            if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.balance);
            if (message.ready != null && Object.hasOwnProperty.call(message, "ready"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.ready);
            if (message.open != null && Object.hasOwnProperty.call(message, "open"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.open);
            return writer;
        };

        /**
         * Encodes the specified SeatPlayer message, length delimited. Does not implicitly {@link base.SeatPlayer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.SeatPlayer
         * @static
         * @param {base.ISeatPlayer} message SeatPlayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeatPlayer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SeatPlayer message from the specified reader or buffer.
         * @function decode
         * @memberof base.SeatPlayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.SeatPlayer} SeatPlayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeatPlayer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.SeatPlayer();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                case 2:
                    message.baseInfo = $root.base.PlayerBaseInfo.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.online = reader.bool();
                    break;
                case 4:
                    message.balance = reader.int32();
                    break;
                case 5:
                    message.ready = reader.bool();
                    break;
                case 6:
                    message.open = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SeatPlayer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.SeatPlayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.SeatPlayer} SeatPlayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeatPlayer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SeatPlayer message.
         * @function verify
         * @memberof base.SeatPlayer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SeatPlayer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo")) {
                var error = $root.base.PlayerBaseInfo.verify(message.baseInfo);
                if (error)
                    return "baseInfo." + error;
            }
            if (message.online != null && message.hasOwnProperty("online"))
                if (typeof message.online !== "boolean")
                    return "online: boolean expected";
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (!$util.isInteger(message.balance))
                    return "balance: integer expected";
            if (message.ready != null && message.hasOwnProperty("ready"))
                if (typeof message.ready !== "boolean")
                    return "ready: boolean expected";
            if (message.open != null && message.hasOwnProperty("open"))
                if (typeof message.open !== "boolean")
                    return "open: boolean expected";
            return null;
        };

        /**
         * Creates a SeatPlayer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.SeatPlayer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.SeatPlayer} SeatPlayer
         */
        SeatPlayer.fromObject = function fromObject(object) {
            if (object instanceof $root.base.SeatPlayer)
                return object;
            var message = new $root.base.SeatPlayer();
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.baseInfo != null) {
                if (typeof object.baseInfo !== "object")
                    throw TypeError(".base.SeatPlayer.baseInfo: object expected");
                message.baseInfo = $root.base.PlayerBaseInfo.fromObject(object.baseInfo);
            }
            if (object.online != null)
                message.online = Boolean(object.online);
            if (object.balance != null)
                message.balance = object.balance | 0;
            if (object.ready != null)
                message.ready = Boolean(object.ready);
            if (object.open != null)
                message.open = Boolean(object.open);
            return message;
        };

        /**
         * Creates a plain object from a SeatPlayer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.SeatPlayer
         * @static
         * @param {base.SeatPlayer} message SeatPlayer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeatPlayer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.seat = 0;
                object.baseInfo = null;
                object.online = false;
                object.balance = 0;
                object.ready = false;
                object.open = false;
            }
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo"))
                object.baseInfo = $root.base.PlayerBaseInfo.toObject(message.baseInfo, options);
            if (message.online != null && message.hasOwnProperty("online"))
                object.online = message.online;
            if (message.balance != null && message.hasOwnProperty("balance"))
                object.balance = message.balance;
            if (message.ready != null && message.hasOwnProperty("ready"))
                object.ready = message.ready;
            if (message.open != null && message.hasOwnProperty("open"))
                object.open = message.open;
            return object;
        };

        /**
         * Converts this SeatPlayer to JSON.
         * @function toJSON
         * @memberof base.SeatPlayer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeatPlayer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SeatPlayer;
    })();

    /**
     * SEAT enum.
     * @name base.SEAT
     * @enum {number}
     * @property {number} SEAT_NONE=0 SEAT_NONE value
     * @property {number} SEAT_1=1 SEAT_1 value
     * @property {number} SEAT_2=2 SEAT_2 value
     * @property {number} SEAT_3=3 SEAT_3 value
     * @property {number} SEAT_4=4 SEAT_4 value
     * @property {number} SEAT_5=5 SEAT_5 value
     * @property {number} SEAT_6=6 SEAT_6 value
     */
    base.SEAT = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SEAT_NONE"] = 0;
        values[valuesById[1] = "SEAT_1"] = 1;
        values[valuesById[2] = "SEAT_2"] = 2;
        values[valuesById[3] = "SEAT_3"] = 3;
        values[valuesById[4] = "SEAT_4"] = 4;
        values[valuesById[5] = "SEAT_5"] = 5;
        values[valuesById[6] = "SEAT_6"] = 6;
        return values;
    })();

    base.TableTimeEvent = (function() {

        /**
         * Properties of a TableTimeEvent.
         * @memberof base
         * @interface ITableTimeEvent
         * @property {number|null} [round] TableTimeEvent round
         * @property {Array.<base.ISeatDeadline>|null} [tableDeadlines] TableTimeEvent tableDeadlines
         * @property {number|null} [curBet] TableTimeEvent curBet
         */

        /**
         * Constructs a new TableTimeEvent.
         * @memberof base
         * @classdesc Represents a TableTimeEvent.
         * @implements ITableTimeEvent
         * @constructor
         * @param {base.ITableTimeEvent=} [properties] Properties to set
         */
        function TableTimeEvent(properties) {
            this.tableDeadlines = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableTimeEvent round.
         * @member {number} round
         * @memberof base.TableTimeEvent
         * @instance
         */
        TableTimeEvent.prototype.round = 0;

        /**
         * TableTimeEvent tableDeadlines.
         * @member {Array.<base.ISeatDeadline>} tableDeadlines
         * @memberof base.TableTimeEvent
         * @instance
         */
        TableTimeEvent.prototype.tableDeadlines = $util.emptyArray;

        /**
         * TableTimeEvent curBet.
         * @member {number} curBet
         * @memberof base.TableTimeEvent
         * @instance
         */
        TableTimeEvent.prototype.curBet = 0;

        /**
         * Creates a new TableTimeEvent instance using the specified properties.
         * @function create
         * @memberof base.TableTimeEvent
         * @static
         * @param {base.ITableTimeEvent=} [properties] Properties to set
         * @returns {base.TableTimeEvent} TableTimeEvent instance
         */
        TableTimeEvent.create = function create(properties) {
            return new TableTimeEvent(properties);
        };

        /**
         * Encodes the specified TableTimeEvent message. Does not implicitly {@link base.TableTimeEvent.verify|verify} messages.
         * @function encode
         * @memberof base.TableTimeEvent
         * @static
         * @param {base.ITableTimeEvent} message TableTimeEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableTimeEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.round != null && Object.hasOwnProperty.call(message, "round"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.round);
            if (message.tableDeadlines != null && message.tableDeadlines.length)
                for (var i = 0; i < message.tableDeadlines.length; ++i)
                    $root.base.SeatDeadline.encode(message.tableDeadlines[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.curBet != null && Object.hasOwnProperty.call(message, "curBet"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.curBet);
            return writer;
        };

        /**
         * Encodes the specified TableTimeEvent message, length delimited. Does not implicitly {@link base.TableTimeEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.TableTimeEvent
         * @static
         * @param {base.ITableTimeEvent} message TableTimeEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableTimeEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableTimeEvent message from the specified reader or buffer.
         * @function decode
         * @memberof base.TableTimeEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.TableTimeEvent} TableTimeEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableTimeEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.TableTimeEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.round = reader.int32();
                    break;
                case 2:
                    if (!(message.tableDeadlines && message.tableDeadlines.length))
                        message.tableDeadlines = [];
                    message.tableDeadlines.push($root.base.SeatDeadline.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.curBet = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableTimeEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.TableTimeEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.TableTimeEvent} TableTimeEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableTimeEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableTimeEvent message.
         * @function verify
         * @memberof base.TableTimeEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableTimeEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.round != null && message.hasOwnProperty("round"))
                if (!$util.isInteger(message.round))
                    return "round: integer expected";
            if (message.tableDeadlines != null && message.hasOwnProperty("tableDeadlines")) {
                if (!Array.isArray(message.tableDeadlines))
                    return "tableDeadlines: array expected";
                for (var i = 0; i < message.tableDeadlines.length; ++i) {
                    var error = $root.base.SeatDeadline.verify(message.tableDeadlines[i]);
                    if (error)
                        return "tableDeadlines." + error;
                }
            }
            if (message.curBet != null && message.hasOwnProperty("curBet"))
                if (!$util.isInteger(message.curBet))
                    return "curBet: integer expected";
            return null;
        };

        /**
         * Creates a TableTimeEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.TableTimeEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.TableTimeEvent} TableTimeEvent
         */
        TableTimeEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.base.TableTimeEvent)
                return object;
            var message = new $root.base.TableTimeEvent();
            if (object.round != null)
                message.round = object.round | 0;
            if (object.tableDeadlines) {
                if (!Array.isArray(object.tableDeadlines))
                    throw TypeError(".base.TableTimeEvent.tableDeadlines: array expected");
                message.tableDeadlines = [];
                for (var i = 0; i < object.tableDeadlines.length; ++i) {
                    if (typeof object.tableDeadlines[i] !== "object")
                        throw TypeError(".base.TableTimeEvent.tableDeadlines: object expected");
                    message.tableDeadlines[i] = $root.base.SeatDeadline.fromObject(object.tableDeadlines[i]);
                }
            }
            if (object.curBet != null)
                message.curBet = object.curBet | 0;
            return message;
        };

        /**
         * Creates a plain object from a TableTimeEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.TableTimeEvent
         * @static
         * @param {base.TableTimeEvent} message TableTimeEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableTimeEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.tableDeadlines = [];
            if (options.defaults) {
                object.round = 0;
                object.curBet = 0;
            }
            if (message.round != null && message.hasOwnProperty("round"))
                object.round = message.round;
            if (message.tableDeadlines && message.tableDeadlines.length) {
                object.tableDeadlines = [];
                for (var j = 0; j < message.tableDeadlines.length; ++j)
                    object.tableDeadlines[j] = $root.base.SeatDeadline.toObject(message.tableDeadlines[j], options);
            }
            if (message.curBet != null && message.hasOwnProperty("curBet"))
                object.curBet = message.curBet;
            return object;
        };

        /**
         * Converts this TableTimeEvent to JSON.
         * @function toJSON
         * @memberof base.TableTimeEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableTimeEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TableTimeEvent;
    })();

    base.SeatDeadline = (function() {

        /**
         * Properties of a SeatDeadline.
         * @memberof base
         * @interface ISeatDeadline
         * @property {number|null} [type] SeatDeadline type
         * @property {number|null} [seat] SeatDeadline seat
         * @property {number|Long|null} [startTime] SeatDeadline startTime
         * @property {number|null} [seconds] SeatDeadline seconds
         */

        /**
         * Constructs a new SeatDeadline.
         * @memberof base
         * @classdesc Represents a SeatDeadline.
         * @implements ISeatDeadline
         * @constructor
         * @param {base.ISeatDeadline=} [properties] Properties to set
         */
        function SeatDeadline(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SeatDeadline type.
         * @member {number} type
         * @memberof base.SeatDeadline
         * @instance
         */
        SeatDeadline.prototype.type = 0;

        /**
         * SeatDeadline seat.
         * @member {number} seat
         * @memberof base.SeatDeadline
         * @instance
         */
        SeatDeadline.prototype.seat = 0;

        /**
         * SeatDeadline startTime.
         * @member {number|Long} startTime
         * @memberof base.SeatDeadline
         * @instance
         */
        SeatDeadline.prototype.startTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SeatDeadline seconds.
         * @member {number} seconds
         * @memberof base.SeatDeadline
         * @instance
         */
        SeatDeadline.prototype.seconds = 0;

        /**
         * Creates a new SeatDeadline instance using the specified properties.
         * @function create
         * @memberof base.SeatDeadline
         * @static
         * @param {base.ISeatDeadline=} [properties] Properties to set
         * @returns {base.SeatDeadline} SeatDeadline instance
         */
        SeatDeadline.create = function create(properties) {
            return new SeatDeadline(properties);
        };

        /**
         * Encodes the specified SeatDeadline message. Does not implicitly {@link base.SeatDeadline.verify|verify} messages.
         * @function encode
         * @memberof base.SeatDeadline
         * @static
         * @param {base.ISeatDeadline} message SeatDeadline message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeatDeadline.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.seat);
            if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.startTime);
            if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.seconds);
            return writer;
        };

        /**
         * Encodes the specified SeatDeadline message, length delimited. Does not implicitly {@link base.SeatDeadline.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.SeatDeadline
         * @static
         * @param {base.ISeatDeadline} message SeatDeadline message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SeatDeadline.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SeatDeadline message from the specified reader or buffer.
         * @function decode
         * @memberof base.SeatDeadline
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.SeatDeadline} SeatDeadline
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeatDeadline.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.SeatDeadline();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.seat = reader.int32();
                    break;
                case 3:
                    message.startTime = reader.int64();
                    break;
                case 4:
                    message.seconds = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SeatDeadline message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.SeatDeadline
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.SeatDeadline} SeatDeadline
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SeatDeadline.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SeatDeadline message.
         * @function verify
         * @memberof base.SeatDeadline
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SeatDeadline.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isInteger(message.type))
                    return "type: integer expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (!$util.isInteger(message.startTime) && !(message.startTime && $util.isInteger(message.startTime.low) && $util.isInteger(message.startTime.high)))
                    return "startTime: integer|Long expected";
            if (message.seconds != null && message.hasOwnProperty("seconds"))
                if (!$util.isInteger(message.seconds))
                    return "seconds: integer expected";
            return null;
        };

        /**
         * Creates a SeatDeadline message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.SeatDeadline
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.SeatDeadline} SeatDeadline
         */
        SeatDeadline.fromObject = function fromObject(object) {
            if (object instanceof $root.base.SeatDeadline)
                return object;
            var message = new $root.base.SeatDeadline();
            if (object.type != null)
                message.type = object.type | 0;
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.startTime != null)
                if ($util.Long)
                    (message.startTime = $util.Long.fromValue(object.startTime)).unsigned = false;
                else if (typeof object.startTime === "string")
                    message.startTime = parseInt(object.startTime, 10);
                else if (typeof object.startTime === "number")
                    message.startTime = object.startTime;
                else if (typeof object.startTime === "object")
                    message.startTime = new $util.LongBits(object.startTime.low >>> 0, object.startTime.high >>> 0).toNumber();
            if (object.seconds != null)
                message.seconds = object.seconds | 0;
            return message;
        };

        /**
         * Creates a plain object from a SeatDeadline message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.SeatDeadline
         * @static
         * @param {base.SeatDeadline} message SeatDeadline
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SeatDeadline.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.type = 0;
                object.seat = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.startTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.startTime = options.longs === String ? "0" : 0;
                object.seconds = 0;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (typeof message.startTime === "number")
                    object.startTime = options.longs === String ? String(message.startTime) : message.startTime;
                else
                    object.startTime = options.longs === String ? $util.Long.prototype.toString.call(message.startTime) : options.longs === Number ? new $util.LongBits(message.startTime.low >>> 0, message.startTime.high >>> 0).toNumber() : message.startTime;
            if (message.seconds != null && message.hasOwnProperty("seconds"))
                object.seconds = message.seconds;
            return object;
        };

        /**
         * Converts this SeatDeadline to JSON.
         * @function toJSON
         * @memberof base.SeatDeadline
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SeatDeadline.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SeatDeadline;
    })();

    base.GameBalanceEvent = (function() {

        /**
         * Properties of a GameBalanceEvent.
         * @memberof base
         * @interface IGameBalanceEvent
         * @property {number|Long|null} [balance] GameBalanceEvent balance
         * @property {string|null} [changed] GameBalanceEvent changed
         * @property {number|null} [updateType] GameBalanceEvent updateType
         * @property {number|null} [currencyType] GameBalanceEvent currencyType
         */

        /**
         * Constructs a new GameBalanceEvent.
         * @memberof base
         * @classdesc Represents a GameBalanceEvent.
         * @implements IGameBalanceEvent
         * @constructor
         * @param {base.IGameBalanceEvent=} [properties] Properties to set
         */
        function GameBalanceEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameBalanceEvent balance.
         * @member {number|Long} balance
         * @memberof base.GameBalanceEvent
         * @instance
         */
        GameBalanceEvent.prototype.balance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GameBalanceEvent changed.
         * @member {string} changed
         * @memberof base.GameBalanceEvent
         * @instance
         */
        GameBalanceEvent.prototype.changed = "";

        /**
         * GameBalanceEvent updateType.
         * @member {number} updateType
         * @memberof base.GameBalanceEvent
         * @instance
         */
        GameBalanceEvent.prototype.updateType = 0;

        /**
         * GameBalanceEvent currencyType.
         * @member {number} currencyType
         * @memberof base.GameBalanceEvent
         * @instance
         */
        GameBalanceEvent.prototype.currencyType = 0;

        /**
         * Creates a new GameBalanceEvent instance using the specified properties.
         * @function create
         * @memberof base.GameBalanceEvent
         * @static
         * @param {base.IGameBalanceEvent=} [properties] Properties to set
         * @returns {base.GameBalanceEvent} GameBalanceEvent instance
         */
        GameBalanceEvent.create = function create(properties) {
            return new GameBalanceEvent(properties);
        };

        /**
         * Encodes the specified GameBalanceEvent message. Does not implicitly {@link base.GameBalanceEvent.verify|verify} messages.
         * @function encode
         * @memberof base.GameBalanceEvent
         * @static
         * @param {base.IGameBalanceEvent} message GameBalanceEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameBalanceEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.balance);
            if (message.changed != null && Object.hasOwnProperty.call(message, "changed"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.changed);
            if (message.updateType != null && Object.hasOwnProperty.call(message, "updateType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.updateType);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.currencyType);
            return writer;
        };

        /**
         * Encodes the specified GameBalanceEvent message, length delimited. Does not implicitly {@link base.GameBalanceEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.GameBalanceEvent
         * @static
         * @param {base.IGameBalanceEvent} message GameBalanceEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameBalanceEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameBalanceEvent message from the specified reader or buffer.
         * @function decode
         * @memberof base.GameBalanceEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.GameBalanceEvent} GameBalanceEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameBalanceEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.GameBalanceEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.balance = reader.int64();
                    break;
                case 2:
                    message.changed = reader.string();
                    break;
                case 3:
                    message.updateType = reader.int32();
                    break;
                case 4:
                    message.currencyType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameBalanceEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.GameBalanceEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.GameBalanceEvent} GameBalanceEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameBalanceEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameBalanceEvent message.
         * @function verify
         * @memberof base.GameBalanceEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameBalanceEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (!$util.isInteger(message.balance) && !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high)))
                    return "balance: integer|Long expected";
            if (message.changed != null && message.hasOwnProperty("changed"))
                if (!$util.isString(message.changed))
                    return "changed: string expected";
            if (message.updateType != null && message.hasOwnProperty("updateType"))
                if (!$util.isInteger(message.updateType))
                    return "updateType: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            return null;
        };

        /**
         * Creates a GameBalanceEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.GameBalanceEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.GameBalanceEvent} GameBalanceEvent
         */
        GameBalanceEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.base.GameBalanceEvent)
                return object;
            var message = new $root.base.GameBalanceEvent();
            if (object.balance != null)
                if ($util.Long)
                    (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
                else if (typeof object.balance === "string")
                    message.balance = parseInt(object.balance, 10);
                else if (typeof object.balance === "number")
                    message.balance = object.balance;
                else if (typeof object.balance === "object")
                    message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
            if (object.changed != null)
                message.changed = String(object.changed);
            if (object.updateType != null)
                message.updateType = object.updateType | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            return message;
        };

        /**
         * Creates a plain object from a GameBalanceEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.GameBalanceEvent
         * @static
         * @param {base.GameBalanceEvent} message GameBalanceEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameBalanceEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.balance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.balance = options.longs === String ? "0" : 0;
                object.changed = "";
                object.updateType = 0;
                object.currencyType = 0;
            }
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (typeof message.balance === "number")
                    object.balance = options.longs === String ? String(message.balance) : message.balance;
                else
                    object.balance = options.longs === String ? $util.Long.prototype.toString.call(message.balance) : options.longs === Number ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber() : message.balance;
            if (message.changed != null && message.hasOwnProperty("changed"))
                object.changed = message.changed;
            if (message.updateType != null && message.hasOwnProperty("updateType"))
                object.updateType = message.updateType;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            return object;
        };

        /**
         * Converts this GameBalanceEvent to JSON.
         * @function toJSON
         * @memberof base.GameBalanceEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameBalanceEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameBalanceEvent;
    })();

    base.ChatExpressionReq = (function() {

        /**
         * Properties of a ChatExpressionReq.
         * @memberof base
         * @interface IChatExpressionReq
         * @property {number|null} [seat] ChatExpressionReq seat
         * @property {number|null} [expression] ChatExpressionReq expression
         */

        /**
         * Constructs a new ChatExpressionReq.
         * @memberof base
         * @classdesc Represents a ChatExpressionReq.
         * @implements IChatExpressionReq
         * @constructor
         * @param {base.IChatExpressionReq=} [properties] Properties to set
         */
        function ChatExpressionReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChatExpressionReq seat.
         * @member {number} seat
         * @memberof base.ChatExpressionReq
         * @instance
         */
        ChatExpressionReq.prototype.seat = 0;

        /**
         * ChatExpressionReq expression.
         * @member {number} expression
         * @memberof base.ChatExpressionReq
         * @instance
         */
        ChatExpressionReq.prototype.expression = 0;

        /**
         * Creates a new ChatExpressionReq instance using the specified properties.
         * @function create
         * @memberof base.ChatExpressionReq
         * @static
         * @param {base.IChatExpressionReq=} [properties] Properties to set
         * @returns {base.ChatExpressionReq} ChatExpressionReq instance
         */
        ChatExpressionReq.create = function create(properties) {
            return new ChatExpressionReq(properties);
        };

        /**
         * Encodes the specified ChatExpressionReq message. Does not implicitly {@link base.ChatExpressionReq.verify|verify} messages.
         * @function encode
         * @memberof base.ChatExpressionReq
         * @static
         * @param {base.IChatExpressionReq} message ChatExpressionReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatExpressionReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            if (message.expression != null && Object.hasOwnProperty.call(message, "expression"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.expression);
            return writer;
        };

        /**
         * Encodes the specified ChatExpressionReq message, length delimited. Does not implicitly {@link base.ChatExpressionReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.ChatExpressionReq
         * @static
         * @param {base.IChatExpressionReq} message ChatExpressionReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatExpressionReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChatExpressionReq message from the specified reader or buffer.
         * @function decode
         * @memberof base.ChatExpressionReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.ChatExpressionReq} ChatExpressionReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatExpressionReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.ChatExpressionReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                case 2:
                    message.expression = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChatExpressionReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.ChatExpressionReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.ChatExpressionReq} ChatExpressionReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatExpressionReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChatExpressionReq message.
         * @function verify
         * @memberof base.ChatExpressionReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChatExpressionReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.expression != null && message.hasOwnProperty("expression"))
                if (!$util.isInteger(message.expression))
                    return "expression: integer expected";
            return null;
        };

        /**
         * Creates a ChatExpressionReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.ChatExpressionReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.ChatExpressionReq} ChatExpressionReq
         */
        ChatExpressionReq.fromObject = function fromObject(object) {
            if (object instanceof $root.base.ChatExpressionReq)
                return object;
            var message = new $root.base.ChatExpressionReq();
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.expression != null)
                message.expression = object.expression | 0;
            return message;
        };

        /**
         * Creates a plain object from a ChatExpressionReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.ChatExpressionReq
         * @static
         * @param {base.ChatExpressionReq} message ChatExpressionReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChatExpressionReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.seat = 0;
                object.expression = 0;
            }
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.expression != null && message.hasOwnProperty("expression"))
                object.expression = message.expression;
            return object;
        };

        /**
         * Converts this ChatExpressionReq to JSON.
         * @function toJSON
         * @memberof base.ChatExpressionReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChatExpressionReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChatExpressionReq;
    })();

    base.ChatExpressionEvent = (function() {

        /**
         * Properties of a ChatExpressionEvent.
         * @memberof base
         * @interface IChatExpressionEvent
         * @property {number|null} [fromSeat] ChatExpressionEvent fromSeat
         * @property {number|null} [toSeat] ChatExpressionEvent toSeat
         * @property {number|null} [expression] ChatExpressionEvent expression
         */

        /**
         * Constructs a new ChatExpressionEvent.
         * @memberof base
         * @classdesc Represents a ChatExpressionEvent.
         * @implements IChatExpressionEvent
         * @constructor
         * @param {base.IChatExpressionEvent=} [properties] Properties to set
         */
        function ChatExpressionEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChatExpressionEvent fromSeat.
         * @member {number} fromSeat
         * @memberof base.ChatExpressionEvent
         * @instance
         */
        ChatExpressionEvent.prototype.fromSeat = 0;

        /**
         * ChatExpressionEvent toSeat.
         * @member {number} toSeat
         * @memberof base.ChatExpressionEvent
         * @instance
         */
        ChatExpressionEvent.prototype.toSeat = 0;

        /**
         * ChatExpressionEvent expression.
         * @member {number} expression
         * @memberof base.ChatExpressionEvent
         * @instance
         */
        ChatExpressionEvent.prototype.expression = 0;

        /**
         * Creates a new ChatExpressionEvent instance using the specified properties.
         * @function create
         * @memberof base.ChatExpressionEvent
         * @static
         * @param {base.IChatExpressionEvent=} [properties] Properties to set
         * @returns {base.ChatExpressionEvent} ChatExpressionEvent instance
         */
        ChatExpressionEvent.create = function create(properties) {
            return new ChatExpressionEvent(properties);
        };

        /**
         * Encodes the specified ChatExpressionEvent message. Does not implicitly {@link base.ChatExpressionEvent.verify|verify} messages.
         * @function encode
         * @memberof base.ChatExpressionEvent
         * @static
         * @param {base.IChatExpressionEvent} message ChatExpressionEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatExpressionEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fromSeat != null && Object.hasOwnProperty.call(message, "fromSeat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.fromSeat);
            if (message.toSeat != null && Object.hasOwnProperty.call(message, "toSeat"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.toSeat);
            if (message.expression != null && Object.hasOwnProperty.call(message, "expression"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.expression);
            return writer;
        };

        /**
         * Encodes the specified ChatExpressionEvent message, length delimited. Does not implicitly {@link base.ChatExpressionEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.ChatExpressionEvent
         * @static
         * @param {base.IChatExpressionEvent} message ChatExpressionEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatExpressionEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChatExpressionEvent message from the specified reader or buffer.
         * @function decode
         * @memberof base.ChatExpressionEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.ChatExpressionEvent} ChatExpressionEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatExpressionEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.ChatExpressionEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fromSeat = reader.int32();
                    break;
                case 2:
                    message.toSeat = reader.int32();
                    break;
                case 3:
                    message.expression = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChatExpressionEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.ChatExpressionEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.ChatExpressionEvent} ChatExpressionEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatExpressionEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChatExpressionEvent message.
         * @function verify
         * @memberof base.ChatExpressionEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChatExpressionEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fromSeat != null && message.hasOwnProperty("fromSeat"))
                if (!$util.isInteger(message.fromSeat))
                    return "fromSeat: integer expected";
            if (message.toSeat != null && message.hasOwnProperty("toSeat"))
                if (!$util.isInteger(message.toSeat))
                    return "toSeat: integer expected";
            if (message.expression != null && message.hasOwnProperty("expression"))
                if (!$util.isInteger(message.expression))
                    return "expression: integer expected";
            return null;
        };

        /**
         * Creates a ChatExpressionEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.ChatExpressionEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.ChatExpressionEvent} ChatExpressionEvent
         */
        ChatExpressionEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.base.ChatExpressionEvent)
                return object;
            var message = new $root.base.ChatExpressionEvent();
            if (object.fromSeat != null)
                message.fromSeat = object.fromSeat | 0;
            if (object.toSeat != null)
                message.toSeat = object.toSeat | 0;
            if (object.expression != null)
                message.expression = object.expression | 0;
            return message;
        };

        /**
         * Creates a plain object from a ChatExpressionEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.ChatExpressionEvent
         * @static
         * @param {base.ChatExpressionEvent} message ChatExpressionEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChatExpressionEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.fromSeat = 0;
                object.toSeat = 0;
                object.expression = 0;
            }
            if (message.fromSeat != null && message.hasOwnProperty("fromSeat"))
                object.fromSeat = message.fromSeat;
            if (message.toSeat != null && message.hasOwnProperty("toSeat"))
                object.toSeat = message.toSeat;
            if (message.expression != null && message.hasOwnProperty("expression"))
                object.expression = message.expression;
            return object;
        };

        /**
         * Converts this ChatExpressionEvent to JSON.
         * @function toJSON
         * @memberof base.ChatExpressionEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChatExpressionEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChatExpressionEvent;
    })();

    base.ChatVoiceReq = (function() {

        /**
         * Properties of a ChatVoiceReq.
         * @memberof base
         * @interface IChatVoiceReq
         * @property {number|null} [seat] ChatVoiceReq seat
         * @property {string|null} [content] ChatVoiceReq content
         * @property {number|null} [time] ChatVoiceReq time
         */

        /**
         * Constructs a new ChatVoiceReq.
         * @memberof base
         * @classdesc Represents a ChatVoiceReq.
         * @implements IChatVoiceReq
         * @constructor
         * @param {base.IChatVoiceReq=} [properties] Properties to set
         */
        function ChatVoiceReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChatVoiceReq seat.
         * @member {number} seat
         * @memberof base.ChatVoiceReq
         * @instance
         */
        ChatVoiceReq.prototype.seat = 0;

        /**
         * ChatVoiceReq content.
         * @member {string} content
         * @memberof base.ChatVoiceReq
         * @instance
         */
        ChatVoiceReq.prototype.content = "";

        /**
         * ChatVoiceReq time.
         * @member {number} time
         * @memberof base.ChatVoiceReq
         * @instance
         */
        ChatVoiceReq.prototype.time = 0;

        /**
         * Creates a new ChatVoiceReq instance using the specified properties.
         * @function create
         * @memberof base.ChatVoiceReq
         * @static
         * @param {base.IChatVoiceReq=} [properties] Properties to set
         * @returns {base.ChatVoiceReq} ChatVoiceReq instance
         */
        ChatVoiceReq.create = function create(properties) {
            return new ChatVoiceReq(properties);
        };

        /**
         * Encodes the specified ChatVoiceReq message. Does not implicitly {@link base.ChatVoiceReq.verify|verify} messages.
         * @function encode
         * @memberof base.ChatVoiceReq
         * @static
         * @param {base.IChatVoiceReq} message ChatVoiceReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatVoiceReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.content);
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.time);
            return writer;
        };

        /**
         * Encodes the specified ChatVoiceReq message, length delimited. Does not implicitly {@link base.ChatVoiceReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.ChatVoiceReq
         * @static
         * @param {base.IChatVoiceReq} message ChatVoiceReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatVoiceReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChatVoiceReq message from the specified reader or buffer.
         * @function decode
         * @memberof base.ChatVoiceReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.ChatVoiceReq} ChatVoiceReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatVoiceReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.ChatVoiceReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                case 2:
                    message.content = reader.string();
                    break;
                case 3:
                    message.time = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChatVoiceReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.ChatVoiceReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.ChatVoiceReq} ChatVoiceReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatVoiceReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChatVoiceReq message.
         * @function verify
         * @memberof base.ChatVoiceReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChatVoiceReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            if (message.time != null && message.hasOwnProperty("time"))
                if (!$util.isInteger(message.time))
                    return "time: integer expected";
            return null;
        };

        /**
         * Creates a ChatVoiceReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.ChatVoiceReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.ChatVoiceReq} ChatVoiceReq
         */
        ChatVoiceReq.fromObject = function fromObject(object) {
            if (object instanceof $root.base.ChatVoiceReq)
                return object;
            var message = new $root.base.ChatVoiceReq();
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.content != null)
                message.content = String(object.content);
            if (object.time != null)
                message.time = object.time | 0;
            return message;
        };

        /**
         * Creates a plain object from a ChatVoiceReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.ChatVoiceReq
         * @static
         * @param {base.ChatVoiceReq} message ChatVoiceReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChatVoiceReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.seat = 0;
                object.content = "";
                object.time = 0;
            }
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            if (message.time != null && message.hasOwnProperty("time"))
                object.time = message.time;
            return object;
        };

        /**
         * Converts this ChatVoiceReq to JSON.
         * @function toJSON
         * @memberof base.ChatVoiceReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChatVoiceReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChatVoiceReq;
    })();

    base.ChatVoiceEvent = (function() {

        /**
         * Properties of a ChatVoiceEvent.
         * @memberof base
         * @interface IChatVoiceEvent
         * @property {number|null} [fromSeat] ChatVoiceEvent fromSeat
         * @property {number|null} [toSeat] ChatVoiceEvent toSeat
         * @property {string|null} [content] ChatVoiceEvent content
         * @property {number|null} [time] ChatVoiceEvent time
         */

        /**
         * Constructs a new ChatVoiceEvent.
         * @memberof base
         * @classdesc Represents a ChatVoiceEvent.
         * @implements IChatVoiceEvent
         * @constructor
         * @param {base.IChatVoiceEvent=} [properties] Properties to set
         */
        function ChatVoiceEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChatVoiceEvent fromSeat.
         * @member {number} fromSeat
         * @memberof base.ChatVoiceEvent
         * @instance
         */
        ChatVoiceEvent.prototype.fromSeat = 0;

        /**
         * ChatVoiceEvent toSeat.
         * @member {number} toSeat
         * @memberof base.ChatVoiceEvent
         * @instance
         */
        ChatVoiceEvent.prototype.toSeat = 0;

        /**
         * ChatVoiceEvent content.
         * @member {string} content
         * @memberof base.ChatVoiceEvent
         * @instance
         */
        ChatVoiceEvent.prototype.content = "";

        /**
         * ChatVoiceEvent time.
         * @member {number} time
         * @memberof base.ChatVoiceEvent
         * @instance
         */
        ChatVoiceEvent.prototype.time = 0;

        /**
         * Creates a new ChatVoiceEvent instance using the specified properties.
         * @function create
         * @memberof base.ChatVoiceEvent
         * @static
         * @param {base.IChatVoiceEvent=} [properties] Properties to set
         * @returns {base.ChatVoiceEvent} ChatVoiceEvent instance
         */
        ChatVoiceEvent.create = function create(properties) {
            return new ChatVoiceEvent(properties);
        };

        /**
         * Encodes the specified ChatVoiceEvent message. Does not implicitly {@link base.ChatVoiceEvent.verify|verify} messages.
         * @function encode
         * @memberof base.ChatVoiceEvent
         * @static
         * @param {base.IChatVoiceEvent} message ChatVoiceEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatVoiceEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fromSeat != null && Object.hasOwnProperty.call(message, "fromSeat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.fromSeat);
            if (message.toSeat != null && Object.hasOwnProperty.call(message, "toSeat"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.toSeat);
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.content);
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.time);
            return writer;
        };

        /**
         * Encodes the specified ChatVoiceEvent message, length delimited. Does not implicitly {@link base.ChatVoiceEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.ChatVoiceEvent
         * @static
         * @param {base.IChatVoiceEvent} message ChatVoiceEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatVoiceEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChatVoiceEvent message from the specified reader or buffer.
         * @function decode
         * @memberof base.ChatVoiceEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.ChatVoiceEvent} ChatVoiceEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatVoiceEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.ChatVoiceEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fromSeat = reader.int32();
                    break;
                case 2:
                    message.toSeat = reader.int32();
                    break;
                case 3:
                    message.content = reader.string();
                    break;
                case 4:
                    message.time = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChatVoiceEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.ChatVoiceEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.ChatVoiceEvent} ChatVoiceEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatVoiceEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChatVoiceEvent message.
         * @function verify
         * @memberof base.ChatVoiceEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChatVoiceEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fromSeat != null && message.hasOwnProperty("fromSeat"))
                if (!$util.isInteger(message.fromSeat))
                    return "fromSeat: integer expected";
            if (message.toSeat != null && message.hasOwnProperty("toSeat"))
                if (!$util.isInteger(message.toSeat))
                    return "toSeat: integer expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            if (message.time != null && message.hasOwnProperty("time"))
                if (!$util.isInteger(message.time))
                    return "time: integer expected";
            return null;
        };

        /**
         * Creates a ChatVoiceEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.ChatVoiceEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.ChatVoiceEvent} ChatVoiceEvent
         */
        ChatVoiceEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.base.ChatVoiceEvent)
                return object;
            var message = new $root.base.ChatVoiceEvent();
            if (object.fromSeat != null)
                message.fromSeat = object.fromSeat | 0;
            if (object.toSeat != null)
                message.toSeat = object.toSeat | 0;
            if (object.content != null)
                message.content = String(object.content);
            if (object.time != null)
                message.time = object.time | 0;
            return message;
        };

        /**
         * Creates a plain object from a ChatVoiceEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.ChatVoiceEvent
         * @static
         * @param {base.ChatVoiceEvent} message ChatVoiceEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChatVoiceEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.fromSeat = 0;
                object.toSeat = 0;
                object.content = "";
                object.time = 0;
            }
            if (message.fromSeat != null && message.hasOwnProperty("fromSeat"))
                object.fromSeat = message.fromSeat;
            if (message.toSeat != null && message.hasOwnProperty("toSeat"))
                object.toSeat = message.toSeat;
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            if (message.time != null && message.hasOwnProperty("time"))
                object.time = message.time;
            return object;
        };

        /**
         * Converts this ChatVoiceEvent to JSON.
         * @function toJSON
         * @memberof base.ChatVoiceEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChatVoiceEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChatVoiceEvent;
    })();

    base.SendCommand = (function() {

        /**
         * Properties of a SendCommand.
         * @memberof base
         * @interface ISendCommand
         * @property {number|null} [type] SendCommand type
         * @property {string|null} [param] SendCommand param
         */

        /**
         * Constructs a new SendCommand.
         * @memberof base
         * @classdesc Represents a SendCommand.
         * @implements ISendCommand
         * @constructor
         * @param {base.ISendCommand=} [properties] Properties to set
         */
        function SendCommand(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendCommand type.
         * @member {number} type
         * @memberof base.SendCommand
         * @instance
         */
        SendCommand.prototype.type = 0;

        /**
         * SendCommand param.
         * @member {string} param
         * @memberof base.SendCommand
         * @instance
         */
        SendCommand.prototype.param = "";

        /**
         * Creates a new SendCommand instance using the specified properties.
         * @function create
         * @memberof base.SendCommand
         * @static
         * @param {base.ISendCommand=} [properties] Properties to set
         * @returns {base.SendCommand} SendCommand instance
         */
        SendCommand.create = function create(properties) {
            return new SendCommand(properties);
        };

        /**
         * Encodes the specified SendCommand message. Does not implicitly {@link base.SendCommand.verify|verify} messages.
         * @function encode
         * @memberof base.SendCommand
         * @static
         * @param {base.ISendCommand} message SendCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendCommand.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.param != null && Object.hasOwnProperty.call(message, "param"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.param);
            return writer;
        };

        /**
         * Encodes the specified SendCommand message, length delimited. Does not implicitly {@link base.SendCommand.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.SendCommand
         * @static
         * @param {base.ISendCommand} message SendCommand message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendCommand.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendCommand message from the specified reader or buffer.
         * @function decode
         * @memberof base.SendCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.SendCommand} SendCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendCommand.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.SendCommand();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    message.param = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendCommand message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.SendCommand
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.SendCommand} SendCommand
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendCommand.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendCommand message.
         * @function verify
         * @memberof base.SendCommand
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendCommand.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isInteger(message.type))
                    return "type: integer expected";
            if (message.param != null && message.hasOwnProperty("param"))
                if (!$util.isString(message.param))
                    return "param: string expected";
            return null;
        };

        /**
         * Creates a SendCommand message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.SendCommand
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.SendCommand} SendCommand
         */
        SendCommand.fromObject = function fromObject(object) {
            if (object instanceof $root.base.SendCommand)
                return object;
            var message = new $root.base.SendCommand();
            if (object.type != null)
                message.type = object.type | 0;
            if (object.param != null)
                message.param = String(object.param);
            return message;
        };

        /**
         * Creates a plain object from a SendCommand message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.SendCommand
         * @static
         * @param {base.SendCommand} message SendCommand
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendCommand.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.type = 0;
                object.param = "";
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            if (message.param != null && message.hasOwnProperty("param"))
                object.param = message.param;
            return object;
        };

        /**
         * Converts this SendCommand to JSON.
         * @function toJSON
         * @memberof base.SendCommand
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendCommand.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendCommand;
    })();

    /**
     * NOTICE_TYPE enum.
     * @name base.NOTICE_TYPE
     * @enum {number}
     * @property {number} NT_NONE=0 NT_NONE value
     * @property {number} NT_WITHDRAW=1 NT_WITHDRAW value
     * @property {number} NT_GAME_WIN=2 NT_GAME_WIN value
     * @property {number} NT_WHEEL=3 NT_WHEEL value
     */
    base.NOTICE_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NT_NONE"] = 0;
        values[valuesById[1] = "NT_WITHDRAW"] = 1;
        values[valuesById[2] = "NT_GAME_WIN"] = 2;
        values[valuesById[3] = "NT_WHEEL"] = 3;
        return values;
    })();

    base.PrivateTableReadyReq = (function() {

        /**
         * Properties of a PrivateTableReadyReq.
         * @memberof base
         * @interface IPrivateTableReadyReq
         * @property {number|null} [seat] PrivateTableReadyReq seat
         */

        /**
         * Constructs a new PrivateTableReadyReq.
         * @memberof base
         * @classdesc Represents a PrivateTableReadyReq.
         * @implements IPrivateTableReadyReq
         * @constructor
         * @param {base.IPrivateTableReadyReq=} [properties] Properties to set
         */
        function PrivateTableReadyReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PrivateTableReadyReq seat.
         * @member {number} seat
         * @memberof base.PrivateTableReadyReq
         * @instance
         */
        PrivateTableReadyReq.prototype.seat = 0;

        /**
         * Creates a new PrivateTableReadyReq instance using the specified properties.
         * @function create
         * @memberof base.PrivateTableReadyReq
         * @static
         * @param {base.IPrivateTableReadyReq=} [properties] Properties to set
         * @returns {base.PrivateTableReadyReq} PrivateTableReadyReq instance
         */
        PrivateTableReadyReq.create = function create(properties) {
            return new PrivateTableReadyReq(properties);
        };

        /**
         * Encodes the specified PrivateTableReadyReq message. Does not implicitly {@link base.PrivateTableReadyReq.verify|verify} messages.
         * @function encode
         * @memberof base.PrivateTableReadyReq
         * @static
         * @param {base.IPrivateTableReadyReq} message PrivateTableReadyReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PrivateTableReadyReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            return writer;
        };

        /**
         * Encodes the specified PrivateTableReadyReq message, length delimited. Does not implicitly {@link base.PrivateTableReadyReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.PrivateTableReadyReq
         * @static
         * @param {base.IPrivateTableReadyReq} message PrivateTableReadyReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PrivateTableReadyReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PrivateTableReadyReq message from the specified reader or buffer.
         * @function decode
         * @memberof base.PrivateTableReadyReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.PrivateTableReadyReq} PrivateTableReadyReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PrivateTableReadyReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.PrivateTableReadyReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PrivateTableReadyReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.PrivateTableReadyReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.PrivateTableReadyReq} PrivateTableReadyReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PrivateTableReadyReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PrivateTableReadyReq message.
         * @function verify
         * @memberof base.PrivateTableReadyReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PrivateTableReadyReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            return null;
        };

        /**
         * Creates a PrivateTableReadyReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.PrivateTableReadyReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.PrivateTableReadyReq} PrivateTableReadyReq
         */
        PrivateTableReadyReq.fromObject = function fromObject(object) {
            if (object instanceof $root.base.PrivateTableReadyReq)
                return object;
            var message = new $root.base.PrivateTableReadyReq();
            if (object.seat != null)
                message.seat = object.seat | 0;
            return message;
        };

        /**
         * Creates a plain object from a PrivateTableReadyReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.PrivateTableReadyReq
         * @static
         * @param {base.PrivateTableReadyReq} message PrivateTableReadyReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PrivateTableReadyReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.seat = 0;
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            return object;
        };

        /**
         * Converts this PrivateTableReadyReq to JSON.
         * @function toJSON
         * @memberof base.PrivateTableReadyReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PrivateTableReadyReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PrivateTableReadyReq;
    })();

    base.PrivateTableReadyEvent = (function() {

        /**
         * Properties of a PrivateTableReadyEvent.
         * @memberof base
         * @interface IPrivateTableReadyEvent
         * @property {number|null} [seat] PrivateTableReadyEvent seat
         */

        /**
         * Constructs a new PrivateTableReadyEvent.
         * @memberof base
         * @classdesc Represents a PrivateTableReadyEvent.
         * @implements IPrivateTableReadyEvent
         * @constructor
         * @param {base.IPrivateTableReadyEvent=} [properties] Properties to set
         */
        function PrivateTableReadyEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PrivateTableReadyEvent seat.
         * @member {number} seat
         * @memberof base.PrivateTableReadyEvent
         * @instance
         */
        PrivateTableReadyEvent.prototype.seat = 0;

        /**
         * Creates a new PrivateTableReadyEvent instance using the specified properties.
         * @function create
         * @memberof base.PrivateTableReadyEvent
         * @static
         * @param {base.IPrivateTableReadyEvent=} [properties] Properties to set
         * @returns {base.PrivateTableReadyEvent} PrivateTableReadyEvent instance
         */
        PrivateTableReadyEvent.create = function create(properties) {
            return new PrivateTableReadyEvent(properties);
        };

        /**
         * Encodes the specified PrivateTableReadyEvent message. Does not implicitly {@link base.PrivateTableReadyEvent.verify|verify} messages.
         * @function encode
         * @memberof base.PrivateTableReadyEvent
         * @static
         * @param {base.IPrivateTableReadyEvent} message PrivateTableReadyEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PrivateTableReadyEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            return writer;
        };

        /**
         * Encodes the specified PrivateTableReadyEvent message, length delimited. Does not implicitly {@link base.PrivateTableReadyEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.PrivateTableReadyEvent
         * @static
         * @param {base.IPrivateTableReadyEvent} message PrivateTableReadyEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PrivateTableReadyEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PrivateTableReadyEvent message from the specified reader or buffer.
         * @function decode
         * @memberof base.PrivateTableReadyEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.PrivateTableReadyEvent} PrivateTableReadyEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PrivateTableReadyEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.PrivateTableReadyEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PrivateTableReadyEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.PrivateTableReadyEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.PrivateTableReadyEvent} PrivateTableReadyEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PrivateTableReadyEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PrivateTableReadyEvent message.
         * @function verify
         * @memberof base.PrivateTableReadyEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PrivateTableReadyEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            return null;
        };

        /**
         * Creates a PrivateTableReadyEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.PrivateTableReadyEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.PrivateTableReadyEvent} PrivateTableReadyEvent
         */
        PrivateTableReadyEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.base.PrivateTableReadyEvent)
                return object;
            var message = new $root.base.PrivateTableReadyEvent();
            if (object.seat != null)
                message.seat = object.seat | 0;
            return message;
        };

        /**
         * Creates a plain object from a PrivateTableReadyEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.PrivateTableReadyEvent
         * @static
         * @param {base.PrivateTableReadyEvent} message PrivateTableReadyEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PrivateTableReadyEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.seat = 0;
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            return object;
        };

        /**
         * Converts this PrivateTableReadyEvent to JSON.
         * @function toJSON
         * @memberof base.PrivateTableReadyEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PrivateTableReadyEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PrivateTableReadyEvent;
    })();

    base.SyncGamePayout = (function() {

        /**
         * Properties of a SyncGamePayout.
         * @memberof base
         * @interface ISyncGamePayout
         * @property {string|null} [payout] SyncGamePayout payout
         */

        /**
         * Constructs a new SyncGamePayout.
         * @memberof base
         * @classdesc Represents a SyncGamePayout.
         * @implements ISyncGamePayout
         * @constructor
         * @param {base.ISyncGamePayout=} [properties] Properties to set
         */
        function SyncGamePayout(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SyncGamePayout payout.
         * @member {string} payout
         * @memberof base.SyncGamePayout
         * @instance
         */
        SyncGamePayout.prototype.payout = "";

        /**
         * Creates a new SyncGamePayout instance using the specified properties.
         * @function create
         * @memberof base.SyncGamePayout
         * @static
         * @param {base.ISyncGamePayout=} [properties] Properties to set
         * @returns {base.SyncGamePayout} SyncGamePayout instance
         */
        SyncGamePayout.create = function create(properties) {
            return new SyncGamePayout(properties);
        };

        /**
         * Encodes the specified SyncGamePayout message. Does not implicitly {@link base.SyncGamePayout.verify|verify} messages.
         * @function encode
         * @memberof base.SyncGamePayout
         * @static
         * @param {base.ISyncGamePayout} message SyncGamePayout message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncGamePayout.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.payout);
            return writer;
        };

        /**
         * Encodes the specified SyncGamePayout message, length delimited. Does not implicitly {@link base.SyncGamePayout.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.SyncGamePayout
         * @static
         * @param {base.ISyncGamePayout} message SyncGamePayout message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncGamePayout.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyncGamePayout message from the specified reader or buffer.
         * @function decode
         * @memberof base.SyncGamePayout
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.SyncGamePayout} SyncGamePayout
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncGamePayout.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.SyncGamePayout();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.payout = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyncGamePayout message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.SyncGamePayout
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.SyncGamePayout} SyncGamePayout
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncGamePayout.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyncGamePayout message.
         * @function verify
         * @memberof base.SyncGamePayout
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyncGamePayout.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isString(message.payout))
                    return "payout: string expected";
            return null;
        };

        /**
         * Creates a SyncGamePayout message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.SyncGamePayout
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.SyncGamePayout} SyncGamePayout
         */
        SyncGamePayout.fromObject = function fromObject(object) {
            if (object instanceof $root.base.SyncGamePayout)
                return object;
            var message = new $root.base.SyncGamePayout();
            if (object.payout != null)
                message.payout = String(object.payout);
            return message;
        };

        /**
         * Creates a plain object from a SyncGamePayout message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.SyncGamePayout
         * @static
         * @param {base.SyncGamePayout} message SyncGamePayout
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyncGamePayout.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.payout = "";
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            return object;
        };

        /**
         * Converts this SyncGamePayout to JSON.
         * @function toJSON
         * @memberof base.SyncGamePayout
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyncGamePayout.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SyncGamePayout;
    })();

    base.PlayerCurrencyCheckReq = (function() {

        /**
         * Properties of a PlayerCurrencyCheckReq.
         * @memberof base
         * @interface IPlayerCurrencyCheckReq
         * @property {number|null} [currency] PlayerCurrencyCheckReq currency
         */

        /**
         * Constructs a new PlayerCurrencyCheckReq.
         * @memberof base
         * @classdesc Represents a PlayerCurrencyCheckReq.
         * @implements IPlayerCurrencyCheckReq
         * @constructor
         * @param {base.IPlayerCurrencyCheckReq=} [properties] Properties to set
         */
        function PlayerCurrencyCheckReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerCurrencyCheckReq currency.
         * @member {number} currency
         * @memberof base.PlayerCurrencyCheckReq
         * @instance
         */
        PlayerCurrencyCheckReq.prototype.currency = 0;

        /**
         * Creates a new PlayerCurrencyCheckReq instance using the specified properties.
         * @function create
         * @memberof base.PlayerCurrencyCheckReq
         * @static
         * @param {base.IPlayerCurrencyCheckReq=} [properties] Properties to set
         * @returns {base.PlayerCurrencyCheckReq} PlayerCurrencyCheckReq instance
         */
        PlayerCurrencyCheckReq.create = function create(properties) {
            return new PlayerCurrencyCheckReq(properties);
        };

        /**
         * Encodes the specified PlayerCurrencyCheckReq message. Does not implicitly {@link base.PlayerCurrencyCheckReq.verify|verify} messages.
         * @function encode
         * @memberof base.PlayerCurrencyCheckReq
         * @static
         * @param {base.IPlayerCurrencyCheckReq} message PlayerCurrencyCheckReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerCurrencyCheckReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currency);
            return writer;
        };

        /**
         * Encodes the specified PlayerCurrencyCheckReq message, length delimited. Does not implicitly {@link base.PlayerCurrencyCheckReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.PlayerCurrencyCheckReq
         * @static
         * @param {base.IPlayerCurrencyCheckReq} message PlayerCurrencyCheckReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerCurrencyCheckReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerCurrencyCheckReq message from the specified reader or buffer.
         * @function decode
         * @memberof base.PlayerCurrencyCheckReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.PlayerCurrencyCheckReq} PlayerCurrencyCheckReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerCurrencyCheckReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.PlayerCurrencyCheckReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currency = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerCurrencyCheckReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.PlayerCurrencyCheckReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.PlayerCurrencyCheckReq} PlayerCurrencyCheckReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerCurrencyCheckReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerCurrencyCheckReq message.
         * @function verify
         * @memberof base.PlayerCurrencyCheckReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerCurrencyCheckReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isInteger(message.currency))
                    return "currency: integer expected";
            return null;
        };

        /**
         * Creates a PlayerCurrencyCheckReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.PlayerCurrencyCheckReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.PlayerCurrencyCheckReq} PlayerCurrencyCheckReq
         */
        PlayerCurrencyCheckReq.fromObject = function fromObject(object) {
            if (object instanceof $root.base.PlayerCurrencyCheckReq)
                return object;
            var message = new $root.base.PlayerCurrencyCheckReq();
            if (object.currency != null)
                message.currency = object.currency | 0;
            return message;
        };

        /**
         * Creates a plain object from a PlayerCurrencyCheckReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.PlayerCurrencyCheckReq
         * @static
         * @param {base.PlayerCurrencyCheckReq} message PlayerCurrencyCheckReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerCurrencyCheckReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.currency = 0;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            return object;
        };

        /**
         * Converts this PlayerCurrencyCheckReq to JSON.
         * @function toJSON
         * @memberof base.PlayerCurrencyCheckReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerCurrencyCheckReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerCurrencyCheckReq;
    })();

    base.PlayerCurrencyCheckReply = (function() {

        /**
         * Properties of a PlayerCurrencyCheckReply.
         * @memberof base
         * @interface IPlayerCurrencyCheckReply
         * @property {Array.<number>|null} [currency] PlayerCurrencyCheckReply currency
         */

        /**
         * Constructs a new PlayerCurrencyCheckReply.
         * @memberof base
         * @classdesc Represents a PlayerCurrencyCheckReply.
         * @implements IPlayerCurrencyCheckReply
         * @constructor
         * @param {base.IPlayerCurrencyCheckReply=} [properties] Properties to set
         */
        function PlayerCurrencyCheckReply(properties) {
            this.currency = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerCurrencyCheckReply currency.
         * @member {Array.<number>} currency
         * @memberof base.PlayerCurrencyCheckReply
         * @instance
         */
        PlayerCurrencyCheckReply.prototype.currency = $util.emptyArray;

        /**
         * Creates a new PlayerCurrencyCheckReply instance using the specified properties.
         * @function create
         * @memberof base.PlayerCurrencyCheckReply
         * @static
         * @param {base.IPlayerCurrencyCheckReply=} [properties] Properties to set
         * @returns {base.PlayerCurrencyCheckReply} PlayerCurrencyCheckReply instance
         */
        PlayerCurrencyCheckReply.create = function create(properties) {
            return new PlayerCurrencyCheckReply(properties);
        };

        /**
         * Encodes the specified PlayerCurrencyCheckReply message. Does not implicitly {@link base.PlayerCurrencyCheckReply.verify|verify} messages.
         * @function encode
         * @memberof base.PlayerCurrencyCheckReply
         * @static
         * @param {base.IPlayerCurrencyCheckReply} message PlayerCurrencyCheckReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerCurrencyCheckReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currency != null && message.currency.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.currency.length; ++i)
                    writer.int32(message.currency[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified PlayerCurrencyCheckReply message, length delimited. Does not implicitly {@link base.PlayerCurrencyCheckReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof base.PlayerCurrencyCheckReply
         * @static
         * @param {base.IPlayerCurrencyCheckReply} message PlayerCurrencyCheckReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerCurrencyCheckReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerCurrencyCheckReply message from the specified reader or buffer.
         * @function decode
         * @memberof base.PlayerCurrencyCheckReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {base.PlayerCurrencyCheckReply} PlayerCurrencyCheckReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerCurrencyCheckReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.base.PlayerCurrencyCheckReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.currency && message.currency.length))
                        message.currency = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.currency.push(reader.int32());
                    } else
                        message.currency.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerCurrencyCheckReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof base.PlayerCurrencyCheckReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {base.PlayerCurrencyCheckReply} PlayerCurrencyCheckReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerCurrencyCheckReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerCurrencyCheckReply message.
         * @function verify
         * @memberof base.PlayerCurrencyCheckReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerCurrencyCheckReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currency != null && message.hasOwnProperty("currency")) {
                if (!Array.isArray(message.currency))
                    return "currency: array expected";
                for (var i = 0; i < message.currency.length; ++i)
                    if (!$util.isInteger(message.currency[i]))
                        return "currency: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a PlayerCurrencyCheckReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof base.PlayerCurrencyCheckReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {base.PlayerCurrencyCheckReply} PlayerCurrencyCheckReply
         */
        PlayerCurrencyCheckReply.fromObject = function fromObject(object) {
            if (object instanceof $root.base.PlayerCurrencyCheckReply)
                return object;
            var message = new $root.base.PlayerCurrencyCheckReply();
            if (object.currency) {
                if (!Array.isArray(object.currency))
                    throw TypeError(".base.PlayerCurrencyCheckReply.currency: array expected");
                message.currency = [];
                for (var i = 0; i < object.currency.length; ++i)
                    message.currency[i] = object.currency[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a PlayerCurrencyCheckReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof base.PlayerCurrencyCheckReply
         * @static
         * @param {base.PlayerCurrencyCheckReply} message PlayerCurrencyCheckReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerCurrencyCheckReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.currency = [];
            if (message.currency && message.currency.length) {
                object.currency = [];
                for (var j = 0; j < message.currency.length; ++j)
                    object.currency[j] = message.currency[j];
            }
            return object;
        };

        /**
         * Converts this PlayerCurrencyCheckReply to JSON.
         * @function toJSON
         * @memberof base.PlayerCurrencyCheckReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerCurrencyCheckReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerCurrencyCheckReply;
    })();

    return base;
})();

$root.wheel = (function() {

    /**
     * Namespace wheel.
     * @exports wheel
     * @namespace
     */
    var wheel = {};

    wheel.WheelStateReply = (function() {

        /**
         * Properties of a WheelStateReply.
         * @memberof wheel
         * @interface IWheelStateReply
         * @property {string|null} [params] WheelStateReply params
         * @property {number|null} [leftTimes] WheelStateReply leftTimes
         * @property {number|null} [leftSeconds] WheelStateReply leftSeconds
         */

        /**
         * Constructs a new WheelStateReply.
         * @memberof wheel
         * @classdesc Represents a WheelStateReply.
         * @implements IWheelStateReply
         * @constructor
         * @param {wheel.IWheelStateReply=} [properties] Properties to set
         */
        function WheelStateReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WheelStateReply params.
         * @member {string} params
         * @memberof wheel.WheelStateReply
         * @instance
         */
        WheelStateReply.prototype.params = "";

        /**
         * WheelStateReply leftTimes.
         * @member {number} leftTimes
         * @memberof wheel.WheelStateReply
         * @instance
         */
        WheelStateReply.prototype.leftTimes = 0;

        /**
         * WheelStateReply leftSeconds.
         * @member {number} leftSeconds
         * @memberof wheel.WheelStateReply
         * @instance
         */
        WheelStateReply.prototype.leftSeconds = 0;

        /**
         * Creates a new WheelStateReply instance using the specified properties.
         * @function create
         * @memberof wheel.WheelStateReply
         * @static
         * @param {wheel.IWheelStateReply=} [properties] Properties to set
         * @returns {wheel.WheelStateReply} WheelStateReply instance
         */
        WheelStateReply.create = function create(properties) {
            return new WheelStateReply(properties);
        };

        /**
         * Encodes the specified WheelStateReply message. Does not implicitly {@link wheel.WheelStateReply.verify|verify} messages.
         * @function encode
         * @memberof wheel.WheelStateReply
         * @static
         * @param {wheel.IWheelStateReply} message WheelStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WheelStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.leftTimes != null && Object.hasOwnProperty.call(message, "leftTimes"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.leftTimes);
            if (message.leftSeconds != null && Object.hasOwnProperty.call(message, "leftSeconds"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.leftSeconds);
            return writer;
        };

        /**
         * Encodes the specified WheelStateReply message, length delimited. Does not implicitly {@link wheel.WheelStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wheel.WheelStateReply
         * @static
         * @param {wheel.IWheelStateReply} message WheelStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WheelStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WheelStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof wheel.WheelStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wheel.WheelStateReply} WheelStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WheelStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.wheel.WheelStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 2:
                    message.leftTimes = reader.int32();
                    break;
                case 3:
                    message.leftSeconds = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WheelStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wheel.WheelStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wheel.WheelStateReply} WheelStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WheelStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WheelStateReply message.
         * @function verify
         * @memberof wheel.WheelStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WheelStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.leftTimes != null && message.hasOwnProperty("leftTimes"))
                if (!$util.isInteger(message.leftTimes))
                    return "leftTimes: integer expected";
            if (message.leftSeconds != null && message.hasOwnProperty("leftSeconds"))
                if (!$util.isInteger(message.leftSeconds))
                    return "leftSeconds: integer expected";
            return null;
        };

        /**
         * Creates a WheelStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wheel.WheelStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wheel.WheelStateReply} WheelStateReply
         */
        WheelStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.wheel.WheelStateReply)
                return object;
            var message = new $root.wheel.WheelStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.leftTimes != null)
                message.leftTimes = object.leftTimes | 0;
            if (object.leftSeconds != null)
                message.leftSeconds = object.leftSeconds | 0;
            return message;
        };

        /**
         * Creates a plain object from a WheelStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wheel.WheelStateReply
         * @static
         * @param {wheel.WheelStateReply} message WheelStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WheelStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.params = "";
                object.leftTimes = 0;
                object.leftSeconds = 0;
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.leftTimes != null && message.hasOwnProperty("leftTimes"))
                object.leftTimes = message.leftTimes;
            if (message.leftSeconds != null && message.hasOwnProperty("leftSeconds"))
                object.leftSeconds = message.leftSeconds;
            return object;
        };

        /**
         * Converts this WheelStateReply to JSON.
         * @function toJSON
         * @memberof wheel.WheelStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WheelStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WheelStateReply;
    })();

    wheel.WheelResultReply = (function() {

        /**
         * Properties of a WheelResultReply.
         * @memberof wheel
         * @interface IWheelResultReply
         * @property {number|null} [awardIdx] WheelResultReply awardIdx
         * @property {number|null} [currencyType] WheelResultReply currencyType
         * @property {number|Long|null} [currencyAmount] WheelResultReply currencyAmount
         * @property {number|null} [leftTimes] WheelResultReply leftTimes
         * @property {number|null} [leftSeconds] WheelResultReply leftSeconds
         * @property {number|null} [isFirst] WheelResultReply isFirst
         */

        /**
         * Constructs a new WheelResultReply.
         * @memberof wheel
         * @classdesc Represents a WheelResultReply.
         * @implements IWheelResultReply
         * @constructor
         * @param {wheel.IWheelResultReply=} [properties] Properties to set
         */
        function WheelResultReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WheelResultReply awardIdx.
         * @member {number} awardIdx
         * @memberof wheel.WheelResultReply
         * @instance
         */
        WheelResultReply.prototype.awardIdx = 0;

        /**
         * WheelResultReply currencyType.
         * @member {number} currencyType
         * @memberof wheel.WheelResultReply
         * @instance
         */
        WheelResultReply.prototype.currencyType = 0;

        /**
         * WheelResultReply currencyAmount.
         * @member {number|Long} currencyAmount
         * @memberof wheel.WheelResultReply
         * @instance
         */
        WheelResultReply.prototype.currencyAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WheelResultReply leftTimes.
         * @member {number} leftTimes
         * @memberof wheel.WheelResultReply
         * @instance
         */
        WheelResultReply.prototype.leftTimes = 0;

        /**
         * WheelResultReply leftSeconds.
         * @member {number} leftSeconds
         * @memberof wheel.WheelResultReply
         * @instance
         */
        WheelResultReply.prototype.leftSeconds = 0;

        /**
         * WheelResultReply isFirst.
         * @member {number} isFirst
         * @memberof wheel.WheelResultReply
         * @instance
         */
        WheelResultReply.prototype.isFirst = 0;

        /**
         * Creates a new WheelResultReply instance using the specified properties.
         * @function create
         * @memberof wheel.WheelResultReply
         * @static
         * @param {wheel.IWheelResultReply=} [properties] Properties to set
         * @returns {wheel.WheelResultReply} WheelResultReply instance
         */
        WheelResultReply.create = function create(properties) {
            return new WheelResultReply(properties);
        };

        /**
         * Encodes the specified WheelResultReply message. Does not implicitly {@link wheel.WheelResultReply.verify|verify} messages.
         * @function encode
         * @memberof wheel.WheelResultReply
         * @static
         * @param {wheel.IWheelResultReply} message WheelResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WheelResultReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.awardIdx != null && Object.hasOwnProperty.call(message, "awardIdx"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.awardIdx);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.currencyAmount != null && Object.hasOwnProperty.call(message, "currencyAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.currencyAmount);
            if (message.leftTimes != null && Object.hasOwnProperty.call(message, "leftTimes"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.leftTimes);
            if (message.leftSeconds != null && Object.hasOwnProperty.call(message, "leftSeconds"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.leftSeconds);
            if (message.isFirst != null && Object.hasOwnProperty.call(message, "isFirst"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.isFirst);
            return writer;
        };

        /**
         * Encodes the specified WheelResultReply message, length delimited. Does not implicitly {@link wheel.WheelResultReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wheel.WheelResultReply
         * @static
         * @param {wheel.IWheelResultReply} message WheelResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WheelResultReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WheelResultReply message from the specified reader or buffer.
         * @function decode
         * @memberof wheel.WheelResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wheel.WheelResultReply} WheelResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WheelResultReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.wheel.WheelResultReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.awardIdx = reader.int32();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.currencyAmount = reader.int64();
                    break;
                case 4:
                    message.leftTimes = reader.int32();
                    break;
                case 5:
                    message.leftSeconds = reader.int32();
                    break;
                case 6:
                    message.isFirst = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WheelResultReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wheel.WheelResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wheel.WheelResultReply} WheelResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WheelResultReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WheelResultReply message.
         * @function verify
         * @memberof wheel.WheelResultReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WheelResultReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.awardIdx != null && message.hasOwnProperty("awardIdx"))
                if (!$util.isInteger(message.awardIdx))
                    return "awardIdx: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.currencyAmount != null && message.hasOwnProperty("currencyAmount"))
                if (!$util.isInteger(message.currencyAmount) && !(message.currencyAmount && $util.isInteger(message.currencyAmount.low) && $util.isInteger(message.currencyAmount.high)))
                    return "currencyAmount: integer|Long expected";
            if (message.leftTimes != null && message.hasOwnProperty("leftTimes"))
                if (!$util.isInteger(message.leftTimes))
                    return "leftTimes: integer expected";
            if (message.leftSeconds != null && message.hasOwnProperty("leftSeconds"))
                if (!$util.isInteger(message.leftSeconds))
                    return "leftSeconds: integer expected";
            if (message.isFirst != null && message.hasOwnProperty("isFirst"))
                if (!$util.isInteger(message.isFirst))
                    return "isFirst: integer expected";
            return null;
        };

        /**
         * Creates a WheelResultReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wheel.WheelResultReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wheel.WheelResultReply} WheelResultReply
         */
        WheelResultReply.fromObject = function fromObject(object) {
            if (object instanceof $root.wheel.WheelResultReply)
                return object;
            var message = new $root.wheel.WheelResultReply();
            if (object.awardIdx != null)
                message.awardIdx = object.awardIdx | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.currencyAmount != null)
                if ($util.Long)
                    (message.currencyAmount = $util.Long.fromValue(object.currencyAmount)).unsigned = false;
                else if (typeof object.currencyAmount === "string")
                    message.currencyAmount = parseInt(object.currencyAmount, 10);
                else if (typeof object.currencyAmount === "number")
                    message.currencyAmount = object.currencyAmount;
                else if (typeof object.currencyAmount === "object")
                    message.currencyAmount = new $util.LongBits(object.currencyAmount.low >>> 0, object.currencyAmount.high >>> 0).toNumber();
            if (object.leftTimes != null)
                message.leftTimes = object.leftTimes | 0;
            if (object.leftSeconds != null)
                message.leftSeconds = object.leftSeconds | 0;
            if (object.isFirst != null)
                message.isFirst = object.isFirst | 0;
            return message;
        };

        /**
         * Creates a plain object from a WheelResultReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wheel.WheelResultReply
         * @static
         * @param {wheel.WheelResultReply} message WheelResultReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WheelResultReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.awardIdx = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.currencyAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.currencyAmount = options.longs === String ? "0" : 0;
                object.leftTimes = 0;
                object.leftSeconds = 0;
                object.isFirst = 0;
            }
            if (message.awardIdx != null && message.hasOwnProperty("awardIdx"))
                object.awardIdx = message.awardIdx;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.currencyAmount != null && message.hasOwnProperty("currencyAmount"))
                if (typeof message.currencyAmount === "number")
                    object.currencyAmount = options.longs === String ? String(message.currencyAmount) : message.currencyAmount;
                else
                    object.currencyAmount = options.longs === String ? $util.Long.prototype.toString.call(message.currencyAmount) : options.longs === Number ? new $util.LongBits(message.currencyAmount.low >>> 0, message.currencyAmount.high >>> 0).toNumber() : message.currencyAmount;
            if (message.leftTimes != null && message.hasOwnProperty("leftTimes"))
                object.leftTimes = message.leftTimes;
            if (message.leftSeconds != null && message.hasOwnProperty("leftSeconds"))
                object.leftSeconds = message.leftSeconds;
            if (message.isFirst != null && message.hasOwnProperty("isFirst"))
                object.isFirst = message.isFirst;
            return object;
        };

        /**
         * Converts this WheelResultReply to JSON.
         * @function toJSON
         * @memberof wheel.WheelResultReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WheelResultReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WheelResultReply;
    })();

    return wheel;
})();

$root.ring = (function() {

    /**
     * Namespace ring.
     * @exports ring
     * @namespace
     */
    var ring = {};

    ring.RingStateReply = (function() {

        /**
         * Properties of a RingStateReply.
         * @memberof ring
         * @interface IRingStateReply
         * @property {string|null} [config] RingStateReply config
         * @property {string|null} [params] RingStateReply params
         * @property {Array.<ring.IHistoryResult>|null} [results] RingStateReply results
         * @property {Array.<string>|null} [fairness] RingStateReply fairness
         */

        /**
         * Constructs a new RingStateReply.
         * @memberof ring
         * @classdesc Represents a RingStateReply.
         * @implements IRingStateReply
         * @constructor
         * @param {ring.IRingStateReply=} [properties] Properties to set
         */
        function RingStateReply(properties) {
            this.results = [];
            this.fairness = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RingStateReply config.
         * @member {string} config
         * @memberof ring.RingStateReply
         * @instance
         */
        RingStateReply.prototype.config = "";

        /**
         * RingStateReply params.
         * @member {string} params
         * @memberof ring.RingStateReply
         * @instance
         */
        RingStateReply.prototype.params = "";

        /**
         * RingStateReply results.
         * @member {Array.<ring.IHistoryResult>} results
         * @memberof ring.RingStateReply
         * @instance
         */
        RingStateReply.prototype.results = $util.emptyArray;

        /**
         * RingStateReply fairness.
         * @member {Array.<string>} fairness
         * @memberof ring.RingStateReply
         * @instance
         */
        RingStateReply.prototype.fairness = $util.emptyArray;

        /**
         * Creates a new RingStateReply instance using the specified properties.
         * @function create
         * @memberof ring.RingStateReply
         * @static
         * @param {ring.IRingStateReply=} [properties] Properties to set
         * @returns {ring.RingStateReply} RingStateReply instance
         */
        RingStateReply.create = function create(properties) {
            return new RingStateReply(properties);
        };

        /**
         * Encodes the specified RingStateReply message. Does not implicitly {@link ring.RingStateReply.verify|verify} messages.
         * @function encode
         * @memberof ring.RingStateReply
         * @static
         * @param {ring.IRingStateReply} message RingStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RingStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.config);
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.params);
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.ring.HistoryResult.encode(message.results[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.fairness != null && message.fairness.length)
                for (var i = 0; i < message.fairness.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.fairness[i]);
            return writer;
        };

        /**
         * Encodes the specified RingStateReply message, length delimited. Does not implicitly {@link ring.RingStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ring.RingStateReply
         * @static
         * @param {ring.IRingStateReply} message RingStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RingStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RingStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof ring.RingStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ring.RingStateReply} RingStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RingStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ring.RingStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.config = reader.string();
                    break;
                case 2:
                    message.params = reader.string();
                    break;
                case 3:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.ring.HistoryResult.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.fairness && message.fairness.length))
                        message.fairness = [];
                    message.fairness.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RingStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ring.RingStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ring.RingStateReply} RingStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RingStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RingStateReply message.
         * @function verify
         * @memberof ring.RingStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RingStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.config != null && message.hasOwnProperty("config"))
                if (!$util.isString(message.config))
                    return "config: string expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.ring.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            if (message.fairness != null && message.hasOwnProperty("fairness")) {
                if (!Array.isArray(message.fairness))
                    return "fairness: array expected";
                for (var i = 0; i < message.fairness.length; ++i)
                    if (!$util.isString(message.fairness[i]))
                        return "fairness: string[] expected";
            }
            return null;
        };

        /**
         * Creates a RingStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ring.RingStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ring.RingStateReply} RingStateReply
         */
        RingStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.ring.RingStateReply)
                return object;
            var message = new $root.ring.RingStateReply();
            if (object.config != null)
                message.config = String(object.config);
            if (object.params != null)
                message.params = String(object.params);
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".ring.RingStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".ring.RingStateReply.results: object expected");
                    message.results[i] = $root.ring.HistoryResult.fromObject(object.results[i]);
                }
            }
            if (object.fairness) {
                if (!Array.isArray(object.fairness))
                    throw TypeError(".ring.RingStateReply.fairness: array expected");
                message.fairness = [];
                for (var i = 0; i < object.fairness.length; ++i)
                    message.fairness[i] = String(object.fairness[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a RingStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ring.RingStateReply
         * @static
         * @param {ring.RingStateReply} message RingStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RingStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.results = [];
                object.fairness = [];
            }
            if (options.defaults) {
                object.config = "";
                object.params = "";
            }
            if (message.config != null && message.hasOwnProperty("config"))
                object.config = message.config;
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.ring.HistoryResult.toObject(message.results[j], options);
            }
            if (message.fairness && message.fairness.length) {
                object.fairness = [];
                for (var j = 0; j < message.fairness.length; ++j)
                    object.fairness[j] = message.fairness[j];
            }
            return object;
        };

        /**
         * Converts this RingStateReply to JSON.
         * @function toJSON
         * @memberof ring.RingStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RingStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RingStateReply;
    })();

    ring.RingResultReq = (function() {

        /**
         * Properties of a RingResultReq.
         * @memberof ring
         * @interface IRingResultReq
         * @property {number|null} [currencyType] RingResultReq currencyType
         * @property {number|Long|null} [betAmount] RingResultReq betAmount
         * @property {number|null} [risk] RingResultReq risk
         * @property {number|null} [segments] RingResultReq segments
         */

        /**
         * Constructs a new RingResultReq.
         * @memberof ring
         * @classdesc Represents a RingResultReq.
         * @implements IRingResultReq
         * @constructor
         * @param {ring.IRingResultReq=} [properties] Properties to set
         */
        function RingResultReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RingResultReq currencyType.
         * @member {number} currencyType
         * @memberof ring.RingResultReq
         * @instance
         */
        RingResultReq.prototype.currencyType = 0;

        /**
         * RingResultReq betAmount.
         * @member {number|Long} betAmount
         * @memberof ring.RingResultReq
         * @instance
         */
        RingResultReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RingResultReq risk.
         * @member {number} risk
         * @memberof ring.RingResultReq
         * @instance
         */
        RingResultReq.prototype.risk = 0;

        /**
         * RingResultReq segments.
         * @member {number} segments
         * @memberof ring.RingResultReq
         * @instance
         */
        RingResultReq.prototype.segments = 0;

        /**
         * Creates a new RingResultReq instance using the specified properties.
         * @function create
         * @memberof ring.RingResultReq
         * @static
         * @param {ring.IRingResultReq=} [properties] Properties to set
         * @returns {ring.RingResultReq} RingResultReq instance
         */
        RingResultReq.create = function create(properties) {
            return new RingResultReq(properties);
        };

        /**
         * Encodes the specified RingResultReq message. Does not implicitly {@link ring.RingResultReq.verify|verify} messages.
         * @function encode
         * @memberof ring.RingResultReq
         * @static
         * @param {ring.IRingResultReq} message RingResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RingResultReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            if (message.risk != null && Object.hasOwnProperty.call(message, "risk"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.risk);
            if (message.segments != null && Object.hasOwnProperty.call(message, "segments"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.segments);
            return writer;
        };

        /**
         * Encodes the specified RingResultReq message, length delimited. Does not implicitly {@link ring.RingResultReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ring.RingResultReq
         * @static
         * @param {ring.IRingResultReq} message RingResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RingResultReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RingResultReq message from the specified reader or buffer.
         * @function decode
         * @memberof ring.RingResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ring.RingResultReq} RingResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RingResultReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ring.RingResultReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                case 3:
                    message.risk = reader.int32();
                    break;
                case 4:
                    message.segments = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RingResultReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ring.RingResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ring.RingResultReq} RingResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RingResultReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RingResultReq message.
         * @function verify
         * @memberof ring.RingResultReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RingResultReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.risk != null && message.hasOwnProperty("risk"))
                if (!$util.isInteger(message.risk))
                    return "risk: integer expected";
            if (message.segments != null && message.hasOwnProperty("segments"))
                if (!$util.isInteger(message.segments))
                    return "segments: integer expected";
            return null;
        };

        /**
         * Creates a RingResultReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ring.RingResultReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ring.RingResultReq} RingResultReq
         */
        RingResultReq.fromObject = function fromObject(object) {
            if (object instanceof $root.ring.RingResultReq)
                return object;
            var message = new $root.ring.RingResultReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.risk != null)
                message.risk = object.risk | 0;
            if (object.segments != null)
                message.segments = object.segments | 0;
            return message;
        };

        /**
         * Creates a plain object from a RingResultReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ring.RingResultReq
         * @static
         * @param {ring.RingResultReq} message RingResultReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RingResultReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.risk = 0;
                object.segments = 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.risk != null && message.hasOwnProperty("risk"))
                object.risk = message.risk;
            if (message.segments != null && message.hasOwnProperty("segments"))
                object.segments = message.segments;
            return object;
        };

        /**
         * Converts this RingResultReq to JSON.
         * @function toJSON
         * @memberof ring.RingResultReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RingResultReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RingResultReq;
    })();

    ring.RingResultReply = (function() {

        /**
         * Properties of a RingResultReply.
         * @memberof ring
         * @interface IRingResultReply
         * @property {number|null} [blockIdx] RingResultReply blockIdx
         * @property {number|null} [currencyType] RingResultReply currencyType
         * @property {number|Long|null} [winAmount] RingResultReply winAmount
         * @property {number|null} [risk] RingResultReply risk
         * @property {number|null} [segments] RingResultReply segments
         * @property {number|Long|null} [betAmount] RingResultReply betAmount
         * @property {string|null} [gameCode] RingResultReply gameCode
         * @property {Array.<string>|null} [fairness] RingResultReply fairness
         */

        /**
         * Constructs a new RingResultReply.
         * @memberof ring
         * @classdesc Represents a RingResultReply.
         * @implements IRingResultReply
         * @constructor
         * @param {ring.IRingResultReply=} [properties] Properties to set
         */
        function RingResultReply(properties) {
            this.fairness = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RingResultReply blockIdx.
         * @member {number} blockIdx
         * @memberof ring.RingResultReply
         * @instance
         */
        RingResultReply.prototype.blockIdx = 0;

        /**
         * RingResultReply currencyType.
         * @member {number} currencyType
         * @memberof ring.RingResultReply
         * @instance
         */
        RingResultReply.prototype.currencyType = 0;

        /**
         * RingResultReply winAmount.
         * @member {number|Long} winAmount
         * @memberof ring.RingResultReply
         * @instance
         */
        RingResultReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RingResultReply risk.
         * @member {number} risk
         * @memberof ring.RingResultReply
         * @instance
         */
        RingResultReply.prototype.risk = 0;

        /**
         * RingResultReply segments.
         * @member {number} segments
         * @memberof ring.RingResultReply
         * @instance
         */
        RingResultReply.prototype.segments = 0;

        /**
         * RingResultReply betAmount.
         * @member {number|Long} betAmount
         * @memberof ring.RingResultReply
         * @instance
         */
        RingResultReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RingResultReply gameCode.
         * @member {string} gameCode
         * @memberof ring.RingResultReply
         * @instance
         */
        RingResultReply.prototype.gameCode = "";

        /**
         * RingResultReply fairness.
         * @member {Array.<string>} fairness
         * @memberof ring.RingResultReply
         * @instance
         */
        RingResultReply.prototype.fairness = $util.emptyArray;

        /**
         * Creates a new RingResultReply instance using the specified properties.
         * @function create
         * @memberof ring.RingResultReply
         * @static
         * @param {ring.IRingResultReply=} [properties] Properties to set
         * @returns {ring.RingResultReply} RingResultReply instance
         */
        RingResultReply.create = function create(properties) {
            return new RingResultReply(properties);
        };

        /**
         * Encodes the specified RingResultReply message. Does not implicitly {@link ring.RingResultReply.verify|verify} messages.
         * @function encode
         * @memberof ring.RingResultReply
         * @static
         * @param {ring.IRingResultReply} message RingResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RingResultReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.blockIdx != null && Object.hasOwnProperty.call(message, "blockIdx"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.blockIdx);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.winAmount);
            if (message.risk != null && Object.hasOwnProperty.call(message, "risk"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.risk);
            if (message.segments != null && Object.hasOwnProperty.call(message, "segments"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.segments);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.betAmount);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.gameCode);
            if (message.fairness != null && message.fairness.length)
                for (var i = 0; i < message.fairness.length; ++i)
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.fairness[i]);
            return writer;
        };

        /**
         * Encodes the specified RingResultReply message, length delimited. Does not implicitly {@link ring.RingResultReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ring.RingResultReply
         * @static
         * @param {ring.IRingResultReply} message RingResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RingResultReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RingResultReply message from the specified reader or buffer.
         * @function decode
         * @memberof ring.RingResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ring.RingResultReply} RingResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RingResultReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ring.RingResultReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.blockIdx = reader.int32();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.winAmount = reader.int64();
                    break;
                case 4:
                    message.risk = reader.int32();
                    break;
                case 5:
                    message.segments = reader.int32();
                    break;
                case 6:
                    message.betAmount = reader.int64();
                    break;
                case 7:
                    message.gameCode = reader.string();
                    break;
                case 8:
                    if (!(message.fairness && message.fairness.length))
                        message.fairness = [];
                    message.fairness.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RingResultReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ring.RingResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ring.RingResultReply} RingResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RingResultReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RingResultReply message.
         * @function verify
         * @memberof ring.RingResultReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RingResultReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.blockIdx != null && message.hasOwnProperty("blockIdx"))
                if (!$util.isInteger(message.blockIdx))
                    return "blockIdx: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.risk != null && message.hasOwnProperty("risk"))
                if (!$util.isInteger(message.risk))
                    return "risk: integer expected";
            if (message.segments != null && message.hasOwnProperty("segments"))
                if (!$util.isInteger(message.segments))
                    return "segments: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.fairness != null && message.hasOwnProperty("fairness")) {
                if (!Array.isArray(message.fairness))
                    return "fairness: array expected";
                for (var i = 0; i < message.fairness.length; ++i)
                    if (!$util.isString(message.fairness[i]))
                        return "fairness: string[] expected";
            }
            return null;
        };

        /**
         * Creates a RingResultReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ring.RingResultReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ring.RingResultReply} RingResultReply
         */
        RingResultReply.fromObject = function fromObject(object) {
            if (object instanceof $root.ring.RingResultReply)
                return object;
            var message = new $root.ring.RingResultReply();
            if (object.blockIdx != null)
                message.blockIdx = object.blockIdx | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.risk != null)
                message.risk = object.risk | 0;
            if (object.segments != null)
                message.segments = object.segments | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.fairness) {
                if (!Array.isArray(object.fairness))
                    throw TypeError(".ring.RingResultReply.fairness: array expected");
                message.fairness = [];
                for (var i = 0; i < object.fairness.length; ++i)
                    message.fairness[i] = String(object.fairness[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a RingResultReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ring.RingResultReply
         * @static
         * @param {ring.RingResultReply} message RingResultReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RingResultReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.fairness = [];
            if (options.defaults) {
                object.blockIdx = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                object.risk = 0;
                object.segments = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.gameCode = "";
            }
            if (message.blockIdx != null && message.hasOwnProperty("blockIdx"))
                object.blockIdx = message.blockIdx;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.risk != null && message.hasOwnProperty("risk"))
                object.risk = message.risk;
            if (message.segments != null && message.hasOwnProperty("segments"))
                object.segments = message.segments;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.fairness && message.fairness.length) {
                object.fairness = [];
                for (var j = 0; j < message.fairness.length; ++j)
                    object.fairness[j] = message.fairness[j];
            }
            return object;
        };

        /**
         * Converts this RingResultReply to JSON.
         * @function toJSON
         * @memberof ring.RingResultReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RingResultReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RingResultReply;
    })();

    ring.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof ring
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof ring
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {ring.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof ring.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof ring.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof ring.HistoryResult
         * @static
         * @param {ring.IHistoryResult=} [properties] Properties to set
         * @returns {ring.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link ring.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof ring.HistoryResult
         * @static
         * @param {ring.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link ring.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ring.HistoryResult
         * @static
         * @param {ring.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof ring.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ring.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ring.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ring.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ring.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof ring.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ring.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ring.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.ring.HistoryResult)
                return object;
            var message = new $root.ring.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ring.HistoryResult
         * @static
         * @param {ring.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof ring.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return ring;
})();

$root.plinko = (function() {

    /**
     * Namespace plinko.
     * @exports plinko
     * @namespace
     */
    var plinko = {};

    plinko.PlinkoStateReply = (function() {

        /**
         * Properties of a PlinkoStateReply.
         * @memberof plinko
         * @interface IPlinkoStateReply
         * @property {string|null} [params] PlinkoStateReply params
         * @property {Array.<plinko.IHistoryResult>|null} [results] PlinkoStateReply results
         * @property {string|null} [fairness] PlinkoStateReply fairness
         * @property {string|null} [config] PlinkoStateReply config
         */

        /**
         * Constructs a new PlinkoStateReply.
         * @memberof plinko
         * @classdesc Represents a PlinkoStateReply.
         * @implements IPlinkoStateReply
         * @constructor
         * @param {plinko.IPlinkoStateReply=} [properties] Properties to set
         */
        function PlinkoStateReply(properties) {
            this.results = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlinkoStateReply params.
         * @member {string} params
         * @memberof plinko.PlinkoStateReply
         * @instance
         */
        PlinkoStateReply.prototype.params = "";

        /**
         * PlinkoStateReply results.
         * @member {Array.<plinko.IHistoryResult>} results
         * @memberof plinko.PlinkoStateReply
         * @instance
         */
        PlinkoStateReply.prototype.results = $util.emptyArray;

        /**
         * PlinkoStateReply fairness.
         * @member {string} fairness
         * @memberof plinko.PlinkoStateReply
         * @instance
         */
        PlinkoStateReply.prototype.fairness = "";

        /**
         * PlinkoStateReply config.
         * @member {string} config
         * @memberof plinko.PlinkoStateReply
         * @instance
         */
        PlinkoStateReply.prototype.config = "";

        /**
         * Creates a new PlinkoStateReply instance using the specified properties.
         * @function create
         * @memberof plinko.PlinkoStateReply
         * @static
         * @param {plinko.IPlinkoStateReply=} [properties] Properties to set
         * @returns {plinko.PlinkoStateReply} PlinkoStateReply instance
         */
        PlinkoStateReply.create = function create(properties) {
            return new PlinkoStateReply(properties);
        };

        /**
         * Encodes the specified PlinkoStateReply message. Does not implicitly {@link plinko.PlinkoStateReply.verify|verify} messages.
         * @function encode
         * @memberof plinko.PlinkoStateReply
         * @static
         * @param {plinko.IPlinkoStateReply} message PlinkoStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlinkoStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.plinko.HistoryResult.encode(message.results[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fairness);
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.config);
            return writer;
        };

        /**
         * Encodes the specified PlinkoStateReply message, length delimited. Does not implicitly {@link plinko.PlinkoStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof plinko.PlinkoStateReply
         * @static
         * @param {plinko.IPlinkoStateReply} message PlinkoStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlinkoStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlinkoStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof plinko.PlinkoStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {plinko.PlinkoStateReply} PlinkoStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlinkoStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.plinko.PlinkoStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 2:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.plinko.HistoryResult.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.fairness = reader.string();
                    break;
                case 4:
                    message.config = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlinkoStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof plinko.PlinkoStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {plinko.PlinkoStateReply} PlinkoStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlinkoStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlinkoStateReply message.
         * @function verify
         * @memberof plinko.PlinkoStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlinkoStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.plinko.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            if (message.config != null && message.hasOwnProperty("config"))
                if (!$util.isString(message.config))
                    return "config: string expected";
            return null;
        };

        /**
         * Creates a PlinkoStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof plinko.PlinkoStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {plinko.PlinkoStateReply} PlinkoStateReply
         */
        PlinkoStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.plinko.PlinkoStateReply)
                return object;
            var message = new $root.plinko.PlinkoStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".plinko.PlinkoStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".plinko.PlinkoStateReply.results: object expected");
                    message.results[i] = $root.plinko.HistoryResult.fromObject(object.results[i]);
                }
            }
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            if (object.config != null)
                message.config = String(object.config);
            return message;
        };

        /**
         * Creates a plain object from a PlinkoStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof plinko.PlinkoStateReply
         * @static
         * @param {plinko.PlinkoStateReply} message PlinkoStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlinkoStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.results = [];
            if (options.defaults) {
                object.params = "";
                object.fairness = "";
                object.config = "";
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.plinko.HistoryResult.toObject(message.results[j], options);
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            if (message.config != null && message.hasOwnProperty("config"))
                object.config = message.config;
            return object;
        };

        /**
         * Converts this PlinkoStateReply to JSON.
         * @function toJSON
         * @memberof plinko.PlinkoStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlinkoStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlinkoStateReply;
    })();

    plinko.PlinkoResultReq = (function() {

        /**
         * Properties of a PlinkoResultReq.
         * @memberof plinko
         * @interface IPlinkoResultReq
         * @property {number|Long|null} [betAmount] PlinkoResultReq betAmount
         * @property {number|null} [currencyType] PlinkoResultReq currencyType
         * @property {number|null} [risk] PlinkoResultReq risk
         * @property {number|null} [row] PlinkoResultReq row
         * @property {number|null} [delay] PlinkoResultReq delay
         */

        /**
         * Constructs a new PlinkoResultReq.
         * @memberof plinko
         * @classdesc Represents a PlinkoResultReq.
         * @implements IPlinkoResultReq
         * @constructor
         * @param {plinko.IPlinkoResultReq=} [properties] Properties to set
         */
        function PlinkoResultReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlinkoResultReq betAmount.
         * @member {number|Long} betAmount
         * @memberof plinko.PlinkoResultReq
         * @instance
         */
        PlinkoResultReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlinkoResultReq currencyType.
         * @member {number} currencyType
         * @memberof plinko.PlinkoResultReq
         * @instance
         */
        PlinkoResultReq.prototype.currencyType = 0;

        /**
         * PlinkoResultReq risk.
         * @member {number} risk
         * @memberof plinko.PlinkoResultReq
         * @instance
         */
        PlinkoResultReq.prototype.risk = 0;

        /**
         * PlinkoResultReq row.
         * @member {number} row
         * @memberof plinko.PlinkoResultReq
         * @instance
         */
        PlinkoResultReq.prototype.row = 0;

        /**
         * PlinkoResultReq delay.
         * @member {number} delay
         * @memberof plinko.PlinkoResultReq
         * @instance
         */
        PlinkoResultReq.prototype.delay = 0;

        /**
         * Creates a new PlinkoResultReq instance using the specified properties.
         * @function create
         * @memberof plinko.PlinkoResultReq
         * @static
         * @param {plinko.IPlinkoResultReq=} [properties] Properties to set
         * @returns {plinko.PlinkoResultReq} PlinkoResultReq instance
         */
        PlinkoResultReq.create = function create(properties) {
            return new PlinkoResultReq(properties);
        };

        /**
         * Encodes the specified PlinkoResultReq message. Does not implicitly {@link plinko.PlinkoResultReq.verify|verify} messages.
         * @function encode
         * @memberof plinko.PlinkoResultReq
         * @static
         * @param {plinko.IPlinkoResultReq} message PlinkoResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlinkoResultReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.betAmount);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.risk != null && Object.hasOwnProperty.call(message, "risk"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.risk);
            if (message.row != null && Object.hasOwnProperty.call(message, "row"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.row);
            if (message.delay != null && Object.hasOwnProperty.call(message, "delay"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.delay);
            return writer;
        };

        /**
         * Encodes the specified PlinkoResultReq message, length delimited. Does not implicitly {@link plinko.PlinkoResultReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof plinko.PlinkoResultReq
         * @static
         * @param {plinko.IPlinkoResultReq} message PlinkoResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlinkoResultReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlinkoResultReq message from the specified reader or buffer.
         * @function decode
         * @memberof plinko.PlinkoResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {plinko.PlinkoResultReq} PlinkoResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlinkoResultReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.plinko.PlinkoResultReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.betAmount = reader.int64();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.risk = reader.int32();
                    break;
                case 4:
                    message.row = reader.int32();
                    break;
                case 5:
                    message.delay = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlinkoResultReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof plinko.PlinkoResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {plinko.PlinkoResultReq} PlinkoResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlinkoResultReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlinkoResultReq message.
         * @function verify
         * @memberof plinko.PlinkoResultReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlinkoResultReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.risk != null && message.hasOwnProperty("risk"))
                if (!$util.isInteger(message.risk))
                    return "risk: integer expected";
            if (message.row != null && message.hasOwnProperty("row"))
                if (!$util.isInteger(message.row))
                    return "row: integer expected";
            if (message.delay != null && message.hasOwnProperty("delay"))
                if (!$util.isInteger(message.delay))
                    return "delay: integer expected";
            return null;
        };

        /**
         * Creates a PlinkoResultReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof plinko.PlinkoResultReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {plinko.PlinkoResultReq} PlinkoResultReq
         */
        PlinkoResultReq.fromObject = function fromObject(object) {
            if (object instanceof $root.plinko.PlinkoResultReq)
                return object;
            var message = new $root.plinko.PlinkoResultReq();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.risk != null)
                message.risk = object.risk | 0;
            if (object.row != null)
                message.row = object.row | 0;
            if (object.delay != null)
                message.delay = object.delay | 0;
            return message;
        };

        /**
         * Creates a plain object from a PlinkoResultReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof plinko.PlinkoResultReq
         * @static
         * @param {plinko.PlinkoResultReq} message PlinkoResultReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlinkoResultReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.currencyType = 0;
                object.risk = 0;
                object.row = 0;
                object.delay = 0;
            }
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.risk != null && message.hasOwnProperty("risk"))
                object.risk = message.risk;
            if (message.row != null && message.hasOwnProperty("row"))
                object.row = message.row;
            if (message.delay != null && message.hasOwnProperty("delay"))
                object.delay = message.delay;
            return object;
        };

        /**
         * Converts this PlinkoResultReq to JSON.
         * @function toJSON
         * @memberof plinko.PlinkoResultReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlinkoResultReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlinkoResultReq;
    })();

    plinko.PlinkoResultReply = (function() {

        /**
         * Properties of a PlinkoResultReply.
         * @memberof plinko
         * @interface IPlinkoResultReply
         * @property {number|null} [awardIdx] PlinkoResultReply awardIdx
         * @property {number|null} [currencyType] PlinkoResultReply currencyType
         * @property {number|Long|null} [winAmount] PlinkoResultReply winAmount
         * @property {number|null} [risk] PlinkoResultReply risk
         * @property {number|null} [row] PlinkoResultReply row
         * @property {number|Long|null} [betAmount] PlinkoResultReply betAmount
         * @property {number|null} [payout] PlinkoResultReply payout
         * @property {string|null} [fairness] PlinkoResultReply fairness
         * @property {string|null} [gameCode] PlinkoResultReply gameCode
         */

        /**
         * Constructs a new PlinkoResultReply.
         * @memberof plinko
         * @classdesc Represents a PlinkoResultReply.
         * @implements IPlinkoResultReply
         * @constructor
         * @param {plinko.IPlinkoResultReply=} [properties] Properties to set
         */
        function PlinkoResultReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlinkoResultReply awardIdx.
         * @member {number} awardIdx
         * @memberof plinko.PlinkoResultReply
         * @instance
         */
        PlinkoResultReply.prototype.awardIdx = 0;

        /**
         * PlinkoResultReply currencyType.
         * @member {number} currencyType
         * @memberof plinko.PlinkoResultReply
         * @instance
         */
        PlinkoResultReply.prototype.currencyType = 0;

        /**
         * PlinkoResultReply winAmount.
         * @member {number|Long} winAmount
         * @memberof plinko.PlinkoResultReply
         * @instance
         */
        PlinkoResultReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlinkoResultReply risk.
         * @member {number} risk
         * @memberof plinko.PlinkoResultReply
         * @instance
         */
        PlinkoResultReply.prototype.risk = 0;

        /**
         * PlinkoResultReply row.
         * @member {number} row
         * @memberof plinko.PlinkoResultReply
         * @instance
         */
        PlinkoResultReply.prototype.row = 0;

        /**
         * PlinkoResultReply betAmount.
         * @member {number|Long} betAmount
         * @memberof plinko.PlinkoResultReply
         * @instance
         */
        PlinkoResultReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlinkoResultReply payout.
         * @member {number} payout
         * @memberof plinko.PlinkoResultReply
         * @instance
         */
        PlinkoResultReply.prototype.payout = 0;

        /**
         * PlinkoResultReply fairness.
         * @member {string} fairness
         * @memberof plinko.PlinkoResultReply
         * @instance
         */
        PlinkoResultReply.prototype.fairness = "";

        /**
         * PlinkoResultReply gameCode.
         * @member {string} gameCode
         * @memberof plinko.PlinkoResultReply
         * @instance
         */
        PlinkoResultReply.prototype.gameCode = "";

        /**
         * Creates a new PlinkoResultReply instance using the specified properties.
         * @function create
         * @memberof plinko.PlinkoResultReply
         * @static
         * @param {plinko.IPlinkoResultReply=} [properties] Properties to set
         * @returns {plinko.PlinkoResultReply} PlinkoResultReply instance
         */
        PlinkoResultReply.create = function create(properties) {
            return new PlinkoResultReply(properties);
        };

        /**
         * Encodes the specified PlinkoResultReply message. Does not implicitly {@link plinko.PlinkoResultReply.verify|verify} messages.
         * @function encode
         * @memberof plinko.PlinkoResultReply
         * @static
         * @param {plinko.IPlinkoResultReply} message PlinkoResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlinkoResultReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.awardIdx != null && Object.hasOwnProperty.call(message, "awardIdx"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.awardIdx);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.winAmount);
            if (message.risk != null && Object.hasOwnProperty.call(message, "risk"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.risk);
            if (message.row != null && Object.hasOwnProperty.call(message, "row"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.row);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.betAmount);
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.payout);
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.fairness);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.gameCode);
            return writer;
        };

        /**
         * Encodes the specified PlinkoResultReply message, length delimited. Does not implicitly {@link plinko.PlinkoResultReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof plinko.PlinkoResultReply
         * @static
         * @param {plinko.IPlinkoResultReply} message PlinkoResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlinkoResultReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlinkoResultReply message from the specified reader or buffer.
         * @function decode
         * @memberof plinko.PlinkoResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {plinko.PlinkoResultReply} PlinkoResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlinkoResultReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.plinko.PlinkoResultReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.awardIdx = reader.int32();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.winAmount = reader.int64();
                    break;
                case 4:
                    message.risk = reader.int32();
                    break;
                case 5:
                    message.row = reader.int32();
                    break;
                case 6:
                    message.betAmount = reader.int64();
                    break;
                case 7:
                    message.payout = reader.int32();
                    break;
                case 8:
                    message.fairness = reader.string();
                    break;
                case 9:
                    message.gameCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlinkoResultReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof plinko.PlinkoResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {plinko.PlinkoResultReply} PlinkoResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlinkoResultReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlinkoResultReply message.
         * @function verify
         * @memberof plinko.PlinkoResultReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlinkoResultReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.awardIdx != null && message.hasOwnProperty("awardIdx"))
                if (!$util.isInteger(message.awardIdx))
                    return "awardIdx: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.risk != null && message.hasOwnProperty("risk"))
                if (!$util.isInteger(message.risk))
                    return "risk: integer expected";
            if (message.row != null && message.hasOwnProperty("row"))
                if (!$util.isInteger(message.row))
                    return "row: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isInteger(message.payout))
                    return "payout: integer expected";
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            return null;
        };

        /**
         * Creates a PlinkoResultReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof plinko.PlinkoResultReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {plinko.PlinkoResultReply} PlinkoResultReply
         */
        PlinkoResultReply.fromObject = function fromObject(object) {
            if (object instanceof $root.plinko.PlinkoResultReply)
                return object;
            var message = new $root.plinko.PlinkoResultReply();
            if (object.awardIdx != null)
                message.awardIdx = object.awardIdx | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.risk != null)
                message.risk = object.risk | 0;
            if (object.row != null)
                message.row = object.row | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.payout != null)
                message.payout = object.payout | 0;
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            return message;
        };

        /**
         * Creates a plain object from a PlinkoResultReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof plinko.PlinkoResultReply
         * @static
         * @param {plinko.PlinkoResultReply} message PlinkoResultReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlinkoResultReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.awardIdx = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                object.risk = 0;
                object.row = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.payout = 0;
                object.fairness = "";
                object.gameCode = "";
            }
            if (message.awardIdx != null && message.hasOwnProperty("awardIdx"))
                object.awardIdx = message.awardIdx;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.risk != null && message.hasOwnProperty("risk"))
                object.risk = message.risk;
            if (message.row != null && message.hasOwnProperty("row"))
                object.row = message.row;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            return object;
        };

        /**
         * Converts this PlinkoResultReply to JSON.
         * @function toJSON
         * @memberof plinko.PlinkoResultReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlinkoResultReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlinkoResultReply;
    })();

    plinko.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof plinko
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof plinko
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {plinko.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof plinko.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof plinko.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof plinko.HistoryResult
         * @static
         * @param {plinko.IHistoryResult=} [properties] Properties to set
         * @returns {plinko.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link plinko.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof plinko.HistoryResult
         * @static
         * @param {plinko.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link plinko.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof plinko.HistoryResult
         * @static
         * @param {plinko.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof plinko.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {plinko.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.plinko.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof plinko.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {plinko.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof plinko.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof plinko.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {plinko.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.plinko.HistoryResult)
                return object;
            var message = new $root.plinko.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof plinko.HistoryResult
         * @static
         * @param {plinko.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof plinko.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return plinko;
})();

$root.cryptos = (function() {

    /**
     * Namespace cryptos.
     * @exports cryptos
     * @namespace
     */
    var cryptos = {};

    cryptos.CryptosStateReply = (function() {

        /**
         * Properties of a CryptosStateReply.
         * @memberof cryptos
         * @interface ICryptosStateReply
         * @property {string|null} [params] CryptosStateReply params
         * @property {Array.<cryptos.IHistoryResult>|null} [results] CryptosStateReply results
         * @property {string|null} [fairness] CryptosStateReply fairness
         */

        /**
         * Constructs a new CryptosStateReply.
         * @memberof cryptos
         * @classdesc Represents a CryptosStateReply.
         * @implements ICryptosStateReply
         * @constructor
         * @param {cryptos.ICryptosStateReply=} [properties] Properties to set
         */
        function CryptosStateReply(properties) {
            this.results = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CryptosStateReply params.
         * @member {string} params
         * @memberof cryptos.CryptosStateReply
         * @instance
         */
        CryptosStateReply.prototype.params = "";

        /**
         * CryptosStateReply results.
         * @member {Array.<cryptos.IHistoryResult>} results
         * @memberof cryptos.CryptosStateReply
         * @instance
         */
        CryptosStateReply.prototype.results = $util.emptyArray;

        /**
         * CryptosStateReply fairness.
         * @member {string} fairness
         * @memberof cryptos.CryptosStateReply
         * @instance
         */
        CryptosStateReply.prototype.fairness = "";

        /**
         * Creates a new CryptosStateReply instance using the specified properties.
         * @function create
         * @memberof cryptos.CryptosStateReply
         * @static
         * @param {cryptos.ICryptosStateReply=} [properties] Properties to set
         * @returns {cryptos.CryptosStateReply} CryptosStateReply instance
         */
        CryptosStateReply.create = function create(properties) {
            return new CryptosStateReply(properties);
        };

        /**
         * Encodes the specified CryptosStateReply message. Does not implicitly {@link cryptos.CryptosStateReply.verify|verify} messages.
         * @function encode
         * @memberof cryptos.CryptosStateReply
         * @static
         * @param {cryptos.ICryptosStateReply} message CryptosStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptosStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.cryptos.HistoryResult.encode(message.results[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified CryptosStateReply message, length delimited. Does not implicitly {@link cryptos.CryptosStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cryptos.CryptosStateReply
         * @static
         * @param {cryptos.ICryptosStateReply} message CryptosStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptosStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CryptosStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof cryptos.CryptosStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cryptos.CryptosStateReply} CryptosStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptosStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.cryptos.CryptosStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 2:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.cryptos.HistoryResult.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CryptosStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cryptos.CryptosStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cryptos.CryptosStateReply} CryptosStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptosStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CryptosStateReply message.
         * @function verify
         * @memberof cryptos.CryptosStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CryptosStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.cryptos.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a CryptosStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cryptos.CryptosStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cryptos.CryptosStateReply} CryptosStateReply
         */
        CryptosStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.cryptos.CryptosStateReply)
                return object;
            var message = new $root.cryptos.CryptosStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".cryptos.CryptosStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".cryptos.CryptosStateReply.results: object expected");
                    message.results[i] = $root.cryptos.HistoryResult.fromObject(object.results[i]);
                }
            }
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a CryptosStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cryptos.CryptosStateReply
         * @static
         * @param {cryptos.CryptosStateReply} message CryptosStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CryptosStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.results = [];
            if (options.defaults) {
                object.params = "";
                object.fairness = "";
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.cryptos.HistoryResult.toObject(message.results[j], options);
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this CryptosStateReply to JSON.
         * @function toJSON
         * @memberof cryptos.CryptosStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CryptosStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CryptosStateReply;
    })();

    cryptos.CryptosResultReq = (function() {

        /**
         * Properties of a CryptosResultReq.
         * @memberof cryptos
         * @interface ICryptosResultReq
         * @property {number|null} [currencyType] CryptosResultReq currencyType
         * @property {number|Long|null} [betAmount] CryptosResultReq betAmount
         */

        /**
         * Constructs a new CryptosResultReq.
         * @memberof cryptos
         * @classdesc Represents a CryptosResultReq.
         * @implements ICryptosResultReq
         * @constructor
         * @param {cryptos.ICryptosResultReq=} [properties] Properties to set
         */
        function CryptosResultReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CryptosResultReq currencyType.
         * @member {number} currencyType
         * @memberof cryptos.CryptosResultReq
         * @instance
         */
        CryptosResultReq.prototype.currencyType = 0;

        /**
         * CryptosResultReq betAmount.
         * @member {number|Long} betAmount
         * @memberof cryptos.CryptosResultReq
         * @instance
         */
        CryptosResultReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CryptosResultReq instance using the specified properties.
         * @function create
         * @memberof cryptos.CryptosResultReq
         * @static
         * @param {cryptos.ICryptosResultReq=} [properties] Properties to set
         * @returns {cryptos.CryptosResultReq} CryptosResultReq instance
         */
        CryptosResultReq.create = function create(properties) {
            return new CryptosResultReq(properties);
        };

        /**
         * Encodes the specified CryptosResultReq message. Does not implicitly {@link cryptos.CryptosResultReq.verify|verify} messages.
         * @function encode
         * @memberof cryptos.CryptosResultReq
         * @static
         * @param {cryptos.ICryptosResultReq} message CryptosResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptosResultReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            return writer;
        };

        /**
         * Encodes the specified CryptosResultReq message, length delimited. Does not implicitly {@link cryptos.CryptosResultReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cryptos.CryptosResultReq
         * @static
         * @param {cryptos.ICryptosResultReq} message CryptosResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptosResultReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CryptosResultReq message from the specified reader or buffer.
         * @function decode
         * @memberof cryptos.CryptosResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cryptos.CryptosResultReq} CryptosResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptosResultReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.cryptos.CryptosResultReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CryptosResultReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cryptos.CryptosResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cryptos.CryptosResultReq} CryptosResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptosResultReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CryptosResultReq message.
         * @function verify
         * @memberof cryptos.CryptosResultReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CryptosResultReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            return null;
        };

        /**
         * Creates a CryptosResultReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cryptos.CryptosResultReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cryptos.CryptosResultReq} CryptosResultReq
         */
        CryptosResultReq.fromObject = function fromObject(object) {
            if (object instanceof $root.cryptos.CryptosResultReq)
                return object;
            var message = new $root.cryptos.CryptosResultReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a CryptosResultReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cryptos.CryptosResultReq
         * @static
         * @param {cryptos.CryptosResultReq} message CryptosResultReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CryptosResultReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            return object;
        };

        /**
         * Converts this CryptosResultReq to JSON.
         * @function toJSON
         * @memberof cryptos.CryptosResultReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CryptosResultReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CryptosResultReq;
    })();

    cryptos.CryptosResultReply = (function() {

        /**
         * Properties of a CryptosResultReply.
         * @memberof cryptos
         * @interface ICryptosResultReply
         * @property {Array.<number>|null} [items] CryptosResultReply items
         * @property {number|null} [awardNum] CryptosResultReply awardNum
         * @property {number|null} [currencyType] CryptosResultReply currencyType
         * @property {number|Long|null} [winAmount] CryptosResultReply winAmount
         * @property {number|Long|null} [betAmount] CryptosResultReply betAmount
         * @property {string|null} [gameCode] CryptosResultReply gameCode
         * @property {number|null} [result] CryptosResultReply result
         * @property {string|null} [fairness] CryptosResultReply fairness
         */

        /**
         * Constructs a new CryptosResultReply.
         * @memberof cryptos
         * @classdesc Represents a CryptosResultReply.
         * @implements ICryptosResultReply
         * @constructor
         * @param {cryptos.ICryptosResultReply=} [properties] Properties to set
         */
        function CryptosResultReply(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CryptosResultReply items.
         * @member {Array.<number>} items
         * @memberof cryptos.CryptosResultReply
         * @instance
         */
        CryptosResultReply.prototype.items = $util.emptyArray;

        /**
         * CryptosResultReply awardNum.
         * @member {number} awardNum
         * @memberof cryptos.CryptosResultReply
         * @instance
         */
        CryptosResultReply.prototype.awardNum = 0;

        /**
         * CryptosResultReply currencyType.
         * @member {number} currencyType
         * @memberof cryptos.CryptosResultReply
         * @instance
         */
        CryptosResultReply.prototype.currencyType = 0;

        /**
         * CryptosResultReply winAmount.
         * @member {number|Long} winAmount
         * @memberof cryptos.CryptosResultReply
         * @instance
         */
        CryptosResultReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CryptosResultReply betAmount.
         * @member {number|Long} betAmount
         * @memberof cryptos.CryptosResultReply
         * @instance
         */
        CryptosResultReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CryptosResultReply gameCode.
         * @member {string} gameCode
         * @memberof cryptos.CryptosResultReply
         * @instance
         */
        CryptosResultReply.prototype.gameCode = "";

        /**
         * CryptosResultReply result.
         * @member {number} result
         * @memberof cryptos.CryptosResultReply
         * @instance
         */
        CryptosResultReply.prototype.result = 0;

        /**
         * CryptosResultReply fairness.
         * @member {string} fairness
         * @memberof cryptos.CryptosResultReply
         * @instance
         */
        CryptosResultReply.prototype.fairness = "";

        /**
         * Creates a new CryptosResultReply instance using the specified properties.
         * @function create
         * @memberof cryptos.CryptosResultReply
         * @static
         * @param {cryptos.ICryptosResultReply=} [properties] Properties to set
         * @returns {cryptos.CryptosResultReply} CryptosResultReply instance
         */
        CryptosResultReply.create = function create(properties) {
            return new CryptosResultReply(properties);
        };

        /**
         * Encodes the specified CryptosResultReply message. Does not implicitly {@link cryptos.CryptosResultReply.verify|verify} messages.
         * @function encode
         * @memberof cryptos.CryptosResultReply
         * @static
         * @param {cryptos.ICryptosResultReply} message CryptosResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptosResultReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.items.length; ++i)
                    writer.int32(message.items[i]);
                writer.ldelim();
            }
            if (message.awardNum != null && Object.hasOwnProperty.call(message, "awardNum"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.awardNum);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.winAmount);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.betAmount);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.result);
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified CryptosResultReply message, length delimited. Does not implicitly {@link cryptos.CryptosResultReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cryptos.CryptosResultReply
         * @static
         * @param {cryptos.ICryptosResultReply} message CryptosResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CryptosResultReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CryptosResultReply message from the specified reader or buffer.
         * @function decode
         * @memberof cryptos.CryptosResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cryptos.CryptosResultReply} CryptosResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptosResultReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.cryptos.CryptosResultReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.items.push(reader.int32());
                    } else
                        message.items.push(reader.int32());
                    break;
                case 2:
                    message.awardNum = reader.int32();
                    break;
                case 3:
                    message.currencyType = reader.int32();
                    break;
                case 4:
                    message.winAmount = reader.int64();
                    break;
                case 5:
                    message.betAmount = reader.int64();
                    break;
                case 6:
                    message.gameCode = reader.string();
                    break;
                case 7:
                    message.result = reader.int32();
                    break;
                case 8:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CryptosResultReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cryptos.CryptosResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cryptos.CryptosResultReply} CryptosResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CryptosResultReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CryptosResultReply message.
         * @function verify
         * @memberof cryptos.CryptosResultReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CryptosResultReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i)
                    if (!$util.isInteger(message.items[i]))
                        return "items: integer[] expected";
            }
            if (message.awardNum != null && message.hasOwnProperty("awardNum"))
                if (!$util.isInteger(message.awardNum))
                    return "awardNum: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a CryptosResultReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cryptos.CryptosResultReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cryptos.CryptosResultReply} CryptosResultReply
         */
        CryptosResultReply.fromObject = function fromObject(object) {
            if (object instanceof $root.cryptos.CryptosResultReply)
                return object;
            var message = new $root.cryptos.CryptosResultReply();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".cryptos.CryptosResultReply.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i)
                    message.items[i] = object.items[i] | 0;
            }
            if (object.awardNum != null)
                message.awardNum = object.awardNum | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a CryptosResultReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cryptos.CryptosResultReply
         * @static
         * @param {cryptos.CryptosResultReply} message CryptosResultReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CryptosResultReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults) {
                object.awardNum = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.gameCode = "";
                object.result = 0;
                object.fairness = "";
            }
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = message.items[j];
            }
            if (message.awardNum != null && message.hasOwnProperty("awardNum"))
                object.awardNum = message.awardNum;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this CryptosResultReply to JSON.
         * @function toJSON
         * @memberof cryptos.CryptosResultReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CryptosResultReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CryptosResultReply;
    })();

    cryptos.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof cryptos
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof cryptos
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {cryptos.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof cryptos.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof cryptos.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof cryptos.HistoryResult
         * @static
         * @param {cryptos.IHistoryResult=} [properties] Properties to set
         * @returns {cryptos.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link cryptos.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof cryptos.HistoryResult
         * @static
         * @param {cryptos.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link cryptos.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cryptos.HistoryResult
         * @static
         * @param {cryptos.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof cryptos.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cryptos.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.cryptos.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cryptos.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cryptos.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof cryptos.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cryptos.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cryptos.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.cryptos.HistoryResult)
                return object;
            var message = new $root.cryptos.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cryptos.HistoryResult
         * @static
         * @param {cryptos.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof cryptos.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return cryptos;
})();

$root.mines = (function() {

    /**
     * Namespace mines.
     * @exports mines
     * @namespace
     */
    var mines = {};

    /**
     * CARD_STATE_TYPE enum.
     * @name mines.CARD_STATE_TYPE
     * @enum {number}
     * @property {number} CST_NONE=0 CST_NONE value
     * @property {number} CST_CLOSED=1 CST_CLOSED value
     * @property {number} CST_OPEN=2 CST_OPEN value
     */
    mines.CARD_STATE_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CST_NONE"] = 0;
        values[valuesById[1] = "CST_CLOSED"] = 1;
        values[valuesById[2] = "CST_OPEN"] = 2;
        return values;
    })();

    /**
     * CARD_TYPE enum.
     * @name mines.CARD_TYPE
     * @enum {number}
     * @property {number} CT_NONE=0 CT_NONE value
     * @property {number} CT_DIAMOND=1 CT_DIAMOND value
     * @property {number} CT_BOMB=2 CT_BOMB value
     */
    mines.CARD_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CT_NONE"] = 0;
        values[valuesById[1] = "CT_DIAMOND"] = 1;
        values[valuesById[2] = "CT_BOMB"] = 2;
        return values;
    })();

    mines.MinesStateReply = (function() {

        /**
         * Properties of a MinesStateReply.
         * @memberof mines
         * @interface IMinesStateReply
         * @property {string|null} [params] MinesStateReply params
         * @property {string|null} [payout] MinesStateReply payout
         * @property {Array.<number>|null} [openIndex] MinesStateReply openIndex
         * @property {Array.<number>|null} [openCards] MinesStateReply openCards
         * @property {number|null} [currencyType] MinesStateReply currencyType
         * @property {number|Long|null} [betAmount] MinesStateReply betAmount
         * @property {number|null} [bombAmount] MinesStateReply bombAmount
         * @property {Array.<mines.IHistoryResult>|null} [results] MinesStateReply results
         * @property {string|null} [fairness] MinesStateReply fairness
         */

        /**
         * Constructs a new MinesStateReply.
         * @memberof mines
         * @classdesc Represents a MinesStateReply.
         * @implements IMinesStateReply
         * @constructor
         * @param {mines.IMinesStateReply=} [properties] Properties to set
         */
        function MinesStateReply(properties) {
            this.openIndex = [];
            this.openCards = [];
            this.results = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MinesStateReply params.
         * @member {string} params
         * @memberof mines.MinesStateReply
         * @instance
         */
        MinesStateReply.prototype.params = "";

        /**
         * MinesStateReply payout.
         * @member {string} payout
         * @memberof mines.MinesStateReply
         * @instance
         */
        MinesStateReply.prototype.payout = "";

        /**
         * MinesStateReply openIndex.
         * @member {Array.<number>} openIndex
         * @memberof mines.MinesStateReply
         * @instance
         */
        MinesStateReply.prototype.openIndex = $util.emptyArray;

        /**
         * MinesStateReply openCards.
         * @member {Array.<number>} openCards
         * @memberof mines.MinesStateReply
         * @instance
         */
        MinesStateReply.prototype.openCards = $util.emptyArray;

        /**
         * MinesStateReply currencyType.
         * @member {number} currencyType
         * @memberof mines.MinesStateReply
         * @instance
         */
        MinesStateReply.prototype.currencyType = 0;

        /**
         * MinesStateReply betAmount.
         * @member {number|Long} betAmount
         * @memberof mines.MinesStateReply
         * @instance
         */
        MinesStateReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * MinesStateReply bombAmount.
         * @member {number} bombAmount
         * @memberof mines.MinesStateReply
         * @instance
         */
        MinesStateReply.prototype.bombAmount = 0;

        /**
         * MinesStateReply results.
         * @member {Array.<mines.IHistoryResult>} results
         * @memberof mines.MinesStateReply
         * @instance
         */
        MinesStateReply.prototype.results = $util.emptyArray;

        /**
         * MinesStateReply fairness.
         * @member {string} fairness
         * @memberof mines.MinesStateReply
         * @instance
         */
        MinesStateReply.prototype.fairness = "";

        /**
         * Creates a new MinesStateReply instance using the specified properties.
         * @function create
         * @memberof mines.MinesStateReply
         * @static
         * @param {mines.IMinesStateReply=} [properties] Properties to set
         * @returns {mines.MinesStateReply} MinesStateReply instance
         */
        MinesStateReply.create = function create(properties) {
            return new MinesStateReply(properties);
        };

        /**
         * Encodes the specified MinesStateReply message. Does not implicitly {@link mines.MinesStateReply.verify|verify} messages.
         * @function encode
         * @memberof mines.MinesStateReply
         * @static
         * @param {mines.IMinesStateReply} message MinesStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.payout);
            if (message.openIndex != null && message.openIndex.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.openIndex.length; ++i)
                    writer.int32(message.openIndex[i]);
                writer.ldelim();
            }
            if (message.openCards != null && message.openCards.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.openCards.length; ++i)
                    writer.int32(message.openCards[i]);
                writer.ldelim();
            }
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.betAmount);
            if (message.bombAmount != null && Object.hasOwnProperty.call(message, "bombAmount"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.bombAmount);
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.mines.HistoryResult.encode(message.results[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified MinesStateReply message, length delimited. Does not implicitly {@link mines.MinesStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mines.MinesStateReply
         * @static
         * @param {mines.IMinesStateReply} message MinesStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MinesStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof mines.MinesStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mines.MinesStateReply} MinesStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mines.MinesStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 2:
                    message.payout = reader.string();
                    break;
                case 3:
                    if (!(message.openIndex && message.openIndex.length))
                        message.openIndex = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.openIndex.push(reader.int32());
                    } else
                        message.openIndex.push(reader.int32());
                    break;
                case 4:
                    if (!(message.openCards && message.openCards.length))
                        message.openCards = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.openCards.push(reader.int32());
                    } else
                        message.openCards.push(reader.int32());
                    break;
                case 5:
                    message.currencyType = reader.int32();
                    break;
                case 6:
                    message.betAmount = reader.int64();
                    break;
                case 7:
                    message.bombAmount = reader.int32();
                    break;
                case 8:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.mines.HistoryResult.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MinesStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mines.MinesStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mines.MinesStateReply} MinesStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MinesStateReply message.
         * @function verify
         * @memberof mines.MinesStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MinesStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isString(message.payout))
                    return "payout: string expected";
            if (message.openIndex != null && message.hasOwnProperty("openIndex")) {
                if (!Array.isArray(message.openIndex))
                    return "openIndex: array expected";
                for (var i = 0; i < message.openIndex.length; ++i)
                    if (!$util.isInteger(message.openIndex[i]))
                        return "openIndex: integer[] expected";
            }
            if (message.openCards != null && message.hasOwnProperty("openCards")) {
                if (!Array.isArray(message.openCards))
                    return "openCards: array expected";
                for (var i = 0; i < message.openCards.length; ++i)
                    if (!$util.isInteger(message.openCards[i]))
                        return "openCards: integer[] expected";
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.bombAmount != null && message.hasOwnProperty("bombAmount"))
                if (!$util.isInteger(message.bombAmount))
                    return "bombAmount: integer expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.mines.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a MinesStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mines.MinesStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mines.MinesStateReply} MinesStateReply
         */
        MinesStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.mines.MinesStateReply)
                return object;
            var message = new $root.mines.MinesStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.payout != null)
                message.payout = String(object.payout);
            if (object.openIndex) {
                if (!Array.isArray(object.openIndex))
                    throw TypeError(".mines.MinesStateReply.openIndex: array expected");
                message.openIndex = [];
                for (var i = 0; i < object.openIndex.length; ++i)
                    message.openIndex[i] = object.openIndex[i] | 0;
            }
            if (object.openCards) {
                if (!Array.isArray(object.openCards))
                    throw TypeError(".mines.MinesStateReply.openCards: array expected");
                message.openCards = [];
                for (var i = 0; i < object.openCards.length; ++i)
                    message.openCards[i] = object.openCards[i] | 0;
            }
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.bombAmount != null)
                message.bombAmount = object.bombAmount | 0;
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".mines.MinesStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".mines.MinesStateReply.results: object expected");
                    message.results[i] = $root.mines.HistoryResult.fromObject(object.results[i]);
                }
            }
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a MinesStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mines.MinesStateReply
         * @static
         * @param {mines.MinesStateReply} message MinesStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MinesStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.openIndex = [];
                object.openCards = [];
                object.results = [];
            }
            if (options.defaults) {
                object.params = "";
                object.payout = "";
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.bombAmount = 0;
                object.fairness = "";
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            if (message.openIndex && message.openIndex.length) {
                object.openIndex = [];
                for (var j = 0; j < message.openIndex.length; ++j)
                    object.openIndex[j] = message.openIndex[j];
            }
            if (message.openCards && message.openCards.length) {
                object.openCards = [];
                for (var j = 0; j < message.openCards.length; ++j)
                    object.openCards[j] = message.openCards[j];
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.bombAmount != null && message.hasOwnProperty("bombAmount"))
                object.bombAmount = message.bombAmount;
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.mines.HistoryResult.toObject(message.results[j], options);
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this MinesStateReply to JSON.
         * @function toJSON
         * @memberof mines.MinesStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MinesStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MinesStateReply;
    })();

    mines.MinesBetReq = (function() {

        /**
         * Properties of a MinesBetReq.
         * @memberof mines
         * @interface IMinesBetReq
         * @property {number|Long|null} [betAmount] MinesBetReq betAmount
         * @property {number|null} [bombAmount] MinesBetReq bombAmount
         * @property {number|null} [currencyType] MinesBetReq currencyType
         */

        /**
         * Constructs a new MinesBetReq.
         * @memberof mines
         * @classdesc Represents a MinesBetReq.
         * @implements IMinesBetReq
         * @constructor
         * @param {mines.IMinesBetReq=} [properties] Properties to set
         */
        function MinesBetReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MinesBetReq betAmount.
         * @member {number|Long} betAmount
         * @memberof mines.MinesBetReq
         * @instance
         */
        MinesBetReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * MinesBetReq bombAmount.
         * @member {number} bombAmount
         * @memberof mines.MinesBetReq
         * @instance
         */
        MinesBetReq.prototype.bombAmount = 0;

        /**
         * MinesBetReq currencyType.
         * @member {number} currencyType
         * @memberof mines.MinesBetReq
         * @instance
         */
        MinesBetReq.prototype.currencyType = 0;

        /**
         * Creates a new MinesBetReq instance using the specified properties.
         * @function create
         * @memberof mines.MinesBetReq
         * @static
         * @param {mines.IMinesBetReq=} [properties] Properties to set
         * @returns {mines.MinesBetReq} MinesBetReq instance
         */
        MinesBetReq.create = function create(properties) {
            return new MinesBetReq(properties);
        };

        /**
         * Encodes the specified MinesBetReq message. Does not implicitly {@link mines.MinesBetReq.verify|verify} messages.
         * @function encode
         * @memberof mines.MinesBetReq
         * @static
         * @param {mines.IMinesBetReq} message MinesBetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesBetReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.betAmount);
            if (message.bombAmount != null && Object.hasOwnProperty.call(message, "bombAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.bombAmount);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.currencyType);
            return writer;
        };

        /**
         * Encodes the specified MinesBetReq message, length delimited. Does not implicitly {@link mines.MinesBetReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mines.MinesBetReq
         * @static
         * @param {mines.IMinesBetReq} message MinesBetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesBetReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MinesBetReq message from the specified reader or buffer.
         * @function decode
         * @memberof mines.MinesBetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mines.MinesBetReq} MinesBetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesBetReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mines.MinesBetReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.betAmount = reader.int64();
                    break;
                case 2:
                    message.bombAmount = reader.int32();
                    break;
                case 3:
                    message.currencyType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MinesBetReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mines.MinesBetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mines.MinesBetReq} MinesBetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesBetReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MinesBetReq message.
         * @function verify
         * @memberof mines.MinesBetReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MinesBetReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.bombAmount != null && message.hasOwnProperty("bombAmount"))
                if (!$util.isInteger(message.bombAmount))
                    return "bombAmount: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            return null;
        };

        /**
         * Creates a MinesBetReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mines.MinesBetReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mines.MinesBetReq} MinesBetReq
         */
        MinesBetReq.fromObject = function fromObject(object) {
            if (object instanceof $root.mines.MinesBetReq)
                return object;
            var message = new $root.mines.MinesBetReq();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.bombAmount != null)
                message.bombAmount = object.bombAmount | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            return message;
        };

        /**
         * Creates a plain object from a MinesBetReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mines.MinesBetReq
         * @static
         * @param {mines.MinesBetReq} message MinesBetReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MinesBetReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.bombAmount = 0;
                object.currencyType = 0;
            }
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.bombAmount != null && message.hasOwnProperty("bombAmount"))
                object.bombAmount = message.bombAmount;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            return object;
        };

        /**
         * Converts this MinesBetReq to JSON.
         * @function toJSON
         * @memberof mines.MinesBetReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MinesBetReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MinesBetReq;
    })();

    mines.MinesBetReply = (function() {

        /**
         * Properties of a MinesBetReply.
         * @memberof mines
         * @interface IMinesBetReply
         * @property {number|null} [currencyType] MinesBetReply currencyType
         * @property {number|Long|null} [betAmount] MinesBetReply betAmount
         * @property {string|null} [fairness] MinesBetReply fairness
         * @property {number|null} [bombAmount] MinesBetReply bombAmount
         */

        /**
         * Constructs a new MinesBetReply.
         * @memberof mines
         * @classdesc Represents a MinesBetReply.
         * @implements IMinesBetReply
         * @constructor
         * @param {mines.IMinesBetReply=} [properties] Properties to set
         */
        function MinesBetReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MinesBetReply currencyType.
         * @member {number} currencyType
         * @memberof mines.MinesBetReply
         * @instance
         */
        MinesBetReply.prototype.currencyType = 0;

        /**
         * MinesBetReply betAmount.
         * @member {number|Long} betAmount
         * @memberof mines.MinesBetReply
         * @instance
         */
        MinesBetReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * MinesBetReply fairness.
         * @member {string} fairness
         * @memberof mines.MinesBetReply
         * @instance
         */
        MinesBetReply.prototype.fairness = "";

        /**
         * MinesBetReply bombAmount.
         * @member {number} bombAmount
         * @memberof mines.MinesBetReply
         * @instance
         */
        MinesBetReply.prototype.bombAmount = 0;

        /**
         * Creates a new MinesBetReply instance using the specified properties.
         * @function create
         * @memberof mines.MinesBetReply
         * @static
         * @param {mines.IMinesBetReply=} [properties] Properties to set
         * @returns {mines.MinesBetReply} MinesBetReply instance
         */
        MinesBetReply.create = function create(properties) {
            return new MinesBetReply(properties);
        };

        /**
         * Encodes the specified MinesBetReply message. Does not implicitly {@link mines.MinesBetReply.verify|verify} messages.
         * @function encode
         * @memberof mines.MinesBetReply
         * @static
         * @param {mines.IMinesBetReply} message MinesBetReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesBetReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fairness);
            if (message.bombAmount != null && Object.hasOwnProperty.call(message, "bombAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.bombAmount);
            return writer;
        };

        /**
         * Encodes the specified MinesBetReply message, length delimited. Does not implicitly {@link mines.MinesBetReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mines.MinesBetReply
         * @static
         * @param {mines.IMinesBetReply} message MinesBetReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesBetReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MinesBetReply message from the specified reader or buffer.
         * @function decode
         * @memberof mines.MinesBetReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mines.MinesBetReply} MinesBetReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesBetReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mines.MinesBetReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                case 3:
                    message.fairness = reader.string();
                    break;
                case 4:
                    message.bombAmount = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MinesBetReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mines.MinesBetReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mines.MinesBetReply} MinesBetReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesBetReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MinesBetReply message.
         * @function verify
         * @memberof mines.MinesBetReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MinesBetReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            if (message.bombAmount != null && message.hasOwnProperty("bombAmount"))
                if (!$util.isInteger(message.bombAmount))
                    return "bombAmount: integer expected";
            return null;
        };

        /**
         * Creates a MinesBetReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mines.MinesBetReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mines.MinesBetReply} MinesBetReply
         */
        MinesBetReply.fromObject = function fromObject(object) {
            if (object instanceof $root.mines.MinesBetReply)
                return object;
            var message = new $root.mines.MinesBetReply();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            if (object.bombAmount != null)
                message.bombAmount = object.bombAmount | 0;
            return message;
        };

        /**
         * Creates a plain object from a MinesBetReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mines.MinesBetReply
         * @static
         * @param {mines.MinesBetReply} message MinesBetReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MinesBetReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.fairness = "";
                object.bombAmount = 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            if (message.bombAmount != null && message.hasOwnProperty("bombAmount"))
                object.bombAmount = message.bombAmount;
            return object;
        };

        /**
         * Converts this MinesBetReply to JSON.
         * @function toJSON
         * @memberof mines.MinesBetReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MinesBetReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MinesBetReply;
    })();

    mines.MinesOpenReq = (function() {

        /**
         * Properties of a MinesOpenReq.
         * @memberof mines
         * @interface IMinesOpenReq
         * @property {number|null} [cardIndex] MinesOpenReq cardIndex
         */

        /**
         * Constructs a new MinesOpenReq.
         * @memberof mines
         * @classdesc Represents a MinesOpenReq.
         * @implements IMinesOpenReq
         * @constructor
         * @param {mines.IMinesOpenReq=} [properties] Properties to set
         */
        function MinesOpenReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MinesOpenReq cardIndex.
         * @member {number} cardIndex
         * @memberof mines.MinesOpenReq
         * @instance
         */
        MinesOpenReq.prototype.cardIndex = 0;

        /**
         * Creates a new MinesOpenReq instance using the specified properties.
         * @function create
         * @memberof mines.MinesOpenReq
         * @static
         * @param {mines.IMinesOpenReq=} [properties] Properties to set
         * @returns {mines.MinesOpenReq} MinesOpenReq instance
         */
        MinesOpenReq.create = function create(properties) {
            return new MinesOpenReq(properties);
        };

        /**
         * Encodes the specified MinesOpenReq message. Does not implicitly {@link mines.MinesOpenReq.verify|verify} messages.
         * @function encode
         * @memberof mines.MinesOpenReq
         * @static
         * @param {mines.IMinesOpenReq} message MinesOpenReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesOpenReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cardIndex != null && Object.hasOwnProperty.call(message, "cardIndex"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.cardIndex);
            return writer;
        };

        /**
         * Encodes the specified MinesOpenReq message, length delimited. Does not implicitly {@link mines.MinesOpenReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mines.MinesOpenReq
         * @static
         * @param {mines.IMinesOpenReq} message MinesOpenReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesOpenReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MinesOpenReq message from the specified reader or buffer.
         * @function decode
         * @memberof mines.MinesOpenReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mines.MinesOpenReq} MinesOpenReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesOpenReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mines.MinesOpenReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cardIndex = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MinesOpenReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mines.MinesOpenReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mines.MinesOpenReq} MinesOpenReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesOpenReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MinesOpenReq message.
         * @function verify
         * @memberof mines.MinesOpenReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MinesOpenReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cardIndex != null && message.hasOwnProperty("cardIndex"))
                if (!$util.isInteger(message.cardIndex))
                    return "cardIndex: integer expected";
            return null;
        };

        /**
         * Creates a MinesOpenReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mines.MinesOpenReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mines.MinesOpenReq} MinesOpenReq
         */
        MinesOpenReq.fromObject = function fromObject(object) {
            if (object instanceof $root.mines.MinesOpenReq)
                return object;
            var message = new $root.mines.MinesOpenReq();
            if (object.cardIndex != null)
                message.cardIndex = object.cardIndex | 0;
            return message;
        };

        /**
         * Creates a plain object from a MinesOpenReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mines.MinesOpenReq
         * @static
         * @param {mines.MinesOpenReq} message MinesOpenReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MinesOpenReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.cardIndex = 0;
            if (message.cardIndex != null && message.hasOwnProperty("cardIndex"))
                object.cardIndex = message.cardIndex;
            return object;
        };

        /**
         * Converts this MinesOpenReq to JSON.
         * @function toJSON
         * @memberof mines.MinesOpenReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MinesOpenReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MinesOpenReq;
    })();

    mines.MinesOpenReply = (function() {

        /**
         * Properties of a MinesOpenReply.
         * @memberof mines
         * @interface IMinesOpenReply
         * @property {number|null} [cardType] MinesOpenReply cardType
         * @property {Array.<number>|null} [openIndex] MinesOpenReply openIndex
         * @property {Array.<number>|null} [openCards] MinesOpenReply openCards
         * @property {number|null} [currencyType] MinesOpenReply currencyType
         * @property {number|Long|null} [betAmount] MinesOpenReply betAmount
         * @property {number|null} [bombAmount] MinesOpenReply bombAmount
         */

        /**
         * Constructs a new MinesOpenReply.
         * @memberof mines
         * @classdesc Represents a MinesOpenReply.
         * @implements IMinesOpenReply
         * @constructor
         * @param {mines.IMinesOpenReply=} [properties] Properties to set
         */
        function MinesOpenReply(properties) {
            this.openIndex = [];
            this.openCards = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MinesOpenReply cardType.
         * @member {number} cardType
         * @memberof mines.MinesOpenReply
         * @instance
         */
        MinesOpenReply.prototype.cardType = 0;

        /**
         * MinesOpenReply openIndex.
         * @member {Array.<number>} openIndex
         * @memberof mines.MinesOpenReply
         * @instance
         */
        MinesOpenReply.prototype.openIndex = $util.emptyArray;

        /**
         * MinesOpenReply openCards.
         * @member {Array.<number>} openCards
         * @memberof mines.MinesOpenReply
         * @instance
         */
        MinesOpenReply.prototype.openCards = $util.emptyArray;

        /**
         * MinesOpenReply currencyType.
         * @member {number} currencyType
         * @memberof mines.MinesOpenReply
         * @instance
         */
        MinesOpenReply.prototype.currencyType = 0;

        /**
         * MinesOpenReply betAmount.
         * @member {number|Long} betAmount
         * @memberof mines.MinesOpenReply
         * @instance
         */
        MinesOpenReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * MinesOpenReply bombAmount.
         * @member {number} bombAmount
         * @memberof mines.MinesOpenReply
         * @instance
         */
        MinesOpenReply.prototype.bombAmount = 0;

        /**
         * Creates a new MinesOpenReply instance using the specified properties.
         * @function create
         * @memberof mines.MinesOpenReply
         * @static
         * @param {mines.IMinesOpenReply=} [properties] Properties to set
         * @returns {mines.MinesOpenReply} MinesOpenReply instance
         */
        MinesOpenReply.create = function create(properties) {
            return new MinesOpenReply(properties);
        };

        /**
         * Encodes the specified MinesOpenReply message. Does not implicitly {@link mines.MinesOpenReply.verify|verify} messages.
         * @function encode
         * @memberof mines.MinesOpenReply
         * @static
         * @param {mines.IMinesOpenReply} message MinesOpenReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesOpenReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cardType != null && Object.hasOwnProperty.call(message, "cardType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.cardType);
            if (message.openIndex != null && message.openIndex.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.openIndex.length; ++i)
                    writer.int32(message.openIndex[i]);
                writer.ldelim();
            }
            if (message.openCards != null && message.openCards.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.openCards.length; ++i)
                    writer.int32(message.openCards[i]);
                writer.ldelim();
            }
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.betAmount);
            if (message.bombAmount != null && Object.hasOwnProperty.call(message, "bombAmount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.bombAmount);
            return writer;
        };

        /**
         * Encodes the specified MinesOpenReply message, length delimited. Does not implicitly {@link mines.MinesOpenReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mines.MinesOpenReply
         * @static
         * @param {mines.IMinesOpenReply} message MinesOpenReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesOpenReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MinesOpenReply message from the specified reader or buffer.
         * @function decode
         * @memberof mines.MinesOpenReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mines.MinesOpenReply} MinesOpenReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesOpenReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mines.MinesOpenReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cardType = reader.int32();
                    break;
                case 2:
                    if (!(message.openIndex && message.openIndex.length))
                        message.openIndex = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.openIndex.push(reader.int32());
                    } else
                        message.openIndex.push(reader.int32());
                    break;
                case 3:
                    if (!(message.openCards && message.openCards.length))
                        message.openCards = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.openCards.push(reader.int32());
                    } else
                        message.openCards.push(reader.int32());
                    break;
                case 4:
                    message.currencyType = reader.int32();
                    break;
                case 5:
                    message.betAmount = reader.int64();
                    break;
                case 6:
                    message.bombAmount = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MinesOpenReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mines.MinesOpenReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mines.MinesOpenReply} MinesOpenReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesOpenReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MinesOpenReply message.
         * @function verify
         * @memberof mines.MinesOpenReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MinesOpenReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cardType != null && message.hasOwnProperty("cardType"))
                if (!$util.isInteger(message.cardType))
                    return "cardType: integer expected";
            if (message.openIndex != null && message.hasOwnProperty("openIndex")) {
                if (!Array.isArray(message.openIndex))
                    return "openIndex: array expected";
                for (var i = 0; i < message.openIndex.length; ++i)
                    if (!$util.isInteger(message.openIndex[i]))
                        return "openIndex: integer[] expected";
            }
            if (message.openCards != null && message.hasOwnProperty("openCards")) {
                if (!Array.isArray(message.openCards))
                    return "openCards: array expected";
                for (var i = 0; i < message.openCards.length; ++i)
                    if (!$util.isInteger(message.openCards[i]))
                        return "openCards: integer[] expected";
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.bombAmount != null && message.hasOwnProperty("bombAmount"))
                if (!$util.isInteger(message.bombAmount))
                    return "bombAmount: integer expected";
            return null;
        };

        /**
         * Creates a MinesOpenReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mines.MinesOpenReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mines.MinesOpenReply} MinesOpenReply
         */
        MinesOpenReply.fromObject = function fromObject(object) {
            if (object instanceof $root.mines.MinesOpenReply)
                return object;
            var message = new $root.mines.MinesOpenReply();
            if (object.cardType != null)
                message.cardType = object.cardType | 0;
            if (object.openIndex) {
                if (!Array.isArray(object.openIndex))
                    throw TypeError(".mines.MinesOpenReply.openIndex: array expected");
                message.openIndex = [];
                for (var i = 0; i < object.openIndex.length; ++i)
                    message.openIndex[i] = object.openIndex[i] | 0;
            }
            if (object.openCards) {
                if (!Array.isArray(object.openCards))
                    throw TypeError(".mines.MinesOpenReply.openCards: array expected");
                message.openCards = [];
                for (var i = 0; i < object.openCards.length; ++i)
                    message.openCards[i] = object.openCards[i] | 0;
            }
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.bombAmount != null)
                message.bombAmount = object.bombAmount | 0;
            return message;
        };

        /**
         * Creates a plain object from a MinesOpenReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mines.MinesOpenReply
         * @static
         * @param {mines.MinesOpenReply} message MinesOpenReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MinesOpenReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.openIndex = [];
                object.openCards = [];
            }
            if (options.defaults) {
                object.cardType = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.bombAmount = 0;
            }
            if (message.cardType != null && message.hasOwnProperty("cardType"))
                object.cardType = message.cardType;
            if (message.openIndex && message.openIndex.length) {
                object.openIndex = [];
                for (var j = 0; j < message.openIndex.length; ++j)
                    object.openIndex[j] = message.openIndex[j];
            }
            if (message.openCards && message.openCards.length) {
                object.openCards = [];
                for (var j = 0; j < message.openCards.length; ++j)
                    object.openCards[j] = message.openCards[j];
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.bombAmount != null && message.hasOwnProperty("bombAmount"))
                object.bombAmount = message.bombAmount;
            return object;
        };

        /**
         * Converts this MinesOpenReply to JSON.
         * @function toJSON
         * @memberof mines.MinesOpenReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MinesOpenReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MinesOpenReply;
    })();

    mines.MinesResultReply = (function() {

        /**
         * Properties of a MinesResultReply.
         * @memberof mines
         * @interface IMinesResultReply
         * @property {Array.<number>|null} [cards] MinesResultReply cards
         * @property {Array.<number>|null} [openIndex] MinesResultReply openIndex
         * @property {number|null} [hit] MinesResultReply hit
         * @property {number|null} [currencyType] MinesResultReply currencyType
         * @property {number|Long|null} [winAmount] MinesResultReply winAmount
         * @property {number|Long|null} [betAmount] MinesResultReply betAmount
         * @property {number|null} [bombAmount] MinesResultReply bombAmount
         * @property {boolean|null} [isAuto] MinesResultReply isAuto
         * @property {string|null} [gameCode] MinesResultReply gameCode
         */

        /**
         * Constructs a new MinesResultReply.
         * @memberof mines
         * @classdesc Represents a MinesResultReply.
         * @implements IMinesResultReply
         * @constructor
         * @param {mines.IMinesResultReply=} [properties] Properties to set
         */
        function MinesResultReply(properties) {
            this.cards = [];
            this.openIndex = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MinesResultReply cards.
         * @member {Array.<number>} cards
         * @memberof mines.MinesResultReply
         * @instance
         */
        MinesResultReply.prototype.cards = $util.emptyArray;

        /**
         * MinesResultReply openIndex.
         * @member {Array.<number>} openIndex
         * @memberof mines.MinesResultReply
         * @instance
         */
        MinesResultReply.prototype.openIndex = $util.emptyArray;

        /**
         * MinesResultReply hit.
         * @member {number} hit
         * @memberof mines.MinesResultReply
         * @instance
         */
        MinesResultReply.prototype.hit = 0;

        /**
         * MinesResultReply currencyType.
         * @member {number} currencyType
         * @memberof mines.MinesResultReply
         * @instance
         */
        MinesResultReply.prototype.currencyType = 0;

        /**
         * MinesResultReply winAmount.
         * @member {number|Long} winAmount
         * @memberof mines.MinesResultReply
         * @instance
         */
        MinesResultReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * MinesResultReply betAmount.
         * @member {number|Long} betAmount
         * @memberof mines.MinesResultReply
         * @instance
         */
        MinesResultReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * MinesResultReply bombAmount.
         * @member {number} bombAmount
         * @memberof mines.MinesResultReply
         * @instance
         */
        MinesResultReply.prototype.bombAmount = 0;

        /**
         * MinesResultReply isAuto.
         * @member {boolean} isAuto
         * @memberof mines.MinesResultReply
         * @instance
         */
        MinesResultReply.prototype.isAuto = false;

        /**
         * MinesResultReply gameCode.
         * @member {string} gameCode
         * @memberof mines.MinesResultReply
         * @instance
         */
        MinesResultReply.prototype.gameCode = "";

        /**
         * Creates a new MinesResultReply instance using the specified properties.
         * @function create
         * @memberof mines.MinesResultReply
         * @static
         * @param {mines.IMinesResultReply=} [properties] Properties to set
         * @returns {mines.MinesResultReply} MinesResultReply instance
         */
        MinesResultReply.create = function create(properties) {
            return new MinesResultReply(properties);
        };

        /**
         * Encodes the specified MinesResultReply message. Does not implicitly {@link mines.MinesResultReply.verify|verify} messages.
         * @function encode
         * @memberof mines.MinesResultReply
         * @static
         * @param {mines.IMinesResultReply} message MinesResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesResultReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cards != null && message.cards.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.cards.length; ++i)
                    writer.int32(message.cards[i]);
                writer.ldelim();
            }
            if (message.openIndex != null && message.openIndex.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.openIndex.length; ++i)
                    writer.int32(message.openIndex[i]);
                writer.ldelim();
            }
            if (message.hit != null && Object.hasOwnProperty.call(message, "hit"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.hit);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.winAmount);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.betAmount);
            if (message.bombAmount != null && Object.hasOwnProperty.call(message, "bombAmount"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.bombAmount);
            if (message.isAuto != null && Object.hasOwnProperty.call(message, "isAuto"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isAuto);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.gameCode);
            return writer;
        };

        /**
         * Encodes the specified MinesResultReply message, length delimited. Does not implicitly {@link mines.MinesResultReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mines.MinesResultReply
         * @static
         * @param {mines.IMinesResultReply} message MinesResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesResultReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MinesResultReply message from the specified reader or buffer.
         * @function decode
         * @memberof mines.MinesResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mines.MinesResultReply} MinesResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesResultReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mines.MinesResultReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.cards && message.cards.length))
                        message.cards = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.cards.push(reader.int32());
                    } else
                        message.cards.push(reader.int32());
                    break;
                case 2:
                    if (!(message.openIndex && message.openIndex.length))
                        message.openIndex = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.openIndex.push(reader.int32());
                    } else
                        message.openIndex.push(reader.int32());
                    break;
                case 3:
                    message.hit = reader.int32();
                    break;
                case 4:
                    message.currencyType = reader.int32();
                    break;
                case 5:
                    message.winAmount = reader.int64();
                    break;
                case 6:
                    message.betAmount = reader.int64();
                    break;
                case 7:
                    message.bombAmount = reader.int32();
                    break;
                case 8:
                    message.isAuto = reader.bool();
                    break;
                case 9:
                    message.gameCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MinesResultReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mines.MinesResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mines.MinesResultReply} MinesResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesResultReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MinesResultReply message.
         * @function verify
         * @memberof mines.MinesResultReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MinesResultReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cards != null && message.hasOwnProperty("cards")) {
                if (!Array.isArray(message.cards))
                    return "cards: array expected";
                for (var i = 0; i < message.cards.length; ++i)
                    if (!$util.isInteger(message.cards[i]))
                        return "cards: integer[] expected";
            }
            if (message.openIndex != null && message.hasOwnProperty("openIndex")) {
                if (!Array.isArray(message.openIndex))
                    return "openIndex: array expected";
                for (var i = 0; i < message.openIndex.length; ++i)
                    if (!$util.isInteger(message.openIndex[i]))
                        return "openIndex: integer[] expected";
            }
            if (message.hit != null && message.hasOwnProperty("hit"))
                if (!$util.isInteger(message.hit))
                    return "hit: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.bombAmount != null && message.hasOwnProperty("bombAmount"))
                if (!$util.isInteger(message.bombAmount))
                    return "bombAmount: integer expected";
            if (message.isAuto != null && message.hasOwnProperty("isAuto"))
                if (typeof message.isAuto !== "boolean")
                    return "isAuto: boolean expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            return null;
        };

        /**
         * Creates a MinesResultReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mines.MinesResultReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mines.MinesResultReply} MinesResultReply
         */
        MinesResultReply.fromObject = function fromObject(object) {
            if (object instanceof $root.mines.MinesResultReply)
                return object;
            var message = new $root.mines.MinesResultReply();
            if (object.cards) {
                if (!Array.isArray(object.cards))
                    throw TypeError(".mines.MinesResultReply.cards: array expected");
                message.cards = [];
                for (var i = 0; i < object.cards.length; ++i)
                    message.cards[i] = object.cards[i] | 0;
            }
            if (object.openIndex) {
                if (!Array.isArray(object.openIndex))
                    throw TypeError(".mines.MinesResultReply.openIndex: array expected");
                message.openIndex = [];
                for (var i = 0; i < object.openIndex.length; ++i)
                    message.openIndex[i] = object.openIndex[i] | 0;
            }
            if (object.hit != null)
                message.hit = object.hit | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.bombAmount != null)
                message.bombAmount = object.bombAmount | 0;
            if (object.isAuto != null)
                message.isAuto = Boolean(object.isAuto);
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            return message;
        };

        /**
         * Creates a plain object from a MinesResultReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mines.MinesResultReply
         * @static
         * @param {mines.MinesResultReply} message MinesResultReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MinesResultReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.cards = [];
                object.openIndex = [];
            }
            if (options.defaults) {
                object.hit = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.bombAmount = 0;
                object.isAuto = false;
                object.gameCode = "";
            }
            if (message.cards && message.cards.length) {
                object.cards = [];
                for (var j = 0; j < message.cards.length; ++j)
                    object.cards[j] = message.cards[j];
            }
            if (message.openIndex && message.openIndex.length) {
                object.openIndex = [];
                for (var j = 0; j < message.openIndex.length; ++j)
                    object.openIndex[j] = message.openIndex[j];
            }
            if (message.hit != null && message.hasOwnProperty("hit"))
                object.hit = message.hit;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.bombAmount != null && message.hasOwnProperty("bombAmount"))
                object.bombAmount = message.bombAmount;
            if (message.isAuto != null && message.hasOwnProperty("isAuto"))
                object.isAuto = message.isAuto;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            return object;
        };

        /**
         * Converts this MinesResultReply to JSON.
         * @function toJSON
         * @memberof mines.MinesResultReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MinesResultReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MinesResultReply;
    })();

    mines.MinesAutoReq = (function() {

        /**
         * Properties of a MinesAutoReq.
         * @memberof mines
         * @interface IMinesAutoReq
         * @property {number|Long|null} [betAmount] MinesAutoReq betAmount
         * @property {number|null} [bombAmount] MinesAutoReq bombAmount
         * @property {Array.<number>|null} [openIndex] MinesAutoReq openIndex
         * @property {number|null} [currencyType] MinesAutoReq currencyType
         */

        /**
         * Constructs a new MinesAutoReq.
         * @memberof mines
         * @classdesc Represents a MinesAutoReq.
         * @implements IMinesAutoReq
         * @constructor
         * @param {mines.IMinesAutoReq=} [properties] Properties to set
         */
        function MinesAutoReq(properties) {
            this.openIndex = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MinesAutoReq betAmount.
         * @member {number|Long} betAmount
         * @memberof mines.MinesAutoReq
         * @instance
         */
        MinesAutoReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * MinesAutoReq bombAmount.
         * @member {number} bombAmount
         * @memberof mines.MinesAutoReq
         * @instance
         */
        MinesAutoReq.prototype.bombAmount = 0;

        /**
         * MinesAutoReq openIndex.
         * @member {Array.<number>} openIndex
         * @memberof mines.MinesAutoReq
         * @instance
         */
        MinesAutoReq.prototype.openIndex = $util.emptyArray;

        /**
         * MinesAutoReq currencyType.
         * @member {number} currencyType
         * @memberof mines.MinesAutoReq
         * @instance
         */
        MinesAutoReq.prototype.currencyType = 0;

        /**
         * Creates a new MinesAutoReq instance using the specified properties.
         * @function create
         * @memberof mines.MinesAutoReq
         * @static
         * @param {mines.IMinesAutoReq=} [properties] Properties to set
         * @returns {mines.MinesAutoReq} MinesAutoReq instance
         */
        MinesAutoReq.create = function create(properties) {
            return new MinesAutoReq(properties);
        };

        /**
         * Encodes the specified MinesAutoReq message. Does not implicitly {@link mines.MinesAutoReq.verify|verify} messages.
         * @function encode
         * @memberof mines.MinesAutoReq
         * @static
         * @param {mines.IMinesAutoReq} message MinesAutoReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesAutoReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.betAmount);
            if (message.bombAmount != null && Object.hasOwnProperty.call(message, "bombAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.bombAmount);
            if (message.openIndex != null && message.openIndex.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.openIndex.length; ++i)
                    writer.int32(message.openIndex[i]);
                writer.ldelim();
            }
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.currencyType);
            return writer;
        };

        /**
         * Encodes the specified MinesAutoReq message, length delimited. Does not implicitly {@link mines.MinesAutoReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mines.MinesAutoReq
         * @static
         * @param {mines.IMinesAutoReq} message MinesAutoReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MinesAutoReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MinesAutoReq message from the specified reader or buffer.
         * @function decode
         * @memberof mines.MinesAutoReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mines.MinesAutoReq} MinesAutoReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesAutoReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mines.MinesAutoReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.betAmount = reader.int64();
                    break;
                case 2:
                    message.bombAmount = reader.int32();
                    break;
                case 3:
                    if (!(message.openIndex && message.openIndex.length))
                        message.openIndex = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.openIndex.push(reader.int32());
                    } else
                        message.openIndex.push(reader.int32());
                    break;
                case 4:
                    message.currencyType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MinesAutoReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mines.MinesAutoReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mines.MinesAutoReq} MinesAutoReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MinesAutoReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MinesAutoReq message.
         * @function verify
         * @memberof mines.MinesAutoReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MinesAutoReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.bombAmount != null && message.hasOwnProperty("bombAmount"))
                if (!$util.isInteger(message.bombAmount))
                    return "bombAmount: integer expected";
            if (message.openIndex != null && message.hasOwnProperty("openIndex")) {
                if (!Array.isArray(message.openIndex))
                    return "openIndex: array expected";
                for (var i = 0; i < message.openIndex.length; ++i)
                    if (!$util.isInteger(message.openIndex[i]))
                        return "openIndex: integer[] expected";
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            return null;
        };

        /**
         * Creates a MinesAutoReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mines.MinesAutoReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mines.MinesAutoReq} MinesAutoReq
         */
        MinesAutoReq.fromObject = function fromObject(object) {
            if (object instanceof $root.mines.MinesAutoReq)
                return object;
            var message = new $root.mines.MinesAutoReq();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.bombAmount != null)
                message.bombAmount = object.bombAmount | 0;
            if (object.openIndex) {
                if (!Array.isArray(object.openIndex))
                    throw TypeError(".mines.MinesAutoReq.openIndex: array expected");
                message.openIndex = [];
                for (var i = 0; i < object.openIndex.length; ++i)
                    message.openIndex[i] = object.openIndex[i] | 0;
            }
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            return message;
        };

        /**
         * Creates a plain object from a MinesAutoReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mines.MinesAutoReq
         * @static
         * @param {mines.MinesAutoReq} message MinesAutoReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MinesAutoReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.openIndex = [];
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.bombAmount = 0;
                object.currencyType = 0;
            }
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.bombAmount != null && message.hasOwnProperty("bombAmount"))
                object.bombAmount = message.bombAmount;
            if (message.openIndex && message.openIndex.length) {
                object.openIndex = [];
                for (var j = 0; j < message.openIndex.length; ++j)
                    object.openIndex[j] = message.openIndex[j];
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            return object;
        };

        /**
         * Converts this MinesAutoReq to JSON.
         * @function toJSON
         * @memberof mines.MinesAutoReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MinesAutoReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MinesAutoReq;
    })();

    mines.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof mines
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof mines
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {mines.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof mines.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof mines.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof mines.HistoryResult
         * @static
         * @param {mines.IHistoryResult=} [properties] Properties to set
         * @returns {mines.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link mines.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof mines.HistoryResult
         * @static
         * @param {mines.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link mines.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mines.HistoryResult
         * @static
         * @param {mines.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof mines.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mines.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.mines.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mines.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mines.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof mines.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mines.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mines.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.mines.HistoryResult)
                return object;
            var message = new $root.mines.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mines.HistoryResult
         * @static
         * @param {mines.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof mines.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return mines;
})();

$root.limbo = (function() {

    /**
     * Namespace limbo.
     * @exports limbo
     * @namespace
     */
    var limbo = {};

    limbo.LimboStateReply = (function() {

        /**
         * Properties of a LimboStateReply.
         * @memberof limbo
         * @interface ILimboStateReply
         * @property {string|null} [params] LimboStateReply params
         * @property {Array.<limbo.IHistoryResult>|null} [results] LimboStateReply results
         * @property {string|null} [fairness] LimboStateReply fairness
         */

        /**
         * Constructs a new LimboStateReply.
         * @memberof limbo
         * @classdesc Represents a LimboStateReply.
         * @implements ILimboStateReply
         * @constructor
         * @param {limbo.ILimboStateReply=} [properties] Properties to set
         */
        function LimboStateReply(properties) {
            this.results = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LimboStateReply params.
         * @member {string} params
         * @memberof limbo.LimboStateReply
         * @instance
         */
        LimboStateReply.prototype.params = "";

        /**
         * LimboStateReply results.
         * @member {Array.<limbo.IHistoryResult>} results
         * @memberof limbo.LimboStateReply
         * @instance
         */
        LimboStateReply.prototype.results = $util.emptyArray;

        /**
         * LimboStateReply fairness.
         * @member {string} fairness
         * @memberof limbo.LimboStateReply
         * @instance
         */
        LimboStateReply.prototype.fairness = "";

        /**
         * Creates a new LimboStateReply instance using the specified properties.
         * @function create
         * @memberof limbo.LimboStateReply
         * @static
         * @param {limbo.ILimboStateReply=} [properties] Properties to set
         * @returns {limbo.LimboStateReply} LimboStateReply instance
         */
        LimboStateReply.create = function create(properties) {
            return new LimboStateReply(properties);
        };

        /**
         * Encodes the specified LimboStateReply message. Does not implicitly {@link limbo.LimboStateReply.verify|verify} messages.
         * @function encode
         * @memberof limbo.LimboStateReply
         * @static
         * @param {limbo.ILimboStateReply} message LimboStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LimboStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.limbo.HistoryResult.encode(message.results[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified LimboStateReply message, length delimited. Does not implicitly {@link limbo.LimboStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof limbo.LimboStateReply
         * @static
         * @param {limbo.ILimboStateReply} message LimboStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LimboStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LimboStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof limbo.LimboStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {limbo.LimboStateReply} LimboStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LimboStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.limbo.LimboStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 2:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.limbo.HistoryResult.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LimboStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof limbo.LimboStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {limbo.LimboStateReply} LimboStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LimboStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LimboStateReply message.
         * @function verify
         * @memberof limbo.LimboStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LimboStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.limbo.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a LimboStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof limbo.LimboStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {limbo.LimboStateReply} LimboStateReply
         */
        LimboStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.limbo.LimboStateReply)
                return object;
            var message = new $root.limbo.LimboStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".limbo.LimboStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".limbo.LimboStateReply.results: object expected");
                    message.results[i] = $root.limbo.HistoryResult.fromObject(object.results[i]);
                }
            }
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a LimboStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof limbo.LimboStateReply
         * @static
         * @param {limbo.LimboStateReply} message LimboStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LimboStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.results = [];
            if (options.defaults) {
                object.params = "";
                object.fairness = "";
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.limbo.HistoryResult.toObject(message.results[j], options);
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this LimboStateReply to JSON.
         * @function toJSON
         * @memberof limbo.LimboStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LimboStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LimboStateReply;
    })();

    limbo.LimboResultReq = (function() {

        /**
         * Properties of a LimboResultReq.
         * @memberof limbo
         * @interface ILimboResultReq
         * @property {number|null} [currencyType] LimboResultReq currencyType
         * @property {number|Long|null} [betAmount] LimboResultReq betAmount
         * @property {number|null} [cashOut] LimboResultReq cashOut
         */

        /**
         * Constructs a new LimboResultReq.
         * @memberof limbo
         * @classdesc Represents a LimboResultReq.
         * @implements ILimboResultReq
         * @constructor
         * @param {limbo.ILimboResultReq=} [properties] Properties to set
         */
        function LimboResultReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LimboResultReq currencyType.
         * @member {number} currencyType
         * @memberof limbo.LimboResultReq
         * @instance
         */
        LimboResultReq.prototype.currencyType = 0;

        /**
         * LimboResultReq betAmount.
         * @member {number|Long} betAmount
         * @memberof limbo.LimboResultReq
         * @instance
         */
        LimboResultReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LimboResultReq cashOut.
         * @member {number} cashOut
         * @memberof limbo.LimboResultReq
         * @instance
         */
        LimboResultReq.prototype.cashOut = 0;

        /**
         * Creates a new LimboResultReq instance using the specified properties.
         * @function create
         * @memberof limbo.LimboResultReq
         * @static
         * @param {limbo.ILimboResultReq=} [properties] Properties to set
         * @returns {limbo.LimboResultReq} LimboResultReq instance
         */
        LimboResultReq.create = function create(properties) {
            return new LimboResultReq(properties);
        };

        /**
         * Encodes the specified LimboResultReq message. Does not implicitly {@link limbo.LimboResultReq.verify|verify} messages.
         * @function encode
         * @memberof limbo.LimboResultReq
         * @static
         * @param {limbo.ILimboResultReq} message LimboResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LimboResultReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            if (message.cashOut != null && Object.hasOwnProperty.call(message, "cashOut"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.cashOut);
            return writer;
        };

        /**
         * Encodes the specified LimboResultReq message, length delimited. Does not implicitly {@link limbo.LimboResultReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof limbo.LimboResultReq
         * @static
         * @param {limbo.ILimboResultReq} message LimboResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LimboResultReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LimboResultReq message from the specified reader or buffer.
         * @function decode
         * @memberof limbo.LimboResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {limbo.LimboResultReq} LimboResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LimboResultReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.limbo.LimboResultReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                case 3:
                    message.cashOut = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LimboResultReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof limbo.LimboResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {limbo.LimboResultReq} LimboResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LimboResultReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LimboResultReq message.
         * @function verify
         * @memberof limbo.LimboResultReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LimboResultReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.cashOut != null && message.hasOwnProperty("cashOut"))
                if (!$util.isInteger(message.cashOut))
                    return "cashOut: integer expected";
            return null;
        };

        /**
         * Creates a LimboResultReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof limbo.LimboResultReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {limbo.LimboResultReq} LimboResultReq
         */
        LimboResultReq.fromObject = function fromObject(object) {
            if (object instanceof $root.limbo.LimboResultReq)
                return object;
            var message = new $root.limbo.LimboResultReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.cashOut != null)
                message.cashOut = object.cashOut | 0;
            return message;
        };

        /**
         * Creates a plain object from a LimboResultReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof limbo.LimboResultReq
         * @static
         * @param {limbo.LimboResultReq} message LimboResultReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LimboResultReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.cashOut = 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.cashOut != null && message.hasOwnProperty("cashOut"))
                object.cashOut = message.cashOut;
            return object;
        };

        /**
         * Converts this LimboResultReq to JSON.
         * @function toJSON
         * @memberof limbo.LimboResultReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LimboResultReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LimboResultReq;
    })();

    limbo.LimboResultReply = (function() {

        /**
         * Properties of a LimboResultReply.
         * @memberof limbo
         * @interface ILimboResultReply
         * @property {number|null} [result] LimboResultReply result
         * @property {number|null} [currencyType] LimboResultReply currencyType
         * @property {number|Long|null} [winAmount] LimboResultReply winAmount
         * @property {number|Long|null} [betAmount] LimboResultReply betAmount
         * @property {string|null} [gameCode] LimboResultReply gameCode
         * @property {string|null} [fairness] LimboResultReply fairness
         */

        /**
         * Constructs a new LimboResultReply.
         * @memberof limbo
         * @classdesc Represents a LimboResultReply.
         * @implements ILimboResultReply
         * @constructor
         * @param {limbo.ILimboResultReply=} [properties] Properties to set
         */
        function LimboResultReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LimboResultReply result.
         * @member {number} result
         * @memberof limbo.LimboResultReply
         * @instance
         */
        LimboResultReply.prototype.result = 0;

        /**
         * LimboResultReply currencyType.
         * @member {number} currencyType
         * @memberof limbo.LimboResultReply
         * @instance
         */
        LimboResultReply.prototype.currencyType = 0;

        /**
         * LimboResultReply winAmount.
         * @member {number|Long} winAmount
         * @memberof limbo.LimboResultReply
         * @instance
         */
        LimboResultReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LimboResultReply betAmount.
         * @member {number|Long} betAmount
         * @memberof limbo.LimboResultReply
         * @instance
         */
        LimboResultReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * LimboResultReply gameCode.
         * @member {string} gameCode
         * @memberof limbo.LimboResultReply
         * @instance
         */
        LimboResultReply.prototype.gameCode = "";

        /**
         * LimboResultReply fairness.
         * @member {string} fairness
         * @memberof limbo.LimboResultReply
         * @instance
         */
        LimboResultReply.prototype.fairness = "";

        /**
         * Creates a new LimboResultReply instance using the specified properties.
         * @function create
         * @memberof limbo.LimboResultReply
         * @static
         * @param {limbo.ILimboResultReply=} [properties] Properties to set
         * @returns {limbo.LimboResultReply} LimboResultReply instance
         */
        LimboResultReply.create = function create(properties) {
            return new LimboResultReply(properties);
        };

        /**
         * Encodes the specified LimboResultReply message. Does not implicitly {@link limbo.LimboResultReply.verify|verify} messages.
         * @function encode
         * @memberof limbo.LimboResultReply
         * @static
         * @param {limbo.ILimboResultReply} message LimboResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LimboResultReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.winAmount);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.betAmount);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.gameCode);
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified LimboResultReply message, length delimited. Does not implicitly {@link limbo.LimboResultReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof limbo.LimboResultReply
         * @static
         * @param {limbo.ILimboResultReply} message LimboResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LimboResultReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LimboResultReply message from the specified reader or buffer.
         * @function decode
         * @memberof limbo.LimboResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {limbo.LimboResultReply} LimboResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LimboResultReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.limbo.LimboResultReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.winAmount = reader.int64();
                    break;
                case 4:
                    message.betAmount = reader.int64();
                    break;
                case 5:
                    message.gameCode = reader.string();
                    break;
                case 6:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LimboResultReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof limbo.LimboResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {limbo.LimboResultReply} LimboResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LimboResultReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LimboResultReply message.
         * @function verify
         * @memberof limbo.LimboResultReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LimboResultReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a LimboResultReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof limbo.LimboResultReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {limbo.LimboResultReply} LimboResultReply
         */
        LimboResultReply.fromObject = function fromObject(object) {
            if (object instanceof $root.limbo.LimboResultReply)
                return object;
            var message = new $root.limbo.LimboResultReply();
            if (object.result != null)
                message.result = object.result | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a LimboResultReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof limbo.LimboResultReply
         * @static
         * @param {limbo.LimboResultReply} message LimboResultReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LimboResultReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.result = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.gameCode = "";
                object.fairness = "";
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this LimboResultReply to JSON.
         * @function toJSON
         * @memberof limbo.LimboResultReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LimboResultReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LimboResultReply;
    })();

    limbo.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof limbo
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         * @property {boolean|null} [win] HistoryResult win
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof limbo
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {limbo.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof limbo.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof limbo.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * HistoryResult win.
         * @member {boolean} win
         * @memberof limbo.HistoryResult
         * @instance
         */
        HistoryResult.prototype.win = false;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof limbo.HistoryResult
         * @static
         * @param {limbo.IHistoryResult=} [properties] Properties to set
         * @returns {limbo.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link limbo.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof limbo.HistoryResult
         * @static
         * @param {limbo.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            if (message.win != null && Object.hasOwnProperty.call(message, "win"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.win);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link limbo.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof limbo.HistoryResult
         * @static
         * @param {limbo.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof limbo.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {limbo.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.limbo.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                case 3:
                    message.win = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof limbo.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {limbo.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof limbo.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            if (message.win != null && message.hasOwnProperty("win"))
                if (typeof message.win !== "boolean")
                    return "win: boolean expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof limbo.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {limbo.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.limbo.HistoryResult)
                return object;
            var message = new $root.limbo.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            if (object.win != null)
                message.win = Boolean(object.win);
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof limbo.HistoryResult
         * @static
         * @param {limbo.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
                object.win = false;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.win != null && message.hasOwnProperty("win"))
                object.win = message.win;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof limbo.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return limbo;
})();

$root.hilo = (function() {

    /**
     * Namespace hilo.
     * @exports hilo
     * @namespace
     */
    var hilo = {};

    /**
     * OPERATE_TYPE enum.
     * @name hilo.OPERATE_TYPE
     * @enum {number}
     * @property {number} OT_NONE=0 OT_NONE value
     * @property {number} OT_UP=1 OT_UP value
     * @property {number} OT_DOWN=2 OT_DOWN value
     * @property {number} OT_SKIP=3 OT_SKIP value
     */
    hilo.OPERATE_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OT_NONE"] = 0;
        values[valuesById[1] = "OT_UP"] = 1;
        values[valuesById[2] = "OT_DOWN"] = 2;
        values[valuesById[3] = "OT_SKIP"] = 3;
        return values;
    })();

    hilo.HiloStateReply = (function() {

        /**
         * Properties of a HiloStateReply.
         * @memberof hilo
         * @interface IHiloStateReply
         * @property {string|null} [params] HiloStateReply params
         * @property {string|null} [payout] HiloStateReply payout
         * @property {Array.<number>|null} [cards] HiloStateReply cards
         * @property {number|null} [coefficient] HiloStateReply coefficient
         * @property {number|null} [currencyType] HiloStateReply currencyType
         * @property {number|Long|null} [betAmount] HiloStateReply betAmount
         * @property {string|null} [gameCode] HiloStateReply gameCode
         * @property {Array.<hilo.IHistoryResult>|null} [results] HiloStateReply results
         * @property {string|null} [fairness] HiloStateReply fairness
         */

        /**
         * Constructs a new HiloStateReply.
         * @memberof hilo
         * @classdesc Represents a HiloStateReply.
         * @implements IHiloStateReply
         * @constructor
         * @param {hilo.IHiloStateReply=} [properties] Properties to set
         */
        function HiloStateReply(properties) {
            this.cards = [];
            this.results = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HiloStateReply params.
         * @member {string} params
         * @memberof hilo.HiloStateReply
         * @instance
         */
        HiloStateReply.prototype.params = "";

        /**
         * HiloStateReply payout.
         * @member {string} payout
         * @memberof hilo.HiloStateReply
         * @instance
         */
        HiloStateReply.prototype.payout = "";

        /**
         * HiloStateReply cards.
         * @member {Array.<number>} cards
         * @memberof hilo.HiloStateReply
         * @instance
         */
        HiloStateReply.prototype.cards = $util.emptyArray;

        /**
         * HiloStateReply coefficient.
         * @member {number} coefficient
         * @memberof hilo.HiloStateReply
         * @instance
         */
        HiloStateReply.prototype.coefficient = 0;

        /**
         * HiloStateReply currencyType.
         * @member {number} currencyType
         * @memberof hilo.HiloStateReply
         * @instance
         */
        HiloStateReply.prototype.currencyType = 0;

        /**
         * HiloStateReply betAmount.
         * @member {number|Long} betAmount
         * @memberof hilo.HiloStateReply
         * @instance
         */
        HiloStateReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HiloStateReply gameCode.
         * @member {string} gameCode
         * @memberof hilo.HiloStateReply
         * @instance
         */
        HiloStateReply.prototype.gameCode = "";

        /**
         * HiloStateReply results.
         * @member {Array.<hilo.IHistoryResult>} results
         * @memberof hilo.HiloStateReply
         * @instance
         */
        HiloStateReply.prototype.results = $util.emptyArray;

        /**
         * HiloStateReply fairness.
         * @member {string} fairness
         * @memberof hilo.HiloStateReply
         * @instance
         */
        HiloStateReply.prototype.fairness = "";

        /**
         * Creates a new HiloStateReply instance using the specified properties.
         * @function create
         * @memberof hilo.HiloStateReply
         * @static
         * @param {hilo.IHiloStateReply=} [properties] Properties to set
         * @returns {hilo.HiloStateReply} HiloStateReply instance
         */
        HiloStateReply.create = function create(properties) {
            return new HiloStateReply(properties);
        };

        /**
         * Encodes the specified HiloStateReply message. Does not implicitly {@link hilo.HiloStateReply.verify|verify} messages.
         * @function encode
         * @memberof hilo.HiloStateReply
         * @static
         * @param {hilo.IHiloStateReply} message HiloStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.payout);
            if (message.cards != null && message.cards.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.cards.length; ++i)
                    writer.int32(message.cards[i]);
                writer.ldelim();
            }
            if (message.coefficient != null && Object.hasOwnProperty.call(message, "coefficient"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.coefficient);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.betAmount);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.gameCode);
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.hilo.HistoryResult.encode(message.results[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified HiloStateReply message, length delimited. Does not implicitly {@link hilo.HiloStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hilo.HiloStateReply
         * @static
         * @param {hilo.IHiloStateReply} message HiloStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HiloStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof hilo.HiloStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hilo.HiloStateReply} HiloStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.hilo.HiloStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 2:
                    message.payout = reader.string();
                    break;
                case 3:
                    if (!(message.cards && message.cards.length))
                        message.cards = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.cards.push(reader.int32());
                    } else
                        message.cards.push(reader.int32());
                    break;
                case 4:
                    message.coefficient = reader.int32();
                    break;
                case 5:
                    message.currencyType = reader.int32();
                    break;
                case 6:
                    message.betAmount = reader.int64();
                    break;
                case 7:
                    message.gameCode = reader.string();
                    break;
                case 8:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.hilo.HistoryResult.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HiloStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hilo.HiloStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hilo.HiloStateReply} HiloStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HiloStateReply message.
         * @function verify
         * @memberof hilo.HiloStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HiloStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isString(message.payout))
                    return "payout: string expected";
            if (message.cards != null && message.hasOwnProperty("cards")) {
                if (!Array.isArray(message.cards))
                    return "cards: array expected";
                for (var i = 0; i < message.cards.length; ++i)
                    if (!$util.isInteger(message.cards[i]))
                        return "cards: integer[] expected";
            }
            if (message.coefficient != null && message.hasOwnProperty("coefficient"))
                if (!$util.isInteger(message.coefficient))
                    return "coefficient: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.hilo.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a HiloStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hilo.HiloStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hilo.HiloStateReply} HiloStateReply
         */
        HiloStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.hilo.HiloStateReply)
                return object;
            var message = new $root.hilo.HiloStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.payout != null)
                message.payout = String(object.payout);
            if (object.cards) {
                if (!Array.isArray(object.cards))
                    throw TypeError(".hilo.HiloStateReply.cards: array expected");
                message.cards = [];
                for (var i = 0; i < object.cards.length; ++i)
                    message.cards[i] = object.cards[i] | 0;
            }
            if (object.coefficient != null)
                message.coefficient = object.coefficient | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".hilo.HiloStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".hilo.HiloStateReply.results: object expected");
                    message.results[i] = $root.hilo.HistoryResult.fromObject(object.results[i]);
                }
            }
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a HiloStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hilo.HiloStateReply
         * @static
         * @param {hilo.HiloStateReply} message HiloStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HiloStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.cards = [];
                object.results = [];
            }
            if (options.defaults) {
                object.params = "";
                object.payout = "";
                object.coefficient = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.gameCode = "";
                object.fairness = "";
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            if (message.cards && message.cards.length) {
                object.cards = [];
                for (var j = 0; j < message.cards.length; ++j)
                    object.cards[j] = message.cards[j];
            }
            if (message.coefficient != null && message.hasOwnProperty("coefficient"))
                object.coefficient = message.coefficient;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.hilo.HistoryResult.toObject(message.results[j], options);
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this HiloStateReply to JSON.
         * @function toJSON
         * @memberof hilo.HiloStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HiloStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HiloStateReply;
    })();

    hilo.HiloBetReq = (function() {

        /**
         * Properties of a HiloBetReq.
         * @memberof hilo
         * @interface IHiloBetReq
         * @property {number|null} [currencyType] HiloBetReq currencyType
         * @property {number|Long|null} [betAmount] HiloBetReq betAmount
         */

        /**
         * Constructs a new HiloBetReq.
         * @memberof hilo
         * @classdesc Represents a HiloBetReq.
         * @implements IHiloBetReq
         * @constructor
         * @param {hilo.IHiloBetReq=} [properties] Properties to set
         */
        function HiloBetReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HiloBetReq currencyType.
         * @member {number} currencyType
         * @memberof hilo.HiloBetReq
         * @instance
         */
        HiloBetReq.prototype.currencyType = 0;

        /**
         * HiloBetReq betAmount.
         * @member {number|Long} betAmount
         * @memberof hilo.HiloBetReq
         * @instance
         */
        HiloBetReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new HiloBetReq instance using the specified properties.
         * @function create
         * @memberof hilo.HiloBetReq
         * @static
         * @param {hilo.IHiloBetReq=} [properties] Properties to set
         * @returns {hilo.HiloBetReq} HiloBetReq instance
         */
        HiloBetReq.create = function create(properties) {
            return new HiloBetReq(properties);
        };

        /**
         * Encodes the specified HiloBetReq message. Does not implicitly {@link hilo.HiloBetReq.verify|verify} messages.
         * @function encode
         * @memberof hilo.HiloBetReq
         * @static
         * @param {hilo.IHiloBetReq} message HiloBetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloBetReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            return writer;
        };

        /**
         * Encodes the specified HiloBetReq message, length delimited. Does not implicitly {@link hilo.HiloBetReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hilo.HiloBetReq
         * @static
         * @param {hilo.IHiloBetReq} message HiloBetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloBetReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HiloBetReq message from the specified reader or buffer.
         * @function decode
         * @memberof hilo.HiloBetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hilo.HiloBetReq} HiloBetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloBetReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.hilo.HiloBetReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HiloBetReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hilo.HiloBetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hilo.HiloBetReq} HiloBetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloBetReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HiloBetReq message.
         * @function verify
         * @memberof hilo.HiloBetReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HiloBetReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            return null;
        };

        /**
         * Creates a HiloBetReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hilo.HiloBetReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hilo.HiloBetReq} HiloBetReq
         */
        HiloBetReq.fromObject = function fromObject(object) {
            if (object instanceof $root.hilo.HiloBetReq)
                return object;
            var message = new $root.hilo.HiloBetReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a HiloBetReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hilo.HiloBetReq
         * @static
         * @param {hilo.HiloBetReq} message HiloBetReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HiloBetReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            return object;
        };

        /**
         * Converts this HiloBetReq to JSON.
         * @function toJSON
         * @memberof hilo.HiloBetReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HiloBetReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HiloBetReq;
    })();

    hilo.HiloBetReply = (function() {

        /**
         * Properties of a HiloBetReply.
         * @memberof hilo
         * @interface IHiloBetReply
         * @property {number|null} [currencyType] HiloBetReply currencyType
         * @property {number|Long|null} [betAmount] HiloBetReply betAmount
         * @property {number|null} [card] HiloBetReply card
         * @property {number|null} [coefficient] HiloBetReply coefficient
         * @property {string|null} [gameCode] HiloBetReply gameCode
         * @property {string|null} [fairness] HiloBetReply fairness
         */

        /**
         * Constructs a new HiloBetReply.
         * @memberof hilo
         * @classdesc Represents a HiloBetReply.
         * @implements IHiloBetReply
         * @constructor
         * @param {hilo.IHiloBetReply=} [properties] Properties to set
         */
        function HiloBetReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HiloBetReply currencyType.
         * @member {number} currencyType
         * @memberof hilo.HiloBetReply
         * @instance
         */
        HiloBetReply.prototype.currencyType = 0;

        /**
         * HiloBetReply betAmount.
         * @member {number|Long} betAmount
         * @memberof hilo.HiloBetReply
         * @instance
         */
        HiloBetReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HiloBetReply card.
         * @member {number} card
         * @memberof hilo.HiloBetReply
         * @instance
         */
        HiloBetReply.prototype.card = 0;

        /**
         * HiloBetReply coefficient.
         * @member {number} coefficient
         * @memberof hilo.HiloBetReply
         * @instance
         */
        HiloBetReply.prototype.coefficient = 0;

        /**
         * HiloBetReply gameCode.
         * @member {string} gameCode
         * @memberof hilo.HiloBetReply
         * @instance
         */
        HiloBetReply.prototype.gameCode = "";

        /**
         * HiloBetReply fairness.
         * @member {string} fairness
         * @memberof hilo.HiloBetReply
         * @instance
         */
        HiloBetReply.prototype.fairness = "";

        /**
         * Creates a new HiloBetReply instance using the specified properties.
         * @function create
         * @memberof hilo.HiloBetReply
         * @static
         * @param {hilo.IHiloBetReply=} [properties] Properties to set
         * @returns {hilo.HiloBetReply} HiloBetReply instance
         */
        HiloBetReply.create = function create(properties) {
            return new HiloBetReply(properties);
        };

        /**
         * Encodes the specified HiloBetReply message. Does not implicitly {@link hilo.HiloBetReply.verify|verify} messages.
         * @function encode
         * @memberof hilo.HiloBetReply
         * @static
         * @param {hilo.IHiloBetReply} message HiloBetReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloBetReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            if (message.card != null && Object.hasOwnProperty.call(message, "card"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.card);
            if (message.coefficient != null && Object.hasOwnProperty.call(message, "coefficient"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.coefficient);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.gameCode);
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified HiloBetReply message, length delimited. Does not implicitly {@link hilo.HiloBetReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hilo.HiloBetReply
         * @static
         * @param {hilo.IHiloBetReply} message HiloBetReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloBetReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HiloBetReply message from the specified reader or buffer.
         * @function decode
         * @memberof hilo.HiloBetReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hilo.HiloBetReply} HiloBetReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloBetReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.hilo.HiloBetReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                case 3:
                    message.card = reader.int32();
                    break;
                case 4:
                    message.coefficient = reader.int32();
                    break;
                case 5:
                    message.gameCode = reader.string();
                    break;
                case 6:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HiloBetReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hilo.HiloBetReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hilo.HiloBetReply} HiloBetReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloBetReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HiloBetReply message.
         * @function verify
         * @memberof hilo.HiloBetReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HiloBetReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.card != null && message.hasOwnProperty("card"))
                if (!$util.isInteger(message.card))
                    return "card: integer expected";
            if (message.coefficient != null && message.hasOwnProperty("coefficient"))
                if (!$util.isInteger(message.coefficient))
                    return "coefficient: integer expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a HiloBetReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hilo.HiloBetReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hilo.HiloBetReply} HiloBetReply
         */
        HiloBetReply.fromObject = function fromObject(object) {
            if (object instanceof $root.hilo.HiloBetReply)
                return object;
            var message = new $root.hilo.HiloBetReply();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.card != null)
                message.card = object.card | 0;
            if (object.coefficient != null)
                message.coefficient = object.coefficient | 0;
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a HiloBetReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hilo.HiloBetReply
         * @static
         * @param {hilo.HiloBetReply} message HiloBetReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HiloBetReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.card = 0;
                object.coefficient = 0;
                object.gameCode = "";
                object.fairness = "";
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.card != null && message.hasOwnProperty("card"))
                object.card = message.card;
            if (message.coefficient != null && message.hasOwnProperty("coefficient"))
                object.coefficient = message.coefficient;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this HiloBetReply to JSON.
         * @function toJSON
         * @memberof hilo.HiloBetReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HiloBetReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HiloBetReply;
    })();

    hilo.HiloGuessReq = (function() {

        /**
         * Properties of a HiloGuessReq.
         * @memberof hilo
         * @interface IHiloGuessReq
         * @property {number|null} [operate] HiloGuessReq operate
         */

        /**
         * Constructs a new HiloGuessReq.
         * @memberof hilo
         * @classdesc Represents a HiloGuessReq.
         * @implements IHiloGuessReq
         * @constructor
         * @param {hilo.IHiloGuessReq=} [properties] Properties to set
         */
        function HiloGuessReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HiloGuessReq operate.
         * @member {number} operate
         * @memberof hilo.HiloGuessReq
         * @instance
         */
        HiloGuessReq.prototype.operate = 0;

        /**
         * Creates a new HiloGuessReq instance using the specified properties.
         * @function create
         * @memberof hilo.HiloGuessReq
         * @static
         * @param {hilo.IHiloGuessReq=} [properties] Properties to set
         * @returns {hilo.HiloGuessReq} HiloGuessReq instance
         */
        HiloGuessReq.create = function create(properties) {
            return new HiloGuessReq(properties);
        };

        /**
         * Encodes the specified HiloGuessReq message. Does not implicitly {@link hilo.HiloGuessReq.verify|verify} messages.
         * @function encode
         * @memberof hilo.HiloGuessReq
         * @static
         * @param {hilo.IHiloGuessReq} message HiloGuessReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloGuessReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.operate != null && Object.hasOwnProperty.call(message, "operate"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.operate);
            return writer;
        };

        /**
         * Encodes the specified HiloGuessReq message, length delimited. Does not implicitly {@link hilo.HiloGuessReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hilo.HiloGuessReq
         * @static
         * @param {hilo.IHiloGuessReq} message HiloGuessReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloGuessReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HiloGuessReq message from the specified reader or buffer.
         * @function decode
         * @memberof hilo.HiloGuessReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hilo.HiloGuessReq} HiloGuessReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloGuessReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.hilo.HiloGuessReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.operate = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HiloGuessReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hilo.HiloGuessReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hilo.HiloGuessReq} HiloGuessReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloGuessReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HiloGuessReq message.
         * @function verify
         * @memberof hilo.HiloGuessReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HiloGuessReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.operate != null && message.hasOwnProperty("operate"))
                if (!$util.isInteger(message.operate))
                    return "operate: integer expected";
            return null;
        };

        /**
         * Creates a HiloGuessReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hilo.HiloGuessReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hilo.HiloGuessReq} HiloGuessReq
         */
        HiloGuessReq.fromObject = function fromObject(object) {
            if (object instanceof $root.hilo.HiloGuessReq)
                return object;
            var message = new $root.hilo.HiloGuessReq();
            if (object.operate != null)
                message.operate = object.operate | 0;
            return message;
        };

        /**
         * Creates a plain object from a HiloGuessReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hilo.HiloGuessReq
         * @static
         * @param {hilo.HiloGuessReq} message HiloGuessReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HiloGuessReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.operate = 0;
            if (message.operate != null && message.hasOwnProperty("operate"))
                object.operate = message.operate;
            return object;
        };

        /**
         * Converts this HiloGuessReq to JSON.
         * @function toJSON
         * @memberof hilo.HiloGuessReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HiloGuessReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HiloGuessReq;
    })();

    hilo.HiloGuessReply = (function() {

        /**
         * Properties of a HiloGuessReply.
         * @memberof hilo
         * @interface IHiloGuessReply
         * @property {number|null} [card] HiloGuessReply card
         * @property {number|null} [coefficient] HiloGuessReply coefficient
         * @property {number|Long|null} [betAmount] HiloGuessReply betAmount
         * @property {number|null} [operate] HiloGuessReply operate
         * @property {boolean|null} [result] HiloGuessReply result
         */

        /**
         * Constructs a new HiloGuessReply.
         * @memberof hilo
         * @classdesc Represents a HiloGuessReply.
         * @implements IHiloGuessReply
         * @constructor
         * @param {hilo.IHiloGuessReply=} [properties] Properties to set
         */
        function HiloGuessReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HiloGuessReply card.
         * @member {number} card
         * @memberof hilo.HiloGuessReply
         * @instance
         */
        HiloGuessReply.prototype.card = 0;

        /**
         * HiloGuessReply coefficient.
         * @member {number} coefficient
         * @memberof hilo.HiloGuessReply
         * @instance
         */
        HiloGuessReply.prototype.coefficient = 0;

        /**
         * HiloGuessReply betAmount.
         * @member {number|Long} betAmount
         * @memberof hilo.HiloGuessReply
         * @instance
         */
        HiloGuessReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HiloGuessReply operate.
         * @member {number} operate
         * @memberof hilo.HiloGuessReply
         * @instance
         */
        HiloGuessReply.prototype.operate = 0;

        /**
         * HiloGuessReply result.
         * @member {boolean} result
         * @memberof hilo.HiloGuessReply
         * @instance
         */
        HiloGuessReply.prototype.result = false;

        /**
         * Creates a new HiloGuessReply instance using the specified properties.
         * @function create
         * @memberof hilo.HiloGuessReply
         * @static
         * @param {hilo.IHiloGuessReply=} [properties] Properties to set
         * @returns {hilo.HiloGuessReply} HiloGuessReply instance
         */
        HiloGuessReply.create = function create(properties) {
            return new HiloGuessReply(properties);
        };

        /**
         * Encodes the specified HiloGuessReply message. Does not implicitly {@link hilo.HiloGuessReply.verify|verify} messages.
         * @function encode
         * @memberof hilo.HiloGuessReply
         * @static
         * @param {hilo.IHiloGuessReply} message HiloGuessReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloGuessReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.card != null && Object.hasOwnProperty.call(message, "card"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.card);
            if (message.coefficient != null && Object.hasOwnProperty.call(message, "coefficient"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.coefficient);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.betAmount);
            if (message.operate != null && Object.hasOwnProperty.call(message, "operate"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.operate);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.result);
            return writer;
        };

        /**
         * Encodes the specified HiloGuessReply message, length delimited. Does not implicitly {@link hilo.HiloGuessReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hilo.HiloGuessReply
         * @static
         * @param {hilo.IHiloGuessReply} message HiloGuessReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloGuessReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HiloGuessReply message from the specified reader or buffer.
         * @function decode
         * @memberof hilo.HiloGuessReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hilo.HiloGuessReply} HiloGuessReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloGuessReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.hilo.HiloGuessReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.card = reader.int32();
                    break;
                case 2:
                    message.coefficient = reader.int32();
                    break;
                case 3:
                    message.betAmount = reader.int64();
                    break;
                case 4:
                    message.operate = reader.int32();
                    break;
                case 5:
                    message.result = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HiloGuessReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hilo.HiloGuessReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hilo.HiloGuessReply} HiloGuessReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloGuessReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HiloGuessReply message.
         * @function verify
         * @memberof hilo.HiloGuessReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HiloGuessReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.card != null && message.hasOwnProperty("card"))
                if (!$util.isInteger(message.card))
                    return "card: integer expected";
            if (message.coefficient != null && message.hasOwnProperty("coefficient"))
                if (!$util.isInteger(message.coefficient))
                    return "coefficient: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.operate != null && message.hasOwnProperty("operate"))
                if (!$util.isInteger(message.operate))
                    return "operate: integer expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (typeof message.result !== "boolean")
                    return "result: boolean expected";
            return null;
        };

        /**
         * Creates a HiloGuessReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hilo.HiloGuessReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hilo.HiloGuessReply} HiloGuessReply
         */
        HiloGuessReply.fromObject = function fromObject(object) {
            if (object instanceof $root.hilo.HiloGuessReply)
                return object;
            var message = new $root.hilo.HiloGuessReply();
            if (object.card != null)
                message.card = object.card | 0;
            if (object.coefficient != null)
                message.coefficient = object.coefficient | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.operate != null)
                message.operate = object.operate | 0;
            if (object.result != null)
                message.result = Boolean(object.result);
            return message;
        };

        /**
         * Creates a plain object from a HiloGuessReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hilo.HiloGuessReply
         * @static
         * @param {hilo.HiloGuessReply} message HiloGuessReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HiloGuessReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.card = 0;
                object.coefficient = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.operate = 0;
                object.result = false;
            }
            if (message.card != null && message.hasOwnProperty("card"))
                object.card = message.card;
            if (message.coefficient != null && message.hasOwnProperty("coefficient"))
                object.coefficient = message.coefficient;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.operate != null && message.hasOwnProperty("operate"))
                object.operate = message.operate;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HiloGuessReply to JSON.
         * @function toJSON
         * @memberof hilo.HiloGuessReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HiloGuessReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HiloGuessReply;
    })();

    hilo.HiloSkipReply = (function() {

        /**
         * Properties of a HiloSkipReply.
         * @memberof hilo
         * @interface IHiloSkipReply
         * @property {number|null} [card] HiloSkipReply card
         * @property {number|null} [coefficient] HiloSkipReply coefficient
         */

        /**
         * Constructs a new HiloSkipReply.
         * @memberof hilo
         * @classdesc Represents a HiloSkipReply.
         * @implements IHiloSkipReply
         * @constructor
         * @param {hilo.IHiloSkipReply=} [properties] Properties to set
         */
        function HiloSkipReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HiloSkipReply card.
         * @member {number} card
         * @memberof hilo.HiloSkipReply
         * @instance
         */
        HiloSkipReply.prototype.card = 0;

        /**
         * HiloSkipReply coefficient.
         * @member {number} coefficient
         * @memberof hilo.HiloSkipReply
         * @instance
         */
        HiloSkipReply.prototype.coefficient = 0;

        /**
         * Creates a new HiloSkipReply instance using the specified properties.
         * @function create
         * @memberof hilo.HiloSkipReply
         * @static
         * @param {hilo.IHiloSkipReply=} [properties] Properties to set
         * @returns {hilo.HiloSkipReply} HiloSkipReply instance
         */
        HiloSkipReply.create = function create(properties) {
            return new HiloSkipReply(properties);
        };

        /**
         * Encodes the specified HiloSkipReply message. Does not implicitly {@link hilo.HiloSkipReply.verify|verify} messages.
         * @function encode
         * @memberof hilo.HiloSkipReply
         * @static
         * @param {hilo.IHiloSkipReply} message HiloSkipReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloSkipReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.card != null && Object.hasOwnProperty.call(message, "card"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.card);
            if (message.coefficient != null && Object.hasOwnProperty.call(message, "coefficient"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.coefficient);
            return writer;
        };

        /**
         * Encodes the specified HiloSkipReply message, length delimited. Does not implicitly {@link hilo.HiloSkipReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hilo.HiloSkipReply
         * @static
         * @param {hilo.IHiloSkipReply} message HiloSkipReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloSkipReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HiloSkipReply message from the specified reader or buffer.
         * @function decode
         * @memberof hilo.HiloSkipReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hilo.HiloSkipReply} HiloSkipReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloSkipReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.hilo.HiloSkipReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.card = reader.int32();
                    break;
                case 2:
                    message.coefficient = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HiloSkipReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hilo.HiloSkipReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hilo.HiloSkipReply} HiloSkipReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloSkipReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HiloSkipReply message.
         * @function verify
         * @memberof hilo.HiloSkipReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HiloSkipReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.card != null && message.hasOwnProperty("card"))
                if (!$util.isInteger(message.card))
                    return "card: integer expected";
            if (message.coefficient != null && message.hasOwnProperty("coefficient"))
                if (!$util.isInteger(message.coefficient))
                    return "coefficient: integer expected";
            return null;
        };

        /**
         * Creates a HiloSkipReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hilo.HiloSkipReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hilo.HiloSkipReply} HiloSkipReply
         */
        HiloSkipReply.fromObject = function fromObject(object) {
            if (object instanceof $root.hilo.HiloSkipReply)
                return object;
            var message = new $root.hilo.HiloSkipReply();
            if (object.card != null)
                message.card = object.card | 0;
            if (object.coefficient != null)
                message.coefficient = object.coefficient | 0;
            return message;
        };

        /**
         * Creates a plain object from a HiloSkipReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hilo.HiloSkipReply
         * @static
         * @param {hilo.HiloSkipReply} message HiloSkipReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HiloSkipReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.card = 0;
                object.coefficient = 0;
            }
            if (message.card != null && message.hasOwnProperty("card"))
                object.card = message.card;
            if (message.coefficient != null && message.hasOwnProperty("coefficient"))
                object.coefficient = message.coefficient;
            return object;
        };

        /**
         * Converts this HiloSkipReply to JSON.
         * @function toJSON
         * @memberof hilo.HiloSkipReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HiloSkipReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HiloSkipReply;
    })();

    hilo.HiloResultReply = (function() {

        /**
         * Properties of a HiloResultReply.
         * @memberof hilo
         * @interface IHiloResultReply
         * @property {number|null} [currencyType] HiloResultReply currencyType
         * @property {number|Long|null} [winAmount] HiloResultReply winAmount
         * @property {number|Long|null} [betAmount] HiloResultReply betAmount
         * @property {number|null} [coefficient] HiloResultReply coefficient
         */

        /**
         * Constructs a new HiloResultReply.
         * @memberof hilo
         * @classdesc Represents a HiloResultReply.
         * @implements IHiloResultReply
         * @constructor
         * @param {hilo.IHiloResultReply=} [properties] Properties to set
         */
        function HiloResultReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HiloResultReply currencyType.
         * @member {number} currencyType
         * @memberof hilo.HiloResultReply
         * @instance
         */
        HiloResultReply.prototype.currencyType = 0;

        /**
         * HiloResultReply winAmount.
         * @member {number|Long} winAmount
         * @memberof hilo.HiloResultReply
         * @instance
         */
        HiloResultReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HiloResultReply betAmount.
         * @member {number|Long} betAmount
         * @memberof hilo.HiloResultReply
         * @instance
         */
        HiloResultReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HiloResultReply coefficient.
         * @member {number} coefficient
         * @memberof hilo.HiloResultReply
         * @instance
         */
        HiloResultReply.prototype.coefficient = 0;

        /**
         * Creates a new HiloResultReply instance using the specified properties.
         * @function create
         * @memberof hilo.HiloResultReply
         * @static
         * @param {hilo.IHiloResultReply=} [properties] Properties to set
         * @returns {hilo.HiloResultReply} HiloResultReply instance
         */
        HiloResultReply.create = function create(properties) {
            return new HiloResultReply(properties);
        };

        /**
         * Encodes the specified HiloResultReply message. Does not implicitly {@link hilo.HiloResultReply.verify|verify} messages.
         * @function encode
         * @memberof hilo.HiloResultReply
         * @static
         * @param {hilo.IHiloResultReply} message HiloResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloResultReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.winAmount);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.betAmount);
            if (message.coefficient != null && Object.hasOwnProperty.call(message, "coefficient"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.coefficient);
            return writer;
        };

        /**
         * Encodes the specified HiloResultReply message, length delimited. Does not implicitly {@link hilo.HiloResultReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hilo.HiloResultReply
         * @static
         * @param {hilo.IHiloResultReply} message HiloResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HiloResultReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HiloResultReply message from the specified reader or buffer.
         * @function decode
         * @memberof hilo.HiloResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hilo.HiloResultReply} HiloResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloResultReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.hilo.HiloResultReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.winAmount = reader.int64();
                    break;
                case 3:
                    message.betAmount = reader.int64();
                    break;
                case 4:
                    message.coefficient = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HiloResultReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hilo.HiloResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hilo.HiloResultReply} HiloResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HiloResultReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HiloResultReply message.
         * @function verify
         * @memberof hilo.HiloResultReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HiloResultReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.coefficient != null && message.hasOwnProperty("coefficient"))
                if (!$util.isInteger(message.coefficient))
                    return "coefficient: integer expected";
            return null;
        };

        /**
         * Creates a HiloResultReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hilo.HiloResultReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hilo.HiloResultReply} HiloResultReply
         */
        HiloResultReply.fromObject = function fromObject(object) {
            if (object instanceof $root.hilo.HiloResultReply)
                return object;
            var message = new $root.hilo.HiloResultReply();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.coefficient != null)
                message.coefficient = object.coefficient | 0;
            return message;
        };

        /**
         * Creates a plain object from a HiloResultReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hilo.HiloResultReply
         * @static
         * @param {hilo.HiloResultReply} message HiloResultReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HiloResultReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.coefficient = 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.coefficient != null && message.hasOwnProperty("coefficient"))
                object.coefficient = message.coefficient;
            return object;
        };

        /**
         * Converts this HiloResultReply to JSON.
         * @function toJSON
         * @memberof hilo.HiloResultReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HiloResultReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HiloResultReply;
    })();

    hilo.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof hilo
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof hilo
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {hilo.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof hilo.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof hilo.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof hilo.HistoryResult
         * @static
         * @param {hilo.IHistoryResult=} [properties] Properties to set
         * @returns {hilo.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link hilo.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof hilo.HistoryResult
         * @static
         * @param {hilo.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link hilo.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof hilo.HistoryResult
         * @static
         * @param {hilo.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof hilo.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {hilo.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.hilo.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof hilo.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {hilo.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof hilo.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof hilo.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {hilo.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.hilo.HistoryResult)
                return object;
            var message = new $root.hilo.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof hilo.HistoryResult
         * @static
         * @param {hilo.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof hilo.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return hilo;
})();

$root.stairs = (function() {

    /**
     * Namespace stairs.
     * @exports stairs
     * @namespace
     */
    var stairs = {};

    stairs.Stones = (function() {

        /**
         * Properties of a Stones.
         * @memberof stairs
         * @interface IStones
         * @property {Array.<number>|null} [nums] Stones nums
         */

        /**
         * Constructs a new Stones.
         * @memberof stairs
         * @classdesc Represents a Stones.
         * @implements IStones
         * @constructor
         * @param {stairs.IStones=} [properties] Properties to set
         */
        function Stones(properties) {
            this.nums = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Stones nums.
         * @member {Array.<number>} nums
         * @memberof stairs.Stones
         * @instance
         */
        Stones.prototype.nums = $util.emptyArray;

        /**
         * Creates a new Stones instance using the specified properties.
         * @function create
         * @memberof stairs.Stones
         * @static
         * @param {stairs.IStones=} [properties] Properties to set
         * @returns {stairs.Stones} Stones instance
         */
        Stones.create = function create(properties) {
            return new Stones(properties);
        };

        /**
         * Encodes the specified Stones message. Does not implicitly {@link stairs.Stones.verify|verify} messages.
         * @function encode
         * @memberof stairs.Stones
         * @static
         * @param {stairs.IStones} message Stones message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Stones.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nums != null && message.nums.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.nums.length; ++i)
                    writer.int32(message.nums[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified Stones message, length delimited. Does not implicitly {@link stairs.Stones.verify|verify} messages.
         * @function encodeDelimited
         * @memberof stairs.Stones
         * @static
         * @param {stairs.IStones} message Stones message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Stones.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Stones message from the specified reader or buffer.
         * @function decode
         * @memberof stairs.Stones
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {stairs.Stones} Stones
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Stones.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.stairs.Stones();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.nums && message.nums.length))
                        message.nums = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.nums.push(reader.int32());
                    } else
                        message.nums.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Stones message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof stairs.Stones
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {stairs.Stones} Stones
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Stones.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Stones message.
         * @function verify
         * @memberof stairs.Stones
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Stones.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nums != null && message.hasOwnProperty("nums")) {
                if (!Array.isArray(message.nums))
                    return "nums: array expected";
                for (var i = 0; i < message.nums.length; ++i)
                    if (!$util.isInteger(message.nums[i]))
                        return "nums: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a Stones message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof stairs.Stones
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {stairs.Stones} Stones
         */
        Stones.fromObject = function fromObject(object) {
            if (object instanceof $root.stairs.Stones)
                return object;
            var message = new $root.stairs.Stones();
            if (object.nums) {
                if (!Array.isArray(object.nums))
                    throw TypeError(".stairs.Stones.nums: array expected");
                message.nums = [];
                for (var i = 0; i < object.nums.length; ++i)
                    message.nums[i] = object.nums[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a Stones message. Also converts values to other types if specified.
         * @function toObject
         * @memberof stairs.Stones
         * @static
         * @param {stairs.Stones} message Stones
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Stones.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.nums = [];
            if (message.nums && message.nums.length) {
                object.nums = [];
                for (var j = 0; j < message.nums.length; ++j)
                    object.nums[j] = message.nums[j];
            }
            return object;
        };

        /**
         * Converts this Stones to JSON.
         * @function toJSON
         * @memberof stairs.Stones
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Stones.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Stones;
    })();

    stairs.StairsStateReply = (function() {

        /**
         * Properties of a StairsStateReply.
         * @memberof stairs
         * @interface IStairsStateReply
         * @property {string|null} [params] StairsStateReply params
         * @property {string|null} [payout] StairsStateReply payout
         * @property {Array.<stairs.IStones>|null} [stones] StairsStateReply stones
         * @property {Array.<number>|null} [stairs] StairsStateReply stairs
         * @property {number|null} [stoneAmount] StairsStateReply stoneAmount
         * @property {number|null} [currencyType] StairsStateReply currencyType
         * @property {number|Long|null} [betAmount] StairsStateReply betAmount
         * @property {Array.<stairs.IHistoryResult>|null} [results] StairsStateReply results
         * @property {string|null} [fairness] StairsStateReply fairness
         */

        /**
         * Constructs a new StairsStateReply.
         * @memberof stairs
         * @classdesc Represents a StairsStateReply.
         * @implements IStairsStateReply
         * @constructor
         * @param {stairs.IStairsStateReply=} [properties] Properties to set
         */
        function StairsStateReply(properties) {
            this.stones = [];
            this.stairs = [];
            this.results = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StairsStateReply params.
         * @member {string} params
         * @memberof stairs.StairsStateReply
         * @instance
         */
        StairsStateReply.prototype.params = "";

        /**
         * StairsStateReply payout.
         * @member {string} payout
         * @memberof stairs.StairsStateReply
         * @instance
         */
        StairsStateReply.prototype.payout = "";

        /**
         * StairsStateReply stones.
         * @member {Array.<stairs.IStones>} stones
         * @memberof stairs.StairsStateReply
         * @instance
         */
        StairsStateReply.prototype.stones = $util.emptyArray;

        /**
         * StairsStateReply stairs.
         * @member {Array.<number>} stairs
         * @memberof stairs.StairsStateReply
         * @instance
         */
        StairsStateReply.prototype.stairs = $util.emptyArray;

        /**
         * StairsStateReply stoneAmount.
         * @member {number} stoneAmount
         * @memberof stairs.StairsStateReply
         * @instance
         */
        StairsStateReply.prototype.stoneAmount = 0;

        /**
         * StairsStateReply currencyType.
         * @member {number} currencyType
         * @memberof stairs.StairsStateReply
         * @instance
         */
        StairsStateReply.prototype.currencyType = 0;

        /**
         * StairsStateReply betAmount.
         * @member {number|Long} betAmount
         * @memberof stairs.StairsStateReply
         * @instance
         */
        StairsStateReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * StairsStateReply results.
         * @member {Array.<stairs.IHistoryResult>} results
         * @memberof stairs.StairsStateReply
         * @instance
         */
        StairsStateReply.prototype.results = $util.emptyArray;

        /**
         * StairsStateReply fairness.
         * @member {string} fairness
         * @memberof stairs.StairsStateReply
         * @instance
         */
        StairsStateReply.prototype.fairness = "";

        /**
         * Creates a new StairsStateReply instance using the specified properties.
         * @function create
         * @memberof stairs.StairsStateReply
         * @static
         * @param {stairs.IStairsStateReply=} [properties] Properties to set
         * @returns {stairs.StairsStateReply} StairsStateReply instance
         */
        StairsStateReply.create = function create(properties) {
            return new StairsStateReply(properties);
        };

        /**
         * Encodes the specified StairsStateReply message. Does not implicitly {@link stairs.StairsStateReply.verify|verify} messages.
         * @function encode
         * @memberof stairs.StairsStateReply
         * @static
         * @param {stairs.IStairsStateReply} message StairsStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StairsStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.payout);
            if (message.stones != null && message.stones.length)
                for (var i = 0; i < message.stones.length; ++i)
                    $root.stairs.Stones.encode(message.stones[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.stairs != null && message.stairs.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.stairs.length; ++i)
                    writer.int32(message.stairs[i]);
                writer.ldelim();
            }
            if (message.stoneAmount != null && Object.hasOwnProperty.call(message, "stoneAmount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.stoneAmount);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.betAmount);
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.stairs.HistoryResult.encode(message.results[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified StairsStateReply message, length delimited. Does not implicitly {@link stairs.StairsStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof stairs.StairsStateReply
         * @static
         * @param {stairs.IStairsStateReply} message StairsStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StairsStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StairsStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof stairs.StairsStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {stairs.StairsStateReply} StairsStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StairsStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.stairs.StairsStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 2:
                    message.payout = reader.string();
                    break;
                case 3:
                    if (!(message.stones && message.stones.length))
                        message.stones = [];
                    message.stones.push($root.stairs.Stones.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.stairs && message.stairs.length))
                        message.stairs = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.stairs.push(reader.int32());
                    } else
                        message.stairs.push(reader.int32());
                    break;
                case 6:
                    message.stoneAmount = reader.int32();
                    break;
                case 7:
                    message.currencyType = reader.int32();
                    break;
                case 8:
                    message.betAmount = reader.int64();
                    break;
                case 9:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.stairs.HistoryResult.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StairsStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof stairs.StairsStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {stairs.StairsStateReply} StairsStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StairsStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StairsStateReply message.
         * @function verify
         * @memberof stairs.StairsStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StairsStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isString(message.payout))
                    return "payout: string expected";
            if (message.stones != null && message.hasOwnProperty("stones")) {
                if (!Array.isArray(message.stones))
                    return "stones: array expected";
                for (var i = 0; i < message.stones.length; ++i) {
                    var error = $root.stairs.Stones.verify(message.stones[i]);
                    if (error)
                        return "stones." + error;
                }
            }
            if (message.stairs != null && message.hasOwnProperty("stairs")) {
                if (!Array.isArray(message.stairs))
                    return "stairs: array expected";
                for (var i = 0; i < message.stairs.length; ++i)
                    if (!$util.isInteger(message.stairs[i]))
                        return "stairs: integer[] expected";
            }
            if (message.stoneAmount != null && message.hasOwnProperty("stoneAmount"))
                if (!$util.isInteger(message.stoneAmount))
                    return "stoneAmount: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.stairs.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a StairsStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof stairs.StairsStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {stairs.StairsStateReply} StairsStateReply
         */
        StairsStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.stairs.StairsStateReply)
                return object;
            var message = new $root.stairs.StairsStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.payout != null)
                message.payout = String(object.payout);
            if (object.stones) {
                if (!Array.isArray(object.stones))
                    throw TypeError(".stairs.StairsStateReply.stones: array expected");
                message.stones = [];
                for (var i = 0; i < object.stones.length; ++i) {
                    if (typeof object.stones[i] !== "object")
                        throw TypeError(".stairs.StairsStateReply.stones: object expected");
                    message.stones[i] = $root.stairs.Stones.fromObject(object.stones[i]);
                }
            }
            if (object.stairs) {
                if (!Array.isArray(object.stairs))
                    throw TypeError(".stairs.StairsStateReply.stairs: array expected");
                message.stairs = [];
                for (var i = 0; i < object.stairs.length; ++i)
                    message.stairs[i] = object.stairs[i] | 0;
            }
            if (object.stoneAmount != null)
                message.stoneAmount = object.stoneAmount | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".stairs.StairsStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".stairs.StairsStateReply.results: object expected");
                    message.results[i] = $root.stairs.HistoryResult.fromObject(object.results[i]);
                }
            }
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a StairsStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof stairs.StairsStateReply
         * @static
         * @param {stairs.StairsStateReply} message StairsStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StairsStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.stones = [];
                object.stairs = [];
                object.results = [];
            }
            if (options.defaults) {
                object.params = "";
                object.payout = "";
                object.stoneAmount = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.fairness = "";
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            if (message.stones && message.stones.length) {
                object.stones = [];
                for (var j = 0; j < message.stones.length; ++j)
                    object.stones[j] = $root.stairs.Stones.toObject(message.stones[j], options);
            }
            if (message.stairs && message.stairs.length) {
                object.stairs = [];
                for (var j = 0; j < message.stairs.length; ++j)
                    object.stairs[j] = message.stairs[j];
            }
            if (message.stoneAmount != null && message.hasOwnProperty("stoneAmount"))
                object.stoneAmount = message.stoneAmount;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.stairs.HistoryResult.toObject(message.results[j], options);
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this StairsStateReply to JSON.
         * @function toJSON
         * @memberof stairs.StairsStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StairsStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StairsStateReply;
    })();

    stairs.StairsBetReq = (function() {

        /**
         * Properties of a StairsBetReq.
         * @memberof stairs
         * @interface IStairsBetReq
         * @property {number|null} [stoneAmount] StairsBetReq stoneAmount
         * @property {number|null} [currencyType] StairsBetReq currencyType
         * @property {number|Long|null} [betAmount] StairsBetReq betAmount
         */

        /**
         * Constructs a new StairsBetReq.
         * @memberof stairs
         * @classdesc Represents a StairsBetReq.
         * @implements IStairsBetReq
         * @constructor
         * @param {stairs.IStairsBetReq=} [properties] Properties to set
         */
        function StairsBetReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StairsBetReq stoneAmount.
         * @member {number} stoneAmount
         * @memberof stairs.StairsBetReq
         * @instance
         */
        StairsBetReq.prototype.stoneAmount = 0;

        /**
         * StairsBetReq currencyType.
         * @member {number} currencyType
         * @memberof stairs.StairsBetReq
         * @instance
         */
        StairsBetReq.prototype.currencyType = 0;

        /**
         * StairsBetReq betAmount.
         * @member {number|Long} betAmount
         * @memberof stairs.StairsBetReq
         * @instance
         */
        StairsBetReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new StairsBetReq instance using the specified properties.
         * @function create
         * @memberof stairs.StairsBetReq
         * @static
         * @param {stairs.IStairsBetReq=} [properties] Properties to set
         * @returns {stairs.StairsBetReq} StairsBetReq instance
         */
        StairsBetReq.create = function create(properties) {
            return new StairsBetReq(properties);
        };

        /**
         * Encodes the specified StairsBetReq message. Does not implicitly {@link stairs.StairsBetReq.verify|verify} messages.
         * @function encode
         * @memberof stairs.StairsBetReq
         * @static
         * @param {stairs.IStairsBetReq} message StairsBetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StairsBetReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.stoneAmount != null && Object.hasOwnProperty.call(message, "stoneAmount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.stoneAmount);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.betAmount);
            return writer;
        };

        /**
         * Encodes the specified StairsBetReq message, length delimited. Does not implicitly {@link stairs.StairsBetReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof stairs.StairsBetReq
         * @static
         * @param {stairs.IStairsBetReq} message StairsBetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StairsBetReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StairsBetReq message from the specified reader or buffer.
         * @function decode
         * @memberof stairs.StairsBetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {stairs.StairsBetReq} StairsBetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StairsBetReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.stairs.StairsBetReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.stoneAmount = reader.int32();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.betAmount = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StairsBetReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof stairs.StairsBetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {stairs.StairsBetReq} StairsBetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StairsBetReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StairsBetReq message.
         * @function verify
         * @memberof stairs.StairsBetReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StairsBetReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.stoneAmount != null && message.hasOwnProperty("stoneAmount"))
                if (!$util.isInteger(message.stoneAmount))
                    return "stoneAmount: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            return null;
        };

        /**
         * Creates a StairsBetReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof stairs.StairsBetReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {stairs.StairsBetReq} StairsBetReq
         */
        StairsBetReq.fromObject = function fromObject(object) {
            if (object instanceof $root.stairs.StairsBetReq)
                return object;
            var message = new $root.stairs.StairsBetReq();
            if (object.stoneAmount != null)
                message.stoneAmount = object.stoneAmount | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a StairsBetReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof stairs.StairsBetReq
         * @static
         * @param {stairs.StairsBetReq} message StairsBetReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StairsBetReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.stoneAmount = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
            }
            if (message.stoneAmount != null && message.hasOwnProperty("stoneAmount"))
                object.stoneAmount = message.stoneAmount;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            return object;
        };

        /**
         * Converts this StairsBetReq to JSON.
         * @function toJSON
         * @memberof stairs.StairsBetReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StairsBetReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StairsBetReq;
    })();

    stairs.StairsBetReply = (function() {

        /**
         * Properties of a StairsBetReply.
         * @memberof stairs
         * @interface IStairsBetReply
         * @property {number|null} [stoneAmount] StairsBetReply stoneAmount
         * @property {number|null} [currencyType] StairsBetReply currencyType
         * @property {number|Long|null} [betAmount] StairsBetReply betAmount
         * @property {string|null} [fairness] StairsBetReply fairness
         */

        /**
         * Constructs a new StairsBetReply.
         * @memberof stairs
         * @classdesc Represents a StairsBetReply.
         * @implements IStairsBetReply
         * @constructor
         * @param {stairs.IStairsBetReply=} [properties] Properties to set
         */
        function StairsBetReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StairsBetReply stoneAmount.
         * @member {number} stoneAmount
         * @memberof stairs.StairsBetReply
         * @instance
         */
        StairsBetReply.prototype.stoneAmount = 0;

        /**
         * StairsBetReply currencyType.
         * @member {number} currencyType
         * @memberof stairs.StairsBetReply
         * @instance
         */
        StairsBetReply.prototype.currencyType = 0;

        /**
         * StairsBetReply betAmount.
         * @member {number|Long} betAmount
         * @memberof stairs.StairsBetReply
         * @instance
         */
        StairsBetReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * StairsBetReply fairness.
         * @member {string} fairness
         * @memberof stairs.StairsBetReply
         * @instance
         */
        StairsBetReply.prototype.fairness = "";

        /**
         * Creates a new StairsBetReply instance using the specified properties.
         * @function create
         * @memberof stairs.StairsBetReply
         * @static
         * @param {stairs.IStairsBetReply=} [properties] Properties to set
         * @returns {stairs.StairsBetReply} StairsBetReply instance
         */
        StairsBetReply.create = function create(properties) {
            return new StairsBetReply(properties);
        };

        /**
         * Encodes the specified StairsBetReply message. Does not implicitly {@link stairs.StairsBetReply.verify|verify} messages.
         * @function encode
         * @memberof stairs.StairsBetReply
         * @static
         * @param {stairs.IStairsBetReply} message StairsBetReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StairsBetReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.stoneAmount != null && Object.hasOwnProperty.call(message, "stoneAmount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.stoneAmount);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.betAmount);
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified StairsBetReply message, length delimited. Does not implicitly {@link stairs.StairsBetReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof stairs.StairsBetReply
         * @static
         * @param {stairs.IStairsBetReply} message StairsBetReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StairsBetReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StairsBetReply message from the specified reader or buffer.
         * @function decode
         * @memberof stairs.StairsBetReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {stairs.StairsBetReply} StairsBetReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StairsBetReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.stairs.StairsBetReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.stoneAmount = reader.int32();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.betAmount = reader.int64();
                    break;
                case 4:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StairsBetReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof stairs.StairsBetReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {stairs.StairsBetReply} StairsBetReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StairsBetReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StairsBetReply message.
         * @function verify
         * @memberof stairs.StairsBetReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StairsBetReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.stoneAmount != null && message.hasOwnProperty("stoneAmount"))
                if (!$util.isInteger(message.stoneAmount))
                    return "stoneAmount: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a StairsBetReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof stairs.StairsBetReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {stairs.StairsBetReply} StairsBetReply
         */
        StairsBetReply.fromObject = function fromObject(object) {
            if (object instanceof $root.stairs.StairsBetReply)
                return object;
            var message = new $root.stairs.StairsBetReply();
            if (object.stoneAmount != null)
                message.stoneAmount = object.stoneAmount | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a StairsBetReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof stairs.StairsBetReply
         * @static
         * @param {stairs.StairsBetReply} message StairsBetReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StairsBetReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.stoneAmount = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.fairness = "";
            }
            if (message.stoneAmount != null && message.hasOwnProperty("stoneAmount"))
                object.stoneAmount = message.stoneAmount;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this StairsBetReply to JSON.
         * @function toJSON
         * @memberof stairs.StairsBetReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StairsBetReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StairsBetReply;
    })();

    stairs.StairsPickReq = (function() {

        /**
         * Properties of a StairsPickReq.
         * @memberof stairs
         * @interface IStairsPickReq
         * @property {number|null} [layer] StairsPickReq layer
         * @property {number|null} [stair] StairsPickReq stair
         */

        /**
         * Constructs a new StairsPickReq.
         * @memberof stairs
         * @classdesc Represents a StairsPickReq.
         * @implements IStairsPickReq
         * @constructor
         * @param {stairs.IStairsPickReq=} [properties] Properties to set
         */
        function StairsPickReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StairsPickReq layer.
         * @member {number} layer
         * @memberof stairs.StairsPickReq
         * @instance
         */
        StairsPickReq.prototype.layer = 0;

        /**
         * StairsPickReq stair.
         * @member {number} stair
         * @memberof stairs.StairsPickReq
         * @instance
         */
        StairsPickReq.prototype.stair = 0;

        /**
         * Creates a new StairsPickReq instance using the specified properties.
         * @function create
         * @memberof stairs.StairsPickReq
         * @static
         * @param {stairs.IStairsPickReq=} [properties] Properties to set
         * @returns {stairs.StairsPickReq} StairsPickReq instance
         */
        StairsPickReq.create = function create(properties) {
            return new StairsPickReq(properties);
        };

        /**
         * Encodes the specified StairsPickReq message. Does not implicitly {@link stairs.StairsPickReq.verify|verify} messages.
         * @function encode
         * @memberof stairs.StairsPickReq
         * @static
         * @param {stairs.IStairsPickReq} message StairsPickReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StairsPickReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.layer != null && Object.hasOwnProperty.call(message, "layer"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.layer);
            if (message.stair != null && Object.hasOwnProperty.call(message, "stair"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.stair);
            return writer;
        };

        /**
         * Encodes the specified StairsPickReq message, length delimited. Does not implicitly {@link stairs.StairsPickReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof stairs.StairsPickReq
         * @static
         * @param {stairs.IStairsPickReq} message StairsPickReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StairsPickReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StairsPickReq message from the specified reader or buffer.
         * @function decode
         * @memberof stairs.StairsPickReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {stairs.StairsPickReq} StairsPickReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StairsPickReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.stairs.StairsPickReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.layer = reader.int32();
                    break;
                case 2:
                    message.stair = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StairsPickReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof stairs.StairsPickReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {stairs.StairsPickReq} StairsPickReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StairsPickReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StairsPickReq message.
         * @function verify
         * @memberof stairs.StairsPickReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StairsPickReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.layer != null && message.hasOwnProperty("layer"))
                if (!$util.isInteger(message.layer))
                    return "layer: integer expected";
            if (message.stair != null && message.hasOwnProperty("stair"))
                if (!$util.isInteger(message.stair))
                    return "stair: integer expected";
            return null;
        };

        /**
         * Creates a StairsPickReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof stairs.StairsPickReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {stairs.StairsPickReq} StairsPickReq
         */
        StairsPickReq.fromObject = function fromObject(object) {
            if (object instanceof $root.stairs.StairsPickReq)
                return object;
            var message = new $root.stairs.StairsPickReq();
            if (object.layer != null)
                message.layer = object.layer | 0;
            if (object.stair != null)
                message.stair = object.stair | 0;
            return message;
        };

        /**
         * Creates a plain object from a StairsPickReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof stairs.StairsPickReq
         * @static
         * @param {stairs.StairsPickReq} message StairsPickReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StairsPickReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.layer = 0;
                object.stair = 0;
            }
            if (message.layer != null && message.hasOwnProperty("layer"))
                object.layer = message.layer;
            if (message.stair != null && message.hasOwnProperty("stair"))
                object.stair = message.stair;
            return object;
        };

        /**
         * Converts this StairsPickReq to JSON.
         * @function toJSON
         * @memberof stairs.StairsPickReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StairsPickReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StairsPickReq;
    })();

    stairs.StairsPickReply = (function() {

        /**
         * Properties of a StairsPickReply.
         * @memberof stairs
         * @interface IStairsPickReply
         * @property {number|null} [stair] StairsPickReply stair
         * @property {Array.<number>|null} [stones] StairsPickReply stones
         * @property {boolean|null} [alive] StairsPickReply alive
         * @property {number|null} [floor] StairsPickReply floor
         * @property {number|Long|null} [winAmount] StairsPickReply winAmount
         */

        /**
         * Constructs a new StairsPickReply.
         * @memberof stairs
         * @classdesc Represents a StairsPickReply.
         * @implements IStairsPickReply
         * @constructor
         * @param {stairs.IStairsPickReply=} [properties] Properties to set
         */
        function StairsPickReply(properties) {
            this.stones = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StairsPickReply stair.
         * @member {number} stair
         * @memberof stairs.StairsPickReply
         * @instance
         */
        StairsPickReply.prototype.stair = 0;

        /**
         * StairsPickReply stones.
         * @member {Array.<number>} stones
         * @memberof stairs.StairsPickReply
         * @instance
         */
        StairsPickReply.prototype.stones = $util.emptyArray;

        /**
         * StairsPickReply alive.
         * @member {boolean} alive
         * @memberof stairs.StairsPickReply
         * @instance
         */
        StairsPickReply.prototype.alive = false;

        /**
         * StairsPickReply floor.
         * @member {number} floor
         * @memberof stairs.StairsPickReply
         * @instance
         */
        StairsPickReply.prototype.floor = 0;

        /**
         * StairsPickReply winAmount.
         * @member {number|Long} winAmount
         * @memberof stairs.StairsPickReply
         * @instance
         */
        StairsPickReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new StairsPickReply instance using the specified properties.
         * @function create
         * @memberof stairs.StairsPickReply
         * @static
         * @param {stairs.IStairsPickReply=} [properties] Properties to set
         * @returns {stairs.StairsPickReply} StairsPickReply instance
         */
        StairsPickReply.create = function create(properties) {
            return new StairsPickReply(properties);
        };

        /**
         * Encodes the specified StairsPickReply message. Does not implicitly {@link stairs.StairsPickReply.verify|verify} messages.
         * @function encode
         * @memberof stairs.StairsPickReply
         * @static
         * @param {stairs.IStairsPickReply} message StairsPickReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StairsPickReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.stair != null && Object.hasOwnProperty.call(message, "stair"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.stair);
            if (message.stones != null && message.stones.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.stones.length; ++i)
                    writer.int32(message.stones[i]);
                writer.ldelim();
            }
            if (message.alive != null && Object.hasOwnProperty.call(message, "alive"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.alive);
            if (message.floor != null && Object.hasOwnProperty.call(message, "floor"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.floor);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.winAmount);
            return writer;
        };

        /**
         * Encodes the specified StairsPickReply message, length delimited. Does not implicitly {@link stairs.StairsPickReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof stairs.StairsPickReply
         * @static
         * @param {stairs.IStairsPickReply} message StairsPickReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StairsPickReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StairsPickReply message from the specified reader or buffer.
         * @function decode
         * @memberof stairs.StairsPickReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {stairs.StairsPickReply} StairsPickReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StairsPickReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.stairs.StairsPickReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.stair = reader.int32();
                    break;
                case 2:
                    if (!(message.stones && message.stones.length))
                        message.stones = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.stones.push(reader.int32());
                    } else
                        message.stones.push(reader.int32());
                    break;
                case 3:
                    message.alive = reader.bool();
                    break;
                case 4:
                    message.floor = reader.int32();
                    break;
                case 5:
                    message.winAmount = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StairsPickReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof stairs.StairsPickReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {stairs.StairsPickReply} StairsPickReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StairsPickReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StairsPickReply message.
         * @function verify
         * @memberof stairs.StairsPickReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StairsPickReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.stair != null && message.hasOwnProperty("stair"))
                if (!$util.isInteger(message.stair))
                    return "stair: integer expected";
            if (message.stones != null && message.hasOwnProperty("stones")) {
                if (!Array.isArray(message.stones))
                    return "stones: array expected";
                for (var i = 0; i < message.stones.length; ++i)
                    if (!$util.isInteger(message.stones[i]))
                        return "stones: integer[] expected";
            }
            if (message.alive != null && message.hasOwnProperty("alive"))
                if (typeof message.alive !== "boolean")
                    return "alive: boolean expected";
            if (message.floor != null && message.hasOwnProperty("floor"))
                if (!$util.isInteger(message.floor))
                    return "floor: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            return null;
        };

        /**
         * Creates a StairsPickReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof stairs.StairsPickReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {stairs.StairsPickReply} StairsPickReply
         */
        StairsPickReply.fromObject = function fromObject(object) {
            if (object instanceof $root.stairs.StairsPickReply)
                return object;
            var message = new $root.stairs.StairsPickReply();
            if (object.stair != null)
                message.stair = object.stair | 0;
            if (object.stones) {
                if (!Array.isArray(object.stones))
                    throw TypeError(".stairs.StairsPickReply.stones: array expected");
                message.stones = [];
                for (var i = 0; i < object.stones.length; ++i)
                    message.stones[i] = object.stones[i] | 0;
            }
            if (object.alive != null)
                message.alive = Boolean(object.alive);
            if (object.floor != null)
                message.floor = object.floor | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a StairsPickReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof stairs.StairsPickReply
         * @static
         * @param {stairs.StairsPickReply} message StairsPickReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StairsPickReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.stones = [];
            if (options.defaults) {
                object.stair = 0;
                object.alive = false;
                object.floor = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
            }
            if (message.stair != null && message.hasOwnProperty("stair"))
                object.stair = message.stair;
            if (message.stones && message.stones.length) {
                object.stones = [];
                for (var j = 0; j < message.stones.length; ++j)
                    object.stones[j] = message.stones[j];
            }
            if (message.alive != null && message.hasOwnProperty("alive"))
                object.alive = message.alive;
            if (message.floor != null && message.hasOwnProperty("floor"))
                object.floor = message.floor;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            return object;
        };

        /**
         * Converts this StairsPickReply to JSON.
         * @function toJSON
         * @memberof stairs.StairsPickReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StairsPickReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StairsPickReply;
    })();

    stairs.StairsResultReply = (function() {

        /**
         * Properties of a StairsResultReply.
         * @memberof stairs
         * @interface IStairsResultReply
         * @property {number|null} [currencyType] StairsResultReply currencyType
         * @property {number|Long|null} [winAmount] StairsResultReply winAmount
         * @property {number|Long|null} [betAmount] StairsResultReply betAmount
         * @property {number|null} [payout] StairsResultReply payout
         * @property {string|null} [gameCode] StairsResultReply gameCode
         */

        /**
         * Constructs a new StairsResultReply.
         * @memberof stairs
         * @classdesc Represents a StairsResultReply.
         * @implements IStairsResultReply
         * @constructor
         * @param {stairs.IStairsResultReply=} [properties] Properties to set
         */
        function StairsResultReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StairsResultReply currencyType.
         * @member {number} currencyType
         * @memberof stairs.StairsResultReply
         * @instance
         */
        StairsResultReply.prototype.currencyType = 0;

        /**
         * StairsResultReply winAmount.
         * @member {number|Long} winAmount
         * @memberof stairs.StairsResultReply
         * @instance
         */
        StairsResultReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * StairsResultReply betAmount.
         * @member {number|Long} betAmount
         * @memberof stairs.StairsResultReply
         * @instance
         */
        StairsResultReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * StairsResultReply payout.
         * @member {number} payout
         * @memberof stairs.StairsResultReply
         * @instance
         */
        StairsResultReply.prototype.payout = 0;

        /**
         * StairsResultReply gameCode.
         * @member {string} gameCode
         * @memberof stairs.StairsResultReply
         * @instance
         */
        StairsResultReply.prototype.gameCode = "";

        /**
         * Creates a new StairsResultReply instance using the specified properties.
         * @function create
         * @memberof stairs.StairsResultReply
         * @static
         * @param {stairs.IStairsResultReply=} [properties] Properties to set
         * @returns {stairs.StairsResultReply} StairsResultReply instance
         */
        StairsResultReply.create = function create(properties) {
            return new StairsResultReply(properties);
        };

        /**
         * Encodes the specified StairsResultReply message. Does not implicitly {@link stairs.StairsResultReply.verify|verify} messages.
         * @function encode
         * @memberof stairs.StairsResultReply
         * @static
         * @param {stairs.IStairsResultReply} message StairsResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StairsResultReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.winAmount);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.betAmount);
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.payout);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.gameCode);
            return writer;
        };

        /**
         * Encodes the specified StairsResultReply message, length delimited. Does not implicitly {@link stairs.StairsResultReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof stairs.StairsResultReply
         * @static
         * @param {stairs.IStairsResultReply} message StairsResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StairsResultReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StairsResultReply message from the specified reader or buffer.
         * @function decode
         * @memberof stairs.StairsResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {stairs.StairsResultReply} StairsResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StairsResultReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.stairs.StairsResultReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.winAmount = reader.int64();
                    break;
                case 3:
                    message.betAmount = reader.int64();
                    break;
                case 4:
                    message.payout = reader.int32();
                    break;
                case 5:
                    message.gameCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StairsResultReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof stairs.StairsResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {stairs.StairsResultReply} StairsResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StairsResultReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StairsResultReply message.
         * @function verify
         * @memberof stairs.StairsResultReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StairsResultReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isInteger(message.payout))
                    return "payout: integer expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            return null;
        };

        /**
         * Creates a StairsResultReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof stairs.StairsResultReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {stairs.StairsResultReply} StairsResultReply
         */
        StairsResultReply.fromObject = function fromObject(object) {
            if (object instanceof $root.stairs.StairsResultReply)
                return object;
            var message = new $root.stairs.StairsResultReply();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.payout != null)
                message.payout = object.payout | 0;
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            return message;
        };

        /**
         * Creates a plain object from a StairsResultReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof stairs.StairsResultReply
         * @static
         * @param {stairs.StairsResultReply} message StairsResultReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StairsResultReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.payout = 0;
                object.gameCode = "";
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            return object;
        };

        /**
         * Converts this StairsResultReply to JSON.
         * @function toJSON
         * @memberof stairs.StairsResultReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StairsResultReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StairsResultReply;
    })();

    stairs.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof stairs
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof stairs
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {stairs.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof stairs.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof stairs.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof stairs.HistoryResult
         * @static
         * @param {stairs.IHistoryResult=} [properties] Properties to set
         * @returns {stairs.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link stairs.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof stairs.HistoryResult
         * @static
         * @param {stairs.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link stairs.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof stairs.HistoryResult
         * @static
         * @param {stairs.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof stairs.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {stairs.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.stairs.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof stairs.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {stairs.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof stairs.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof stairs.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {stairs.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.stairs.HistoryResult)
                return object;
            var message = new $root.stairs.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof stairs.HistoryResult
         * @static
         * @param {stairs.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof stairs.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return stairs;
})();

$root.coinflip = (function() {

    /**
     * Namespace coinflip.
     * @exports coinflip
     * @namespace
     */
    var coinflip = {};

    coinflip.CoinflipStateReply = (function() {

        /**
         * Properties of a CoinflipStateReply.
         * @memberof coinflip
         * @interface ICoinflipStateReply
         * @property {string|null} [params] CoinflipStateReply params
         * @property {number|null} [payout] CoinflipStateReply payout
         * @property {Array.<number>|null} [coins] CoinflipStateReply coins
         * @property {Array.<coinflip.IHistoryResult>|null} [results] CoinflipStateReply results
         * @property {number|null} [currencyType] CoinflipStateReply currencyType
         * @property {number|Long|null} [betAmount] CoinflipStateReply betAmount
         * @property {string|null} [gameCode] CoinflipStateReply gameCode
         * @property {string|null} [fairness] CoinflipStateReply fairness
         */

        /**
         * Constructs a new CoinflipStateReply.
         * @memberof coinflip
         * @classdesc Represents a CoinflipStateReply.
         * @implements ICoinflipStateReply
         * @constructor
         * @param {coinflip.ICoinflipStateReply=} [properties] Properties to set
         */
        function CoinflipStateReply(properties) {
            this.coins = [];
            this.results = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoinflipStateReply params.
         * @member {string} params
         * @memberof coinflip.CoinflipStateReply
         * @instance
         */
        CoinflipStateReply.prototype.params = "";

        /**
         * CoinflipStateReply payout.
         * @member {number} payout
         * @memberof coinflip.CoinflipStateReply
         * @instance
         */
        CoinflipStateReply.prototype.payout = 0;

        /**
         * CoinflipStateReply coins.
         * @member {Array.<number>} coins
         * @memberof coinflip.CoinflipStateReply
         * @instance
         */
        CoinflipStateReply.prototype.coins = $util.emptyArray;

        /**
         * CoinflipStateReply results.
         * @member {Array.<coinflip.IHistoryResult>} results
         * @memberof coinflip.CoinflipStateReply
         * @instance
         */
        CoinflipStateReply.prototype.results = $util.emptyArray;

        /**
         * CoinflipStateReply currencyType.
         * @member {number} currencyType
         * @memberof coinflip.CoinflipStateReply
         * @instance
         */
        CoinflipStateReply.prototype.currencyType = 0;

        /**
         * CoinflipStateReply betAmount.
         * @member {number|Long} betAmount
         * @memberof coinflip.CoinflipStateReply
         * @instance
         */
        CoinflipStateReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CoinflipStateReply gameCode.
         * @member {string} gameCode
         * @memberof coinflip.CoinflipStateReply
         * @instance
         */
        CoinflipStateReply.prototype.gameCode = "";

        /**
         * CoinflipStateReply fairness.
         * @member {string} fairness
         * @memberof coinflip.CoinflipStateReply
         * @instance
         */
        CoinflipStateReply.prototype.fairness = "";

        /**
         * Creates a new CoinflipStateReply instance using the specified properties.
         * @function create
         * @memberof coinflip.CoinflipStateReply
         * @static
         * @param {coinflip.ICoinflipStateReply=} [properties] Properties to set
         * @returns {coinflip.CoinflipStateReply} CoinflipStateReply instance
         */
        CoinflipStateReply.create = function create(properties) {
            return new CoinflipStateReply(properties);
        };

        /**
         * Encodes the specified CoinflipStateReply message. Does not implicitly {@link coinflip.CoinflipStateReply.verify|verify} messages.
         * @function encode
         * @memberof coinflip.CoinflipStateReply
         * @static
         * @param {coinflip.ICoinflipStateReply} message CoinflipStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinflipStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.payout);
            if (message.coins != null && message.coins.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.coins.length; ++i)
                    writer.int32(message.coins[i]);
                writer.ldelim();
            }
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.coinflip.HistoryResult.encode(message.results[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.betAmount);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.gameCode);
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified CoinflipStateReply message, length delimited. Does not implicitly {@link coinflip.CoinflipStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof coinflip.CoinflipStateReply
         * @static
         * @param {coinflip.ICoinflipStateReply} message CoinflipStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinflipStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CoinflipStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof coinflip.CoinflipStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {coinflip.CoinflipStateReply} CoinflipStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinflipStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.coinflip.CoinflipStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 2:
                    message.payout = reader.int32();
                    break;
                case 3:
                    if (!(message.coins && message.coins.length))
                        message.coins = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.coins.push(reader.int32());
                    } else
                        message.coins.push(reader.int32());
                    break;
                case 4:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.coinflip.HistoryResult.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.currencyType = reader.int32();
                    break;
                case 6:
                    message.betAmount = reader.int64();
                    break;
                case 7:
                    message.gameCode = reader.string();
                    break;
                case 8:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CoinflipStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof coinflip.CoinflipStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {coinflip.CoinflipStateReply} CoinflipStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinflipStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CoinflipStateReply message.
         * @function verify
         * @memberof coinflip.CoinflipStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CoinflipStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isInteger(message.payout))
                    return "payout: integer expected";
            if (message.coins != null && message.hasOwnProperty("coins")) {
                if (!Array.isArray(message.coins))
                    return "coins: array expected";
                for (var i = 0; i < message.coins.length; ++i)
                    if (!$util.isInteger(message.coins[i]))
                        return "coins: integer[] expected";
            }
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.coinflip.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a CoinflipStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof coinflip.CoinflipStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {coinflip.CoinflipStateReply} CoinflipStateReply
         */
        CoinflipStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.coinflip.CoinflipStateReply)
                return object;
            var message = new $root.coinflip.CoinflipStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.payout != null)
                message.payout = object.payout | 0;
            if (object.coins) {
                if (!Array.isArray(object.coins))
                    throw TypeError(".coinflip.CoinflipStateReply.coins: array expected");
                message.coins = [];
                for (var i = 0; i < object.coins.length; ++i)
                    message.coins[i] = object.coins[i] | 0;
            }
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".coinflip.CoinflipStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".coinflip.CoinflipStateReply.results: object expected");
                    message.results[i] = $root.coinflip.HistoryResult.fromObject(object.results[i]);
                }
            }
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a CoinflipStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof coinflip.CoinflipStateReply
         * @static
         * @param {coinflip.CoinflipStateReply} message CoinflipStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CoinflipStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.coins = [];
                object.results = [];
            }
            if (options.defaults) {
                object.params = "";
                object.payout = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.gameCode = "";
                object.fairness = "";
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            if (message.coins && message.coins.length) {
                object.coins = [];
                for (var j = 0; j < message.coins.length; ++j)
                    object.coins[j] = message.coins[j];
            }
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.coinflip.HistoryResult.toObject(message.results[j], options);
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this CoinflipStateReply to JSON.
         * @function toJSON
         * @memberof coinflip.CoinflipStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CoinflipStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CoinflipStateReply;
    })();

    coinflip.CoinflipBetReq = (function() {

        /**
         * Properties of a CoinflipBetReq.
         * @memberof coinflip
         * @interface ICoinflipBetReq
         * @property {number|null} [currencyType] CoinflipBetReq currencyType
         * @property {number|Long|null} [betAmount] CoinflipBetReq betAmount
         */

        /**
         * Constructs a new CoinflipBetReq.
         * @memberof coinflip
         * @classdesc Represents a CoinflipBetReq.
         * @implements ICoinflipBetReq
         * @constructor
         * @param {coinflip.ICoinflipBetReq=} [properties] Properties to set
         */
        function CoinflipBetReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoinflipBetReq currencyType.
         * @member {number} currencyType
         * @memberof coinflip.CoinflipBetReq
         * @instance
         */
        CoinflipBetReq.prototype.currencyType = 0;

        /**
         * CoinflipBetReq betAmount.
         * @member {number|Long} betAmount
         * @memberof coinflip.CoinflipBetReq
         * @instance
         */
        CoinflipBetReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CoinflipBetReq instance using the specified properties.
         * @function create
         * @memberof coinflip.CoinflipBetReq
         * @static
         * @param {coinflip.ICoinflipBetReq=} [properties] Properties to set
         * @returns {coinflip.CoinflipBetReq} CoinflipBetReq instance
         */
        CoinflipBetReq.create = function create(properties) {
            return new CoinflipBetReq(properties);
        };

        /**
         * Encodes the specified CoinflipBetReq message. Does not implicitly {@link coinflip.CoinflipBetReq.verify|verify} messages.
         * @function encode
         * @memberof coinflip.CoinflipBetReq
         * @static
         * @param {coinflip.ICoinflipBetReq} message CoinflipBetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinflipBetReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            return writer;
        };

        /**
         * Encodes the specified CoinflipBetReq message, length delimited. Does not implicitly {@link coinflip.CoinflipBetReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof coinflip.CoinflipBetReq
         * @static
         * @param {coinflip.ICoinflipBetReq} message CoinflipBetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinflipBetReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CoinflipBetReq message from the specified reader or buffer.
         * @function decode
         * @memberof coinflip.CoinflipBetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {coinflip.CoinflipBetReq} CoinflipBetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinflipBetReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.coinflip.CoinflipBetReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CoinflipBetReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof coinflip.CoinflipBetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {coinflip.CoinflipBetReq} CoinflipBetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinflipBetReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CoinflipBetReq message.
         * @function verify
         * @memberof coinflip.CoinflipBetReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CoinflipBetReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            return null;
        };

        /**
         * Creates a CoinflipBetReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof coinflip.CoinflipBetReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {coinflip.CoinflipBetReq} CoinflipBetReq
         */
        CoinflipBetReq.fromObject = function fromObject(object) {
            if (object instanceof $root.coinflip.CoinflipBetReq)
                return object;
            var message = new $root.coinflip.CoinflipBetReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a CoinflipBetReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof coinflip.CoinflipBetReq
         * @static
         * @param {coinflip.CoinflipBetReq} message CoinflipBetReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CoinflipBetReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            return object;
        };

        /**
         * Converts this CoinflipBetReq to JSON.
         * @function toJSON
         * @memberof coinflip.CoinflipBetReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CoinflipBetReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CoinflipBetReq;
    })();

    coinflip.CoinflipBetReply = (function() {

        /**
         * Properties of a CoinflipBetReply.
         * @memberof coinflip
         * @interface ICoinflipBetReply
         * @property {number|null} [payout] CoinflipBetReply payout
         * @property {string|null} [gameCode] CoinflipBetReply gameCode
         * @property {string|null} [fairness] CoinflipBetReply fairness
         */

        /**
         * Constructs a new CoinflipBetReply.
         * @memberof coinflip
         * @classdesc Represents a CoinflipBetReply.
         * @implements ICoinflipBetReply
         * @constructor
         * @param {coinflip.ICoinflipBetReply=} [properties] Properties to set
         */
        function CoinflipBetReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoinflipBetReply payout.
         * @member {number} payout
         * @memberof coinflip.CoinflipBetReply
         * @instance
         */
        CoinflipBetReply.prototype.payout = 0;

        /**
         * CoinflipBetReply gameCode.
         * @member {string} gameCode
         * @memberof coinflip.CoinflipBetReply
         * @instance
         */
        CoinflipBetReply.prototype.gameCode = "";

        /**
         * CoinflipBetReply fairness.
         * @member {string} fairness
         * @memberof coinflip.CoinflipBetReply
         * @instance
         */
        CoinflipBetReply.prototype.fairness = "";

        /**
         * Creates a new CoinflipBetReply instance using the specified properties.
         * @function create
         * @memberof coinflip.CoinflipBetReply
         * @static
         * @param {coinflip.ICoinflipBetReply=} [properties] Properties to set
         * @returns {coinflip.CoinflipBetReply} CoinflipBetReply instance
         */
        CoinflipBetReply.create = function create(properties) {
            return new CoinflipBetReply(properties);
        };

        /**
         * Encodes the specified CoinflipBetReply message. Does not implicitly {@link coinflip.CoinflipBetReply.verify|verify} messages.
         * @function encode
         * @memberof coinflip.CoinflipBetReply
         * @static
         * @param {coinflip.ICoinflipBetReply} message CoinflipBetReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinflipBetReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.payout);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.gameCode);
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified CoinflipBetReply message, length delimited. Does not implicitly {@link coinflip.CoinflipBetReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof coinflip.CoinflipBetReply
         * @static
         * @param {coinflip.ICoinflipBetReply} message CoinflipBetReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinflipBetReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CoinflipBetReply message from the specified reader or buffer.
         * @function decode
         * @memberof coinflip.CoinflipBetReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {coinflip.CoinflipBetReply} CoinflipBetReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinflipBetReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.coinflip.CoinflipBetReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.payout = reader.int32();
                    break;
                case 2:
                    message.gameCode = reader.string();
                    break;
                case 3:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CoinflipBetReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof coinflip.CoinflipBetReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {coinflip.CoinflipBetReply} CoinflipBetReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinflipBetReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CoinflipBetReply message.
         * @function verify
         * @memberof coinflip.CoinflipBetReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CoinflipBetReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isInteger(message.payout))
                    return "payout: integer expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a CoinflipBetReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof coinflip.CoinflipBetReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {coinflip.CoinflipBetReply} CoinflipBetReply
         */
        CoinflipBetReply.fromObject = function fromObject(object) {
            if (object instanceof $root.coinflip.CoinflipBetReply)
                return object;
            var message = new $root.coinflip.CoinflipBetReply();
            if (object.payout != null)
                message.payout = object.payout | 0;
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a CoinflipBetReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof coinflip.CoinflipBetReply
         * @static
         * @param {coinflip.CoinflipBetReply} message CoinflipBetReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CoinflipBetReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.payout = 0;
                object.gameCode = "";
                object.fairness = "";
            }
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this CoinflipBetReply to JSON.
         * @function toJSON
         * @memberof coinflip.CoinflipBetReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CoinflipBetReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CoinflipBetReply;
    })();

    coinflip.CoinflipGuessReq = (function() {

        /**
         * Properties of a CoinflipGuessReq.
         * @memberof coinflip
         * @interface ICoinflipGuessReq
         * @property {number|null} [choice] CoinflipGuessReq choice
         */

        /**
         * Constructs a new CoinflipGuessReq.
         * @memberof coinflip
         * @classdesc Represents a CoinflipGuessReq.
         * @implements ICoinflipGuessReq
         * @constructor
         * @param {coinflip.ICoinflipGuessReq=} [properties] Properties to set
         */
        function CoinflipGuessReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoinflipGuessReq choice.
         * @member {number} choice
         * @memberof coinflip.CoinflipGuessReq
         * @instance
         */
        CoinflipGuessReq.prototype.choice = 0;

        /**
         * Creates a new CoinflipGuessReq instance using the specified properties.
         * @function create
         * @memberof coinflip.CoinflipGuessReq
         * @static
         * @param {coinflip.ICoinflipGuessReq=} [properties] Properties to set
         * @returns {coinflip.CoinflipGuessReq} CoinflipGuessReq instance
         */
        CoinflipGuessReq.create = function create(properties) {
            return new CoinflipGuessReq(properties);
        };

        /**
         * Encodes the specified CoinflipGuessReq message. Does not implicitly {@link coinflip.CoinflipGuessReq.verify|verify} messages.
         * @function encode
         * @memberof coinflip.CoinflipGuessReq
         * @static
         * @param {coinflip.ICoinflipGuessReq} message CoinflipGuessReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinflipGuessReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.choice != null && Object.hasOwnProperty.call(message, "choice"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.choice);
            return writer;
        };

        /**
         * Encodes the specified CoinflipGuessReq message, length delimited. Does not implicitly {@link coinflip.CoinflipGuessReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof coinflip.CoinflipGuessReq
         * @static
         * @param {coinflip.ICoinflipGuessReq} message CoinflipGuessReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinflipGuessReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CoinflipGuessReq message from the specified reader or buffer.
         * @function decode
         * @memberof coinflip.CoinflipGuessReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {coinflip.CoinflipGuessReq} CoinflipGuessReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinflipGuessReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.coinflip.CoinflipGuessReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.choice = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CoinflipGuessReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof coinflip.CoinflipGuessReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {coinflip.CoinflipGuessReq} CoinflipGuessReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinflipGuessReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CoinflipGuessReq message.
         * @function verify
         * @memberof coinflip.CoinflipGuessReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CoinflipGuessReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.choice != null && message.hasOwnProperty("choice"))
                if (!$util.isInteger(message.choice))
                    return "choice: integer expected";
            return null;
        };

        /**
         * Creates a CoinflipGuessReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof coinflip.CoinflipGuessReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {coinflip.CoinflipGuessReq} CoinflipGuessReq
         */
        CoinflipGuessReq.fromObject = function fromObject(object) {
            if (object instanceof $root.coinflip.CoinflipGuessReq)
                return object;
            var message = new $root.coinflip.CoinflipGuessReq();
            if (object.choice != null)
                message.choice = object.choice | 0;
            return message;
        };

        /**
         * Creates a plain object from a CoinflipGuessReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof coinflip.CoinflipGuessReq
         * @static
         * @param {coinflip.CoinflipGuessReq} message CoinflipGuessReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CoinflipGuessReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.choice = 0;
            if (message.choice != null && message.hasOwnProperty("choice"))
                object.choice = message.choice;
            return object;
        };

        /**
         * Converts this CoinflipGuessReq to JSON.
         * @function toJSON
         * @memberof coinflip.CoinflipGuessReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CoinflipGuessReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CoinflipGuessReq;
    })();

    coinflip.CoinflipGuessReply = (function() {

        /**
         * Properties of a CoinflipGuessReply.
         * @memberof coinflip
         * @interface ICoinflipGuessReply
         * @property {number|null} [coin] CoinflipGuessReply coin
         * @property {boolean|null} [right] CoinflipGuessReply right
         * @property {number|null} [payout] CoinflipGuessReply payout
         * @property {number|Long|null} [betAmount] CoinflipGuessReply betAmount
         */

        /**
         * Constructs a new CoinflipGuessReply.
         * @memberof coinflip
         * @classdesc Represents a CoinflipGuessReply.
         * @implements ICoinflipGuessReply
         * @constructor
         * @param {coinflip.ICoinflipGuessReply=} [properties] Properties to set
         */
        function CoinflipGuessReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoinflipGuessReply coin.
         * @member {number} coin
         * @memberof coinflip.CoinflipGuessReply
         * @instance
         */
        CoinflipGuessReply.prototype.coin = 0;

        /**
         * CoinflipGuessReply right.
         * @member {boolean} right
         * @memberof coinflip.CoinflipGuessReply
         * @instance
         */
        CoinflipGuessReply.prototype.right = false;

        /**
         * CoinflipGuessReply payout.
         * @member {number} payout
         * @memberof coinflip.CoinflipGuessReply
         * @instance
         */
        CoinflipGuessReply.prototype.payout = 0;

        /**
         * CoinflipGuessReply betAmount.
         * @member {number|Long} betAmount
         * @memberof coinflip.CoinflipGuessReply
         * @instance
         */
        CoinflipGuessReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CoinflipGuessReply instance using the specified properties.
         * @function create
         * @memberof coinflip.CoinflipGuessReply
         * @static
         * @param {coinflip.ICoinflipGuessReply=} [properties] Properties to set
         * @returns {coinflip.CoinflipGuessReply} CoinflipGuessReply instance
         */
        CoinflipGuessReply.create = function create(properties) {
            return new CoinflipGuessReply(properties);
        };

        /**
         * Encodes the specified CoinflipGuessReply message. Does not implicitly {@link coinflip.CoinflipGuessReply.verify|verify} messages.
         * @function encode
         * @memberof coinflip.CoinflipGuessReply
         * @static
         * @param {coinflip.ICoinflipGuessReply} message CoinflipGuessReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinflipGuessReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.coin != null && Object.hasOwnProperty.call(message, "coin"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.coin);
            if (message.right != null && Object.hasOwnProperty.call(message, "right"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.right);
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.payout);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.betAmount);
            return writer;
        };

        /**
         * Encodes the specified CoinflipGuessReply message, length delimited. Does not implicitly {@link coinflip.CoinflipGuessReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof coinflip.CoinflipGuessReply
         * @static
         * @param {coinflip.ICoinflipGuessReply} message CoinflipGuessReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinflipGuessReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CoinflipGuessReply message from the specified reader or buffer.
         * @function decode
         * @memberof coinflip.CoinflipGuessReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {coinflip.CoinflipGuessReply} CoinflipGuessReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinflipGuessReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.coinflip.CoinflipGuessReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.coin = reader.int32();
                    break;
                case 2:
                    message.right = reader.bool();
                    break;
                case 3:
                    message.payout = reader.int32();
                    break;
                case 4:
                    message.betAmount = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CoinflipGuessReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof coinflip.CoinflipGuessReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {coinflip.CoinflipGuessReply} CoinflipGuessReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinflipGuessReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CoinflipGuessReply message.
         * @function verify
         * @memberof coinflip.CoinflipGuessReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CoinflipGuessReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.coin != null && message.hasOwnProperty("coin"))
                if (!$util.isInteger(message.coin))
                    return "coin: integer expected";
            if (message.right != null && message.hasOwnProperty("right"))
                if (typeof message.right !== "boolean")
                    return "right: boolean expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isInteger(message.payout))
                    return "payout: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            return null;
        };

        /**
         * Creates a CoinflipGuessReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof coinflip.CoinflipGuessReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {coinflip.CoinflipGuessReply} CoinflipGuessReply
         */
        CoinflipGuessReply.fromObject = function fromObject(object) {
            if (object instanceof $root.coinflip.CoinflipGuessReply)
                return object;
            var message = new $root.coinflip.CoinflipGuessReply();
            if (object.coin != null)
                message.coin = object.coin | 0;
            if (object.right != null)
                message.right = Boolean(object.right);
            if (object.payout != null)
                message.payout = object.payout | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a CoinflipGuessReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof coinflip.CoinflipGuessReply
         * @static
         * @param {coinflip.CoinflipGuessReply} message CoinflipGuessReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CoinflipGuessReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.coin = 0;
                object.right = false;
                object.payout = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
            }
            if (message.coin != null && message.hasOwnProperty("coin"))
                object.coin = message.coin;
            if (message.right != null && message.hasOwnProperty("right"))
                object.right = message.right;
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            return object;
        };

        /**
         * Converts this CoinflipGuessReply to JSON.
         * @function toJSON
         * @memberof coinflip.CoinflipGuessReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CoinflipGuessReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CoinflipGuessReply;
    })();

    coinflip.CoinflipCashOutReply = (function() {

        /**
         * Properties of a CoinflipCashOutReply.
         * @memberof coinflip
         * @interface ICoinflipCashOutReply
         * @property {number|null} [currencyType] CoinflipCashOutReply currencyType
         * @property {number|Long|null} [winAmount] CoinflipCashOutReply winAmount
         * @property {number|null} [payout] CoinflipCashOutReply payout
         * @property {number|Long|null} [betAmount] CoinflipCashOutReply betAmount
         */

        /**
         * Constructs a new CoinflipCashOutReply.
         * @memberof coinflip
         * @classdesc Represents a CoinflipCashOutReply.
         * @implements ICoinflipCashOutReply
         * @constructor
         * @param {coinflip.ICoinflipCashOutReply=} [properties] Properties to set
         */
        function CoinflipCashOutReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoinflipCashOutReply currencyType.
         * @member {number} currencyType
         * @memberof coinflip.CoinflipCashOutReply
         * @instance
         */
        CoinflipCashOutReply.prototype.currencyType = 0;

        /**
         * CoinflipCashOutReply winAmount.
         * @member {number|Long} winAmount
         * @memberof coinflip.CoinflipCashOutReply
         * @instance
         */
        CoinflipCashOutReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CoinflipCashOutReply payout.
         * @member {number} payout
         * @memberof coinflip.CoinflipCashOutReply
         * @instance
         */
        CoinflipCashOutReply.prototype.payout = 0;

        /**
         * CoinflipCashOutReply betAmount.
         * @member {number|Long} betAmount
         * @memberof coinflip.CoinflipCashOutReply
         * @instance
         */
        CoinflipCashOutReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CoinflipCashOutReply instance using the specified properties.
         * @function create
         * @memberof coinflip.CoinflipCashOutReply
         * @static
         * @param {coinflip.ICoinflipCashOutReply=} [properties] Properties to set
         * @returns {coinflip.CoinflipCashOutReply} CoinflipCashOutReply instance
         */
        CoinflipCashOutReply.create = function create(properties) {
            return new CoinflipCashOutReply(properties);
        };

        /**
         * Encodes the specified CoinflipCashOutReply message. Does not implicitly {@link coinflip.CoinflipCashOutReply.verify|verify} messages.
         * @function encode
         * @memberof coinflip.CoinflipCashOutReply
         * @static
         * @param {coinflip.ICoinflipCashOutReply} message CoinflipCashOutReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinflipCashOutReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.winAmount);
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.payout);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.betAmount);
            return writer;
        };

        /**
         * Encodes the specified CoinflipCashOutReply message, length delimited. Does not implicitly {@link coinflip.CoinflipCashOutReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof coinflip.CoinflipCashOutReply
         * @static
         * @param {coinflip.ICoinflipCashOutReply} message CoinflipCashOutReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinflipCashOutReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CoinflipCashOutReply message from the specified reader or buffer.
         * @function decode
         * @memberof coinflip.CoinflipCashOutReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {coinflip.CoinflipCashOutReply} CoinflipCashOutReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinflipCashOutReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.coinflip.CoinflipCashOutReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.winAmount = reader.int64();
                    break;
                case 3:
                    message.payout = reader.int32();
                    break;
                case 4:
                    message.betAmount = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CoinflipCashOutReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof coinflip.CoinflipCashOutReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {coinflip.CoinflipCashOutReply} CoinflipCashOutReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinflipCashOutReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CoinflipCashOutReply message.
         * @function verify
         * @memberof coinflip.CoinflipCashOutReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CoinflipCashOutReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isInteger(message.payout))
                    return "payout: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            return null;
        };

        /**
         * Creates a CoinflipCashOutReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof coinflip.CoinflipCashOutReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {coinflip.CoinflipCashOutReply} CoinflipCashOutReply
         */
        CoinflipCashOutReply.fromObject = function fromObject(object) {
            if (object instanceof $root.coinflip.CoinflipCashOutReply)
                return object;
            var message = new $root.coinflip.CoinflipCashOutReply();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.payout != null)
                message.payout = object.payout | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a CoinflipCashOutReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof coinflip.CoinflipCashOutReply
         * @static
         * @param {coinflip.CoinflipCashOutReply} message CoinflipCashOutReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CoinflipCashOutReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                object.payout = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            return object;
        };

        /**
         * Converts this CoinflipCashOutReply to JSON.
         * @function toJSON
         * @memberof coinflip.CoinflipCashOutReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CoinflipCashOutReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CoinflipCashOutReply;
    })();

    coinflip.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof coinflip
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof coinflip
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {coinflip.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof coinflip.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof coinflip.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof coinflip.HistoryResult
         * @static
         * @param {coinflip.IHistoryResult=} [properties] Properties to set
         * @returns {coinflip.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link coinflip.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof coinflip.HistoryResult
         * @static
         * @param {coinflip.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link coinflip.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof coinflip.HistoryResult
         * @static
         * @param {coinflip.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof coinflip.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {coinflip.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.coinflip.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof coinflip.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {coinflip.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof coinflip.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof coinflip.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {coinflip.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.coinflip.HistoryResult)
                return object;
            var message = new $root.coinflip.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof coinflip.HistoryResult
         * @static
         * @param {coinflip.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof coinflip.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return coinflip;
})();

$root.videopoker = (function() {

    /**
     * Namespace videopoker.
     * @exports videopoker
     * @namespace
     */
    var videopoker = {};

    videopoker.VideopokerStateReply = (function() {

        /**
         * Properties of a VideopokerStateReply.
         * @memberof videopoker
         * @interface IVideopokerStateReply
         * @property {string|null} [params] VideopokerStateReply params
         * @property {Array.<videopoker.IHistoryResult>|null} [results] VideopokerStateReply results
         * @property {number|null} [currencyType] VideopokerStateReply currencyType
         * @property {number|Long|null} [betAmount] VideopokerStateReply betAmount
         * @property {string|null} [gameCode] VideopokerStateReply gameCode
         * @property {Array.<number>|null} [cards] VideopokerStateReply cards
         * @property {string|null} [fairness] VideopokerStateReply fairness
         */

        /**
         * Constructs a new VideopokerStateReply.
         * @memberof videopoker
         * @classdesc Represents a VideopokerStateReply.
         * @implements IVideopokerStateReply
         * @constructor
         * @param {videopoker.IVideopokerStateReply=} [properties] Properties to set
         */
        function VideopokerStateReply(properties) {
            this.results = [];
            this.cards = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VideopokerStateReply params.
         * @member {string} params
         * @memberof videopoker.VideopokerStateReply
         * @instance
         */
        VideopokerStateReply.prototype.params = "";

        /**
         * VideopokerStateReply results.
         * @member {Array.<videopoker.IHistoryResult>} results
         * @memberof videopoker.VideopokerStateReply
         * @instance
         */
        VideopokerStateReply.prototype.results = $util.emptyArray;

        /**
         * VideopokerStateReply currencyType.
         * @member {number} currencyType
         * @memberof videopoker.VideopokerStateReply
         * @instance
         */
        VideopokerStateReply.prototype.currencyType = 0;

        /**
         * VideopokerStateReply betAmount.
         * @member {number|Long} betAmount
         * @memberof videopoker.VideopokerStateReply
         * @instance
         */
        VideopokerStateReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * VideopokerStateReply gameCode.
         * @member {string} gameCode
         * @memberof videopoker.VideopokerStateReply
         * @instance
         */
        VideopokerStateReply.prototype.gameCode = "";

        /**
         * VideopokerStateReply cards.
         * @member {Array.<number>} cards
         * @memberof videopoker.VideopokerStateReply
         * @instance
         */
        VideopokerStateReply.prototype.cards = $util.emptyArray;

        /**
         * VideopokerStateReply fairness.
         * @member {string} fairness
         * @memberof videopoker.VideopokerStateReply
         * @instance
         */
        VideopokerStateReply.prototype.fairness = "";

        /**
         * Creates a new VideopokerStateReply instance using the specified properties.
         * @function create
         * @memberof videopoker.VideopokerStateReply
         * @static
         * @param {videopoker.IVideopokerStateReply=} [properties] Properties to set
         * @returns {videopoker.VideopokerStateReply} VideopokerStateReply instance
         */
        VideopokerStateReply.create = function create(properties) {
            return new VideopokerStateReply(properties);
        };

        /**
         * Encodes the specified VideopokerStateReply message. Does not implicitly {@link videopoker.VideopokerStateReply.verify|verify} messages.
         * @function encode
         * @memberof videopoker.VideopokerStateReply
         * @static
         * @param {videopoker.IVideopokerStateReply} message VideopokerStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideopokerStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.videopoker.HistoryResult.encode(message.results[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.betAmount);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.gameCode);
            if (message.cards != null && message.cards.length) {
                writer.uint32(/* id 6, wireType 2 =*/50).fork();
                for (var i = 0; i < message.cards.length; ++i)
                    writer.int32(message.cards[i]);
                writer.ldelim();
            }
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified VideopokerStateReply message, length delimited. Does not implicitly {@link videopoker.VideopokerStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof videopoker.VideopokerStateReply
         * @static
         * @param {videopoker.IVideopokerStateReply} message VideopokerStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideopokerStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VideopokerStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof videopoker.VideopokerStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {videopoker.VideopokerStateReply} VideopokerStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideopokerStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.videopoker.VideopokerStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 2:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.videopoker.HistoryResult.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.currencyType = reader.int32();
                    break;
                case 4:
                    message.betAmount = reader.int64();
                    break;
                case 5:
                    message.gameCode = reader.string();
                    break;
                case 6:
                    if (!(message.cards && message.cards.length))
                        message.cards = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.cards.push(reader.int32());
                    } else
                        message.cards.push(reader.int32());
                    break;
                case 7:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VideopokerStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof videopoker.VideopokerStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {videopoker.VideopokerStateReply} VideopokerStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideopokerStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VideopokerStateReply message.
         * @function verify
         * @memberof videopoker.VideopokerStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VideopokerStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.videopoker.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.cards != null && message.hasOwnProperty("cards")) {
                if (!Array.isArray(message.cards))
                    return "cards: array expected";
                for (var i = 0; i < message.cards.length; ++i)
                    if (!$util.isInteger(message.cards[i]))
                        return "cards: integer[] expected";
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a VideopokerStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof videopoker.VideopokerStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {videopoker.VideopokerStateReply} VideopokerStateReply
         */
        VideopokerStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.videopoker.VideopokerStateReply)
                return object;
            var message = new $root.videopoker.VideopokerStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".videopoker.VideopokerStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".videopoker.VideopokerStateReply.results: object expected");
                    message.results[i] = $root.videopoker.HistoryResult.fromObject(object.results[i]);
                }
            }
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.cards) {
                if (!Array.isArray(object.cards))
                    throw TypeError(".videopoker.VideopokerStateReply.cards: array expected");
                message.cards = [];
                for (var i = 0; i < object.cards.length; ++i)
                    message.cards[i] = object.cards[i] | 0;
            }
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a VideopokerStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof videopoker.VideopokerStateReply
         * @static
         * @param {videopoker.VideopokerStateReply} message VideopokerStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VideopokerStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.results = [];
                object.cards = [];
            }
            if (options.defaults) {
                object.params = "";
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.gameCode = "";
                object.fairness = "";
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.videopoker.HistoryResult.toObject(message.results[j], options);
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.cards && message.cards.length) {
                object.cards = [];
                for (var j = 0; j < message.cards.length; ++j)
                    object.cards[j] = message.cards[j];
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this VideopokerStateReply to JSON.
         * @function toJSON
         * @memberof videopoker.VideopokerStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VideopokerStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VideopokerStateReply;
    })();

    videopoker.VideopokerBetReq = (function() {

        /**
         * Properties of a VideopokerBetReq.
         * @memberof videopoker
         * @interface IVideopokerBetReq
         * @property {number|null} [currencyType] VideopokerBetReq currencyType
         * @property {number|Long|null} [betAmount] VideopokerBetReq betAmount
         */

        /**
         * Constructs a new VideopokerBetReq.
         * @memberof videopoker
         * @classdesc Represents a VideopokerBetReq.
         * @implements IVideopokerBetReq
         * @constructor
         * @param {videopoker.IVideopokerBetReq=} [properties] Properties to set
         */
        function VideopokerBetReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VideopokerBetReq currencyType.
         * @member {number} currencyType
         * @memberof videopoker.VideopokerBetReq
         * @instance
         */
        VideopokerBetReq.prototype.currencyType = 0;

        /**
         * VideopokerBetReq betAmount.
         * @member {number|Long} betAmount
         * @memberof videopoker.VideopokerBetReq
         * @instance
         */
        VideopokerBetReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new VideopokerBetReq instance using the specified properties.
         * @function create
         * @memberof videopoker.VideopokerBetReq
         * @static
         * @param {videopoker.IVideopokerBetReq=} [properties] Properties to set
         * @returns {videopoker.VideopokerBetReq} VideopokerBetReq instance
         */
        VideopokerBetReq.create = function create(properties) {
            return new VideopokerBetReq(properties);
        };

        /**
         * Encodes the specified VideopokerBetReq message. Does not implicitly {@link videopoker.VideopokerBetReq.verify|verify} messages.
         * @function encode
         * @memberof videopoker.VideopokerBetReq
         * @static
         * @param {videopoker.IVideopokerBetReq} message VideopokerBetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideopokerBetReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            return writer;
        };

        /**
         * Encodes the specified VideopokerBetReq message, length delimited. Does not implicitly {@link videopoker.VideopokerBetReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof videopoker.VideopokerBetReq
         * @static
         * @param {videopoker.IVideopokerBetReq} message VideopokerBetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideopokerBetReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VideopokerBetReq message from the specified reader or buffer.
         * @function decode
         * @memberof videopoker.VideopokerBetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {videopoker.VideopokerBetReq} VideopokerBetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideopokerBetReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.videopoker.VideopokerBetReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VideopokerBetReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof videopoker.VideopokerBetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {videopoker.VideopokerBetReq} VideopokerBetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideopokerBetReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VideopokerBetReq message.
         * @function verify
         * @memberof videopoker.VideopokerBetReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VideopokerBetReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            return null;
        };

        /**
         * Creates a VideopokerBetReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof videopoker.VideopokerBetReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {videopoker.VideopokerBetReq} VideopokerBetReq
         */
        VideopokerBetReq.fromObject = function fromObject(object) {
            if (object instanceof $root.videopoker.VideopokerBetReq)
                return object;
            var message = new $root.videopoker.VideopokerBetReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a VideopokerBetReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof videopoker.VideopokerBetReq
         * @static
         * @param {videopoker.VideopokerBetReq} message VideopokerBetReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VideopokerBetReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            return object;
        };

        /**
         * Converts this VideopokerBetReq to JSON.
         * @function toJSON
         * @memberof videopoker.VideopokerBetReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VideopokerBetReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VideopokerBetReq;
    })();

    videopoker.VideopokerBetReply = (function() {

        /**
         * Properties of a VideopokerBetReply.
         * @memberof videopoker
         * @interface IVideopokerBetReply
         * @property {Array.<number>|null} [cards] VideopokerBetReply cards
         * @property {string|null} [fairness] VideopokerBetReply fairness
         */

        /**
         * Constructs a new VideopokerBetReply.
         * @memberof videopoker
         * @classdesc Represents a VideopokerBetReply.
         * @implements IVideopokerBetReply
         * @constructor
         * @param {videopoker.IVideopokerBetReply=} [properties] Properties to set
         */
        function VideopokerBetReply(properties) {
            this.cards = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VideopokerBetReply cards.
         * @member {Array.<number>} cards
         * @memberof videopoker.VideopokerBetReply
         * @instance
         */
        VideopokerBetReply.prototype.cards = $util.emptyArray;

        /**
         * VideopokerBetReply fairness.
         * @member {string} fairness
         * @memberof videopoker.VideopokerBetReply
         * @instance
         */
        VideopokerBetReply.prototype.fairness = "";

        /**
         * Creates a new VideopokerBetReply instance using the specified properties.
         * @function create
         * @memberof videopoker.VideopokerBetReply
         * @static
         * @param {videopoker.IVideopokerBetReply=} [properties] Properties to set
         * @returns {videopoker.VideopokerBetReply} VideopokerBetReply instance
         */
        VideopokerBetReply.create = function create(properties) {
            return new VideopokerBetReply(properties);
        };

        /**
         * Encodes the specified VideopokerBetReply message. Does not implicitly {@link videopoker.VideopokerBetReply.verify|verify} messages.
         * @function encode
         * @memberof videopoker.VideopokerBetReply
         * @static
         * @param {videopoker.IVideopokerBetReply} message VideopokerBetReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideopokerBetReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cards != null && message.cards.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.cards.length; ++i)
                    writer.int32(message.cards[i]);
                writer.ldelim();
            }
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified VideopokerBetReply message, length delimited. Does not implicitly {@link videopoker.VideopokerBetReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof videopoker.VideopokerBetReply
         * @static
         * @param {videopoker.IVideopokerBetReply} message VideopokerBetReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideopokerBetReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VideopokerBetReply message from the specified reader or buffer.
         * @function decode
         * @memberof videopoker.VideopokerBetReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {videopoker.VideopokerBetReply} VideopokerBetReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideopokerBetReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.videopoker.VideopokerBetReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.cards && message.cards.length))
                        message.cards = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.cards.push(reader.int32());
                    } else
                        message.cards.push(reader.int32());
                    break;
                case 2:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VideopokerBetReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof videopoker.VideopokerBetReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {videopoker.VideopokerBetReply} VideopokerBetReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideopokerBetReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VideopokerBetReply message.
         * @function verify
         * @memberof videopoker.VideopokerBetReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VideopokerBetReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cards != null && message.hasOwnProperty("cards")) {
                if (!Array.isArray(message.cards))
                    return "cards: array expected";
                for (var i = 0; i < message.cards.length; ++i)
                    if (!$util.isInteger(message.cards[i]))
                        return "cards: integer[] expected";
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a VideopokerBetReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof videopoker.VideopokerBetReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {videopoker.VideopokerBetReply} VideopokerBetReply
         */
        VideopokerBetReply.fromObject = function fromObject(object) {
            if (object instanceof $root.videopoker.VideopokerBetReply)
                return object;
            var message = new $root.videopoker.VideopokerBetReply();
            if (object.cards) {
                if (!Array.isArray(object.cards))
                    throw TypeError(".videopoker.VideopokerBetReply.cards: array expected");
                message.cards = [];
                for (var i = 0; i < object.cards.length; ++i)
                    message.cards[i] = object.cards[i] | 0;
            }
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a VideopokerBetReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof videopoker.VideopokerBetReply
         * @static
         * @param {videopoker.VideopokerBetReply} message VideopokerBetReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VideopokerBetReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.cards = [];
            if (options.defaults)
                object.fairness = "";
            if (message.cards && message.cards.length) {
                object.cards = [];
                for (var j = 0; j < message.cards.length; ++j)
                    object.cards[j] = message.cards[j];
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this VideopokerBetReply to JSON.
         * @function toJSON
         * @memberof videopoker.VideopokerBetReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VideopokerBetReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VideopokerBetReply;
    })();

    videopoker.VideopokerDealReq = (function() {

        /**
         * Properties of a VideopokerDealReq.
         * @memberof videopoker
         * @interface IVideopokerDealReq
         * @property {Array.<number>|null} [choiceIndex] VideopokerDealReq choiceIndex
         */

        /**
         * Constructs a new VideopokerDealReq.
         * @memberof videopoker
         * @classdesc Represents a VideopokerDealReq.
         * @implements IVideopokerDealReq
         * @constructor
         * @param {videopoker.IVideopokerDealReq=} [properties] Properties to set
         */
        function VideopokerDealReq(properties) {
            this.choiceIndex = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VideopokerDealReq choiceIndex.
         * @member {Array.<number>} choiceIndex
         * @memberof videopoker.VideopokerDealReq
         * @instance
         */
        VideopokerDealReq.prototype.choiceIndex = $util.emptyArray;

        /**
         * Creates a new VideopokerDealReq instance using the specified properties.
         * @function create
         * @memberof videopoker.VideopokerDealReq
         * @static
         * @param {videopoker.IVideopokerDealReq=} [properties] Properties to set
         * @returns {videopoker.VideopokerDealReq} VideopokerDealReq instance
         */
        VideopokerDealReq.create = function create(properties) {
            return new VideopokerDealReq(properties);
        };

        /**
         * Encodes the specified VideopokerDealReq message. Does not implicitly {@link videopoker.VideopokerDealReq.verify|verify} messages.
         * @function encode
         * @memberof videopoker.VideopokerDealReq
         * @static
         * @param {videopoker.IVideopokerDealReq} message VideopokerDealReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideopokerDealReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.choiceIndex != null && message.choiceIndex.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.choiceIndex.length; ++i)
                    writer.int32(message.choiceIndex[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified VideopokerDealReq message, length delimited. Does not implicitly {@link videopoker.VideopokerDealReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof videopoker.VideopokerDealReq
         * @static
         * @param {videopoker.IVideopokerDealReq} message VideopokerDealReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideopokerDealReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VideopokerDealReq message from the specified reader or buffer.
         * @function decode
         * @memberof videopoker.VideopokerDealReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {videopoker.VideopokerDealReq} VideopokerDealReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideopokerDealReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.videopoker.VideopokerDealReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.choiceIndex && message.choiceIndex.length))
                        message.choiceIndex = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.choiceIndex.push(reader.int32());
                    } else
                        message.choiceIndex.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VideopokerDealReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof videopoker.VideopokerDealReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {videopoker.VideopokerDealReq} VideopokerDealReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideopokerDealReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VideopokerDealReq message.
         * @function verify
         * @memberof videopoker.VideopokerDealReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VideopokerDealReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.choiceIndex != null && message.hasOwnProperty("choiceIndex")) {
                if (!Array.isArray(message.choiceIndex))
                    return "choiceIndex: array expected";
                for (var i = 0; i < message.choiceIndex.length; ++i)
                    if (!$util.isInteger(message.choiceIndex[i]))
                        return "choiceIndex: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a VideopokerDealReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof videopoker.VideopokerDealReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {videopoker.VideopokerDealReq} VideopokerDealReq
         */
        VideopokerDealReq.fromObject = function fromObject(object) {
            if (object instanceof $root.videopoker.VideopokerDealReq)
                return object;
            var message = new $root.videopoker.VideopokerDealReq();
            if (object.choiceIndex) {
                if (!Array.isArray(object.choiceIndex))
                    throw TypeError(".videopoker.VideopokerDealReq.choiceIndex: array expected");
                message.choiceIndex = [];
                for (var i = 0; i < object.choiceIndex.length; ++i)
                    message.choiceIndex[i] = object.choiceIndex[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a VideopokerDealReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof videopoker.VideopokerDealReq
         * @static
         * @param {videopoker.VideopokerDealReq} message VideopokerDealReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VideopokerDealReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.choiceIndex = [];
            if (message.choiceIndex && message.choiceIndex.length) {
                object.choiceIndex = [];
                for (var j = 0; j < message.choiceIndex.length; ++j)
                    object.choiceIndex[j] = message.choiceIndex[j];
            }
            return object;
        };

        /**
         * Converts this VideopokerDealReq to JSON.
         * @function toJSON
         * @memberof videopoker.VideopokerDealReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VideopokerDealReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VideopokerDealReq;
    })();

    videopoker.VideopokerDealReply = (function() {

        /**
         * Properties of a VideopokerDealReply.
         * @memberof videopoker
         * @interface IVideopokerDealReply
         * @property {Array.<number>|null} [cards] VideopokerDealReply cards
         * @property {Array.<number>|null} [awardIndex] VideopokerDealReply awardIndex
         * @property {number|null} [currencyType] VideopokerDealReply currencyType
         * @property {number|Long|null} [winAmount] VideopokerDealReply winAmount
         * @property {number|Long|null} [betAmount] VideopokerDealReply betAmount
         * @property {string|null} [gameCode] VideopokerDealReply gameCode
         * @property {number|null} [payout] VideopokerDealReply payout
         */

        /**
         * Constructs a new VideopokerDealReply.
         * @memberof videopoker
         * @classdesc Represents a VideopokerDealReply.
         * @implements IVideopokerDealReply
         * @constructor
         * @param {videopoker.IVideopokerDealReply=} [properties] Properties to set
         */
        function VideopokerDealReply(properties) {
            this.cards = [];
            this.awardIndex = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VideopokerDealReply cards.
         * @member {Array.<number>} cards
         * @memberof videopoker.VideopokerDealReply
         * @instance
         */
        VideopokerDealReply.prototype.cards = $util.emptyArray;

        /**
         * VideopokerDealReply awardIndex.
         * @member {Array.<number>} awardIndex
         * @memberof videopoker.VideopokerDealReply
         * @instance
         */
        VideopokerDealReply.prototype.awardIndex = $util.emptyArray;

        /**
         * VideopokerDealReply currencyType.
         * @member {number} currencyType
         * @memberof videopoker.VideopokerDealReply
         * @instance
         */
        VideopokerDealReply.prototype.currencyType = 0;

        /**
         * VideopokerDealReply winAmount.
         * @member {number|Long} winAmount
         * @memberof videopoker.VideopokerDealReply
         * @instance
         */
        VideopokerDealReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * VideopokerDealReply betAmount.
         * @member {number|Long} betAmount
         * @memberof videopoker.VideopokerDealReply
         * @instance
         */
        VideopokerDealReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * VideopokerDealReply gameCode.
         * @member {string} gameCode
         * @memberof videopoker.VideopokerDealReply
         * @instance
         */
        VideopokerDealReply.prototype.gameCode = "";

        /**
         * VideopokerDealReply payout.
         * @member {number} payout
         * @memberof videopoker.VideopokerDealReply
         * @instance
         */
        VideopokerDealReply.prototype.payout = 0;

        /**
         * Creates a new VideopokerDealReply instance using the specified properties.
         * @function create
         * @memberof videopoker.VideopokerDealReply
         * @static
         * @param {videopoker.IVideopokerDealReply=} [properties] Properties to set
         * @returns {videopoker.VideopokerDealReply} VideopokerDealReply instance
         */
        VideopokerDealReply.create = function create(properties) {
            return new VideopokerDealReply(properties);
        };

        /**
         * Encodes the specified VideopokerDealReply message. Does not implicitly {@link videopoker.VideopokerDealReply.verify|verify} messages.
         * @function encode
         * @memberof videopoker.VideopokerDealReply
         * @static
         * @param {videopoker.IVideopokerDealReply} message VideopokerDealReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideopokerDealReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cards != null && message.cards.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.cards.length; ++i)
                    writer.int32(message.cards[i]);
                writer.ldelim();
            }
            if (message.awardIndex != null && message.awardIndex.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.awardIndex.length; ++i)
                    writer.int32(message.awardIndex[i]);
                writer.ldelim();
            }
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.winAmount);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.betAmount);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.gameCode);
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.payout);
            return writer;
        };

        /**
         * Encodes the specified VideopokerDealReply message, length delimited. Does not implicitly {@link videopoker.VideopokerDealReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof videopoker.VideopokerDealReply
         * @static
         * @param {videopoker.IVideopokerDealReply} message VideopokerDealReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VideopokerDealReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VideopokerDealReply message from the specified reader or buffer.
         * @function decode
         * @memberof videopoker.VideopokerDealReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {videopoker.VideopokerDealReply} VideopokerDealReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideopokerDealReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.videopoker.VideopokerDealReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.cards && message.cards.length))
                        message.cards = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.cards.push(reader.int32());
                    } else
                        message.cards.push(reader.int32());
                    break;
                case 2:
                    if (!(message.awardIndex && message.awardIndex.length))
                        message.awardIndex = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.awardIndex.push(reader.int32());
                    } else
                        message.awardIndex.push(reader.int32());
                    break;
                case 3:
                    message.currencyType = reader.int32();
                    break;
                case 4:
                    message.winAmount = reader.int64();
                    break;
                case 5:
                    message.betAmount = reader.int64();
                    break;
                case 6:
                    message.gameCode = reader.string();
                    break;
                case 7:
                    message.payout = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VideopokerDealReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof videopoker.VideopokerDealReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {videopoker.VideopokerDealReply} VideopokerDealReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VideopokerDealReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VideopokerDealReply message.
         * @function verify
         * @memberof videopoker.VideopokerDealReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VideopokerDealReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cards != null && message.hasOwnProperty("cards")) {
                if (!Array.isArray(message.cards))
                    return "cards: array expected";
                for (var i = 0; i < message.cards.length; ++i)
                    if (!$util.isInteger(message.cards[i]))
                        return "cards: integer[] expected";
            }
            if (message.awardIndex != null && message.hasOwnProperty("awardIndex")) {
                if (!Array.isArray(message.awardIndex))
                    return "awardIndex: array expected";
                for (var i = 0; i < message.awardIndex.length; ++i)
                    if (!$util.isInteger(message.awardIndex[i]))
                        return "awardIndex: integer[] expected";
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isInteger(message.payout))
                    return "payout: integer expected";
            return null;
        };

        /**
         * Creates a VideopokerDealReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof videopoker.VideopokerDealReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {videopoker.VideopokerDealReply} VideopokerDealReply
         */
        VideopokerDealReply.fromObject = function fromObject(object) {
            if (object instanceof $root.videopoker.VideopokerDealReply)
                return object;
            var message = new $root.videopoker.VideopokerDealReply();
            if (object.cards) {
                if (!Array.isArray(object.cards))
                    throw TypeError(".videopoker.VideopokerDealReply.cards: array expected");
                message.cards = [];
                for (var i = 0; i < object.cards.length; ++i)
                    message.cards[i] = object.cards[i] | 0;
            }
            if (object.awardIndex) {
                if (!Array.isArray(object.awardIndex))
                    throw TypeError(".videopoker.VideopokerDealReply.awardIndex: array expected");
                message.awardIndex = [];
                for (var i = 0; i < object.awardIndex.length; ++i)
                    message.awardIndex[i] = object.awardIndex[i] | 0;
            }
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.payout != null)
                message.payout = object.payout | 0;
            return message;
        };

        /**
         * Creates a plain object from a VideopokerDealReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof videopoker.VideopokerDealReply
         * @static
         * @param {videopoker.VideopokerDealReply} message VideopokerDealReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VideopokerDealReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.cards = [];
                object.awardIndex = [];
            }
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.gameCode = "";
                object.payout = 0;
            }
            if (message.cards && message.cards.length) {
                object.cards = [];
                for (var j = 0; j < message.cards.length; ++j)
                    object.cards[j] = message.cards[j];
            }
            if (message.awardIndex && message.awardIndex.length) {
                object.awardIndex = [];
                for (var j = 0; j < message.awardIndex.length; ++j)
                    object.awardIndex[j] = message.awardIndex[j];
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            return object;
        };

        /**
         * Converts this VideopokerDealReply to JSON.
         * @function toJSON
         * @memberof videopoker.VideopokerDealReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VideopokerDealReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VideopokerDealReply;
    })();

    videopoker.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof videopoker
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof videopoker
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {videopoker.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof videopoker.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof videopoker.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof videopoker.HistoryResult
         * @static
         * @param {videopoker.IHistoryResult=} [properties] Properties to set
         * @returns {videopoker.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link videopoker.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof videopoker.HistoryResult
         * @static
         * @param {videopoker.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link videopoker.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof videopoker.HistoryResult
         * @static
         * @param {videopoker.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof videopoker.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {videopoker.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.videopoker.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof videopoker.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {videopoker.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof videopoker.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof videopoker.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {videopoker.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.videopoker.HistoryResult)
                return object;
            var message = new $root.videopoker.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof videopoker.HistoryResult
         * @static
         * @param {videopoker.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof videopoker.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return videopoker;
})();

$root.keno = (function() {

    /**
     * Namespace keno.
     * @exports keno
     * @namespace
     */
    var keno = {};

    keno.KenoStateReply = (function() {

        /**
         * Properties of a KenoStateReply.
         * @memberof keno
         * @interface IKenoStateReply
         * @property {string|null} [params] KenoStateReply params
         * @property {string|null} [risks] KenoStateReply risks
         * @property {Array.<keno.IHistoryResult>|null} [results] KenoStateReply results
         * @property {string|null} [pay] KenoStateReply pay
         * @property {string|null} [fairness] KenoStateReply fairness
         */

        /**
         * Constructs a new KenoStateReply.
         * @memberof keno
         * @classdesc Represents a KenoStateReply.
         * @implements IKenoStateReply
         * @constructor
         * @param {keno.IKenoStateReply=} [properties] Properties to set
         */
        function KenoStateReply(properties) {
            this.results = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KenoStateReply params.
         * @member {string} params
         * @memberof keno.KenoStateReply
         * @instance
         */
        KenoStateReply.prototype.params = "";

        /**
         * KenoStateReply risks.
         * @member {string} risks
         * @memberof keno.KenoStateReply
         * @instance
         */
        KenoStateReply.prototype.risks = "";

        /**
         * KenoStateReply results.
         * @member {Array.<keno.IHistoryResult>} results
         * @memberof keno.KenoStateReply
         * @instance
         */
        KenoStateReply.prototype.results = $util.emptyArray;

        /**
         * KenoStateReply pay.
         * @member {string} pay
         * @memberof keno.KenoStateReply
         * @instance
         */
        KenoStateReply.prototype.pay = "";

        /**
         * KenoStateReply fairness.
         * @member {string} fairness
         * @memberof keno.KenoStateReply
         * @instance
         */
        KenoStateReply.prototype.fairness = "";

        /**
         * Creates a new KenoStateReply instance using the specified properties.
         * @function create
         * @memberof keno.KenoStateReply
         * @static
         * @param {keno.IKenoStateReply=} [properties] Properties to set
         * @returns {keno.KenoStateReply} KenoStateReply instance
         */
        KenoStateReply.create = function create(properties) {
            return new KenoStateReply(properties);
        };

        /**
         * Encodes the specified KenoStateReply message. Does not implicitly {@link keno.KenoStateReply.verify|verify} messages.
         * @function encode
         * @memberof keno.KenoStateReply
         * @static
         * @param {keno.IKenoStateReply} message KenoStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KenoStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.risks != null && Object.hasOwnProperty.call(message, "risks"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.risks);
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.keno.HistoryResult.encode(message.results[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.pay != null && Object.hasOwnProperty.call(message, "pay"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.pay);
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified KenoStateReply message, length delimited. Does not implicitly {@link keno.KenoStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof keno.KenoStateReply
         * @static
         * @param {keno.IKenoStateReply} message KenoStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KenoStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KenoStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof keno.KenoStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {keno.KenoStateReply} KenoStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KenoStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.keno.KenoStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 2:
                    message.risks = reader.string();
                    break;
                case 3:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.keno.HistoryResult.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.pay = reader.string();
                    break;
                case 5:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a KenoStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof keno.KenoStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {keno.KenoStateReply} KenoStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KenoStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KenoStateReply message.
         * @function verify
         * @memberof keno.KenoStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KenoStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.risks != null && message.hasOwnProperty("risks"))
                if (!$util.isString(message.risks))
                    return "risks: string expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.keno.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            if (message.pay != null && message.hasOwnProperty("pay"))
                if (!$util.isString(message.pay))
                    return "pay: string expected";
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a KenoStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof keno.KenoStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {keno.KenoStateReply} KenoStateReply
         */
        KenoStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.keno.KenoStateReply)
                return object;
            var message = new $root.keno.KenoStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.risks != null)
                message.risks = String(object.risks);
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".keno.KenoStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".keno.KenoStateReply.results: object expected");
                    message.results[i] = $root.keno.HistoryResult.fromObject(object.results[i]);
                }
            }
            if (object.pay != null)
                message.pay = String(object.pay);
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a KenoStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof keno.KenoStateReply
         * @static
         * @param {keno.KenoStateReply} message KenoStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KenoStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.results = [];
            if (options.defaults) {
                object.params = "";
                object.risks = "";
                object.pay = "";
                object.fairness = "";
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.risks != null && message.hasOwnProperty("risks"))
                object.risks = message.risks;
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.keno.HistoryResult.toObject(message.results[j], options);
            }
            if (message.pay != null && message.hasOwnProperty("pay"))
                object.pay = message.pay;
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this KenoStateReply to JSON.
         * @function toJSON
         * @memberof keno.KenoStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KenoStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return KenoStateReply;
    })();

    keno.KenoResultReq = (function() {

        /**
         * Properties of a KenoResultReq.
         * @memberof keno
         * @interface IKenoResultReq
         * @property {number|null} [currencyType] KenoResultReq currencyType
         * @property {number|Long|null} [betAmount] KenoResultReq betAmount
         * @property {number|null} [risk] KenoResultReq risk
         * @property {Array.<number>|null} [choiceNums] KenoResultReq choiceNums
         */

        /**
         * Constructs a new KenoResultReq.
         * @memberof keno
         * @classdesc Represents a KenoResultReq.
         * @implements IKenoResultReq
         * @constructor
         * @param {keno.IKenoResultReq=} [properties] Properties to set
         */
        function KenoResultReq(properties) {
            this.choiceNums = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KenoResultReq currencyType.
         * @member {number} currencyType
         * @memberof keno.KenoResultReq
         * @instance
         */
        KenoResultReq.prototype.currencyType = 0;

        /**
         * KenoResultReq betAmount.
         * @member {number|Long} betAmount
         * @memberof keno.KenoResultReq
         * @instance
         */
        KenoResultReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * KenoResultReq risk.
         * @member {number} risk
         * @memberof keno.KenoResultReq
         * @instance
         */
        KenoResultReq.prototype.risk = 0;

        /**
         * KenoResultReq choiceNums.
         * @member {Array.<number>} choiceNums
         * @memberof keno.KenoResultReq
         * @instance
         */
        KenoResultReq.prototype.choiceNums = $util.emptyArray;

        /**
         * Creates a new KenoResultReq instance using the specified properties.
         * @function create
         * @memberof keno.KenoResultReq
         * @static
         * @param {keno.IKenoResultReq=} [properties] Properties to set
         * @returns {keno.KenoResultReq} KenoResultReq instance
         */
        KenoResultReq.create = function create(properties) {
            return new KenoResultReq(properties);
        };

        /**
         * Encodes the specified KenoResultReq message. Does not implicitly {@link keno.KenoResultReq.verify|verify} messages.
         * @function encode
         * @memberof keno.KenoResultReq
         * @static
         * @param {keno.IKenoResultReq} message KenoResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KenoResultReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            if (message.risk != null && Object.hasOwnProperty.call(message, "risk"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.risk);
            if (message.choiceNums != null && message.choiceNums.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.choiceNums.length; ++i)
                    writer.int32(message.choiceNums[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified KenoResultReq message, length delimited. Does not implicitly {@link keno.KenoResultReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof keno.KenoResultReq
         * @static
         * @param {keno.IKenoResultReq} message KenoResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KenoResultReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KenoResultReq message from the specified reader or buffer.
         * @function decode
         * @memberof keno.KenoResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {keno.KenoResultReq} KenoResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KenoResultReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.keno.KenoResultReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                case 3:
                    message.risk = reader.int32();
                    break;
                case 4:
                    if (!(message.choiceNums && message.choiceNums.length))
                        message.choiceNums = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.choiceNums.push(reader.int32());
                    } else
                        message.choiceNums.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a KenoResultReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof keno.KenoResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {keno.KenoResultReq} KenoResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KenoResultReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KenoResultReq message.
         * @function verify
         * @memberof keno.KenoResultReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KenoResultReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.risk != null && message.hasOwnProperty("risk"))
                if (!$util.isInteger(message.risk))
                    return "risk: integer expected";
            if (message.choiceNums != null && message.hasOwnProperty("choiceNums")) {
                if (!Array.isArray(message.choiceNums))
                    return "choiceNums: array expected";
                for (var i = 0; i < message.choiceNums.length; ++i)
                    if (!$util.isInteger(message.choiceNums[i]))
                        return "choiceNums: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a KenoResultReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof keno.KenoResultReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {keno.KenoResultReq} KenoResultReq
         */
        KenoResultReq.fromObject = function fromObject(object) {
            if (object instanceof $root.keno.KenoResultReq)
                return object;
            var message = new $root.keno.KenoResultReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.risk != null)
                message.risk = object.risk | 0;
            if (object.choiceNums) {
                if (!Array.isArray(object.choiceNums))
                    throw TypeError(".keno.KenoResultReq.choiceNums: array expected");
                message.choiceNums = [];
                for (var i = 0; i < object.choiceNums.length; ++i)
                    message.choiceNums[i] = object.choiceNums[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a KenoResultReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof keno.KenoResultReq
         * @static
         * @param {keno.KenoResultReq} message KenoResultReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KenoResultReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.choiceNums = [];
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.risk = 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.risk != null && message.hasOwnProperty("risk"))
                object.risk = message.risk;
            if (message.choiceNums && message.choiceNums.length) {
                object.choiceNums = [];
                for (var j = 0; j < message.choiceNums.length; ++j)
                    object.choiceNums[j] = message.choiceNums[j];
            }
            return object;
        };

        /**
         * Converts this KenoResultReq to JSON.
         * @function toJSON
         * @memberof keno.KenoResultReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KenoResultReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return KenoResultReq;
    })();

    keno.KenoResultReply = (function() {

        /**
         * Properties of a KenoResultReply.
         * @memberof keno
         * @interface IKenoResultReply
         * @property {Array.<number>|null} [awardNums] KenoResultReply awardNums
         * @property {number|null} [currencyType] KenoResultReply currencyType
         * @property {number|Long|null} [winAmount] KenoResultReply winAmount
         * @property {number|Long|null} [betAmount] KenoResultReply betAmount
         * @property {string|null} [gameCode] KenoResultReply gameCode
         * @property {number|null} [result] KenoResultReply result
         * @property {string|null} [fairness] KenoResultReply fairness
         */

        /**
         * Constructs a new KenoResultReply.
         * @memberof keno
         * @classdesc Represents a KenoResultReply.
         * @implements IKenoResultReply
         * @constructor
         * @param {keno.IKenoResultReply=} [properties] Properties to set
         */
        function KenoResultReply(properties) {
            this.awardNums = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KenoResultReply awardNums.
         * @member {Array.<number>} awardNums
         * @memberof keno.KenoResultReply
         * @instance
         */
        KenoResultReply.prototype.awardNums = $util.emptyArray;

        /**
         * KenoResultReply currencyType.
         * @member {number} currencyType
         * @memberof keno.KenoResultReply
         * @instance
         */
        KenoResultReply.prototype.currencyType = 0;

        /**
         * KenoResultReply winAmount.
         * @member {number|Long} winAmount
         * @memberof keno.KenoResultReply
         * @instance
         */
        KenoResultReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * KenoResultReply betAmount.
         * @member {number|Long} betAmount
         * @memberof keno.KenoResultReply
         * @instance
         */
        KenoResultReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * KenoResultReply gameCode.
         * @member {string} gameCode
         * @memberof keno.KenoResultReply
         * @instance
         */
        KenoResultReply.prototype.gameCode = "";

        /**
         * KenoResultReply result.
         * @member {number} result
         * @memberof keno.KenoResultReply
         * @instance
         */
        KenoResultReply.prototype.result = 0;

        /**
         * KenoResultReply fairness.
         * @member {string} fairness
         * @memberof keno.KenoResultReply
         * @instance
         */
        KenoResultReply.prototype.fairness = "";

        /**
         * Creates a new KenoResultReply instance using the specified properties.
         * @function create
         * @memberof keno.KenoResultReply
         * @static
         * @param {keno.IKenoResultReply=} [properties] Properties to set
         * @returns {keno.KenoResultReply} KenoResultReply instance
         */
        KenoResultReply.create = function create(properties) {
            return new KenoResultReply(properties);
        };

        /**
         * Encodes the specified KenoResultReply message. Does not implicitly {@link keno.KenoResultReply.verify|verify} messages.
         * @function encode
         * @memberof keno.KenoResultReply
         * @static
         * @param {keno.IKenoResultReply} message KenoResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KenoResultReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.awardNums != null && message.awardNums.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (var i = 0; i < message.awardNums.length; ++i)
                    writer.int32(message.awardNums[i]);
                writer.ldelim();
            }
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.winAmount);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.betAmount);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.result);
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.fairness);
            return writer;
        };

        /**
         * Encodes the specified KenoResultReply message, length delimited. Does not implicitly {@link keno.KenoResultReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof keno.KenoResultReply
         * @static
         * @param {keno.IKenoResultReply} message KenoResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KenoResultReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KenoResultReply message from the specified reader or buffer.
         * @function decode
         * @memberof keno.KenoResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {keno.KenoResultReply} KenoResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KenoResultReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.keno.KenoResultReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.awardNums && message.awardNums.length))
                        message.awardNums = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.awardNums.push(reader.int32());
                    } else
                        message.awardNums.push(reader.int32());
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.winAmount = reader.int64();
                    break;
                case 4:
                    message.betAmount = reader.int64();
                    break;
                case 5:
                    message.gameCode = reader.string();
                    break;
                case 6:
                    message.result = reader.int32();
                    break;
                case 7:
                    message.fairness = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a KenoResultReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof keno.KenoResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {keno.KenoResultReply} KenoResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KenoResultReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KenoResultReply message.
         * @function verify
         * @memberof keno.KenoResultReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KenoResultReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.awardNums != null && message.hasOwnProperty("awardNums")) {
                if (!Array.isArray(message.awardNums))
                    return "awardNums: array expected";
                for (var i = 0; i < message.awardNums.length; ++i)
                    if (!$util.isInteger(message.awardNums[i]))
                        return "awardNums: integer[] expected";
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            return null;
        };

        /**
         * Creates a KenoResultReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof keno.KenoResultReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {keno.KenoResultReply} KenoResultReply
         */
        KenoResultReply.fromObject = function fromObject(object) {
            if (object instanceof $root.keno.KenoResultReply)
                return object;
            var message = new $root.keno.KenoResultReply();
            if (object.awardNums) {
                if (!Array.isArray(object.awardNums))
                    throw TypeError(".keno.KenoResultReply.awardNums: array expected");
                message.awardNums = [];
                for (var i = 0; i < object.awardNums.length; ++i)
                    message.awardNums[i] = object.awardNums[i] | 0;
            }
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            return message;
        };

        /**
         * Creates a plain object from a KenoResultReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof keno.KenoResultReply
         * @static
         * @param {keno.KenoResultReply} message KenoResultReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KenoResultReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.awardNums = [];
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.gameCode = "";
                object.result = 0;
                object.fairness = "";
            }
            if (message.awardNums && message.awardNums.length) {
                object.awardNums = [];
                for (var j = 0; j < message.awardNums.length; ++j)
                    object.awardNums[j] = message.awardNums[j];
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            return object;
        };

        /**
         * Converts this KenoResultReply to JSON.
         * @function toJSON
         * @memberof keno.KenoResultReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KenoResultReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return KenoResultReply;
    })();

    keno.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof keno
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof keno
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {keno.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof keno.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof keno.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof keno.HistoryResult
         * @static
         * @param {keno.IHistoryResult=} [properties] Properties to set
         * @returns {keno.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link keno.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof keno.HistoryResult
         * @static
         * @param {keno.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link keno.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof keno.HistoryResult
         * @static
         * @param {keno.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof keno.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {keno.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.keno.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof keno.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {keno.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof keno.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof keno.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {keno.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.keno.HistoryResult)
                return object;
            var message = new $root.keno.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof keno.HistoryResult
         * @static
         * @param {keno.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof keno.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return keno;
})();

$root.classicdice = (function() {

    /**
     * Namespace classicdice.
     * @exports classicdice
     * @namespace
     */
    var classicdice = {};

    classicdice.ClassicDiceStateReply = (function() {

        /**
         * Properties of a ClassicDiceStateReply.
         * @memberof classicdice
         * @interface IClassicDiceStateReply
         * @property {string|null} [params] ClassicDiceStateReply params
         * @property {Array.<classicdice.IHistoryResult>|null} [results] ClassicDiceStateReply results
         */

        /**
         * Constructs a new ClassicDiceStateReply.
         * @memberof classicdice
         * @classdesc Represents a ClassicDiceStateReply.
         * @implements IClassicDiceStateReply
         * @constructor
         * @param {classicdice.IClassicDiceStateReply=} [properties] Properties to set
         */
        function ClassicDiceStateReply(properties) {
            this.results = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClassicDiceStateReply params.
         * @member {string} params
         * @memberof classicdice.ClassicDiceStateReply
         * @instance
         */
        ClassicDiceStateReply.prototype.params = "";

        /**
         * ClassicDiceStateReply results.
         * @member {Array.<classicdice.IHistoryResult>} results
         * @memberof classicdice.ClassicDiceStateReply
         * @instance
         */
        ClassicDiceStateReply.prototype.results = $util.emptyArray;

        /**
         * Creates a new ClassicDiceStateReply instance using the specified properties.
         * @function create
         * @memberof classicdice.ClassicDiceStateReply
         * @static
         * @param {classicdice.IClassicDiceStateReply=} [properties] Properties to set
         * @returns {classicdice.ClassicDiceStateReply} ClassicDiceStateReply instance
         */
        ClassicDiceStateReply.create = function create(properties) {
            return new ClassicDiceStateReply(properties);
        };

        /**
         * Encodes the specified ClassicDiceStateReply message. Does not implicitly {@link classicdice.ClassicDiceStateReply.verify|verify} messages.
         * @function encode
         * @memberof classicdice.ClassicDiceStateReply
         * @static
         * @param {classicdice.IClassicDiceStateReply} message ClassicDiceStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClassicDiceStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.classicdice.HistoryResult.encode(message.results[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ClassicDiceStateReply message, length delimited. Does not implicitly {@link classicdice.ClassicDiceStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof classicdice.ClassicDiceStateReply
         * @static
         * @param {classicdice.IClassicDiceStateReply} message ClassicDiceStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClassicDiceStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClassicDiceStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof classicdice.ClassicDiceStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {classicdice.ClassicDiceStateReply} ClassicDiceStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClassicDiceStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.classicdice.ClassicDiceStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 3:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.classicdice.HistoryResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClassicDiceStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof classicdice.ClassicDiceStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {classicdice.ClassicDiceStateReply} ClassicDiceStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClassicDiceStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClassicDiceStateReply message.
         * @function verify
         * @memberof classicdice.ClassicDiceStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClassicDiceStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.classicdice.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ClassicDiceStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof classicdice.ClassicDiceStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {classicdice.ClassicDiceStateReply} ClassicDiceStateReply
         */
        ClassicDiceStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.classicdice.ClassicDiceStateReply)
                return object;
            var message = new $root.classicdice.ClassicDiceStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".classicdice.ClassicDiceStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".classicdice.ClassicDiceStateReply.results: object expected");
                    message.results[i] = $root.classicdice.HistoryResult.fromObject(object.results[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ClassicDiceStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof classicdice.ClassicDiceStateReply
         * @static
         * @param {classicdice.ClassicDiceStateReply} message ClassicDiceStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClassicDiceStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.results = [];
            if (options.defaults)
                object.params = "";
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.classicdice.HistoryResult.toObject(message.results[j], options);
            }
            return object;
        };

        /**
         * Converts this ClassicDiceStateReply to JSON.
         * @function toJSON
         * @memberof classicdice.ClassicDiceStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClassicDiceStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ClassicDiceStateReply;
    })();

    classicdice.ClassicDiceResultReq = (function() {

        /**
         * Properties of a ClassicDiceResultReq.
         * @memberof classicdice
         * @interface IClassicDiceResultReq
         * @property {number|null} [currencyType] ClassicDiceResultReq currencyType
         * @property {number|Long|null} [betAmount] ClassicDiceResultReq betAmount
         * @property {number|null} [rollType] ClassicDiceResultReq rollType
         * @property {number|null} [rollNum] ClassicDiceResultReq rollNum
         */

        /**
         * Constructs a new ClassicDiceResultReq.
         * @memberof classicdice
         * @classdesc Represents a ClassicDiceResultReq.
         * @implements IClassicDiceResultReq
         * @constructor
         * @param {classicdice.IClassicDiceResultReq=} [properties] Properties to set
         */
        function ClassicDiceResultReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClassicDiceResultReq currencyType.
         * @member {number} currencyType
         * @memberof classicdice.ClassicDiceResultReq
         * @instance
         */
        ClassicDiceResultReq.prototype.currencyType = 0;

        /**
         * ClassicDiceResultReq betAmount.
         * @member {number|Long} betAmount
         * @memberof classicdice.ClassicDiceResultReq
         * @instance
         */
        ClassicDiceResultReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ClassicDiceResultReq rollType.
         * @member {number} rollType
         * @memberof classicdice.ClassicDiceResultReq
         * @instance
         */
        ClassicDiceResultReq.prototype.rollType = 0;

        /**
         * ClassicDiceResultReq rollNum.
         * @member {number} rollNum
         * @memberof classicdice.ClassicDiceResultReq
         * @instance
         */
        ClassicDiceResultReq.prototype.rollNum = 0;

        /**
         * Creates a new ClassicDiceResultReq instance using the specified properties.
         * @function create
         * @memberof classicdice.ClassicDiceResultReq
         * @static
         * @param {classicdice.IClassicDiceResultReq=} [properties] Properties to set
         * @returns {classicdice.ClassicDiceResultReq} ClassicDiceResultReq instance
         */
        ClassicDiceResultReq.create = function create(properties) {
            return new ClassicDiceResultReq(properties);
        };

        /**
         * Encodes the specified ClassicDiceResultReq message. Does not implicitly {@link classicdice.ClassicDiceResultReq.verify|verify} messages.
         * @function encode
         * @memberof classicdice.ClassicDiceResultReq
         * @static
         * @param {classicdice.IClassicDiceResultReq} message ClassicDiceResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClassicDiceResultReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            if (message.rollType != null && Object.hasOwnProperty.call(message, "rollType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rollType);
            if (message.rollNum != null && Object.hasOwnProperty.call(message, "rollNum"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.rollNum);
            return writer;
        };

        /**
         * Encodes the specified ClassicDiceResultReq message, length delimited. Does not implicitly {@link classicdice.ClassicDiceResultReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof classicdice.ClassicDiceResultReq
         * @static
         * @param {classicdice.IClassicDiceResultReq} message ClassicDiceResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClassicDiceResultReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClassicDiceResultReq message from the specified reader or buffer.
         * @function decode
         * @memberof classicdice.ClassicDiceResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {classicdice.ClassicDiceResultReq} ClassicDiceResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClassicDiceResultReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.classicdice.ClassicDiceResultReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                case 3:
                    message.rollType = reader.int32();
                    break;
                case 4:
                    message.rollNum = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClassicDiceResultReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof classicdice.ClassicDiceResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {classicdice.ClassicDiceResultReq} ClassicDiceResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClassicDiceResultReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClassicDiceResultReq message.
         * @function verify
         * @memberof classicdice.ClassicDiceResultReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClassicDiceResultReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.rollType != null && message.hasOwnProperty("rollType"))
                if (!$util.isInteger(message.rollType))
                    return "rollType: integer expected";
            if (message.rollNum != null && message.hasOwnProperty("rollNum"))
                if (!$util.isInteger(message.rollNum))
                    return "rollNum: integer expected";
            return null;
        };

        /**
         * Creates a ClassicDiceResultReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof classicdice.ClassicDiceResultReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {classicdice.ClassicDiceResultReq} ClassicDiceResultReq
         */
        ClassicDiceResultReq.fromObject = function fromObject(object) {
            if (object instanceof $root.classicdice.ClassicDiceResultReq)
                return object;
            var message = new $root.classicdice.ClassicDiceResultReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.rollType != null)
                message.rollType = object.rollType | 0;
            if (object.rollNum != null)
                message.rollNum = object.rollNum | 0;
            return message;
        };

        /**
         * Creates a plain object from a ClassicDiceResultReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof classicdice.ClassicDiceResultReq
         * @static
         * @param {classicdice.ClassicDiceResultReq} message ClassicDiceResultReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClassicDiceResultReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.rollType = 0;
                object.rollNum = 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.rollType != null && message.hasOwnProperty("rollType"))
                object.rollType = message.rollType;
            if (message.rollNum != null && message.hasOwnProperty("rollNum"))
                object.rollNum = message.rollNum;
            return object;
        };

        /**
         * Converts this ClassicDiceResultReq to JSON.
         * @function toJSON
         * @memberof classicdice.ClassicDiceResultReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClassicDiceResultReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ClassicDiceResultReq;
    })();

    classicdice.ClassicDiceResultReply = (function() {

        /**
         * Properties of a ClassicDiceResultReply.
         * @memberof classicdice
         * @interface IClassicDiceResultReply
         * @property {number|null} [awardNum] ClassicDiceResultReply awardNum
         * @property {number|null} [currencyType] ClassicDiceResultReply currencyType
         * @property {number|Long|null} [winAmount] ClassicDiceResultReply winAmount
         * @property {number|Long|null} [betAmount] ClassicDiceResultReply betAmount
         * @property {string|null} [gameCode] ClassicDiceResultReply gameCode
         * @property {number|null} [result] ClassicDiceResultReply result
         */

        /**
         * Constructs a new ClassicDiceResultReply.
         * @memberof classicdice
         * @classdesc Represents a ClassicDiceResultReply.
         * @implements IClassicDiceResultReply
         * @constructor
         * @param {classicdice.IClassicDiceResultReply=} [properties] Properties to set
         */
        function ClassicDiceResultReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClassicDiceResultReply awardNum.
         * @member {number} awardNum
         * @memberof classicdice.ClassicDiceResultReply
         * @instance
         */
        ClassicDiceResultReply.prototype.awardNum = 0;

        /**
         * ClassicDiceResultReply currencyType.
         * @member {number} currencyType
         * @memberof classicdice.ClassicDiceResultReply
         * @instance
         */
        ClassicDiceResultReply.prototype.currencyType = 0;

        /**
         * ClassicDiceResultReply winAmount.
         * @member {number|Long} winAmount
         * @memberof classicdice.ClassicDiceResultReply
         * @instance
         */
        ClassicDiceResultReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ClassicDiceResultReply betAmount.
         * @member {number|Long} betAmount
         * @memberof classicdice.ClassicDiceResultReply
         * @instance
         */
        ClassicDiceResultReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ClassicDiceResultReply gameCode.
         * @member {string} gameCode
         * @memberof classicdice.ClassicDiceResultReply
         * @instance
         */
        ClassicDiceResultReply.prototype.gameCode = "";

        /**
         * ClassicDiceResultReply result.
         * @member {number} result
         * @memberof classicdice.ClassicDiceResultReply
         * @instance
         */
        ClassicDiceResultReply.prototype.result = 0;

        /**
         * Creates a new ClassicDiceResultReply instance using the specified properties.
         * @function create
         * @memberof classicdice.ClassicDiceResultReply
         * @static
         * @param {classicdice.IClassicDiceResultReply=} [properties] Properties to set
         * @returns {classicdice.ClassicDiceResultReply} ClassicDiceResultReply instance
         */
        ClassicDiceResultReply.create = function create(properties) {
            return new ClassicDiceResultReply(properties);
        };

        /**
         * Encodes the specified ClassicDiceResultReply message. Does not implicitly {@link classicdice.ClassicDiceResultReply.verify|verify} messages.
         * @function encode
         * @memberof classicdice.ClassicDiceResultReply
         * @static
         * @param {classicdice.IClassicDiceResultReply} message ClassicDiceResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClassicDiceResultReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.awardNum != null && Object.hasOwnProperty.call(message, "awardNum"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.awardNum);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.winAmount);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.betAmount);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified ClassicDiceResultReply message, length delimited. Does not implicitly {@link classicdice.ClassicDiceResultReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof classicdice.ClassicDiceResultReply
         * @static
         * @param {classicdice.IClassicDiceResultReply} message ClassicDiceResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClassicDiceResultReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClassicDiceResultReply message from the specified reader or buffer.
         * @function decode
         * @memberof classicdice.ClassicDiceResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {classicdice.ClassicDiceResultReply} ClassicDiceResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClassicDiceResultReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.classicdice.ClassicDiceResultReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.awardNum = reader.int32();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.winAmount = reader.int64();
                    break;
                case 4:
                    message.betAmount = reader.int64();
                    break;
                case 5:
                    message.gameCode = reader.string();
                    break;
                case 6:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClassicDiceResultReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof classicdice.ClassicDiceResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {classicdice.ClassicDiceResultReply} ClassicDiceResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClassicDiceResultReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClassicDiceResultReply message.
         * @function verify
         * @memberof classicdice.ClassicDiceResultReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClassicDiceResultReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.awardNum != null && message.hasOwnProperty("awardNum"))
                if (!$util.isInteger(message.awardNum))
                    return "awardNum: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a ClassicDiceResultReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof classicdice.ClassicDiceResultReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {classicdice.ClassicDiceResultReply} ClassicDiceResultReply
         */
        ClassicDiceResultReply.fromObject = function fromObject(object) {
            if (object instanceof $root.classicdice.ClassicDiceResultReply)
                return object;
            var message = new $root.classicdice.ClassicDiceResultReply();
            if (object.awardNum != null)
                message.awardNum = object.awardNum | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a ClassicDiceResultReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof classicdice.ClassicDiceResultReply
         * @static
         * @param {classicdice.ClassicDiceResultReply} message ClassicDiceResultReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClassicDiceResultReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.awardNum = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.gameCode = "";
                object.result = 0;
            }
            if (message.awardNum != null && message.hasOwnProperty("awardNum"))
                object.awardNum = message.awardNum;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this ClassicDiceResultReply to JSON.
         * @function toJSON
         * @memberof classicdice.ClassicDiceResultReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClassicDiceResultReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ClassicDiceResultReply;
    })();

    classicdice.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof classicdice
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         * @property {boolean|null} [win] HistoryResult win
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof classicdice
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {classicdice.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof classicdice.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof classicdice.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * HistoryResult win.
         * @member {boolean} win
         * @memberof classicdice.HistoryResult
         * @instance
         */
        HistoryResult.prototype.win = false;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof classicdice.HistoryResult
         * @static
         * @param {classicdice.IHistoryResult=} [properties] Properties to set
         * @returns {classicdice.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link classicdice.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof classicdice.HistoryResult
         * @static
         * @param {classicdice.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            if (message.win != null && Object.hasOwnProperty.call(message, "win"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.win);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link classicdice.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof classicdice.HistoryResult
         * @static
         * @param {classicdice.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof classicdice.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {classicdice.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.classicdice.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                case 3:
                    message.win = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof classicdice.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {classicdice.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof classicdice.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            if (message.win != null && message.hasOwnProperty("win"))
                if (typeof message.win !== "boolean")
                    return "win: boolean expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof classicdice.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {classicdice.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.classicdice.HistoryResult)
                return object;
            var message = new $root.classicdice.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            if (object.win != null)
                message.win = Boolean(object.win);
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof classicdice.HistoryResult
         * @static
         * @param {classicdice.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
                object.win = false;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.win != null && message.hasOwnProperty("win"))
                object.win = message.win;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof classicdice.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return classicdice;
})();

$root.ringoffortune = (function() {

    /**
     * Namespace ringoffortune.
     * @exports ringoffortune
     * @namespace
     */
    var ringoffortune = {};

    ringoffortune.RingOfFortuneStateReply = (function() {

        /**
         * Properties of a RingOfFortuneStateReply.
         * @memberof ringoffortune
         * @interface IRingOfFortuneStateReply
         * @property {string|null} [params] RingOfFortuneStateReply params
         * @property {string|null} [config] RingOfFortuneStateReply config
         * @property {Array.<ringoffortune.IHistoryResult>|null} [results] RingOfFortuneStateReply results
         */

        /**
         * Constructs a new RingOfFortuneStateReply.
         * @memberof ringoffortune
         * @classdesc Represents a RingOfFortuneStateReply.
         * @implements IRingOfFortuneStateReply
         * @constructor
         * @param {ringoffortune.IRingOfFortuneStateReply=} [properties] Properties to set
         */
        function RingOfFortuneStateReply(properties) {
            this.results = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RingOfFortuneStateReply params.
         * @member {string} params
         * @memberof ringoffortune.RingOfFortuneStateReply
         * @instance
         */
        RingOfFortuneStateReply.prototype.params = "";

        /**
         * RingOfFortuneStateReply config.
         * @member {string} config
         * @memberof ringoffortune.RingOfFortuneStateReply
         * @instance
         */
        RingOfFortuneStateReply.prototype.config = "";

        /**
         * RingOfFortuneStateReply results.
         * @member {Array.<ringoffortune.IHistoryResult>} results
         * @memberof ringoffortune.RingOfFortuneStateReply
         * @instance
         */
        RingOfFortuneStateReply.prototype.results = $util.emptyArray;

        /**
         * Creates a new RingOfFortuneStateReply instance using the specified properties.
         * @function create
         * @memberof ringoffortune.RingOfFortuneStateReply
         * @static
         * @param {ringoffortune.IRingOfFortuneStateReply=} [properties] Properties to set
         * @returns {ringoffortune.RingOfFortuneStateReply} RingOfFortuneStateReply instance
         */
        RingOfFortuneStateReply.create = function create(properties) {
            return new RingOfFortuneStateReply(properties);
        };

        /**
         * Encodes the specified RingOfFortuneStateReply message. Does not implicitly {@link ringoffortune.RingOfFortuneStateReply.verify|verify} messages.
         * @function encode
         * @memberof ringoffortune.RingOfFortuneStateReply
         * @static
         * @param {ringoffortune.IRingOfFortuneStateReply} message RingOfFortuneStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RingOfFortuneStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.config);
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.ringoffortune.HistoryResult.encode(message.results[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RingOfFortuneStateReply message, length delimited. Does not implicitly {@link ringoffortune.RingOfFortuneStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ringoffortune.RingOfFortuneStateReply
         * @static
         * @param {ringoffortune.IRingOfFortuneStateReply} message RingOfFortuneStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RingOfFortuneStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RingOfFortuneStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof ringoffortune.RingOfFortuneStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ringoffortune.RingOfFortuneStateReply} RingOfFortuneStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RingOfFortuneStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ringoffortune.RingOfFortuneStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 2:
                    message.config = reader.string();
                    break;
                case 3:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.ringoffortune.HistoryResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RingOfFortuneStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ringoffortune.RingOfFortuneStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ringoffortune.RingOfFortuneStateReply} RingOfFortuneStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RingOfFortuneStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RingOfFortuneStateReply message.
         * @function verify
         * @memberof ringoffortune.RingOfFortuneStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RingOfFortuneStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.config != null && message.hasOwnProperty("config"))
                if (!$util.isString(message.config))
                    return "config: string expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.ringoffortune.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RingOfFortuneStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ringoffortune.RingOfFortuneStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ringoffortune.RingOfFortuneStateReply} RingOfFortuneStateReply
         */
        RingOfFortuneStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.ringoffortune.RingOfFortuneStateReply)
                return object;
            var message = new $root.ringoffortune.RingOfFortuneStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.config != null)
                message.config = String(object.config);
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".ringoffortune.RingOfFortuneStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".ringoffortune.RingOfFortuneStateReply.results: object expected");
                    message.results[i] = $root.ringoffortune.HistoryResult.fromObject(object.results[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RingOfFortuneStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ringoffortune.RingOfFortuneStateReply
         * @static
         * @param {ringoffortune.RingOfFortuneStateReply} message RingOfFortuneStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RingOfFortuneStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.results = [];
            if (options.defaults) {
                object.params = "";
                object.config = "";
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.config != null && message.hasOwnProperty("config"))
                object.config = message.config;
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.ringoffortune.HistoryResult.toObject(message.results[j], options);
            }
            return object;
        };

        /**
         * Converts this RingOfFortuneStateReply to JSON.
         * @function toJSON
         * @memberof ringoffortune.RingOfFortuneStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RingOfFortuneStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RingOfFortuneStateReply;
    })();

    ringoffortune.RingOfFortuneResultReq = (function() {

        /**
         * Properties of a RingOfFortuneResultReq.
         * @memberof ringoffortune
         * @interface IRingOfFortuneResultReq
         * @property {number|null} [currencyType] RingOfFortuneResultReq currencyType
         * @property {Array.<number|Long>|null} [betAmounts] RingOfFortuneResultReq betAmounts
         */

        /**
         * Constructs a new RingOfFortuneResultReq.
         * @memberof ringoffortune
         * @classdesc Represents a RingOfFortuneResultReq.
         * @implements IRingOfFortuneResultReq
         * @constructor
         * @param {ringoffortune.IRingOfFortuneResultReq=} [properties] Properties to set
         */
        function RingOfFortuneResultReq(properties) {
            this.betAmounts = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RingOfFortuneResultReq currencyType.
         * @member {number} currencyType
         * @memberof ringoffortune.RingOfFortuneResultReq
         * @instance
         */
        RingOfFortuneResultReq.prototype.currencyType = 0;

        /**
         * RingOfFortuneResultReq betAmounts.
         * @member {Array.<number|Long>} betAmounts
         * @memberof ringoffortune.RingOfFortuneResultReq
         * @instance
         */
        RingOfFortuneResultReq.prototype.betAmounts = $util.emptyArray;

        /**
         * Creates a new RingOfFortuneResultReq instance using the specified properties.
         * @function create
         * @memberof ringoffortune.RingOfFortuneResultReq
         * @static
         * @param {ringoffortune.IRingOfFortuneResultReq=} [properties] Properties to set
         * @returns {ringoffortune.RingOfFortuneResultReq} RingOfFortuneResultReq instance
         */
        RingOfFortuneResultReq.create = function create(properties) {
            return new RingOfFortuneResultReq(properties);
        };

        /**
         * Encodes the specified RingOfFortuneResultReq message. Does not implicitly {@link ringoffortune.RingOfFortuneResultReq.verify|verify} messages.
         * @function encode
         * @memberof ringoffortune.RingOfFortuneResultReq
         * @static
         * @param {ringoffortune.IRingOfFortuneResultReq} message RingOfFortuneResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RingOfFortuneResultReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmounts != null && message.betAmounts.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.betAmounts.length; ++i)
                    writer.int64(message.betAmounts[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified RingOfFortuneResultReq message, length delimited. Does not implicitly {@link ringoffortune.RingOfFortuneResultReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ringoffortune.RingOfFortuneResultReq
         * @static
         * @param {ringoffortune.IRingOfFortuneResultReq} message RingOfFortuneResultReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RingOfFortuneResultReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RingOfFortuneResultReq message from the specified reader or buffer.
         * @function decode
         * @memberof ringoffortune.RingOfFortuneResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ringoffortune.RingOfFortuneResultReq} RingOfFortuneResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RingOfFortuneResultReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ringoffortune.RingOfFortuneResultReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    if (!(message.betAmounts && message.betAmounts.length))
                        message.betAmounts = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.betAmounts.push(reader.int64());
                    } else
                        message.betAmounts.push(reader.int64());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RingOfFortuneResultReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ringoffortune.RingOfFortuneResultReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ringoffortune.RingOfFortuneResultReq} RingOfFortuneResultReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RingOfFortuneResultReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RingOfFortuneResultReq message.
         * @function verify
         * @memberof ringoffortune.RingOfFortuneResultReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RingOfFortuneResultReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmounts != null && message.hasOwnProperty("betAmounts")) {
                if (!Array.isArray(message.betAmounts))
                    return "betAmounts: array expected";
                for (var i = 0; i < message.betAmounts.length; ++i)
                    if (!$util.isInteger(message.betAmounts[i]) && !(message.betAmounts[i] && $util.isInteger(message.betAmounts[i].low) && $util.isInteger(message.betAmounts[i].high)))
                        return "betAmounts: integer|Long[] expected";
            }
            return null;
        };

        /**
         * Creates a RingOfFortuneResultReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ringoffortune.RingOfFortuneResultReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ringoffortune.RingOfFortuneResultReq} RingOfFortuneResultReq
         */
        RingOfFortuneResultReq.fromObject = function fromObject(object) {
            if (object instanceof $root.ringoffortune.RingOfFortuneResultReq)
                return object;
            var message = new $root.ringoffortune.RingOfFortuneResultReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmounts) {
                if (!Array.isArray(object.betAmounts))
                    throw TypeError(".ringoffortune.RingOfFortuneResultReq.betAmounts: array expected");
                message.betAmounts = [];
                for (var i = 0; i < object.betAmounts.length; ++i)
                    if ($util.Long)
                        (message.betAmounts[i] = $util.Long.fromValue(object.betAmounts[i])).unsigned = false;
                    else if (typeof object.betAmounts[i] === "string")
                        message.betAmounts[i] = parseInt(object.betAmounts[i], 10);
                    else if (typeof object.betAmounts[i] === "number")
                        message.betAmounts[i] = object.betAmounts[i];
                    else if (typeof object.betAmounts[i] === "object")
                        message.betAmounts[i] = new $util.LongBits(object.betAmounts[i].low >>> 0, object.betAmounts[i].high >>> 0).toNumber();
            }
            return message;
        };

        /**
         * Creates a plain object from a RingOfFortuneResultReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ringoffortune.RingOfFortuneResultReq
         * @static
         * @param {ringoffortune.RingOfFortuneResultReq} message RingOfFortuneResultReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RingOfFortuneResultReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.betAmounts = [];
            if (options.defaults)
                object.currencyType = 0;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmounts && message.betAmounts.length) {
                object.betAmounts = [];
                for (var j = 0; j < message.betAmounts.length; ++j)
                    if (typeof message.betAmounts[j] === "number")
                        object.betAmounts[j] = options.longs === String ? String(message.betAmounts[j]) : message.betAmounts[j];
                    else
                        object.betAmounts[j] = options.longs === String ? $util.Long.prototype.toString.call(message.betAmounts[j]) : options.longs === Number ? new $util.LongBits(message.betAmounts[j].low >>> 0, message.betAmounts[j].high >>> 0).toNumber() : message.betAmounts[j];
            }
            return object;
        };

        /**
         * Converts this RingOfFortuneResultReq to JSON.
         * @function toJSON
         * @memberof ringoffortune.RingOfFortuneResultReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RingOfFortuneResultReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RingOfFortuneResultReq;
    })();

    ringoffortune.RingOfFortuneResultReply = (function() {

        /**
         * Properties of a RingOfFortuneResultReply.
         * @memberof ringoffortune
         * @interface IRingOfFortuneResultReply
         * @property {number|null} [awardIndex] RingOfFortuneResultReply awardIndex
         * @property {number|null} [currencyType] RingOfFortuneResultReply currencyType
         * @property {Array.<number|Long>|null} [betAmounts] RingOfFortuneResultReply betAmounts
         * @property {number|Long|null} [winAmount] RingOfFortuneResultReply winAmount
         * @property {string|null} [gameCode] RingOfFortuneResultReply gameCode
         * @property {number|null} [result] RingOfFortuneResultReply result
         * @property {number|null} [payout] RingOfFortuneResultReply payout
         */

        /**
         * Constructs a new RingOfFortuneResultReply.
         * @memberof ringoffortune
         * @classdesc Represents a RingOfFortuneResultReply.
         * @implements IRingOfFortuneResultReply
         * @constructor
         * @param {ringoffortune.IRingOfFortuneResultReply=} [properties] Properties to set
         */
        function RingOfFortuneResultReply(properties) {
            this.betAmounts = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RingOfFortuneResultReply awardIndex.
         * @member {number} awardIndex
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @instance
         */
        RingOfFortuneResultReply.prototype.awardIndex = 0;

        /**
         * RingOfFortuneResultReply currencyType.
         * @member {number} currencyType
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @instance
         */
        RingOfFortuneResultReply.prototype.currencyType = 0;

        /**
         * RingOfFortuneResultReply betAmounts.
         * @member {Array.<number|Long>} betAmounts
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @instance
         */
        RingOfFortuneResultReply.prototype.betAmounts = $util.emptyArray;

        /**
         * RingOfFortuneResultReply winAmount.
         * @member {number|Long} winAmount
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @instance
         */
        RingOfFortuneResultReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RingOfFortuneResultReply gameCode.
         * @member {string} gameCode
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @instance
         */
        RingOfFortuneResultReply.prototype.gameCode = "";

        /**
         * RingOfFortuneResultReply result.
         * @member {number} result
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @instance
         */
        RingOfFortuneResultReply.prototype.result = 0;

        /**
         * RingOfFortuneResultReply payout.
         * @member {number} payout
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @instance
         */
        RingOfFortuneResultReply.prototype.payout = 0;

        /**
         * Creates a new RingOfFortuneResultReply instance using the specified properties.
         * @function create
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @static
         * @param {ringoffortune.IRingOfFortuneResultReply=} [properties] Properties to set
         * @returns {ringoffortune.RingOfFortuneResultReply} RingOfFortuneResultReply instance
         */
        RingOfFortuneResultReply.create = function create(properties) {
            return new RingOfFortuneResultReply(properties);
        };

        /**
         * Encodes the specified RingOfFortuneResultReply message. Does not implicitly {@link ringoffortune.RingOfFortuneResultReply.verify|verify} messages.
         * @function encode
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @static
         * @param {ringoffortune.IRingOfFortuneResultReply} message RingOfFortuneResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RingOfFortuneResultReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.awardIndex != null && Object.hasOwnProperty.call(message, "awardIndex"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.awardIndex);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.betAmounts != null && message.betAmounts.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.betAmounts.length; ++i)
                    writer.int64(message.betAmounts[i]);
                writer.ldelim();
            }
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.winAmount);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.result);
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.payout);
            return writer;
        };

        /**
         * Encodes the specified RingOfFortuneResultReply message, length delimited. Does not implicitly {@link ringoffortune.RingOfFortuneResultReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @static
         * @param {ringoffortune.IRingOfFortuneResultReply} message RingOfFortuneResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RingOfFortuneResultReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RingOfFortuneResultReply message from the specified reader or buffer.
         * @function decode
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ringoffortune.RingOfFortuneResultReply} RingOfFortuneResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RingOfFortuneResultReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ringoffortune.RingOfFortuneResultReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.awardIndex = reader.int32();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    if (!(message.betAmounts && message.betAmounts.length))
                        message.betAmounts = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.betAmounts.push(reader.int64());
                    } else
                        message.betAmounts.push(reader.int64());
                    break;
                case 4:
                    message.winAmount = reader.int64();
                    break;
                case 5:
                    message.gameCode = reader.string();
                    break;
                case 6:
                    message.result = reader.int32();
                    break;
                case 7:
                    message.payout = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RingOfFortuneResultReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ringoffortune.RingOfFortuneResultReply} RingOfFortuneResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RingOfFortuneResultReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RingOfFortuneResultReply message.
         * @function verify
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RingOfFortuneResultReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.awardIndex != null && message.hasOwnProperty("awardIndex"))
                if (!$util.isInteger(message.awardIndex))
                    return "awardIndex: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmounts != null && message.hasOwnProperty("betAmounts")) {
                if (!Array.isArray(message.betAmounts))
                    return "betAmounts: array expected";
                for (var i = 0; i < message.betAmounts.length; ++i)
                    if (!$util.isInteger(message.betAmounts[i]) && !(message.betAmounts[i] && $util.isInteger(message.betAmounts[i].low) && $util.isInteger(message.betAmounts[i].high)))
                        return "betAmounts: integer|Long[] expected";
            }
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isInteger(message.payout))
                    return "payout: integer expected";
            return null;
        };

        /**
         * Creates a RingOfFortuneResultReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ringoffortune.RingOfFortuneResultReply} RingOfFortuneResultReply
         */
        RingOfFortuneResultReply.fromObject = function fromObject(object) {
            if (object instanceof $root.ringoffortune.RingOfFortuneResultReply)
                return object;
            var message = new $root.ringoffortune.RingOfFortuneResultReply();
            if (object.awardIndex != null)
                message.awardIndex = object.awardIndex | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmounts) {
                if (!Array.isArray(object.betAmounts))
                    throw TypeError(".ringoffortune.RingOfFortuneResultReply.betAmounts: array expected");
                message.betAmounts = [];
                for (var i = 0; i < object.betAmounts.length; ++i)
                    if ($util.Long)
                        (message.betAmounts[i] = $util.Long.fromValue(object.betAmounts[i])).unsigned = false;
                    else if (typeof object.betAmounts[i] === "string")
                        message.betAmounts[i] = parseInt(object.betAmounts[i], 10);
                    else if (typeof object.betAmounts[i] === "number")
                        message.betAmounts[i] = object.betAmounts[i];
                    else if (typeof object.betAmounts[i] === "object")
                        message.betAmounts[i] = new $util.LongBits(object.betAmounts[i].low >>> 0, object.betAmounts[i].high >>> 0).toNumber();
            }
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            if (object.payout != null)
                message.payout = object.payout | 0;
            return message;
        };

        /**
         * Creates a plain object from a RingOfFortuneResultReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @static
         * @param {ringoffortune.RingOfFortuneResultReply} message RingOfFortuneResultReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RingOfFortuneResultReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.betAmounts = [];
            if (options.defaults) {
                object.awardIndex = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                object.gameCode = "";
                object.result = 0;
                object.payout = 0;
            }
            if (message.awardIndex != null && message.hasOwnProperty("awardIndex"))
                object.awardIndex = message.awardIndex;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmounts && message.betAmounts.length) {
                object.betAmounts = [];
                for (var j = 0; j < message.betAmounts.length; ++j)
                    if (typeof message.betAmounts[j] === "number")
                        object.betAmounts[j] = options.longs === String ? String(message.betAmounts[j]) : message.betAmounts[j];
                    else
                        object.betAmounts[j] = options.longs === String ? $util.Long.prototype.toString.call(message.betAmounts[j]) : options.longs === Number ? new $util.LongBits(message.betAmounts[j].low >>> 0, message.betAmounts[j].high >>> 0).toNumber() : message.betAmounts[j];
            }
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            return object;
        };

        /**
         * Converts this RingOfFortuneResultReply to JSON.
         * @function toJSON
         * @memberof ringoffortune.RingOfFortuneResultReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RingOfFortuneResultReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RingOfFortuneResultReply;
    })();

    ringoffortune.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof ringoffortune
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof ringoffortune
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {ringoffortune.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof ringoffortune.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof ringoffortune.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof ringoffortune.HistoryResult
         * @static
         * @param {ringoffortune.IHistoryResult=} [properties] Properties to set
         * @returns {ringoffortune.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link ringoffortune.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof ringoffortune.HistoryResult
         * @static
         * @param {ringoffortune.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link ringoffortune.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ringoffortune.HistoryResult
         * @static
         * @param {ringoffortune.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof ringoffortune.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ringoffortune.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ringoffortune.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ringoffortune.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ringoffortune.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof ringoffortune.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ringoffortune.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ringoffortune.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.ringoffortune.HistoryResult)
                return object;
            var message = new $root.ringoffortune.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ringoffortune.HistoryResult
         * @static
         * @param {ringoffortune.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof ringoffortune.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return ringoffortune;
})();

$root.double = (function() {

    /**
     * Namespace double.
     * @exports double
     * @namespace
     */
    var double = {};

    /**
     * DEADLINE_TYPE enum.
     * @name double.DEADLINE_TYPE
     * @enum {number}
     * @property {number} DT_NONE=0 DT_NONE value
     * @property {number} START_BET=1 START_BET value
     * @property {number} STOP_BET=2 STOP_BET value
     */
    double.DEADLINE_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DT_NONE"] = 0;
        values[valuesById[1] = "START_BET"] = 1;
        values[valuesById[2] = "STOP_BET"] = 2;
        return values;
    })();

    double.GameStartEvent = (function() {

        /**
         * Properties of a GameStartEvent.
         * @memberof double
         * @interface IGameStartEvent
         * @property {string|null} [gameCode] GameStartEvent gameCode
         * @property {string|null} [hashRound] GameStartEvent hashRound
         */

        /**
         * Constructs a new GameStartEvent.
         * @memberof double
         * @classdesc Represents a GameStartEvent.
         * @implements IGameStartEvent
         * @constructor
         * @param {double.IGameStartEvent=} [properties] Properties to set
         */
        function GameStartEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameStartEvent gameCode.
         * @member {string} gameCode
         * @memberof double.GameStartEvent
         * @instance
         */
        GameStartEvent.prototype.gameCode = "";

        /**
         * GameStartEvent hashRound.
         * @member {string} hashRound
         * @memberof double.GameStartEvent
         * @instance
         */
        GameStartEvent.prototype.hashRound = "";

        /**
         * Creates a new GameStartEvent instance using the specified properties.
         * @function create
         * @memberof double.GameStartEvent
         * @static
         * @param {double.IGameStartEvent=} [properties] Properties to set
         * @returns {double.GameStartEvent} GameStartEvent instance
         */
        GameStartEvent.create = function create(properties) {
            return new GameStartEvent(properties);
        };

        /**
         * Encodes the specified GameStartEvent message. Does not implicitly {@link double.GameStartEvent.verify|verify} messages.
         * @function encode
         * @memberof double.GameStartEvent
         * @static
         * @param {double.IGameStartEvent} message GameStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameStartEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.hashRound != null && Object.hasOwnProperty.call(message, "hashRound"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.hashRound);
            return writer;
        };

        /**
         * Encodes the specified GameStartEvent message, length delimited. Does not implicitly {@link double.GameStartEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof double.GameStartEvent
         * @static
         * @param {double.IGameStartEvent} message GameStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameStartEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameStartEvent message from the specified reader or buffer.
         * @function decode
         * @memberof double.GameStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {double.GameStartEvent} GameStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameStartEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.double.GameStartEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.hashRound = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameStartEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof double.GameStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {double.GameStartEvent} GameStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameStartEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameStartEvent message.
         * @function verify
         * @memberof double.GameStartEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameStartEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                if (!$util.isString(message.hashRound))
                    return "hashRound: string expected";
            return null;
        };

        /**
         * Creates a GameStartEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof double.GameStartEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {double.GameStartEvent} GameStartEvent
         */
        GameStartEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.double.GameStartEvent)
                return object;
            var message = new $root.double.GameStartEvent();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.hashRound != null)
                message.hashRound = String(object.hashRound);
            return message;
        };

        /**
         * Creates a plain object from a GameStartEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof double.GameStartEvent
         * @static
         * @param {double.GameStartEvent} message GameStartEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameStartEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.hashRound = "";
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                object.hashRound = message.hashRound;
            return object;
        };

        /**
         * Converts this GameStartEvent to JSON.
         * @function toJSON
         * @memberof double.GameStartEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameStartEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameStartEvent;
    })();

    double.DoublePlayersEvent = (function() {

        /**
         * Properties of a DoublePlayersEvent.
         * @memberof double
         * @interface IDoublePlayersEvent
         * @property {Array.<double.IDoublePlayer>|null} [doublePlayers] DoublePlayersEvent doublePlayers
         * @property {number|null} [playersNum] DoublePlayersEvent playersNum
         */

        /**
         * Constructs a new DoublePlayersEvent.
         * @memberof double
         * @classdesc Represents a DoublePlayersEvent.
         * @implements IDoublePlayersEvent
         * @constructor
         * @param {double.IDoublePlayersEvent=} [properties] Properties to set
         */
        function DoublePlayersEvent(properties) {
            this.doublePlayers = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DoublePlayersEvent doublePlayers.
         * @member {Array.<double.IDoublePlayer>} doublePlayers
         * @memberof double.DoublePlayersEvent
         * @instance
         */
        DoublePlayersEvent.prototype.doublePlayers = $util.emptyArray;

        /**
         * DoublePlayersEvent playersNum.
         * @member {number} playersNum
         * @memberof double.DoublePlayersEvent
         * @instance
         */
        DoublePlayersEvent.prototype.playersNum = 0;

        /**
         * Creates a new DoublePlayersEvent instance using the specified properties.
         * @function create
         * @memberof double.DoublePlayersEvent
         * @static
         * @param {double.IDoublePlayersEvent=} [properties] Properties to set
         * @returns {double.DoublePlayersEvent} DoublePlayersEvent instance
         */
        DoublePlayersEvent.create = function create(properties) {
            return new DoublePlayersEvent(properties);
        };

        /**
         * Encodes the specified DoublePlayersEvent message. Does not implicitly {@link double.DoublePlayersEvent.verify|verify} messages.
         * @function encode
         * @memberof double.DoublePlayersEvent
         * @static
         * @param {double.IDoublePlayersEvent} message DoublePlayersEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DoublePlayersEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.doublePlayers != null && message.doublePlayers.length)
                for (var i = 0; i < message.doublePlayers.length; ++i)
                    $root.double.DoublePlayer.encode(message.doublePlayers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.playersNum != null && Object.hasOwnProperty.call(message, "playersNum"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.playersNum);
            return writer;
        };

        /**
         * Encodes the specified DoublePlayersEvent message, length delimited. Does not implicitly {@link double.DoublePlayersEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof double.DoublePlayersEvent
         * @static
         * @param {double.IDoublePlayersEvent} message DoublePlayersEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DoublePlayersEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DoublePlayersEvent message from the specified reader or buffer.
         * @function decode
         * @memberof double.DoublePlayersEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {double.DoublePlayersEvent} DoublePlayersEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DoublePlayersEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.double.DoublePlayersEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.doublePlayers && message.doublePlayers.length))
                        message.doublePlayers = [];
                    message.doublePlayers.push($root.double.DoublePlayer.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.playersNum = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DoublePlayersEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof double.DoublePlayersEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {double.DoublePlayersEvent} DoublePlayersEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DoublePlayersEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DoublePlayersEvent message.
         * @function verify
         * @memberof double.DoublePlayersEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DoublePlayersEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.doublePlayers != null && message.hasOwnProperty("doublePlayers")) {
                if (!Array.isArray(message.doublePlayers))
                    return "doublePlayers: array expected";
                for (var i = 0; i < message.doublePlayers.length; ++i) {
                    var error = $root.double.DoublePlayer.verify(message.doublePlayers[i]);
                    if (error)
                        return "doublePlayers." + error;
                }
            }
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                if (!$util.isInteger(message.playersNum))
                    return "playersNum: integer expected";
            return null;
        };

        /**
         * Creates a DoublePlayersEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof double.DoublePlayersEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {double.DoublePlayersEvent} DoublePlayersEvent
         */
        DoublePlayersEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.double.DoublePlayersEvent)
                return object;
            var message = new $root.double.DoublePlayersEvent();
            if (object.doublePlayers) {
                if (!Array.isArray(object.doublePlayers))
                    throw TypeError(".double.DoublePlayersEvent.doublePlayers: array expected");
                message.doublePlayers = [];
                for (var i = 0; i < object.doublePlayers.length; ++i) {
                    if (typeof object.doublePlayers[i] !== "object")
                        throw TypeError(".double.DoublePlayersEvent.doublePlayers: object expected");
                    message.doublePlayers[i] = $root.double.DoublePlayer.fromObject(object.doublePlayers[i]);
                }
            }
            if (object.playersNum != null)
                message.playersNum = object.playersNum | 0;
            return message;
        };

        /**
         * Creates a plain object from a DoublePlayersEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof double.DoublePlayersEvent
         * @static
         * @param {double.DoublePlayersEvent} message DoublePlayersEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DoublePlayersEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.doublePlayers = [];
            if (options.defaults)
                object.playersNum = 0;
            if (message.doublePlayers && message.doublePlayers.length) {
                object.doublePlayers = [];
                for (var j = 0; j < message.doublePlayers.length; ++j)
                    object.doublePlayers[j] = $root.double.DoublePlayer.toObject(message.doublePlayers[j], options);
            }
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                object.playersNum = message.playersNum;
            return object;
        };

        /**
         * Converts this DoublePlayersEvent to JSON.
         * @function toJSON
         * @memberof double.DoublePlayersEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DoublePlayersEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DoublePlayersEvent;
    })();

    double.DoublePlayer = (function() {

        /**
         * Properties of a DoublePlayer.
         * @memberof double
         * @interface IDoublePlayer
         * @property {number|null} [seat] DoublePlayer seat
         * @property {base.IPlayerBaseInfo|null} [baseInfo] DoublePlayer baseInfo
         * @property {boolean|null} [online] DoublePlayer online
         * @property {number|Long|null} [balance] DoublePlayer balance
         * @property {number|null} [currencyType] DoublePlayer currencyType
         * @property {number|Long|null} [betAmount] DoublePlayer betAmount
         * @property {number|null} [betColor] DoublePlayer betColor
         */

        /**
         * Constructs a new DoublePlayer.
         * @memberof double
         * @classdesc Represents a DoublePlayer.
         * @implements IDoublePlayer
         * @constructor
         * @param {double.IDoublePlayer=} [properties] Properties to set
         */
        function DoublePlayer(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DoublePlayer seat.
         * @member {number} seat
         * @memberof double.DoublePlayer
         * @instance
         */
        DoublePlayer.prototype.seat = 0;

        /**
         * DoublePlayer baseInfo.
         * @member {base.IPlayerBaseInfo|null|undefined} baseInfo
         * @memberof double.DoublePlayer
         * @instance
         */
        DoublePlayer.prototype.baseInfo = null;

        /**
         * DoublePlayer online.
         * @member {boolean} online
         * @memberof double.DoublePlayer
         * @instance
         */
        DoublePlayer.prototype.online = false;

        /**
         * DoublePlayer balance.
         * @member {number|Long} balance
         * @memberof double.DoublePlayer
         * @instance
         */
        DoublePlayer.prototype.balance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DoublePlayer currencyType.
         * @member {number} currencyType
         * @memberof double.DoublePlayer
         * @instance
         */
        DoublePlayer.prototype.currencyType = 0;

        /**
         * DoublePlayer betAmount.
         * @member {number|Long} betAmount
         * @memberof double.DoublePlayer
         * @instance
         */
        DoublePlayer.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DoublePlayer betColor.
         * @member {number} betColor
         * @memberof double.DoublePlayer
         * @instance
         */
        DoublePlayer.prototype.betColor = 0;

        /**
         * Creates a new DoublePlayer instance using the specified properties.
         * @function create
         * @memberof double.DoublePlayer
         * @static
         * @param {double.IDoublePlayer=} [properties] Properties to set
         * @returns {double.DoublePlayer} DoublePlayer instance
         */
        DoublePlayer.create = function create(properties) {
            return new DoublePlayer(properties);
        };

        /**
         * Encodes the specified DoublePlayer message. Does not implicitly {@link double.DoublePlayer.verify|verify} messages.
         * @function encode
         * @memberof double.DoublePlayer
         * @static
         * @param {double.IDoublePlayer} message DoublePlayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DoublePlayer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            if (message.baseInfo != null && Object.hasOwnProperty.call(message, "baseInfo"))
                $root.base.PlayerBaseInfo.encode(message.baseInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.online != null && Object.hasOwnProperty.call(message, "online"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.online);
            if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.balance);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.betAmount);
            if (message.betColor != null && Object.hasOwnProperty.call(message, "betColor"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.betColor);
            return writer;
        };

        /**
         * Encodes the specified DoublePlayer message, length delimited. Does not implicitly {@link double.DoublePlayer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof double.DoublePlayer
         * @static
         * @param {double.IDoublePlayer} message DoublePlayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DoublePlayer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DoublePlayer message from the specified reader or buffer.
         * @function decode
         * @memberof double.DoublePlayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {double.DoublePlayer} DoublePlayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DoublePlayer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.double.DoublePlayer();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                case 2:
                    message.baseInfo = $root.base.PlayerBaseInfo.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.online = reader.bool();
                    break;
                case 4:
                    message.balance = reader.int64();
                    break;
                case 5:
                    message.currencyType = reader.int32();
                    break;
                case 6:
                    message.betAmount = reader.int64();
                    break;
                case 7:
                    message.betColor = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DoublePlayer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof double.DoublePlayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {double.DoublePlayer} DoublePlayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DoublePlayer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DoublePlayer message.
         * @function verify
         * @memberof double.DoublePlayer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DoublePlayer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo")) {
                var error = $root.base.PlayerBaseInfo.verify(message.baseInfo);
                if (error)
                    return "baseInfo." + error;
            }
            if (message.online != null && message.hasOwnProperty("online"))
                if (typeof message.online !== "boolean")
                    return "online: boolean expected";
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (!$util.isInteger(message.balance) && !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high)))
                    return "balance: integer|Long expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                if (!$util.isInteger(message.betColor))
                    return "betColor: integer expected";
            return null;
        };

        /**
         * Creates a DoublePlayer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof double.DoublePlayer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {double.DoublePlayer} DoublePlayer
         */
        DoublePlayer.fromObject = function fromObject(object) {
            if (object instanceof $root.double.DoublePlayer)
                return object;
            var message = new $root.double.DoublePlayer();
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.baseInfo != null) {
                if (typeof object.baseInfo !== "object")
                    throw TypeError(".double.DoublePlayer.baseInfo: object expected");
                message.baseInfo = $root.base.PlayerBaseInfo.fromObject(object.baseInfo);
            }
            if (object.online != null)
                message.online = Boolean(object.online);
            if (object.balance != null)
                if ($util.Long)
                    (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
                else if (typeof object.balance === "string")
                    message.balance = parseInt(object.balance, 10);
                else if (typeof object.balance === "number")
                    message.balance = object.balance;
                else if (typeof object.balance === "object")
                    message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.betColor != null)
                message.betColor = object.betColor | 0;
            return message;
        };

        /**
         * Creates a plain object from a DoublePlayer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof double.DoublePlayer
         * @static
         * @param {double.DoublePlayer} message DoublePlayer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DoublePlayer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.seat = 0;
                object.baseInfo = null;
                object.online = false;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.balance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.balance = options.longs === String ? "0" : 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.betColor = 0;
            }
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo"))
                object.baseInfo = $root.base.PlayerBaseInfo.toObject(message.baseInfo, options);
            if (message.online != null && message.hasOwnProperty("online"))
                object.online = message.online;
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (typeof message.balance === "number")
                    object.balance = options.longs === String ? String(message.balance) : message.balance;
                else
                    object.balance = options.longs === String ? $util.Long.prototype.toString.call(message.balance) : options.longs === Number ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber() : message.balance;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                object.betColor = message.betColor;
            return object;
        };

        /**
         * Converts this DoublePlayer to JSON.
         * @function toJSON
         * @memberof double.DoublePlayer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DoublePlayer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DoublePlayer;
    })();

    double.TableStateEvent = (function() {

        /**
         * Properties of a TableStateEvent.
         * @memberof double
         * @interface ITableStateEvent
         * @property {string|null} [gameCode] TableStateEvent gameCode
         * @property {number|null} [playersNum] TableStateEvent playersNum
         * @property {string|null} [hashRound] TableStateEvent hashRound
         */

        /**
         * Constructs a new TableStateEvent.
         * @memberof double
         * @classdesc Represents a TableStateEvent.
         * @implements ITableStateEvent
         * @constructor
         * @param {double.ITableStateEvent=} [properties] Properties to set
         */
        function TableStateEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableStateEvent gameCode.
         * @member {string} gameCode
         * @memberof double.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.gameCode = "";

        /**
         * TableStateEvent playersNum.
         * @member {number} playersNum
         * @memberof double.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.playersNum = 0;

        /**
         * TableStateEvent hashRound.
         * @member {string} hashRound
         * @memberof double.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.hashRound = "";

        /**
         * Creates a new TableStateEvent instance using the specified properties.
         * @function create
         * @memberof double.TableStateEvent
         * @static
         * @param {double.ITableStateEvent=} [properties] Properties to set
         * @returns {double.TableStateEvent} TableStateEvent instance
         */
        TableStateEvent.create = function create(properties) {
            return new TableStateEvent(properties);
        };

        /**
         * Encodes the specified TableStateEvent message. Does not implicitly {@link double.TableStateEvent.verify|verify} messages.
         * @function encode
         * @memberof double.TableStateEvent
         * @static
         * @param {double.ITableStateEvent} message TableStateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableStateEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.playersNum != null && Object.hasOwnProperty.call(message, "playersNum"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.playersNum);
            if (message.hashRound != null && Object.hasOwnProperty.call(message, "hashRound"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.hashRound);
            return writer;
        };

        /**
         * Encodes the specified TableStateEvent message, length delimited. Does not implicitly {@link double.TableStateEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof double.TableStateEvent
         * @static
         * @param {double.ITableStateEvent} message TableStateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableStateEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableStateEvent message from the specified reader or buffer.
         * @function decode
         * @memberof double.TableStateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {double.TableStateEvent} TableStateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableStateEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.double.TableStateEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.playersNum = reader.int32();
                    break;
                case 3:
                    message.hashRound = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableStateEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof double.TableStateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {double.TableStateEvent} TableStateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableStateEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableStateEvent message.
         * @function verify
         * @memberof double.TableStateEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableStateEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                if (!$util.isInteger(message.playersNum))
                    return "playersNum: integer expected";
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                if (!$util.isString(message.hashRound))
                    return "hashRound: string expected";
            return null;
        };

        /**
         * Creates a TableStateEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof double.TableStateEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {double.TableStateEvent} TableStateEvent
         */
        TableStateEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.double.TableStateEvent)
                return object;
            var message = new $root.double.TableStateEvent();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.playersNum != null)
                message.playersNum = object.playersNum | 0;
            if (object.hashRound != null)
                message.hashRound = String(object.hashRound);
            return message;
        };

        /**
         * Creates a plain object from a TableStateEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof double.TableStateEvent
         * @static
         * @param {double.TableStateEvent} message TableStateEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableStateEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.playersNum = 0;
                object.hashRound = "";
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                object.playersNum = message.playersNum;
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                object.hashRound = message.hashRound;
            return object;
        };

        /**
         * Converts this TableStateEvent to JSON.
         * @function toJSON
         * @memberof double.TableStateEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableStateEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TableStateEvent;
    })();

    double.HistoryResultEvent = (function() {

        /**
         * Properties of a HistoryResultEvent.
         * @memberof double
         * @interface IHistoryResultEvent
         * @property {Array.<double.IHistoryResult>|null} [historyResults] HistoryResultEvent historyResults
         */

        /**
         * Constructs a new HistoryResultEvent.
         * @memberof double
         * @classdesc Represents a HistoryResultEvent.
         * @implements IHistoryResultEvent
         * @constructor
         * @param {double.IHistoryResultEvent=} [properties] Properties to set
         */
        function HistoryResultEvent(properties) {
            this.historyResults = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResultEvent historyResults.
         * @member {Array.<double.IHistoryResult>} historyResults
         * @memberof double.HistoryResultEvent
         * @instance
         */
        HistoryResultEvent.prototype.historyResults = $util.emptyArray;

        /**
         * Creates a new HistoryResultEvent instance using the specified properties.
         * @function create
         * @memberof double.HistoryResultEvent
         * @static
         * @param {double.IHistoryResultEvent=} [properties] Properties to set
         * @returns {double.HistoryResultEvent} HistoryResultEvent instance
         */
        HistoryResultEvent.create = function create(properties) {
            return new HistoryResultEvent(properties);
        };

        /**
         * Encodes the specified HistoryResultEvent message. Does not implicitly {@link double.HistoryResultEvent.verify|verify} messages.
         * @function encode
         * @memberof double.HistoryResultEvent
         * @static
         * @param {double.IHistoryResultEvent} message HistoryResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResultEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.historyResults != null && message.historyResults.length)
                for (var i = 0; i < message.historyResults.length; ++i)
                    $root.double.HistoryResult.encode(message.historyResults[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HistoryResultEvent message, length delimited. Does not implicitly {@link double.HistoryResultEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof double.HistoryResultEvent
         * @static
         * @param {double.IHistoryResultEvent} message HistoryResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResultEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResultEvent message from the specified reader or buffer.
         * @function decode
         * @memberof double.HistoryResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {double.HistoryResultEvent} HistoryResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResultEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.double.HistoryResultEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.historyResults && message.historyResults.length))
                        message.historyResults = [];
                    message.historyResults.push($root.double.HistoryResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResultEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof double.HistoryResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {double.HistoryResultEvent} HistoryResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResultEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResultEvent message.
         * @function verify
         * @memberof double.HistoryResultEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResultEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.historyResults != null && message.hasOwnProperty("historyResults")) {
                if (!Array.isArray(message.historyResults))
                    return "historyResults: array expected";
                for (var i = 0; i < message.historyResults.length; ++i) {
                    var error = $root.double.HistoryResult.verify(message.historyResults[i]);
                    if (error)
                        return "historyResults." + error;
                }
            }
            return null;
        };

        /**
         * Creates a HistoryResultEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof double.HistoryResultEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {double.HistoryResultEvent} HistoryResultEvent
         */
        HistoryResultEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.double.HistoryResultEvent)
                return object;
            var message = new $root.double.HistoryResultEvent();
            if (object.historyResults) {
                if (!Array.isArray(object.historyResults))
                    throw TypeError(".double.HistoryResultEvent.historyResults: array expected");
                message.historyResults = [];
                for (var i = 0; i < object.historyResults.length; ++i) {
                    if (typeof object.historyResults[i] !== "object")
                        throw TypeError(".double.HistoryResultEvent.historyResults: object expected");
                    message.historyResults[i] = $root.double.HistoryResult.fromObject(object.historyResults[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a HistoryResultEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof double.HistoryResultEvent
         * @static
         * @param {double.HistoryResultEvent} message HistoryResultEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResultEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.historyResults = [];
            if (message.historyResults && message.historyResults.length) {
                object.historyResults = [];
                for (var j = 0; j < message.historyResults.length; ++j)
                    object.historyResults[j] = $root.double.HistoryResult.toObject(message.historyResults[j], options);
            }
            return object;
        };

        /**
         * Converts this HistoryResultEvent to JSON.
         * @function toJSON
         * @memberof double.HistoryResultEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResultEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResultEvent;
    })();

    double.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof double
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof double
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {double.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof double.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof double.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof double.HistoryResult
         * @static
         * @param {double.IHistoryResult=} [properties] Properties to set
         * @returns {double.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link double.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof double.HistoryResult
         * @static
         * @param {double.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link double.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof double.HistoryResult
         * @static
         * @param {double.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof double.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {double.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.double.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof double.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {double.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof double.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof double.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {double.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.double.HistoryResult)
                return object;
            var message = new $root.double.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof double.HistoryResult
         * @static
         * @param {double.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof double.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    double.BetReq = (function() {

        /**
         * Properties of a BetReq.
         * @memberof double
         * @interface IBetReq
         * @property {number|null} [currencyType] BetReq currencyType
         * @property {number|Long|null} [betAmount] BetReq betAmount
         * @property {number|null} [betColor] BetReq betColor
         */

        /**
         * Constructs a new BetReq.
         * @memberof double
         * @classdesc Represents a BetReq.
         * @implements IBetReq
         * @constructor
         * @param {double.IBetReq=} [properties] Properties to set
         */
        function BetReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BetReq currencyType.
         * @member {number} currencyType
         * @memberof double.BetReq
         * @instance
         */
        BetReq.prototype.currencyType = 0;

        /**
         * BetReq betAmount.
         * @member {number|Long} betAmount
         * @memberof double.BetReq
         * @instance
         */
        BetReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BetReq betColor.
         * @member {number} betColor
         * @memberof double.BetReq
         * @instance
         */
        BetReq.prototype.betColor = 0;

        /**
         * Creates a new BetReq instance using the specified properties.
         * @function create
         * @memberof double.BetReq
         * @static
         * @param {double.IBetReq=} [properties] Properties to set
         * @returns {double.BetReq} BetReq instance
         */
        BetReq.create = function create(properties) {
            return new BetReq(properties);
        };

        /**
         * Encodes the specified BetReq message. Does not implicitly {@link double.BetReq.verify|verify} messages.
         * @function encode
         * @memberof double.BetReq
         * @static
         * @param {double.IBetReq} message BetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betColor != null && Object.hasOwnProperty.call(message, "betColor"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.betColor);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.betAmount);
            return writer;
        };

        /**
         * Encodes the specified BetReq message, length delimited. Does not implicitly {@link double.BetReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof double.BetReq
         * @static
         * @param {double.IBetReq} message BetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BetReq message from the specified reader or buffer.
         * @function decode
         * @memberof double.BetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {double.BetReq} BetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.double.BetReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 5:
                    message.betAmount = reader.int64();
                    break;
                case 4:
                    message.betColor = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BetReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof double.BetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {double.BetReq} BetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BetReq message.
         * @function verify
         * @memberof double.BetReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BetReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                if (!$util.isInteger(message.betColor))
                    return "betColor: integer expected";
            return null;
        };

        /**
         * Creates a BetReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof double.BetReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {double.BetReq} BetReq
         */
        BetReq.fromObject = function fromObject(object) {
            if (object instanceof $root.double.BetReq)
                return object;
            var message = new $root.double.BetReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.betColor != null)
                message.betColor = object.betColor | 0;
            return message;
        };

        /**
         * Creates a plain object from a BetReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof double.BetReq
         * @static
         * @param {double.BetReq} message BetReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BetReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                object.betColor = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                object.betColor = message.betColor;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            return object;
        };

        /**
         * Converts this BetReq to JSON.
         * @function toJSON
         * @memberof double.BetReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BetReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BetReq;
    })();

    double.BetEvent = (function() {

        /**
         * Properties of a BetEvent.
         * @memberof double
         * @interface IBetEvent
         * @property {number|null} [seat] BetEvent seat
         * @property {number|null} [currencyType] BetEvent currencyType
         * @property {number|Long|null} [betAmount] BetEvent betAmount
         * @property {number|null} [betColor] BetEvent betColor
         */

        /**
         * Constructs a new BetEvent.
         * @memberof double
         * @classdesc Represents a BetEvent.
         * @implements IBetEvent
         * @constructor
         * @param {double.IBetEvent=} [properties] Properties to set
         */
        function BetEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BetEvent seat.
         * @member {number} seat
         * @memberof double.BetEvent
         * @instance
         */
        BetEvent.prototype.seat = 0;

        /**
         * BetEvent currencyType.
         * @member {number} currencyType
         * @memberof double.BetEvent
         * @instance
         */
        BetEvent.prototype.currencyType = 0;

        /**
         * BetEvent betAmount.
         * @member {number|Long} betAmount
         * @memberof double.BetEvent
         * @instance
         */
        BetEvent.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BetEvent betColor.
         * @member {number} betColor
         * @memberof double.BetEvent
         * @instance
         */
        BetEvent.prototype.betColor = 0;

        /**
         * Creates a new BetEvent instance using the specified properties.
         * @function create
         * @memberof double.BetEvent
         * @static
         * @param {double.IBetEvent=} [properties] Properties to set
         * @returns {double.BetEvent} BetEvent instance
         */
        BetEvent.create = function create(properties) {
            return new BetEvent(properties);
        };

        /**
         * Encodes the specified BetEvent message. Does not implicitly {@link double.BetEvent.verify|verify} messages.
         * @function encode
         * @memberof double.BetEvent
         * @static
         * @param {double.IBetEvent} message BetEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.betAmount);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.currencyType);
            if (message.betColor != null && Object.hasOwnProperty.call(message, "betColor"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.betColor);
            return writer;
        };

        /**
         * Encodes the specified BetEvent message, length delimited. Does not implicitly {@link double.BetEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof double.BetEvent
         * @static
         * @param {double.IBetEvent} message BetEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BetEvent message from the specified reader or buffer.
         * @function decode
         * @memberof double.BetEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {double.BetEvent} BetEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.double.BetEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                case 4:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.betAmount = reader.int64();
                    break;
                case 5:
                    message.betColor = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BetEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof double.BetEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {double.BetEvent} BetEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BetEvent message.
         * @function verify
         * @memberof double.BetEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BetEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                if (!$util.isInteger(message.betColor))
                    return "betColor: integer expected";
            return null;
        };

        /**
         * Creates a BetEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof double.BetEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {double.BetEvent} BetEvent
         */
        BetEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.double.BetEvent)
                return object;
            var message = new $root.double.BetEvent();
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.betColor != null)
                message.betColor = object.betColor | 0;
            return message;
        };

        /**
         * Creates a plain object from a BetEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof double.BetEvent
         * @static
         * @param {double.BetEvent} message BetEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BetEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.seat = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.currencyType = 0;
                object.betColor = 0;
            }
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                object.betColor = message.betColor;
            return object;
        };

        /**
         * Converts this BetEvent to JSON.
         * @function toJSON
         * @memberof double.BetEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BetEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BetEvent;
    })();

    double.PlayerListEvent = (function() {

        /**
         * Properties of a PlayerListEvent.
         * @memberof double
         * @interface IPlayerListEvent
         * @property {Array.<double.IPlayerInfo>|null} [infos] PlayerListEvent infos
         */

        /**
         * Constructs a new PlayerListEvent.
         * @memberof double
         * @classdesc Represents a PlayerListEvent.
         * @implements IPlayerListEvent
         * @constructor
         * @param {double.IPlayerListEvent=} [properties] Properties to set
         */
        function PlayerListEvent(properties) {
            this.infos = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerListEvent infos.
         * @member {Array.<double.IPlayerInfo>} infos
         * @memberof double.PlayerListEvent
         * @instance
         */
        PlayerListEvent.prototype.infos = $util.emptyArray;

        /**
         * Creates a new PlayerListEvent instance using the specified properties.
         * @function create
         * @memberof double.PlayerListEvent
         * @static
         * @param {double.IPlayerListEvent=} [properties] Properties to set
         * @returns {double.PlayerListEvent} PlayerListEvent instance
         */
        PlayerListEvent.create = function create(properties) {
            return new PlayerListEvent(properties);
        };

        /**
         * Encodes the specified PlayerListEvent message. Does not implicitly {@link double.PlayerListEvent.verify|verify} messages.
         * @function encode
         * @memberof double.PlayerListEvent
         * @static
         * @param {double.IPlayerListEvent} message PlayerListEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerListEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.infos != null && message.infos.length)
                for (var i = 0; i < message.infos.length; ++i)
                    $root.double.PlayerInfo.encode(message.infos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PlayerListEvent message, length delimited. Does not implicitly {@link double.PlayerListEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof double.PlayerListEvent
         * @static
         * @param {double.IPlayerListEvent} message PlayerListEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerListEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerListEvent message from the specified reader or buffer.
         * @function decode
         * @memberof double.PlayerListEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {double.PlayerListEvent} PlayerListEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerListEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.double.PlayerListEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.infos && message.infos.length))
                        message.infos = [];
                    message.infos.push($root.double.PlayerInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerListEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof double.PlayerListEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {double.PlayerListEvent} PlayerListEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerListEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerListEvent message.
         * @function verify
         * @memberof double.PlayerListEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerListEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.infos != null && message.hasOwnProperty("infos")) {
                if (!Array.isArray(message.infos))
                    return "infos: array expected";
                for (var i = 0; i < message.infos.length; ++i) {
                    var error = $root.double.PlayerInfo.verify(message.infos[i]);
                    if (error)
                        return "infos." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PlayerListEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof double.PlayerListEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {double.PlayerListEvent} PlayerListEvent
         */
        PlayerListEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.double.PlayerListEvent)
                return object;
            var message = new $root.double.PlayerListEvent();
            if (object.infos) {
                if (!Array.isArray(object.infos))
                    throw TypeError(".double.PlayerListEvent.infos: array expected");
                message.infos = [];
                for (var i = 0; i < object.infos.length; ++i) {
                    if (typeof object.infos[i] !== "object")
                        throw TypeError(".double.PlayerListEvent.infos: object expected");
                    message.infos[i] = $root.double.PlayerInfo.fromObject(object.infos[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PlayerListEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof double.PlayerListEvent
         * @static
         * @param {double.PlayerListEvent} message PlayerListEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerListEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.infos = [];
            if (message.infos && message.infos.length) {
                object.infos = [];
                for (var j = 0; j < message.infos.length; ++j)
                    object.infos[j] = $root.double.PlayerInfo.toObject(message.infos[j], options);
            }
            return object;
        };

        /**
         * Converts this PlayerListEvent to JSON.
         * @function toJSON
         * @memberof double.PlayerListEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerListEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerListEvent;
    })();

    double.PlayerInfo = (function() {

        /**
         * Properties of a PlayerInfo.
         * @memberof double
         * @interface IPlayerInfo
         * @property {number|Long|null} [playerId] PlayerInfo playerId
         * @property {string|null} [nickname] PlayerInfo nickname
         * @property {string|null} [headUrl] PlayerInfo headUrl
         * @property {number|null} [level] PlayerInfo level
         * @property {number|null} [currencyType] PlayerInfo currencyType
         * @property {number|Long|null} [betAmount] PlayerInfo betAmount
         * @property {number|null} [betColor] PlayerInfo betColor
         */

        /**
         * Constructs a new PlayerInfo.
         * @memberof double
         * @classdesc Represents a PlayerInfo.
         * @implements IPlayerInfo
         * @constructor
         * @param {double.IPlayerInfo=} [properties] Properties to set
         */
        function PlayerInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerInfo playerId.
         * @member {number|Long} playerId
         * @memberof double.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.playerId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PlayerInfo nickname.
         * @member {string} nickname
         * @memberof double.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.nickname = "";

        /**
         * PlayerInfo headUrl.
         * @member {string} headUrl
         * @memberof double.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.headUrl = "";

        /**
         * PlayerInfo level.
         * @member {number} level
         * @memberof double.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.level = 0;

        /**
         * PlayerInfo currencyType.
         * @member {number} currencyType
         * @memberof double.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.currencyType = 0;

        /**
         * PlayerInfo betAmount.
         * @member {number|Long} betAmount
         * @memberof double.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlayerInfo betColor.
         * @member {number} betColor
         * @memberof double.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.betColor = 0;

        /**
         * Creates a new PlayerInfo instance using the specified properties.
         * @function create
         * @memberof double.PlayerInfo
         * @static
         * @param {double.IPlayerInfo=} [properties] Properties to set
         * @returns {double.PlayerInfo} PlayerInfo instance
         */
        PlayerInfo.create = function create(properties) {
            return new PlayerInfo(properties);
        };

        /**
         * Encodes the specified PlayerInfo message. Does not implicitly {@link double.PlayerInfo.verify|verify} messages.
         * @function encode
         * @memberof double.PlayerInfo
         * @static
         * @param {double.IPlayerInfo} message PlayerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.playerId != null && Object.hasOwnProperty.call(message, "playerId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.playerId);
            if (message.nickname != null && Object.hasOwnProperty.call(message, "nickname"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.nickname);
            if (message.headUrl != null && Object.hasOwnProperty.call(message, "headUrl"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.headUrl);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.level);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.betAmount);
            if (message.betColor != null && Object.hasOwnProperty.call(message, "betColor"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.betColor);
            return writer;
        };

        /**
         * Encodes the specified PlayerInfo message, length delimited. Does not implicitly {@link double.PlayerInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof double.PlayerInfo
         * @static
         * @param {double.IPlayerInfo} message PlayerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerInfo message from the specified reader or buffer.
         * @function decode
         * @memberof double.PlayerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {double.PlayerInfo} PlayerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.double.PlayerInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.playerId = reader.uint64();
                    break;
                case 2:
                    message.nickname = reader.string();
                    break;
                case 3:
                    message.headUrl = reader.string();
                    break;
                case 4:
                    message.level = reader.int32();
                    break;
                case 5:
                    message.currencyType = reader.int32();
                    break;
                case 6:
                    message.betAmount = reader.int64();
                    break;
                case 7:
                    message.betColor = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof double.PlayerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {double.PlayerInfo} PlayerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerInfo message.
         * @function verify
         * @memberof double.PlayerInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.playerId != null && message.hasOwnProperty("playerId"))
                if (!$util.isInteger(message.playerId) && !(message.playerId && $util.isInteger(message.playerId.low) && $util.isInteger(message.playerId.high)))
                    return "playerId: integer|Long expected";
            if (message.nickname != null && message.hasOwnProperty("nickname"))
                if (!$util.isString(message.nickname))
                    return "nickname: string expected";
            if (message.headUrl != null && message.hasOwnProperty("headUrl"))
                if (!$util.isString(message.headUrl))
                    return "headUrl: string expected";
            if (message.level != null && message.hasOwnProperty("level"))
                if (!$util.isInteger(message.level))
                    return "level: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                if (!$util.isInteger(message.betColor))
                    return "betColor: integer expected";
            return null;
        };

        /**
         * Creates a PlayerInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof double.PlayerInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {double.PlayerInfo} PlayerInfo
         */
        PlayerInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.double.PlayerInfo)
                return object;
            var message = new $root.double.PlayerInfo();
            if (object.playerId != null)
                if ($util.Long)
                    (message.playerId = $util.Long.fromValue(object.playerId)).unsigned = true;
                else if (typeof object.playerId === "string")
                    message.playerId = parseInt(object.playerId, 10);
                else if (typeof object.playerId === "number")
                    message.playerId = object.playerId;
                else if (typeof object.playerId === "object")
                    message.playerId = new $util.LongBits(object.playerId.low >>> 0, object.playerId.high >>> 0).toNumber(true);
            if (object.nickname != null)
                message.nickname = String(object.nickname);
            if (object.headUrl != null)
                message.headUrl = String(object.headUrl);
            if (object.level != null)
                message.level = object.level | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.betColor != null)
                message.betColor = object.betColor | 0;
            return message;
        };

        /**
         * Creates a plain object from a PlayerInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof double.PlayerInfo
         * @static
         * @param {double.PlayerInfo} message PlayerInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.playerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.playerId = options.longs === String ? "0" : 0;
                object.nickname = "";
                object.headUrl = "";
                object.level = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.betColor = 0;
            }
            if (message.playerId != null && message.hasOwnProperty("playerId"))
                if (typeof message.playerId === "number")
                    object.playerId = options.longs === String ? String(message.playerId) : message.playerId;
                else
                    object.playerId = options.longs === String ? $util.Long.prototype.toString.call(message.playerId) : options.longs === Number ? new $util.LongBits(message.playerId.low >>> 0, message.playerId.high >>> 0).toNumber(true) : message.playerId;
            if (message.nickname != null && message.hasOwnProperty("nickname"))
                object.nickname = message.nickname;
            if (message.headUrl != null && message.hasOwnProperty("headUrl"))
                object.headUrl = message.headUrl;
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = message.level;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                object.betColor = message.betColor;
            return object;
        };

        /**
         * Converts this PlayerInfo to JSON.
         * @function toJSON
         * @memberof double.PlayerInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerInfo;
    })();

    double.GameEndEvent = (function() {

        /**
         * Properties of a GameEndEvent.
         * @memberof double
         * @interface IGameEndEvent
         * @property {number|null} [result] GameEndEvent result
         */

        /**
         * Constructs a new GameEndEvent.
         * @memberof double
         * @classdesc Represents a GameEndEvent.
         * @implements IGameEndEvent
         * @constructor
         * @param {double.IGameEndEvent=} [properties] Properties to set
         */
        function GameEndEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameEndEvent result.
         * @member {number} result
         * @memberof double.GameEndEvent
         * @instance
         */
        GameEndEvent.prototype.result = 0;

        /**
         * Creates a new GameEndEvent instance using the specified properties.
         * @function create
         * @memberof double.GameEndEvent
         * @static
         * @param {double.IGameEndEvent=} [properties] Properties to set
         * @returns {double.GameEndEvent} GameEndEvent instance
         */
        GameEndEvent.create = function create(properties) {
            return new GameEndEvent(properties);
        };

        /**
         * Encodes the specified GameEndEvent message. Does not implicitly {@link double.GameEndEvent.verify|verify} messages.
         * @function encode
         * @memberof double.GameEndEvent
         * @static
         * @param {double.IGameEndEvent} message GameEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameEndEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified GameEndEvent message, length delimited. Does not implicitly {@link double.GameEndEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof double.GameEndEvent
         * @static
         * @param {double.IGameEndEvent} message GameEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameEndEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameEndEvent message from the specified reader or buffer.
         * @function decode
         * @memberof double.GameEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {double.GameEndEvent} GameEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameEndEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.double.GameEndEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameEndEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof double.GameEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {double.GameEndEvent} GameEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameEndEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameEndEvent message.
         * @function verify
         * @memberof double.GameEndEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameEndEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a GameEndEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof double.GameEndEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {double.GameEndEvent} GameEndEvent
         */
        GameEndEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.double.GameEndEvent)
                return object;
            var message = new $root.double.GameEndEvent();
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a GameEndEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof double.GameEndEvent
         * @static
         * @param {double.GameEndEvent} message GameEndEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameEndEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.result = 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this GameEndEvent to JSON.
         * @function toJSON
         * @memberof double.GameEndEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameEndEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameEndEvent;
    })();

    double.GameResultEvent = (function() {

        /**
         * Properties of a GameResultEvent.
         * @memberof double
         * @interface IGameResultEvent
         * @property {number|null} [currencyType] GameResultEvent currencyType
         * @property {number|Long|null} [betAmount] GameResultEvent betAmount
         * @property {number|null} [betColor] GameResultEvent betColor
         * @property {number|Long|null} [winAmount] GameResultEvent winAmount
         * @property {string|null} [gameCode] GameResultEvent gameCode
         */

        /**
         * Constructs a new GameResultEvent.
         * @memberof double
         * @classdesc Represents a GameResultEvent.
         * @implements IGameResultEvent
         * @constructor
         * @param {double.IGameResultEvent=} [properties] Properties to set
         */
        function GameResultEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameResultEvent currencyType.
         * @member {number} currencyType
         * @memberof double.GameResultEvent
         * @instance
         */
        GameResultEvent.prototype.currencyType = 0;

        /**
         * GameResultEvent betAmount.
         * @member {number|Long} betAmount
         * @memberof double.GameResultEvent
         * @instance
         */
        GameResultEvent.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GameResultEvent betColor.
         * @member {number} betColor
         * @memberof double.GameResultEvent
         * @instance
         */
        GameResultEvent.prototype.betColor = 0;

        /**
         * GameResultEvent winAmount.
         * @member {number|Long} winAmount
         * @memberof double.GameResultEvent
         * @instance
         */
        GameResultEvent.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GameResultEvent gameCode.
         * @member {string} gameCode
         * @memberof double.GameResultEvent
         * @instance
         */
        GameResultEvent.prototype.gameCode = "";

        /**
         * Creates a new GameResultEvent instance using the specified properties.
         * @function create
         * @memberof double.GameResultEvent
         * @static
         * @param {double.IGameResultEvent=} [properties] Properties to set
         * @returns {double.GameResultEvent} GameResultEvent instance
         */
        GameResultEvent.create = function create(properties) {
            return new GameResultEvent(properties);
        };

        /**
         * Encodes the specified GameResultEvent message. Does not implicitly {@link double.GameResultEvent.verify|verify} messages.
         * @function encode
         * @memberof double.GameResultEvent
         * @static
         * @param {double.IGameResultEvent} message GameResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameResultEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            if (message.betColor != null && Object.hasOwnProperty.call(message, "betColor"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.betColor);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.winAmount);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.gameCode);
            return writer;
        };

        /**
         * Encodes the specified GameResultEvent message, length delimited. Does not implicitly {@link double.GameResultEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof double.GameResultEvent
         * @static
         * @param {double.IGameResultEvent} message GameResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameResultEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameResultEvent message from the specified reader or buffer.
         * @function decode
         * @memberof double.GameResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {double.GameResultEvent} GameResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameResultEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.double.GameResultEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                case 3:
                    message.betColor = reader.int32();
                    break;
                case 4:
                    message.winAmount = reader.int64();
                    break;
                case 5:
                    message.gameCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameResultEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof double.GameResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {double.GameResultEvent} GameResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameResultEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameResultEvent message.
         * @function verify
         * @memberof double.GameResultEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameResultEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                if (!$util.isInteger(message.betColor))
                    return "betColor: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            return null;
        };

        /**
         * Creates a GameResultEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof double.GameResultEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {double.GameResultEvent} GameResultEvent
         */
        GameResultEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.double.GameResultEvent)
                return object;
            var message = new $root.double.GameResultEvent();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.betColor != null)
                message.betColor = object.betColor | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            return message;
        };

        /**
         * Creates a plain object from a GameResultEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof double.GameResultEvent
         * @static
         * @param {double.GameResultEvent} message GameResultEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameResultEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.betColor = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                object.gameCode = "";
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                object.betColor = message.betColor;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            return object;
        };

        /**
         * Converts this GameResultEvent to JSON.
         * @function toJSON
         * @memberof double.GameResultEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameResultEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameResultEvent;
    })();

    return double;
})();

$root.towerlegend = (function() {

    /**
     * Namespace towerlegend.
     * @exports towerlegend
     * @namespace
     */
    var towerlegend = {};

    towerlegend.TowerlegendStateReply = (function() {

        /**
         * Properties of a TowerlegendStateReply.
         * @memberof towerlegend
         * @interface ITowerlegendStateReply
         * @property {string|null} [params] TowerlegendStateReply params
         * @property {string|null} [config] TowerlegendStateReply config
         * @property {Array.<towerlegend.IHistoryResult>|null} [results] TowerlegendStateReply results
         * @property {number|null} [currencyType] TowerlegendStateReply currencyType
         * @property {number|Long|null} [betAmount] TowerlegendStateReply betAmount
         * @property {string|null} [gameCode] TowerlegendStateReply gameCode
         * @property {string|null} [fairness] TowerlegendStateReply fairness
         * @property {number|null} [difficulty] TowerlegendStateReply difficulty
         * @property {number|null} [layer] TowerlegendStateReply layer
         * @property {Array.<number>|null} [layerData] TowerlegendStateReply layerData
         * @property {Array.<number>|null} [choices] TowerlegendStateReply choices
         */

        /**
         * Constructs a new TowerlegendStateReply.
         * @memberof towerlegend
         * @classdesc Represents a TowerlegendStateReply.
         * @implements ITowerlegendStateReply
         * @constructor
         * @param {towerlegend.ITowerlegendStateReply=} [properties] Properties to set
         */
        function TowerlegendStateReply(properties) {
            this.results = [];
            this.layerData = [];
            this.choices = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TowerlegendStateReply params.
         * @member {string} params
         * @memberof towerlegend.TowerlegendStateReply
         * @instance
         */
        TowerlegendStateReply.prototype.params = "";

        /**
         * TowerlegendStateReply config.
         * @member {string} config
         * @memberof towerlegend.TowerlegendStateReply
         * @instance
         */
        TowerlegendStateReply.prototype.config = "";

        /**
         * TowerlegendStateReply results.
         * @member {Array.<towerlegend.IHistoryResult>} results
         * @memberof towerlegend.TowerlegendStateReply
         * @instance
         */
        TowerlegendStateReply.prototype.results = $util.emptyArray;

        /**
         * TowerlegendStateReply currencyType.
         * @member {number} currencyType
         * @memberof towerlegend.TowerlegendStateReply
         * @instance
         */
        TowerlegendStateReply.prototype.currencyType = 0;

        /**
         * TowerlegendStateReply betAmount.
         * @member {number|Long} betAmount
         * @memberof towerlegend.TowerlegendStateReply
         * @instance
         */
        TowerlegendStateReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TowerlegendStateReply gameCode.
         * @member {string} gameCode
         * @memberof towerlegend.TowerlegendStateReply
         * @instance
         */
        TowerlegendStateReply.prototype.gameCode = "";

        /**
         * TowerlegendStateReply fairness.
         * @member {string} fairness
         * @memberof towerlegend.TowerlegendStateReply
         * @instance
         */
        TowerlegendStateReply.prototype.fairness = "";

        /**
         * TowerlegendStateReply difficulty.
         * @member {number} difficulty
         * @memberof towerlegend.TowerlegendStateReply
         * @instance
         */
        TowerlegendStateReply.prototype.difficulty = 0;

        /**
         * TowerlegendStateReply layer.
         * @member {number} layer
         * @memberof towerlegend.TowerlegendStateReply
         * @instance
         */
        TowerlegendStateReply.prototype.layer = 0;

        /**
         * TowerlegendStateReply layerData.
         * @member {Array.<number>} layerData
         * @memberof towerlegend.TowerlegendStateReply
         * @instance
         */
        TowerlegendStateReply.prototype.layerData = $util.emptyArray;

        /**
         * TowerlegendStateReply choices.
         * @member {Array.<number>} choices
         * @memberof towerlegend.TowerlegendStateReply
         * @instance
         */
        TowerlegendStateReply.prototype.choices = $util.emptyArray;

        /**
         * Creates a new TowerlegendStateReply instance using the specified properties.
         * @function create
         * @memberof towerlegend.TowerlegendStateReply
         * @static
         * @param {towerlegend.ITowerlegendStateReply=} [properties] Properties to set
         * @returns {towerlegend.TowerlegendStateReply} TowerlegendStateReply instance
         */
        TowerlegendStateReply.create = function create(properties) {
            return new TowerlegendStateReply(properties);
        };

        /**
         * Encodes the specified TowerlegendStateReply message. Does not implicitly {@link towerlegend.TowerlegendStateReply.verify|verify} messages.
         * @function encode
         * @memberof towerlegend.TowerlegendStateReply
         * @static
         * @param {towerlegend.ITowerlegendStateReply} message TowerlegendStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerlegendStateReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.params);
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.config);
            if (message.results != null && message.results.length)
                for (var i = 0; i < message.results.length; ++i)
                    $root.towerlegend.HistoryResult.encode(message.results[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.betAmount);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.gameCode);
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.fairness);
            if (message.difficulty != null && Object.hasOwnProperty.call(message, "difficulty"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.difficulty);
            if (message.layer != null && Object.hasOwnProperty.call(message, "layer"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.layer);
            if (message.layerData != null && message.layerData.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (var i = 0; i < message.layerData.length; ++i)
                    writer.int32(message.layerData[i]);
                writer.ldelim();
            }
            if (message.choices != null && message.choices.length) {
                writer.uint32(/* id 11, wireType 2 =*/90).fork();
                for (var i = 0; i < message.choices.length; ++i)
                    writer.int32(message.choices[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified TowerlegendStateReply message, length delimited. Does not implicitly {@link towerlegend.TowerlegendStateReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof towerlegend.TowerlegendStateReply
         * @static
         * @param {towerlegend.ITowerlegendStateReply} message TowerlegendStateReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerlegendStateReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TowerlegendStateReply message from the specified reader or buffer.
         * @function decode
         * @memberof towerlegend.TowerlegendStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {towerlegend.TowerlegendStateReply} TowerlegendStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerlegendStateReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.towerlegend.TowerlegendStateReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.params = reader.string();
                    break;
                case 2:
                    message.config = reader.string();
                    break;
                case 3:
                    if (!(message.results && message.results.length))
                        message.results = [];
                    message.results.push($root.towerlegend.HistoryResult.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.currencyType = reader.int32();
                    break;
                case 5:
                    message.betAmount = reader.int64();
                    break;
                case 6:
                    message.gameCode = reader.string();
                    break;
                case 7:
                    message.fairness = reader.string();
                    break;
                case 8:
                    message.difficulty = reader.int32();
                    break;
                case 9:
                    message.layer = reader.int32();
                    break;
                case 10:
                    if (!(message.layerData && message.layerData.length))
                        message.layerData = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.layerData.push(reader.int32());
                    } else
                        message.layerData.push(reader.int32());
                    break;
                case 11:
                    if (!(message.choices && message.choices.length))
                        message.choices = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.choices.push(reader.int32());
                    } else
                        message.choices.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TowerlegendStateReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof towerlegend.TowerlegendStateReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {towerlegend.TowerlegendStateReply} TowerlegendStateReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerlegendStateReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TowerlegendStateReply message.
         * @function verify
         * @memberof towerlegend.TowerlegendStateReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TowerlegendStateReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.config != null && message.hasOwnProperty("config"))
                if (!$util.isString(message.config))
                    return "config: string expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (var i = 0; i < message.results.length; ++i) {
                    var error = $root.towerlegend.HistoryResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            if (message.difficulty != null && message.hasOwnProperty("difficulty"))
                if (!$util.isInteger(message.difficulty))
                    return "difficulty: integer expected";
            if (message.layer != null && message.hasOwnProperty("layer"))
                if (!$util.isInteger(message.layer))
                    return "layer: integer expected";
            if (message.layerData != null && message.hasOwnProperty("layerData")) {
                if (!Array.isArray(message.layerData))
                    return "layerData: array expected";
                for (var i = 0; i < message.layerData.length; ++i)
                    if (!$util.isInteger(message.layerData[i]))
                        return "layerData: integer[] expected";
            }
            if (message.choices != null && message.hasOwnProperty("choices")) {
                if (!Array.isArray(message.choices))
                    return "choices: array expected";
                for (var i = 0; i < message.choices.length; ++i)
                    if (!$util.isInteger(message.choices[i]))
                        return "choices: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a TowerlegendStateReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof towerlegend.TowerlegendStateReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {towerlegend.TowerlegendStateReply} TowerlegendStateReply
         */
        TowerlegendStateReply.fromObject = function fromObject(object) {
            if (object instanceof $root.towerlegend.TowerlegendStateReply)
                return object;
            var message = new $root.towerlegend.TowerlegendStateReply();
            if (object.params != null)
                message.params = String(object.params);
            if (object.config != null)
                message.config = String(object.config);
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".towerlegend.TowerlegendStateReply.results: array expected");
                message.results = [];
                for (var i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".towerlegend.TowerlegendStateReply.results: object expected");
                    message.results[i] = $root.towerlegend.HistoryResult.fromObject(object.results[i]);
                }
            }
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            if (object.difficulty != null)
                message.difficulty = object.difficulty | 0;
            if (object.layer != null)
                message.layer = object.layer | 0;
            if (object.layerData) {
                if (!Array.isArray(object.layerData))
                    throw TypeError(".towerlegend.TowerlegendStateReply.layerData: array expected");
                message.layerData = [];
                for (var i = 0; i < object.layerData.length; ++i)
                    message.layerData[i] = object.layerData[i] | 0;
            }
            if (object.choices) {
                if (!Array.isArray(object.choices))
                    throw TypeError(".towerlegend.TowerlegendStateReply.choices: array expected");
                message.choices = [];
                for (var i = 0; i < object.choices.length; ++i)
                    message.choices[i] = object.choices[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a TowerlegendStateReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof towerlegend.TowerlegendStateReply
         * @static
         * @param {towerlegend.TowerlegendStateReply} message TowerlegendStateReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TowerlegendStateReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.results = [];
                object.layerData = [];
                object.choices = [];
            }
            if (options.defaults) {
                object.params = "";
                object.config = "";
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.gameCode = "";
                object.fairness = "";
                object.difficulty = 0;
                object.layer = 0;
            }
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.config != null && message.hasOwnProperty("config"))
                object.config = message.config;
            if (message.results && message.results.length) {
                object.results = [];
                for (var j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.towerlegend.HistoryResult.toObject(message.results[j], options);
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            if (message.difficulty != null && message.hasOwnProperty("difficulty"))
                object.difficulty = message.difficulty;
            if (message.layer != null && message.hasOwnProperty("layer"))
                object.layer = message.layer;
            if (message.layerData && message.layerData.length) {
                object.layerData = [];
                for (var j = 0; j < message.layerData.length; ++j)
                    object.layerData[j] = message.layerData[j];
            }
            if (message.choices && message.choices.length) {
                object.choices = [];
                for (var j = 0; j < message.choices.length; ++j)
                    object.choices[j] = message.choices[j];
            }
            return object;
        };

        /**
         * Converts this TowerlegendStateReply to JSON.
         * @function toJSON
         * @memberof towerlegend.TowerlegendStateReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TowerlegendStateReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TowerlegendStateReply;
    })();

    towerlegend.TowerlegendBetReq = (function() {

        /**
         * Properties of a TowerlegendBetReq.
         * @memberof towerlegend
         * @interface ITowerlegendBetReq
         * @property {number|null} [currencyType] TowerlegendBetReq currencyType
         * @property {number|Long|null} [betAmount] TowerlegendBetReq betAmount
         * @property {number|null} [difficulty] TowerlegendBetReq difficulty
         */

        /**
         * Constructs a new TowerlegendBetReq.
         * @memberof towerlegend
         * @classdesc Represents a TowerlegendBetReq.
         * @implements ITowerlegendBetReq
         * @constructor
         * @param {towerlegend.ITowerlegendBetReq=} [properties] Properties to set
         */
        function TowerlegendBetReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TowerlegendBetReq currencyType.
         * @member {number} currencyType
         * @memberof towerlegend.TowerlegendBetReq
         * @instance
         */
        TowerlegendBetReq.prototype.currencyType = 0;

        /**
         * TowerlegendBetReq betAmount.
         * @member {number|Long} betAmount
         * @memberof towerlegend.TowerlegendBetReq
         * @instance
         */
        TowerlegendBetReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TowerlegendBetReq difficulty.
         * @member {number} difficulty
         * @memberof towerlegend.TowerlegendBetReq
         * @instance
         */
        TowerlegendBetReq.prototype.difficulty = 0;

        /**
         * Creates a new TowerlegendBetReq instance using the specified properties.
         * @function create
         * @memberof towerlegend.TowerlegendBetReq
         * @static
         * @param {towerlegend.ITowerlegendBetReq=} [properties] Properties to set
         * @returns {towerlegend.TowerlegendBetReq} TowerlegendBetReq instance
         */
        TowerlegendBetReq.create = function create(properties) {
            return new TowerlegendBetReq(properties);
        };

        /**
         * Encodes the specified TowerlegendBetReq message. Does not implicitly {@link towerlegend.TowerlegendBetReq.verify|verify} messages.
         * @function encode
         * @memberof towerlegend.TowerlegendBetReq
         * @static
         * @param {towerlegend.ITowerlegendBetReq} message TowerlegendBetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerlegendBetReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            if (message.difficulty != null && Object.hasOwnProperty.call(message, "difficulty"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.difficulty);
            return writer;
        };

        /**
         * Encodes the specified TowerlegendBetReq message, length delimited. Does not implicitly {@link towerlegend.TowerlegendBetReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof towerlegend.TowerlegendBetReq
         * @static
         * @param {towerlegend.ITowerlegendBetReq} message TowerlegendBetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerlegendBetReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TowerlegendBetReq message from the specified reader or buffer.
         * @function decode
         * @memberof towerlegend.TowerlegendBetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {towerlegend.TowerlegendBetReq} TowerlegendBetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerlegendBetReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.towerlegend.TowerlegendBetReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                case 3:
                    message.difficulty = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TowerlegendBetReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof towerlegend.TowerlegendBetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {towerlegend.TowerlegendBetReq} TowerlegendBetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerlegendBetReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TowerlegendBetReq message.
         * @function verify
         * @memberof towerlegend.TowerlegendBetReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TowerlegendBetReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.difficulty != null && message.hasOwnProperty("difficulty"))
                if (!$util.isInteger(message.difficulty))
                    return "difficulty: integer expected";
            return null;
        };

        /**
         * Creates a TowerlegendBetReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof towerlegend.TowerlegendBetReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {towerlegend.TowerlegendBetReq} TowerlegendBetReq
         */
        TowerlegendBetReq.fromObject = function fromObject(object) {
            if (object instanceof $root.towerlegend.TowerlegendBetReq)
                return object;
            var message = new $root.towerlegend.TowerlegendBetReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.difficulty != null)
                message.difficulty = object.difficulty | 0;
            return message;
        };

        /**
         * Creates a plain object from a TowerlegendBetReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof towerlegend.TowerlegendBetReq
         * @static
         * @param {towerlegend.TowerlegendBetReq} message TowerlegendBetReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TowerlegendBetReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.difficulty = 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.difficulty != null && message.hasOwnProperty("difficulty"))
                object.difficulty = message.difficulty;
            return object;
        };

        /**
         * Converts this TowerlegendBetReq to JSON.
         * @function toJSON
         * @memberof towerlegend.TowerlegendBetReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TowerlegendBetReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TowerlegendBetReq;
    })();

    towerlegend.TowerlegendBetReply = (function() {

        /**
         * Properties of a TowerlegendBetReply.
         * @memberof towerlegend
         * @interface ITowerlegendBetReply
         * @property {string|null} [fairness] TowerlegendBetReply fairness
         * @property {number|Long|null} [betAmount] TowerlegendBetReply betAmount
         */

        /**
         * Constructs a new TowerlegendBetReply.
         * @memberof towerlegend
         * @classdesc Represents a TowerlegendBetReply.
         * @implements ITowerlegendBetReply
         * @constructor
         * @param {towerlegend.ITowerlegendBetReply=} [properties] Properties to set
         */
        function TowerlegendBetReply(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TowerlegendBetReply fairness.
         * @member {string} fairness
         * @memberof towerlegend.TowerlegendBetReply
         * @instance
         */
        TowerlegendBetReply.prototype.fairness = "";

        /**
         * TowerlegendBetReply betAmount.
         * @member {number|Long} betAmount
         * @memberof towerlegend.TowerlegendBetReply
         * @instance
         */
        TowerlegendBetReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new TowerlegendBetReply instance using the specified properties.
         * @function create
         * @memberof towerlegend.TowerlegendBetReply
         * @static
         * @param {towerlegend.ITowerlegendBetReply=} [properties] Properties to set
         * @returns {towerlegend.TowerlegendBetReply} TowerlegendBetReply instance
         */
        TowerlegendBetReply.create = function create(properties) {
            return new TowerlegendBetReply(properties);
        };

        /**
         * Encodes the specified TowerlegendBetReply message. Does not implicitly {@link towerlegend.TowerlegendBetReply.verify|verify} messages.
         * @function encode
         * @memberof towerlegend.TowerlegendBetReply
         * @static
         * @param {towerlegend.ITowerlegendBetReply} message TowerlegendBetReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerlegendBetReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fairness != null && Object.hasOwnProperty.call(message, "fairness"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fairness);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            return writer;
        };

        /**
         * Encodes the specified TowerlegendBetReply message, length delimited. Does not implicitly {@link towerlegend.TowerlegendBetReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof towerlegend.TowerlegendBetReply
         * @static
         * @param {towerlegend.ITowerlegendBetReply} message TowerlegendBetReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerlegendBetReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TowerlegendBetReply message from the specified reader or buffer.
         * @function decode
         * @memberof towerlegend.TowerlegendBetReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {towerlegend.TowerlegendBetReply} TowerlegendBetReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerlegendBetReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.towerlegend.TowerlegendBetReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fairness = reader.string();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TowerlegendBetReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof towerlegend.TowerlegendBetReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {towerlegend.TowerlegendBetReply} TowerlegendBetReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerlegendBetReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TowerlegendBetReply message.
         * @function verify
         * @memberof towerlegend.TowerlegendBetReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TowerlegendBetReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                if (!$util.isString(message.fairness))
                    return "fairness: string expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            return null;
        };

        /**
         * Creates a TowerlegendBetReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof towerlegend.TowerlegendBetReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {towerlegend.TowerlegendBetReply} TowerlegendBetReply
         */
        TowerlegendBetReply.fromObject = function fromObject(object) {
            if (object instanceof $root.towerlegend.TowerlegendBetReply)
                return object;
            var message = new $root.towerlegend.TowerlegendBetReply();
            if (object.fairness != null)
                message.fairness = String(object.fairness);
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a TowerlegendBetReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof towerlegend.TowerlegendBetReply
         * @static
         * @param {towerlegend.TowerlegendBetReply} message TowerlegendBetReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TowerlegendBetReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.fairness = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
            }
            if (message.fairness != null && message.hasOwnProperty("fairness"))
                object.fairness = message.fairness;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            return object;
        };

        /**
         * Converts this TowerlegendBetReply to JSON.
         * @function toJSON
         * @memberof towerlegend.TowerlegendBetReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TowerlegendBetReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TowerlegendBetReply;
    })();

    towerlegend.TowerlegendDealReq = (function() {

        /**
         * Properties of a TowerlegendDealReq.
         * @memberof towerlegend
         * @interface ITowerlegendDealReq
         * @property {number|null} [layer] TowerlegendDealReq layer
         * @property {number|null} [choiceIndex] TowerlegendDealReq choiceIndex
         */

        /**
         * Constructs a new TowerlegendDealReq.
         * @memberof towerlegend
         * @classdesc Represents a TowerlegendDealReq.
         * @implements ITowerlegendDealReq
         * @constructor
         * @param {towerlegend.ITowerlegendDealReq=} [properties] Properties to set
         */
        function TowerlegendDealReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TowerlegendDealReq layer.
         * @member {number} layer
         * @memberof towerlegend.TowerlegendDealReq
         * @instance
         */
        TowerlegendDealReq.prototype.layer = 0;

        /**
         * TowerlegendDealReq choiceIndex.
         * @member {number} choiceIndex
         * @memberof towerlegend.TowerlegendDealReq
         * @instance
         */
        TowerlegendDealReq.prototype.choiceIndex = 0;

        /**
         * Creates a new TowerlegendDealReq instance using the specified properties.
         * @function create
         * @memberof towerlegend.TowerlegendDealReq
         * @static
         * @param {towerlegend.ITowerlegendDealReq=} [properties] Properties to set
         * @returns {towerlegend.TowerlegendDealReq} TowerlegendDealReq instance
         */
        TowerlegendDealReq.create = function create(properties) {
            return new TowerlegendDealReq(properties);
        };

        /**
         * Encodes the specified TowerlegendDealReq message. Does not implicitly {@link towerlegend.TowerlegendDealReq.verify|verify} messages.
         * @function encode
         * @memberof towerlegend.TowerlegendDealReq
         * @static
         * @param {towerlegend.ITowerlegendDealReq} message TowerlegendDealReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerlegendDealReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.layer != null && Object.hasOwnProperty.call(message, "layer"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.layer);
            if (message.choiceIndex != null && Object.hasOwnProperty.call(message, "choiceIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.choiceIndex);
            return writer;
        };

        /**
         * Encodes the specified TowerlegendDealReq message, length delimited. Does not implicitly {@link towerlegend.TowerlegendDealReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof towerlegend.TowerlegendDealReq
         * @static
         * @param {towerlegend.ITowerlegendDealReq} message TowerlegendDealReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerlegendDealReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TowerlegendDealReq message from the specified reader or buffer.
         * @function decode
         * @memberof towerlegend.TowerlegendDealReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {towerlegend.TowerlegendDealReq} TowerlegendDealReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerlegendDealReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.towerlegend.TowerlegendDealReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.layer = reader.int32();
                    break;
                case 2:
                    message.choiceIndex = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TowerlegendDealReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof towerlegend.TowerlegendDealReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {towerlegend.TowerlegendDealReq} TowerlegendDealReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerlegendDealReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TowerlegendDealReq message.
         * @function verify
         * @memberof towerlegend.TowerlegendDealReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TowerlegendDealReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.layer != null && message.hasOwnProperty("layer"))
                if (!$util.isInteger(message.layer))
                    return "layer: integer expected";
            if (message.choiceIndex != null && message.hasOwnProperty("choiceIndex"))
                if (!$util.isInteger(message.choiceIndex))
                    return "choiceIndex: integer expected";
            return null;
        };

        /**
         * Creates a TowerlegendDealReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof towerlegend.TowerlegendDealReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {towerlegend.TowerlegendDealReq} TowerlegendDealReq
         */
        TowerlegendDealReq.fromObject = function fromObject(object) {
            if (object instanceof $root.towerlegend.TowerlegendDealReq)
                return object;
            var message = new $root.towerlegend.TowerlegendDealReq();
            if (object.layer != null)
                message.layer = object.layer | 0;
            if (object.choiceIndex != null)
                message.choiceIndex = object.choiceIndex | 0;
            return message;
        };

        /**
         * Creates a plain object from a TowerlegendDealReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof towerlegend.TowerlegendDealReq
         * @static
         * @param {towerlegend.TowerlegendDealReq} message TowerlegendDealReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TowerlegendDealReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.layer = 0;
                object.choiceIndex = 0;
            }
            if (message.layer != null && message.hasOwnProperty("layer"))
                object.layer = message.layer;
            if (message.choiceIndex != null && message.hasOwnProperty("choiceIndex"))
                object.choiceIndex = message.choiceIndex;
            return object;
        };

        /**
         * Converts this TowerlegendDealReq to JSON.
         * @function toJSON
         * @memberof towerlegend.TowerlegendDealReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TowerlegendDealReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TowerlegendDealReq;
    })();

    towerlegend.TowerlegendDealReply = (function() {

        /**
         * Properties of a TowerlegendDealReply.
         * @memberof towerlegend
         * @interface ITowerlegendDealReply
         * @property {number|null} [isBomb] TowerlegendDealReply isBomb
         * @property {number|null} [layer] TowerlegendDealReply layer
         * @property {number|null} [choiceIndex] TowerlegendDealReply choiceIndex
         * @property {Array.<number>|null} [layerData] TowerlegendDealReply layerData
         * @property {number|Long|null} [betAmount] TowerlegendDealReply betAmount
         */

        /**
         * Constructs a new TowerlegendDealReply.
         * @memberof towerlegend
         * @classdesc Represents a TowerlegendDealReply.
         * @implements ITowerlegendDealReply
         * @constructor
         * @param {towerlegend.ITowerlegendDealReply=} [properties] Properties to set
         */
        function TowerlegendDealReply(properties) {
            this.layerData = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TowerlegendDealReply isBomb.
         * @member {number} isBomb
         * @memberof towerlegend.TowerlegendDealReply
         * @instance
         */
        TowerlegendDealReply.prototype.isBomb = 0;

        /**
         * TowerlegendDealReply layer.
         * @member {number} layer
         * @memberof towerlegend.TowerlegendDealReply
         * @instance
         */
        TowerlegendDealReply.prototype.layer = 0;

        /**
         * TowerlegendDealReply choiceIndex.
         * @member {number} choiceIndex
         * @memberof towerlegend.TowerlegendDealReply
         * @instance
         */
        TowerlegendDealReply.prototype.choiceIndex = 0;

        /**
         * TowerlegendDealReply layerData.
         * @member {Array.<number>} layerData
         * @memberof towerlegend.TowerlegendDealReply
         * @instance
         */
        TowerlegendDealReply.prototype.layerData = $util.emptyArray;

        /**
         * TowerlegendDealReply betAmount.
         * @member {number|Long} betAmount
         * @memberof towerlegend.TowerlegendDealReply
         * @instance
         */
        TowerlegendDealReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new TowerlegendDealReply instance using the specified properties.
         * @function create
         * @memberof towerlegend.TowerlegendDealReply
         * @static
         * @param {towerlegend.ITowerlegendDealReply=} [properties] Properties to set
         * @returns {towerlegend.TowerlegendDealReply} TowerlegendDealReply instance
         */
        TowerlegendDealReply.create = function create(properties) {
            return new TowerlegendDealReply(properties);
        };

        /**
         * Encodes the specified TowerlegendDealReply message. Does not implicitly {@link towerlegend.TowerlegendDealReply.verify|verify} messages.
         * @function encode
         * @memberof towerlegend.TowerlegendDealReply
         * @static
         * @param {towerlegend.ITowerlegendDealReply} message TowerlegendDealReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerlegendDealReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isBomb != null && Object.hasOwnProperty.call(message, "isBomb"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.isBomb);
            if (message.layer != null && Object.hasOwnProperty.call(message, "layer"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.layer);
            if (message.choiceIndex != null && Object.hasOwnProperty.call(message, "choiceIndex"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.choiceIndex);
            if (message.layerData != null && message.layerData.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (var i = 0; i < message.layerData.length; ++i)
                    writer.int32(message.layerData[i]);
                writer.ldelim();
            }
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.betAmount);
            return writer;
        };

        /**
         * Encodes the specified TowerlegendDealReply message, length delimited. Does not implicitly {@link towerlegend.TowerlegendDealReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof towerlegend.TowerlegendDealReply
         * @static
         * @param {towerlegend.ITowerlegendDealReply} message TowerlegendDealReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerlegendDealReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TowerlegendDealReply message from the specified reader or buffer.
         * @function decode
         * @memberof towerlegend.TowerlegendDealReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {towerlegend.TowerlegendDealReply} TowerlegendDealReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerlegendDealReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.towerlegend.TowerlegendDealReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.isBomb = reader.int32();
                    break;
                case 2:
                    message.layer = reader.int32();
                    break;
                case 3:
                    message.choiceIndex = reader.int32();
                    break;
                case 4:
                    if (!(message.layerData && message.layerData.length))
                        message.layerData = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.layerData.push(reader.int32());
                    } else
                        message.layerData.push(reader.int32());
                    break;
                case 5:
                    message.betAmount = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TowerlegendDealReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof towerlegend.TowerlegendDealReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {towerlegend.TowerlegendDealReply} TowerlegendDealReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerlegendDealReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TowerlegendDealReply message.
         * @function verify
         * @memberof towerlegend.TowerlegendDealReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TowerlegendDealReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.isBomb != null && message.hasOwnProperty("isBomb"))
                if (!$util.isInteger(message.isBomb))
                    return "isBomb: integer expected";
            if (message.layer != null && message.hasOwnProperty("layer"))
                if (!$util.isInteger(message.layer))
                    return "layer: integer expected";
            if (message.choiceIndex != null && message.hasOwnProperty("choiceIndex"))
                if (!$util.isInteger(message.choiceIndex))
                    return "choiceIndex: integer expected";
            if (message.layerData != null && message.hasOwnProperty("layerData")) {
                if (!Array.isArray(message.layerData))
                    return "layerData: array expected";
                for (var i = 0; i < message.layerData.length; ++i)
                    if (!$util.isInteger(message.layerData[i]))
                        return "layerData: integer[] expected";
            }
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            return null;
        };

        /**
         * Creates a TowerlegendDealReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof towerlegend.TowerlegendDealReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {towerlegend.TowerlegendDealReply} TowerlegendDealReply
         */
        TowerlegendDealReply.fromObject = function fromObject(object) {
            if (object instanceof $root.towerlegend.TowerlegendDealReply)
                return object;
            var message = new $root.towerlegend.TowerlegendDealReply();
            if (object.isBomb != null)
                message.isBomb = object.isBomb | 0;
            if (object.layer != null)
                message.layer = object.layer | 0;
            if (object.choiceIndex != null)
                message.choiceIndex = object.choiceIndex | 0;
            if (object.layerData) {
                if (!Array.isArray(object.layerData))
                    throw TypeError(".towerlegend.TowerlegendDealReply.layerData: array expected");
                message.layerData = [];
                for (var i = 0; i < object.layerData.length; ++i)
                    message.layerData[i] = object.layerData[i] | 0;
            }
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a TowerlegendDealReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof towerlegend.TowerlegendDealReply
         * @static
         * @param {towerlegend.TowerlegendDealReply} message TowerlegendDealReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TowerlegendDealReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.layerData = [];
            if (options.defaults) {
                object.isBomb = 0;
                object.layer = 0;
                object.choiceIndex = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
            }
            if (message.isBomb != null && message.hasOwnProperty("isBomb"))
                object.isBomb = message.isBomb;
            if (message.layer != null && message.hasOwnProperty("layer"))
                object.layer = message.layer;
            if (message.choiceIndex != null && message.hasOwnProperty("choiceIndex"))
                object.choiceIndex = message.choiceIndex;
            if (message.layerData && message.layerData.length) {
                object.layerData = [];
                for (var j = 0; j < message.layerData.length; ++j)
                    object.layerData[j] = message.layerData[j];
            }
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            return object;
        };

        /**
         * Converts this TowerlegendDealReply to JSON.
         * @function toJSON
         * @memberof towerlegend.TowerlegendDealReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TowerlegendDealReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TowerlegendDealReply;
    })();

    towerlegend.TowerlegendResultReply = (function() {

        /**
         * Properties of a TowerlegendResultReply.
         * @memberof towerlegend
         * @interface ITowerlegendResultReply
         * @property {number|null} [currencyType] TowerlegendResultReply currencyType
         * @property {number|Long|null} [winAmount] TowerlegendResultReply winAmount
         * @property {number|Long|null} [betAmount] TowerlegendResultReply betAmount
         * @property {number|null} [payout] TowerlegendResultReply payout
         * @property {Array.<number>|null} [layerData] TowerlegendResultReply layerData
         * @property {Array.<number>|null} [choices] TowerlegendResultReply choices
         * @property {string|null} [gameCode] TowerlegendResultReply gameCode
         */

        /**
         * Constructs a new TowerlegendResultReply.
         * @memberof towerlegend
         * @classdesc Represents a TowerlegendResultReply.
         * @implements ITowerlegendResultReply
         * @constructor
         * @param {towerlegend.ITowerlegendResultReply=} [properties] Properties to set
         */
        function TowerlegendResultReply(properties) {
            this.layerData = [];
            this.choices = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TowerlegendResultReply currencyType.
         * @member {number} currencyType
         * @memberof towerlegend.TowerlegendResultReply
         * @instance
         */
        TowerlegendResultReply.prototype.currencyType = 0;

        /**
         * TowerlegendResultReply winAmount.
         * @member {number|Long} winAmount
         * @memberof towerlegend.TowerlegendResultReply
         * @instance
         */
        TowerlegendResultReply.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TowerlegendResultReply betAmount.
         * @member {number|Long} betAmount
         * @memberof towerlegend.TowerlegendResultReply
         * @instance
         */
        TowerlegendResultReply.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TowerlegendResultReply payout.
         * @member {number} payout
         * @memberof towerlegend.TowerlegendResultReply
         * @instance
         */
        TowerlegendResultReply.prototype.payout = 0;

        /**
         * TowerlegendResultReply layerData.
         * @member {Array.<number>} layerData
         * @memberof towerlegend.TowerlegendResultReply
         * @instance
         */
        TowerlegendResultReply.prototype.layerData = $util.emptyArray;

        /**
         * TowerlegendResultReply choices.
         * @member {Array.<number>} choices
         * @memberof towerlegend.TowerlegendResultReply
         * @instance
         */
        TowerlegendResultReply.prototype.choices = $util.emptyArray;

        /**
         * TowerlegendResultReply gameCode.
         * @member {string} gameCode
         * @memberof towerlegend.TowerlegendResultReply
         * @instance
         */
        TowerlegendResultReply.prototype.gameCode = "";

        /**
         * Creates a new TowerlegendResultReply instance using the specified properties.
         * @function create
         * @memberof towerlegend.TowerlegendResultReply
         * @static
         * @param {towerlegend.ITowerlegendResultReply=} [properties] Properties to set
         * @returns {towerlegend.TowerlegendResultReply} TowerlegendResultReply instance
         */
        TowerlegendResultReply.create = function create(properties) {
            return new TowerlegendResultReply(properties);
        };

        /**
         * Encodes the specified TowerlegendResultReply message. Does not implicitly {@link towerlegend.TowerlegendResultReply.verify|verify} messages.
         * @function encode
         * @memberof towerlegend.TowerlegendResultReply
         * @static
         * @param {towerlegend.ITowerlegendResultReply} message TowerlegendResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerlegendResultReply.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.winAmount);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.betAmount);
            if (message.payout != null && Object.hasOwnProperty.call(message, "payout"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.payout);
            if (message.layerData != null && message.layerData.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (var i = 0; i < message.layerData.length; ++i)
                    writer.int32(message.layerData[i]);
                writer.ldelim();
            }
            if (message.choices != null && message.choices.length) {
                writer.uint32(/* id 6, wireType 2 =*/50).fork();
                for (var i = 0; i < message.choices.length; ++i)
                    writer.int32(message.choices[i]);
                writer.ldelim();
            }
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.gameCode);
            return writer;
        };

        /**
         * Encodes the specified TowerlegendResultReply message, length delimited. Does not implicitly {@link towerlegend.TowerlegendResultReply.verify|verify} messages.
         * @function encodeDelimited
         * @memberof towerlegend.TowerlegendResultReply
         * @static
         * @param {towerlegend.ITowerlegendResultReply} message TowerlegendResultReply message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerlegendResultReply.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TowerlegendResultReply message from the specified reader or buffer.
         * @function decode
         * @memberof towerlegend.TowerlegendResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {towerlegend.TowerlegendResultReply} TowerlegendResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerlegendResultReply.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.towerlegend.TowerlegendResultReply();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.winAmount = reader.int64();
                    break;
                case 3:
                    message.betAmount = reader.int64();
                    break;
                case 4:
                    message.payout = reader.int32();
                    break;
                case 5:
                    if (!(message.layerData && message.layerData.length))
                        message.layerData = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.layerData.push(reader.int32());
                    } else
                        message.layerData.push(reader.int32());
                    break;
                case 6:
                    if (!(message.choices && message.choices.length))
                        message.choices = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.choices.push(reader.int32());
                    } else
                        message.choices.push(reader.int32());
                    break;
                case 7:
                    message.gameCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TowerlegendResultReply message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof towerlegend.TowerlegendResultReply
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {towerlegend.TowerlegendResultReply} TowerlegendResultReply
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerlegendResultReply.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TowerlegendResultReply message.
         * @function verify
         * @memberof towerlegend.TowerlegendResultReply
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TowerlegendResultReply.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.payout != null && message.hasOwnProperty("payout"))
                if (!$util.isInteger(message.payout))
                    return "payout: integer expected";
            if (message.layerData != null && message.hasOwnProperty("layerData")) {
                if (!Array.isArray(message.layerData))
                    return "layerData: array expected";
                for (var i = 0; i < message.layerData.length; ++i)
                    if (!$util.isInteger(message.layerData[i]))
                        return "layerData: integer[] expected";
            }
            if (message.choices != null && message.hasOwnProperty("choices")) {
                if (!Array.isArray(message.choices))
                    return "choices: array expected";
                for (var i = 0; i < message.choices.length; ++i)
                    if (!$util.isInteger(message.choices[i]))
                        return "choices: integer[] expected";
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            return null;
        };

        /**
         * Creates a TowerlegendResultReply message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof towerlegend.TowerlegendResultReply
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {towerlegend.TowerlegendResultReply} TowerlegendResultReply
         */
        TowerlegendResultReply.fromObject = function fromObject(object) {
            if (object instanceof $root.towerlegend.TowerlegendResultReply)
                return object;
            var message = new $root.towerlegend.TowerlegendResultReply();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.payout != null)
                message.payout = object.payout | 0;
            if (object.layerData) {
                if (!Array.isArray(object.layerData))
                    throw TypeError(".towerlegend.TowerlegendResultReply.layerData: array expected");
                message.layerData = [];
                for (var i = 0; i < object.layerData.length; ++i)
                    message.layerData[i] = object.layerData[i] | 0;
            }
            if (object.choices) {
                if (!Array.isArray(object.choices))
                    throw TypeError(".towerlegend.TowerlegendResultReply.choices: array expected");
                message.choices = [];
                for (var i = 0; i < object.choices.length; ++i)
                    message.choices[i] = object.choices[i] | 0;
            }
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            return message;
        };

        /**
         * Creates a plain object from a TowerlegendResultReply message. Also converts values to other types if specified.
         * @function toObject
         * @memberof towerlegend.TowerlegendResultReply
         * @static
         * @param {towerlegend.TowerlegendResultReply} message TowerlegendResultReply
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TowerlegendResultReply.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.layerData = [];
                object.choices = [];
            }
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.payout = 0;
                object.gameCode = "";
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.payout != null && message.hasOwnProperty("payout"))
                object.payout = message.payout;
            if (message.layerData && message.layerData.length) {
                object.layerData = [];
                for (var j = 0; j < message.layerData.length; ++j)
                    object.layerData[j] = message.layerData[j];
            }
            if (message.choices && message.choices.length) {
                object.choices = [];
                for (var j = 0; j < message.choices.length; ++j)
                    object.choices[j] = message.choices[j];
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            return object;
        };

        /**
         * Converts this TowerlegendResultReply to JSON.
         * @function toJSON
         * @memberof towerlegend.TowerlegendResultReply
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TowerlegendResultReply.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TowerlegendResultReply;
    })();

    towerlegend.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof towerlegend
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof towerlegend
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {towerlegend.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof towerlegend.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof towerlegend.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof towerlegend.HistoryResult
         * @static
         * @param {towerlegend.IHistoryResult=} [properties] Properties to set
         * @returns {towerlegend.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link towerlegend.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof towerlegend.HistoryResult
         * @static
         * @param {towerlegend.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link towerlegend.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof towerlegend.HistoryResult
         * @static
         * @param {towerlegend.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof towerlegend.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {towerlegend.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.towerlegend.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof towerlegend.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {towerlegend.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof towerlegend.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof towerlegend.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {towerlegend.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.towerlegend.HistoryResult)
                return object;
            var message = new $root.towerlegend.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof towerlegend.HistoryResult
         * @static
         * @param {towerlegend.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof towerlegend.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    return towerlegend;
})();

$root.crash = (function() {

    /**
     * Namespace crash.
     * @exports crash
     * @namespace
     */
    var crash = {};

    /**
     * DEADLINE_TYPE enum.
     * @name crash.DEADLINE_TYPE
     * @enum {number}
     * @property {number} DT_NONE=0 DT_NONE value
     * @property {number} START_BET=1 START_BET value
     * @property {number} STOP_BET=2 STOP_BET value
     * @property {number} START_FLY=3 START_FLY value
     * @property {number} END_FLY=4 END_FLY value
     * @property {number} GAME_END=5 GAME_END value
     */
    crash.DEADLINE_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DT_NONE"] = 0;
        values[valuesById[1] = "START_BET"] = 1;
        values[valuesById[2] = "STOP_BET"] = 2;
        values[valuesById[3] = "START_FLY"] = 3;
        values[valuesById[4] = "END_FLY"] = 4;
        values[valuesById[5] = "GAME_END"] = 5;
        return values;
    })();

    crash.GameStartEvent = (function() {

        /**
         * Properties of a GameStartEvent.
         * @memberof crash
         * @interface IGameStartEvent
         * @property {string|null} [gameCode] GameStartEvent gameCode
         * @property {string|null} [hashRound] GameStartEvent hashRound
         */

        /**
         * Constructs a new GameStartEvent.
         * @memberof crash
         * @classdesc Represents a GameStartEvent.
         * @implements IGameStartEvent
         * @constructor
         * @param {crash.IGameStartEvent=} [properties] Properties to set
         */
        function GameStartEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameStartEvent gameCode.
         * @member {string} gameCode
         * @memberof crash.GameStartEvent
         * @instance
         */
        GameStartEvent.prototype.gameCode = "";

        /**
         * GameStartEvent hashRound.
         * @member {string} hashRound
         * @memberof crash.GameStartEvent
         * @instance
         */
        GameStartEvent.prototype.hashRound = "";

        /**
         * Creates a new GameStartEvent instance using the specified properties.
         * @function create
         * @memberof crash.GameStartEvent
         * @static
         * @param {crash.IGameStartEvent=} [properties] Properties to set
         * @returns {crash.GameStartEvent} GameStartEvent instance
         */
        GameStartEvent.create = function create(properties) {
            return new GameStartEvent(properties);
        };

        /**
         * Encodes the specified GameStartEvent message. Does not implicitly {@link crash.GameStartEvent.verify|verify} messages.
         * @function encode
         * @memberof crash.GameStartEvent
         * @static
         * @param {crash.IGameStartEvent} message GameStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameStartEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.hashRound != null && Object.hasOwnProperty.call(message, "hashRound"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.hashRound);
            return writer;
        };

        /**
         * Encodes the specified GameStartEvent message, length delimited. Does not implicitly {@link crash.GameStartEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.GameStartEvent
         * @static
         * @param {crash.IGameStartEvent} message GameStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameStartEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameStartEvent message from the specified reader or buffer.
         * @function decode
         * @memberof crash.GameStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.GameStartEvent} GameStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameStartEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.GameStartEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.hashRound = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameStartEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.GameStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.GameStartEvent} GameStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameStartEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameStartEvent message.
         * @function verify
         * @memberof crash.GameStartEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameStartEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                if (!$util.isString(message.hashRound))
                    return "hashRound: string expected";
            return null;
        };

        /**
         * Creates a GameStartEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.GameStartEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.GameStartEvent} GameStartEvent
         */
        GameStartEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.GameStartEvent)
                return object;
            var message = new $root.crash.GameStartEvent();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.hashRound != null)
                message.hashRound = String(object.hashRound);
            return message;
        };

        /**
         * Creates a plain object from a GameStartEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.GameStartEvent
         * @static
         * @param {crash.GameStartEvent} message GameStartEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameStartEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.hashRound = "";
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                object.hashRound = message.hashRound;
            return object;
        };

        /**
         * Converts this GameStartEvent to JSON.
         * @function toJSON
         * @memberof crash.GameStartEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameStartEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameStartEvent;
    })();

    crash.CrashPlayersEvent = (function() {

        /**
         * Properties of a CrashPlayersEvent.
         * @memberof crash
         * @interface ICrashPlayersEvent
         * @property {Array.<crash.ICrashPlayer>|null} [crashPlayers] CrashPlayersEvent crashPlayers
         * @property {number|null} [playersNum] CrashPlayersEvent playersNum
         */

        /**
         * Constructs a new CrashPlayersEvent.
         * @memberof crash
         * @classdesc Represents a CrashPlayersEvent.
         * @implements ICrashPlayersEvent
         * @constructor
         * @param {crash.ICrashPlayersEvent=} [properties] Properties to set
         */
        function CrashPlayersEvent(properties) {
            this.crashPlayers = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CrashPlayersEvent crashPlayers.
         * @member {Array.<crash.ICrashPlayer>} crashPlayers
         * @memberof crash.CrashPlayersEvent
         * @instance
         */
        CrashPlayersEvent.prototype.crashPlayers = $util.emptyArray;

        /**
         * CrashPlayersEvent playersNum.
         * @member {number} playersNum
         * @memberof crash.CrashPlayersEvent
         * @instance
         */
        CrashPlayersEvent.prototype.playersNum = 0;

        /**
         * Creates a new CrashPlayersEvent instance using the specified properties.
         * @function create
         * @memberof crash.CrashPlayersEvent
         * @static
         * @param {crash.ICrashPlayersEvent=} [properties] Properties to set
         * @returns {crash.CrashPlayersEvent} CrashPlayersEvent instance
         */
        CrashPlayersEvent.create = function create(properties) {
            return new CrashPlayersEvent(properties);
        };

        /**
         * Encodes the specified CrashPlayersEvent message. Does not implicitly {@link crash.CrashPlayersEvent.verify|verify} messages.
         * @function encode
         * @memberof crash.CrashPlayersEvent
         * @static
         * @param {crash.ICrashPlayersEvent} message CrashPlayersEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CrashPlayersEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.crashPlayers != null && message.crashPlayers.length)
                for (var i = 0; i < message.crashPlayers.length; ++i)
                    $root.crash.CrashPlayer.encode(message.crashPlayers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.playersNum != null && Object.hasOwnProperty.call(message, "playersNum"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.playersNum);
            return writer;
        };

        /**
         * Encodes the specified CrashPlayersEvent message, length delimited. Does not implicitly {@link crash.CrashPlayersEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.CrashPlayersEvent
         * @static
         * @param {crash.ICrashPlayersEvent} message CrashPlayersEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CrashPlayersEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CrashPlayersEvent message from the specified reader or buffer.
         * @function decode
         * @memberof crash.CrashPlayersEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.CrashPlayersEvent} CrashPlayersEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CrashPlayersEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.CrashPlayersEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.crashPlayers && message.crashPlayers.length))
                        message.crashPlayers = [];
                    message.crashPlayers.push($root.crash.CrashPlayer.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.playersNum = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CrashPlayersEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.CrashPlayersEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.CrashPlayersEvent} CrashPlayersEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CrashPlayersEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CrashPlayersEvent message.
         * @function verify
         * @memberof crash.CrashPlayersEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CrashPlayersEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.crashPlayers != null && message.hasOwnProperty("crashPlayers")) {
                if (!Array.isArray(message.crashPlayers))
                    return "crashPlayers: array expected";
                for (var i = 0; i < message.crashPlayers.length; ++i) {
                    var error = $root.crash.CrashPlayer.verify(message.crashPlayers[i]);
                    if (error)
                        return "crashPlayers." + error;
                }
            }
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                if (!$util.isInteger(message.playersNum))
                    return "playersNum: integer expected";
            return null;
        };

        /**
         * Creates a CrashPlayersEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.CrashPlayersEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.CrashPlayersEvent} CrashPlayersEvent
         */
        CrashPlayersEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.CrashPlayersEvent)
                return object;
            var message = new $root.crash.CrashPlayersEvent();
            if (object.crashPlayers) {
                if (!Array.isArray(object.crashPlayers))
                    throw TypeError(".crash.CrashPlayersEvent.crashPlayers: array expected");
                message.crashPlayers = [];
                for (var i = 0; i < object.crashPlayers.length; ++i) {
                    if (typeof object.crashPlayers[i] !== "object")
                        throw TypeError(".crash.CrashPlayersEvent.crashPlayers: object expected");
                    message.crashPlayers[i] = $root.crash.CrashPlayer.fromObject(object.crashPlayers[i]);
                }
            }
            if (object.playersNum != null)
                message.playersNum = object.playersNum | 0;
            return message;
        };

        /**
         * Creates a plain object from a CrashPlayersEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.CrashPlayersEvent
         * @static
         * @param {crash.CrashPlayersEvent} message CrashPlayersEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CrashPlayersEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.crashPlayers = [];
            if (options.defaults)
                object.playersNum = 0;
            if (message.crashPlayers && message.crashPlayers.length) {
                object.crashPlayers = [];
                for (var j = 0; j < message.crashPlayers.length; ++j)
                    object.crashPlayers[j] = $root.crash.CrashPlayer.toObject(message.crashPlayers[j], options);
            }
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                object.playersNum = message.playersNum;
            return object;
        };

        /**
         * Converts this CrashPlayersEvent to JSON.
         * @function toJSON
         * @memberof crash.CrashPlayersEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CrashPlayersEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CrashPlayersEvent;
    })();

    crash.CrashPlayer = (function() {

        /**
         * Properties of a CrashPlayer.
         * @memberof crash
         * @interface ICrashPlayer
         * @property {number|null} [seat] CrashPlayer seat
         * @property {base.IPlayerBaseInfo|null} [baseInfo] CrashPlayer baseInfo
         * @property {boolean|null} [online] CrashPlayer online
         * @property {number|Long|null} [balance] CrashPlayer balance
         * @property {number|Long|null} [betAmount] CrashPlayer betAmount
         * @property {number|null} [betHeigth] CrashPlayer betHeigth
         * @property {number|null} [currencyType] CrashPlayer currencyType
         * @property {number|null} [cashOutHeight] CrashPlayer cashOutHeight
         * @property {number|Long|null} [ballAmount] CrashPlayer ballAmount
         * @property {Array.<number>|null} [ballColor] CrashPlayer ballColor
         */

        /**
         * Constructs a new CrashPlayer.
         * @memberof crash
         * @classdesc Represents a CrashPlayer.
         * @implements ICrashPlayer
         * @constructor
         * @param {crash.ICrashPlayer=} [properties] Properties to set
         */
        function CrashPlayer(properties) {
            this.ballColor = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CrashPlayer seat.
         * @member {number} seat
         * @memberof crash.CrashPlayer
         * @instance
         */
        CrashPlayer.prototype.seat = 0;

        /**
         * CrashPlayer baseInfo.
         * @member {base.IPlayerBaseInfo|null|undefined} baseInfo
         * @memberof crash.CrashPlayer
         * @instance
         */
        CrashPlayer.prototype.baseInfo = null;

        /**
         * CrashPlayer online.
         * @member {boolean} online
         * @memberof crash.CrashPlayer
         * @instance
         */
        CrashPlayer.prototype.online = false;

        /**
         * CrashPlayer balance.
         * @member {number|Long} balance
         * @memberof crash.CrashPlayer
         * @instance
         */
        CrashPlayer.prototype.balance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CrashPlayer betAmount.
         * @member {number|Long} betAmount
         * @memberof crash.CrashPlayer
         * @instance
         */
        CrashPlayer.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CrashPlayer betHeigth.
         * @member {number} betHeigth
         * @memberof crash.CrashPlayer
         * @instance
         */
        CrashPlayer.prototype.betHeigth = 0;

        /**
         * CrashPlayer currencyType.
         * @member {number} currencyType
         * @memberof crash.CrashPlayer
         * @instance
         */
        CrashPlayer.prototype.currencyType = 0;

        /**
         * CrashPlayer cashOutHeight.
         * @member {number} cashOutHeight
         * @memberof crash.CrashPlayer
         * @instance
         */
        CrashPlayer.prototype.cashOutHeight = 0;

        /**
         * CrashPlayer ballAmount.
         * @member {number|Long} ballAmount
         * @memberof crash.CrashPlayer
         * @instance
         */
        CrashPlayer.prototype.ballAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CrashPlayer ballColor.
         * @member {Array.<number>} ballColor
         * @memberof crash.CrashPlayer
         * @instance
         */
        CrashPlayer.prototype.ballColor = $util.emptyArray;

        /**
         * Creates a new CrashPlayer instance using the specified properties.
         * @function create
         * @memberof crash.CrashPlayer
         * @static
         * @param {crash.ICrashPlayer=} [properties] Properties to set
         * @returns {crash.CrashPlayer} CrashPlayer instance
         */
        CrashPlayer.create = function create(properties) {
            return new CrashPlayer(properties);
        };

        /**
         * Encodes the specified CrashPlayer message. Does not implicitly {@link crash.CrashPlayer.verify|verify} messages.
         * @function encode
         * @memberof crash.CrashPlayer
         * @static
         * @param {crash.ICrashPlayer} message CrashPlayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CrashPlayer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            if (message.baseInfo != null && Object.hasOwnProperty.call(message, "baseInfo"))
                $root.base.PlayerBaseInfo.encode(message.baseInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.online != null && Object.hasOwnProperty.call(message, "online"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.online);
            if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.balance);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.betAmount);
            if (message.betHeigth != null && Object.hasOwnProperty.call(message, "betHeigth"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.betHeigth);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.currencyType);
            if (message.cashOutHeight != null && Object.hasOwnProperty.call(message, "cashOutHeight"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.cashOutHeight);
            if (message.ballAmount != null && Object.hasOwnProperty.call(message, "ballAmount"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.ballAmount);
            if (message.ballColor != null && message.ballColor.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (var i = 0; i < message.ballColor.length; ++i)
                    writer.int32(message.ballColor[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified CrashPlayer message, length delimited. Does not implicitly {@link crash.CrashPlayer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.CrashPlayer
         * @static
         * @param {crash.ICrashPlayer} message CrashPlayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CrashPlayer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CrashPlayer message from the specified reader or buffer.
         * @function decode
         * @memberof crash.CrashPlayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.CrashPlayer} CrashPlayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CrashPlayer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.CrashPlayer();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                case 2:
                    message.baseInfo = $root.base.PlayerBaseInfo.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.online = reader.bool();
                    break;
                case 4:
                    message.balance = reader.int64();
                    break;
                case 5:
                    message.betAmount = reader.int64();
                    break;
                case 6:
                    message.betHeigth = reader.int32();
                    break;
                case 7:
                    message.currencyType = reader.int32();
                    break;
                case 8:
                    message.cashOutHeight = reader.int32();
                    break;
                case 9:
                    message.ballAmount = reader.int64();
                    break;
                case 10:
                    if (!(message.ballColor && message.ballColor.length))
                        message.ballColor = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.ballColor.push(reader.int32());
                    } else
                        message.ballColor.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CrashPlayer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.CrashPlayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.CrashPlayer} CrashPlayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CrashPlayer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CrashPlayer message.
         * @function verify
         * @memberof crash.CrashPlayer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CrashPlayer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo")) {
                var error = $root.base.PlayerBaseInfo.verify(message.baseInfo);
                if (error)
                    return "baseInfo." + error;
            }
            if (message.online != null && message.hasOwnProperty("online"))
                if (typeof message.online !== "boolean")
                    return "online: boolean expected";
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (!$util.isInteger(message.balance) && !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high)))
                    return "balance: integer|Long expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.betHeigth != null && message.hasOwnProperty("betHeigth"))
                if (!$util.isInteger(message.betHeigth))
                    return "betHeigth: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.cashOutHeight != null && message.hasOwnProperty("cashOutHeight"))
                if (!$util.isInteger(message.cashOutHeight))
                    return "cashOutHeight: integer expected";
            if (message.ballAmount != null && message.hasOwnProperty("ballAmount"))
                if (!$util.isInteger(message.ballAmount) && !(message.ballAmount && $util.isInteger(message.ballAmount.low) && $util.isInteger(message.ballAmount.high)))
                    return "ballAmount: integer|Long expected";
            if (message.ballColor != null && message.hasOwnProperty("ballColor")) {
                if (!Array.isArray(message.ballColor))
                    return "ballColor: array expected";
                for (var i = 0; i < message.ballColor.length; ++i)
                    if (!$util.isInteger(message.ballColor[i]))
                        return "ballColor: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a CrashPlayer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.CrashPlayer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.CrashPlayer} CrashPlayer
         */
        CrashPlayer.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.CrashPlayer)
                return object;
            var message = new $root.crash.CrashPlayer();
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.baseInfo != null) {
                if (typeof object.baseInfo !== "object")
                    throw TypeError(".crash.CrashPlayer.baseInfo: object expected");
                message.baseInfo = $root.base.PlayerBaseInfo.fromObject(object.baseInfo);
            }
            if (object.online != null)
                message.online = Boolean(object.online);
            if (object.balance != null)
                if ($util.Long)
                    (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
                else if (typeof object.balance === "string")
                    message.balance = parseInt(object.balance, 10);
                else if (typeof object.balance === "number")
                    message.balance = object.balance;
                else if (typeof object.balance === "object")
                    message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.betHeigth != null)
                message.betHeigth = object.betHeigth | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.cashOutHeight != null)
                message.cashOutHeight = object.cashOutHeight | 0;
            if (object.ballAmount != null)
                if ($util.Long)
                    (message.ballAmount = $util.Long.fromValue(object.ballAmount)).unsigned = false;
                else if (typeof object.ballAmount === "string")
                    message.ballAmount = parseInt(object.ballAmount, 10);
                else if (typeof object.ballAmount === "number")
                    message.ballAmount = object.ballAmount;
                else if (typeof object.ballAmount === "object")
                    message.ballAmount = new $util.LongBits(object.ballAmount.low >>> 0, object.ballAmount.high >>> 0).toNumber();
            if (object.ballColor) {
                if (!Array.isArray(object.ballColor))
                    throw TypeError(".crash.CrashPlayer.ballColor: array expected");
                message.ballColor = [];
                for (var i = 0; i < object.ballColor.length; ++i)
                    message.ballColor[i] = object.ballColor[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a CrashPlayer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.CrashPlayer
         * @static
         * @param {crash.CrashPlayer} message CrashPlayer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CrashPlayer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.ballColor = [];
            if (options.defaults) {
                object.seat = 0;
                object.baseInfo = null;
                object.online = false;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.balance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.balance = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.betHeigth = 0;
                object.currencyType = 0;
                object.cashOutHeight = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.ballAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ballAmount = options.longs === String ? "0" : 0;
            }
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo"))
                object.baseInfo = $root.base.PlayerBaseInfo.toObject(message.baseInfo, options);
            if (message.online != null && message.hasOwnProperty("online"))
                object.online = message.online;
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (typeof message.balance === "number")
                    object.balance = options.longs === String ? String(message.balance) : message.balance;
                else
                    object.balance = options.longs === String ? $util.Long.prototype.toString.call(message.balance) : options.longs === Number ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber() : message.balance;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.betHeigth != null && message.hasOwnProperty("betHeigth"))
                object.betHeigth = message.betHeigth;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.cashOutHeight != null && message.hasOwnProperty("cashOutHeight"))
                object.cashOutHeight = message.cashOutHeight;
            if (message.ballAmount != null && message.hasOwnProperty("ballAmount"))
                if (typeof message.ballAmount === "number")
                    object.ballAmount = options.longs === String ? String(message.ballAmount) : message.ballAmount;
                else
                    object.ballAmount = options.longs === String ? $util.Long.prototype.toString.call(message.ballAmount) : options.longs === Number ? new $util.LongBits(message.ballAmount.low >>> 0, message.ballAmount.high >>> 0).toNumber() : message.ballAmount;
            if (message.ballColor && message.ballColor.length) {
                object.ballColor = [];
                for (var j = 0; j < message.ballColor.length; ++j)
                    object.ballColor[j] = message.ballColor[j];
            }
            return object;
        };

        /**
         * Converts this CrashPlayer to JSON.
         * @function toJSON
         * @memberof crash.CrashPlayer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CrashPlayer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CrashPlayer;
    })();

    crash.TableStateEvent = (function() {

        /**
         * Properties of a TableStateEvent.
         * @memberof crash
         * @interface ITableStateEvent
         * @property {string|null} [gameCode] TableStateEvent gameCode
         * @property {number|null} [playersNum] TableStateEvent playersNum
         * @property {number|Long|null} [beginFlyTime] TableStateEvent beginFlyTime
         * @property {number|null} [flyTime] TableStateEvent flyTime
         * @property {string|null} [hashRound] TableStateEvent hashRound
         */

        /**
         * Constructs a new TableStateEvent.
         * @memberof crash
         * @classdesc Represents a TableStateEvent.
         * @implements ITableStateEvent
         * @constructor
         * @param {crash.ITableStateEvent=} [properties] Properties to set
         */
        function TableStateEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableStateEvent gameCode.
         * @member {string} gameCode
         * @memberof crash.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.gameCode = "";

        /**
         * TableStateEvent playersNum.
         * @member {number} playersNum
         * @memberof crash.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.playersNum = 0;

        /**
         * TableStateEvent beginFlyTime.
         * @member {number|Long} beginFlyTime
         * @memberof crash.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.beginFlyTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TableStateEvent flyTime.
         * @member {number} flyTime
         * @memberof crash.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.flyTime = 0;

        /**
         * TableStateEvent hashRound.
         * @member {string} hashRound
         * @memberof crash.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.hashRound = "";

        /**
         * Creates a new TableStateEvent instance using the specified properties.
         * @function create
         * @memberof crash.TableStateEvent
         * @static
         * @param {crash.ITableStateEvent=} [properties] Properties to set
         * @returns {crash.TableStateEvent} TableStateEvent instance
         */
        TableStateEvent.create = function create(properties) {
            return new TableStateEvent(properties);
        };

        /**
         * Encodes the specified TableStateEvent message. Does not implicitly {@link crash.TableStateEvent.verify|verify} messages.
         * @function encode
         * @memberof crash.TableStateEvent
         * @static
         * @param {crash.ITableStateEvent} message TableStateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableStateEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.playersNum != null && Object.hasOwnProperty.call(message, "playersNum"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.playersNum);
            if (message.beginFlyTime != null && Object.hasOwnProperty.call(message, "beginFlyTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.beginFlyTime);
            if (message.flyTime != null && Object.hasOwnProperty.call(message, "flyTime"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.flyTime);
            if (message.hashRound != null && Object.hasOwnProperty.call(message, "hashRound"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.hashRound);
            return writer;
        };

        /**
         * Encodes the specified TableStateEvent message, length delimited. Does not implicitly {@link crash.TableStateEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.TableStateEvent
         * @static
         * @param {crash.ITableStateEvent} message TableStateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableStateEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableStateEvent message from the specified reader or buffer.
         * @function decode
         * @memberof crash.TableStateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.TableStateEvent} TableStateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableStateEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.TableStateEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.playersNum = reader.int32();
                    break;
                case 3:
                    message.beginFlyTime = reader.int64();
                    break;
                case 4:
                    message.flyTime = reader.int32();
                    break;
                case 5:
                    message.hashRound = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableStateEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.TableStateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.TableStateEvent} TableStateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableStateEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableStateEvent message.
         * @function verify
         * @memberof crash.TableStateEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableStateEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                if (!$util.isInteger(message.playersNum))
                    return "playersNum: integer expected";
            if (message.beginFlyTime != null && message.hasOwnProperty("beginFlyTime"))
                if (!$util.isInteger(message.beginFlyTime) && !(message.beginFlyTime && $util.isInteger(message.beginFlyTime.low) && $util.isInteger(message.beginFlyTime.high)))
                    return "beginFlyTime: integer|Long expected";
            if (message.flyTime != null && message.hasOwnProperty("flyTime"))
                if (!$util.isInteger(message.flyTime))
                    return "flyTime: integer expected";
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                if (!$util.isString(message.hashRound))
                    return "hashRound: string expected";
            return null;
        };

        /**
         * Creates a TableStateEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.TableStateEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.TableStateEvent} TableStateEvent
         */
        TableStateEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.TableStateEvent)
                return object;
            var message = new $root.crash.TableStateEvent();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.playersNum != null)
                message.playersNum = object.playersNum | 0;
            if (object.beginFlyTime != null)
                if ($util.Long)
                    (message.beginFlyTime = $util.Long.fromValue(object.beginFlyTime)).unsigned = false;
                else if (typeof object.beginFlyTime === "string")
                    message.beginFlyTime = parseInt(object.beginFlyTime, 10);
                else if (typeof object.beginFlyTime === "number")
                    message.beginFlyTime = object.beginFlyTime;
                else if (typeof object.beginFlyTime === "object")
                    message.beginFlyTime = new $util.LongBits(object.beginFlyTime.low >>> 0, object.beginFlyTime.high >>> 0).toNumber();
            if (object.flyTime != null)
                message.flyTime = object.flyTime | 0;
            if (object.hashRound != null)
                message.hashRound = String(object.hashRound);
            return message;
        };

        /**
         * Creates a plain object from a TableStateEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.TableStateEvent
         * @static
         * @param {crash.TableStateEvent} message TableStateEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableStateEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.playersNum = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.beginFlyTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.beginFlyTime = options.longs === String ? "0" : 0;
                object.flyTime = 0;
                object.hashRound = "";
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                object.playersNum = message.playersNum;
            if (message.beginFlyTime != null && message.hasOwnProperty("beginFlyTime"))
                if (typeof message.beginFlyTime === "number")
                    object.beginFlyTime = options.longs === String ? String(message.beginFlyTime) : message.beginFlyTime;
                else
                    object.beginFlyTime = options.longs === String ? $util.Long.prototype.toString.call(message.beginFlyTime) : options.longs === Number ? new $util.LongBits(message.beginFlyTime.low >>> 0, message.beginFlyTime.high >>> 0).toNumber() : message.beginFlyTime;
            if (message.flyTime != null && message.hasOwnProperty("flyTime"))
                object.flyTime = message.flyTime;
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                object.hashRound = message.hashRound;
            return object;
        };

        /**
         * Converts this TableStateEvent to JSON.
         * @function toJSON
         * @memberof crash.TableStateEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableStateEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TableStateEvent;
    })();

    crash.HistoryResultEvent = (function() {

        /**
         * Properties of a HistoryResultEvent.
         * @memberof crash
         * @interface IHistoryResultEvent
         * @property {Array.<crash.IHistoryResult>|null} [historyResults] HistoryResultEvent historyResults
         */

        /**
         * Constructs a new HistoryResultEvent.
         * @memberof crash
         * @classdesc Represents a HistoryResultEvent.
         * @implements IHistoryResultEvent
         * @constructor
         * @param {crash.IHistoryResultEvent=} [properties] Properties to set
         */
        function HistoryResultEvent(properties) {
            this.historyResults = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResultEvent historyResults.
         * @member {Array.<crash.IHistoryResult>} historyResults
         * @memberof crash.HistoryResultEvent
         * @instance
         */
        HistoryResultEvent.prototype.historyResults = $util.emptyArray;

        /**
         * Creates a new HistoryResultEvent instance using the specified properties.
         * @function create
         * @memberof crash.HistoryResultEvent
         * @static
         * @param {crash.IHistoryResultEvent=} [properties] Properties to set
         * @returns {crash.HistoryResultEvent} HistoryResultEvent instance
         */
        HistoryResultEvent.create = function create(properties) {
            return new HistoryResultEvent(properties);
        };

        /**
         * Encodes the specified HistoryResultEvent message. Does not implicitly {@link crash.HistoryResultEvent.verify|verify} messages.
         * @function encode
         * @memberof crash.HistoryResultEvent
         * @static
         * @param {crash.IHistoryResultEvent} message HistoryResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResultEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.historyResults != null && message.historyResults.length)
                for (var i = 0; i < message.historyResults.length; ++i)
                    $root.crash.HistoryResult.encode(message.historyResults[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HistoryResultEvent message, length delimited. Does not implicitly {@link crash.HistoryResultEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.HistoryResultEvent
         * @static
         * @param {crash.IHistoryResultEvent} message HistoryResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResultEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResultEvent message from the specified reader or buffer.
         * @function decode
         * @memberof crash.HistoryResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.HistoryResultEvent} HistoryResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResultEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.HistoryResultEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.historyResults && message.historyResults.length))
                        message.historyResults = [];
                    message.historyResults.push($root.crash.HistoryResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResultEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.HistoryResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.HistoryResultEvent} HistoryResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResultEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResultEvent message.
         * @function verify
         * @memberof crash.HistoryResultEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResultEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.historyResults != null && message.hasOwnProperty("historyResults")) {
                if (!Array.isArray(message.historyResults))
                    return "historyResults: array expected";
                for (var i = 0; i < message.historyResults.length; ++i) {
                    var error = $root.crash.HistoryResult.verify(message.historyResults[i]);
                    if (error)
                        return "historyResults." + error;
                }
            }
            return null;
        };

        /**
         * Creates a HistoryResultEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.HistoryResultEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.HistoryResultEvent} HistoryResultEvent
         */
        HistoryResultEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.HistoryResultEvent)
                return object;
            var message = new $root.crash.HistoryResultEvent();
            if (object.historyResults) {
                if (!Array.isArray(object.historyResults))
                    throw TypeError(".crash.HistoryResultEvent.historyResults: array expected");
                message.historyResults = [];
                for (var i = 0; i < object.historyResults.length; ++i) {
                    if (typeof object.historyResults[i] !== "object")
                        throw TypeError(".crash.HistoryResultEvent.historyResults: object expected");
                    message.historyResults[i] = $root.crash.HistoryResult.fromObject(object.historyResults[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a HistoryResultEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.HistoryResultEvent
         * @static
         * @param {crash.HistoryResultEvent} message HistoryResultEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResultEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.historyResults = [];
            if (message.historyResults && message.historyResults.length) {
                object.historyResults = [];
                for (var j = 0; j < message.historyResults.length; ++j)
                    object.historyResults[j] = $root.crash.HistoryResult.toObject(message.historyResults[j], options);
            }
            return object;
        };

        /**
         * Converts this HistoryResultEvent to JSON.
         * @function toJSON
         * @memberof crash.HistoryResultEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResultEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResultEvent;
    })();

    crash.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof crash
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof crash
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {crash.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof crash.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof crash.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof crash.HistoryResult
         * @static
         * @param {crash.IHistoryResult=} [properties] Properties to set
         * @returns {crash.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link crash.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof crash.HistoryResult
         * @static
         * @param {crash.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link crash.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.HistoryResult
         * @static
         * @param {crash.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof crash.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof crash.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.HistoryResult)
                return object;
            var message = new $root.crash.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.HistoryResult
         * @static
         * @param {crash.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof crash.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    crash.BetReq = (function() {

        /**
         * Properties of a BetReq.
         * @memberof crash
         * @interface IBetReq
         * @property {number|null} [currencyType] BetReq currencyType
         * @property {number|null} [betHeight] BetReq betHeight
         * @property {number|Long|null} [betAmount] BetReq betAmount
         * @property {number|null} [betColor] BetReq betColor
         */

        /**
         * Constructs a new BetReq.
         * @memberof crash
         * @classdesc Represents a BetReq.
         * @implements IBetReq
         * @constructor
         * @param {crash.IBetReq=} [properties] Properties to set
         */
        function BetReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BetReq currencyType.
         * @member {number} currencyType
         * @memberof crash.BetReq
         * @instance
         */
        BetReq.prototype.currencyType = 0;

        /**
         * BetReq betHeight.
         * @member {number} betHeight
         * @memberof crash.BetReq
         * @instance
         */
        BetReq.prototype.betHeight = 0;

        /**
         * BetReq betAmount.
         * @member {number|Long} betAmount
         * @memberof crash.BetReq
         * @instance
         */
        BetReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BetReq betColor.
         * @member {number} betColor
         * @memberof crash.BetReq
         * @instance
         */
        BetReq.prototype.betColor = 0;

        /**
         * Creates a new BetReq instance using the specified properties.
         * @function create
         * @memberof crash.BetReq
         * @static
         * @param {crash.IBetReq=} [properties] Properties to set
         * @returns {crash.BetReq} BetReq instance
         */
        BetReq.create = function create(properties) {
            return new BetReq(properties);
        };

        /**
         * Encodes the specified BetReq message. Does not implicitly {@link crash.BetReq.verify|verify} messages.
         * @function encode
         * @memberof crash.BetReq
         * @static
         * @param {crash.IBetReq} message BetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betHeight != null && Object.hasOwnProperty.call(message, "betHeight"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.betHeight);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.betAmount);
            if (message.betColor != null && Object.hasOwnProperty.call(message, "betColor"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.betColor);
            return writer;
        };

        /**
         * Encodes the specified BetReq message, length delimited. Does not implicitly {@link crash.BetReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.BetReq
         * @static
         * @param {crash.IBetReq} message BetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BetReq message from the specified reader or buffer.
         * @function decode
         * @memberof crash.BetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.BetReq} BetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.BetReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betHeight = reader.int32();
                    break;
                case 3:
                    message.betAmount = reader.int64();
                    break;
                case 4:
                    message.betColor = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BetReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.BetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.BetReq} BetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BetReq message.
         * @function verify
         * @memberof crash.BetReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BetReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betHeight != null && message.hasOwnProperty("betHeight"))
                if (!$util.isInteger(message.betHeight))
                    return "betHeight: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                if (!$util.isInteger(message.betColor))
                    return "betColor: integer expected";
            return null;
        };

        /**
         * Creates a BetReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.BetReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.BetReq} BetReq
         */
        BetReq.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.BetReq)
                return object;
            var message = new $root.crash.BetReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betHeight != null)
                message.betHeight = object.betHeight | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.betColor != null)
                message.betColor = object.betColor | 0;
            return message;
        };

        /**
         * Creates a plain object from a BetReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.BetReq
         * @static
         * @param {crash.BetReq} message BetReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BetReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                object.betHeight = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.betColor = 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betHeight != null && message.hasOwnProperty("betHeight"))
                object.betHeight = message.betHeight;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                object.betColor = message.betColor;
            return object;
        };

        /**
         * Converts this BetReq to JSON.
         * @function toJSON
         * @memberof crash.BetReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BetReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BetReq;
    })();

    crash.BetEvent = (function() {

        /**
         * Properties of a BetEvent.
         * @memberof crash
         * @interface IBetEvent
         * @property {number|null} [seat] BetEvent seat
         * @property {number|null} [cashOut] BetEvent cashOut
         * @property {number|Long|null} [betAmount] BetEvent betAmount
         * @property {number|null} [currencyType] BetEvent currencyType
         * @property {number|null} [betColor] BetEvent betColor
         */

        /**
         * Constructs a new BetEvent.
         * @memberof crash
         * @classdesc Represents a BetEvent.
         * @implements IBetEvent
         * @constructor
         * @param {crash.IBetEvent=} [properties] Properties to set
         */
        function BetEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BetEvent seat.
         * @member {number} seat
         * @memberof crash.BetEvent
         * @instance
         */
        BetEvent.prototype.seat = 0;

        /**
         * BetEvent cashOut.
         * @member {number} cashOut
         * @memberof crash.BetEvent
         * @instance
         */
        BetEvent.prototype.cashOut = 0;

        /**
         * BetEvent betAmount.
         * @member {number|Long} betAmount
         * @memberof crash.BetEvent
         * @instance
         */
        BetEvent.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BetEvent currencyType.
         * @member {number} currencyType
         * @memberof crash.BetEvent
         * @instance
         */
        BetEvent.prototype.currencyType = 0;

        /**
         * BetEvent betColor.
         * @member {number} betColor
         * @memberof crash.BetEvent
         * @instance
         */
        BetEvent.prototype.betColor = 0;

        /**
         * Creates a new BetEvent instance using the specified properties.
         * @function create
         * @memberof crash.BetEvent
         * @static
         * @param {crash.IBetEvent=} [properties] Properties to set
         * @returns {crash.BetEvent} BetEvent instance
         */
        BetEvent.create = function create(properties) {
            return new BetEvent(properties);
        };

        /**
         * Encodes the specified BetEvent message. Does not implicitly {@link crash.BetEvent.verify|verify} messages.
         * @function encode
         * @memberof crash.BetEvent
         * @static
         * @param {crash.IBetEvent} message BetEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            if (message.cashOut != null && Object.hasOwnProperty.call(message, "cashOut"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.cashOut);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.betAmount);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.currencyType);
            if (message.betColor != null && Object.hasOwnProperty.call(message, "betColor"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.betColor);
            return writer;
        };

        /**
         * Encodes the specified BetEvent message, length delimited. Does not implicitly {@link crash.BetEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.BetEvent
         * @static
         * @param {crash.IBetEvent} message BetEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BetEvent message from the specified reader or buffer.
         * @function decode
         * @memberof crash.BetEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.BetEvent} BetEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.BetEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                case 2:
                    message.cashOut = reader.int32();
                    break;
                case 3:
                    message.betAmount = reader.int64();
                    break;
                case 4:
                    message.currencyType = reader.int32();
                    break;
                case 5:
                    message.betColor = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BetEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.BetEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.BetEvent} BetEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BetEvent message.
         * @function verify
         * @memberof crash.BetEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BetEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.cashOut != null && message.hasOwnProperty("cashOut"))
                if (!$util.isInteger(message.cashOut))
                    return "cashOut: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                if (!$util.isInteger(message.betColor))
                    return "betColor: integer expected";
            return null;
        };

        /**
         * Creates a BetEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.BetEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.BetEvent} BetEvent
         */
        BetEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.BetEvent)
                return object;
            var message = new $root.crash.BetEvent();
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.cashOut != null)
                message.cashOut = object.cashOut | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betColor != null)
                message.betColor = object.betColor | 0;
            return message;
        };

        /**
         * Creates a plain object from a BetEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.BetEvent
         * @static
         * @param {crash.BetEvent} message BetEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BetEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.seat = 0;
                object.cashOut = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.currencyType = 0;
                object.betColor = 0;
            }
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.cashOut != null && message.hasOwnProperty("cashOut"))
                object.cashOut = message.cashOut;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                object.betColor = message.betColor;
            return object;
        };

        /**
         * Converts this BetEvent to JSON.
         * @function toJSON
         * @memberof crash.BetEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BetEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BetEvent;
    })();

    crash.CashOutReq = (function() {

        /**
         * Properties of a CashOutReq.
         * @memberof crash
         * @interface ICashOutReq
         * @property {number|null} [height] CashOutReq height
         */

        /**
         * Constructs a new CashOutReq.
         * @memberof crash
         * @classdesc Represents a CashOutReq.
         * @implements ICashOutReq
         * @constructor
         * @param {crash.ICashOutReq=} [properties] Properties to set
         */
        function CashOutReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CashOutReq height.
         * @member {number} height
         * @memberof crash.CashOutReq
         * @instance
         */
        CashOutReq.prototype.height = 0;

        /**
         * Creates a new CashOutReq instance using the specified properties.
         * @function create
         * @memberof crash.CashOutReq
         * @static
         * @param {crash.ICashOutReq=} [properties] Properties to set
         * @returns {crash.CashOutReq} CashOutReq instance
         */
        CashOutReq.create = function create(properties) {
            return new CashOutReq(properties);
        };

        /**
         * Encodes the specified CashOutReq message. Does not implicitly {@link crash.CashOutReq.verify|verify} messages.
         * @function encode
         * @memberof crash.CashOutReq
         * @static
         * @param {crash.ICashOutReq} message CashOutReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CashOutReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.height);
            return writer;
        };

        /**
         * Encodes the specified CashOutReq message, length delimited. Does not implicitly {@link crash.CashOutReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.CashOutReq
         * @static
         * @param {crash.ICashOutReq} message CashOutReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CashOutReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CashOutReq message from the specified reader or buffer.
         * @function decode
         * @memberof crash.CashOutReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.CashOutReq} CashOutReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CashOutReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.CashOutReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.height = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CashOutReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.CashOutReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.CashOutReq} CashOutReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CashOutReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CashOutReq message.
         * @function verify
         * @memberof crash.CashOutReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CashOutReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            return null;
        };

        /**
         * Creates a CashOutReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.CashOutReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.CashOutReq} CashOutReq
         */
        CashOutReq.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.CashOutReq)
                return object;
            var message = new $root.crash.CashOutReq();
            if (object.height != null)
                message.height = object.height | 0;
            return message;
        };

        /**
         * Creates a plain object from a CashOutReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.CashOutReq
         * @static
         * @param {crash.CashOutReq} message CashOutReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CashOutReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.height = 0;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            return object;
        };

        /**
         * Converts this CashOutReq to JSON.
         * @function toJSON
         * @memberof crash.CashOutReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CashOutReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CashOutReq;
    })();

    crash.FlyEndEvent = (function() {

        /**
         * Properties of a FlyEndEvent.
         * @memberof crash
         * @interface IFlyEndEvent
         * @property {number|null} [flyTime] FlyEndEvent flyTime
         */

        /**
         * Constructs a new FlyEndEvent.
         * @memberof crash
         * @classdesc Represents a FlyEndEvent.
         * @implements IFlyEndEvent
         * @constructor
         * @param {crash.IFlyEndEvent=} [properties] Properties to set
         */
        function FlyEndEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FlyEndEvent flyTime.
         * @member {number} flyTime
         * @memberof crash.FlyEndEvent
         * @instance
         */
        FlyEndEvent.prototype.flyTime = 0;

        /**
         * Creates a new FlyEndEvent instance using the specified properties.
         * @function create
         * @memberof crash.FlyEndEvent
         * @static
         * @param {crash.IFlyEndEvent=} [properties] Properties to set
         * @returns {crash.FlyEndEvent} FlyEndEvent instance
         */
        FlyEndEvent.create = function create(properties) {
            return new FlyEndEvent(properties);
        };

        /**
         * Encodes the specified FlyEndEvent message. Does not implicitly {@link crash.FlyEndEvent.verify|verify} messages.
         * @function encode
         * @memberof crash.FlyEndEvent
         * @static
         * @param {crash.IFlyEndEvent} message FlyEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FlyEndEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.flyTime != null && Object.hasOwnProperty.call(message, "flyTime"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.flyTime);
            return writer;
        };

        /**
         * Encodes the specified FlyEndEvent message, length delimited. Does not implicitly {@link crash.FlyEndEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.FlyEndEvent
         * @static
         * @param {crash.IFlyEndEvent} message FlyEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FlyEndEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FlyEndEvent message from the specified reader or buffer.
         * @function decode
         * @memberof crash.FlyEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.FlyEndEvent} FlyEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FlyEndEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.FlyEndEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.flyTime = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FlyEndEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.FlyEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.FlyEndEvent} FlyEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FlyEndEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FlyEndEvent message.
         * @function verify
         * @memberof crash.FlyEndEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FlyEndEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.flyTime != null && message.hasOwnProperty("flyTime"))
                if (!$util.isInteger(message.flyTime))
                    return "flyTime: integer expected";
            return null;
        };

        /**
         * Creates a FlyEndEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.FlyEndEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.FlyEndEvent} FlyEndEvent
         */
        FlyEndEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.FlyEndEvent)
                return object;
            var message = new $root.crash.FlyEndEvent();
            if (object.flyTime != null)
                message.flyTime = object.flyTime | 0;
            return message;
        };

        /**
         * Creates a plain object from a FlyEndEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.FlyEndEvent
         * @static
         * @param {crash.FlyEndEvent} message FlyEndEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FlyEndEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.flyTime = 0;
            if (message.flyTime != null && message.hasOwnProperty("flyTime"))
                object.flyTime = message.flyTime;
            return object;
        };

        /**
         * Converts this FlyEndEvent to JSON.
         * @function toJSON
         * @memberof crash.FlyEndEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FlyEndEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FlyEndEvent;
    })();

    crash.GameWinEvent = (function() {

        /**
         * Properties of a GameWinEvent.
         * @memberof crash
         * @interface IGameWinEvent
         * @property {Array.<crash.IWinPlayerInfo>|null} [infos] GameWinEvent infos
         */

        /**
         * Constructs a new GameWinEvent.
         * @memberof crash
         * @classdesc Represents a GameWinEvent.
         * @implements IGameWinEvent
         * @constructor
         * @param {crash.IGameWinEvent=} [properties] Properties to set
         */
        function GameWinEvent(properties) {
            this.infos = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameWinEvent infos.
         * @member {Array.<crash.IWinPlayerInfo>} infos
         * @memberof crash.GameWinEvent
         * @instance
         */
        GameWinEvent.prototype.infos = $util.emptyArray;

        /**
         * Creates a new GameWinEvent instance using the specified properties.
         * @function create
         * @memberof crash.GameWinEvent
         * @static
         * @param {crash.IGameWinEvent=} [properties] Properties to set
         * @returns {crash.GameWinEvent} GameWinEvent instance
         */
        GameWinEvent.create = function create(properties) {
            return new GameWinEvent(properties);
        };

        /**
         * Encodes the specified GameWinEvent message. Does not implicitly {@link crash.GameWinEvent.verify|verify} messages.
         * @function encode
         * @memberof crash.GameWinEvent
         * @static
         * @param {crash.IGameWinEvent} message GameWinEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameWinEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.infos != null && message.infos.length)
                for (var i = 0; i < message.infos.length; ++i)
                    $root.crash.WinPlayerInfo.encode(message.infos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GameWinEvent message, length delimited. Does not implicitly {@link crash.GameWinEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.GameWinEvent
         * @static
         * @param {crash.IGameWinEvent} message GameWinEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameWinEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameWinEvent message from the specified reader or buffer.
         * @function decode
         * @memberof crash.GameWinEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.GameWinEvent} GameWinEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameWinEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.GameWinEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.infos && message.infos.length))
                        message.infos = [];
                    message.infos.push($root.crash.WinPlayerInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameWinEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.GameWinEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.GameWinEvent} GameWinEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameWinEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameWinEvent message.
         * @function verify
         * @memberof crash.GameWinEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameWinEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.infos != null && message.hasOwnProperty("infos")) {
                if (!Array.isArray(message.infos))
                    return "infos: array expected";
                for (var i = 0; i < message.infos.length; ++i) {
                    var error = $root.crash.WinPlayerInfo.verify(message.infos[i]);
                    if (error)
                        return "infos." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GameWinEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.GameWinEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.GameWinEvent} GameWinEvent
         */
        GameWinEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.GameWinEvent)
                return object;
            var message = new $root.crash.GameWinEvent();
            if (object.infos) {
                if (!Array.isArray(object.infos))
                    throw TypeError(".crash.GameWinEvent.infos: array expected");
                message.infos = [];
                for (var i = 0; i < object.infos.length; ++i) {
                    if (typeof object.infos[i] !== "object")
                        throw TypeError(".crash.GameWinEvent.infos: object expected");
                    message.infos[i] = $root.crash.WinPlayerInfo.fromObject(object.infos[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GameWinEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.GameWinEvent
         * @static
         * @param {crash.GameWinEvent} message GameWinEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameWinEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.infos = [];
            if (message.infos && message.infos.length) {
                object.infos = [];
                for (var j = 0; j < message.infos.length; ++j)
                    object.infos[j] = $root.crash.WinPlayerInfo.toObject(message.infos[j], options);
            }
            return object;
        };

        /**
         * Converts this GameWinEvent to JSON.
         * @function toJSON
         * @memberof crash.GameWinEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameWinEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameWinEvent;
    })();

    crash.WinPlayerInfo = (function() {

        /**
         * Properties of a WinPlayerInfo.
         * @memberof crash
         * @interface IWinPlayerInfo
         * @property {number|null} [currencyType] WinPlayerInfo currencyType
         * @property {number|Long|null} [winAmount] WinPlayerInfo winAmount
         * @property {number|Long|null} [cashOut] WinPlayerInfo cashOut
         * @property {number|null} [betColor] WinPlayerInfo betColor
         * @property {base.IPlayerBaseInfo|null} [baseInfo] WinPlayerInfo baseInfo
         */

        /**
         * Constructs a new WinPlayerInfo.
         * @memberof crash
         * @classdesc Represents a WinPlayerInfo.
         * @implements IWinPlayerInfo
         * @constructor
         * @param {crash.IWinPlayerInfo=} [properties] Properties to set
         */
        function WinPlayerInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WinPlayerInfo currencyType.
         * @member {number} currencyType
         * @memberof crash.WinPlayerInfo
         * @instance
         */
        WinPlayerInfo.prototype.currencyType = 0;

        /**
         * WinPlayerInfo winAmount.
         * @member {number|Long} winAmount
         * @memberof crash.WinPlayerInfo
         * @instance
         */
        WinPlayerInfo.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WinPlayerInfo cashOut.
         * @member {number|Long} cashOut
         * @memberof crash.WinPlayerInfo
         * @instance
         */
        WinPlayerInfo.prototype.cashOut = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WinPlayerInfo betColor.
         * @member {number} betColor
         * @memberof crash.WinPlayerInfo
         * @instance
         */
        WinPlayerInfo.prototype.betColor = 0;

        /**
         * WinPlayerInfo baseInfo.
         * @member {base.IPlayerBaseInfo|null|undefined} baseInfo
         * @memberof crash.WinPlayerInfo
         * @instance
         */
        WinPlayerInfo.prototype.baseInfo = null;

        /**
         * Creates a new WinPlayerInfo instance using the specified properties.
         * @function create
         * @memberof crash.WinPlayerInfo
         * @static
         * @param {crash.IWinPlayerInfo=} [properties] Properties to set
         * @returns {crash.WinPlayerInfo} WinPlayerInfo instance
         */
        WinPlayerInfo.create = function create(properties) {
            return new WinPlayerInfo(properties);
        };

        /**
         * Encodes the specified WinPlayerInfo message. Does not implicitly {@link crash.WinPlayerInfo.verify|verify} messages.
         * @function encode
         * @memberof crash.WinPlayerInfo
         * @static
         * @param {crash.IWinPlayerInfo} message WinPlayerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WinPlayerInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.winAmount);
            if (message.cashOut != null && Object.hasOwnProperty.call(message, "cashOut"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.cashOut);
            if (message.betColor != null && Object.hasOwnProperty.call(message, "betColor"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.betColor);
            if (message.baseInfo != null && Object.hasOwnProperty.call(message, "baseInfo"))
                $root.base.PlayerBaseInfo.encode(message.baseInfo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WinPlayerInfo message, length delimited. Does not implicitly {@link crash.WinPlayerInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.WinPlayerInfo
         * @static
         * @param {crash.IWinPlayerInfo} message WinPlayerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WinPlayerInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WinPlayerInfo message from the specified reader or buffer.
         * @function decode
         * @memberof crash.WinPlayerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.WinPlayerInfo} WinPlayerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WinPlayerInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.WinPlayerInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.winAmount = reader.int64();
                    break;
                case 3:
                    message.cashOut = reader.int64();
                    break;
                case 4:
                    message.betColor = reader.int32();
                    break;
                case 5:
                    message.baseInfo = $root.base.PlayerBaseInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WinPlayerInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.WinPlayerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.WinPlayerInfo} WinPlayerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WinPlayerInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WinPlayerInfo message.
         * @function verify
         * @memberof crash.WinPlayerInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WinPlayerInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.cashOut != null && message.hasOwnProperty("cashOut"))
                if (!$util.isInteger(message.cashOut) && !(message.cashOut && $util.isInteger(message.cashOut.low) && $util.isInteger(message.cashOut.high)))
                    return "cashOut: integer|Long expected";
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                if (!$util.isInteger(message.betColor))
                    return "betColor: integer expected";
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo")) {
                var error = $root.base.PlayerBaseInfo.verify(message.baseInfo);
                if (error)
                    return "baseInfo." + error;
            }
            return null;
        };

        /**
         * Creates a WinPlayerInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.WinPlayerInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.WinPlayerInfo} WinPlayerInfo
         */
        WinPlayerInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.WinPlayerInfo)
                return object;
            var message = new $root.crash.WinPlayerInfo();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.cashOut != null)
                if ($util.Long)
                    (message.cashOut = $util.Long.fromValue(object.cashOut)).unsigned = false;
                else if (typeof object.cashOut === "string")
                    message.cashOut = parseInt(object.cashOut, 10);
                else if (typeof object.cashOut === "number")
                    message.cashOut = object.cashOut;
                else if (typeof object.cashOut === "object")
                    message.cashOut = new $util.LongBits(object.cashOut.low >>> 0, object.cashOut.high >>> 0).toNumber();
            if (object.betColor != null)
                message.betColor = object.betColor | 0;
            if (object.baseInfo != null) {
                if (typeof object.baseInfo !== "object")
                    throw TypeError(".crash.WinPlayerInfo.baseInfo: object expected");
                message.baseInfo = $root.base.PlayerBaseInfo.fromObject(object.baseInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from a WinPlayerInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.WinPlayerInfo
         * @static
         * @param {crash.WinPlayerInfo} message WinPlayerInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WinPlayerInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.cashOut = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.cashOut = options.longs === String ? "0" : 0;
                object.betColor = 0;
                object.baseInfo = null;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.cashOut != null && message.hasOwnProperty("cashOut"))
                if (typeof message.cashOut === "number")
                    object.cashOut = options.longs === String ? String(message.cashOut) : message.cashOut;
                else
                    object.cashOut = options.longs === String ? $util.Long.prototype.toString.call(message.cashOut) : options.longs === Number ? new $util.LongBits(message.cashOut.low >>> 0, message.cashOut.high >>> 0).toNumber() : message.cashOut;
            if (message.betColor != null && message.hasOwnProperty("betColor"))
                object.betColor = message.betColor;
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo"))
                object.baseInfo = $root.base.PlayerBaseInfo.toObject(message.baseInfo, options);
            return object;
        };

        /**
         * Converts this WinPlayerInfo to JSON.
         * @function toJSON
         * @memberof crash.WinPlayerInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WinPlayerInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WinPlayerInfo;
    })();

    crash.PlayerListEvent = (function() {

        /**
         * Properties of a PlayerListEvent.
         * @memberof crash
         * @interface IPlayerListEvent
         * @property {Array.<crash.IPlayerInfo>|null} [infos] PlayerListEvent infos
         */

        /**
         * Constructs a new PlayerListEvent.
         * @memberof crash
         * @classdesc Represents a PlayerListEvent.
         * @implements IPlayerListEvent
         * @constructor
         * @param {crash.IPlayerListEvent=} [properties] Properties to set
         */
        function PlayerListEvent(properties) {
            this.infos = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerListEvent infos.
         * @member {Array.<crash.IPlayerInfo>} infos
         * @memberof crash.PlayerListEvent
         * @instance
         */
        PlayerListEvent.prototype.infos = $util.emptyArray;

        /**
         * Creates a new PlayerListEvent instance using the specified properties.
         * @function create
         * @memberof crash.PlayerListEvent
         * @static
         * @param {crash.IPlayerListEvent=} [properties] Properties to set
         * @returns {crash.PlayerListEvent} PlayerListEvent instance
         */
        PlayerListEvent.create = function create(properties) {
            return new PlayerListEvent(properties);
        };

        /**
         * Encodes the specified PlayerListEvent message. Does not implicitly {@link crash.PlayerListEvent.verify|verify} messages.
         * @function encode
         * @memberof crash.PlayerListEvent
         * @static
         * @param {crash.IPlayerListEvent} message PlayerListEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerListEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.infos != null && message.infos.length)
                for (var i = 0; i < message.infos.length; ++i)
                    $root.crash.PlayerInfo.encode(message.infos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PlayerListEvent message, length delimited. Does not implicitly {@link crash.PlayerListEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.PlayerListEvent
         * @static
         * @param {crash.IPlayerListEvent} message PlayerListEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerListEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerListEvent message from the specified reader or buffer.
         * @function decode
         * @memberof crash.PlayerListEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.PlayerListEvent} PlayerListEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerListEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.PlayerListEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.infos && message.infos.length))
                        message.infos = [];
                    message.infos.push($root.crash.PlayerInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerListEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.PlayerListEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.PlayerListEvent} PlayerListEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerListEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerListEvent message.
         * @function verify
         * @memberof crash.PlayerListEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerListEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.infos != null && message.hasOwnProperty("infos")) {
                if (!Array.isArray(message.infos))
                    return "infos: array expected";
                for (var i = 0; i < message.infos.length; ++i) {
                    var error = $root.crash.PlayerInfo.verify(message.infos[i]);
                    if (error)
                        return "infos." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PlayerListEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.PlayerListEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.PlayerListEvent} PlayerListEvent
         */
        PlayerListEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.PlayerListEvent)
                return object;
            var message = new $root.crash.PlayerListEvent();
            if (object.infos) {
                if (!Array.isArray(object.infos))
                    throw TypeError(".crash.PlayerListEvent.infos: array expected");
                message.infos = [];
                for (var i = 0; i < object.infos.length; ++i) {
                    if (typeof object.infos[i] !== "object")
                        throw TypeError(".crash.PlayerListEvent.infos: object expected");
                    message.infos[i] = $root.crash.PlayerInfo.fromObject(object.infos[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PlayerListEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.PlayerListEvent
         * @static
         * @param {crash.PlayerListEvent} message PlayerListEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerListEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.infos = [];
            if (message.infos && message.infos.length) {
                object.infos = [];
                for (var j = 0; j < message.infos.length; ++j)
                    object.infos[j] = $root.crash.PlayerInfo.toObject(message.infos[j], options);
            }
            return object;
        };

        /**
         * Converts this PlayerListEvent to JSON.
         * @function toJSON
         * @memberof crash.PlayerListEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerListEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerListEvent;
    })();

    crash.PlayerInfo = (function() {

        /**
         * Properties of a PlayerInfo.
         * @memberof crash
         * @interface IPlayerInfo
         * @property {number|Long|null} [playerId] PlayerInfo playerId
         * @property {string|null} [nickname] PlayerInfo nickname
         * @property {string|null} [headUrl] PlayerInfo headUrl
         * @property {number|null} [level] PlayerInfo level
         * @property {number|null} [currencyType] PlayerInfo currencyType
         * @property {number|Long|null} [winAmount] PlayerInfo winAmount
         * @property {number|Long|null} [cashOut] PlayerInfo cashOut
         * @property {number|Long|null} [betAmount] PlayerInfo betAmount
         * @property {number|Long|null} [ballAmount] PlayerInfo ballAmount
         * @property {Array.<number>|null} [ballColor] PlayerInfo ballColor
         */

        /**
         * Constructs a new PlayerInfo.
         * @memberof crash
         * @classdesc Represents a PlayerInfo.
         * @implements IPlayerInfo
         * @constructor
         * @param {crash.IPlayerInfo=} [properties] Properties to set
         */
        function PlayerInfo(properties) {
            this.ballColor = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerInfo playerId.
         * @member {number|Long} playerId
         * @memberof crash.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.playerId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PlayerInfo nickname.
         * @member {string} nickname
         * @memberof crash.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.nickname = "";

        /**
         * PlayerInfo headUrl.
         * @member {string} headUrl
         * @memberof crash.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.headUrl = "";

        /**
         * PlayerInfo level.
         * @member {number} level
         * @memberof crash.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.level = 0;

        /**
         * PlayerInfo currencyType.
         * @member {number} currencyType
         * @memberof crash.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.currencyType = 0;

        /**
         * PlayerInfo winAmount.
         * @member {number|Long} winAmount
         * @memberof crash.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.winAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlayerInfo cashOut.
         * @member {number|Long} cashOut
         * @memberof crash.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.cashOut = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlayerInfo betAmount.
         * @member {number|Long} betAmount
         * @memberof crash.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlayerInfo ballAmount.
         * @member {number|Long} ballAmount
         * @memberof crash.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.ballAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PlayerInfo ballColor.
         * @member {Array.<number>} ballColor
         * @memberof crash.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.ballColor = $util.emptyArray;

        /**
         * Creates a new PlayerInfo instance using the specified properties.
         * @function create
         * @memberof crash.PlayerInfo
         * @static
         * @param {crash.IPlayerInfo=} [properties] Properties to set
         * @returns {crash.PlayerInfo} PlayerInfo instance
         */
        PlayerInfo.create = function create(properties) {
            return new PlayerInfo(properties);
        };

        /**
         * Encodes the specified PlayerInfo message. Does not implicitly {@link crash.PlayerInfo.verify|verify} messages.
         * @function encode
         * @memberof crash.PlayerInfo
         * @static
         * @param {crash.IPlayerInfo} message PlayerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.playerId != null && Object.hasOwnProperty.call(message, "playerId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.playerId);
            if (message.nickname != null && Object.hasOwnProperty.call(message, "nickname"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.nickname);
            if (message.headUrl != null && Object.hasOwnProperty.call(message, "headUrl"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.headUrl);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.level);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.currencyType);
            if (message.winAmount != null && Object.hasOwnProperty.call(message, "winAmount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.winAmount);
            if (message.cashOut != null && Object.hasOwnProperty.call(message, "cashOut"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.cashOut);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.betAmount);
            if (message.ballAmount != null && Object.hasOwnProperty.call(message, "ballAmount"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.ballAmount);
            if (message.ballColor != null && message.ballColor.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (var i = 0; i < message.ballColor.length; ++i)
                    writer.int32(message.ballColor[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified PlayerInfo message, length delimited. Does not implicitly {@link crash.PlayerInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.PlayerInfo
         * @static
         * @param {crash.IPlayerInfo} message PlayerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerInfo message from the specified reader or buffer.
         * @function decode
         * @memberof crash.PlayerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.PlayerInfo} PlayerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.PlayerInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.playerId = reader.uint64();
                    break;
                case 2:
                    message.nickname = reader.string();
                    break;
                case 3:
                    message.headUrl = reader.string();
                    break;
                case 4:
                    message.level = reader.int32();
                    break;
                case 5:
                    message.currencyType = reader.int32();
                    break;
                case 6:
                    message.winAmount = reader.int64();
                    break;
                case 7:
                    message.cashOut = reader.int64();
                    break;
                case 8:
                    message.betAmount = reader.int64();
                    break;
                case 9:
                    message.ballAmount = reader.int64();
                    break;
                case 10:
                    if (!(message.ballColor && message.ballColor.length))
                        message.ballColor = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.ballColor.push(reader.int32());
                    } else
                        message.ballColor.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.PlayerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.PlayerInfo} PlayerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerInfo message.
         * @function verify
         * @memberof crash.PlayerInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.playerId != null && message.hasOwnProperty("playerId"))
                if (!$util.isInteger(message.playerId) && !(message.playerId && $util.isInteger(message.playerId.low) && $util.isInteger(message.playerId.high)))
                    return "playerId: integer|Long expected";
            if (message.nickname != null && message.hasOwnProperty("nickname"))
                if (!$util.isString(message.nickname))
                    return "nickname: string expected";
            if (message.headUrl != null && message.hasOwnProperty("headUrl"))
                if (!$util.isString(message.headUrl))
                    return "headUrl: string expected";
            if (message.level != null && message.hasOwnProperty("level"))
                if (!$util.isInteger(message.level))
                    return "level: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (!$util.isInteger(message.winAmount) && !(message.winAmount && $util.isInteger(message.winAmount.low) && $util.isInteger(message.winAmount.high)))
                    return "winAmount: integer|Long expected";
            if (message.cashOut != null && message.hasOwnProperty("cashOut"))
                if (!$util.isInteger(message.cashOut) && !(message.cashOut && $util.isInteger(message.cashOut.low) && $util.isInteger(message.cashOut.high)))
                    return "cashOut: integer|Long expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.ballAmount != null && message.hasOwnProperty("ballAmount"))
                if (!$util.isInteger(message.ballAmount) && !(message.ballAmount && $util.isInteger(message.ballAmount.low) && $util.isInteger(message.ballAmount.high)))
                    return "ballAmount: integer|Long expected";
            if (message.ballColor != null && message.hasOwnProperty("ballColor")) {
                if (!Array.isArray(message.ballColor))
                    return "ballColor: array expected";
                for (var i = 0; i < message.ballColor.length; ++i)
                    if (!$util.isInteger(message.ballColor[i]))
                        return "ballColor: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a PlayerInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.PlayerInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.PlayerInfo} PlayerInfo
         */
        PlayerInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.PlayerInfo)
                return object;
            var message = new $root.crash.PlayerInfo();
            if (object.playerId != null)
                if ($util.Long)
                    (message.playerId = $util.Long.fromValue(object.playerId)).unsigned = true;
                else if (typeof object.playerId === "string")
                    message.playerId = parseInt(object.playerId, 10);
                else if (typeof object.playerId === "number")
                    message.playerId = object.playerId;
                else if (typeof object.playerId === "object")
                    message.playerId = new $util.LongBits(object.playerId.low >>> 0, object.playerId.high >>> 0).toNumber(true);
            if (object.nickname != null)
                message.nickname = String(object.nickname);
            if (object.headUrl != null)
                message.headUrl = String(object.headUrl);
            if (object.level != null)
                message.level = object.level | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.winAmount != null)
                if ($util.Long)
                    (message.winAmount = $util.Long.fromValue(object.winAmount)).unsigned = false;
                else if (typeof object.winAmount === "string")
                    message.winAmount = parseInt(object.winAmount, 10);
                else if (typeof object.winAmount === "number")
                    message.winAmount = object.winAmount;
                else if (typeof object.winAmount === "object")
                    message.winAmount = new $util.LongBits(object.winAmount.low >>> 0, object.winAmount.high >>> 0).toNumber();
            if (object.cashOut != null)
                if ($util.Long)
                    (message.cashOut = $util.Long.fromValue(object.cashOut)).unsigned = false;
                else if (typeof object.cashOut === "string")
                    message.cashOut = parseInt(object.cashOut, 10);
                else if (typeof object.cashOut === "number")
                    message.cashOut = object.cashOut;
                else if (typeof object.cashOut === "object")
                    message.cashOut = new $util.LongBits(object.cashOut.low >>> 0, object.cashOut.high >>> 0).toNumber();
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.ballAmount != null)
                if ($util.Long)
                    (message.ballAmount = $util.Long.fromValue(object.ballAmount)).unsigned = false;
                else if (typeof object.ballAmount === "string")
                    message.ballAmount = parseInt(object.ballAmount, 10);
                else if (typeof object.ballAmount === "number")
                    message.ballAmount = object.ballAmount;
                else if (typeof object.ballAmount === "object")
                    message.ballAmount = new $util.LongBits(object.ballAmount.low >>> 0, object.ballAmount.high >>> 0).toNumber();
            if (object.ballColor) {
                if (!Array.isArray(object.ballColor))
                    throw TypeError(".crash.PlayerInfo.ballColor: array expected");
                message.ballColor = [];
                for (var i = 0; i < object.ballColor.length; ++i)
                    message.ballColor[i] = object.ballColor[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a PlayerInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.PlayerInfo
         * @static
         * @param {crash.PlayerInfo} message PlayerInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.ballColor = [];
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.playerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.playerId = options.longs === String ? "0" : 0;
                object.nickname = "";
                object.headUrl = "";
                object.level = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.winAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.winAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.cashOut = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.cashOut = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.ballAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ballAmount = options.longs === String ? "0" : 0;
            }
            if (message.playerId != null && message.hasOwnProperty("playerId"))
                if (typeof message.playerId === "number")
                    object.playerId = options.longs === String ? String(message.playerId) : message.playerId;
                else
                    object.playerId = options.longs === String ? $util.Long.prototype.toString.call(message.playerId) : options.longs === Number ? new $util.LongBits(message.playerId.low >>> 0, message.playerId.high >>> 0).toNumber(true) : message.playerId;
            if (message.nickname != null && message.hasOwnProperty("nickname"))
                object.nickname = message.nickname;
            if (message.headUrl != null && message.hasOwnProperty("headUrl"))
                object.headUrl = message.headUrl;
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = message.level;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.winAmount != null && message.hasOwnProperty("winAmount"))
                if (typeof message.winAmount === "number")
                    object.winAmount = options.longs === String ? String(message.winAmount) : message.winAmount;
                else
                    object.winAmount = options.longs === String ? $util.Long.prototype.toString.call(message.winAmount) : options.longs === Number ? new $util.LongBits(message.winAmount.low >>> 0, message.winAmount.high >>> 0).toNumber() : message.winAmount;
            if (message.cashOut != null && message.hasOwnProperty("cashOut"))
                if (typeof message.cashOut === "number")
                    object.cashOut = options.longs === String ? String(message.cashOut) : message.cashOut;
                else
                    object.cashOut = options.longs === String ? $util.Long.prototype.toString.call(message.cashOut) : options.longs === Number ? new $util.LongBits(message.cashOut.low >>> 0, message.cashOut.high >>> 0).toNumber() : message.cashOut;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.ballAmount != null && message.hasOwnProperty("ballAmount"))
                if (typeof message.ballAmount === "number")
                    object.ballAmount = options.longs === String ? String(message.ballAmount) : message.ballAmount;
                else
                    object.ballAmount = options.longs === String ? $util.Long.prototype.toString.call(message.ballAmount) : options.longs === Number ? new $util.LongBits(message.ballAmount.low >>> 0, message.ballAmount.high >>> 0).toNumber() : message.ballAmount;
            if (message.ballColor && message.ballColor.length) {
                object.ballColor = [];
                for (var j = 0; j < message.ballColor.length; ++j)
                    object.ballColor[j] = message.ballColor[j];
            }
            return object;
        };

        /**
         * Converts this PlayerInfo to JSON.
         * @function toJSON
         * @memberof crash.PlayerInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerInfo;
    })();

    crash.GameLoseEvent = (function() {

        /**
         * Properties of a GameLoseEvent.
         * @memberof crash
         * @interface IGameLoseEvent
         * @property {number|null} [currencyType] GameLoseEvent currencyType
         * @property {number|Long|null} [betAmount] GameLoseEvent betAmount
         * @property {number|Long|null} [ballAmount] GameLoseEvent ballAmount
         * @property {Array.<number>|null} [ballColor] GameLoseEvent ballColor
         * @property {number|null} [result] GameLoseEvent result
         */

        /**
         * Constructs a new GameLoseEvent.
         * @memberof crash
         * @classdesc Represents a GameLoseEvent.
         * @implements IGameLoseEvent
         * @constructor
         * @param {crash.IGameLoseEvent=} [properties] Properties to set
         */
        function GameLoseEvent(properties) {
            this.ballColor = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameLoseEvent currencyType.
         * @member {number} currencyType
         * @memberof crash.GameLoseEvent
         * @instance
         */
        GameLoseEvent.prototype.currencyType = 0;

        /**
         * GameLoseEvent betAmount.
         * @member {number|Long} betAmount
         * @memberof crash.GameLoseEvent
         * @instance
         */
        GameLoseEvent.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GameLoseEvent ballAmount.
         * @member {number|Long} ballAmount
         * @memberof crash.GameLoseEvent
         * @instance
         */
        GameLoseEvent.prototype.ballAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GameLoseEvent ballColor.
         * @member {Array.<number>} ballColor
         * @memberof crash.GameLoseEvent
         * @instance
         */
        GameLoseEvent.prototype.ballColor = $util.emptyArray;

        /**
         * GameLoseEvent result.
         * @member {number} result
         * @memberof crash.GameLoseEvent
         * @instance
         */
        GameLoseEvent.prototype.result = 0;

        /**
         * Creates a new GameLoseEvent instance using the specified properties.
         * @function create
         * @memberof crash.GameLoseEvent
         * @static
         * @param {crash.IGameLoseEvent=} [properties] Properties to set
         * @returns {crash.GameLoseEvent} GameLoseEvent instance
         */
        GameLoseEvent.create = function create(properties) {
            return new GameLoseEvent(properties);
        };

        /**
         * Encodes the specified GameLoseEvent message. Does not implicitly {@link crash.GameLoseEvent.verify|verify} messages.
         * @function encode
         * @memberof crash.GameLoseEvent
         * @static
         * @param {crash.IGameLoseEvent} message GameLoseEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameLoseEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            if (message.ballAmount != null && Object.hasOwnProperty.call(message, "ballAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.ballAmount);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.result);
            if (message.ballColor != null && message.ballColor.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (var i = 0; i < message.ballColor.length; ++i)
                    writer.int32(message.ballColor[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified GameLoseEvent message, length delimited. Does not implicitly {@link crash.GameLoseEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.GameLoseEvent
         * @static
         * @param {crash.IGameLoseEvent} message GameLoseEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameLoseEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameLoseEvent message from the specified reader or buffer.
         * @function decode
         * @memberof crash.GameLoseEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.GameLoseEvent} GameLoseEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameLoseEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.GameLoseEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                case 3:
                    message.ballAmount = reader.int64();
                    break;
                case 10:
                    if (!(message.ballColor && message.ballColor.length))
                        message.ballColor = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.ballColor.push(reader.int32());
                    } else
                        message.ballColor.push(reader.int32());
                    break;
                case 5:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameLoseEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.GameLoseEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.GameLoseEvent} GameLoseEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameLoseEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameLoseEvent message.
         * @function verify
         * @memberof crash.GameLoseEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameLoseEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.ballAmount != null && message.hasOwnProperty("ballAmount"))
                if (!$util.isInteger(message.ballAmount) && !(message.ballAmount && $util.isInteger(message.ballAmount.low) && $util.isInteger(message.ballAmount.high)))
                    return "ballAmount: integer|Long expected";
            if (message.ballColor != null && message.hasOwnProperty("ballColor")) {
                if (!Array.isArray(message.ballColor))
                    return "ballColor: array expected";
                for (var i = 0; i < message.ballColor.length; ++i)
                    if (!$util.isInteger(message.ballColor[i]))
                        return "ballColor: integer[] expected";
            }
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a GameLoseEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.GameLoseEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.GameLoseEvent} GameLoseEvent
         */
        GameLoseEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.GameLoseEvent)
                return object;
            var message = new $root.crash.GameLoseEvent();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.ballAmount != null)
                if ($util.Long)
                    (message.ballAmount = $util.Long.fromValue(object.ballAmount)).unsigned = false;
                else if (typeof object.ballAmount === "string")
                    message.ballAmount = parseInt(object.ballAmount, 10);
                else if (typeof object.ballAmount === "number")
                    message.ballAmount = object.ballAmount;
                else if (typeof object.ballAmount === "object")
                    message.ballAmount = new $util.LongBits(object.ballAmount.low >>> 0, object.ballAmount.high >>> 0).toNumber();
            if (object.ballColor) {
                if (!Array.isArray(object.ballColor))
                    throw TypeError(".crash.GameLoseEvent.ballColor: array expected");
                message.ballColor = [];
                for (var i = 0; i < object.ballColor.length; ++i)
                    message.ballColor[i] = object.ballColor[i] | 0;
            }
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a GameLoseEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.GameLoseEvent
         * @static
         * @param {crash.GameLoseEvent} message GameLoseEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameLoseEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.ballColor = [];
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.ballAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ballAmount = options.longs === String ? "0" : 0;
                object.result = 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.ballAmount != null && message.hasOwnProperty("ballAmount"))
                if (typeof message.ballAmount === "number")
                    object.ballAmount = options.longs === String ? String(message.ballAmount) : message.ballAmount;
                else
                    object.ballAmount = options.longs === String ? $util.Long.prototype.toString.call(message.ballAmount) : options.longs === Number ? new $util.LongBits(message.ballAmount.low >>> 0, message.ballAmount.high >>> 0).toNumber() : message.ballAmount;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.ballColor && message.ballColor.length) {
                object.ballColor = [];
                for (var j = 0; j < message.ballColor.length; ++j)
                    object.ballColor[j] = message.ballColor[j];
            }
            return object;
        };

        /**
         * Converts this GameLoseEvent to JSON.
         * @function toJSON
         * @memberof crash.GameLoseEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameLoseEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameLoseEvent;
    })();

    crash.FlyTickEvent = (function() {

        /**
         * Properties of a FlyTickEvent.
         * @memberof crash
         * @interface IFlyTickEvent
         * @property {number|null} [tick] FlyTickEvent tick
         * @property {boolean|null} [end] FlyTickEvent end
         * @property {number|null} [lastTick] FlyTickEvent lastTick
         * @property {string|null} [nonce] FlyTickEvent nonce
         */

        /**
         * Constructs a new FlyTickEvent.
         * @memberof crash
         * @classdesc Represents a FlyTickEvent.
         * @implements IFlyTickEvent
         * @constructor
         * @param {crash.IFlyTickEvent=} [properties] Properties to set
         */
        function FlyTickEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FlyTickEvent tick.
         * @member {number} tick
         * @memberof crash.FlyTickEvent
         * @instance
         */
        FlyTickEvent.prototype.tick = 0;

        /**
         * FlyTickEvent end.
         * @member {boolean} end
         * @memberof crash.FlyTickEvent
         * @instance
         */
        FlyTickEvent.prototype.end = false;

        /**
         * FlyTickEvent lastTick.
         * @member {number} lastTick
         * @memberof crash.FlyTickEvent
         * @instance
         */
        FlyTickEvent.prototype.lastTick = 0;

        /**
         * FlyTickEvent nonce.
         * @member {string} nonce
         * @memberof crash.FlyTickEvent
         * @instance
         */
        FlyTickEvent.prototype.nonce = "";

        /**
         * Creates a new FlyTickEvent instance using the specified properties.
         * @function create
         * @memberof crash.FlyTickEvent
         * @static
         * @param {crash.IFlyTickEvent=} [properties] Properties to set
         * @returns {crash.FlyTickEvent} FlyTickEvent instance
         */
        FlyTickEvent.create = function create(properties) {
            return new FlyTickEvent(properties);
        };

        /**
         * Encodes the specified FlyTickEvent message. Does not implicitly {@link crash.FlyTickEvent.verify|verify} messages.
         * @function encode
         * @memberof crash.FlyTickEvent
         * @static
         * @param {crash.IFlyTickEvent} message FlyTickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FlyTickEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tick != null && Object.hasOwnProperty.call(message, "tick"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tick);
            if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.end);
            if (message.lastTick != null && Object.hasOwnProperty.call(message, "lastTick"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.lastTick);
            if (message.nonce != null && Object.hasOwnProperty.call(message, "nonce"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.nonce);
            return writer;
        };

        /**
         * Encodes the specified FlyTickEvent message, length delimited. Does not implicitly {@link crash.FlyTickEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof crash.FlyTickEvent
         * @static
         * @param {crash.IFlyTickEvent} message FlyTickEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FlyTickEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FlyTickEvent message from the specified reader or buffer.
         * @function decode
         * @memberof crash.FlyTickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {crash.FlyTickEvent} FlyTickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FlyTickEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.crash.FlyTickEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.tick = reader.int32();
                    break;
                case 2:
                    message.end = reader.bool();
                    break;
                case 3:
                    message.lastTick = reader.int32();
                    break;
                case 4:
                    message.nonce = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FlyTickEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof crash.FlyTickEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {crash.FlyTickEvent} FlyTickEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FlyTickEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FlyTickEvent message.
         * @function verify
         * @memberof crash.FlyTickEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FlyTickEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tick != null && message.hasOwnProperty("tick"))
                if (!$util.isInteger(message.tick))
                    return "tick: integer expected";
            if (message.end != null && message.hasOwnProperty("end"))
                if (typeof message.end !== "boolean")
                    return "end: boolean expected";
            if (message.lastTick != null && message.hasOwnProperty("lastTick"))
                if (!$util.isInteger(message.lastTick))
                    return "lastTick: integer expected";
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                if (!$util.isString(message.nonce))
                    return "nonce: string expected";
            return null;
        };

        /**
         * Creates a FlyTickEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof crash.FlyTickEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {crash.FlyTickEvent} FlyTickEvent
         */
        FlyTickEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.crash.FlyTickEvent)
                return object;
            var message = new $root.crash.FlyTickEvent();
            if (object.tick != null)
                message.tick = object.tick | 0;
            if (object.end != null)
                message.end = Boolean(object.end);
            if (object.lastTick != null)
                message.lastTick = object.lastTick | 0;
            if (object.nonce != null)
                message.nonce = String(object.nonce);
            return message;
        };

        /**
         * Creates a plain object from a FlyTickEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof crash.FlyTickEvent
         * @static
         * @param {crash.FlyTickEvent} message FlyTickEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FlyTickEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.tick = 0;
                object.end = false;
                object.lastTick = 0;
                object.nonce = "";
            }
            if (message.tick != null && message.hasOwnProperty("tick"))
                object.tick = message.tick;
            if (message.end != null && message.hasOwnProperty("end"))
                object.end = message.end;
            if (message.lastTick != null && message.hasOwnProperty("lastTick"))
                object.lastTick = message.lastTick;
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                object.nonce = message.nonce;
            return object;
        };

        /**
         * Converts this FlyTickEvent to JSON.
         * @function toJSON
         * @memberof crash.FlyTickEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FlyTickEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FlyTickEvent;
    })();

    return crash;
})();

$root.dragontiger = (function() {

    /**
     * Namespace dragontiger.
     * @exports dragontiger
     * @namespace
     */
    var dragontiger = {};

    /**
     * DEADLINE_TYPE enum.
     * @name dragontiger.DEADLINE_TYPE
     * @enum {number}
     * @property {number} DT_NONE=0 DT_NONE value
     * @property {number} START_BET=1 START_BET value
     * @property {number} STOP_BET=2 STOP_BET value
     * @property {number} COLLECT_BET=3 COLLECT_BET value
     */
    dragontiger.DEADLINE_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DT_NONE"] = 0;
        values[valuesById[1] = "START_BET"] = 1;
        values[valuesById[2] = "STOP_BET"] = 2;
        values[valuesById[3] = "COLLECT_BET"] = 3;
        return values;
    })();

    dragontiger.GameStartEvent = (function() {

        /**
         * Properties of a GameStartEvent.
         * @memberof dragontiger
         * @interface IGameStartEvent
         * @property {string|null} [gameCode] GameStartEvent gameCode
         * @property {string|null} [hashRound] GameStartEvent hashRound
         * @property {string|null} [params] GameStartEvent params
         */

        /**
         * Constructs a new GameStartEvent.
         * @memberof dragontiger
         * @classdesc Represents a GameStartEvent.
         * @implements IGameStartEvent
         * @constructor
         * @param {dragontiger.IGameStartEvent=} [properties] Properties to set
         */
        function GameStartEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameStartEvent gameCode.
         * @member {string} gameCode
         * @memberof dragontiger.GameStartEvent
         * @instance
         */
        GameStartEvent.prototype.gameCode = "";

        /**
         * GameStartEvent hashRound.
         * @member {string} hashRound
         * @memberof dragontiger.GameStartEvent
         * @instance
         */
        GameStartEvent.prototype.hashRound = "";

        /**
         * GameStartEvent params.
         * @member {string} params
         * @memberof dragontiger.GameStartEvent
         * @instance
         */
        GameStartEvent.prototype.params = "";

        /**
         * Creates a new GameStartEvent instance using the specified properties.
         * @function create
         * @memberof dragontiger.GameStartEvent
         * @static
         * @param {dragontiger.IGameStartEvent=} [properties] Properties to set
         * @returns {dragontiger.GameStartEvent} GameStartEvent instance
         */
        GameStartEvent.create = function create(properties) {
            return new GameStartEvent(properties);
        };

        /**
         * Encodes the specified GameStartEvent message. Does not implicitly {@link dragontiger.GameStartEvent.verify|verify} messages.
         * @function encode
         * @memberof dragontiger.GameStartEvent
         * @static
         * @param {dragontiger.IGameStartEvent} message GameStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameStartEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.hashRound != null && Object.hasOwnProperty.call(message, "hashRound"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.hashRound);
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.params);
            return writer;
        };

        /**
         * Encodes the specified GameStartEvent message, length delimited. Does not implicitly {@link dragontiger.GameStartEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dragontiger.GameStartEvent
         * @static
         * @param {dragontiger.IGameStartEvent} message GameStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameStartEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameStartEvent message from the specified reader or buffer.
         * @function decode
         * @memberof dragontiger.GameStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dragontiger.GameStartEvent} GameStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameStartEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.dragontiger.GameStartEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.hashRound = reader.string();
                    break;
                case 3:
                    message.params = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameStartEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dragontiger.GameStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dragontiger.GameStartEvent} GameStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameStartEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameStartEvent message.
         * @function verify
         * @memberof dragontiger.GameStartEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameStartEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                if (!$util.isString(message.hashRound))
                    return "hashRound: string expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            return null;
        };

        /**
         * Creates a GameStartEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dragontiger.GameStartEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dragontiger.GameStartEvent} GameStartEvent
         */
        GameStartEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.dragontiger.GameStartEvent)
                return object;
            var message = new $root.dragontiger.GameStartEvent();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.hashRound != null)
                message.hashRound = String(object.hashRound);
            if (object.params != null)
                message.params = String(object.params);
            return message;
        };

        /**
         * Creates a plain object from a GameStartEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dragontiger.GameStartEvent
         * @static
         * @param {dragontiger.GameStartEvent} message GameStartEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameStartEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.hashRound = "";
                object.params = "";
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                object.hashRound = message.hashRound;
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            return object;
        };

        /**
         * Converts this GameStartEvent to JSON.
         * @function toJSON
         * @memberof dragontiger.GameStartEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameStartEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameStartEvent;
    })();

    dragontiger.DragontigerPlayersEvent = (function() {

        /**
         * Properties of a DragontigerPlayersEvent.
         * @memberof dragontiger
         * @interface IDragontigerPlayersEvent
         * @property {Array.<dragontiger.IDragontigerPlayer>|null} [dragontigerPlayers] DragontigerPlayersEvent dragontigerPlayers
         * @property {number|null} [playersNum] DragontigerPlayersEvent playersNum
         */

        /**
         * Constructs a new DragontigerPlayersEvent.
         * @memberof dragontiger
         * @classdesc Represents a DragontigerPlayersEvent.
         * @implements IDragontigerPlayersEvent
         * @constructor
         * @param {dragontiger.IDragontigerPlayersEvent=} [properties] Properties to set
         */
        function DragontigerPlayersEvent(properties) {
            this.dragontigerPlayers = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DragontigerPlayersEvent dragontigerPlayers.
         * @member {Array.<dragontiger.IDragontigerPlayer>} dragontigerPlayers
         * @memberof dragontiger.DragontigerPlayersEvent
         * @instance
         */
        DragontigerPlayersEvent.prototype.dragontigerPlayers = $util.emptyArray;

        /**
         * DragontigerPlayersEvent playersNum.
         * @member {number} playersNum
         * @memberof dragontiger.DragontigerPlayersEvent
         * @instance
         */
        DragontigerPlayersEvent.prototype.playersNum = 0;

        /**
         * Creates a new DragontigerPlayersEvent instance using the specified properties.
         * @function create
         * @memberof dragontiger.DragontigerPlayersEvent
         * @static
         * @param {dragontiger.IDragontigerPlayersEvent=} [properties] Properties to set
         * @returns {dragontiger.DragontigerPlayersEvent} DragontigerPlayersEvent instance
         */
        DragontigerPlayersEvent.create = function create(properties) {
            return new DragontigerPlayersEvent(properties);
        };

        /**
         * Encodes the specified DragontigerPlayersEvent message. Does not implicitly {@link dragontiger.DragontigerPlayersEvent.verify|verify} messages.
         * @function encode
         * @memberof dragontiger.DragontigerPlayersEvent
         * @static
         * @param {dragontiger.IDragontigerPlayersEvent} message DragontigerPlayersEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DragontigerPlayersEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dragontigerPlayers != null && message.dragontigerPlayers.length)
                for (var i = 0; i < message.dragontigerPlayers.length; ++i)
                    $root.dragontiger.DragontigerPlayer.encode(message.dragontigerPlayers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.playersNum != null && Object.hasOwnProperty.call(message, "playersNum"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.playersNum);
            return writer;
        };

        /**
         * Encodes the specified DragontigerPlayersEvent message, length delimited. Does not implicitly {@link dragontiger.DragontigerPlayersEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dragontiger.DragontigerPlayersEvent
         * @static
         * @param {dragontiger.IDragontigerPlayersEvent} message DragontigerPlayersEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DragontigerPlayersEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DragontigerPlayersEvent message from the specified reader or buffer.
         * @function decode
         * @memberof dragontiger.DragontigerPlayersEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dragontiger.DragontigerPlayersEvent} DragontigerPlayersEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DragontigerPlayersEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.dragontiger.DragontigerPlayersEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.dragontigerPlayers && message.dragontigerPlayers.length))
                        message.dragontigerPlayers = [];
                    message.dragontigerPlayers.push($root.dragontiger.DragontigerPlayer.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.playersNum = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DragontigerPlayersEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dragontiger.DragontigerPlayersEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dragontiger.DragontigerPlayersEvent} DragontigerPlayersEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DragontigerPlayersEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DragontigerPlayersEvent message.
         * @function verify
         * @memberof dragontiger.DragontigerPlayersEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DragontigerPlayersEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dragontigerPlayers != null && message.hasOwnProperty("dragontigerPlayers")) {
                if (!Array.isArray(message.dragontigerPlayers))
                    return "dragontigerPlayers: array expected";
                for (var i = 0; i < message.dragontigerPlayers.length; ++i) {
                    var error = $root.dragontiger.DragontigerPlayer.verify(message.dragontigerPlayers[i]);
                    if (error)
                        return "dragontigerPlayers." + error;
                }
            }
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                if (!$util.isInteger(message.playersNum))
                    return "playersNum: integer expected";
            return null;
        };

        /**
         * Creates a DragontigerPlayersEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dragontiger.DragontigerPlayersEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dragontiger.DragontigerPlayersEvent} DragontigerPlayersEvent
         */
        DragontigerPlayersEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.dragontiger.DragontigerPlayersEvent)
                return object;
            var message = new $root.dragontiger.DragontigerPlayersEvent();
            if (object.dragontigerPlayers) {
                if (!Array.isArray(object.dragontigerPlayers))
                    throw TypeError(".dragontiger.DragontigerPlayersEvent.dragontigerPlayers: array expected");
                message.dragontigerPlayers = [];
                for (var i = 0; i < object.dragontigerPlayers.length; ++i) {
                    if (typeof object.dragontigerPlayers[i] !== "object")
                        throw TypeError(".dragontiger.DragontigerPlayersEvent.dragontigerPlayers: object expected");
                    message.dragontigerPlayers[i] = $root.dragontiger.DragontigerPlayer.fromObject(object.dragontigerPlayers[i]);
                }
            }
            if (object.playersNum != null)
                message.playersNum = object.playersNum | 0;
            return message;
        };

        /**
         * Creates a plain object from a DragontigerPlayersEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dragontiger.DragontigerPlayersEvent
         * @static
         * @param {dragontiger.DragontigerPlayersEvent} message DragontigerPlayersEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DragontigerPlayersEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.dragontigerPlayers = [];
            if (options.defaults)
                object.playersNum = 0;
            if (message.dragontigerPlayers && message.dragontigerPlayers.length) {
                object.dragontigerPlayers = [];
                for (var j = 0; j < message.dragontigerPlayers.length; ++j)
                    object.dragontigerPlayers[j] = $root.dragontiger.DragontigerPlayer.toObject(message.dragontigerPlayers[j], options);
            }
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                object.playersNum = message.playersNum;
            return object;
        };

        /**
         * Converts this DragontigerPlayersEvent to JSON.
         * @function toJSON
         * @memberof dragontiger.DragontigerPlayersEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DragontigerPlayersEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DragontigerPlayersEvent;
    })();

    dragontiger.DragontigerPlayer = (function() {

        /**
         * Properties of a DragontigerPlayer.
         * @memberof dragontiger
         * @interface IDragontigerPlayer
         * @property {number|null} [seat] DragontigerPlayer seat
         * @property {base.IPlayerBaseInfo|null} [baseInfo] DragontigerPlayer baseInfo
         * @property {boolean|null} [online] DragontigerPlayer online
         * @property {number|Long|null} [balance] DragontigerPlayer balance
         * @property {Array.<string>|null} [betList] DragontigerPlayer betList
         */

        /**
         * Constructs a new DragontigerPlayer.
         * @memberof dragontiger
         * @classdesc Represents a DragontigerPlayer.
         * @implements IDragontigerPlayer
         * @constructor
         * @param {dragontiger.IDragontigerPlayer=} [properties] Properties to set
         */
        function DragontigerPlayer(properties) {
            this.betList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DragontigerPlayer seat.
         * @member {number} seat
         * @memberof dragontiger.DragontigerPlayer
         * @instance
         */
        DragontigerPlayer.prototype.seat = 0;

        /**
         * DragontigerPlayer baseInfo.
         * @member {base.IPlayerBaseInfo|null|undefined} baseInfo
         * @memberof dragontiger.DragontigerPlayer
         * @instance
         */
        DragontigerPlayer.prototype.baseInfo = null;

        /**
         * DragontigerPlayer online.
         * @member {boolean} online
         * @memberof dragontiger.DragontigerPlayer
         * @instance
         */
        DragontigerPlayer.prototype.online = false;

        /**
         * DragontigerPlayer balance.
         * @member {number|Long} balance
         * @memberof dragontiger.DragontigerPlayer
         * @instance
         */
        DragontigerPlayer.prototype.balance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DragontigerPlayer betList.
         * @member {Array.<string>} betList
         * @memberof dragontiger.DragontigerPlayer
         * @instance
         */
        DragontigerPlayer.prototype.betList = $util.emptyArray;

        /**
         * Creates a new DragontigerPlayer instance using the specified properties.
         * @function create
         * @memberof dragontiger.DragontigerPlayer
         * @static
         * @param {dragontiger.IDragontigerPlayer=} [properties] Properties to set
         * @returns {dragontiger.DragontigerPlayer} DragontigerPlayer instance
         */
        DragontigerPlayer.create = function create(properties) {
            return new DragontigerPlayer(properties);
        };

        /**
         * Encodes the specified DragontigerPlayer message. Does not implicitly {@link dragontiger.DragontigerPlayer.verify|verify} messages.
         * @function encode
         * @memberof dragontiger.DragontigerPlayer
         * @static
         * @param {dragontiger.IDragontigerPlayer} message DragontigerPlayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DragontigerPlayer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            if (message.baseInfo != null && Object.hasOwnProperty.call(message, "baseInfo"))
                $root.base.PlayerBaseInfo.encode(message.baseInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.online != null && Object.hasOwnProperty.call(message, "online"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.online);
            if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.balance);
            if (message.betList != null && message.betList.length)
                for (var i = 0; i < message.betList.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.betList[i]);
            return writer;
        };

        /**
         * Encodes the specified DragontigerPlayer message, length delimited. Does not implicitly {@link dragontiger.DragontigerPlayer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dragontiger.DragontigerPlayer
         * @static
         * @param {dragontiger.IDragontigerPlayer} message DragontigerPlayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DragontigerPlayer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DragontigerPlayer message from the specified reader or buffer.
         * @function decode
         * @memberof dragontiger.DragontigerPlayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dragontiger.DragontigerPlayer} DragontigerPlayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DragontigerPlayer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.dragontiger.DragontigerPlayer();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                case 2:
                    message.baseInfo = $root.base.PlayerBaseInfo.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.online = reader.bool();
                    break;
                case 4:
                    message.balance = reader.int64();
                    break;
                case 5:
                    if (!(message.betList && message.betList.length))
                        message.betList = [];
                    message.betList.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DragontigerPlayer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dragontiger.DragontigerPlayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dragontiger.DragontigerPlayer} DragontigerPlayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DragontigerPlayer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DragontigerPlayer message.
         * @function verify
         * @memberof dragontiger.DragontigerPlayer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DragontigerPlayer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo")) {
                var error = $root.base.PlayerBaseInfo.verify(message.baseInfo);
                if (error)
                    return "baseInfo." + error;
            }
            if (message.online != null && message.hasOwnProperty("online"))
                if (typeof message.online !== "boolean")
                    return "online: boolean expected";
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (!$util.isInteger(message.balance) && !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high)))
                    return "balance: integer|Long expected";
            if (message.betList != null && message.hasOwnProperty("betList")) {
                if (!Array.isArray(message.betList))
                    return "betList: array expected";
                for (var i = 0; i < message.betList.length; ++i)
                    if (!$util.isString(message.betList[i]))
                        return "betList: string[] expected";
            }
            return null;
        };

        /**
         * Creates a DragontigerPlayer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dragontiger.DragontigerPlayer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dragontiger.DragontigerPlayer} DragontigerPlayer
         */
        DragontigerPlayer.fromObject = function fromObject(object) {
            if (object instanceof $root.dragontiger.DragontigerPlayer)
                return object;
            var message = new $root.dragontiger.DragontigerPlayer();
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.baseInfo != null) {
                if (typeof object.baseInfo !== "object")
                    throw TypeError(".dragontiger.DragontigerPlayer.baseInfo: object expected");
                message.baseInfo = $root.base.PlayerBaseInfo.fromObject(object.baseInfo);
            }
            if (object.online != null)
                message.online = Boolean(object.online);
            if (object.balance != null)
                if ($util.Long)
                    (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
                else if (typeof object.balance === "string")
                    message.balance = parseInt(object.balance, 10);
                else if (typeof object.balance === "number")
                    message.balance = object.balance;
                else if (typeof object.balance === "object")
                    message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
            if (object.betList) {
                if (!Array.isArray(object.betList))
                    throw TypeError(".dragontiger.DragontigerPlayer.betList: array expected");
                message.betList = [];
                for (var i = 0; i < object.betList.length; ++i)
                    message.betList[i] = String(object.betList[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a DragontigerPlayer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dragontiger.DragontigerPlayer
         * @static
         * @param {dragontiger.DragontigerPlayer} message DragontigerPlayer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DragontigerPlayer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.betList = [];
            if (options.defaults) {
                object.seat = 0;
                object.baseInfo = null;
                object.online = false;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.balance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.balance = options.longs === String ? "0" : 0;
            }
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo"))
                object.baseInfo = $root.base.PlayerBaseInfo.toObject(message.baseInfo, options);
            if (message.online != null && message.hasOwnProperty("online"))
                object.online = message.online;
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (typeof message.balance === "number")
                    object.balance = options.longs === String ? String(message.balance) : message.balance;
                else
                    object.balance = options.longs === String ? $util.Long.prototype.toString.call(message.balance) : options.longs === Number ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber() : message.balance;
            if (message.betList && message.betList.length) {
                object.betList = [];
                for (var j = 0; j < message.betList.length; ++j)
                    object.betList[j] = message.betList[j];
            }
            return object;
        };

        /**
         * Converts this DragontigerPlayer to JSON.
         * @function toJSON
         * @memberof dragontiger.DragontigerPlayer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DragontigerPlayer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DragontigerPlayer;
    })();

    dragontiger.TableStateEvent = (function() {

        /**
         * Properties of a TableStateEvent.
         * @memberof dragontiger
         * @interface ITableStateEvent
         * @property {string|null} [gameCode] TableStateEvent gameCode
         * @property {number|null} [playersNum] TableStateEvent playersNum
         * @property {string|null} [hashRound] TableStateEvent hashRound
         * @property {number|null} [result] TableStateEvent result
         * @property {Array.<number>|null} [cards] TableStateEvent cards
         * @property {Array.<string>|null} [betList] TableStateEvent betList
         */

        /**
         * Constructs a new TableStateEvent.
         * @memberof dragontiger
         * @classdesc Represents a TableStateEvent.
         * @implements ITableStateEvent
         * @constructor
         * @param {dragontiger.ITableStateEvent=} [properties] Properties to set
         */
        function TableStateEvent(properties) {
            this.cards = [];
            this.betList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableStateEvent gameCode.
         * @member {string} gameCode
         * @memberof dragontiger.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.gameCode = "";

        /**
         * TableStateEvent playersNum.
         * @member {number} playersNum
         * @memberof dragontiger.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.playersNum = 0;

        /**
         * TableStateEvent hashRound.
         * @member {string} hashRound
         * @memberof dragontiger.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.hashRound = "";

        /**
         * TableStateEvent result.
         * @member {number} result
         * @memberof dragontiger.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.result = 0;

        /**
         * TableStateEvent cards.
         * @member {Array.<number>} cards
         * @memberof dragontiger.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.cards = $util.emptyArray;

        /**
         * TableStateEvent betList.
         * @member {Array.<string>} betList
         * @memberof dragontiger.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.betList = $util.emptyArray;

        /**
         * Creates a new TableStateEvent instance using the specified properties.
         * @function create
         * @memberof dragontiger.TableStateEvent
         * @static
         * @param {dragontiger.ITableStateEvent=} [properties] Properties to set
         * @returns {dragontiger.TableStateEvent} TableStateEvent instance
         */
        TableStateEvent.create = function create(properties) {
            return new TableStateEvent(properties);
        };

        /**
         * Encodes the specified TableStateEvent message. Does not implicitly {@link dragontiger.TableStateEvent.verify|verify} messages.
         * @function encode
         * @memberof dragontiger.TableStateEvent
         * @static
         * @param {dragontiger.ITableStateEvent} message TableStateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableStateEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.playersNum != null && Object.hasOwnProperty.call(message, "playersNum"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.playersNum);
            if (message.hashRound != null && Object.hasOwnProperty.call(message, "hashRound"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.hashRound);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.result);
            if (message.cards != null && message.cards.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (var i = 0; i < message.cards.length; ++i)
                    writer.int32(message.cards[i]);
                writer.ldelim();
            }
            if (message.betList != null && message.betList.length)
                for (var i = 0; i < message.betList.length; ++i)
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.betList[i]);
            return writer;
        };

        /**
         * Encodes the specified TableStateEvent message, length delimited. Does not implicitly {@link dragontiger.TableStateEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dragontiger.TableStateEvent
         * @static
         * @param {dragontiger.ITableStateEvent} message TableStateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableStateEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableStateEvent message from the specified reader or buffer.
         * @function decode
         * @memberof dragontiger.TableStateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dragontiger.TableStateEvent} TableStateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableStateEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.dragontiger.TableStateEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.playersNum = reader.int32();
                    break;
                case 3:
                    message.hashRound = reader.string();
                    break;
                case 4:
                    message.result = reader.int32();
                    break;
                case 5:
                    if (!(message.cards && message.cards.length))
                        message.cards = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.cards.push(reader.int32());
                    } else
                        message.cards.push(reader.int32());
                    break;
                case 6:
                    if (!(message.betList && message.betList.length))
                        message.betList = [];
                    message.betList.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableStateEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dragontiger.TableStateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dragontiger.TableStateEvent} TableStateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableStateEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableStateEvent message.
         * @function verify
         * @memberof dragontiger.TableStateEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableStateEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                if (!$util.isInteger(message.playersNum))
                    return "playersNum: integer expected";
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                if (!$util.isString(message.hashRound))
                    return "hashRound: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            if (message.cards != null && message.hasOwnProperty("cards")) {
                if (!Array.isArray(message.cards))
                    return "cards: array expected";
                for (var i = 0; i < message.cards.length; ++i)
                    if (!$util.isInteger(message.cards[i]))
                        return "cards: integer[] expected";
            }
            if (message.betList != null && message.hasOwnProperty("betList")) {
                if (!Array.isArray(message.betList))
                    return "betList: array expected";
                for (var i = 0; i < message.betList.length; ++i)
                    if (!$util.isString(message.betList[i]))
                        return "betList: string[] expected";
            }
            return null;
        };

        /**
         * Creates a TableStateEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dragontiger.TableStateEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dragontiger.TableStateEvent} TableStateEvent
         */
        TableStateEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.dragontiger.TableStateEvent)
                return object;
            var message = new $root.dragontiger.TableStateEvent();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.playersNum != null)
                message.playersNum = object.playersNum | 0;
            if (object.hashRound != null)
                message.hashRound = String(object.hashRound);
            if (object.result != null)
                message.result = object.result | 0;
            if (object.cards) {
                if (!Array.isArray(object.cards))
                    throw TypeError(".dragontiger.TableStateEvent.cards: array expected");
                message.cards = [];
                for (var i = 0; i < object.cards.length; ++i)
                    message.cards[i] = object.cards[i] | 0;
            }
            if (object.betList) {
                if (!Array.isArray(object.betList))
                    throw TypeError(".dragontiger.TableStateEvent.betList: array expected");
                message.betList = [];
                for (var i = 0; i < object.betList.length; ++i)
                    message.betList[i] = String(object.betList[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a TableStateEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dragontiger.TableStateEvent
         * @static
         * @param {dragontiger.TableStateEvent} message TableStateEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableStateEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.cards = [];
                object.betList = [];
            }
            if (options.defaults) {
                object.gameCode = "";
                object.playersNum = 0;
                object.hashRound = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                object.playersNum = message.playersNum;
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                object.hashRound = message.hashRound;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.cards && message.cards.length) {
                object.cards = [];
                for (var j = 0; j < message.cards.length; ++j)
                    object.cards[j] = message.cards[j];
            }
            if (message.betList && message.betList.length) {
                object.betList = [];
                for (var j = 0; j < message.betList.length; ++j)
                    object.betList[j] = message.betList[j];
            }
            return object;
        };

        /**
         * Converts this TableStateEvent to JSON.
         * @function toJSON
         * @memberof dragontiger.TableStateEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableStateEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TableStateEvent;
    })();

    dragontiger.HistoryResultEvent = (function() {

        /**
         * Properties of a HistoryResultEvent.
         * @memberof dragontiger
         * @interface IHistoryResultEvent
         * @property {Array.<dragontiger.IHistoryResult>|null} [historyResults] HistoryResultEvent historyResults
         */

        /**
         * Constructs a new HistoryResultEvent.
         * @memberof dragontiger
         * @classdesc Represents a HistoryResultEvent.
         * @implements IHistoryResultEvent
         * @constructor
         * @param {dragontiger.IHistoryResultEvent=} [properties] Properties to set
         */
        function HistoryResultEvent(properties) {
            this.historyResults = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResultEvent historyResults.
         * @member {Array.<dragontiger.IHistoryResult>} historyResults
         * @memberof dragontiger.HistoryResultEvent
         * @instance
         */
        HistoryResultEvent.prototype.historyResults = $util.emptyArray;

        /**
         * Creates a new HistoryResultEvent instance using the specified properties.
         * @function create
         * @memberof dragontiger.HistoryResultEvent
         * @static
         * @param {dragontiger.IHistoryResultEvent=} [properties] Properties to set
         * @returns {dragontiger.HistoryResultEvent} HistoryResultEvent instance
         */
        HistoryResultEvent.create = function create(properties) {
            return new HistoryResultEvent(properties);
        };

        /**
         * Encodes the specified HistoryResultEvent message. Does not implicitly {@link dragontiger.HistoryResultEvent.verify|verify} messages.
         * @function encode
         * @memberof dragontiger.HistoryResultEvent
         * @static
         * @param {dragontiger.IHistoryResultEvent} message HistoryResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResultEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.historyResults != null && message.historyResults.length)
                for (var i = 0; i < message.historyResults.length; ++i)
                    $root.dragontiger.HistoryResult.encode(message.historyResults[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HistoryResultEvent message, length delimited. Does not implicitly {@link dragontiger.HistoryResultEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dragontiger.HistoryResultEvent
         * @static
         * @param {dragontiger.IHistoryResultEvent} message HistoryResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResultEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResultEvent message from the specified reader or buffer.
         * @function decode
         * @memberof dragontiger.HistoryResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dragontiger.HistoryResultEvent} HistoryResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResultEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.dragontiger.HistoryResultEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.historyResults && message.historyResults.length))
                        message.historyResults = [];
                    message.historyResults.push($root.dragontiger.HistoryResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResultEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dragontiger.HistoryResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dragontiger.HistoryResultEvent} HistoryResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResultEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResultEvent message.
         * @function verify
         * @memberof dragontiger.HistoryResultEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResultEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.historyResults != null && message.hasOwnProperty("historyResults")) {
                if (!Array.isArray(message.historyResults))
                    return "historyResults: array expected";
                for (var i = 0; i < message.historyResults.length; ++i) {
                    var error = $root.dragontiger.HistoryResult.verify(message.historyResults[i]);
                    if (error)
                        return "historyResults." + error;
                }
            }
            return null;
        };

        /**
         * Creates a HistoryResultEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dragontiger.HistoryResultEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dragontiger.HistoryResultEvent} HistoryResultEvent
         */
        HistoryResultEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.dragontiger.HistoryResultEvent)
                return object;
            var message = new $root.dragontiger.HistoryResultEvent();
            if (object.historyResults) {
                if (!Array.isArray(object.historyResults))
                    throw TypeError(".dragontiger.HistoryResultEvent.historyResults: array expected");
                message.historyResults = [];
                for (var i = 0; i < object.historyResults.length; ++i) {
                    if (typeof object.historyResults[i] !== "object")
                        throw TypeError(".dragontiger.HistoryResultEvent.historyResults: object expected");
                    message.historyResults[i] = $root.dragontiger.HistoryResult.fromObject(object.historyResults[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a HistoryResultEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dragontiger.HistoryResultEvent
         * @static
         * @param {dragontiger.HistoryResultEvent} message HistoryResultEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResultEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.historyResults = [];
            if (message.historyResults && message.historyResults.length) {
                object.historyResults = [];
                for (var j = 0; j < message.historyResults.length; ++j)
                    object.historyResults[j] = $root.dragontiger.HistoryResult.toObject(message.historyResults[j], options);
            }
            return object;
        };

        /**
         * Converts this HistoryResultEvent to JSON.
         * @function toJSON
         * @memberof dragontiger.HistoryResultEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResultEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResultEvent;
    })();

    dragontiger.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof dragontiger
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof dragontiger
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {dragontiger.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof dragontiger.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof dragontiger.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof dragontiger.HistoryResult
         * @static
         * @param {dragontiger.IHistoryResult=} [properties] Properties to set
         * @returns {dragontiger.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link dragontiger.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof dragontiger.HistoryResult
         * @static
         * @param {dragontiger.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link dragontiger.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dragontiger.HistoryResult
         * @static
         * @param {dragontiger.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof dragontiger.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dragontiger.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.dragontiger.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dragontiger.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dragontiger.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof dragontiger.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dragontiger.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dragontiger.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.dragontiger.HistoryResult)
                return object;
            var message = new $root.dragontiger.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dragontiger.HistoryResult
         * @static
         * @param {dragontiger.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof dragontiger.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    dragontiger.BetReq = (function() {

        /**
         * Properties of a BetReq.
         * @memberof dragontiger
         * @interface IBetReq
         * @property {number|null} [currencyType] BetReq currencyType
         * @property {number|Long|null} [betAmount] BetReq betAmount
         * @property {number|null} [betArea] BetReq betArea
         */

        /**
         * Constructs a new BetReq.
         * @memberof dragontiger
         * @classdesc Represents a BetReq.
         * @implements IBetReq
         * @constructor
         * @param {dragontiger.IBetReq=} [properties] Properties to set
         */
        function BetReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BetReq currencyType.
         * @member {number} currencyType
         * @memberof dragontiger.BetReq
         * @instance
         */
        BetReq.prototype.currencyType = 0;

        /**
         * BetReq betAmount.
         * @member {number|Long} betAmount
         * @memberof dragontiger.BetReq
         * @instance
         */
        BetReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BetReq betArea.
         * @member {number} betArea
         * @memberof dragontiger.BetReq
         * @instance
         */
        BetReq.prototype.betArea = 0;

        /**
         * Creates a new BetReq instance using the specified properties.
         * @function create
         * @memberof dragontiger.BetReq
         * @static
         * @param {dragontiger.IBetReq=} [properties] Properties to set
         * @returns {dragontiger.BetReq} BetReq instance
         */
        BetReq.create = function create(properties) {
            return new BetReq(properties);
        };

        /**
         * Encodes the specified BetReq message. Does not implicitly {@link dragontiger.BetReq.verify|verify} messages.
         * @function encode
         * @memberof dragontiger.BetReq
         * @static
         * @param {dragontiger.IBetReq} message BetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            if (message.betArea != null && Object.hasOwnProperty.call(message, "betArea"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.betArea);
            return writer;
        };

        /**
         * Encodes the specified BetReq message, length delimited. Does not implicitly {@link dragontiger.BetReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dragontiger.BetReq
         * @static
         * @param {dragontiger.IBetReq} message BetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BetReq message from the specified reader or buffer.
         * @function decode
         * @memberof dragontiger.BetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dragontiger.BetReq} BetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.dragontiger.BetReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                case 3:
                    message.betArea = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BetReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dragontiger.BetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dragontiger.BetReq} BetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BetReq message.
         * @function verify
         * @memberof dragontiger.BetReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BetReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.betArea != null && message.hasOwnProperty("betArea"))
                if (!$util.isInteger(message.betArea))
                    return "betArea: integer expected";
            return null;
        };

        /**
         * Creates a BetReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dragontiger.BetReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dragontiger.BetReq} BetReq
         */
        BetReq.fromObject = function fromObject(object) {
            if (object instanceof $root.dragontiger.BetReq)
                return object;
            var message = new $root.dragontiger.BetReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.betArea != null)
                message.betArea = object.betArea | 0;
            return message;
        };

        /**
         * Creates a plain object from a BetReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dragontiger.BetReq
         * @static
         * @param {dragontiger.BetReq} message BetReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BetReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.betArea = 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.betArea != null && message.hasOwnProperty("betArea"))
                object.betArea = message.betArea;
            return object;
        };

        /**
         * Converts this BetReq to JSON.
         * @function toJSON
         * @memberof dragontiger.BetReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BetReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BetReq;
    })();

    dragontiger.BetEvent = (function() {

        /**
         * Properties of a BetEvent.
         * @memberof dragontiger
         * @interface IBetEvent
         * @property {number|null} [seat] BetEvent seat
         * @property {number|null} [currencyType] BetEvent currencyType
         * @property {number|Long|null} [betAmount] BetEvent betAmount
         * @property {number|null} [betArea] BetEvent betArea
         */

        /**
         * Constructs a new BetEvent.
         * @memberof dragontiger
         * @classdesc Represents a BetEvent.
         * @implements IBetEvent
         * @constructor
         * @param {dragontiger.IBetEvent=} [properties] Properties to set
         */
        function BetEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BetEvent seat.
         * @member {number} seat
         * @memberof dragontiger.BetEvent
         * @instance
         */
        BetEvent.prototype.seat = 0;

        /**
         * BetEvent currencyType.
         * @member {number} currencyType
         * @memberof dragontiger.BetEvent
         * @instance
         */
        BetEvent.prototype.currencyType = 0;

        /**
         * BetEvent betAmount.
         * @member {number|Long} betAmount
         * @memberof dragontiger.BetEvent
         * @instance
         */
        BetEvent.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BetEvent betArea.
         * @member {number} betArea
         * @memberof dragontiger.BetEvent
         * @instance
         */
        BetEvent.prototype.betArea = 0;

        /**
         * Creates a new BetEvent instance using the specified properties.
         * @function create
         * @memberof dragontiger.BetEvent
         * @static
         * @param {dragontiger.IBetEvent=} [properties] Properties to set
         * @returns {dragontiger.BetEvent} BetEvent instance
         */
        BetEvent.create = function create(properties) {
            return new BetEvent(properties);
        };

        /**
         * Encodes the specified BetEvent message. Does not implicitly {@link dragontiger.BetEvent.verify|verify} messages.
         * @function encode
         * @memberof dragontiger.BetEvent
         * @static
         * @param {dragontiger.IBetEvent} message BetEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.betAmount);
            if (message.betArea != null && Object.hasOwnProperty.call(message, "betArea"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.betArea);
            return writer;
        };

        /**
         * Encodes the specified BetEvent message, length delimited. Does not implicitly {@link dragontiger.BetEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dragontiger.BetEvent
         * @static
         * @param {dragontiger.IBetEvent} message BetEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BetEvent message from the specified reader or buffer.
         * @function decode
         * @memberof dragontiger.BetEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dragontiger.BetEvent} BetEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.dragontiger.BetEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.betAmount = reader.int64();
                    break;
                case 4:
                    message.betArea = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BetEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dragontiger.BetEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dragontiger.BetEvent} BetEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BetEvent message.
         * @function verify
         * @memberof dragontiger.BetEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BetEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.betArea != null && message.hasOwnProperty("betArea"))
                if (!$util.isInteger(message.betArea))
                    return "betArea: integer expected";
            return null;
        };

        /**
         * Creates a BetEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dragontiger.BetEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dragontiger.BetEvent} BetEvent
         */
        BetEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.dragontiger.BetEvent)
                return object;
            var message = new $root.dragontiger.BetEvent();
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.betArea != null)
                message.betArea = object.betArea | 0;
            return message;
        };

        /**
         * Creates a plain object from a BetEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dragontiger.BetEvent
         * @static
         * @param {dragontiger.BetEvent} message BetEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BetEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.seat = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.betArea = 0;
            }
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.betArea != null && message.hasOwnProperty("betArea"))
                object.betArea = message.betArea;
            return object;
        };

        /**
         * Converts this BetEvent to JSON.
         * @function toJSON
         * @memberof dragontiger.BetEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BetEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BetEvent;
    })();

    dragontiger.PlayerListEvent = (function() {

        /**
         * Properties of a PlayerListEvent.
         * @memberof dragontiger
         * @interface IPlayerListEvent
         * @property {Array.<dragontiger.IPlayerInfo>|null} [infos] PlayerListEvent infos
         */

        /**
         * Constructs a new PlayerListEvent.
         * @memberof dragontiger
         * @classdesc Represents a PlayerListEvent.
         * @implements IPlayerListEvent
         * @constructor
         * @param {dragontiger.IPlayerListEvent=} [properties] Properties to set
         */
        function PlayerListEvent(properties) {
            this.infos = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerListEvent infos.
         * @member {Array.<dragontiger.IPlayerInfo>} infos
         * @memberof dragontiger.PlayerListEvent
         * @instance
         */
        PlayerListEvent.prototype.infos = $util.emptyArray;

        /**
         * Creates a new PlayerListEvent instance using the specified properties.
         * @function create
         * @memberof dragontiger.PlayerListEvent
         * @static
         * @param {dragontiger.IPlayerListEvent=} [properties] Properties to set
         * @returns {dragontiger.PlayerListEvent} PlayerListEvent instance
         */
        PlayerListEvent.create = function create(properties) {
            return new PlayerListEvent(properties);
        };

        /**
         * Encodes the specified PlayerListEvent message. Does not implicitly {@link dragontiger.PlayerListEvent.verify|verify} messages.
         * @function encode
         * @memberof dragontiger.PlayerListEvent
         * @static
         * @param {dragontiger.IPlayerListEvent} message PlayerListEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerListEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.infos != null && message.infos.length)
                for (var i = 0; i < message.infos.length; ++i)
                    $root.dragontiger.PlayerInfo.encode(message.infos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PlayerListEvent message, length delimited. Does not implicitly {@link dragontiger.PlayerListEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dragontiger.PlayerListEvent
         * @static
         * @param {dragontiger.IPlayerListEvent} message PlayerListEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerListEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerListEvent message from the specified reader or buffer.
         * @function decode
         * @memberof dragontiger.PlayerListEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dragontiger.PlayerListEvent} PlayerListEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerListEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.dragontiger.PlayerListEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.infos && message.infos.length))
                        message.infos = [];
                    message.infos.push($root.dragontiger.PlayerInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerListEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dragontiger.PlayerListEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dragontiger.PlayerListEvent} PlayerListEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerListEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerListEvent message.
         * @function verify
         * @memberof dragontiger.PlayerListEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerListEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.infos != null && message.hasOwnProperty("infos")) {
                if (!Array.isArray(message.infos))
                    return "infos: array expected";
                for (var i = 0; i < message.infos.length; ++i) {
                    var error = $root.dragontiger.PlayerInfo.verify(message.infos[i]);
                    if (error)
                        return "infos." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PlayerListEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dragontiger.PlayerListEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dragontiger.PlayerListEvent} PlayerListEvent
         */
        PlayerListEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.dragontiger.PlayerListEvent)
                return object;
            var message = new $root.dragontiger.PlayerListEvent();
            if (object.infos) {
                if (!Array.isArray(object.infos))
                    throw TypeError(".dragontiger.PlayerListEvent.infos: array expected");
                message.infos = [];
                for (var i = 0; i < object.infos.length; ++i) {
                    if (typeof object.infos[i] !== "object")
                        throw TypeError(".dragontiger.PlayerListEvent.infos: object expected");
                    message.infos[i] = $root.dragontiger.PlayerInfo.fromObject(object.infos[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PlayerListEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dragontiger.PlayerListEvent
         * @static
         * @param {dragontiger.PlayerListEvent} message PlayerListEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerListEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.infos = [];
            if (message.infos && message.infos.length) {
                object.infos = [];
                for (var j = 0; j < message.infos.length; ++j)
                    object.infos[j] = $root.dragontiger.PlayerInfo.toObject(message.infos[j], options);
            }
            return object;
        };

        /**
         * Converts this PlayerListEvent to JSON.
         * @function toJSON
         * @memberof dragontiger.PlayerListEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerListEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerListEvent;
    })();

    dragontiger.PlayerInfo = (function() {

        /**
         * Properties of a PlayerInfo.
         * @memberof dragontiger
         * @interface IPlayerInfo
         * @property {number|Long|null} [playerId] PlayerInfo playerId
         * @property {string|null} [nickname] PlayerInfo nickname
         * @property {string|null} [headUrl] PlayerInfo headUrl
         * @property {number|null} [level] PlayerInfo level
         * @property {string|null} [betInfo] PlayerInfo betInfo
         */

        /**
         * Constructs a new PlayerInfo.
         * @memberof dragontiger
         * @classdesc Represents a PlayerInfo.
         * @implements IPlayerInfo
         * @constructor
         * @param {dragontiger.IPlayerInfo=} [properties] Properties to set
         */
        function PlayerInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerInfo playerId.
         * @member {number|Long} playerId
         * @memberof dragontiger.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.playerId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PlayerInfo nickname.
         * @member {string} nickname
         * @memberof dragontiger.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.nickname = "";

        /**
         * PlayerInfo headUrl.
         * @member {string} headUrl
         * @memberof dragontiger.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.headUrl = "";

        /**
         * PlayerInfo level.
         * @member {number} level
         * @memberof dragontiger.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.level = 0;

        /**
         * PlayerInfo betInfo.
         * @member {string} betInfo
         * @memberof dragontiger.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.betInfo = "";

        /**
         * Creates a new PlayerInfo instance using the specified properties.
         * @function create
         * @memberof dragontiger.PlayerInfo
         * @static
         * @param {dragontiger.IPlayerInfo=} [properties] Properties to set
         * @returns {dragontiger.PlayerInfo} PlayerInfo instance
         */
        PlayerInfo.create = function create(properties) {
            return new PlayerInfo(properties);
        };

        /**
         * Encodes the specified PlayerInfo message. Does not implicitly {@link dragontiger.PlayerInfo.verify|verify} messages.
         * @function encode
         * @memberof dragontiger.PlayerInfo
         * @static
         * @param {dragontiger.IPlayerInfo} message PlayerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.playerId != null && Object.hasOwnProperty.call(message, "playerId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.playerId);
            if (message.nickname != null && Object.hasOwnProperty.call(message, "nickname"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.nickname);
            if (message.headUrl != null && Object.hasOwnProperty.call(message, "headUrl"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.headUrl);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.level);
            if (message.betInfo != null && Object.hasOwnProperty.call(message, "betInfo"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.betInfo);
            return writer;
        };

        /**
         * Encodes the specified PlayerInfo message, length delimited. Does not implicitly {@link dragontiger.PlayerInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dragontiger.PlayerInfo
         * @static
         * @param {dragontiger.IPlayerInfo} message PlayerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerInfo message from the specified reader or buffer.
         * @function decode
         * @memberof dragontiger.PlayerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dragontiger.PlayerInfo} PlayerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.dragontiger.PlayerInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.playerId = reader.uint64();
                    break;
                case 2:
                    message.nickname = reader.string();
                    break;
                case 3:
                    message.headUrl = reader.string();
                    break;
                case 4:
                    message.level = reader.int32();
                    break;
                case 5:
                    message.betInfo = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dragontiger.PlayerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dragontiger.PlayerInfo} PlayerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerInfo message.
         * @function verify
         * @memberof dragontiger.PlayerInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.playerId != null && message.hasOwnProperty("playerId"))
                if (!$util.isInteger(message.playerId) && !(message.playerId && $util.isInteger(message.playerId.low) && $util.isInteger(message.playerId.high)))
                    return "playerId: integer|Long expected";
            if (message.nickname != null && message.hasOwnProperty("nickname"))
                if (!$util.isString(message.nickname))
                    return "nickname: string expected";
            if (message.headUrl != null && message.hasOwnProperty("headUrl"))
                if (!$util.isString(message.headUrl))
                    return "headUrl: string expected";
            if (message.level != null && message.hasOwnProperty("level"))
                if (!$util.isInteger(message.level))
                    return "level: integer expected";
            if (message.betInfo != null && message.hasOwnProperty("betInfo"))
                if (!$util.isString(message.betInfo))
                    return "betInfo: string expected";
            return null;
        };

        /**
         * Creates a PlayerInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dragontiger.PlayerInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dragontiger.PlayerInfo} PlayerInfo
         */
        PlayerInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.dragontiger.PlayerInfo)
                return object;
            var message = new $root.dragontiger.PlayerInfo();
            if (object.playerId != null)
                if ($util.Long)
                    (message.playerId = $util.Long.fromValue(object.playerId)).unsigned = true;
                else if (typeof object.playerId === "string")
                    message.playerId = parseInt(object.playerId, 10);
                else if (typeof object.playerId === "number")
                    message.playerId = object.playerId;
                else if (typeof object.playerId === "object")
                    message.playerId = new $util.LongBits(object.playerId.low >>> 0, object.playerId.high >>> 0).toNumber(true);
            if (object.nickname != null)
                message.nickname = String(object.nickname);
            if (object.headUrl != null)
                message.headUrl = String(object.headUrl);
            if (object.level != null)
                message.level = object.level | 0;
            if (object.betInfo != null)
                message.betInfo = String(object.betInfo);
            return message;
        };

        /**
         * Creates a plain object from a PlayerInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dragontiger.PlayerInfo
         * @static
         * @param {dragontiger.PlayerInfo} message PlayerInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.playerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.playerId = options.longs === String ? "0" : 0;
                object.nickname = "";
                object.headUrl = "";
                object.level = 0;
                object.betInfo = "";
            }
            if (message.playerId != null && message.hasOwnProperty("playerId"))
                if (typeof message.playerId === "number")
                    object.playerId = options.longs === String ? String(message.playerId) : message.playerId;
                else
                    object.playerId = options.longs === String ? $util.Long.prototype.toString.call(message.playerId) : options.longs === Number ? new $util.LongBits(message.playerId.low >>> 0, message.playerId.high >>> 0).toNumber(true) : message.playerId;
            if (message.nickname != null && message.hasOwnProperty("nickname"))
                object.nickname = message.nickname;
            if (message.headUrl != null && message.hasOwnProperty("headUrl"))
                object.headUrl = message.headUrl;
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = message.level;
            if (message.betInfo != null && message.hasOwnProperty("betInfo"))
                object.betInfo = message.betInfo;
            return object;
        };

        /**
         * Converts this PlayerInfo to JSON.
         * @function toJSON
         * @memberof dragontiger.PlayerInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerInfo;
    })();

    dragontiger.GameEndEvent = (function() {

        /**
         * Properties of a GameEndEvent.
         * @memberof dragontiger
         * @interface IGameEndEvent
         * @property {number|null} [result] GameEndEvent result
         * @property {Array.<number>|null} [cards] GameEndEvent cards
         */

        /**
         * Constructs a new GameEndEvent.
         * @memberof dragontiger
         * @classdesc Represents a GameEndEvent.
         * @implements IGameEndEvent
         * @constructor
         * @param {dragontiger.IGameEndEvent=} [properties] Properties to set
         */
        function GameEndEvent(properties) {
            this.cards = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameEndEvent result.
         * @member {number} result
         * @memberof dragontiger.GameEndEvent
         * @instance
         */
        GameEndEvent.prototype.result = 0;

        /**
         * GameEndEvent cards.
         * @member {Array.<number>} cards
         * @memberof dragontiger.GameEndEvent
         * @instance
         */
        GameEndEvent.prototype.cards = $util.emptyArray;

        /**
         * Creates a new GameEndEvent instance using the specified properties.
         * @function create
         * @memberof dragontiger.GameEndEvent
         * @static
         * @param {dragontiger.IGameEndEvent=} [properties] Properties to set
         * @returns {dragontiger.GameEndEvent} GameEndEvent instance
         */
        GameEndEvent.create = function create(properties) {
            return new GameEndEvent(properties);
        };

        /**
         * Encodes the specified GameEndEvent message. Does not implicitly {@link dragontiger.GameEndEvent.verify|verify} messages.
         * @function encode
         * @memberof dragontiger.GameEndEvent
         * @static
         * @param {dragontiger.IGameEndEvent} message GameEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameEndEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.cards != null && message.cards.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.cards.length; ++i)
                    writer.int32(message.cards[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified GameEndEvent message, length delimited. Does not implicitly {@link dragontiger.GameEndEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dragontiger.GameEndEvent
         * @static
         * @param {dragontiger.IGameEndEvent} message GameEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameEndEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameEndEvent message from the specified reader or buffer.
         * @function decode
         * @memberof dragontiger.GameEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dragontiger.GameEndEvent} GameEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameEndEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.dragontiger.GameEndEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    if (!(message.cards && message.cards.length))
                        message.cards = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.cards.push(reader.int32());
                    } else
                        message.cards.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameEndEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dragontiger.GameEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dragontiger.GameEndEvent} GameEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameEndEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameEndEvent message.
         * @function verify
         * @memberof dragontiger.GameEndEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameEndEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            if (message.cards != null && message.hasOwnProperty("cards")) {
                if (!Array.isArray(message.cards))
                    return "cards: array expected";
                for (var i = 0; i < message.cards.length; ++i)
                    if (!$util.isInteger(message.cards[i]))
                        return "cards: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a GameEndEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dragontiger.GameEndEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dragontiger.GameEndEvent} GameEndEvent
         */
        GameEndEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.dragontiger.GameEndEvent)
                return object;
            var message = new $root.dragontiger.GameEndEvent();
            if (object.result != null)
                message.result = object.result | 0;
            if (object.cards) {
                if (!Array.isArray(object.cards))
                    throw TypeError(".dragontiger.GameEndEvent.cards: array expected");
                message.cards = [];
                for (var i = 0; i < object.cards.length; ++i)
                    message.cards[i] = object.cards[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a GameEndEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dragontiger.GameEndEvent
         * @static
         * @param {dragontiger.GameEndEvent} message GameEndEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameEndEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.cards = [];
            if (options.defaults)
                object.result = 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.cards && message.cards.length) {
                object.cards = [];
                for (var j = 0; j < message.cards.length; ++j)
                    object.cards[j] = message.cards[j];
            }
            return object;
        };

        /**
         * Converts this GameEndEvent to JSON.
         * @function toJSON
         * @memberof dragontiger.GameEndEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameEndEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameEndEvent;
    })();

    dragontiger.GameResultEvent = (function() {

        /**
         * Properties of a GameResultEvent.
         * @memberof dragontiger
         * @interface IGameResultEvent
         * @property {Array.<string>|null} [winInfo] GameResultEvent winInfo
         * @property {string|null} [gameCode] GameResultEvent gameCode
         * @property {number|null} [result] GameResultEvent result
         */

        /**
         * Constructs a new GameResultEvent.
         * @memberof dragontiger
         * @classdesc Represents a GameResultEvent.
         * @implements IGameResultEvent
         * @constructor
         * @param {dragontiger.IGameResultEvent=} [properties] Properties to set
         */
        function GameResultEvent(properties) {
            this.winInfo = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameResultEvent winInfo.
         * @member {Array.<string>} winInfo
         * @memberof dragontiger.GameResultEvent
         * @instance
         */
        GameResultEvent.prototype.winInfo = $util.emptyArray;

        /**
         * GameResultEvent gameCode.
         * @member {string} gameCode
         * @memberof dragontiger.GameResultEvent
         * @instance
         */
        GameResultEvent.prototype.gameCode = "";

        /**
         * GameResultEvent result.
         * @member {number} result
         * @memberof dragontiger.GameResultEvent
         * @instance
         */
        GameResultEvent.prototype.result = 0;

        /**
         * Creates a new GameResultEvent instance using the specified properties.
         * @function create
         * @memberof dragontiger.GameResultEvent
         * @static
         * @param {dragontiger.IGameResultEvent=} [properties] Properties to set
         * @returns {dragontiger.GameResultEvent} GameResultEvent instance
         */
        GameResultEvent.create = function create(properties) {
            return new GameResultEvent(properties);
        };

        /**
         * Encodes the specified GameResultEvent message. Does not implicitly {@link dragontiger.GameResultEvent.verify|verify} messages.
         * @function encode
         * @memberof dragontiger.GameResultEvent
         * @static
         * @param {dragontiger.IGameResultEvent} message GameResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameResultEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.winInfo != null && message.winInfo.length)
                for (var i = 0; i < message.winInfo.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.winInfo[i]);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified GameResultEvent message, length delimited. Does not implicitly {@link dragontiger.GameResultEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof dragontiger.GameResultEvent
         * @static
         * @param {dragontiger.IGameResultEvent} message GameResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameResultEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameResultEvent message from the specified reader or buffer.
         * @function decode
         * @memberof dragontiger.GameResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {dragontiger.GameResultEvent} GameResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameResultEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.dragontiger.GameResultEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.winInfo && message.winInfo.length))
                        message.winInfo = [];
                    message.winInfo.push(reader.string());
                    break;
                case 2:
                    message.gameCode = reader.string();
                    break;
                case 3:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameResultEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof dragontiger.GameResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {dragontiger.GameResultEvent} GameResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameResultEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameResultEvent message.
         * @function verify
         * @memberof dragontiger.GameResultEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameResultEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.winInfo != null && message.hasOwnProperty("winInfo")) {
                if (!Array.isArray(message.winInfo))
                    return "winInfo: array expected";
                for (var i = 0; i < message.winInfo.length; ++i)
                    if (!$util.isString(message.winInfo[i]))
                        return "winInfo: string[] expected";
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a GameResultEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof dragontiger.GameResultEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {dragontiger.GameResultEvent} GameResultEvent
         */
        GameResultEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.dragontiger.GameResultEvent)
                return object;
            var message = new $root.dragontiger.GameResultEvent();
            if (object.winInfo) {
                if (!Array.isArray(object.winInfo))
                    throw TypeError(".dragontiger.GameResultEvent.winInfo: array expected");
                message.winInfo = [];
                for (var i = 0; i < object.winInfo.length; ++i)
                    message.winInfo[i] = String(object.winInfo[i]);
            }
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a GameResultEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof dragontiger.GameResultEvent
         * @static
         * @param {dragontiger.GameResultEvent} message GameResultEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameResultEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.winInfo = [];
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.winInfo && message.winInfo.length) {
                object.winInfo = [];
                for (var j = 0; j < message.winInfo.length; ++j)
                    object.winInfo[j] = message.winInfo[j];
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this GameResultEvent to JSON.
         * @function toJSON
         * @memberof dragontiger.GameResultEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameResultEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameResultEvent;
    })();

    return dragontiger;
})();

$root.truco = (function() {

    /**
     * Namespace truco.
     * @exports truco
     * @namespace
     */
    var truco = {};

    /**
     * DEADLINE_TYPE enum.
     * @name truco.DEADLINE_TYPE
     * @enum {number}
     * @property {number} DT_NONE=0 DT_NONE value
     * @property {number} START_BET=1 START_BET value
     * @property {number} STOP_BET=2 STOP_BET value
     * @property {number} COLLECT_BET=3 COLLECT_BET value
     */
    truco.DEADLINE_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DT_NONE"] = 0;
        values[valuesById[1] = "START_BET"] = 1;
        values[valuesById[2] = "STOP_BET"] = 2;
        values[valuesById[3] = "COLLECT_BET"] = 3;
        return values;
    })();

    truco.GameStartEvent = (function() {

        /**
         * Properties of a GameStartEvent.
         * @memberof truco
         * @interface IGameStartEvent
         * @property {string|null} [gameCode] GameStartEvent gameCode
         * @property {string|null} [hashRound] GameStartEvent hashRound
         * @property {string|null} [params] GameStartEvent params
         * @property {number|null} [keyCard] GameStartEvent keyCard
         */

        /**
         * Constructs a new GameStartEvent.
         * @memberof truco
         * @classdesc Represents a GameStartEvent.
         * @implements IGameStartEvent
         * @constructor
         * @param {truco.IGameStartEvent=} [properties] Properties to set
         */
        function GameStartEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameStartEvent gameCode.
         * @member {string} gameCode
         * @memberof truco.GameStartEvent
         * @instance
         */
        GameStartEvent.prototype.gameCode = "";

        /**
         * GameStartEvent hashRound.
         * @member {string} hashRound
         * @memberof truco.GameStartEvent
         * @instance
         */
        GameStartEvent.prototype.hashRound = "";

        /**
         * GameStartEvent params.
         * @member {string} params
         * @memberof truco.GameStartEvent
         * @instance
         */
        GameStartEvent.prototype.params = "";

        /**
         * GameStartEvent keyCard.
         * @member {number} keyCard
         * @memberof truco.GameStartEvent
         * @instance
         */
        GameStartEvent.prototype.keyCard = 0;

        /**
         * Creates a new GameStartEvent instance using the specified properties.
         * @function create
         * @memberof truco.GameStartEvent
         * @static
         * @param {truco.IGameStartEvent=} [properties] Properties to set
         * @returns {truco.GameStartEvent} GameStartEvent instance
         */
        GameStartEvent.create = function create(properties) {
            return new GameStartEvent(properties);
        };

        /**
         * Encodes the specified GameStartEvent message. Does not implicitly {@link truco.GameStartEvent.verify|verify} messages.
         * @function encode
         * @memberof truco.GameStartEvent
         * @static
         * @param {truco.IGameStartEvent} message GameStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameStartEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.hashRound != null && Object.hasOwnProperty.call(message, "hashRound"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.hashRound);
            if (message.params != null && Object.hasOwnProperty.call(message, "params"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.params);
            if (message.keyCard != null && Object.hasOwnProperty.call(message, "keyCard"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.keyCard);
            return writer;
        };

        /**
         * Encodes the specified GameStartEvent message, length delimited. Does not implicitly {@link truco.GameStartEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof truco.GameStartEvent
         * @static
         * @param {truco.IGameStartEvent} message GameStartEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameStartEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameStartEvent message from the specified reader or buffer.
         * @function decode
         * @memberof truco.GameStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {truco.GameStartEvent} GameStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameStartEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.truco.GameStartEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.hashRound = reader.string();
                    break;
                case 3:
                    message.params = reader.string();
                    break;
                case 4:
                    message.keyCard = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameStartEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof truco.GameStartEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {truco.GameStartEvent} GameStartEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameStartEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameStartEvent message.
         * @function verify
         * @memberof truco.GameStartEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameStartEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                if (!$util.isString(message.hashRound))
                    return "hashRound: string expected";
            if (message.params != null && message.hasOwnProperty("params"))
                if (!$util.isString(message.params))
                    return "params: string expected";
            if (message.keyCard != null && message.hasOwnProperty("keyCard"))
                if (!$util.isInteger(message.keyCard))
                    return "keyCard: integer expected";
            return null;
        };

        /**
         * Creates a GameStartEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof truco.GameStartEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {truco.GameStartEvent} GameStartEvent
         */
        GameStartEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.truco.GameStartEvent)
                return object;
            var message = new $root.truco.GameStartEvent();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.hashRound != null)
                message.hashRound = String(object.hashRound);
            if (object.params != null)
                message.params = String(object.params);
            if (object.keyCard != null)
                message.keyCard = object.keyCard | 0;
            return message;
        };

        /**
         * Creates a plain object from a GameStartEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof truco.GameStartEvent
         * @static
         * @param {truco.GameStartEvent} message GameStartEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameStartEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.hashRound = "";
                object.params = "";
                object.keyCard = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                object.hashRound = message.hashRound;
            if (message.params != null && message.hasOwnProperty("params"))
                object.params = message.params;
            if (message.keyCard != null && message.hasOwnProperty("keyCard"))
                object.keyCard = message.keyCard;
            return object;
        };

        /**
         * Converts this GameStartEvent to JSON.
         * @function toJSON
         * @memberof truco.GameStartEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameStartEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameStartEvent;
    })();

    truco.TrucoPlayersEvent = (function() {

        /**
         * Properties of a TrucoPlayersEvent.
         * @memberof truco
         * @interface ITrucoPlayersEvent
         * @property {Array.<truco.ITrucoPlayer>|null} [trucoPlayers] TrucoPlayersEvent trucoPlayers
         * @property {number|null} [playersNum] TrucoPlayersEvent playersNum
         */

        /**
         * Constructs a new TrucoPlayersEvent.
         * @memberof truco
         * @classdesc Represents a TrucoPlayersEvent.
         * @implements ITrucoPlayersEvent
         * @constructor
         * @param {truco.ITrucoPlayersEvent=} [properties] Properties to set
         */
        function TrucoPlayersEvent(properties) {
            this.trucoPlayers = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TrucoPlayersEvent trucoPlayers.
         * @member {Array.<truco.ITrucoPlayer>} trucoPlayers
         * @memberof truco.TrucoPlayersEvent
         * @instance
         */
        TrucoPlayersEvent.prototype.trucoPlayers = $util.emptyArray;

        /**
         * TrucoPlayersEvent playersNum.
         * @member {number} playersNum
         * @memberof truco.TrucoPlayersEvent
         * @instance
         */
        TrucoPlayersEvent.prototype.playersNum = 0;

        /**
         * Creates a new TrucoPlayersEvent instance using the specified properties.
         * @function create
         * @memberof truco.TrucoPlayersEvent
         * @static
         * @param {truco.ITrucoPlayersEvent=} [properties] Properties to set
         * @returns {truco.TrucoPlayersEvent} TrucoPlayersEvent instance
         */
        TrucoPlayersEvent.create = function create(properties) {
            return new TrucoPlayersEvent(properties);
        };

        /**
         * Encodes the specified TrucoPlayersEvent message. Does not implicitly {@link truco.TrucoPlayersEvent.verify|verify} messages.
         * @function encode
         * @memberof truco.TrucoPlayersEvent
         * @static
         * @param {truco.ITrucoPlayersEvent} message TrucoPlayersEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TrucoPlayersEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.trucoPlayers != null && message.trucoPlayers.length)
                for (var i = 0; i < message.trucoPlayers.length; ++i)
                    $root.truco.TrucoPlayer.encode(message.trucoPlayers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.playersNum != null && Object.hasOwnProperty.call(message, "playersNum"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.playersNum);
            return writer;
        };

        /**
         * Encodes the specified TrucoPlayersEvent message, length delimited. Does not implicitly {@link truco.TrucoPlayersEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof truco.TrucoPlayersEvent
         * @static
         * @param {truco.ITrucoPlayersEvent} message TrucoPlayersEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TrucoPlayersEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TrucoPlayersEvent message from the specified reader or buffer.
         * @function decode
         * @memberof truco.TrucoPlayersEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {truco.TrucoPlayersEvent} TrucoPlayersEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TrucoPlayersEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.truco.TrucoPlayersEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.trucoPlayers && message.trucoPlayers.length))
                        message.trucoPlayers = [];
                    message.trucoPlayers.push($root.truco.TrucoPlayer.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.playersNum = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TrucoPlayersEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof truco.TrucoPlayersEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {truco.TrucoPlayersEvent} TrucoPlayersEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TrucoPlayersEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TrucoPlayersEvent message.
         * @function verify
         * @memberof truco.TrucoPlayersEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TrucoPlayersEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.trucoPlayers != null && message.hasOwnProperty("trucoPlayers")) {
                if (!Array.isArray(message.trucoPlayers))
                    return "trucoPlayers: array expected";
                for (var i = 0; i < message.trucoPlayers.length; ++i) {
                    var error = $root.truco.TrucoPlayer.verify(message.trucoPlayers[i]);
                    if (error)
                        return "trucoPlayers." + error;
                }
            }
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                if (!$util.isInteger(message.playersNum))
                    return "playersNum: integer expected";
            return null;
        };

        /**
         * Creates a TrucoPlayersEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof truco.TrucoPlayersEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {truco.TrucoPlayersEvent} TrucoPlayersEvent
         */
        TrucoPlayersEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.truco.TrucoPlayersEvent)
                return object;
            var message = new $root.truco.TrucoPlayersEvent();
            if (object.trucoPlayers) {
                if (!Array.isArray(object.trucoPlayers))
                    throw TypeError(".truco.TrucoPlayersEvent.trucoPlayers: array expected");
                message.trucoPlayers = [];
                for (var i = 0; i < object.trucoPlayers.length; ++i) {
                    if (typeof object.trucoPlayers[i] !== "object")
                        throw TypeError(".truco.TrucoPlayersEvent.trucoPlayers: object expected");
                    message.trucoPlayers[i] = $root.truco.TrucoPlayer.fromObject(object.trucoPlayers[i]);
                }
            }
            if (object.playersNum != null)
                message.playersNum = object.playersNum | 0;
            return message;
        };

        /**
         * Creates a plain object from a TrucoPlayersEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof truco.TrucoPlayersEvent
         * @static
         * @param {truco.TrucoPlayersEvent} message TrucoPlayersEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TrucoPlayersEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.trucoPlayers = [];
            if (options.defaults)
                object.playersNum = 0;
            if (message.trucoPlayers && message.trucoPlayers.length) {
                object.trucoPlayers = [];
                for (var j = 0; j < message.trucoPlayers.length; ++j)
                    object.trucoPlayers[j] = $root.truco.TrucoPlayer.toObject(message.trucoPlayers[j], options);
            }
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                object.playersNum = message.playersNum;
            return object;
        };

        /**
         * Converts this TrucoPlayersEvent to JSON.
         * @function toJSON
         * @memberof truco.TrucoPlayersEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TrucoPlayersEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TrucoPlayersEvent;
    })();

    truco.TrucoPlayer = (function() {

        /**
         * Properties of a TrucoPlayer.
         * @memberof truco
         * @interface ITrucoPlayer
         * @property {number|null} [seat] TrucoPlayer seat
         * @property {base.IPlayerBaseInfo|null} [baseInfo] TrucoPlayer baseInfo
         * @property {boolean|null} [online] TrucoPlayer online
         * @property {number|Long|null} [balance] TrucoPlayer balance
         * @property {Array.<string>|null} [betList] TrucoPlayer betList
         */

        /**
         * Constructs a new TrucoPlayer.
         * @memberof truco
         * @classdesc Represents a TrucoPlayer.
         * @implements ITrucoPlayer
         * @constructor
         * @param {truco.ITrucoPlayer=} [properties] Properties to set
         */
        function TrucoPlayer(properties) {
            this.betList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TrucoPlayer seat.
         * @member {number} seat
         * @memberof truco.TrucoPlayer
         * @instance
         */
        TrucoPlayer.prototype.seat = 0;

        /**
         * TrucoPlayer baseInfo.
         * @member {base.IPlayerBaseInfo|null|undefined} baseInfo
         * @memberof truco.TrucoPlayer
         * @instance
         */
        TrucoPlayer.prototype.baseInfo = null;

        /**
         * TrucoPlayer online.
         * @member {boolean} online
         * @memberof truco.TrucoPlayer
         * @instance
         */
        TrucoPlayer.prototype.online = false;

        /**
         * TrucoPlayer balance.
         * @member {number|Long} balance
         * @memberof truco.TrucoPlayer
         * @instance
         */
        TrucoPlayer.prototype.balance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TrucoPlayer betList.
         * @member {Array.<string>} betList
         * @memberof truco.TrucoPlayer
         * @instance
         */
        TrucoPlayer.prototype.betList = $util.emptyArray;

        /**
         * Creates a new TrucoPlayer instance using the specified properties.
         * @function create
         * @memberof truco.TrucoPlayer
         * @static
         * @param {truco.ITrucoPlayer=} [properties] Properties to set
         * @returns {truco.TrucoPlayer} TrucoPlayer instance
         */
        TrucoPlayer.create = function create(properties) {
            return new TrucoPlayer(properties);
        };

        /**
         * Encodes the specified TrucoPlayer message. Does not implicitly {@link truco.TrucoPlayer.verify|verify} messages.
         * @function encode
         * @memberof truco.TrucoPlayer
         * @static
         * @param {truco.ITrucoPlayer} message TrucoPlayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TrucoPlayer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            if (message.baseInfo != null && Object.hasOwnProperty.call(message, "baseInfo"))
                $root.base.PlayerBaseInfo.encode(message.baseInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.online != null && Object.hasOwnProperty.call(message, "online"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.online);
            if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.balance);
            if (message.betList != null && message.betList.length)
                for (var i = 0; i < message.betList.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.betList[i]);
            return writer;
        };

        /**
         * Encodes the specified TrucoPlayer message, length delimited. Does not implicitly {@link truco.TrucoPlayer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof truco.TrucoPlayer
         * @static
         * @param {truco.ITrucoPlayer} message TrucoPlayer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TrucoPlayer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TrucoPlayer message from the specified reader or buffer.
         * @function decode
         * @memberof truco.TrucoPlayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {truco.TrucoPlayer} TrucoPlayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TrucoPlayer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.truco.TrucoPlayer();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                case 2:
                    message.baseInfo = $root.base.PlayerBaseInfo.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.online = reader.bool();
                    break;
                case 4:
                    message.balance = reader.int64();
                    break;
                case 5:
                    if (!(message.betList && message.betList.length))
                        message.betList = [];
                    message.betList.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TrucoPlayer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof truco.TrucoPlayer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {truco.TrucoPlayer} TrucoPlayer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TrucoPlayer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TrucoPlayer message.
         * @function verify
         * @memberof truco.TrucoPlayer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TrucoPlayer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo")) {
                var error = $root.base.PlayerBaseInfo.verify(message.baseInfo);
                if (error)
                    return "baseInfo." + error;
            }
            if (message.online != null && message.hasOwnProperty("online"))
                if (typeof message.online !== "boolean")
                    return "online: boolean expected";
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (!$util.isInteger(message.balance) && !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high)))
                    return "balance: integer|Long expected";
            if (message.betList != null && message.hasOwnProperty("betList")) {
                if (!Array.isArray(message.betList))
                    return "betList: array expected";
                for (var i = 0; i < message.betList.length; ++i)
                    if (!$util.isString(message.betList[i]))
                        return "betList: string[] expected";
            }
            return null;
        };

        /**
         * Creates a TrucoPlayer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof truco.TrucoPlayer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {truco.TrucoPlayer} TrucoPlayer
         */
        TrucoPlayer.fromObject = function fromObject(object) {
            if (object instanceof $root.truco.TrucoPlayer)
                return object;
            var message = new $root.truco.TrucoPlayer();
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.baseInfo != null) {
                if (typeof object.baseInfo !== "object")
                    throw TypeError(".truco.TrucoPlayer.baseInfo: object expected");
                message.baseInfo = $root.base.PlayerBaseInfo.fromObject(object.baseInfo);
            }
            if (object.online != null)
                message.online = Boolean(object.online);
            if (object.balance != null)
                if ($util.Long)
                    (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
                else if (typeof object.balance === "string")
                    message.balance = parseInt(object.balance, 10);
                else if (typeof object.balance === "number")
                    message.balance = object.balance;
                else if (typeof object.balance === "object")
                    message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
            if (object.betList) {
                if (!Array.isArray(object.betList))
                    throw TypeError(".truco.TrucoPlayer.betList: array expected");
                message.betList = [];
                for (var i = 0; i < object.betList.length; ++i)
                    message.betList[i] = String(object.betList[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a TrucoPlayer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof truco.TrucoPlayer
         * @static
         * @param {truco.TrucoPlayer} message TrucoPlayer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TrucoPlayer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.betList = [];
            if (options.defaults) {
                object.seat = 0;
                object.baseInfo = null;
                object.online = false;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.balance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.balance = options.longs === String ? "0" : 0;
            }
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.baseInfo != null && message.hasOwnProperty("baseInfo"))
                object.baseInfo = $root.base.PlayerBaseInfo.toObject(message.baseInfo, options);
            if (message.online != null && message.hasOwnProperty("online"))
                object.online = message.online;
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (typeof message.balance === "number")
                    object.balance = options.longs === String ? String(message.balance) : message.balance;
                else
                    object.balance = options.longs === String ? $util.Long.prototype.toString.call(message.balance) : options.longs === Number ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber() : message.balance;
            if (message.betList && message.betList.length) {
                object.betList = [];
                for (var j = 0; j < message.betList.length; ++j)
                    object.betList[j] = message.betList[j];
            }
            return object;
        };

        /**
         * Converts this TrucoPlayer to JSON.
         * @function toJSON
         * @memberof truco.TrucoPlayer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TrucoPlayer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TrucoPlayer;
    })();

    truco.TableStateEvent = (function() {

        /**
         * Properties of a TableStateEvent.
         * @memberof truco
         * @interface ITableStateEvent
         * @property {string|null} [gameCode] TableStateEvent gameCode
         * @property {number|null} [playersNum] TableStateEvent playersNum
         * @property {string|null} [hashRound] TableStateEvent hashRound
         * @property {number|null} [result] TableStateEvent result
         * @property {Array.<number>|null} [cards] TableStateEvent cards
         * @property {Array.<string>|null} [betList] TableStateEvent betList
         * @property {number|null} [keyCard] TableStateEvent keyCard
         */

        /**
         * Constructs a new TableStateEvent.
         * @memberof truco
         * @classdesc Represents a TableStateEvent.
         * @implements ITableStateEvent
         * @constructor
         * @param {truco.ITableStateEvent=} [properties] Properties to set
         */
        function TableStateEvent(properties) {
            this.cards = [];
            this.betList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TableStateEvent gameCode.
         * @member {string} gameCode
         * @memberof truco.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.gameCode = "";

        /**
         * TableStateEvent playersNum.
         * @member {number} playersNum
         * @memberof truco.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.playersNum = 0;

        /**
         * TableStateEvent hashRound.
         * @member {string} hashRound
         * @memberof truco.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.hashRound = "";

        /**
         * TableStateEvent result.
         * @member {number} result
         * @memberof truco.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.result = 0;

        /**
         * TableStateEvent cards.
         * @member {Array.<number>} cards
         * @memberof truco.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.cards = $util.emptyArray;

        /**
         * TableStateEvent betList.
         * @member {Array.<string>} betList
         * @memberof truco.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.betList = $util.emptyArray;

        /**
         * TableStateEvent keyCard.
         * @member {number} keyCard
         * @memberof truco.TableStateEvent
         * @instance
         */
        TableStateEvent.prototype.keyCard = 0;

        /**
         * Creates a new TableStateEvent instance using the specified properties.
         * @function create
         * @memberof truco.TableStateEvent
         * @static
         * @param {truco.ITableStateEvent=} [properties] Properties to set
         * @returns {truco.TableStateEvent} TableStateEvent instance
         */
        TableStateEvent.create = function create(properties) {
            return new TableStateEvent(properties);
        };

        /**
         * Encodes the specified TableStateEvent message. Does not implicitly {@link truco.TableStateEvent.verify|verify} messages.
         * @function encode
         * @memberof truco.TableStateEvent
         * @static
         * @param {truco.ITableStateEvent} message TableStateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableStateEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.playersNum != null && Object.hasOwnProperty.call(message, "playersNum"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.playersNum);
            if (message.hashRound != null && Object.hasOwnProperty.call(message, "hashRound"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.hashRound);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.result);
            if (message.cards != null && message.cards.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (var i = 0; i < message.cards.length; ++i)
                    writer.int32(message.cards[i]);
                writer.ldelim();
            }
            if (message.betList != null && message.betList.length)
                for (var i = 0; i < message.betList.length; ++i)
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.betList[i]);
            if (message.keyCard != null && Object.hasOwnProperty.call(message, "keyCard"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.keyCard);
            return writer;
        };

        /**
         * Encodes the specified TableStateEvent message, length delimited. Does not implicitly {@link truco.TableStateEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof truco.TableStateEvent
         * @static
         * @param {truco.ITableStateEvent} message TableStateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TableStateEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TableStateEvent message from the specified reader or buffer.
         * @function decode
         * @memberof truco.TableStateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {truco.TableStateEvent} TableStateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableStateEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.truco.TableStateEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.playersNum = reader.int32();
                    break;
                case 3:
                    message.hashRound = reader.string();
                    break;
                case 4:
                    message.result = reader.int32();
                    break;
                case 5:
                    if (!(message.cards && message.cards.length))
                        message.cards = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.cards.push(reader.int32());
                    } else
                        message.cards.push(reader.int32());
                    break;
                case 6:
                    if (!(message.betList && message.betList.length))
                        message.betList = [];
                    message.betList.push(reader.string());
                    break;
                case 7:
                    message.keyCard = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TableStateEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof truco.TableStateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {truco.TableStateEvent} TableStateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TableStateEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TableStateEvent message.
         * @function verify
         * @memberof truco.TableStateEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TableStateEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                if (!$util.isInteger(message.playersNum))
                    return "playersNum: integer expected";
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                if (!$util.isString(message.hashRound))
                    return "hashRound: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            if (message.cards != null && message.hasOwnProperty("cards")) {
                if (!Array.isArray(message.cards))
                    return "cards: array expected";
                for (var i = 0; i < message.cards.length; ++i)
                    if (!$util.isInteger(message.cards[i]))
                        return "cards: integer[] expected";
            }
            if (message.betList != null && message.hasOwnProperty("betList")) {
                if (!Array.isArray(message.betList))
                    return "betList: array expected";
                for (var i = 0; i < message.betList.length; ++i)
                    if (!$util.isString(message.betList[i]))
                        return "betList: string[] expected";
            }
            if (message.keyCard != null && message.hasOwnProperty("keyCard"))
                if (!$util.isInteger(message.keyCard))
                    return "keyCard: integer expected";
            return null;
        };

        /**
         * Creates a TableStateEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof truco.TableStateEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {truco.TableStateEvent} TableStateEvent
         */
        TableStateEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.truco.TableStateEvent)
                return object;
            var message = new $root.truco.TableStateEvent();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.playersNum != null)
                message.playersNum = object.playersNum | 0;
            if (object.hashRound != null)
                message.hashRound = String(object.hashRound);
            if (object.result != null)
                message.result = object.result | 0;
            if (object.cards) {
                if (!Array.isArray(object.cards))
                    throw TypeError(".truco.TableStateEvent.cards: array expected");
                message.cards = [];
                for (var i = 0; i < object.cards.length; ++i)
                    message.cards[i] = object.cards[i] | 0;
            }
            if (object.betList) {
                if (!Array.isArray(object.betList))
                    throw TypeError(".truco.TableStateEvent.betList: array expected");
                message.betList = [];
                for (var i = 0; i < object.betList.length; ++i)
                    message.betList[i] = String(object.betList[i]);
            }
            if (object.keyCard != null)
                message.keyCard = object.keyCard | 0;
            return message;
        };

        /**
         * Creates a plain object from a TableStateEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof truco.TableStateEvent
         * @static
         * @param {truco.TableStateEvent} message TableStateEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TableStateEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.cards = [];
                object.betList = [];
            }
            if (options.defaults) {
                object.gameCode = "";
                object.playersNum = 0;
                object.hashRound = "";
                object.result = 0;
                object.keyCard = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.playersNum != null && message.hasOwnProperty("playersNum"))
                object.playersNum = message.playersNum;
            if (message.hashRound != null && message.hasOwnProperty("hashRound"))
                object.hashRound = message.hashRound;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.cards && message.cards.length) {
                object.cards = [];
                for (var j = 0; j < message.cards.length; ++j)
                    object.cards[j] = message.cards[j];
            }
            if (message.betList && message.betList.length) {
                object.betList = [];
                for (var j = 0; j < message.betList.length; ++j)
                    object.betList[j] = message.betList[j];
            }
            if (message.keyCard != null && message.hasOwnProperty("keyCard"))
                object.keyCard = message.keyCard;
            return object;
        };

        /**
         * Converts this TableStateEvent to JSON.
         * @function toJSON
         * @memberof truco.TableStateEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TableStateEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TableStateEvent;
    })();

    truco.HistoryResultEvent = (function() {

        /**
         * Properties of a HistoryResultEvent.
         * @memberof truco
         * @interface IHistoryResultEvent
         * @property {Array.<truco.IHistoryResult>|null} [historyResults] HistoryResultEvent historyResults
         */

        /**
         * Constructs a new HistoryResultEvent.
         * @memberof truco
         * @classdesc Represents a HistoryResultEvent.
         * @implements IHistoryResultEvent
         * @constructor
         * @param {truco.IHistoryResultEvent=} [properties] Properties to set
         */
        function HistoryResultEvent(properties) {
            this.historyResults = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResultEvent historyResults.
         * @member {Array.<truco.IHistoryResult>} historyResults
         * @memberof truco.HistoryResultEvent
         * @instance
         */
        HistoryResultEvent.prototype.historyResults = $util.emptyArray;

        /**
         * Creates a new HistoryResultEvent instance using the specified properties.
         * @function create
         * @memberof truco.HistoryResultEvent
         * @static
         * @param {truco.IHistoryResultEvent=} [properties] Properties to set
         * @returns {truco.HistoryResultEvent} HistoryResultEvent instance
         */
        HistoryResultEvent.create = function create(properties) {
            return new HistoryResultEvent(properties);
        };

        /**
         * Encodes the specified HistoryResultEvent message. Does not implicitly {@link truco.HistoryResultEvent.verify|verify} messages.
         * @function encode
         * @memberof truco.HistoryResultEvent
         * @static
         * @param {truco.IHistoryResultEvent} message HistoryResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResultEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.historyResults != null && message.historyResults.length)
                for (var i = 0; i < message.historyResults.length; ++i)
                    $root.truco.HistoryResult.encode(message.historyResults[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HistoryResultEvent message, length delimited. Does not implicitly {@link truco.HistoryResultEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof truco.HistoryResultEvent
         * @static
         * @param {truco.IHistoryResultEvent} message HistoryResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResultEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResultEvent message from the specified reader or buffer.
         * @function decode
         * @memberof truco.HistoryResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {truco.HistoryResultEvent} HistoryResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResultEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.truco.HistoryResultEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.historyResults && message.historyResults.length))
                        message.historyResults = [];
                    message.historyResults.push($root.truco.HistoryResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResultEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof truco.HistoryResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {truco.HistoryResultEvent} HistoryResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResultEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResultEvent message.
         * @function verify
         * @memberof truco.HistoryResultEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResultEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.historyResults != null && message.hasOwnProperty("historyResults")) {
                if (!Array.isArray(message.historyResults))
                    return "historyResults: array expected";
                for (var i = 0; i < message.historyResults.length; ++i) {
                    var error = $root.truco.HistoryResult.verify(message.historyResults[i]);
                    if (error)
                        return "historyResults." + error;
                }
            }
            return null;
        };

        /**
         * Creates a HistoryResultEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof truco.HistoryResultEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {truco.HistoryResultEvent} HistoryResultEvent
         */
        HistoryResultEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.truco.HistoryResultEvent)
                return object;
            var message = new $root.truco.HistoryResultEvent();
            if (object.historyResults) {
                if (!Array.isArray(object.historyResults))
                    throw TypeError(".truco.HistoryResultEvent.historyResults: array expected");
                message.historyResults = [];
                for (var i = 0; i < object.historyResults.length; ++i) {
                    if (typeof object.historyResults[i] !== "object")
                        throw TypeError(".truco.HistoryResultEvent.historyResults: object expected");
                    message.historyResults[i] = $root.truco.HistoryResult.fromObject(object.historyResults[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a HistoryResultEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof truco.HistoryResultEvent
         * @static
         * @param {truco.HistoryResultEvent} message HistoryResultEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResultEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.historyResults = [];
            if (message.historyResults && message.historyResults.length) {
                object.historyResults = [];
                for (var j = 0; j < message.historyResults.length; ++j)
                    object.historyResults[j] = $root.truco.HistoryResult.toObject(message.historyResults[j], options);
            }
            return object;
        };

        /**
         * Converts this HistoryResultEvent to JSON.
         * @function toJSON
         * @memberof truco.HistoryResultEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResultEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResultEvent;
    })();

    truco.HistoryResult = (function() {

        /**
         * Properties of a HistoryResult.
         * @memberof truco
         * @interface IHistoryResult
         * @property {string|null} [gameCode] HistoryResult gameCode
         * @property {number|null} [result] HistoryResult result
         */

        /**
         * Constructs a new HistoryResult.
         * @memberof truco
         * @classdesc Represents a HistoryResult.
         * @implements IHistoryResult
         * @constructor
         * @param {truco.IHistoryResult=} [properties] Properties to set
         */
        function HistoryResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HistoryResult gameCode.
         * @member {string} gameCode
         * @memberof truco.HistoryResult
         * @instance
         */
        HistoryResult.prototype.gameCode = "";

        /**
         * HistoryResult result.
         * @member {number} result
         * @memberof truco.HistoryResult
         * @instance
         */
        HistoryResult.prototype.result = 0;

        /**
         * Creates a new HistoryResult instance using the specified properties.
         * @function create
         * @memberof truco.HistoryResult
         * @static
         * @param {truco.IHistoryResult=} [properties] Properties to set
         * @returns {truco.HistoryResult} HistoryResult instance
         */
        HistoryResult.create = function create(properties) {
            return new HistoryResult(properties);
        };

        /**
         * Encodes the specified HistoryResult message. Does not implicitly {@link truco.HistoryResult.verify|verify} messages.
         * @function encode
         * @memberof truco.HistoryResult
         * @static
         * @param {truco.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.gameCode);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified HistoryResult message, length delimited. Does not implicitly {@link truco.HistoryResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof truco.HistoryResult
         * @static
         * @param {truco.IHistoryResult} message HistoryResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HistoryResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer.
         * @function decode
         * @memberof truco.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {truco.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.truco.HistoryResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.gameCode = reader.string();
                    break;
                case 2:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HistoryResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof truco.HistoryResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {truco.HistoryResult} HistoryResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HistoryResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HistoryResult message.
         * @function verify
         * @memberof truco.HistoryResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HistoryResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            return null;
        };

        /**
         * Creates a HistoryResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof truco.HistoryResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {truco.HistoryResult} HistoryResult
         */
        HistoryResult.fromObject = function fromObject(object) {
            if (object instanceof $root.truco.HistoryResult)
                return object;
            var message = new $root.truco.HistoryResult();
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result != null)
                message.result = object.result | 0;
            return message;
        };

        /**
         * Creates a plain object from a HistoryResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof truco.HistoryResult
         * @static
         * @param {truco.HistoryResult} message HistoryResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HistoryResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.gameCode = "";
                object.result = 0;
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };

        /**
         * Converts this HistoryResult to JSON.
         * @function toJSON
         * @memberof truco.HistoryResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HistoryResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HistoryResult;
    })();

    truco.BetReq = (function() {

        /**
         * Properties of a BetReq.
         * @memberof truco
         * @interface IBetReq
         * @property {number|null} [currencyType] BetReq currencyType
         * @property {number|Long|null} [betAmount] BetReq betAmount
         * @property {number|null} [betArea] BetReq betArea
         */

        /**
         * Constructs a new BetReq.
         * @memberof truco
         * @classdesc Represents a BetReq.
         * @implements IBetReq
         * @constructor
         * @param {truco.IBetReq=} [properties] Properties to set
         */
        function BetReq(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BetReq currencyType.
         * @member {number} currencyType
         * @memberof truco.BetReq
         * @instance
         */
        BetReq.prototype.currencyType = 0;

        /**
         * BetReq betAmount.
         * @member {number|Long} betAmount
         * @memberof truco.BetReq
         * @instance
         */
        BetReq.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BetReq betArea.
         * @member {number} betArea
         * @memberof truco.BetReq
         * @instance
         */
        BetReq.prototype.betArea = 0;

        /**
         * Creates a new BetReq instance using the specified properties.
         * @function create
         * @memberof truco.BetReq
         * @static
         * @param {truco.IBetReq=} [properties] Properties to set
         * @returns {truco.BetReq} BetReq instance
         */
        BetReq.create = function create(properties) {
            return new BetReq(properties);
        };

        /**
         * Encodes the specified BetReq message. Does not implicitly {@link truco.BetReq.verify|verify} messages.
         * @function encode
         * @memberof truco.BetReq
         * @static
         * @param {truco.IBetReq} message BetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.betAmount);
            if (message.betArea != null && Object.hasOwnProperty.call(message, "betArea"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.betArea);
            return writer;
        };

        /**
         * Encodes the specified BetReq message, length delimited. Does not implicitly {@link truco.BetReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof truco.BetReq
         * @static
         * @param {truco.IBetReq} message BetReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BetReq message from the specified reader or buffer.
         * @function decode
         * @memberof truco.BetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {truco.BetReq} BetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.truco.BetReq();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.currencyType = reader.int32();
                    break;
                case 2:
                    message.betAmount = reader.int64();
                    break;
                case 3:
                    message.betArea = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BetReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof truco.BetReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {truco.BetReq} BetReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BetReq message.
         * @function verify
         * @memberof truco.BetReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BetReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.betArea != null && message.hasOwnProperty("betArea"))
                if (!$util.isInteger(message.betArea))
                    return "betArea: integer expected";
            return null;
        };

        /**
         * Creates a BetReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof truco.BetReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {truco.BetReq} BetReq
         */
        BetReq.fromObject = function fromObject(object) {
            if (object instanceof $root.truco.BetReq)
                return object;
            var message = new $root.truco.BetReq();
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.betArea != null)
                message.betArea = object.betArea | 0;
            return message;
        };

        /**
         * Creates a plain object from a BetReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof truco.BetReq
         * @static
         * @param {truco.BetReq} message BetReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BetReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.betArea = 0;
            }
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.betArea != null && message.hasOwnProperty("betArea"))
                object.betArea = message.betArea;
            return object;
        };

        /**
         * Converts this BetReq to JSON.
         * @function toJSON
         * @memberof truco.BetReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BetReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BetReq;
    })();

    truco.BetEvent = (function() {

        /**
         * Properties of a BetEvent.
         * @memberof truco
         * @interface IBetEvent
         * @property {number|null} [seat] BetEvent seat
         * @property {number|null} [currencyType] BetEvent currencyType
         * @property {number|Long|null} [betAmount] BetEvent betAmount
         * @property {number|null} [betArea] BetEvent betArea
         */

        /**
         * Constructs a new BetEvent.
         * @memberof truco
         * @classdesc Represents a BetEvent.
         * @implements IBetEvent
         * @constructor
         * @param {truco.IBetEvent=} [properties] Properties to set
         */
        function BetEvent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BetEvent seat.
         * @member {number} seat
         * @memberof truco.BetEvent
         * @instance
         */
        BetEvent.prototype.seat = 0;

        /**
         * BetEvent currencyType.
         * @member {number} currencyType
         * @memberof truco.BetEvent
         * @instance
         */
        BetEvent.prototype.currencyType = 0;

        /**
         * BetEvent betAmount.
         * @member {number|Long} betAmount
         * @memberof truco.BetEvent
         * @instance
         */
        BetEvent.prototype.betAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BetEvent betArea.
         * @member {number} betArea
         * @memberof truco.BetEvent
         * @instance
         */
        BetEvent.prototype.betArea = 0;

        /**
         * Creates a new BetEvent instance using the specified properties.
         * @function create
         * @memberof truco.BetEvent
         * @static
         * @param {truco.IBetEvent=} [properties] Properties to set
         * @returns {truco.BetEvent} BetEvent instance
         */
        BetEvent.create = function create(properties) {
            return new BetEvent(properties);
        };

        /**
         * Encodes the specified BetEvent message. Does not implicitly {@link truco.BetEvent.verify|verify} messages.
         * @function encode
         * @memberof truco.BetEvent
         * @static
         * @param {truco.IBetEvent} message BetEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.seat != null && Object.hasOwnProperty.call(message, "seat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.seat);
            if (message.currencyType != null && Object.hasOwnProperty.call(message, "currencyType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.currencyType);
            if (message.betAmount != null && Object.hasOwnProperty.call(message, "betAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.betAmount);
            if (message.betArea != null && Object.hasOwnProperty.call(message, "betArea"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.betArea);
            return writer;
        };

        /**
         * Encodes the specified BetEvent message, length delimited. Does not implicitly {@link truco.BetEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof truco.BetEvent
         * @static
         * @param {truco.IBetEvent} message BetEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BetEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BetEvent message from the specified reader or buffer.
         * @function decode
         * @memberof truco.BetEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {truco.BetEvent} BetEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.truco.BetEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.seat = reader.int32();
                    break;
                case 2:
                    message.currencyType = reader.int32();
                    break;
                case 3:
                    message.betAmount = reader.int64();
                    break;
                case 4:
                    message.betArea = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BetEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof truco.BetEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {truco.BetEvent} BetEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BetEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BetEvent message.
         * @function verify
         * @memberof truco.BetEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BetEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.seat != null && message.hasOwnProperty("seat"))
                if (!$util.isInteger(message.seat))
                    return "seat: integer expected";
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                if (!$util.isInteger(message.currencyType))
                    return "currencyType: integer expected";
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (!$util.isInteger(message.betAmount) && !(message.betAmount && $util.isInteger(message.betAmount.low) && $util.isInteger(message.betAmount.high)))
                    return "betAmount: integer|Long expected";
            if (message.betArea != null && message.hasOwnProperty("betArea"))
                if (!$util.isInteger(message.betArea))
                    return "betArea: integer expected";
            return null;
        };

        /**
         * Creates a BetEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof truco.BetEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {truco.BetEvent} BetEvent
         */
        BetEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.truco.BetEvent)
                return object;
            var message = new $root.truco.BetEvent();
            if (object.seat != null)
                message.seat = object.seat | 0;
            if (object.currencyType != null)
                message.currencyType = object.currencyType | 0;
            if (object.betAmount != null)
                if ($util.Long)
                    (message.betAmount = $util.Long.fromValue(object.betAmount)).unsigned = false;
                else if (typeof object.betAmount === "string")
                    message.betAmount = parseInt(object.betAmount, 10);
                else if (typeof object.betAmount === "number")
                    message.betAmount = object.betAmount;
                else if (typeof object.betAmount === "object")
                    message.betAmount = new $util.LongBits(object.betAmount.low >>> 0, object.betAmount.high >>> 0).toNumber();
            if (object.betArea != null)
                message.betArea = object.betArea | 0;
            return message;
        };

        /**
         * Creates a plain object from a BetEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof truco.BetEvent
         * @static
         * @param {truco.BetEvent} message BetEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BetEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.seat = 0;
                object.currencyType = 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.betAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.betAmount = options.longs === String ? "0" : 0;
                object.betArea = 0;
            }
            if (message.seat != null && message.hasOwnProperty("seat"))
                object.seat = message.seat;
            if (message.currencyType != null && message.hasOwnProperty("currencyType"))
                object.currencyType = message.currencyType;
            if (message.betAmount != null && message.hasOwnProperty("betAmount"))
                if (typeof message.betAmount === "number")
                    object.betAmount = options.longs === String ? String(message.betAmount) : message.betAmount;
                else
                    object.betAmount = options.longs === String ? $util.Long.prototype.toString.call(message.betAmount) : options.longs === Number ? new $util.LongBits(message.betAmount.low >>> 0, message.betAmount.high >>> 0).toNumber() : message.betAmount;
            if (message.betArea != null && message.hasOwnProperty("betArea"))
                object.betArea = message.betArea;
            return object;
        };

        /**
         * Converts this BetEvent to JSON.
         * @function toJSON
         * @memberof truco.BetEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BetEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BetEvent;
    })();

    truco.PlayerListEvent = (function() {

        /**
         * Properties of a PlayerListEvent.
         * @memberof truco
         * @interface IPlayerListEvent
         * @property {Array.<truco.IPlayerInfo>|null} [infos] PlayerListEvent infos
         */

        /**
         * Constructs a new PlayerListEvent.
         * @memberof truco
         * @classdesc Represents a PlayerListEvent.
         * @implements IPlayerListEvent
         * @constructor
         * @param {truco.IPlayerListEvent=} [properties] Properties to set
         */
        function PlayerListEvent(properties) {
            this.infos = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerListEvent infos.
         * @member {Array.<truco.IPlayerInfo>} infos
         * @memberof truco.PlayerListEvent
         * @instance
         */
        PlayerListEvent.prototype.infos = $util.emptyArray;

        /**
         * Creates a new PlayerListEvent instance using the specified properties.
         * @function create
         * @memberof truco.PlayerListEvent
         * @static
         * @param {truco.IPlayerListEvent=} [properties] Properties to set
         * @returns {truco.PlayerListEvent} PlayerListEvent instance
         */
        PlayerListEvent.create = function create(properties) {
            return new PlayerListEvent(properties);
        };

        /**
         * Encodes the specified PlayerListEvent message. Does not implicitly {@link truco.PlayerListEvent.verify|verify} messages.
         * @function encode
         * @memberof truco.PlayerListEvent
         * @static
         * @param {truco.IPlayerListEvent} message PlayerListEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerListEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.infos != null && message.infos.length)
                for (var i = 0; i < message.infos.length; ++i)
                    $root.truco.PlayerInfo.encode(message.infos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PlayerListEvent message, length delimited. Does not implicitly {@link truco.PlayerListEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof truco.PlayerListEvent
         * @static
         * @param {truco.IPlayerListEvent} message PlayerListEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerListEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerListEvent message from the specified reader or buffer.
         * @function decode
         * @memberof truco.PlayerListEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {truco.PlayerListEvent} PlayerListEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerListEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.truco.PlayerListEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.infos && message.infos.length))
                        message.infos = [];
                    message.infos.push($root.truco.PlayerInfo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerListEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof truco.PlayerListEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {truco.PlayerListEvent} PlayerListEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerListEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerListEvent message.
         * @function verify
         * @memberof truco.PlayerListEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerListEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.infos != null && message.hasOwnProperty("infos")) {
                if (!Array.isArray(message.infos))
                    return "infos: array expected";
                for (var i = 0; i < message.infos.length; ++i) {
                    var error = $root.truco.PlayerInfo.verify(message.infos[i]);
                    if (error)
                        return "infos." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PlayerListEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof truco.PlayerListEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {truco.PlayerListEvent} PlayerListEvent
         */
        PlayerListEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.truco.PlayerListEvent)
                return object;
            var message = new $root.truco.PlayerListEvent();
            if (object.infos) {
                if (!Array.isArray(object.infos))
                    throw TypeError(".truco.PlayerListEvent.infos: array expected");
                message.infos = [];
                for (var i = 0; i < object.infos.length; ++i) {
                    if (typeof object.infos[i] !== "object")
                        throw TypeError(".truco.PlayerListEvent.infos: object expected");
                    message.infos[i] = $root.truco.PlayerInfo.fromObject(object.infos[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PlayerListEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof truco.PlayerListEvent
         * @static
         * @param {truco.PlayerListEvent} message PlayerListEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerListEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.infos = [];
            if (message.infos && message.infos.length) {
                object.infos = [];
                for (var j = 0; j < message.infos.length; ++j)
                    object.infos[j] = $root.truco.PlayerInfo.toObject(message.infos[j], options);
            }
            return object;
        };

        /**
         * Converts this PlayerListEvent to JSON.
         * @function toJSON
         * @memberof truco.PlayerListEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerListEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerListEvent;
    })();

    truco.PlayerInfo = (function() {

        /**
         * Properties of a PlayerInfo.
         * @memberof truco
         * @interface IPlayerInfo
         * @property {number|Long|null} [playerId] PlayerInfo playerId
         * @property {string|null} [nickname] PlayerInfo nickname
         * @property {string|null} [headUrl] PlayerInfo headUrl
         * @property {number|null} [level] PlayerInfo level
         * @property {string|null} [betInfo] PlayerInfo betInfo
         */

        /**
         * Constructs a new PlayerInfo.
         * @memberof truco
         * @classdesc Represents a PlayerInfo.
         * @implements IPlayerInfo
         * @constructor
         * @param {truco.IPlayerInfo=} [properties] Properties to set
         */
        function PlayerInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerInfo playerId.
         * @member {number|Long} playerId
         * @memberof truco.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.playerId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PlayerInfo nickname.
         * @member {string} nickname
         * @memberof truco.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.nickname = "";

        /**
         * PlayerInfo headUrl.
         * @member {string} headUrl
         * @memberof truco.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.headUrl = "";

        /**
         * PlayerInfo level.
         * @member {number} level
         * @memberof truco.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.level = 0;

        /**
         * PlayerInfo betInfo.
         * @member {string} betInfo
         * @memberof truco.PlayerInfo
         * @instance
         */
        PlayerInfo.prototype.betInfo = "";

        /**
         * Creates a new PlayerInfo instance using the specified properties.
         * @function create
         * @memberof truco.PlayerInfo
         * @static
         * @param {truco.IPlayerInfo=} [properties] Properties to set
         * @returns {truco.PlayerInfo} PlayerInfo instance
         */
        PlayerInfo.create = function create(properties) {
            return new PlayerInfo(properties);
        };

        /**
         * Encodes the specified PlayerInfo message. Does not implicitly {@link truco.PlayerInfo.verify|verify} messages.
         * @function encode
         * @memberof truco.PlayerInfo
         * @static
         * @param {truco.IPlayerInfo} message PlayerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.playerId != null && Object.hasOwnProperty.call(message, "playerId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.playerId);
            if (message.nickname != null && Object.hasOwnProperty.call(message, "nickname"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.nickname);
            if (message.headUrl != null && Object.hasOwnProperty.call(message, "headUrl"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.headUrl);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.level);
            if (message.betInfo != null && Object.hasOwnProperty.call(message, "betInfo"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.betInfo);
            return writer;
        };

        /**
         * Encodes the specified PlayerInfo message, length delimited. Does not implicitly {@link truco.PlayerInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof truco.PlayerInfo
         * @static
         * @param {truco.IPlayerInfo} message PlayerInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerInfo message from the specified reader or buffer.
         * @function decode
         * @memberof truco.PlayerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {truco.PlayerInfo} PlayerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.truco.PlayerInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.playerId = reader.uint64();
                    break;
                case 2:
                    message.nickname = reader.string();
                    break;
                case 3:
                    message.headUrl = reader.string();
                    break;
                case 4:
                    message.level = reader.int32();
                    break;
                case 5:
                    message.betInfo = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof truco.PlayerInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {truco.PlayerInfo} PlayerInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerInfo message.
         * @function verify
         * @memberof truco.PlayerInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.playerId != null && message.hasOwnProperty("playerId"))
                if (!$util.isInteger(message.playerId) && !(message.playerId && $util.isInteger(message.playerId.low) && $util.isInteger(message.playerId.high)))
                    return "playerId: integer|Long expected";
            if (message.nickname != null && message.hasOwnProperty("nickname"))
                if (!$util.isString(message.nickname))
                    return "nickname: string expected";
            if (message.headUrl != null && message.hasOwnProperty("headUrl"))
                if (!$util.isString(message.headUrl))
                    return "headUrl: string expected";
            if (message.level != null && message.hasOwnProperty("level"))
                if (!$util.isInteger(message.level))
                    return "level: integer expected";
            if (message.betInfo != null && message.hasOwnProperty("betInfo"))
                if (!$util.isString(message.betInfo))
                    return "betInfo: string expected";
            return null;
        };

        /**
         * Creates a PlayerInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof truco.PlayerInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {truco.PlayerInfo} PlayerInfo
         */
        PlayerInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.truco.PlayerInfo)
                return object;
            var message = new $root.truco.PlayerInfo();
            if (object.playerId != null)
                if ($util.Long)
                    (message.playerId = $util.Long.fromValue(object.playerId)).unsigned = true;
                else if (typeof object.playerId === "string")
                    message.playerId = parseInt(object.playerId, 10);
                else if (typeof object.playerId === "number")
                    message.playerId = object.playerId;
                else if (typeof object.playerId === "object")
                    message.playerId = new $util.LongBits(object.playerId.low >>> 0, object.playerId.high >>> 0).toNumber(true);
            if (object.nickname != null)
                message.nickname = String(object.nickname);
            if (object.headUrl != null)
                message.headUrl = String(object.headUrl);
            if (object.level != null)
                message.level = object.level | 0;
            if (object.betInfo != null)
                message.betInfo = String(object.betInfo);
            return message;
        };

        /**
         * Creates a plain object from a PlayerInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof truco.PlayerInfo
         * @static
         * @param {truco.PlayerInfo} message PlayerInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.playerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.playerId = options.longs === String ? "0" : 0;
                object.nickname = "";
                object.headUrl = "";
                object.level = 0;
                object.betInfo = "";
            }
            if (message.playerId != null && message.hasOwnProperty("playerId"))
                if (typeof message.playerId === "number")
                    object.playerId = options.longs === String ? String(message.playerId) : message.playerId;
                else
                    object.playerId = options.longs === String ? $util.Long.prototype.toString.call(message.playerId) : options.longs === Number ? new $util.LongBits(message.playerId.low >>> 0, message.playerId.high >>> 0).toNumber(true) : message.playerId;
            if (message.nickname != null && message.hasOwnProperty("nickname"))
                object.nickname = message.nickname;
            if (message.headUrl != null && message.hasOwnProperty("headUrl"))
                object.headUrl = message.headUrl;
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = message.level;
            if (message.betInfo != null && message.hasOwnProperty("betInfo"))
                object.betInfo = message.betInfo;
            return object;
        };

        /**
         * Converts this PlayerInfo to JSON.
         * @function toJSON
         * @memberof truco.PlayerInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlayerInfo;
    })();

    truco.GameEndEvent = (function() {

        /**
         * Properties of a GameEndEvent.
         * @memberof truco
         * @interface IGameEndEvent
         * @property {number|null} [result] GameEndEvent result
         * @property {Array.<number>|null} [cards] GameEndEvent cards
         * @property {Array.<number>|null} [winResult] GameEndEvent winResult
         */

        /**
         * Constructs a new GameEndEvent.
         * @memberof truco
         * @classdesc Represents a GameEndEvent.
         * @implements IGameEndEvent
         * @constructor
         * @param {truco.IGameEndEvent=} [properties] Properties to set
         */
        function GameEndEvent(properties) {
            this.cards = [];
            this.winResult = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameEndEvent result.
         * @member {number} result
         * @memberof truco.GameEndEvent
         * @instance
         */
        GameEndEvent.prototype.result = 0;

        /**
         * GameEndEvent cards.
         * @member {Array.<number>} cards
         * @memberof truco.GameEndEvent
         * @instance
         */
        GameEndEvent.prototype.cards = $util.emptyArray;

        /**
         * GameEndEvent winResult.
         * @member {Array.<number>} winResult
         * @memberof truco.GameEndEvent
         * @instance
         */
        GameEndEvent.prototype.winResult = $util.emptyArray;

        /**
         * Creates a new GameEndEvent instance using the specified properties.
         * @function create
         * @memberof truco.GameEndEvent
         * @static
         * @param {truco.IGameEndEvent=} [properties] Properties to set
         * @returns {truco.GameEndEvent} GameEndEvent instance
         */
        GameEndEvent.create = function create(properties) {
            return new GameEndEvent(properties);
        };

        /**
         * Encodes the specified GameEndEvent message. Does not implicitly {@link truco.GameEndEvent.verify|verify} messages.
         * @function encode
         * @memberof truco.GameEndEvent
         * @static
         * @param {truco.IGameEndEvent} message GameEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameEndEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.cards != null && message.cards.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (var i = 0; i < message.cards.length; ++i)
                    writer.int32(message.cards[i]);
                writer.ldelim();
            }
            if (message.winResult != null && message.winResult.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.winResult.length; ++i)
                    writer.int32(message.winResult[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified GameEndEvent message, length delimited. Does not implicitly {@link truco.GameEndEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof truco.GameEndEvent
         * @static
         * @param {truco.IGameEndEvent} message GameEndEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameEndEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameEndEvent message from the specified reader or buffer.
         * @function decode
         * @memberof truco.GameEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {truco.GameEndEvent} GameEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameEndEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.truco.GameEndEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                case 2:
                    if (!(message.cards && message.cards.length))
                        message.cards = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.cards.push(reader.int32());
                    } else
                        message.cards.push(reader.int32());
                    break;
                case 3:
                    if (!(message.winResult && message.winResult.length))
                        message.winResult = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.winResult.push(reader.int32());
                    } else
                        message.winResult.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameEndEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof truco.GameEndEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {truco.GameEndEvent} GameEndEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameEndEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameEndEvent message.
         * @function verify
         * @memberof truco.GameEndEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameEndEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isInteger(message.result))
                    return "result: integer expected";
            if (message.cards != null && message.hasOwnProperty("cards")) {
                if (!Array.isArray(message.cards))
                    return "cards: array expected";
                for (var i = 0; i < message.cards.length; ++i)
                    if (!$util.isInteger(message.cards[i]))
                        return "cards: integer[] expected";
            }
            if (message.winResult != null && message.hasOwnProperty("winResult")) {
                if (!Array.isArray(message.winResult))
                    return "winResult: array expected";
                for (var i = 0; i < message.winResult.length; ++i)
                    if (!$util.isInteger(message.winResult[i]))
                        return "winResult: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a GameEndEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof truco.GameEndEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {truco.GameEndEvent} GameEndEvent
         */
        GameEndEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.truco.GameEndEvent)
                return object;
            var message = new $root.truco.GameEndEvent();
            if (object.result != null)
                message.result = object.result | 0;
            if (object.cards) {
                if (!Array.isArray(object.cards))
                    throw TypeError(".truco.GameEndEvent.cards: array expected");
                message.cards = [];
                for (var i = 0; i < object.cards.length; ++i)
                    message.cards[i] = object.cards[i] | 0;
            }
            if (object.winResult) {
                if (!Array.isArray(object.winResult))
                    throw TypeError(".truco.GameEndEvent.winResult: array expected");
                message.winResult = [];
                for (var i = 0; i < object.winResult.length; ++i)
                    message.winResult[i] = object.winResult[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a GameEndEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof truco.GameEndEvent
         * @static
         * @param {truco.GameEndEvent} message GameEndEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameEndEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.cards = [];
                object.winResult = [];
            }
            if (options.defaults)
                object.result = 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            if (message.cards && message.cards.length) {
                object.cards = [];
                for (var j = 0; j < message.cards.length; ++j)
                    object.cards[j] = message.cards[j];
            }
            if (message.winResult && message.winResult.length) {
                object.winResult = [];
                for (var j = 0; j < message.winResult.length; ++j)
                    object.winResult[j] = message.winResult[j];
            }
            return object;
        };

        /**
         * Converts this GameEndEvent to JSON.
         * @function toJSON
         * @memberof truco.GameEndEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameEndEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameEndEvent;
    })();

    truco.GameResultEvent = (function() {

        /**
         * Properties of a GameResultEvent.
         * @memberof truco
         * @interface IGameResultEvent
         * @property {Array.<string>|null} [winInfo] GameResultEvent winInfo
         * @property {string|null} [gameCode] GameResultEvent gameCode
         * @property {Array.<number>|null} [result] GameResultEvent result
         */

        /**
         * Constructs a new GameResultEvent.
         * @memberof truco
         * @classdesc Represents a GameResultEvent.
         * @implements IGameResultEvent
         * @constructor
         * @param {truco.IGameResultEvent=} [properties] Properties to set
         */
        function GameResultEvent(properties) {
            this.winInfo = [];
            this.result = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GameResultEvent winInfo.
         * @member {Array.<string>} winInfo
         * @memberof truco.GameResultEvent
         * @instance
         */
        GameResultEvent.prototype.winInfo = $util.emptyArray;

        /**
         * GameResultEvent gameCode.
         * @member {string} gameCode
         * @memberof truco.GameResultEvent
         * @instance
         */
        GameResultEvent.prototype.gameCode = "";

        /**
         * GameResultEvent result.
         * @member {Array.<number>} result
         * @memberof truco.GameResultEvent
         * @instance
         */
        GameResultEvent.prototype.result = $util.emptyArray;

        /**
         * Creates a new GameResultEvent instance using the specified properties.
         * @function create
         * @memberof truco.GameResultEvent
         * @static
         * @param {truco.IGameResultEvent=} [properties] Properties to set
         * @returns {truco.GameResultEvent} GameResultEvent instance
         */
        GameResultEvent.create = function create(properties) {
            return new GameResultEvent(properties);
        };

        /**
         * Encodes the specified GameResultEvent message. Does not implicitly {@link truco.GameResultEvent.verify|verify} messages.
         * @function encode
         * @memberof truco.GameResultEvent
         * @static
         * @param {truco.IGameResultEvent} message GameResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameResultEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.winInfo != null && message.winInfo.length)
                for (var i = 0; i < message.winInfo.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.winInfo[i]);
            if (message.gameCode != null && Object.hasOwnProperty.call(message, "gameCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.gameCode);
            if (message.result != null && message.result.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.result.length; ++i)
                    writer.int32(message.result[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified GameResultEvent message, length delimited. Does not implicitly {@link truco.GameResultEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof truco.GameResultEvent
         * @static
         * @param {truco.IGameResultEvent} message GameResultEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GameResultEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GameResultEvent message from the specified reader or buffer.
         * @function decode
         * @memberof truco.GameResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {truco.GameResultEvent} GameResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameResultEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.truco.GameResultEvent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.winInfo && message.winInfo.length))
                        message.winInfo = [];
                    message.winInfo.push(reader.string());
                    break;
                case 2:
                    message.gameCode = reader.string();
                    break;
                case 3:
                    if (!(message.result && message.result.length))
                        message.result = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.result.push(reader.int32());
                    } else
                        message.result.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GameResultEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof truco.GameResultEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {truco.GameResultEvent} GameResultEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GameResultEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GameResultEvent message.
         * @function verify
         * @memberof truco.GameResultEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GameResultEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.winInfo != null && message.hasOwnProperty("winInfo")) {
                if (!Array.isArray(message.winInfo))
                    return "winInfo: array expected";
                for (var i = 0; i < message.winInfo.length; ++i)
                    if (!$util.isString(message.winInfo[i]))
                        return "winInfo: string[] expected";
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                if (!$util.isString(message.gameCode))
                    return "gameCode: string expected";
            if (message.result != null && message.hasOwnProperty("result")) {
                if (!Array.isArray(message.result))
                    return "result: array expected";
                for (var i = 0; i < message.result.length; ++i)
                    if (!$util.isInteger(message.result[i]))
                        return "result: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a GameResultEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof truco.GameResultEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {truco.GameResultEvent} GameResultEvent
         */
        GameResultEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.truco.GameResultEvent)
                return object;
            var message = new $root.truco.GameResultEvent();
            if (object.winInfo) {
                if (!Array.isArray(object.winInfo))
                    throw TypeError(".truco.GameResultEvent.winInfo: array expected");
                message.winInfo = [];
                for (var i = 0; i < object.winInfo.length; ++i)
                    message.winInfo[i] = String(object.winInfo[i]);
            }
            if (object.gameCode != null)
                message.gameCode = String(object.gameCode);
            if (object.result) {
                if (!Array.isArray(object.result))
                    throw TypeError(".truco.GameResultEvent.result: array expected");
                message.result = [];
                for (var i = 0; i < object.result.length; ++i)
                    message.result[i] = object.result[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a GameResultEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof truco.GameResultEvent
         * @static
         * @param {truco.GameResultEvent} message GameResultEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GameResultEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.winInfo = [];
                object.result = [];
            }
            if (options.defaults)
                object.gameCode = "";
            if (message.winInfo && message.winInfo.length) {
                object.winInfo = [];
                for (var j = 0; j < message.winInfo.length; ++j)
                    object.winInfo[j] = message.winInfo[j];
            }
            if (message.gameCode != null && message.hasOwnProperty("gameCode"))
                object.gameCode = message.gameCode;
            if (message.result && message.result.length) {
                object.result = [];
                for (var j = 0; j < message.result.length; ++j)
                    object.result[j] = message.result[j];
            }
            return object;
        };

        /**
         * Converts this GameResultEvent to JSON.
         * @function toJSON
         * @memberof truco.GameResultEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GameResultEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GameResultEvent;
    })();

    return truco;
})();

module.exports = $root;
