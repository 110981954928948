import { IDestroyOptions } from "pixi.js";
import I18NMgr from "./I18NMgr";
import * as PIXI from "pixi.js";

export default class I18NLabel extends PIXI.Text {
    private i18nkey: string = "";

    public constructor(key:string) {
        super();
        this.i18nkey = key;
        I18NMgr.Instance.add(this);
        let str = I18NMgr.Instance.getText(this.i18nkey);
        if (!!str) {
            this.text = `${str}`;
        }
    }

    set Key(key:string){
        this.i18nkey = key;
        this.refresh();
    }

    public destroy(options?: IDestroyOptions | boolean): void {
        super.destroy(options);
        I18NMgr.Instance.remove(this);
    }

    refresh() {
        let str = I18NMgr.Instance.getText(this.i18nkey);
        if (!!str) {
            this.text = `${str}`;
        }
    }
}
