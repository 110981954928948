import {useState} from "react";
import AnimateButton from "ui-component/AnimateButton";
import SvgIcon from "ui-component/SvgIcon";
import {toast} from "react-toastify";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import apiUser from "api/user";
import md5 from "md5";
import {useIntl} from "react-intl";
import {dispatch} from "store";
import {loginSuccess} from "store/slices/user";
import {showDialog} from "store/slices/dialog";
import Loader from "ui-component/Loader";
import GoogleLogin from "./GoogleLogin";
import MetaMaskLogin from "./MetaMaskLogin";
import TelegramLogin from "./TelegramLogin";

interface Props {
    onChangeLoginType?: (type: string) => void;
}

const SignIn = ({onChangeLoginType}: Props) => {
    const {$t} = useIntl();
    const [showPass, setShowPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email($t({id: "EmailDomainNotSpported"}))
            .required($t({id: "ThisFieldIsRequired"})),
        password: Yup.string()
            .min(8, $t({id: "YourPasswordLeast8Long"}))
            .required($t({id: "ThisFieldIsRequired"})),
    });

    const initialValues = {
        email: "",
        password: "",
    };

    const switchType = (val: string) => {
        onChangeLoginType && onChangeLoginType(val);
    };

    const getErrorElement = (errorMessage: string) => {
        return (
            <div className="flex items-center pt-2 pb-1 text-[#F2708A] text-xs">
                <SvgIcon dataKey="icon-error" className="w-3 h-3 mr-2" style={{fill: "#F2708A"}} />
                <span>{errorMessage}</span>
            </div>
        );
    };

    const handleSubmit = async (values: {email: string; password: string}) => {
        if (isLoading) return;
        setIsLoading(true);
        const {email, password} = values;
        try {
            const res = await apiUser.login(email, md5(password));
            const {status, data} = res;
            if (status === 200 && data.code === 0) {
                dispatch(loginSuccess(data.data));
                dispatch(showDialog(null));
                return;
            }
            if (data.code === 101) {
                toast.error($t({id: "SystemError"}));
                return;
            }
            toast.error(data.code ? $t({id: `LOGIN_ERROR_${data.code}`}) : data.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="font-semibold">
            <div className="text-lg text-white text-center font-semibold">{$t({id: "SignIn"})}</div>
            <div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {() => (
                        <Form>
                            <div className="mt-4">
                                <div>
                                    <label htmlFor="email">
                                        {$t({id: "Email"})}
                                        <span className="text-[#F2708A] ml-1">*</span>
                                    </label>
                                </div>

                                <Field
                                    type="text"
                                    name="email"
                                    className="mt-1 w-full h-[40px] text-white bg-[#0f212e] rounded outline-none p-2 border-2 border-solid border-[#2f4553] focus:border-[#557086]"
                                />
                                <ErrorMessage name="email">{getErrorElement}</ErrorMessage>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <label htmlFor="password">
                                        {$t({id: "Password"})}
                                        <span className="text-[#F2708A] ml-1">*</span>
                                    </label>
                                </div>
                                <div className="relative">
                                    <Field
                                        type={showPass ? "text" : "password"}
                                        name="password"
                                        className="mt-1 w-full h-[40px] text-white bg-[#0f212e] rounded outline-none p-2 border-2 border-solid border-[#2f4553] focus:border-[#557086]"
                                    />
                                    <div className="absolute top-1 right-0 w-12 h-10 flex items-center justify-center">
                                        <AnimateButton>
                                            <SvgIcon
                                                dataKey="icon-eye"
                                                className=" w-4 h-4"
                                                style={{fill: showPass ? "#b1bad3" : "#ffffff"}}
                                                onClick={() => {
                                                    setShowPass(!showPass);
                                                }}
                                            />
                                        </AnimateButton>
                                    </div>
                                </div>

                                <ErrorMessage name="password">{getErrorElement}</ErrorMessage>
                            </div>

                            <AnimateButton>
                                <button
                                    type="submit"
                                    className="w-full h-12 mt-4 flex font-semibold items-center justify-center text-[#05080a] text-base bg-[#00e701] rounded"
                                    style={{boxShadow: "0rem .0625rem .1875rem #00000033 , 0rem .0625rem .125rem #0000001f"}}>
                                    {isLoading ? <Loader /> : $t({id: "SignIn"})}
                                </button>
                            </AnimateButton>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="w-full h-5 flex items-center justify-center mt-4">
                <div className="w-16 h-[1px] bg-[#2f4553]"></div>
                <div className="text-sm text-[#b1bad3] px-4">{$t({id: "OrUse"})}</div>
                <div className="w-16 h-[1px] bg-[#2f4553]"></div>
            </div>
            <div className="w-full mt-4 grid grid-cols-3 gap-2">
                <AnimateButton>
                    <MetaMaskLogin />
                </AnimateButton>
                <AnimateButton>
                    <GoogleLogin />
                </AnimateButton>
                <AnimateButton>
                    <TelegramLogin />
                </AnimateButton>
            </div>

            <div className="mt-4 text-center text-white text-sm font-semibold">
                <AnimateButton>
                    <span
                        className="cursor-pointer"
                        onClick={() => {
                            switchType("forget");
                        }}>
                        {$t({id: "ForgotPassword"})}
                    </span>
                </AnimateButton>
            </div>
            <div className="mt-4 text-sm flex items-center justify-center">
                {$t({id: "DonotHaveAccount"})}&nbsp;
                <AnimateButton>
                    <span
                        className="text-white font-semibold cursor-pointer"
                        onClick={() => {
                            switchType("signup");
                        }}>
                        {$t({id: "RegisterAccount"})}
                    </span>
                </AnimateButton>
            </div>
        </div>
    );
};

export default SignIn;
