import {Howl, Howler} from 'howler';

export default class AudioMgr{
    private musics:Map<string, Howl> = new Map();
    private effects:Map<string, Howl> = new Map();

    unload(){
        this.musics.forEach(h => h.unload());
        this.musics.clear();

        this.effects.forEach(h => h.unload());
        this.effects.clear();
    }

    initMusic(music:string){
        let ef = new Howl({
            src:music,
            loop:true,
            preload:true
        });
        
        let e = this.musics.get(music);
        !!e && e.stop();
        this.musics.set(music, ef);
    }

    initEffect(effect:string){
        let ef = new Howl({
            src:effect,
            loop:false,
            pool:5,
            preload:true
        });
        let e = this.effects.get(effect);
        !!e && e.stop();
        this.effects.set(effect, ef);
    }

    playMusic(music:string, keepOthers:boolean = true){
        this.musics.forEach((howler, k)=>{
            if(music == k){
                howler.stop();
                howler.play();
            }
            else{
                !keepOthers && howler.stop();
            }
        });
    }

    stopMusic(music:string){
        this.musics.forEach((howler, k)=>{
            if(music == k){
                howler.stop();
            }
        });
    }

    playEffect(effect:string){
        let auido = -1;
        this.effects.forEach((howler, k)=>{
            if(effect == k){
                auido = howler.play();
            }
        });

        return auido;
    }

    stopEffect(effect:string, id:number){
        this.effects.forEach((howler, k)=>{
            if(effect == k){
                howler.stop(id);
            }
        });
    }
}