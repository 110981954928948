import client from "./client";

const getOperationsList = (data = {}) => client.post("/getOperationsList", data);

const queryChargActivityList = (data = {}) => client.post("/queryChargActivityList", data);

const apiPromotion = {
    getOperationsList,
    queryChargActivityList,
};

export default apiPromotion;
