import AnimateButton from "ui-component/AnimateButton";
import SvgIcon from "ui-component/SvgIcon";
import {dispatch, useAppSelector} from "store";
import {showDialog} from "store/slices/dialog";
import {useIntl} from "react-intl";
import {WalletType} from "types/config";
import {setWalletType} from "store/slices/config";

import Deposit from "./components/deposit";
import BuyCrypto from "./components/buyCrypto";
import Withdraw from "./components/withdraw";
// import SendFee from "./components/sendFee";

const Wallet = () => {
    const {$t} = useIntl();
    const walletType = useAppSelector((state) => state.config.walletType);

    const tabList = [
        {type: WalletType.DEPOSIT, title: $t({id: "Deposit"})},
        //{type: WalletType.BUYCRYPTO, title: $t({id: "BuyCrypto"})},
        {type: WalletType.WITHDRAW, title: $t({id: "Withdraw"})},
        // {type: WalletType.SENDFEE, title: $t({id: "Tip"})},
    ];

    return (
        <div className="bg-[#1a2c38] text-[#b1bad3] rounded-lg relative py-4">
            <div
                className="absolute top-1 right-0 w-12 h-12 flex items-center justify-center cursor-pointer hover:fill-white"
                onClick={() => {
                    dispatch(showDialog(null));
                }}>
                <AnimateButton>
                    <SvgIcon dataKey="icon-close" className="hover:fill-white fill-[#b1bad3]" style={{width: 16, height: 16}} />
                </AnimateButton>
            </div>
            <div className="flex items-center justify-center">
                <div className="text-lg text-white font-bold">{$t({id: "Wallet"})}</div>
            </div>
            <div className="px-4 sm:px-10">
                <div className="flex justify-center">
                    <div className="inline-flex pt-4 px-5">
                        <div className="h-9 sm:h-14 overflow-hidden bg-[#0E212E] rounded-[28px] p-1">
                            <div className="h-full overflow-x-auto">
                                <div className="h-full flex items-center">
                                    {tabList.map((v, key) => {
                                        return (
                                            <div
                                                key={key}
                                                className="h-full px-7 flex items-center justify-center rounded-[24px] text-sm text-white font-bold cursor-pointer flex-shrink-0 hover:bg-[#192C38]"
                                                style={walletType === v.type ? {background: "#2F4553"} : {}}
                                                onClick={() => {
                                                    dispatch(setWalletType(v.type));
                                                }}>
                                                <AnimateButton>{v.title}</AnimateButton>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-[200px]">
                    {walletType === WalletType.DEPOSIT && <Deposit />}
                    {walletType === WalletType.BUYCRYPTO && <BuyCrypto />}
                    {walletType === WalletType.WITHDRAW && <Withdraw />}
                    {/* {walletType === WalletType.SENDFEE && <SendFee />} */}
                </div>
            </div>
        </div>
    );
};

export default Wallet;
