import {createContext, useContext, FC, ReactNode} from "react";

const tabbarContext = createContext<Record<string, any>>({});
export const useTabbarContext = () => useContext(tabbarContext);

const TabbarProvider: FC<{children: ReactNode; onClose: () => void}> = ({children, onClose}) => {
    return <tabbarContext.Provider value={{onClose}}>{children}</tabbarContext.Provider>;
};

export default TabbarProvider;
