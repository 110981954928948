// types
import {Coin, UserInfo, UserProps} from "types/user";
import {MoneyType} from "types/index";
import {PayloadAction, createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import client from "api/client";
import apiAuth from "api/auth";

// initial state
const initialState: UserProps = {
    isLogin: false,
    user: undefined,
    balance: [],
    turnoverData: [],
    userCurrency: MoneyType.USDT,
    userMainToken: 0,
    userHiddenBalanceInZero: false,
    userIsLegalCurrency: false,
    reconnectWebSocket: true,
};
// ==============================|| SLICE - MENU ||============================== //

export const getUserTurnover = createAsyncThunk("user/getUserTurnover", async () => {
    const response = await apiAuth.getWithdrawLimit();
    if (response.status === 200 && response.data.code === 0) {
        return response.data.data;
    }
});

const user = createSlice({
    name: "user",
    initialState,
    reducers: {
        loginSuccess(state, action: PayloadAction<UserInfo>) {
            client.setToken(action.payload.token);
            state.user = action.payload;
            state.reconnectWebSocket = true;
            state.isLogin = true;
        },

        setUserInfo(state, action: PayloadAction<UserInfo>) {
            state.user = action.payload;
            state.reconnectWebSocket = true;
        },

        logout(state) {
            state.user = undefined;
            state.isLogin = false;
        },

        setBalance: (state, action: PayloadAction<Coin[]>) => {
            state.balance = action.payload;
        },

        setUserCurrency: (state, action) => {
            state.userCurrency = action.payload;
        },
        setUserIsLegalCurrency: (state, action) => {
            state.userIsLegalCurrency = action.payload;
        },
        setUserMainToken: (state, action) => {
            state.userMainToken = action.payload;
        },
        setUserHiddenBalanceInZero: (state, action) => {
            state.userHiddenBalanceInZero = action.payload;
        },
        setReconnectWebSocket: (state, action: PayloadAction<boolean>) => {
            state.reconnectWebSocket = action.payload;
        },
        setPddActStatus: (state, action: PayloadAction<0 | 1>) => {
            state.user.pddActEnable = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserTurnover.fulfilled, (state, action) => {
            try {
                const jsonData = JSON.parse(action.payload);
                state.turnoverData = jsonData?.data || [];
            } catch (error) {
                console.log(error);
            }
        });
    },
});

export default user.reducer;

export const {
    loginSuccess,
    setUserInfo,
    logout,
    setBalance,
    setUserCurrency,
    setUserIsLegalCurrency,
    setUserMainToken,
    setUserHiddenBalanceInZero,
    setReconnectWebSocket,
    setPddActStatus,
} = user.actions;
