import client from "./client";

const getUserMessageList = (msgType: number) =>
    client.post("/getUserMessageList", {
        msgType,
        state: 0,
        pageId: 1,
        pageCount: 20,
    });

const getStationMessageList = (msgType: number, pageId: number, pageCount = 10) =>
    client.post("/getUserMessageList", {
        msgType,
        state: 0,
        pageId,
        pageCount,
    });

interface ReceiveRewardParams {
    messageId: number;
    userid: number;
    rewardType: number;
    rewardAmount: number;
    coinType: number;
    withdrawBetRate: number;
}

const receiveRewardByMsg = (data: ReceiveRewardParams) => client.post("/receiveRewardByMsg", data);
const getUserMessageCountList = () => client.post("/getUserMessageCountList");
const updateUserMessageVo = (ids: number[]) => client.post("/updateUserMessageList", {idList: ids});

const feedback = (message: string) => client.post("/leaveMessage", {message});

const apiMessage = {
    getUserMessageList,
    getStationMessageList,
    getUserMessageCountList,
    updateUserMessageVo,
    feedback,
    receiveRewardByMsg,
};

export default apiMessage;
