import {GameInfo} from "types/game";
type Game = {gameName: string};

export const getPath = <T extends Game>(game: T) => {
    return game.gameName.toLowerCase().replace(/[^a-z\d]+/g, "-");
};

export const getURL = <T extends Game>(game: T) => {
    return `/casino/games/${getPath(game)}`;
};

export const getComboPath = (game: GameInfo) => {
    const path = `${game.gameName || ""}-${game.brandVo?.brandName || game.providerName || ""}-${game.gameId}`;
    return path.toLowerCase().replace(/[^a-z\d-]+/g, "-");
};
