import {useEffect, useRef} from "react";
import _ from "lodash";
import {useAppDispatch, useAppSelector} from "store";
import {load} from "protobufjs";
import {MessageType, MoneyType} from "types";
import {logout, setBalance, setReconnectWebSocket} from "store/slices/user";
import {base64Decode, stringToUint8Array} from "utils/help";
import {LatestBetOrder, setLatestBetOrder} from "hooks/useLatestBetOrder";

const protoFile = require("../utils/message.proto");

type PlatformMessageBalanceParam = {
    type: MoneyType;
    amount: number;
    delay: number;
    mode: number;
    locked_amount: number;
    unlocked_amount: number;
};

const useMessage = () => {
    const dispatch = useAppDispatch();
    const balanceList = useAppSelector((state) => state.user.balance);
    const timer = useRef<NodeJS.Timeout | undefined>(undefined);
    const AwesomeMessage = useRef<any>();

    useEffect(() => {
        load(protoFile, function (err, root) {
            if (!err && root) {
                AwesomeMessage.current = root.lookupType("base.PlatformMessage");
            }
        });
    }, []);

    const handleBalanceChange = (param: PlatformMessageBalanceParam) => {
        const tempList = _.cloneDeep(balanceList);
        tempList.forEach((e) => {
            if (e.type === param.type) {
                if (param.mode === 1 || param.mode === 4 || param.mode === 6) {
                    e.amount = param.amount;
                }
                if (param.mode === 2 || param.mode === 4 || param.mode === 5 || param.mode === 6) {
                    e.locked_amount = param.locked_amount;
                }
                if (param.mode === 3 || param.mode === 5 || param.mode === 6) {
                    e.unlocked_amount = param.unlocked_amount;
                }
            }
        });
        dispatch(setBalance(tempList));
    };

    const handleReceiveMsg = (serveMsg: string | undefined) => {
        if (!serveMsg) {
            return;
        }
        const msgData = JSON.parse(serveMsg);
        if (msgData?.errcode) {
            if (msgData?.errcode === 102 && msgData?.msgtype === MessageType.CLOSE) {
                dispatch(setReconnectWebSocket(false));
                dispatch(logout());
            }
            return;
        }

        if (msgData?.msgtype === MessageType.PLATFORM_MESSAGE) {
            const msg = base64Decode(msgData.msg);
            const buffer = stringToUint8Array(msg);
            if (!AwesomeMessage.current) {
                return;
            }
            const decoded = AwesomeMessage.current.decode(buffer);
            try {
                const result = decoded.toJSON();

                switch (result.type) {
                    // Balance change
                    case 3:
                        {
                            const param: PlatformMessageBalanceParam = JSON.parse(result.param);

                            timer.current && clearTimeout(timer.current);
                            if (param.delay > 0) {
                                timer.current = setTimeout(() => {
                                    handleBalanceChange(param);
                                }, param.delay);
                                break;
                            }

                            handleBalanceChange(param);
                        }
                        break;
                    case 6:
                        {
                            const latestBet: LatestBetOrder = JSON.parse(result.param);
                            setLatestBetOrder(latestBet);
                        }
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    return {handleReceiveMsg};
};

export default useMessage;
