import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import AnimateButton from "ui-component/AnimateButton";
import Loader from "ui-component/Loader";
import SvgIcon from "ui-component/SvgIcon";
import {useAppSelector, dispatch} from "store";
import {showDialog, closeDialog} from "store/slices/dialog";
import {useIntl} from "react-intl";

const CreateCampaign = () => {
    const {$t} = useIntl();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const user = useAppSelector((state) => state.user.user);
    const initialValues = {
        CampaignName: "",
        CampaignCode: user.inviteCode,
    };
    const [campaignList, setCampaignList]: any[] = useState([]);
    const validationSchema = Yup.object().shape({
        CampaignName: Yup.string()
            .min(3, $t({id: "NameMustBeAtLeast3Characters"}))
            .required($t({id: "ThisFieldIsRequired"})),
        CampaignCode: Yup.string()
            .min(8, $t({id: "YourPasswordLeast8Long"}))
            .required($t({id: "ThisFieldIsRequired"})),
    });
    const handleSubmit = async (values: {CampaignName: string; CampaignCode: number}) => {
        if (isLoading) return;
        setIsLoading(true);
        const {CampaignName, CampaignCode} = values;
        try {
            // const res = await apiUser.login(email, md5(password));
            // const {status, data} = res;
            // if (status === 200 && data.code === 0) {
            //     dispatch(loginSuccess(data.data));
            //     dispatch(showDialog(null));
            //     return;
            // }
            // if (data.code === 101) {
            //     toast.error($t({id: "SystemError"}));
            //     return;
            // }
            // toast.error(data.code ? $t({id: `LOGIN_ERROR_${data.code}`}) : data.message);
            setCampaignList(values);
            setTimeout(() => {
                toast.success("Create Campaign Success");
                dispatch(closeDialog());
            }, 0);
        } finally {
            setIsLoading(false);
        }
    };
    const getErrorElement = (errorMessage: string) => {
        return (
            <div className="flex items-center pt-2 pb-1 text-[#F2708A] text-xs">
                <SvgIcon dataKey="icon-error" className="w-3 h-3 mr-2" style={{fill: "#F2708A"}} />
                <span>{errorMessage}</span>
            </div>
        );
    };
    return (
        <div className="bg-[#1a2c38] text-[#b1bad3] rounded-lg relative">
            <div
                className="p-4 flex items-center justify-between cursor-pointer hover:fill-white"
                onClick={() => {
                    dispatch(showDialog(null));
                }}>
                <div className="flex justify-start items-center">
                    <svg fill="currentColor" viewBox="0 0 96 96" className="w-4 h-4 mr-2">
                        <title></title>
                        <path d="M64.689 54.312c37.121-4.57 30.353-38.63 30.353-38.63H78.355c.627-4.697.99-10.138 1.004-15.665V0H16.641c.013 5.54.377 10.982 1.073 16.32l-.069-.641H.958s-6.764 34.011 30.353 38.63c2.62 3.87 5.51 7.244 8.743 10.254l.035.031c-.412 5.579-2.65 21.624-15.59 21.624h-7.862V96h62.719v-9.782l-10.879-.367C58.191 83.52 56.271 69.67 55.905 64.594a59.158 59.158 0 0 0 8.646-10.08l.13-.205.008.003ZM75.43 30.267l2.195-5.44h8.502c-.329 4.638-1.995 8.831-4.611 12.26l.041-.055a22.157 22.157 0 0 1-10.543 6.682l-.157.038c1.734-3.789 3.301-8.283 4.457-12.94l.116-.548v.003Zm-60.984 6.765A22.652 22.652 0 0 1 9.88 24.898l-.003-.072h8.41l2.287 5.441c1.396 5.322 3.052 9.882 5.064 14.246l-.22-.531c-4.408-1.235-8.142-3.665-10.947-6.92l-.024-.027v-.003Zm40.78 3.977-6.401-3.11a1.712 1.712 0 0 0-.823-.205c-.302 0-.587.075-.833.212l.01-.003-6.401 3.11a1.604 1.604 0 0 1-2.243-1.656v.006l1.005-7.176a1.547 1.547 0 0 0-.411-1.282l-4.845-5.256a1.56 1.56 0 0 1 .813-2.606h.01l7.176-1.326c.497-.055.915-.35 1.138-.768l.004-.007 3.428-6.401a1.555 1.555 0 0 1 2.74-.007l.003.007 3.429 6.4c.216.416.617.71 1.09.776h.007l7.176 1.326a1.555 1.555 0 0 1 .823 2.606l-5.03 5.256a1.548 1.548 0 0 0-.425 1.07c0 .075.007.147.013.22v-.008l1.005 7.176a1.6 1.6 0 0 1-2.475 1.646l.006.004.01-.004Z"></path>
                    </svg>
                    <span className="text-white font-bold">{$t({id: "CreateCampaign"})}</span>
                </div>
                <AnimateButton>
                    <SvgIcon dataKey="icon-close" className="hover:fill-white fill-[#b1bad3]" style={{width: 16, height: 16}} />
                </AnimateButton>
            </div>
            <div className="px-4 pb-4">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {() => (
                        <Form>
                            <div>
                                <div>
                                    <label htmlFor="CampaignName">
                                        {$t({id: "CampaignName"})}
                                        <span className="text-[#F2708A] ml-1">*</span>
                                    </label>
                                </div>

                                <Field
                                    type="text"
                                    name="CampaignName"
                                    className="mt-1 w-full h-[40px] text-white bg-[#0f212e] rounded outline-none p-2 border-2 border-solid border-[#2f4553] focus:border-[#557086]"
                                />
                                <ErrorMessage name="CampaignName">{getErrorElement}</ErrorMessage>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <label htmlFor="CampaignCode">
                                        {$t({id: "CampaignCode"})}
                                        <span className="text-[#F2708A] ml-1">*</span>
                                    </label>
                                </div>
                                <div className="relative">
                                    <Field
                                        type={"CampaignCode"}
                                        name="CampaignCode"
                                        className="mt-1 w-full h-[40px] text-white bg-[#0f212e] rounded outline-none p-2 border-2 border-solid border-[#2f4553] focus:border-[#557086]"
                                    />
                                </div>

                                <ErrorMessage name="CampaignCode">{getErrorElement}</ErrorMessage>
                            </div>

                            <AnimateButton>
                                <button
                                    type="submit"
                                    className="w-full h-12 mt-4 flex font-semibold items-center justify-center text-[#05080a] text-base bg-[#00e701] rounded"
                                    style={{boxShadow: "0rem .0625rem .1875rem #00000033 , 0rem .0625rem .125rem #0000001f"}}>
                                    {isLoading ? <Loader /> : $t({id: "CreateCampaign"})}
                                </button>
                            </AnimateButton>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default CreateCampaign;
