import React, {useState, useEffect} from "react";

// third-party
import {IntlProvider, MessageFormatElement} from "react-intl";
import {useAppSelector} from "store";

// load locales files
const loadLocaleData = (locale: string) => {
    switch (locale) {
        case "pt":
            return import("utils/locales/pt.json");
        case "jp":
            return import("utils/locales/jp.json");
        case "zh":
            return import("utils/locales/zh.json");
        default:
            return import("utils/locales/en.json");
    }
};

// ==============================|| LOCALIZATION ||============================== //

interface LocalsProps {
    children: React.ReactNode;
}

const Locales = ({children}: LocalsProps) => {
    const locale = useAppSelector((state) => state.config.locale);
    const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();

    useEffect(() => {
        loadLocaleData(locale).then((d: {default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined}) => {
            setMessages(d.default);
        });
    }, [locale]);

    return (
        <>
            {messages && (
                <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

export default Locales;
