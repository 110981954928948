import {FC, useState, useEffect, useRef} from "react";
import {InView} from "react-intersection-observer";
import Loader from "ui-component/Loader";
import {useIntl} from "react-intl";

const LoadMore: FC<{hasMore: boolean; nextPage: () => void}> = ({hasMore, nextPage}) => {
    const [show, setShow] = useState(false);
    const next = useRef(nextPage);
    const intl = useIntl();
    next.current = nextPage;

    useEffect(() => {
        if (hasMore && show) next.current();
    }, [hasMore, show]);

    return (
        <InView as="div" delay={500} onChange={setShow}>
            <div className="flex mt-4 justify-center items-center h-6">
                {hasMore ? (
                    <Loader />
                ) : (
                    <div className="-mt-2 w-full flex justify-around items-center px-8">
                        <span className="h-0.5 bg-[#98A7B5] flex-grow"></span>
                        <span className="px-6 relative z-10">{intl.$t({id: "NoMore"})}</span>
                        <span className="h-0.5 bg-[#98A7B5] flex-grow"></span>
                    </div>
                )}
            </div>
        </InView>
    );
};

export default LoadMore;
