import {useEffect} from "react";
import {useAppSelector} from "store";
import {createSignal} from "utils/reaction";

const [userInfo, setUserInfo] = createSignal<{nick: string; email: string; userId: string | number}>({nick: "", email: "", userId: ""});

const useLiveChat = () => {
    const user = useAppSelector((state) => state.user.user);
    useEffect(() => {
        setUserInfo({
            nick: user?.nick || "",
            email: user?.email || "",
            userId: user?.userId || "",
        });
    }, [user]);
};

export const showLiveChat = () => {
    const user = userInfo();
    window.LiveChatWidget?.call("maximize");
    window.LiveChatWidget?.call("set_session_variables", {
        Nickname: user.nick,
        Email: user.email,
        UserID: user.userId,
    });
};

export default useLiveChat;
