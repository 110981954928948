import SampleLog from "../Common/SampleLog";
import * as WSProto from "./WSProtocol";


export interface IWsMsgReceiver{
    onNetConnect():void;
    onNetError():void;
    onNetClose():void;

    onMessageError(errorCode:number):void;
}

export default class WsMsgDispatcher {
    private _msgQueue:Array<WSProto.MsgPackage> = null;
    private _msgHandleFunction:Map<number, (obj:Object)=>void> = null;
    private _isDispatching:boolean = false;
    private _msgReciver:IWsMsgReceiver = null;
    private lastTick:number = 0;

    constructor(){
        if(!this._msgQueue) this._msgQueue = new Array<WSProto.MsgPackage>();
        if(!this._msgHandleFunction) this._msgHandleFunction = new Map<number, (obj:Object)=>void>();

        let interval = 10;
        setInterval(()=>{
            if(this.lastTick == 0){
                this.lastTick = (new Date()).getTime();
                return;
            }

            //setInterval定时器不准，要校正
            let now = (new Date()).getTime();
            this.update(now-this.lastTick);
            this.lastTick = now;
        }, interval);
    }

    startDispatchMsg(reciver:IWsMsgReceiver){
        this._isDispatching = true;
        this._msgReciver = reciver;
    }

    stopDispatchMsg(){
        this._isDispatching = false;
        this._msgReciver = null;
    }

    registerHandler(cmdId:number, handler:(obj:Object)=>void){
        this._msgHandleFunction.set(cmdId, handler);
    }

    private getHandler(cmdId:number):(obj:Object)=>void{
        if(this._msgHandleFunction.has(cmdId)){
            let item = this._msgHandleFunction.get(cmdId);
            return item;
        }
        return null;
    }

    removeHandler(cmdId:number){
        if(this._msgHandleFunction.has(cmdId)){
            this._msgHandleFunction.delete(cmdId);
        }
    }

    clearMsgQueue(){
        this._msgQueue.splice(0, this._msgQueue.length);
    }

    pushMsg(msg:WSProto.MsgPackage){
        this._msgQueue.push(msg);
    }

    private popMsg():WSProto.MsgPackage{
        let msg:WSProto.MsgPackage = null;
        if(this._msgQueue.length){
            msg = this._msgQueue[0];
             this._msgQueue.splice(0, 1);
        }
        return msg;
    }

    onNetConnect(){
        SampleLog.Log('websocket connect succ');
        !!this._msgReciver && this._msgReciver.onNetConnect();
    }

    onNetError(){
        SampleLog.Warn('websocket error');
        !!this._msgReciver && this._msgReciver.onNetError();
    }

    onNetClose(){
        SampleLog.Warn("websocket close");
        !!this._msgReciver && this._msgReciver.onNetClose();
    }

    onMessageError(errorCode:number){
        !!this._msgReciver && this._msgReciver.onMessageError(errorCode);
    }


    //分帧处理消息 dt : ms
    update(dt:number){
        // ConnectionHelper.getInstance().update(dt);
        if(!this._isDispatching) return;
        if(this._msgQueue && this._msgQueue.length){
            let msg = this.popMsg();
            let handler = this.getHandler(msg.id);
            if(handler){
                handler(msg.msg);
            }
            else{
                SampleLog.Warn(msg.id, msg.msg);
            }
        }
    }
}
