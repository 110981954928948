import {lazy} from "react";

// project imports
import AuthGuard from "./AuthGuard";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// page routing
const HomePage = Loadable(lazy(() => import("views/home")));
const CasinoHomePage = Loadable(lazy(() => import("views/casino/home")));
const CasinoGroup = Loadable(lazy(() => import("views/casino/group")));
const Games = Loadable(lazy(() => import("views/games")));
const CasinoGames = Loadable(lazy(() => import("views/casino/games")));
const CasinoCollection = Loadable(lazy(() => import("views/casino/collection")));
const TransactionsHome = Loadable(lazy(() => import("views/transactions")));
const Deposits = Loadable(lazy(() => import("views/transactions/components/deposits")));
const Withdrawals = Loadable(lazy(() => import("views/transactions/components/withdrawals")));
const Bets = Loadable(lazy(() => import("views/transactions/components/bets")));
const Sports = Loadable(lazy(() => import("views/sport")));
const Favourites = Loadable(lazy(() => import("views/casino/favourites")));
const Recent = Loadable(lazy(() => import("views/casino/recent")));
const AffiliateHome = Loadable(lazy(() => import("views/affiliate")));
const AffiliateOverview = Loadable(lazy(() => import("views/affiliate/components/overview")));
const AffiliateRetention = Loadable(lazy(() => import("views/affiliate/components/retention")));
const AffiliateCommission = Loadable(lazy(() => import("views/affiliate/components/commission")));
const AffiliateFunds = Loadable(lazy(() => import("views/affiliate/components/funds")));
const ReferredUsers = Loadable(lazy(() => import("views/affiliate/components/referred-users")));
const Campaigns = Loadable(lazy(() => import("views/affiliate/components/campaigns")));
const Financial = Loadable(lazy(() => import("views/rlb/financial")));
const Lottery = Loadable(lazy(() => import("views/rlb/lottery/index")));
//const VIPClubHome = Loadable(lazy(() => import("views/vip-club/index")));
const VIPClubHome = Loadable(lazy(() => import("views/vipclub/index")));
const PromotionHome = Loadable(lazy(() => import("views/promotion/index")));
const PoliciesHome = Loadable(lazy(() => import("views/policies/index")));
const Privacy = Loadable(lazy(() => import("views/policies/components/privacy")));
const FAQ = Loadable(lazy(() => import("views/policies/components/FAQ")));
const GambleAware = Loadable(lazy(() => import("views/policies/components/GambleAware")));
const TermsAndConditions = Loadable(lazy(() => import("views/policies/components/TermsAndConditions")));
const ResponsibleGambling = Loadable(lazy(() => import("views/policies/components/ResponsibleGambling")));
const AML = Loadable(lazy(() => import("views/policies/components/AML")));
const SelfExclusion = Loadable(lazy(() => import("views/policies/components/SelfExclusion")));
const KYCPolicy = Loadable(lazy(() => import("views/policies/components/KYCPolicy")));
const LotteryHome = Loadable(lazy(() => import("views/lottery")));
const FurnaceHome = Loadable(lazy(() => import("views/furnace/home")));
const FurnaceGroup = Loadable(lazy(() => import("views/furnace/group")));
const Roulette = Loadable(lazy(() => import("views/roulette")));
const Crypto = Loadable(lazy(() => import("views/crypto")));
const Setting = Loadable(lazy(() => import("views/setting")));
const Kyc = Loadable(lazy(() => import("views/setting/components/kyc")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: "/",
            element: <HomePage />,
        },
        {
            path: "/casino",
            children: [
                {
                    path: "home",
                    element: <CasinoHomePage />,
                },
                {
                    path: "group/:type",
                    element: <CasinoGroup />,
                },
                {
                    path: "favourites",
                    element: <Favourites />,
                },
                {
                    path: "recent",
                    element: <Recent />,
                },
                {
                    path: "collection/:type",
                    element: <CasinoCollection />,
                },
                {
                    path: "games/:gameName",
                    element: <Games />,
                },
            ],
        },
        {
            path: "/transactions",
            element: <TransactionsHome />,
            children: [
                {
                    path: "deposits",
                    element: <Deposits />,
                },
                {
                    path: "withdrawals",
                    element: <Withdrawals />,
                },
                {
                    path: "bets",
                    element: <Bets />,
                },
            ],
        },
        {
            path: "/policies",
            element: <PoliciesHome />,
            children: [
                {
                    path: "privacy",
                    element: <Privacy />,
                },
                {
                    path: "FAQ",
                    element: <FAQ />,
                },
                {
                    path: "GambleAware",
                    element: <GambleAware />,
                },
                {
                    path: "TermsAndConditions",
                    element: <TermsAndConditions />,
                },
                {
                    path: "ResponsibleGambling",
                    element: <ResponsibleGambling />,
                },
                {
                    path: "AML",
                    element: <AML />,
                },
                {
                    path: "SelfExclusion",
                    element: <SelfExclusion />,
                },
                {
                    path: "KYCPolicy",
                    element: <KYCPolicy />,
                },
            ],
        },
        {
            path: "/sports",
            children: [
                {
                    path: "home",
                    element: <Sports />,
                },
            ],
        },
        {
            path: "/furnace",
            children: [
                {
                    path: "home",
                    element: <FurnaceHome />,
                },
                {
                    path: "group",
                    element: <FurnaceGroup />,
                },
            ],
        },
        {
            path: "/game-detail/:gameName",
            element: <CasinoGames />,
        },
        {
            path: "/affiliate",
            element: <AffiliateHome />,
            children: [
                {
                    path: "overview",
                    element: <AffiliateOverview />,
                },
                {
                    path: "retention",
                    element: <AffiliateRetention />,
                },
                {
                    path: "commission",
                    element: <AffiliateCommission />,
                },
                {
                    path: "funds",
                    element: <AffiliateFunds />,
                },
                {
                    path: "referred-users",
                    element: <ReferredUsers />,
                },
                {
                    path: "campaigns",
                    element: <Campaigns />,
                },
            ],
        },
        {
            path: "/financial",
            element: <Financial />,
        },
        {
            path: "/lottery",
            element: <Lottery />,
        },
        {
            path: "/roulette",
            element: <Roulette />,
        },
        {
            path: "/lottery-home",
            element: <LotteryHome />,
        },
        {
            path: "/vip-club",
            element: <VIPClubHome />,
        },
        {
            path: "/promotion",
            element: <PromotionHome />,
        },
        {
            path: "/crypto",
            element: <Crypto />,
        },
        {
            path: "/settings",
            element: <Setting />,
            children: [
                {
                    path: "general",
                    element: <Kyc />,
                },
            ],
        },
        {
            path: "*",
            element: <HomePage />,
        },
    ],
};

export default MainRoutes;
