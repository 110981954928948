import {FormattedMessage} from "react-intl";
import AnimateButton from "ui-component/AnimateButton";
import SvgIcon from "ui-component/SvgIcon";
import {useAppDispatch} from "store";
import {showDialog} from "store/slices/dialog";

const ReconnectSocketModal = () => {
    const dispatch = useAppDispatch();

    return (
        <div className="bg-[#1a2c38] text-[#b1bad3] rounded-lg relative p-4">
            <div
                className="absolute top-1 right-0 w-12 h-12 flex items-center justify-center cursor-pointer hover:fill-white"
                onClick={() => {
                    dispatch(showDialog(null));
                }}>
                <AnimateButton>
                    <SvgIcon dataKey="icon-close" className="hover:fill-white fill-[#b1bad3]" style={{width: 16, height: 16}} />
                </AnimateButton>
            </div>
            <div className="font-semibold">
                <div className="text-lg text-white text-center font-semibold">
                    <FormattedMessage id="NOTICE" />
                </div>
                <div className="h-40 flex items-center justify-center">
                    <div className="text-lg">
                        <FormattedMessage id="ReconnectSocket" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReconnectSocketModal;
