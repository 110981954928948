import apiAuth from "api/auth";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";
import Web3 from "web3";
import client from "api/client";
import {useAppDispatch, useAppSelector} from "store";
import {loginSuccess} from "store/slices/user";
import {closeDialog} from "store/slices/dialog";
import SvgIcon from "ui-component/SvgIcon";

let web3: Web3 | undefined; // Will hold the web3 instance

const MetaMaskLogin = () => {
    const inviteCode = useAppSelector((state) => state.config.inviteCode);
    const promoCode = useAppSelector((state) => state.config.promoCode);
    const isMobile = useAppSelector((state) => state.config.isMobile);
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const handleLogin = async () => {
        if (!window.ethereum && !window.web3) {
            if (!isMobile) {
                window.open("https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn");
                return;
            }
            window.alert("browser have no wallet");
            return;
        }
        if (!web3) {
            let provider = null;
            if (window.ethereum) {
                provider = window.ethereum;
            } else if (window.web3) {
                provider = window.web3.currentProvider;
            }
            await provider.enable();
            web3 = new Web3(provider);
        }
        try {
            const accounts = await web3.eth.getAccounts();
            if (!accounts) {
                window.alert("Please activate MetaMask first.");
                return;
            }
            const publicAddress = accounts[0].toLowerCase();
            const {status, data} = await apiAuth.getMetaMaskNonce(publicAddress);
            if (status === 200 && data.code === 0) {
                const signature = await web3.eth.personal.sign(
                    data.data,
                    publicAddress,
                    "", // MetaMask will ignore the password argument here
                );
                const res = await apiAuth.metaMaskLogin({
                    publicAddress,
                    signature,
                    ...(promoCode ? {promoCode} : {inviteCode: inviteCode || "-1"}),
                });
                if (res.status === 200 && res.data.code === 0) {
                    client.setToken(res.data.data.token);
                    dispatch(loginSuccess(res.data.data));
                    dispatch(closeDialog());
                    return;
                }
                if (res.data.code === 101) {
                    toast.error(intl.$t({id: "SystemError"}));
                    return;
                }
                toast.error(intl.$t({id: `MetaMask_LOGIN_ERROR_${res.data.code}`}));
            }
        } catch (error) {
            console.log("error=", error);
        }
    };

    return (
        <button className="w-full flex justify-center py-[0.8125rem] px-4 bg-gray-400 hover:bg-gray-300 rounded-[0.25rem]" onClick={handleLogin}>
            <SvgIcon dataKey="MetaMask" className="w-4 h-4 fill-white" />
        </button>
    );
};

export default MetaMaskLogin;
