import { truco } from "../Protobuf/message";
import { base, classicdice, coinflip, crash, cryptos, double, dragontiger, hilo, keno, limbo, mines, plinko, ring, ringoffortune, stairs, towerlegend, videopoker, wheel } from "../Protobuf/message";
import { MessageImpl } from "./ConnectionHelper";
import { CMD_ALLSTAR_C2S, CMD_ALLSTAR_S2C, CMD_CLASSICDICE_C2S, CMD_CLASSICDICE_S2C, CMD_COINFLIP_C2S, CMD_COINFLIP_S2C, CMD_COMMON_C2S, CMD_COMMON_S2C, CMD_CRASH_C2S, CMD_CRASH_S2C, CMD_CRYPTOS_C2S, CMD_CRYPTOS_S2C, CMD_DOUBLE_C2S, CMD_DOUBLE_S2C, CMD_DRAGONTIGER_C2S, CMD_DRAGONTIGER_S2C, CMD_FURYWHEEL_C2S, CMD_FURYWHEEL_S2C, CMD_HILO_C2S, CMD_HILO_S2C, CMD_KENO_C2S, CMD_KENO_S2C, CMD_LIMBO_C2S, CMD_LIMBO_S2C, CMD_MINES_C2S, CMD_MINES_S2C, CMD_PLINKO_C2S, CMD_PLINKO_S2C, CMD_RINGOFFORTUNE_C2S, CMD_RINGOFFORTUNE_S2C, CMD_STAIRS_C2S, CMD_STAIRS_S2C, CMD_TOWERLEGEND_C2S, CMD_TOWERLEGEND_S2C, CMD_TRUCO_C2S, CMD_TRUCO_S2C, CMD_VIDEOPOKER_C2S, CMD_VIDEOPOKER_S2C, CMD_WHEEL_C2S, CMD_WHEEL_S2C } from "./WSProtocol";


export default class ProtoHelper implements MessageImpl{
    protoEncode(protoid: number, data: Object): Uint8Array{
        switch(protoid){
            //base
            case CMD_COMMON_C2S.PLAYER_LOGIN_REQ:
                return base.PlayerLoginReq.encode(base.PlayerLoginReq.create(data)).finish();
            case CMD_COMMON_C2S.ROOM_LIST_REQ:
                return base.RoomListReq.encode(base.RoomListReq.create(data)).finish();
            case CMD_COMMON_C2S.ENTER_ROOM_REQ:
                return base.EnterRoomReq.encode(base.EnterRoomReq.create(data)).finish();
            case CMD_COMMON_C2S.EXIT_ROOM_REQ:
                return base.ExitRoomReq.encode(base.ExitRoomReq.create(data)).finish();
            case CMD_COMMON_C2S.SWITCH_COIN_REQ:
                return base.SwitchCoinReq.encode(base.SwitchCoinReq.create(data)).finish();
            //crash
            case CMD_CRASH_C2S.CRASH_BET_REQ:
                return crash.BetReq.encode(crash.BetReq.create(data)).finish();
            case CMD_CRASH_C2S.CRASH_CASH_OUT_REQ:
                return crash.CashOutReq.encode(crash.CashOutReq.create(data)).finish();
            case CMD_CRASH_C2S.CRASH_PLAYER_LIST_REQ:
                return new Uint8Array(0);

            //furywheel
            case CMD_FURYWHEEL_C2S.WHEEL_STATE_REQ:
                return new Uint8Array(0);
            case CMD_FURYWHEEL_C2S.WHEEL_RESULT_REQ:
                return new Uint8Array(0);
            //ring
            case CMD_WHEEL_C2S.WHEEL_STATE_REQ:
                return new Uint8Array(0);
            case CMD_WHEEL_C2S.WHEEL_RESULT_REQ:
                return ring.RingResultReq.encode(ring.RingResultReq.create(data)).finish();

            //plinko
            case CMD_PLINKO_C2S.PLINKO_STATE_REQ:
                return new Uint8Array(0);
            case CMD_PLINKO_C2S.PLINKO_RESULT_REQ:
                return plinko.PlinkoResultReq.encode(plinko.PlinkoResultReq.create(data)).finish();
            //cryptos
            case CMD_CRYPTOS_C2S.CRYPTOS_STATE_REQ:
                return new Uint8Array(0);
            case CMD_CRYPTOS_C2S.CRYPTOS_RESULT_REQ:
                return cryptos.CryptosResultReq.encode(cryptos.CryptosResultReq.create(data)).finish();
            //limbo
            case CMD_LIMBO_C2S.LIMBO_STATE_REQ:
                return new Uint8Array(0);
            case CMD_LIMBO_C2S.LIMBO_RESULT_REQ:
                return limbo.LimboResultReq.encode(limbo.LimboResultReq.create(data)).finish();
            //Mines
            case CMD_MINES_C2S.MINES_STATE_REQ: //状态请求
                return new Uint8Array(0);
            case CMD_MINES_C2S.MINES_BET_REQ: //下注请求
                return mines.MinesBetReq.encode(mines.MinesBetReq.create(data)).finish();
            case CMD_MINES_C2S.MINES_OPEN_REQ: //打开卡片请求
                return mines.MinesOpenReq.encode(mines.MinesOpenReq.create(data)).finish();
            case CMD_MINES_C2S.MINES_AUTO_REQ: //自动请求
                return mines.MinesAutoReq.encode(mines.MinesAutoReq.create(data)).finish();
            case CMD_MINES_C2S.MINES_CASHOUT_REQ: //cashOut
                return new Uint8Array(0);
            //hilo
            case CMD_HILO_C2S.HILO_STATE_REQ: //状态请求
                return new Uint8Array(0);
            case CMD_HILO_C2S.HILO_BET_REQ:
                return hilo.HiloBetReq.encode(hilo.HiloBetReq.create(data)).finish();
            case CMD_HILO_C2S.HILO_GUESS_REQ:
                return hilo.HiloGuessReq.encode(hilo.HiloGuessReq.create(data)).finish();
            case CMD_HILO_C2S.HILO_SKIP_REQ:
                return new Uint8Array(0);
             case CMD_HILO_C2S.HILO_CASHOUT_REQ:
                return new Uint8Array(0);
            //dice
            case CMD_CLASSICDICE_C2S.CLASSICDICE_STATE_REQ:
                return new Uint8Array(0);
            case CMD_CLASSICDICE_C2S.CLASSICDICE_RESULT_REQ:
                return classicdice.ClassicDiceResultReq.encode(classicdice.ClassicDiceResultReq.create(data)).finish();
            //stairs
            case CMD_STAIRS_C2S.STAIRS_STATE_REQ:
                return new Uint8Array(0);
            case CMD_STAIRS_C2S.STAIRS_BET_REQ:
                return stairs.StairsBetReq.encode(stairs.StairsBetReq.create(data)).finish();
            case CMD_STAIRS_C2S.STAIRS_PICK_REQ:
                return stairs.StairsPickReq.encode(stairs.StairsPickReq.create(data)).finish();
            case CMD_STAIRS_C2S.STAIRS_CASHOUT_REQ:
                return new Uint8Array(0);
            //coinflip
            case CMD_COINFLIP_C2S.COINFLIP_STATE_REQ :
                return new Uint8Array(0);
            case CMD_COINFLIP_C2S.COINFLIP_BET_REQ:
                return coinflip.CoinflipBetReq.encode(coinflip.CoinflipBetReq.create(data)).finish();
            case CMD_COINFLIP_C2S.COINFLIP_GUESS_REQ:
                return coinflip.CoinflipGuessReq.encode(coinflip.CoinflipGuessReq.create(data)).finish();
            case CMD_COINFLIP_C2S.COINFLIP_CASH_OUT_REQ:
                return new Uint8Array(0);
            //videopoker
            case CMD_VIDEOPOKER_C2S.VIDEOPOKER_STATE_REQ:
                return new Uint8Array(0);
            case CMD_VIDEOPOKER_C2S.VIDEOPOKER_BET_REQ:
                return videopoker.VideopokerBetReq.encode(videopoker.VideopokerBetReq.create(data)).finish();
            case CMD_VIDEOPOKER_C2S.VIDEOPOKER_DEAL_REQ:
                return videopoker.VideopokerDealReq.encode(videopoker.VideopokerDealReq.create(data)).finish();
          //keno
            case CMD_KENO_C2S.KENO_STATE_REQ:
                return new Uint8Array(0);
            case CMD_KENO_C2S.KENO_RESULT_REQ:
                return keno.KenoResultReq.encode(keno.KenoResultReq.create(data)).finish();
            //ringoffortune
            case CMD_RINGOFFORTUNE_C2S.RINGOFFORTUNE_STATE_REQ:
                return new Uint8Array(0);
            case CMD_RINGOFFORTUNE_C2S.RINGOFFORTUNE_RESULT_REQ:
                return ringoffortune.RingOfFortuneResultReq.encode(ringoffortune.RingOfFortuneResultReq.create(data)).finish();
            //double
            case CMD_DOUBLE_C2S.DOUBLE_BET_REQ://下注请求
                return double.BetReq.encode(double.BetReq.create(data)).finish();
            case CMD_DOUBLE_C2S.DOUBLE_PLAYER_LIST_REQ://玩家列表请求
                return new Uint8Array(8);
            //dragon
            case CMD_DRAGONTIGER_C2S.DRAGONTIGER_BET_REQ://下注请求
                return dragontiger.BetReq.encode(dragontiger.BetReq.create(data)).finish();
            case CMD_DRAGONTIGER_C2S.DRAGONTIGER_PLAYER_LIST_REQ://玩家列表请求
                return new Uint8Array(8);
            //truco
            case CMD_TRUCO_C2S.TRUCO_BET_REQ://下注请求
                return truco.BetReq.encode(truco.BetReq.create(data)).finish();
            case CMD_TRUCO_C2S.TRUCO_PLAYER_LIST_REQ://玩家列表请求
                return new Uint8Array(8);
            //allstar
            // case CMD_ALLSTAR_C2S.ALLSTAR_BET_REQ://下注请求
            //     return allstar.BetReq.encode(allstar.BetReq.create(data)).finish();
            // case CMD_ALLSTAR_C2S.ALLSTAR_PLAYER_LIST_REQ://玩家列表请求
            //     return new Uint8Array(8);
            //tower
            case CMD_TOWERLEGEND_C2S.TOWERLEGEND_STATE_REQ:
                return new Uint8Array(8);
            case CMD_TOWERLEGEND_C2S.TOWERLEGEND_BET_REQ:
                return towerlegend.TowerlegendBetReq.encode(towerlegend.TowerlegendBetReq.create(data)).finish();
            case CMD_TOWERLEGEND_C2S.TOWERLEGEND_DEAL_REQ:
                return towerlegend.TowerlegendDealReq.encode(towerlegend.TowerlegendDealReq.create(data)).finish();
            case CMD_TOWERLEGEND_C2S.TOWERLEGEND_CASH_OUT_REQ:
                return new Uint8Array(8);
        }
        return new Uint8Array(0);
    }

    protoDecode(protoid: number, buffer: Uint8Array): Object{
        switch(protoid){
            //base
            case CMD_COMMON_S2C.PLAYER_LOGIN_REPLY:
                return base.PlayerLoginReply.decode(buffer);
            case CMD_COMMON_S2C.ROOM_LIST_REPLY:
                return base.RoomListReply.decode(buffer);
            case CMD_COMMON_S2C.ENTER_ROOM_REPLY:
                return base.EnterRoomReply.decode(buffer);
            case CMD_COMMON_S2C.EXIT_ROOM_REPLY:
                return base.ExitRoomReply.decode(buffer);
            case CMD_COMMON_S2C.PLAYER_BALANCE_EVENT:
                return base.PlayerBalanceEvent.decode(buffer);
            case CMD_COMMON_S2C.TABLE_PLAYERS_EVENT:
                return base.TablePlayersEvent.decode(buffer);
            case CMD_COMMON_S2C.TABLE_TIME_EVENT:
                return base.TableTimeEvent.decode(buffer);
            case CMD_COMMON_S2C.GAME_BALANCE_EVENT:
                return base.GameBalanceEvent.decode(buffer);
            case CMD_COMMON_S2C.SWITCH_COIN_REPLY:
                return base.SwitchCoinReq.decode(buffer);
            case CMD_COMMON_S2C.SYNC_GAME_PAYOUT:
                return base.SyncGamePayout.decode(buffer);
            //crash
            case CMD_CRASH_S2C.CRASH_HISTORY_RESULT_EVENT:
                return crash.HistoryResultEvent.decode(buffer);
            case CMD_CRASH_S2C.CRASH_PLAYER_LIST_EVENT:
                return crash.PlayerListEvent.decode(buffer);
            case CMD_CRASH_S2C.CRASH_GAME_START_EVENT:
                return crash.GameStartEvent.decode(buffer);
            case CMD_CRASH_S2C.CRASH_PLAYERS_EVEVT:
                return crash.CrashPlayersEvent.decode(buffer);
            case CMD_CRASH_S2C.CRASH_STATE_EVENT:
                return crash.TableStateEvent.decode(buffer);
            case CMD_CRASH_S2C.CRASH_BET_EVENT:
                return crash.BetEvent.decode(buffer);
            case CMD_CRASH_S2C.CRASH_FLY_END_EVENT:
                return crash.FlyEndEvent.decode(buffer);
            case CMD_CRASH_S2C.CRASH_WIN_EVENT:
                return crash.GameWinEvent.decode(buffer);
            case CMD_CRASH_S2C.CRASH_LOSE_EVENT:
                return crash.GameLoseEvent.decode(buffer);
            case CMD_CRASH_S2C.CRASH_FLY_TICK_EVENT:
                return crash.FlyTickEvent.decode(buffer);
            //furywheel
            case CMD_FURYWHEEL_S2C.WHEEL_STATE_REPLY:
                return wheel.WheelStateReply.decode(buffer);
            case CMD_FURYWHEEL_S2C.WHEEL_RESULT_REPLY:
                return wheel.WheelResultReply.decode(buffer);
            //ring
            case CMD_WHEEL_S2C.WHEEL_STATE_REPLY:
                return  ring.RingStateReply.decode(buffer);
            case CMD_WHEEL_S2C.WHEEL_RESULT_REPLY:
                return ring.RingResultReply.decode(buffer);
            //plinko
            case CMD_PLINKO_S2C.PLINKO_STATE_REPLY:
                return plinko.PlinkoStateReply.decode(buffer);
            case CMD_PLINKO_S2C.PLINKO_RESULT_REPLY:
                return plinko.PlinkoResultReply.decode(buffer);
            //cryptos
            case CMD_CRYPTOS_S2C.CRYPTOS_STATE_REPLY:
                return cryptos.CryptosStateReply.decode(buffer);
            case CMD_CRYPTOS_S2C.CRYPTOS_RESULT_REPLY:
                return cryptos.CryptosResultReply.decode(buffer);
            //limbo
            case CMD_LIMBO_S2C.LIMBO_STATE_REPLY:
                return limbo.LimboStateReply.decode(buffer);
            case CMD_LIMBO_S2C.LIMBO_RESULT_REPLY:
                return limbo.LimboResultReply.decode(buffer);
            //mines
            case CMD_MINES_S2C.MINES_STATE_REPLY: //状态回应
                return mines.MinesStateReply.decode(buffer);
            case CMD_MINES_S2C.MINES_BET_REPLY: //下注回应
                return mines.MinesBetReply.decode(buffer);
            case CMD_MINES_S2C.MINES_OPEN_REPLY: //打开卡片回应
                return mines.MinesOpenReply.decode(buffer);
            case CMD_MINES_S2C.MINES_RESULT_REPLY: //结果回应
                return mines.MinesResultReply.decode(buffer);
            //hilo            
            case CMD_HILO_S2C.HILO_STATE_REPLY:
                return hilo.HiloStateReply.decode(buffer);
            case CMD_HILO_S2C.HILO_BET_REPLY:
                return hilo.HiloBetReply.decode(buffer);
            case CMD_HILO_S2C.HILO_GUESS_REPLY:
                return hilo.HiloGuessReply.decode(buffer);
            case CMD_HILO_S2C.HILO_RESULT_REPLY:
                return hilo.HiloResultReply.decode(buffer);
            case CMD_HILO_S2C.HILO_SKIP_REPLY:
                return hilo.HiloSkipReply.decode(buffer);
            //Dice
            case CMD_CLASSICDICE_S2C.CLASSICDICE_STATE_REPLY:
                return classicdice.ClassicDiceStateReply.decode(buffer);
            case CMD_CLASSICDICE_S2C.CLASSICDICE_RESULT_REPLY:
                return classicdice.ClassicDiceResultReply.decode(buffer);
            //stairs
            case CMD_STAIRS_S2C.STAIRS_STATE_REPLY:
                return stairs.StairsStateReply.decode(buffer);
            case CMD_STAIRS_S2C.STAIRS_BET_REPLY:
                return stairs.StairsBetReply.decode(buffer);
            case CMD_STAIRS_S2C.STAIRS_PICK_REPLY:
                return stairs.StairsPickReply.decode(buffer);
            case CMD_STAIRS_S2C.STAIRS_RESULT_REPLY:
                return stairs.StairsResultReply.decode(buffer);
            //coinflip
            case CMD_COINFLIP_S2C.COINFLIP_STATE_REPLY:
                return coinflip.CoinflipStateReply.decode(buffer);
            case CMD_COINFLIP_S2C.COINFLIP_BET_REPLY:
                return coinflip.CoinflipBetReply.decode(buffer);
            case CMD_COINFLIP_S2C.COINFLIP_GUESS_REPLY:
                return coinflip.CoinflipGuessReply.decode(buffer);
            case CMD_COINFLIP_S2C.COINFLIP_CASH_OUT_REPLY:
                return coinflip.CoinflipCashOutReply.decode(buffer);
            //videopoker
            case CMD_VIDEOPOKER_S2C.VIDEOPOKER_STATE_REPLY:  
                return videopoker.VideopokerStateReply.decode(buffer);
            case CMD_VIDEOPOKER_S2C.VIDEOPOKER_BET_REPLY:  
                return videopoker.VideopokerBetReply.decode(buffer);
            case CMD_VIDEOPOKER_S2C.VIDEOPOKER_DEAL_REPLY:  
                return videopoker.VideopokerDealReply.decode(buffer);
            //keno
            case CMD_KENO_S2C.KENO_STATE_REPLY:
                return  keno.KenoStateReply.decode(buffer);
            case CMD_KENO_S2C.KENO_RESULT_REPLY:
                return keno.KenoResultReply.decode(buffer);
            //ringoffortune
            case CMD_RINGOFFORTUNE_S2C.RINGOFFORTUNE_STATE_REPLY:
                return  ringoffortune.RingOfFortuneStateReply.decode(buffer);
            case CMD_RINGOFFORTUNE_S2C.RINGOFFORTUNE_RESULT_REPLY:
                return ringoffortune.RingOfFortuneResultReply.decode(buffer);
            //double
            case CMD_DOUBLE_S2C.DOUBLE_HISTORY_RESULT_EVENT:
                return double.HistoryResultEvent.decode(buffer);
            case CMD_DOUBLE_S2C.DOUBLE_GAME_START_EVENT:
                return double.GameStartEvent.decode(buffer);
            case CMD_DOUBLE_S2C.DOUBLE_PLAYERS_EVEVT:
                return double.DoublePlayersEvent.decode(buffer);
            case CMD_DOUBLE_S2C.DOUBLE_STATE_EVENT:
                return double.TableStateEvent.decode(buffer);
            case CMD_DOUBLE_S2C.DOUBLE_BET_EVENT:
                return double.BetEvent.decode(buffer);
            case CMD_DOUBLE_S2C.DOUBLE_PLAYER_LIST_EVENT:
                return double.PlayerListEvent.decode(buffer);
            case CMD_DOUBLE_S2C.DOUBLE_GAME_END_EVENT:
                return double.GameEndEvent.decode(buffer);
            case CMD_DOUBLE_S2C.DOUBLE_GAME_RESULT_EVENT:
                return double.GameResultEvent.decode(buffer);
            //dragon
            case CMD_DRAGONTIGER_S2C.DRAGONTIGER_HISTORY_RESULT_EVENT:
                return dragontiger.HistoryResultEvent.decode(buffer);
            case CMD_DRAGONTIGER_S2C.DRAGONTIGER_GAME_START_EVENT:
                return dragontiger.GameStartEvent.decode(buffer);
            case CMD_DRAGONTIGER_S2C.DRAGONTIGER_PLAYERS_EVEVT:
                return dragontiger.DragontigerPlayersEvent.decode(buffer);
            case CMD_DRAGONTIGER_S2C.DRAGONTIGER_STATE_EVENT:
                return dragontiger.TableStateEvent.decode(buffer);
            case CMD_DRAGONTIGER_S2C.DRAGONTIGER_BET_EVENT:
                return dragontiger.BetEvent.decode(buffer);
            case CMD_DRAGONTIGER_S2C.DRAGONTIGER_PLAYER_LIST_EVENT:
                return dragontiger.PlayerListEvent.decode(buffer);
            case CMD_DRAGONTIGER_S2C.DRAGONTIGER_GAME_END_EVENT:
                return dragontiger.GameEndEvent.decode(buffer);
            case CMD_DRAGONTIGER_S2C.DRAGONTIGER_GAME_RESULT_EVENT:
                return dragontiger.GameResultEvent.decode(buffer);
            //truco
            case CMD_TRUCO_S2C.TRUCO_HISTORY_RESULT_EVENT:
                return truco.HistoryResultEvent.decode(buffer);
            case CMD_TRUCO_S2C.TRUCO_GAME_START_EVENT:
                return truco.GameStartEvent.decode(buffer);
            case CMD_TRUCO_S2C.TRUCO_PLAYERS_EVEVT:
                return truco.TrucoPlayersEvent.decode(buffer);
            case CMD_TRUCO_S2C.TRUCO_STATE_EVENT:
                return truco.TableStateEvent.decode(buffer);
            case CMD_TRUCO_S2C.TRUCO_BET_EVENT:
                return truco.BetEvent.decode(buffer);
            case CMD_TRUCO_S2C.TRUCO_PLAYER_LIST_EVENT:
                return truco.PlayerListEvent.decode(buffer);
            case CMD_TRUCO_S2C.TRUCO_GAME_END_EVENT:
                return truco.GameEndEvent.decode(buffer);
            case CMD_TRUCO_S2C.TRUCO_GAME_RESULT_EVENT:
                return truco.GameResultEvent.decode(buffer);
            //allstar
            // case CMD_ALLSTAR_S2C.ALLSTAR_HISTORY_RESULT_EVENT:
            //     return allstar.HistoryResultEvent.decode(buffer);
            // case CMD_ALLSTAR_S2C.ALLSTAR_GAME_START_EVENT:
            //     return allstar.GameStartEvent.decode(buffer);
            // case CMD_ALLSTAR_S2C.ALLSTAR_PLAYERS_EVEVT:
            //     return allstar.DoublePlayersEvent.decode(buffer);
            // case CMD_ALLSTAR_S2C.ALLSTAR_STATE_EVENT:
            //     return allstar.TableStateEvent.decode(buffer);
            // case CMD_ALLSTAR_S2C.ALLSTAR_BET_EVENT:
            //     return allstar.BetEvent.decode(buffer);
            // case CMD_ALLSTAR_S2C.ALLSTAR_PLAYER_LIST_EVENT:
            //     return allstar.PlayerListEvent.decode(buffer);
            // case CMD_ALLSTAR_S2C.ALLSTAR_GAME_END_EVENT:
            //     return allstar.GameEndEvent.decode(buffer);
            // case CMD_ALLSTAR_S2C.ALLSTAR_GAME_RESULT_EVENT:
            //     return allstar.GameResultEvent.decode(buffer);
            //tower
            case CMD_TOWERLEGEND_S2C.TOWERLEGEND_STATE_REPLY:
                return towerlegend.TowerlegendStateReply.decode(buffer);
            case CMD_TOWERLEGEND_S2C.TOWERLEGEND_BET_REPLY:
                return towerlegend.TowerlegendBetReply.decode(buffer);
            case CMD_TOWERLEGEND_S2C.TOWERLEGEND_DEAL_REPLY:
                return towerlegend.TowerlegendDealReply.decode(buffer);
            case CMD_TOWERLEGEND_S2C.TOWERLEGEND_RESULT_REPLY:
                return towerlegend.TowerlegendResultReply.decode(buffer);
            case CMD_TOWERLEGEND_S2C.TOWERLEGEND_CASH_OUT_REPLY:
                return null;
        }
        return null;
    }
}
