import React from "react";
import svgFile from "assets/symbol.svg";

interface SvgIconProps {
    dataKey: string;
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
}

const SvgIcon: React.FC<SvgIconProps> = ({dataKey, className = "", style = {}, onClick}) => {
    return (
        <svg xmlnsXlink="http://www.w3.org/1999/xlink" className={className} style={style} onClick={onClick}>
            <use xlinkHref={`${svgFile}#${dataKey}`}></use>
        </svg>
    );
};

export default SvgIcon;
