import {useEffect, useState} from "react";
import {useAppSelector} from "store";
import {motion} from "framer-motion";
import AnimateButton from "ui-component/AnimateButton";
import {dispatch} from "store";
import {showDialog} from "store/slices/dialog";
import SvgIcon from "ui-component/SvgIcon";
import logo from "assets/images/logo.png";
import {Link, useLocation} from "react-router-dom";
import {useIntl} from "react-intl";
import {openNotification} from "views/notification";
import CurrencyList from "./components/currencyList";
import User from "./components/user";
import LeftPanel from "../Sidebar/LeftPanel";

import {LoginType} from "types/dialog";
import Search from "ui-component/Search";
import {openRoll} from "views/roulette/RollDialog";

const Header = () => {
    const menuOpen = useAppSelector((state) => state.config.menuOpen);
    const isMobile = useAppSelector((state) => state.config.isMobile);
    const isLogin = useAppSelector((state) => state.user.isLogin);
    const showPddRoll = useAppSelector((state) => state.user.user?.pddActEnable);
    const isPddEnable = useAppSelector((state) => state.config.platformConfig?.isPddEnable);
    const location = useLocation();

    const {$t} = useIntl();
    const isSports = location.pathname.includes("/sports");
    const isRoulette = location.pathname.includes("/roulette");
    const isNormal = isMobile || isSports;

    const [showSearch, setShowSearch] = useState(false);
    const [headerZIndex, setHeaderZIndex] = useState(10);

    const hiddenSearch = () => {
        setShowSearch(false);
        setHeaderZIndex(10);
    };

    useEffect(() => {
        if (isPddEnable === 1 && showPddRoll === 0 && !isRoulette) {
            openRoll();
        }
    }, [isRoulette, isPddEnable, showPddRoll]);

    return (
        <motion.div
            className="fixed z-10 top-0 right-0 h-[60px] bg-gray-600 px-[3vw]"
            style={{boxShadow: "#0003 0 4px 6px -1px, #0000001f 0 2px 4px -1px", zIndex: headerZIndex}}
            initial={{left: isNormal ? 0 : 240}}
            animate={isNormal ? {} : {left: menuOpen ? 240 : 60}}>
            <div className="h-full flex items-center relative">
                {isSports && !isMobile && (
                    <div className="flex flex-col -ml-[3vw] pl-4">
                        <LeftPanel />
                    </div>
                )}
                <div className="page w-full h-11 flex items-center justify-between">
                    <AnimateButton>
                        <Link to="/" keep-scroll-position="true" className="footer-link-item" target="_self">
                            {window.innerWidth < 400 ? (
                                <img src={logo} alt={""} className="w-20 object-contain select-none" />
                            ) : (
                                <img src={logo} alt={""} className="w-24 object-contain select-none" />
                            )}
                        </Link>
                    </AnimateButton>
                    {isLogin && (
                        <>
                            <div className="h-12 bg-[#0f212e] rounded overflow-hidden ml-3">
                                <div className="w-full h-full text-white">
                                    <CurrencyList />
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="max-md:hidden">
                                    <AnimateButton>
                                        <div
                                            className="flex items-center p-3 cursor-pointer"
                                            onClick={() => {
                                                setHeaderZIndex(90);
                                                setShowSearch(true);
                                            }}>
                                            <SvgIcon dataKey="icon-search" style={{fill: "#ffffff", width: 14, height: 14}} />
                                            <div className="ml-2 text-white font-bold text-sm max-lg:hidden">{$t({id: "Search"})}</div>
                                        </div>
                                    </AnimateButton>
                                </div>

                                <User />

                                <AnimateButton>
                                    <div className="flex items-center p-3 cursor-pointer" onClick={() => openNotification()}>
                                        <SvgIcon dataKey="icon-notification" style={{fill: "#ffffff", width: 14, height: 14}} />
                                    </div>
                                </AnimateButton>

                                {/* <div className="max-md:hidden">
                                    <Chat />
                                </div> */}
                            </div>
                        </>
                    )}

                    {!isLogin && (
                        <div className="flex items-center text-white text-sm font-bold">
                            <AnimateButton>
                                <div
                                    className="w-[4.25rem] h-11 flex items-center justify-center cursor-pointer"
                                    onClick={() => {
                                        dispatch(showDialog(LoginType.SIGNIN));
                                    }}>
                                    {$t({id: "SignIn"})}
                                </div>
                            </AnimateButton>
                            <AnimateButton>
                                <div
                                    className="w-[4.25rem] h-11 flex items-center justify-center cursor-pointer bg-[#1475e1] rounded hover:bg-[#105eb4]"
                                    style={{transition: "background-color 0.1s ease-in-out"}}
                                    onClick={() => {
                                        dispatch(showDialog(LoginType.SIGNUP));
                                    }}>
                                    {$t({id: "Register"})}
                                </div>
                            </AnimateButton>
                        </div>
                    )}
                </div>
                {showSearch && (
                    <div className="absolute left-0 top-[60px] w-full z-[99]">
                        <Search
                            placeholderText={$t({id: "SearchYourGame"})}
                            autoFocus={true}
                            cancleHandler={hiddenSearch}
                            overlayHandler={hiddenSearch}
                            gameItemHandler={hiddenSearch}
                        />
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default Header;
