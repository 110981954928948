import SampleLog from "./Common/SampleLog";
import ConnectionHelper from "./Network/ConnectionHelper";
import { CMD_COMMON_C2S } from "./Network/WSProtocol";
import WsMsgDispatcher, { IWsMsgReceiver } from "./Network/WsMsgDispatcher";
import I18NMgr from "./Utils/I18NMgr";
import { Md5 } from "./Utils/Md5";

export enum CommonAction_H52G {
    loadGame = "loadGame",
    switchDayNight = "switchDayNight",
    switchLang = "switchLang",
    resize = "resize",
}

export enum CommonAction_G2H5 {
    inited = "inited",
    loadCompleted = "loadCompleted",
    errorCode = "errorCode",
    hashRound = "hashRound",
    currencyLimit = "currencyLimit",
}

export class currencyUri{
    type:number;
    url:string;
    desc:string;
}

export enum eGames{
    FuryWheel = 8000,
    Crash = 8001,
    Wheel = 8002,
    Plinko = 8003,
    VideoPoker = 8004,
    Limbo = 8005,
    Mines = 8006,
    Hilo = 8007,
    ClassicDice = 8008,
    CoinFlip = 8009,
    Keno = 8010,
    Stairs = 8012,
    Cryptos = 8014,
    RingofFortune = 8015,
    TowerLegend = 8016,
    Double = 8017,
    AllStar = 8018,
    DragonTiger = 8019,
    Truco = 8021,
    PddWheel = 9000,
}

export const GameName = {
    [eGames.Crash]:"Crash",
    [eGames.FuryWheel] :"FuryWheel",
    [eGames.Wheel] :"Wheel",
    [eGames.Plinko] :"Plinko",
    [eGames.Cryptos] :"Cryptos",
    [eGames.Mines] :"Mines",
    [eGames.Limbo] :"Limbo",
    [eGames.Hilo] :"Hilo",
    [eGames.ClassicDice] :"ClassicDice",
    [eGames.CoinFlip] :"CoinFlip",
    [eGames.Keno] :"Keno",
    [eGames.VideoPoker] :"VideoPoker",
    [eGames.RingofFortune] :"RingofFortune",
    [eGames.TowerLegend] :"TowerLegend",
    [eGames.Double] :"Double",
    [eGames.Stairs] :"Stairs",
    [eGames.AllStar] :"AllStar",
    [eGames.DragonTiger] :"DragonTiger",
    [eGames.Truco] :"Truco",
    [eGames.PddWheel] :"PddWheel",
}

export const GamePort = {
    [eGames.FuryWheel] : 3100,
    [eGames.Crash] : 3101,
    [eGames.Wheel] : 3102,
    [eGames.Plinko] : 3103,
    [eGames.VideoPoker] : 3104,
    [eGames.Limbo] : 3105,
    [eGames.Mines] : 3106,
    [eGames.Hilo] : 3107,
    [eGames.ClassicDice] : 3108,
    [eGames.CoinFlip] : 3109,
    [eGames.Keno] : 3110,
    [eGames.Stairs] : 3112,
    [eGames.Cryptos] : 3114,
    [eGames.RingofFortune] : 3115,
    [eGames.TowerLegend] : 3116,
    [eGames.Double] : 3117,
    [eGames.AllStar] : 3118,
    [eGames.DragonTiger] : 3119,
    [eGames.Truco] : 3121,
}

export enum eOrientation{
    LandScape = 1,
    Portrait  = 2,
}

export default abstract class GameApp implements IWsMsgReceiver {
    private _loginToken : string = "";
    private _msgCenter:WsMsgDispatcher = null;
    private _gateMgr:ConnectionHelper = null;
    protected _listenner:any = null;
    protected _currencyList:currencyUri[] = [];
    protected darkMode:boolean = true;
    protected orientation:eOrientation = eOrientation.LandScape;
    protected balanceList:any = null;
    protected currency:number = 0;

    abstract get GameId():number;

    abstract get GamePort():number;

    abstract get GameName():string;

    get Orientation(){
        return this.orientation;
    }

    get MsgCenter(){
        return this._msgCenter;
    }

    get GateMgr(){
        return this._gateMgr;
    }

    public get Currency() {
        return this._currencyList;
    }

    private genToken(token:any, type:any, port:any, gamename:any){
        let md5Sign = Md5.hashStr(`${token}${type}${port}${gamename}V8nbCQNdDyHclbEa`);
        this._loginToken =`${token}:${type}:${port}:${gamename}:${md5Sign}`;
    }

    private getToken(){
        return this._loginToken;
    }

    onNetConnect(){}
    onNetError(){}
    onNetClose(){}
    onMessageError(errorCode:number){
        this.postMessage(CommonAction_G2H5.errorCode,{errorCode:errorCode})
    }
    
    private setloginParam(data:any, port:number){
        let gameid = data["gameId"];
        let gamename = data["gameName"];
        let token = data["token"];
        let currency = data["currency"];
        let gameUrl = data["gameUrl"];

        if(!token || !currency || !gamename || !gameid || !gameUrl){
            SampleLog.Error("loadgame params error, check it please, [token,currency,gamename,gameid,gameurl]:["+token, currency, gamename, gameid, gameUrl+"]");
            return;
        }

        this.genToken(token, currency, port, gamename);
    }

    protected releaseScene(){};

    protected listenFunc(event: any){
        try {
            SampleLog.Log("@@@@receive from H5:" + JSON.stringify(event.detail));
            let msg = event.detail;
            if (!msg || !msg.action) return;
            if(msg.gameId != this.GameId) return;
            this.processMessage(msg.action, msg.param);
        } catch (error) {
            SampleLog.Log("@@@receive from H5:", JSON.stringify(event));
        }
    }

    start(orientation:eOrientation = eOrientation.LandScape) {
        this.orientation = orientation;
        if(!this._listenner){
            this._listenner = this.listenFunc.bind(this);
            window.addEventListener("pageMessage", this._listenner, false);
            this._gateMgr = new ConnectionHelper();
            this._msgCenter = new WsMsgDispatcher();
        }

        // if(!!process.env.REACT_APP_gameDebug){
        //     let token = "S+qOYRKsW73ekwHu5lztjZ9cLufS+9eqT6+PkeE8XDRDMoO7ouKzaHBTeH+7hKsexlNKv1zF/sRqmNdZDcFHBCMGx3TncJOwUkLFLFz8JCZSOgmIUyZSJzrtbCnmIkEJXmnEYI2ORVQw1ALP8B5WGvOJCP7PZSbesWRyhxpj+F4=";
        //     let data = {gameId:this.getGameId(),currency:3,gameName:"Game",token:token,orientation:1, "gameUrl":"https://originals.iboxgame.io/"};
        //     this.connectServer(data, this.getGamePort());
        // }
    }

    sendInited(){
        this.postMessage(CommonAction_G2H5.inited, {});
    }

    sendHashRound(data:any){
        this.postMessage(CommonAction_G2H5.hashRound, data);
    }

    release() {
        !!this._msgCenter && this._msgCenter.stopDispatchMsg();
        !!this._msgCenter && this._msgCenter.clearMsgQueue();
        !!this._gateMgr && this._gateMgr.close();
        if(this._listenner){
            window.removeEventListener("pageMessage", this._listenner);
            this._listenner = null;
        }
        this.releaseScene();
    }

    connectServer(params:any, port:number){
        let gameUrl = params["gameUrl"];
        gameUrl = gameUrl.replace("https:\/\/", "");
        gameUrl = gameUrl.replace("http:\/\/", "");
        let path = gameUrl.split("\/");
        let wsUrl = "wss:\/\/"+path[0]+"\/ws\/{port}";
        wsUrl = wsUrl.replace("{port}", `${port}`);
        this._gateMgr.setUrl(wsUrl);
        SampleLog.Log("#############",wsUrl)

        this.setloginParam(params, port);
        this._gateMgr.init(this._msgCenter, this.getToken());
        !!this._gateMgr && this._gateMgr.connect();
        !!this._msgCenter && this._msgCenter.clearMsgQueue();
    }

    reConnectServer(){
        !!this._gateMgr && this._gateMgr.connect();
        !!this._msgCenter && this._msgCenter.clearMsgQueue();
    }

    protected processMessage(action:string, params:any):boolean{
        switch(action){
            case CommonAction_H52G.loadGame:
                this.connectServer(params,this.GamePort);
                this.postMessage(CommonAction_G2H5.loadCompleted, {});
                this.currency = params["currency"];
                this._currencyList = params["currencyList"];
                this.darkMode = !params["dayOrNight"];
                this.orientation = params["orientation"];
                this.balanceList = params["balanceList"];
                this.switchDayNight();
                return true;
            case CommonAction_H52G.switchDayNight:
                this.darkMode = !params;
                this.switchDayNight();
                return true; 
            case CommonAction_H52G.switchLang:
                let lang = params.lang;
                I18NMgr.Instance.StringTables[lang] = params.config;
                !!lang && I18NMgr.Instance.switch(lang);
                this.switchLang();
                return true; 
            case CommonAction_H52G.resize:
                this.resize();
                return true;   
            default:
                return false;
        }
    };

    resize(){}

    public getCurrency(type:number){
        if(!!this._currencyList && !!this._currencyList.length){
            let currency:currencyUri = null;
            this._currencyList.forEach(c =>{
                if(c.type == type){
                    currency = c;
                }
            });
            return currency;
        }
        return null;
    }

    public getCurrencyRate(type:number){
        if(!this.balanceList || !this.balanceList.length) return 0;
        let elem = this.balanceList.find(balance => balance.type == type);
        if(!elem) return 0;
        return elem.rate;
    }

    switchDayNight(){
    }

    switchLang(){
    }

    checkSupportCurrency(){
        // this._gateMgr.send(CMD_COMMON_C2S.PLAYER_CURRENCY_CHECK_REQ, {currency:this.currency});
    }

    postMessage(action:string, param:any){
        let data = {action:action, gameId:this.GameId, param:param};
        SampleLog.Log("#####post to H5:",JSON.stringify(data));
        let myEvent = new CustomEvent("gameMessage", {detail:data});
        window.dispatchEvent(myEvent);
    }
}
