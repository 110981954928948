import React, {useState, useEffect, useRef} from "react";
import {Link, useLocation} from "react-router-dom";
import {motion, AnimatePresence} from "framer-motion";
import {useAppDispatch, useAppSelector} from "store";
import {useIntl} from "react-intl";
import {setLocale, setMenuOpen, setOdds} from "store/slices/config";
import Tooltip, {Align, Side} from "ui-component/Tooltip";
import SvgIcon from "./SvgIcon";
import {useTabbarContext} from "layout/MainLayout/Tabbar/TabbarContext";

export interface MenuItem {
    title: string;
    menuPath?: string;
    disabled?: boolean;
    icon?: string;
    extraIcon?: React.ReactNode;
    parentMenuId?: string | number;
    menuId?: string | number;
    renderType?: "language" | "odds";
    footer?: Omit<MenuItem, "children">;
    children?: MenuItem[];
    onClick?: () => void;
}

interface Props {
    title?: string;
    menuPath?: string;
    cls?: string;
    open?: boolean;
    carteId: string | number;
    items: MenuItem[];
}

interface SubMenuProps extends Omit<MenuItem, "children"> {
    children?: React.ReactNode;
    open?: boolean;
    childList?: MenuItem[];
}

const emitter = (() => {
    const subscriptions = new Map();
    return {
        emit: (v) => subscriptions.forEach((fn) => fn(v)),
        subscribe: (fn) => {
            const key = Symbol();
            subscriptions.set(key, fn);
            return () => subscriptions.delete(key);
        },
    };
})();

const currentExpandMenu: {id?: string | number} = {
    id: null,
};

const Box: React.FC<{children: React.ReactElement; title: string; open: boolean}> = ({children, title, open}) => {
    if (open) return children;
    return (
        <Tooltip align={Align.start} side={Side.top} alignOffset={-50} content={<span>{title}</span>}>
            {children}
        </Tooltip>
    );
};

const SubMenu: React.FC<SubMenuProps> = (props) => {
    const [expand, setExpand] = useState(false);
    const {children, title, icon, extraIcon, menuId, menuPath = undefined, disabled, parentMenuId, onClick, footer, open, renderType, childList} = props;
    const dispatch = useAppDispatch();
    const {language, odds} = useAppSelector((state) => ({language: state.config.locale, odds: state.config.odds}));
    const hasChildren = children;
    const {$t} = useIntl();
    const tabContext = useTabbarContext();

    const currentId = useRef(menuId);
    const location = useLocation();

    useEffect(() => {
        if (currentExpandMenu.id === currentId.current && open) setExpand(true);
        const unsubscribe = emitter.subscribe((expandId) => {
            if (currentId.current !== expandId) setExpand(false);
        });
        return () => {
            unsubscribe();
        };
    }, []);
    const handleExpand = () => {
        if (hasChildren) {
            if (expand) {
                emitter.emit(undefined);
                currentExpandMenu.id = undefined;
            } else {
                emitter.emit(menuId);
                currentExpandMenu.id = menuId;
            }
            setExpand(!expand);
            dispatch(setMenuOpen(true));
        } else {
            onClick?.();
            tabContext.onClose?.();
        }
    };
    let tranTitle = $t({id: title});
    if (renderType === "language") tranTitle = `${$t({id: title})}: ${$t({id: childList.find((l) => l.icon === language)?.title})}`;
    if (renderType === "odds") tranTitle = `${$t({id: title})}: ${$t({id: childList.find((l) => l.icon === odds)?.title})}`;
    return (
        <>
            <Box title={tranTitle} open={open}>
                <Link to={disabled ? undefined : menuPath}>
                    <div
                        className={`flex justify-between items-center group h-[2.875rem] px-4 rounded-[0.25rem] ${
                            expand || (menuPath && location.pathname.includes(menuPath)) ? "bg-[#213743]" : ""
                        } ${expand && hasChildren ? "" : parentMenuId ? "hover:bg-gray-400" : "hover:bg-[#213743]"} ${
                            disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                        }`}
                        onClick={handleExpand}>
                        <div className={`flex-1 flex overflow-hidden ${open ? "" : "-mx-2 justify-center"}`}>
                            <button>{extraIcon ? extraIcon : <SvgIcon dataKey={icon} className="w-3.5 h-3.5 fill-gray-200 group-hover:!fill-white" />}</button>
                            {open && <span className="truncate text-[0.875rem] font-semibold mx-2">{tranTitle}</span>}
                        </div>

                        {hasChildren &&
                            (open ? (
                                <button className={`w-6 h-6 flex justify-center items-center rounded-full ${expand ? "bg-blue-600" : "bg-gray-400"}`}>
                                    <SvgIcon dataKey="icon-arrowdown" style={{fill: "#fff"}} className={`w-3 h-3 ${expand ? "" : "-rotate-90"}`} />
                                </button>
                            ) : (
                                <SvgIcon dataKey="icon-arrowdown" style={{fill: "#fff"}} className="w-3 h-3 ml-0.5 -mr-3.5 -rotate-90" />
                            ))}
                    </div>
                </Link>
            </Box>
            <AnimatePresence>
                {expand && hasChildren && (
                    <motion.div initial={{opacity: 0, height: 0}} animate={{opacity: 1, height: "auto"}} exit={{opacity: 0, height: 0}}>
                        <div className="w-full h-0.5 bg-gray-400"></div>
                        <div className="bg-[#213743]">{children}</div>
                        {footer && (
                            <div>
                                <Link to={menuPath}>
                                    <div className="w-full h-0.5 bg-gray-400"></div>
                                    <div
                                        className="bg-[#213743] flex justify-between items-center cursor-pointer h-[2.875rem] px-4 hover:bg-gray-400"
                                        onClick={() => footer.onClick?.()}>
                                        <div className="flex-1 flex pr-2 overflow-hidden">
                                            <button className="mr-2">
                                                <SvgIcon dataKey={footer.icon} className="w-3.5 h-3.5" style={{fill: "#fff"}} />
                                            </button>
                                            <span className="truncate text-[0.875rem] font-[600]">{footer.title}</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

const LanguageRender: React.FC<MenuItem> = (props) => {
    const {icon, title} = props;
    const language = useAppSelector((state) => state.config.locale);
    const dispatch = useAppDispatch();
    const selectLanguage = (lang: string) => {
        dispatch(setLocale(lang));
    };
    return (
        <label htmlFor={icon} key={icon}>
            <div className="flex justify-between items-center cursor-pointer h-[2.875rem] px-4 rounded-[0.25rem] bg-[#213743] hover:bg-gray-400">
                <div className="flex-1 flex pr-2 overflow-hidden">
                    <span className="pl-5 truncate text-[0.875rem] font-bold">{title}</span>
                </div>
                <label className="inline-flex items-center relative cursor-pointer">
                    <input
                        onChange={(e) => selectLanguage(e.target.value)}
                        id={icon}
                        type="radio"
                        value={icon}
                        className="absolute left-0 border-none opacity-0 -z-[1] lange_input cursor-pointer"
                        checked={language === icon}
                    />
                    <span className="indicator"></span>
                </label>
            </div>
        </label>
    );
};

const OddsRender: React.FC<MenuItem> = (props) => {
    const {$t} = useIntl();
    const {icon, title} = props;
    const odds = useAppSelector((state) => state.config.odds);
    const dispatch = useAppDispatch();
    const selectOdds = (option: string) => {
        dispatch(setOdds(option));
    };
    return (
        <label htmlFor={icon} key={icon}>
            <div className="flex justify-between items-center cursor-pointer h-[2.875rem] px-4 rounded-[0.25rem] bg-[#213743] hover:bg-gray-400">
                <div className="flex-1 flex pr-2 overflow-hidden">
                    <span className="pl-2 truncate text-[0.875rem] font-bold">{$t({id: title})}</span>
                </div>
                <label className="inline-flex items-center relative cursor-pointer">
                    <input
                        onChange={(e) => selectOdds(e.target.value)}
                        id={icon}
                        type="radio"
                        value={icon}
                        className="absolute left-0 border-none opacity-0 -z-[1] lange_input cursor-pointer"
                        checked={odds === icon}
                    />
                    <span className="indicator"></span>
                </label>
            </div>
        </label>
    );
};

const Expand: React.FC<Props> = ({title, cls, open, items}) => {
    const {$t} = useIntl();
    const renderItem = (item) => {
        const {children, renderType, ...rest} = item;
        if (renderType === "language")
            return (
                <SubMenu {...rest} key={rest.menuId} open={open} renderType={renderType} childList={children}>
                    {children?.map(LanguageRender)}
                </SubMenu>
            );
        if (renderType === "odds")
            return (
                <SubMenu {...rest} key={rest.menuId} open={open} renderType={renderType} childList={children}>
                    {children?.map(OddsRender)}
                </SubMenu>
            );
        return (
            <SubMenu {...rest} key={rest.menuId} open={open}>
                {children?.map(renderItem)}
            </SubMenu>
        );
    };
    return (
        <div className={`rounded-[0.25rem] text-white ${cls ? cls : ""} mb-4 overflow-hidden bg-[#1a2c38]`}>
            {title && (
                <>
                    <div className="flex justify-between items-center cursor-pointer py-[0.8125rem] px-4 text-[0.875rem] font-[600]">{$t({id: title})}</div>
                    <div className="w-full h-0.5 bg-gray-400"></div>
                </>
            )}
            <div>{items.map(renderItem)}</div>
        </div>
    );
};

export default Expand;
