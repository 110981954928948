import client from "./client";

const LOTTERY_URL = window.$config.lottery_url;

const getLotteryBlock = () => client.post(LOTTERY_URL + "/lottery/block");

const getLotteryInfo = () => client.post(LOTTERY_URL + "/lottery/info");

const lotteryLock = (data = {}) => client.post(LOTTERY_URL + "/lottery/lock", data);

const lotteryBet = (data = {}) => client.post(LOTTERY_URL + "/lottery/bet", data);

const getLotteryLockbets = () => client.post(LOTTERY_URL + "/lottery/lockbets");

const getLotteryBethis = (data = {}) => client.post(LOTTERY_URL + "/lottery/bethis", data);

const apiLottery = {
    getLotteryBlock,
    getLotteryInfo,
    getLotteryLockbets,
    getLotteryBethis,
    lotteryLock,
    lotteryBet,
};

export default apiLottery;
