import {useState, useEffect} from "react";
import AnimateButton from "ui-component/AnimateButton";
import SvgIcon from "ui-component/SvgIcon";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "store";
import {dispatch} from "store";
import {showDialog} from "store/slices/dialog";
import {useIntl} from "react-intl";

import SignIn from "./components/signin";
import SignUp from "./components/signup";
import Forget from "./components/forget";
import {LoginType} from "types/dialog";

const Login = () => {
    const {$t} = useIntl();
    const currentDialogID = useAppSelector((state) => state.dialog.currentDialogID);
    const [loginType, setLoginType] = useState("signin");
    const navigate = useNavigate();

    const changeLoginType = (val: string) => {
        setLoginType(val);
    };

    useEffect(() => {
        if (currentDialogID === LoginType.SIGNIN) {
            setLoginType("signin");
        }
        if (currentDialogID === LoginType.SIGNUP) {
            setLoginType("signup");
        }
    }, [currentDialogID]);

    const handleLink = (link: string) => {
        navigate(link);
        dispatch(showDialog(null));
    };

    return (
        <div className="bg-[#1a2c38] text-[#b1bad3] rounded-lg relative p-4">
            <div
                className="absolute top-1 right-0 w-12 h-12 flex items-center justify-center cursor-pointer hover:fill-white"
                onClick={() => {
                    dispatch(showDialog(null));
                }}>
                <AnimateButton>
                    <SvgIcon dataKey="icon-close" className="hover:fill-white fill-[#b1bad3]" style={{width: 16, height: 16}} />
                </AnimateButton>
            </div>
            {loginType === "signin" && <SignIn onChangeLoginType={changeLoginType}></SignIn>}
            {loginType === "signup" && <SignUp onChangeLoginType={changeLoginType}></SignUp>}
            {loginType === "forget" && <Forget onChangeLoginType={changeLoginType}></Forget>}
            {loginType !== "forget" && (
                <div className="flex items-center justify-center flex-wrap text-xs mt-3">
                    {$t({id: "StakeProtectedByHCaptcha"})}&nbsp;
                    <AnimateButton>
                        <span className="cursor-pointer" onClick={() => handleLink("/policies/privacy")}>{$t({id: "PrivacyPolicy"})}&nbsp;</span>
                    </AnimateButton>
                    {$t({id: "And"})}&nbsp;
                    <AnimateButton>{$t({id: "TermsOfService"})}&nbsp;</AnimateButton>
                    {$t({id: "Apply"})}
                </div>
            )}
        </div>
    );
};

export default Login;
