import React, {useState, useEffect, useRef} from "react";
import {useClickAway} from "react-use";
import {useIntl} from "react-intl";
import {motion, AnimatePresence} from "framer-motion";
import {useDebounce} from "react-use";
import SvgIcon from "./SvgIcon";
import AllGallery from "./AllGallery";
import casinoGame from "api/casinoGame";

const SearchBox = (props) => {
    const {$t} = useIntl();
    const {placeholderText, autoFocus = false, cancleHandler = undefined, overlayHandler = undefined, gameItemHandler = undefined} = props;
    const initSearchHistory = JSON.parse(localStorage.getItem("searchHistory")) || [];
    const [searchTerm, setSearchTerm] = useState("");
    const [searchKeyWord, setSearchKeyWord] = useState("");
    const [searchHistory, setSearchHistory] = useState(initSearchHistory);
    const [searchResult, setSearchResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    const SearchRef = useRef(null);
    // useClickAway(SearchRef, (event) => {
    //     event.stopPropagation(); // 阻止事件冒泡
    //     setShowHistory(false);

    //     const overlay = document.getElementById("overlay");
    //     overlay.classList.add("hidden");
    // });
    const clearSearchHistory = () => {
        setSearchHistory([]);
        setSearchResult(null);
        localStorage.removeItem("searchHistory");
        setShowHistory(false);
    };

    const handleRemoveTag = (item) => {
        const newArray = searchHistory.filter((v) => v !== item);
        setSearchHistory([...newArray]);
    };

    const handleCancle = () => {
        setSearchTerm("");
        setShowHistory(false);
        setSearchResult(null);
        cancleHandler && cancleHandler();
    };

    const handlerOverlay = (event) => {
        event.stopPropagation(); // 阻止事件冒泡
        setShowHistory(false);
        if (overlayHandler) {
            overlayHandler();
        } else {
            const overlay = document.getElementById("overlay");
            overlay.classList.add("hidden");
        }
    };

    const getTypeGameListBySearchKey = async (dataStr: string) => {
        setLoading(true);
        try {
            const {status, data} = await casinoGame.getTypeGameList({
                pageId: 0,
                params: dataStr,
                type: 3,
            });
            if (status === 200 && data.code === 0) {
                setSearchResult(data.data.gameList || []);
            } else {
                setSearchResult([]);
            }
            setSearchHistory((prevHistory) => {
                if (prevHistory && prevHistory.length === 0) {
                    return [searchKeyWord, ...prevHistory];
                } else {
                    const findItem = prevHistory.find((f) => f === searchKeyWord.trim());
                    if (!findItem) {
                        if (prevHistory.length < 5) {
                            return [searchKeyWord, ...prevHistory];
                        } else {
                            return [searchKeyWord, ...prevHistory.slice(0, 4)];
                        }
                    } else {
                        return [...prevHistory.slice(0, 5)];
                    }
                }
            });
            // setSearchTerm("");
        } finally {
            setLoading(false);
        }
    };

    useDebounce(
        () => {
            setSearchKeyWord(searchTerm);
        },
        500,
        [searchTerm],
    );

    useEffect(() => {
        if (searchKeyWord && searchKeyWord.length >= 3) getTypeGameListBySearchKey(searchKeyWord);
        if (!searchKeyWord || searchKeyWord.length === 0) setSearchResult(null);
        localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
    }, [searchKeyWord]);

    useEffect(() => {
        if (showHistory) {
            const searchHistoryStr: string = localStorage.getItem("searchHistory");
            setSearchHistory(JSON.parse(searchHistoryStr));
        }
    }, [showHistory]);

    return (
        <>
            <div
                className="relative"
                ref={SearchRef}
                style={{zIndex: 88}}
                onClick={(e) => {
                    e.stopPropagation();
                }}>
                <div className="flex z-50 items-center m-0.5 h-10 px-4 border-2 border-gray-400 hover:border-gray-300 rounded-full bg-gray-700">
                    <SvgIcon dataKey="icon-search" className="w-5 h-5 cursor-pointer" style={{fill: "#557086"}} />
                    <input
                        className="w-full p-1.5 bg-gray-700 focus:outline-none"
                        type="text"
                        autoFocus={autoFocus}
                        placeholder={placeholderText}
                        value={searchTerm.trim()}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onFocus={() => {
                            setShowHistory(true);
                            const overlay = document.getElementById("overlay");
                            overlay.classList.remove("hidden");
                        }}
                        // onBlur={() => {
                        //     setShowHistory(false);
                        //     const overlay = document.getElementById("overlay");
                        //     overlay.classList.add("hidden");
                        // }}
                    />
                    <SvgIcon dataKey="icon-close" className="cursor-pointer w-3.5 h-3.5 fill-current text-gray-300 hover:text-white" onClick={handleCancle} />
                </div>
                {loading ? (
                    <>
                        <div className="animate-pulse grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-2">
                            <div className="bg-gray-300 h-40 w-full rounded-[0.5rem]"></div>
                            <div className="bg-gray-300 h-40 w-full rounded-[0.5rem]"></div>
                            <div className="bg-gray-300 h-40 w-full rounded-[0.5rem]"></div>
                            <div className="bg-gray-300 h-40 w-full rounded-[0.5rem]"></div>
                            <div className="bg-gray-300 h-40 w-full rounded-[0.5rem]"></div>
                            <div className="bg-gray-300 h-40 w-full rounded-[0.5rem]"></div>
                            <div className="bg-gray-300 h-40 w-full rounded-[0.5rem]"></div>
                        </div>
                    </>
                ) : null}
                {searchResult && searchResult.length > 0 ? (
                    <AnimatePresence>
                        <motion.div
                            className={`p-4 shadow-md bg-gray-700 rounded-lg absolute w-full z-50 h-64 overflow-y-auto ${showHistory ? "block" : "hidden"}`}
                            initial={{opacity: 0, y: -10}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: -10}}>
                            <AllGallery CarouselList={searchResult} gameItemHandler={gameItemHandler} />
                        </motion.div>
                    </AnimatePresence>
                ) : null}
                {!searchResult || searchResult.length === 0 ? (
                    <AnimatePresence>
                        {showHistory && (
                            <motion.div
                                className="mt-4 p-4 shadow-md bg-gray-700 rounded-lg absolute w-full z-50"
                                initial={{opacity: 0, y: -10}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: -10}}>
                                <h2 className="mb-2 py-2 text-center text-sm">{searchResult ? $t({id: "Noresultsfound"}) : $t({id: "SearchRules"})}</h2>
                                <div className="grid grid-rows-2 gap-4 text-sm">
                                    <div className="flex justify-between">
                                        <div>{$t({id: "RecentSearches"})}</div>
                                        <div onClick={clearSearchHistory} className="cursor-pointer">
                                            <span>{$t({id: "ClearSearch"})}</span>
                                            <span>({searchHistory ? searchHistory.length : 0})</span>
                                        </div>
                                    </div>
                                    <ul className="flex grad gap-1">
                                        {searchHistory.map((item, index) => (
                                            <motion.div
                                                key={index}
                                                className="inline-flex items-center px-2 py-1 rounded-full bg-gray-400 mr-2 mb-2"
                                                initial={{opacity: 0, scale: 0.8}}
                                                animate={{opacity: 1, scale: 1}}
                                                exit={{opacity: 0, scale: 0.8}}>
                                                <span className="mr-1 cursor-pointer hover:text-white text-xs" onClick={() => setSearchTerm(item)}>
                                                    {item}
                                                </span>
                                                <SvgIcon
                                                    dataKey="icon-close"
                                                    className="cursor-pointer w-3 h-3 fill-current text-gray-200 hover:text-white"
                                                    onClick={() => handleRemoveTag(item)}
                                                />
                                            </motion.div>
                                        ))}
                                    </ul>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                ) : null}
            </div>
            <div
                id="overlay"
                style={{zIndex: 80, pointerEvents: "auto"}}
                className="w-full h-full fixed inset-0 bg-black bg-opacity-60 hidden cursor-default"
                onClick={handlerOverlay}></div>
        </>
    );
};

export default SearchBox;
