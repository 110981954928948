import SampleLog from "../Common/SampleLog";
import { Base64 } from "../Utils/Base64";
import { Md5 } from "../Utils/Md5";
import TimeManager from "../Utils/TimeManager";
import * as wsCore from "./WebsocketCore"
import WsMsgDispatcher from "./WsMsgDispatcher"
import * as WSProto from "./WSProtocol";
import ProtoHelper from "./ProtoHelper";
import { base } from "../Protobuf/message";

interface ReconnectParams{
    heartTime:number,
    retryTimes:number,
}

export interface MessageImpl {
    protoEncode:(protoid: number, data: Object)=> Uint8Array;
    protoDecode:(protoid: number, buffer: Uint8Array)=> Object;
}

 export default class ConnectionHelper{
    private wsInstance:wsCore.WebSocketCore = null;
    private wsUrl:string = null;
    private closeSelf:boolean = false;
    private callback:wsCore.IWsMsgHandler = null;
    private reconnectParams:ReconnectParams = null;
    private lastMsgRevEplase:number = 0;
    private hasReconnectCount:number = 0;
    private heartbeatTimer:any = null;

    private detectTimer:any = null;
    private lastTick:number = 0;

    private protoDelegate:MessageImpl;
    private wsMsgDispatcher:WsMsgDispatcher;

    private token:string = "";

    constructor(){
        this.protoDelegate = new ProtoHelper();
        this.reconnectParams = {
            heartTime: 5, 
            retryTimes: 3, //每次最大的重连次数，超过此值后，不在重试自动连接
        };

        this.callback = {
            onopen : (event:Event)=>{
                this.hasReconnectCount = 0;
                // PopupMgr.hideNetTips();

                this.wsMsgDispatcher.onNetConnect();
                if(this.heartbeatTimer){
                    clearInterval(this.heartbeatTimer);
                    this.heartbeatTimer = null;
                }

                this.heartbeatTimer = setInterval(() => {
                    this.sendHeartBeatMsg();
                }, this.reconnectParams.heartTime*1000);

                this.send(WSProto.CMD_COMMON_C2S.PLAYER_LOGIN_REQ, {token:this.token});
            },

            onmessage: (event:MessageEvent)=>{
                this.lastMsgRevEplase = 0;
                let message = JSON.parse(event.data);
                if(message.msgtype == WSProto.CMD_COMMON_S2C.PONG){
                    SampleLog.HeartBeat('HeartBeatResponse:'+event.data);
                }
                else if(message.msgtype == WSProto.CMD_COMMON_S2C.CONNECTED){
                    let data_str = new Base64().decode(message.msg);
                    let data_buffer:any[] = [];
                    for (let index = 0; index < data_str.length; index++) {
                        data_buffer.push(data_str.charCodeAt(index));
                    }
                    let data = base.Connected.decode(new Uint8Array(data_buffer));
                    TimeManager.getInstance().sync(data["nowTime"] as number);
                }
                else
                {
                    this.decode(message, (message:any) => {
                        if(!message) return;
                        let msg = new WSProto.MsgPackage();
                        msg.id = message.protoid;
                        msg.msg = message.data;
                        this.wsMsgDispatcher.pushMsg(msg);
                    });
                }
            },

            onerror: (event:Event)=>{
                this.wsMsgDispatcher.onNetError();
            },

            onclose:(event:Event)=>{
                SampleLog.Log("#####ConnectionHelper: onclose");

                if(!this.closeSelf){
                    this.wsMsgDispatcher.onNetClose();
                }
            }
        }
    }

    init(dispatcher:WsMsgDispatcher, token:string){
        this.wsMsgDispatcher = dispatcher;
        this.token = token;
    }

    setUrl(url:string){
        this.wsUrl = url;
    }

    connect(){
        if(!this.wsUrl){
            SampleLog.Log("####ConnectionHelper: url cannot be null");
            return;  
        }

        if(this.wsInstance){
            this.wsInstance.removeMsgHandler();
            this.wsInstance.close();
        }

        this.wsInstance = new wsCore.WebSocketCore();
        this.wsInstance.registerMsgHandler(this.callback);
        this.wsInstance.connect(this.wsUrl);

        this.closeSelf = false;
        this.lastMsgRevEplase = 0;
        this.hasReconnectCount++;
        this.startDetectTimer();
    }

    startDetectTimer(){
        clearInterval(this.detectTimer);
        this.detectTimer = setInterval(()=>{
            if(this.lastTick == 0){
                this.lastTick = (new Date()).getTime();
                return;
            }

            //setInterval定时器不准，要校正
            let now = (new Date()).getTime();
            this.update(now-this.lastTick);
            this.lastTick = now;
        }, 1000);
    }

    encode(protoid: number, data: any): string {
        if(protoid == WSProto.CMD_COMMON_C2S.PING){
            SampleLog.HeartBeat("<<<<<<<<<<---------- Encode protoid === " + protoid + " data === " + JSON.stringify(data));
        }
        else{
            SampleLog.Log("<<<<<<<<<<---------- Encode protoid === " + protoid + " data === " + JSON.stringify(data));
        }
        let data_buffer = this.protoDelegate.protoEncode(protoid, data);
        let data_str = String.fromCharCode(...data_buffer);
        let base64_str = new Base64().encode(data_str);
        return base64_str;
    }

    decode(data: any, callback: (message: Object) => void): void {
        let protoid = data["msgtype"];
        let ret:any = {};

        if(protoid == WSProto.CMD_COMMON_S2C.PONG){
            SampleLog.HeartBeat("---------->>>>>>>>>> Decode protoid === "+ protoid + " " + JSON.stringify(data));
            callback(null);
            return;
        }

        if (data["errcode"] != null) {
            SampleLog.Error("---------->>>>>>>>>> Decode protoid === "+ protoid + " " + JSON.stringify(data));
            // PopupMgr.hideNetTips();
            
            this.wsMsgDispatcher.onMessageError(data["errcode"]);
         
            if(data["errcode"] == WSProto.ERROR_CODE.TOKEN_INVALID ||
                data["errcode"] == WSProto.ERROR_CODE.KICK ||
                data["errcode"] == WSProto.ERROR_CODE.GAME_REMOVED ||
                data["errcode"] == WSProto.ERROR_CODE.REPLACE){
                SampleLog.Log("#####Kick ,Remove Game, Replace");
                this.close();
                callback(null);
            }
            return;
        }
        let data_str = new Base64().decode(data["msg"]);
        let data_buffer = [];
        for (let index = 0; index < data_str.length; index++) {
            data_buffer.push(data_str.charCodeAt(index));
        }
        ret["protoid"] = protoid;
        ret["data"] = this.protoDelegate.protoDecode(protoid, new Uint8Array(data_buffer));
        SampleLog.Log("---------->>>>>>>>>> Decode protoid === "+ protoid + " " + JSON.stringify(ret["data"]));
        callback(ret);
    }

    send(protoid: number, data: Object)
    {
        if(!this.wsInstance){
            SampleLog.Log("####ConnectionHelper: wsinstance is null, please create at first");
            return;
        }

        let msg = this.encode(protoid, data);
        let time = Math.floor(TimeManager.getInstance().now() / 1000);
        let message = {
            msgtype: protoid,
            msg: msg,
            time: time,
            sign: Md5.hashStr(`${protoid}${msg}${time}vNqChg2BpMCoykK0`)
        };
        this.wsInstance.send(JSON.stringify(message));
    }

    close()
    {
        this.closeSelf = true;

        if(this.heartbeatTimer){
            clearInterval(this.heartbeatTimer);
            this.heartbeatTimer = null;
        }

        if(this.detectTimer){
            clearInterval(this.detectTimer);
            this.detectTimer = null;
        }

        if(!this.wsInstance){
            SampleLog.Log("####ConnectionHelper: wsinstance is null, no need to close");
            return;
        }

        this.wsInstance.removeMsgHandler();
        this.wsInstance.close();
        this.wsInstance = null;
    }

    sendHeartBeatMsg(){
        this.send(WSProto.CMD_COMMON_C2S.PING, {});
    }

    RespHeartBeatMsg(){
    }

    //dt:ms
    update(dt:number){
        this.lastMsgRevEplase += dt;
        if(this.lastMsgRevEplase >= this.reconnectParams.heartTime*2*1000){
            SampleLog.Log("#####try reconnet times:"+this.hasReconnectCount);
            this.lastMsgRevEplase = 0;
            // if(this.hasReconnectCount >= this.reconnectParams.retryTimes){
            //     this.closeSelf = true;//重连超过最大次数后，主动复位ws连接状态，停止超时检测
            //     SampleLog.Log("#####reconnect maxtimes still fail, stop auto reconnect");
            //     this.hasReconnectCount = 0;
            //     if(this.heartbeatTimer){
            //         clearInterval(this.heartbeatTimer);
            //         this.heartbeatTimer = null;
            //     }

            //     if(this.detectTimer){
            //         clearInterval(this.detectTimer);
            //         this.detectTimer = null;
            //     }
                
            //     PopupMgr.hideNetTips();
            
            // GameApp.postMessage(CommonAction_G2H5.errorCode, {errorCode:10000});

            //     return;
            // }


            // PopupMgr.showNetTips(gameString[STRING.RECONN_NET]);
            this.wsMsgDispatcher.clearMsgQueue();
            this.connect();
        }
    }
}
