import SvgIcon from "ui-component/SvgIcon";
import {Link} from "react-router-dom";
import AnimateButton from "ui-component/AnimateButton";
import {useIntl} from "react-intl";
import "styles/casinoGame.scss";
import {showLiveChat} from "hooks/useLiveChat";

import pGsoft from "assets/images/gameProvider/PGsoft.png";
import belatra from "assets/images/gameProvider/belatra.png";
import playingIo from "assets/images/gameProvider/playing.io.png";
import pushgaming from "assets/images/gameProvider/pushgaming.png";
import habanero from "assets/images/gameProvider/habanero.png";
import spinomenal from "assets/images/gameProvider/spinomenal.png";
import gamomat from "assets/images/gameProvider/gamomat.png";
import Hacksaw from "assets/images/gameProvider/Hacksaw.png";
import KAgaming from "assets/images/gameProvider/KAgaming.png";
import evolution from "assets/images/gameProvider/evolution.png";
import pragmaticplay from "assets/images/gameProvider/pragmaticplay.png";
import bgaming from "assets/images/gameProvider/bgaming.png";
import Playgo from "assets/images/gameProvider/Playgo.png";
import nolimit from "assets/images/gameProvider/nolimit.png";
import evoplay from "assets/images/gameProvider/evoplay.png";
import rela from "assets/images/gameProvider/rela.png";
import quickspin from "assets/images/gameProvider/quickspin.png";
import playtech from "assets/images/gameProvider/playtech.png";
import FooterLanguage from "./components/footerLanguage";
import AlfaLogo from "assets/footer/alfa-logo.51289638.svg";
import evertonLogo from "assets/footer/everton-logo.64c62448.svg";
import UfcPartner from "assets/footer/ufc-partner.49329c95.svg";
import gamcare from "assets/footer/gamcare.png";
import aware from "assets/footer/aware.png";
import respon from "assets/footer/respon.png";
import sigma from "assets/footer/sigma.png";
import eighteen from "assets/footer/eighteen.png";
import logo from "assets/images/logo.png";

const Footer = () => {
    const {$t} = useIntl();

    const linkList = [
        {
            title: $t({id: "Casino"}),
            children: [
                {
                    name: $t({id: "Games"}),
                    isOutLink: false,
                    link: "/casino/home",
                },
                {
                    name: $t({id: "Slots"}),
                    isOutLink: false,
                    link: "/casino/group/slots",
                },
                {
                    name: $t({id: "LiveCasino"}),
                    isOutLink: false,
                    link: "/casino/group/live-casino",
                },
                {
                    name: $t({id: "NewReleases"}),
                    isOutLink: false,
                    link: "/casino/group/new-releases",
                },
                {
                    name: $t({id: "Recommend"}),
                    isOutLink: false,
                    link: "/casino/group/recommend",
                },
                {
                    name: $t({id: "TableGames"}),
                    isOutLink: false,
                    link: "/casino/group/table-game",
                },
                {
                    name: $t({id: "Blackjack"}),
                    isOutLink: false,
                    link: "/casino/group/blackjack",
                },
                {
                    name: $t({id: "Roulette"}),
                    isOutLink: false,
                    link: "/casino/group/roulette",
                },
                {
                    name: $t({id: "Baccarat"}),
                    isOutLink: false,
                    link: "/casino/group/baccarat",
                },
            ],
        },
        // {
        //     title: $t({id: "Lottery"}),
        //     children: [
        //         {
        //             name: $t({id: "A9Lottery"}),
        //             isOutLink: false,
        //             link: "/lottery-home",
        //         },
        //     ],
        // },
        {
            title: $t({id: "CasinoPromo"}),
            children: [
                {
                    name: $t({id: "VIPClub"}),
                    isOutLink: false,
                    link: "vip-club",
                },
                {
                    name: $t({id: "Promotions"}),
                    isOutLink: false,
                    link: "/promotion",
                },
            ],
        },
        {
            title: $t({id: "Support"}),
            children: [
                {
                    name: $t({id: "GambleAware"}),
                    isOutLink: false,
                    link: "/policies/GambleAware",
                },
                {
                    name: $t({id: "FAQ"}),
                    isOutLink: false,
                    link: "/policies/FAQ",
                },
                {
                    name: $t({id: "PrivacyPolicy"}),
                    isOutLink: false,
                    link: "/policies/privacy",
                },
                {
                    name: $t({id: "TermsAndConditions"}),
                    isOutLink: false,
                    link: "/policies/TermsAndConditions",
                },
                {
                    name: $t({id: "ResponsibleGambling"}),
                    isOutLink: false,
                    link: "/policies/ResponsibleGambling",
                },
                {
                    name: $t({id: "AML"}),
                    isOutLink: false,
                    link: "/policies/AML",
                },
                // {
                //     name: $t({id: "DesignResources"}),
                //     isOutLink: false,
                //     link: "/",
                // },
                {
                    name: $t({id: "SelfExclusion"}),
                    isOutLink: false,
                    link: "/policies/SelfExclusion",
                },
                {
                    name: $t({id: "LiveSupport"}),
                    isOutLink: false,
                    clickHandler: showLiveChat,
                },
                // {
                //     name: $t({id: "APP"}),
                //     isOutLink: false,
                //     link: "/",
                // },
                {
                    name: $t({id: "KYC"}),
                    isOutLink: false,
                    link: "/policies/KYCPolicy",
                },
            ],
        },
        {
            title: $t({id: "Community"}),
            children: [
                {
                    name: $t({id: "Discord"}),
                    isOutLink: true,
                    link: "https://discord.gg/C3Umswbgd3",
                },
                {
                    name: $t({id: "Telegram"}),
                    isOutLink: true,
                    link: "https://t.me/a9gamechannel",
                },
                {
                    name: $t({id: "Twitter"}),
                    isOutLink: true,
                    link: "https://twitter.com/A9GameOfficial",
                },
            ],
        },
        // {
        //     title: $t({id: "AboutUs"}),
        //     children: [
        //         {
        //             name: $t({id: "PrivacyPolicy"}),
        //             isOutLink: false,
        //             link: "policies",
        //         },
        //         {
        //             name: $t({id: "Licenses"}),
        //             isOutLink: false,
        //             link: "/",
        //         },
        //         {
        //             name: $t({id: "AMLPolicy"}),
        //             isOutLink: false,
        //             link: "/",
        //         },
        //         {
        //             name: $t({id: "TermsOfService"}),
        //             isOutLink: false,
        //             link: "/",
        //         },
        //         {
        //             name: $t({id: "SelfExclusion"}),
        //             isOutLink: false,
        //             link: "/",
        //         },
        //         {
        //             name: $t({id: "Primedice"}),
        //             isOutLink: true,
        //             link: "/",
        //         },
        //     ],
        // },
    ];

    const providerList = [
        {img: pGsoft, count: 94, providerName: "PGSoft"},
        {img: playingIo, count: 13, providerName: "Originals"},
        {img: evolution, count: 371, providerName: "Evolution"},
        {img: pragmaticplay, count: 274, providerName: "Pragmatic Play "},
        {img: bgaming, count: 106, providerName: "BGaming"},
        {img: Playgo, count: 281, providerName: "Play’N Go"},
        {img: nolimit, count: 130, providerName: "NolimitCity"},
        {img: evoplay, count: 191, providerName: "Evoplay"},
        {img: rela, count: 89, providerName: "Relax"},
        {img: quickspin, count: 129, providerName: "Quickspin"},
        {img: playtech, count: 411, providerName: "Playtech"},
        {img: spinomenal, count: 298, providerName: "Spinomenal"},
        {img: belatra, count: 83, providerName: "Belatra"},
        {img: pushgaming, count: 29, providerName: "Push Gaming"},
        {img: habanero, count: 183, providerName: "Habanero"},
        {img: gamomat, count: 258, providerName: "Gamomat"},
        {img: Hacksaw, count: 214, providerName: "Hacksaw"},
        {img: KAgaming, count: 470, providerName: "KA Gaming"},
    ];

    return (
        <div className="bg-[#071d2a] py-8">
            <div className="w-full max-w-[1200px] mx-auto">
                <div className="px-[3vw]">
                    <div className="w-full grid gap-4" style={{gridTemplateColumns: "repeat(auto-fit,minmax(14ch,1fr))"}}>
                        {linkList.map((v) => {
                            return (
                                <div key={v.title} className="font-semibold text-[#b1bad3] text-sm">
                                    <div className="text-white text-base mb-1">{v.title}</div>
                                    {v.children.map((v1, key1) => {
                                        return v1.link ? (
                                            <Link
                                                key={key1}
                                                to={v1.link}
                                                keep-scroll-position="true"
                                                target={v1.isOutLink ? "_blank" : "_self"}
                                                className="flex hover:text-white">
                                                <AnimateButton>
                                                    <div className="flex items-center h-5">
                                                        <div>{v1.name}</div>
                                                        {v1.isOutLink && (
                                                            <SvgIcon dataKey="icon-share" className="ml-2" style={{fill: "#b1bad3", width: 13, height: 13}} />
                                                        )}
                                                    </div>
                                                </AnimateButton>
                                            </Link>
                                        ) : (
                                            <AnimateButton>
                                                <div className="flex items-center h-5 cursor-pointer hover:text-white" onClick={v1.clickHandler}>
                                                    <div>{v1.name}</div>
                                                    {v1.isOutLink && (
                                                        <SvgIcon dataKey="icon-share" className="ml-2" style={{fill: "#b1bad3", width: 13, height: 13}} />
                                                    )}
                                                </div>
                                            </AnimateButton>
                                        );
                                    })}
                                </div>
                            );
                        })}
                        {/*
                        <div className="font-semibold text-[#b1bad3] text-sm">
                            <div className="text-white text-base mb-1">{$t({id: "Language"})}</div>
                            <div className="text-white text-base mb-1 flex items-center">
                                <FooterLanguage />
                            </div>
                            <div className="text-white text-base mb-1">{$t({id: "Odds"})}</div>
                            <div className="text-white text-base mb-1">小款式</div>
                        </div>
                        */}
                    </div>
                </div>
                <div className="w-full my-8 h-[2px] bg-[#213743]"></div>
                <div className="w-full flex items-center justify-between flex-wrap px-10">
                    {/*
                    providerList.map((v, key) => {
                        return <img key={key} src={v.img} className="h-10 object-contain" alt="" />;
                    })
                    */}
                    <img src={sigma} className="h-[4.5rem] object-contain" alt="" />
                    <img src={aware} className="h-[4.5rem] object-contain" alt="" />
                    <img src={gamcare} className="h-[4.5rem] object-contain" alt="" />
                    <img src={respon} className="h-[4.5rem] object-contain" alt="" />
                    <img src={eighteen} className="h-[4.5rem] object-contain" alt="" />
                </div>
                <div className="w-full my-8 h-[2px] bg-[#213743]"></div>

                <div className="grid gap-2 grid-flow-row items-center justify-center text-sm">
                    <img src={logo} className="w-20 object-contain mx-auto" alt="" />
                    {/*<SvgIcon dataKey="icon-stake-logo" className="mx-auto" style={{fill: "#ffffff", width: 67, height: 33, color: "#ffffff"}} />*/}
                    <div className="text-[#b1bad3]">© 2024 a9.game | {$t({id: "AllRightsReserved"})}</div>
                    {/*<div className="text-center">1 BTC = US$26,553.37</div>*/}
                </div>
                <div className="text-[0.75rem] leading-1 mt-8 text-center font-normal">{$t({id: "StakeDesc"})}</div>
                <div className="w-full mt-8 text-center text-[#b1bad3] text-[0.75rem] leading-1 font-normal">
                    {$t({id: "Support"})}
                    <span className="text-white"> support@a9.game </span>| {$t({id: "Partners"})}
                    <span className="text-white"> partners@a9.game </span>| {$t({id: "Press"})}
                    <span className="text-white"> press@a9.game </span>
                </div>
                <div className="w-full mt-8 flex items-center justify-center">
                    <div
                        id={`apg-${"e58cefb9-b309-47df-abb2-05634f331d48"}`}
                        data-apg-seal-id={"e58cefb9-b309-47df-abb2-05634f331d48"}
                        data-apg-image-size="256"
                        className="apg-seal-container"
                        data-apg-image-type="basic-light-large"
                        style={{width: 40, height: 40}}
                    />
                </div>
            </div>
        </div>
    );
};

export default Footer;
