import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import * as Popover from "@radix-ui/react-popover";
import AnimateButton from "ui-component/AnimateButton";
import SvgIcon from "ui-component/SvgIcon";
import {useDebounce} from "react-use";
import useCurrencyList from "hooks/useCurrency";
import {useAppSelector, dispatch} from "store";
import {CurrencyType} from "types/config";
import {showDialog} from "store/slices/dialog";
import {setUserCurrency} from "store/slices/user";
import {useIntl} from "react-intl";
import AnimatedNumber from "animated-number-react";
import {CurrencySymbol} from "utils/constants";
import {getCurrencyIcon, toLocaleNumber} from "utils/help";
import {setWalletType} from "store/slices/config";
import {WalletType} from "types/config";
import {MoneyType} from "types";

const CurrencyList = () => {
    const {pathname} = useLocation();
    const balance = useAppSelector((state) => state.user.balance);
    const userMainToken = useAppSelector((state) => state.user.userMainToken);
    const userIsLegalCurrency = useAppSelector((state) => state.user.userIsLegalCurrency);
    const userCurrency = useAppSelector((state) => state.user.userCurrency);
    const {balanceIsNotZeroCurrencyList, getAmountWithCurrencySymbolAndMainToken, totalAssets} = useCurrencyList();
    const [isOpen, setIsOpen] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [currencyDict, setCurrencyDict] = useState<any>({bonus: [], fiat: [], crypto: []});
    const {$t} = useIntl();
    const [isPlaying, setIsPlaying] = useState(false);
    useEffect(() => {
        pathname.indexOf("/game-detail") > -1 ? setIsPlaying(true) : setIsPlaying(false);
    }, [pathname]);
    useDebounce(
        () => {
            setSearchResult(balanceIsNotZeroCurrencyList.filter((v) => v.tokenName.toUpperCase().indexOf(keyword.toUpperCase()) >= 0));
        },
        300,
        [keyword],
    );

    useEffect(() => {
        setDataList(balanceIsNotZeroCurrencyList);
        setCurrencyDict(
            balanceIsNotZeroCurrencyList.reduce((pre, cur) => {
                if (cur.categoryId === CurrencyType.Platform) {
                    if (!pre.bonus) pre.bonus = [];
                    pre.bonus.push(cur);
                    return pre;
                }
                if (cur.categoryId === CurrencyType.Fiat) {
                    if (!pre.fiat) pre.fiat = [];
                    pre.fiat.push(cur);
                    return pre;
                }
                if (!pre.crypto) pre.crypto = [];
                pre.crypto.push(cur);
                return pre;
            }, Object.create(null)),
        );
    }, [balanceIsNotZeroCurrencyList]);

    const setCurrency = (v: {tokenId: number}) => {
        const tokenId = v.tokenId;
        dispatch(setUserCurrency(tokenId));
        setIsOpen(false);
    };

    const getBalanceAmount = (type: number) => {
        const obj = balance.find((v) => v.type === type);
        let amount = 0;
        if (userIsLegalCurrency) {
            const amountCurrency = obj?.amount || 0;
            const rateCurrency = obj?.rate || 0;
            const rateMainToken = balance.find((v) => v.type === userMainToken)?.rate || 0;
            amount = (amountCurrency * rateCurrency) / rateMainToken;
        } else {
            amount = obj?.amount;
        }
        return amount;
    };

    const renderMainView = () => {
        if (keyword.trim().length > 0) {
            return (
                <div className="w-full flex-1 overflow-x-hidden overflow-y-auto">
                    {searchResult.length > 0 &&
                        searchResult.map((v, key) => {
                            return (
                                <div
                                    key={key}
                                    className="w-full px-3 text-sm cursor-pointer hover:bg-[#b1bad3]"
                                    onClick={() => {
                                        setCurrency(v);
                                    }}>
                                    <AnimateButton>
                                        <div className="w-full h-9 flex items-center justify-between">
                                            <div className="flex items-center justify-start ml-2">
                                                <img src={getCurrencyIcon(v.tokenId)} className="w-4 h-4 mr-1" alt="" />
                                                {v.tokenName}
                                            </div>
                                            <div className="w-32 flex justify-end">{getAmountWithCurrencySymbolAndMainToken(v)}</div>
                                        </div>
                                    </AnimateButton>
                                </div>
                            );
                        })}
                </div>
            );
        }
        const bonusData = currencyDict.bonus || [];
        const fiatData = currencyDict.fiat || [];
        const cryptoData = currencyDict.crypto || [];
        return (
            <div className="w-full flex-1 overflow-x-hidden overflow-y-auto">
                {bonusData.length > 0 && <div className="w-full px-3 text-xs text-[#2F4553] font-bold">Bonus</div>}
                {bonusData.map((v, key) => {
                    return (
                        <div
                            key={key}
                            className="w-full px-3 text-sm cursor-pointer hover:bg-[#b1bad3]"
                            onClick={() => {
                                setCurrency(v);
                            }}>
                            <AnimateButton>
                                <div className="w-full h-9 flex items-center justify-between">
                                    <div className="flex items-center justify-start">
                                        <img src={getCurrencyIcon(v.tokenId)} className="w-4 h-4 mr-1" alt="" />
                                        {v.tokenName}
                                    </div>
                                    <div className="w-32 flex justify-end">{getAmountWithCurrencySymbolAndMainToken(v)}</div>
                                </div>
                            </AnimateButton>
                        </div>
                    );
                })}
                {fiatData.length > 0 && <div className="w-full px-3 text-xs text-[#2F4553] font-bold">Fiat</div>}
                {fiatData.map((v, key) => {
                    return (
                        <div
                            key={key}
                            className="w-full px-3 text-sm cursor-pointer hover:bg-[#b1bad3]"
                            onClick={() => {
                                setCurrency(v);
                            }}>
                            <AnimateButton>
                                <div className="w-full h-9 flex items-center justify-between">
                                    <div className="flex items-center justify-start">
                                        <img src={getCurrencyIcon(v.tokenId)} className="w-4 h-4 mr-1" alt="" />
                                        {v.tokenName}
                                    </div>
                                    <div className="w-32 flex justify-end">{getAmountWithCurrencySymbolAndMainToken(v)}</div>
                                </div>
                            </AnimateButton>
                        </div>
                    );
                })}
                {cryptoData.length > 0 && <div className="w-full px-3 text-xs text-[#2F4553] font-bold">Crypto</div>}
                {cryptoData.map((v, key) => {
                    return (
                        <div
                            key={key}
                            className="w-full px-3 text-sm cursor-pointer hover:bg-[#b1bad3]"
                            onClick={() => {
                                setCurrency(v);
                            }}>
                            <AnimateButton>
                                <div className="w-full h-9 flex items-center justify-between">
                                    <div className="flex items-center justify-start">
                                        <img src={getCurrencyIcon(v.tokenId)} className="w-4 h-4 mr-1" alt="" />
                                        {v.tokenName}
                                    </div>
                                    <div className="w-32 flex justify-end">{getAmountWithCurrencySymbolAndMainToken(v)}</div>
                                </div>
                            </AnimateButton>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="w-full h-full">
            <Popover.Root
                onOpenChange={(open) => {
                    setIsOpen(open);
                    setKeyword("");
                }}
                open={isOpen}>
                <Popover.Trigger className="w-full h-full flex items-center">
                    <div className="w-full h-full flex items-center">
                        <div className="flex-1 h-full px-3">
                            <AnimateButton>
                                <div className="w-full h-12 flex items-center justify-center">
                                    <div className="text-white font-semibold">
                                        <AnimatedNumber
                                            value={getBalanceAmount(userCurrency)}
                                            duration={300}
                                            formatValue={(value: number) => {
                                                const obj = balance.find((v) => v.type === userCurrency);
                                                if (!isPlaying) {
                                                    return obj?.categroy === CurrencyType.Fiat
                                                        ? CurrencySymbol[obj.type] && userIsLegalCurrency
                                                            ? CurrencySymbol[obj.type] + value.toFixed(2)
                                                            : value.toFixed(2)
                                                        : CurrencySymbol[userMainToken] && userIsLegalCurrency
                                                        ? CurrencySymbol[userMainToken] + value.toFixed(5)
                                                        : value.toFixed(5);
                                                } else {
                                                    return "(In Play)";
                                                }
                                            }}
                                        />
                                    </div>
                                    <img src={getCurrencyIcon(userCurrency)} className="w-3 h-3 ml-1 flex-shrink-0" alt="" />
                                    <SvgIcon dataKey="icon-down" className="ml-2 flex-shrink-0" style={{fill: "#ffffff", width: 14, height: 14}} />
                                </div>
                            </AnimateButton>
                        </div>
                        <div
                            className="flex-shrink-0 h-full bg-[#1475e1] flex items-center justify-center text-white text-sm px-4 font-semibold"
                            style={{boxShadow: "0rem .0625rem .1875rem #00000033 , 0rem .0625rem .125rem #0000001f"}}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsOpen(false);
                                dispatch(showDialog("wallet"));
                                dispatch(setWalletType(WalletType.DEPOSIT));
                            }}>
                            <AnimateButton>
                                <span className="font-bold">{$t({id: "Wallet"})}</span>
                            </AnimateButton>
                        </div>
                    </div>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content className="bg-white rounded overflow-hidden z-[101]" sideOffset={10}>
                        <Popover.Arrow className="fill-white" />
                        <div className="w-full h-full max-h-[440px] flex flex-col text-[#2f4553] font-semibold">
                            <div className="pt-2 pb-3 bg-white w-full px-3">
                                <div className="w-full h-10 flex items-center rounded" style={{border: "2px solid #d5dceb"}}>
                                    <SvgIcon dataKey="icon-search" className="mx-2 flex-shrink-0" style={{fill: "#b1bad3", width: 16, height: 16}} />
                                    <input
                                        className="w-32 pr-2 outline-none"
                                        type="text"
                                        value={keyword}
                                        placeholder={$t({id: "Search"})}
                                        onChange={(e) => {
                                            setKeyword(e.target.value);
                                        }}
                                    />
                                    <SvgIcon
                                        onClick={() => setKeyword("")}
                                        dataKey="icon-close"
                                        className="flex-shrink-0 cursor-pointer"
                                        style={{fill: "#b1bad3", width: 14, height: 14}}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-center items-center px-3 rounded">
                                <div
                                    className="h-10 w-full bg-[#282632] rounded flex items-center justify-between px-2"
                                    style={{background: "rgba(38, 64, 77, 0.10)"}}>
                                    <span className="text-xs font-bold text-[#0E212E]">Total assets</span>
                                    <span className="inline-flex items-center">
                                        <img src={getCurrencyIcon(MoneyType.USDT)} className="w-4 h-4 mx-1 flex-shrink-0" alt="" />
                                        <span className="text-xs font-bold text-[#0E212E]">{toLocaleNumber(totalAssets)}</span>
                                    </span>
                                </div>
                            </div>
                            <div className="mt-2">{renderMainView()}</div>
                            {/*
                            <div className="flex-shrink-0" style={{borderTop: "2px solid #d5dceb"}}>
                                <AnimateButton>
                                    <div
                                        className="w-full h-10 flex items-center justify-center cursor-pointer"
                                        onClick={() => {
                                            setIsOpen(false);
                                            dispatch(showDialog("walletSettings"));
                                        }}>
                                        <SvgIcon dataKey="icon-wallet" className="mx-1 flex-shrink-0" style={{fill: "#2f4553", width: 16, height: 16}} />
                                        {$t({id: "WalletSettings"})}
                                    </div>
                                </AnimateButton>
                            </div>
                            */}
                        </div>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </div>
    );
};

export default CurrencyList;
