import {useMemo} from "react";
import {useAppSelector} from "store";
import {CurrencySymbol} from "utils/constants";
import {CurrencyType} from "types/config";
import {MoneyType} from "types";

const useCurrency = () => {
    const platformConfig = useAppSelector((state) => state.config.platformConfig);
    const userHiddenBalanceInZero = useAppSelector((state) => state.user.userHiddenBalanceInZero);
    const userIsLegalCurrency = useAppSelector((state) => state.user.userIsLegalCurrency);
    const balance = useAppSelector((state) => state.user.balance);
    const userMainToken = useAppSelector((state) => state.user.userMainToken);

    const currencyList = useMemo(() => {
        return platformConfig.currencyList.filter((v) => v.status === 0);
    }, [platformConfig]);

    const gameCurrencyOptions = useMemo(() => {
        return platformConfig.convertedToken;
    }, [platformConfig.convertedToken]);

    const mainTokenOptions = useMemo(() => {
        return platformConfig.mainToken;
    }, [platformConfig.mainToken]);

    const balanceIsNotZeroCurrencyList = useMemo(() => {
        const currencyListTemp: any[] = [];
        currencyList.forEach((item) => {
            const obj = balance.find((v) => v.type === item.tokenId);
            obj && currencyListTemp.push({...item, ...obj, lastAmount: obj.amount * obj.rate});
        });
        if (userHiddenBalanceInZero) {
            return currencyListTemp.filter((v) => v.amount && v.amount !== 0);
        } else {
            return currencyListTemp;
        }
    }, [balance, currencyList, userHiddenBalanceInZero]);

    const totalAssets = useMemo(() => {
        let total = balance.find((c) => c.type === MoneyType.USDT)?.amount || 0;
        balance.forEach((x) => {
            if (x.type !== MoneyType.USDT) {
                total += x.amount * x.rate;
            }
        });
        return total;
    }, [balance]);

    const getAmountWithCurrencySymbolAndMainToken = (objParam: any) => {
        const obj = objParam;
        let amount = 0;
        if (userIsLegalCurrency) {
            const amountCurrency = obj?.amount || 0;
            const rateCurrency = obj?.rate || 0;
            const rateMainToken = balance.find((v) => v.type === userMainToken)?.rate || 0;
            amount = (amountCurrency * rateCurrency) / rateMainToken;
        } else {
            amount = obj?.amount;
        }
        const categroy = obj?.categroy;
        if (categroy === CurrencyType.Fiat) {
            return CurrencySymbol[obj.tokenId] && userIsLegalCurrency ? CurrencySymbol[obj.tokenId] + amount.toFixed(2) : amount.toFixed(2);
        }
        return CurrencySymbol[userMainToken] && userIsLegalCurrency ? CurrencySymbol[userMainToken] + amount.toFixed(5) : amount.toFixed(5);
    };

    return {currencyList, gameCurrencyOptions, mainTokenOptions, balanceIsNotZeroCurrencyList, getAmountWithCurrencySymbolAndMainToken, totalAssets};
};

export default useCurrency;
