import {FC} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";

const LeftPanel: FC<{small?: boolean}> = ({small = false}) => {
    const {$t} = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const handleSelectMenu = (e) => {
        const {menu} = e.currentTarget.dataset;
        if (menu === "casino") navigate("/casino/home");
        if (menu === "furnace") navigate("/furnace/home");
    };
    if (small) {
        return (
            <div className="flex flex-col items-center h-[55px] w-[44px] gap-2 mb-3 flex-shrink-0">
                <div
                    className={`casino_small w-full ${location.pathname.includes("casino") || location.pathname.includes("game-detail") ? "active" : ""}`}
                    data-menu="casino"
                    onClick={handleSelectMenu}></div>
                {/*
                <div
                    className={`sport_small w-full ${location.pathname.includes("furnace") ? "active" : ""}`}
                    data-menu="furnace"
                    onClick={handleSelectMenu}></div>
                */}
            </div>
        );
    }
    return (
        <div className="flex items-center gap-2 pr-6 w-1/2 h-full">
            <div
                className={`casino ${location.pathname.includes("casino") || location.pathname.includes("game-detail") ? "active" : ""} min-w-[4rem]`}
                data-menu="casino"
                onClick={handleSelectMenu}>
                <span className="text-white text-sm font-bold uppercase">{$t({id: "Casino"})}</span>
            </div>
            {/*
            <div className={`sport ${location.pathname.includes("furnace") ? "active" : ""} min-w-[4rem]`} data-menu="furnace" onClick={handleSelectMenu}>
                <span className="text-white text-sm font-bold uppercase">Furnace</span>
            </div>
            */}
        </div>
    );
};

export default LeftPanel;
