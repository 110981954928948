import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GameState, LatestBetOrder, GameInfo} from "types/game";

const initialState: GameState = {
    previewPages: [[]],
    previewPage: 0,
    gameList: [],
};
export const game = createSlice({
    name: "game",
    initialState,
    reducers: {
        setGameList: (state, action: PayloadAction<GameInfo[]>) => {
            state.gameList = action.payload;
        },
        setPreviewPages: (state, action: PayloadAction<number[][]>) => {
            state.previewPages = action.payload;
        },
        setPreviewPage: (state, action: PayloadAction<number>) => {
            state.previewPage = action.payload;
        },
        updateLatestBets: (state, action: PayloadAction<LatestBetOrder>) => {
            state.latestBetOrder = action.payload;
        },
        updateHighRollers: (state, action: PayloadAction<LatestBetOrder>) => {
            state.highRollers = action.payload;
        },
    },
});

export const {updateLatestBets, updateHighRollers, setPreviewPages, setPreviewPage, setGameList} = game.actions;

export default game.reducer;
