import React, {useState, useEffect} from "react";
import {motion} from "framer-motion";
import SvgIcon from "ui-component/SvgIcon";
import {Animate, easeInOutQuart} from "utils/help";

export const AnimateValue: React.FC<{value: number; during?: number}> = ({value, during = 800}) => {
    const [finalValue, setFinalValue] = useState(0);
    useEffect(() => {
        Animate(0, value, during, setFinalValue, easeInOutQuart);
    }, [value]);
    return <>{finalValue.toFixed(2)}</>;
};

const Progress: React.FC<{value: number; bgColor?: string}> = ({value, bgColor = "#007CEF"}) => {
    return (
        <div className="mt-4 h-2 rounded-md bg-[#304553] relative bg-opacity-50">
            <motion.div
                className="flex justify-end"
                initial={{width: 0}}
                animate={{width: `${value * 100}%`}}
                transition={{duration: 0.8}}
                style={{
                    background: bgColor,
                    borderRadius: "5px",
                    height: "100%",
                }}>
                <div className="w-2 h-2 rounded-[1rem] bg-white relative">
                    <div className="absolute w-[55px] h-[55px] z-10 -top-[40px] -left-[22px]">
                        <SvgIcon dataKey="icon-processPop" style={{fill: bgColor, width: 55, height: 55}} className="absolute top-0" />
                        <div className="absolute text-white text-sm top-[15px] text-center w-full">
                            <AnimateValue value={value * 100} />%
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default Progress;
