import SampleLog from "../Common/SampleLog";

export default class TimeManager {

    serverTime: number = 0;
    localTime: number = 0;

    private static instance: TimeManager = null;
    public static getInstance(): TimeManager {
        if (!TimeManager.instance) {
            TimeManager.instance = new TimeManager();
        }
        return TimeManager.instance;
    }

    public sync(serverTime: number) {
        this.serverTime = serverTime;
        this.localTime = Date.now();
    }

    public now(): number {
        if (this.serverTime == 0 || this.localTime == 0) {
            return Date.now();
        }
        return Date.now() - this.localTime + this.serverTime;
    }

    public adjust(duration: number, serverTime: number): number {
        if (this.serverTime == 0 || this.localTime == 0) {
            SampleLog.Log("TimeManager adjust: supplement sync");
            this.sync(serverTime);
        }
        SampleLog.Log("TimeManager adjust: this.serverTime = " + this.serverTime + " this.localTime = " + this.localTime);
        let ret: number = duration * 1000 - (this.now() - serverTime);
        if (ret > duration * 1000) {
            let revise: number = ret - (duration * 1000);
            SampleLog.Log("TimeManager adjust: revise this.serverTime " + revise);
            this.serverTime += revise;
            ret = duration * 1000;
        }
        return ret;
    }

}
