export enum CurrencyType {
    Crypto = 1,
    Fiat = 2,
    Platform = 3,
}

export interface VIP {
    cashbackRate: number;
    depositeAmount: number;
    exp: number;
    inviteRewards: string;
    level: number;
    name: string;
    platformId: number;
    rewards: string;
    rid: number;
    vipMonthReward: number;
    vipWeekReward: number;
    vipDailyMaxWithdrawCount: number;
    vipSingleMaxWithdrawAmount: number;
    originalsCashbackRate: number;
    slotsCashbackRate: number;
    sportsCashbackRate: number;
    liveCasinoCashbackRate: number;
}

export interface CurrencyItem {
    tokenId: number;
    minDeposit: number;
    maxDeposit: number;
    minWithdraw: number;
    maxWithdraw: number;
    status: number;
    tokenName: string;
    categoryId: CurrencyType;
    minWithdrawGasAmount: number;
    gasWithdraw: number;
    canDeposit: number;
    canWithdraw: number;
}

export interface PlatformConfig {
    registerAndDepositTime: number;
    registerAndDepositRate: number;
    registerAndDepositBonusRate: number;
    vipWithdrawConfig?: {
        effectiveUserTags: string;
        isAllUserEffective: number;
    };
    affiliateRewardList: Array<{
        isDefault: number;
        platformId: number;
        rewardAmount: string;
        rewardTokenId: number;
        validLevel: number;
    }>;
    webSocketUrl: string;
    androidUrl: string;
    countryCode: string;
    currencyList: Array<CurrencyItem>;
    payProviderList: Array<{
        canDeposit: number;
        canWithdraw: number;
        minDeposit: number;
        maxDeposit: number;
        minWithdraw: number;
        maxWithdraw: number;
        providerName: string;
        tokenId: number;
        minWithdrawGasAmount: number;
        gasWithdraw: number;
    }>;
    customerEmail: string;
    vipList: VIP[];
    depositEmailStatus: number;
    depositMobileStatus: number;
    withdrawEmailStatus: number;
    withdrawMobileStatus: number;
    forceBindPhoneNo: number;
    isOpenEmailRegister: number;
    isOpenMobileRegister: number;
    isPddEnable: number;
    socialMediaList: Array<{
        name: string;
        isFeatured: number;
        isSideMenu: number;
        linkUrl: string;
        logoUrl: string;
        sortIndex: number;
    }>;
    convertedToken: Array<{tokenId: number; tokenName: string}>;
    mainToken: Array<{tokenId: number; tokenName: string}>;
}

export interface ConfigProps {
    platformConfig?: PlatformConfig;
    locale: string;
    isMobile: boolean;
    odds: string;
    cashBackData: number;
    menuOpen: boolean;
    walletCurrency: number;
    inviteCode: string;
    promoCode: string;
    walletType: string;
    maintainTime: number;
}

export interface WindowConfig {
    furnace_url: string;
    base_url: string;
    image_base_url: string;
    thirdparty_image_base_url: string;
    original_game: string;
    bridge_url: string;
    lottery_url: string;
    crypto_url: string;
    currencyList: CurrencyItem[];
    betby_library: string;
    betby_brand_id: string;
    betby_support_currency: string;
    betby_theme_dark: string;
    betby_theme_light: string;
    platform_id: number;
    lang: string[];
    promotion_url: string;
}

export enum WalletType {
    DEPOSIT = "deposit",
    WITHDRAW = "withdraw",
    BUYCRYPTO = "buyCrypto",
    // SENDFEE = "sendFee",
}
