import React, {useRef} from "react";
import {useEffect} from "react";

export const GoogleLoginButton = (props) => {
    const wrapRef = useRef(null);

    useEffect(() => {
        if (window.google && wrapRef) {
            window.google.accounts.id.renderButton(wrapRef.current, {
                type: "standard",
                width: "96",
                text: "signin",
                theme: "filled_black",
            });
        }
    }, []);

    return (
        <div className="w-full absolute top-0 left-0 overflow-hidden opacity-0" {...props}>
            <div ref={wrapRef} />
        </div>
    );
};
