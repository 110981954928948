import {useEffect, useState} from "react";
import {useMount, useInterval} from "react-use";
import casinoGame from "api/casinoGame";
import apiUser from "api/user";
import client from "api/client";
import {dispatch, useAppSelector} from "store";
import {setGameList} from "store/slices/game";
import {setUserInfo, getUserTurnover} from "store/slices/user";

const useInitData = () => {
    const [isRunning, setIsRunning] = useState(false);
    const token = useAppSelector((state) => state.user.user?.token);
    useMount(() => {
        const requestOriginalGames = async () => {
            const res = await casinoGame.getTypeGameList({
                pageId: 0,
                pageCount: 0,
                type: 4,
                params: "1",
            });
            if (res.status === 200 && res.data.code === 0) {
                dispatch(setGameList(res.data.data.gameList));
            }
        };
        requestOriginalGames();
    });

    useInterval(
        () => {
            dispatch(getUserTurnover());
        },
        isRunning ? 120 * 1000 : null,
    );

    useEffect(() => {
        if (token) {
            client.setToken(token);
            dispatch(getUserTurnover());
            setIsRunning(true);
        } else {
            setIsRunning(false);
        }
    }, [dispatch, token]);

    useEffect(() => {
        const getUserInfo = async () => {
            const {status, data} = await apiUser.getUserInfo();
            if (status === 200 && data.code === 0) {
                dispatch(setUserInfo({...data.data, token}));
            }
        };
        if (token) {
            client.setToken(token);
            getUserInfo();
        } else {
            client.setToken("");
        }
    }, [token]);
};

export default useInitData;
