import * as PIXI from "pixi.js";
import * as TWEEDLE from "tweedle.js";
import GameApp from "./GameApp";
import AudioMgr from "./Common/AudioMgr";
import SampleLog from "./Common/SampleLog";

export default abstract class GameScene<T extends GameApp> {
    protected canvas: HTMLCanvasElement = null;
    protected app: PIXI.Application = null;
    protected container: PIXI.Container = null;
    protected gameApp: T = null;

    protected effect: boolean = false;
    protected music: boolean = false;
    protected musicId: AudioContext = null;
    protected isDark: boolean = true;
    private lastTick: number = 0;

    private audioMgr: AudioMgr = new AudioMgr();

    protected get GameName(){
        return this.gameApp.GameName;
    }

    get CanvasRect(){
        if(!this.app) return new PIXI.Rectangle(0,0,0,0);
        return new PIXI.Rectangle(0,0,this.canvas.width/this.app.renderer.resolution,this.canvas.height/this.app.renderer.resolution);
    }

    protected get TexturePath() {
        return "/game_assets/resources/" + this.GameName + "/textures/";
    }

    protected get AudioPath() {
        return "/game_assets/resources/" + this.GameName + "/sounds/";
    }

    protected get SpinesPath() {
        return "/game_assets/resources/" + this.GameName + "/spines/";
    }

    public getTexture(filename: string) {
        return this.TexturePath + filename;
    }

    public getCardsTexture(filename: string) {
        return "/game_assets/resources/Common/textures/cards/" + filename;
    }

    // public unloadAudio(){
    //     this.audioMgr.unload();
    // }

    public initAudio(audio: string, music: boolean = false) {
        if (music) {
            this.audioMgr.initMusic(this.AudioPath + audio);
        } else {
            this.audioMgr.initEffect(this.AudioPath + audio);
        }
    }

    public playMusic(audio: string) {
        !document.hidden && !!this.music && this.audioMgr.playMusic(this.AudioPath + audio);
    }

    public stopMusic(audio: string) {
        this.audioMgr.stopMusic(this.AudioPath + audio);
    }

    public playEffect(audio: string) {
        if(document.hidden || !this.effect) return -1;
        return this.audioMgr.playEffect(this.AudioPath + audio);
    }

    public stopEffect(audio: string, auidoId: number) {
        this.audioMgr.stopEffect(this.AudioPath + audio, auidoId);
    }

    public onSize(){
        this.app.renderer.resize(this.CanvasRect.width, this.CanvasRect.height);
        this.container.position.set(this.CanvasRect.width / 2, this.CanvasRect.height / 2);
    }

    protected init(autoPreventDefault: boolean = false, resolution:number = 2) {
        this.canvas = document.getElementById(this.GameName) as HTMLCanvasElement;
        this.app = new PIXI.Application({
            background: "#000000",
            backgroundAlpha: 0,
            view: this.canvas,
            height: this.canvas.height,
            width: this.canvas.width,
            resolution: resolution,
            hello: true,
        });

        this.app.renderer.events.autoPreventDefault = autoPreventDefault;
        this.container = new PIXI.Container();
        this.app.stage.addChild(this.container);
        this.app.stage.hitArea = this.app.screen;
        this.container.position.set(this.CanvasRect.width / 2, this.container.y = this.CanvasRect.height / 2);

        setInterval(()=>{
            TWEEDLE.Group.shared.update();
            if (this.lastTick == 0) {
                this.lastTick = new Date().getTime();
                return;
            }

            let now = new Date().getTime();
            this.update(now - this.lastTick);
            this.lastTick = now;
        }, 16);

        document.addEventListener("visibilitychange", () => {
            SampleLog.Log("@@@@@@@@", document.hidden ? "hiden" : "show");
            if(document.hidden){
                this.onHide();
            }
            else{
                this.loadAudio();
                this.onShow();
            }
        });
        
        // window.addEventListener("resize", ()=>{
        //     this.onSize();
        // })
        
        this.loadAudio();
        this.preloadResource();
    }

    onShow(){}

    onHide(){}

    loadAudio(){}

    protected releaseScene() {
        TWEEDLE.Group.shared.removeAll();
        this.music = false;
        this.effect = false;
        if (!!this.app) {
            this.app.ticker.destroy();
            this.app.stage.removeChildren();
        }
    }

    public getCurrency(type:number){
        return this.gameApp.getCurrency(type);
    }

    public getCurrencyRate(type:number){
        return this.gameApp.getCurrencyRate(type);
    }

    public preloadResource(){}

    public switchLang(){}

    protected update(dt: number) { }
}