import GameApp, { CommonAction_G2H5, CommonAction_H52G, GameName, GamePort, eGames } from "../GameApp";
import { PddWheelScene } from "./PddWheelScene";
import I18NMgr from "../Utils/I18NMgr";

export enum PddWheelAction_G2H5 {
    config = "config",
    result = "result",
    rule = "rule",
    spinWheel = "spinWheel",
    noCount = "noCount",
    reload = "reload",
}

export enum PddWheelAction_H52G {
    spin = "spin",
    switch = "switch",
    wheelData = "wheelData",
    spinWheelReply = "spinWheelReply",
    spinFail = "spinFail"
}

export default class PddWheelApp extends GameApp {
    private _scene: PddWheelScene = null;

    public get GameId() {
        return eGames.PddWheel;
    }

    public get GamePort() {
        return GamePort[eGames.FuryWheel];
    }

    public get GameName() {
        return GameName[eGames.PddWheel];
    }

    public get scene() {
        return this._scene;
    }

    releaseScene() {
        this._scene.releaseScene();
    }

    registerWsListenner() {
    }

    switchLang(){
        !!this._scene && this._scene.switchLang();
    }

    start() {
        if (!this._listenner) {
            this._listenner = this.listenFunc.bind(this);
            window.addEventListener("pageMessage", this._listenner, false);
        }
        this._scene = new PddWheelScene;
        this._scene.load(this);
        this.sendInited();
    }

    processMessage(action: string, params: any): boolean {
        switch (action) {
            case CommonAction_H52G.loadGame:
                this.postMessage(CommonAction_G2H5.loadCompleted, {});
                this._currencyList = params["currencyList"];
                return true;
            case PddWheelAction_H52G.switch:
                this.scene.handleSwitch(params);
                return true;
            case PddWheelAction_H52G.spin:
                this.scene.handleSpin();
                return true;
            case PddWheelAction_H52G.spinWheelReply:
                this.scene.handleSpinReply(params);
                return true;
            case PddWheelAction_H52G.wheelData:
                this.scene.handleWheelData(params);
                return true;
            case PddWheelAction_H52G.spinFail:
                this.scene.handleSpinFail();
        }
        return super.processMessage(action, params);
    }
}