import AnimateButton from "ui-component/AnimateButton";
import SvgIcon from "ui-component/SvgIcon";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import md5 from "md5";
import {useIntl} from "react-intl";
import {useState} from "react";
import SmallLoading from "ui-component/SmallLoading";
import apiUser from "api/user";
import {useInterval} from "react-use";
import {toast} from "react-toastify";

interface Props {
    onChangeLoginType?: (type: string) => void;
}

const Forget = ({onChangeLoginType}: Props) => {
    const {$t} = useIntl();
    const [showPass, setShowPass] = useState(false);
    const [count, setCount] = useState(0);
    const [isSendingverifyCode, setIsSendingverifyCode] = useState(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email($t({id: "EmailDomainNotSpported"}))
            .required($t({id: "ThisFieldIsRequired"})),
        password: Yup.string()
            .matches(/[A-Z]/, $t({id: "PasswordIncludeLowerUpperCharacter"}))
            .matches(/[a-z]/, $t({id: "PasswordIncludeLowerUpperCharacter"}))
            .matches(/\d/, $t({id: "PasswordIncludeOneNumber"}))
            .required($t({id: "ThisFieldIsRequired"}))
            .min(8, $t({id: "YourPasswordLeast8Long"})),
        code: Yup.string().required($t({id: "ThisFieldIsRequired"})),
    });

    const initialValues = {
        email: "",
        password: "",
        code: "",
    };
    useInterval(
        () => {
            if (count !== 0) {
                setCount((old) => old - 1);
            }
        },
        count !== 0 ? 1000 : null,
    );
    const sendCode = async (e, email: string) => {
        e.stopPropagation();
        setIsSendingverifyCode(true);
        try {
            const res = await apiUser.getVerificationCode(email);
            const {status, data} = res;
            if (status === 200) {
                if (data.code !== 0) {
                    toast.error($t({id: `SEND_CODE_ERROR_${data.code}`}));
                } else {
                    toast.success($t({id: "CodeSendSuccess"}));
                    setCount(60);
                }
            }
        } finally {
            setIsSendingverifyCode(false);
        }
    };

    const getPassErrorElement = (errorMessage: string, values: {password: string}) => {
        const password = values.password;
        const hasUpperCaseLowerCase = /[A-Z]/.test(password) && /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const has8Str = /^.{8,}$/.test(password);

        return (
            <div>
                <div className="flex items-center pt-2 pb-1 text-[#F2708A] text-xs">
                    <SvgIcon dataKey="icon-error" className="w-3 h-3 mr-2" style={{fill: "#F2708A"}} />
                    <span>{errorMessage}</span>
                </div>
                <div className="text-xs mt-1 flex items-center">
                    <SvgIcon dataKey="icon-check" className="w-3 h-3 mr-2" style={{fill: hasUpperCaseLowerCase ? "#00b801" : "#b1bad3"}} />
                    {$t({id: "IncludesLowerUpperCase"})}
                </div>
                <div className="text-xs mt-1 flex items-center">
                    <SvgIcon dataKey="icon-check" className="w-3 h-3 mr-2" style={{fill: hasNumber ? "#00b801" : "#b1bad3"}} />
                    {$t({id: "AtLeast1Number"})}
                </div>
                <div className="text-xs mt-1 flex items-center">
                    <SvgIcon dataKey="icon-check" className="w-3 h-3 mr-2" style={{fill: has8Str ? "#00b801" : "#b1bad3"}} />
                    {$t({id: "Minimum8Characters"})}
                </div>
            </div>
        );
    };

    const getErrorElement = (errorMessage: string) => {
        return (
            <div className="flex items-center pt-2 pb-1 text-[#F2708A] text-xs">
                <SvgIcon dataKey="icon-error" className="w-3 h-3 mr-2" style={{fill: "#F2708A"}} />
                <span>{errorMessage}</span>
            </div>
        );
    };

    const handleSubmit = async (values) => {
        const res = await apiUser.forgetPassword({
            email: values.email,
            code: values.code,
            password: md5(values.password),
        });
        const {status, data} = res;
        if (status === 200) {
            if (data.code !== 0) {
                toast.error($t({id: `FORGETPW_CODE_ERROR_${data.code}`}));
            } else {
                toast.success($t({id: "ResetPwSuccess"}));
                onChangeLoginType?.("signin");
            }
        }
    };

    const getSendText = (): string => {
        return count > 0 ? $t({id: "ResendA"}, {A: count + "S"}) : $t({id: "Send"});
    };
    return (
        <div className="font-semibold">
            <div className="flex items-center">
                <SvgIcon dataKey="icon-setting" className="w-6 h-6 mr-2" style={{fill: "#b1bad3"}} />
                <div className="text-base text-white font-semibold">{$t({id: "ForgotPassword"})}</div>
            </div>
            <div className="mt-4">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({values, errors, touched}) => (
                        <Form>
                            <div className="mt-4">
                                <div>
                                    <label htmlFor="email">
                                        {$t({id: "Email"})}
                                        <span className="text-[#F2708A] ml-1">*</span>
                                    </label>
                                </div>

                                <Field
                                    type="text"
                                    name="email"
                                    className="mt-1 w-full h-[40px] text-white bg-[#0f212e] rounded outline-none p-2 border-2 border-solid border-[#2f4553] focus:border-[#557086]"
                                />
                                <ErrorMessage name="email">{getErrorElement}</ErrorMessage>
                            </div>
                            <div className="mt-4">
                                <div>
                                    <label htmlFor="code">
                                        {$t({id: "VerificationCode"})}
                                        <span className="text-[#F2708A] ml-1">*</span>
                                    </label>
                                </div>
                                <div className="relative">
                                    <Field
                                        type="text"
                                        name="code"
                                        className="mt-1 w-full h-[40px] text-white bg-[#0f212e] rounded outline-none p-2 border-2 border-solid border-[#2f4553] focus:border-[#557086]"
                                    />
                                    <div className="absolute top-1 right-5 w-15 h-10 flex items-center justify-center">
                                        <div className="w-1 h-10 flex items-center pr-5">|</div>
                                        <button
                                            type="button"
                                            style={{
                                                color: errors["email"] || !touched["email"] || count > 0 ? "#b1bad3" : "#ffffff",
                                                cursor: errors["email"] || !touched["email"] || count > 0 ? "default" : "pointer",
                                            }}
                                            onClick={(e) => {
                                                if (!errors["email"] && touched["email"] && count <= 0) {
                                                    sendCode(e, values["email"]);
                                                }
                                            }}
                                            className="w-30 h-10">
                                            {isSendingverifyCode ? <SmallLoading /> : getSendText()}
                                        </button>
                                    </div>
                                </div>
                                <ErrorMessage name="code">{getErrorElement}</ErrorMessage>
                            </div>
                            <div className="mt-4">
                                <div>
                                    <label htmlFor="password">
                                        {$t({id: "NewPassword"})}
                                        <span className="text-[#F2708A] ml-1">*</span>
                                    </label>
                                </div>
                                <div className="relative">
                                    <Field
                                        type={showPass ? "text" : "password"}
                                        name="password"
                                        autocomplete="new-password"
                                        className="mt-1 w-full h-[40px] text-white bg-[#0f212e] rounded outline-none p-2 border-2 border-solid border-[#2f4553] focus:border-[#557086]"
                                    />
                                    <input type="text" style={{display: "none"}} />
                                    <div className="absolute top-1 right-0 w-12 h-10 flex items-center justify-center">
                                        <AnimateButton>
                                            <SvgIcon
                                                dataKey="icon-eye"
                                                className=" w-4 h-4"
                                                style={{fill: showPass ? "#b1bad3" : "#ffffff"}}
                                                onClick={() => {
                                                    setShowPass(!showPass);
                                                }}
                                            />
                                        </AnimateButton>
                                    </div>
                                </div>

                                <ErrorMessage name="password">{(errorMessage) => getPassErrorElement(errorMessage, values)}</ErrorMessage>
                            </div>
                            <AnimateButton>
                                <button
                                    type="submit"
                                    disabled={Object.keys(errors).length > 0}
                                    className="w-full h-12 mt-4 flex font-semibold items-center justify-center text-[#05080a] text-base rounded"
                                    style={{
                                        boxShadow: "0rem .0625rem .1875rem #00000033 , 0rem .0625rem .125rem #0000001f",
                                        backgroundColor: Object.keys(errors).length > 0 ? "#b1bad3" : "#00e701",
                                    }}>
                                    {$t({id: "RecoverPassword"})}
                                </button>
                            </AnimateButton>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default Forget;
