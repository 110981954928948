import React, {useEffect, useMemo} from "react";
import {motion, AnimatePresence} from "framer-motion";
import Expand, {MenuItem} from "./Expand";
import {openVip} from "views/vip";
import {dispatch, useAppSelector} from "store";
import {logout} from "store/slices/user";
import {showDialog} from "store/slices/dialog";
import {openMyBets} from "views/lottery/MyBets";
import useLiveChat, {showLiveChat} from "hooks/useLiveChat";
import {openNotification} from "views/notification";
import {WalletType} from "types/config";
import {setWalletType} from "store/slices/config";

interface MenuProps {
    title?: string;
    menuPath?: string;
    disabled?: boolean;
    cls?: string;
    carteId: string | number;
    items: MenuItem[];
}

const profileMenu: MenuItem = {
    menuId: 100101,
    parentMenuId: null,
    title: "Profile",
    icon: "icon-user",
    footer: {
        menuId: 10010100,
        title: "Logout",
        icon: "icon-exit",
        onClick: () => {
            // dispatch(logout());
            dispatch(showDialog("logout"));
        },
    },
    children: [
        {
            menuId: 10010101,
            parentMenuId: 100101,
            title: "Wallet",
            icon: "icon-coins",
            onClick: () => {
                dispatch(showDialog("wallet"));
                dispatch(setWalletType(WalletType.DEPOSIT));
            },
        },
        // {
        //     menuId: 10010102,
        //     parentMenuId: 100101,
        //     title: "Vault",
        //     icon: "icon-vault",
        // },
        // {
        //     menuId: 10010103,
        //     parentMenuId: 100101,
        //     title: "VIP",
        //     icon: "icon-trophy",
        //     onClick: () => openVip(),
        // },
        // {
        //     menuId: 10010104,
        //     parentMenuId: 100101,
        //     title: "Statistics",
        //     icon: "icon-stats",
        // },
        {
            menuId: 10010105,
            parentMenuId: 100101,
            title: "Transactions",
            icon: "icon-list",
            menuPath: "transactions",
        },
        // {
        //     menuId: 10010106,
        //     parentMenuId: 100101,
        //     title: "SportBets",
        //     icon: "icon-sports",
        // },
        {
            menuId: 10010107,
            parentMenuId: 100101,
            title: "Settings",
            icon: "icon-gear",
            menuPath: "settings/general",
        },
    ],
};

const baseMenuData: MenuProps[] = [
    {
        carteId: 1001,
        items: [
            {
                menuId: 100401,
                parentMenuId: null,
                title: "VIPClub",
                menuPath: "vip-club",
                icon: "icon-trophy",
            },
            // {
            //     menuId: 100201,
            //     parentMenuId: null,
            //     title: "Promotions",
            //     menuPath: "promotion",
            //     icon: "icon-promotions",
            //     footer: {
            //         menuId: 10020100,
            //         title: "ViewAll",
            //         icon: "icon-promotions",
            //     },
            //     children: [
            //         {
            //             menuId: 10020101,
            //             parentMenuId: 100201,
            //             title: "75WeeklyRaffle",
            //             icon: "icon-coupon",
            //         },
            //         {
            //             menuId: 10020102,
            //             parentMenuId: 100201,
            //             title: "RaceHours",
            //             icon: "icon-races",
            //         },
            //         {
            //             menuId: 10020103,
            //             parentMenuId: 100201,
            //             title: "PragmaticDropsWins",
            //             icon: "icon-trophy",
            //         },
            //     ],
            // },
            // {
            //     menuId: 200701,
            //     parentMenuId: null,
            //     title: "Refer & Earn",
            //     icon: "icon-hearthstone",
            // },
            {
                menuId: 200801,
                parentMenuId: null,
                title: "Help Center",
                icon: "icon-Policies",
                menuPath: "policies",
            },
            {
                menuId: 200301,
                parentMenuId: null,
                title: "LiveSupport",
                icon: "icon-support",
                onClick: showLiveChat,
            },
            {
                menuId: 200401,
                parentMenuId: null,
                title: "Language",
                icon: "icon-language",
                disabled: true,
                renderType: "language",
                children: [
                    {
                        menuId: 20040101,
                        parentMenuId: 200401,
                        title: "English",
                        icon: "en",
                    },
                    // {
                    //     menuId: 20040102,
                    //     parentMenuId: 200401,
                    //     title: "日本語",
                    //     icon: "jp",
                    // },
                    // {
                    //     menuId: 20040103,
                    //     parentMenuId: 200401,
                    //     title: "中文",
                    //     icon: "zh",
                    // },
                    // {
                    //     menuId: 20040104,
                    //     parentMenuId: 200401,
                    //     title: "Português",
                    //     icon: "pt",
                    // },
                ],
            },
            // {
            //     menuId: 100301,
            //     parentMenuId: null,
            //     title: "Affiliate",
            //     menuPath: "affiliate",
            //     icon: "icon-affiliate",
            // },
            // {
            //     menuId: 100501,
            //     parentMenuId: null,
            //     title: "Blog",
            //     icon: "icon-news",
            // },
            // {
            //     menuId: 100601,
            //     parentMenuId: null,
            //     title: "Forum",
            //     icon: "icon-chat",
            // },
        ],
    },
    {
        carteId: 2001,
        items: [
            // {
            //     menuId: 200101,
            //     parentMenuId: null,
            //     title: "Sponsorships",
            //     icon: "icon-sponsorships",
            //     children: [
            //         {
            //             menuId: 20010101,
            //             parentMenuId: 200101,
            //             title: "Drake",
            //             icon: "icon-drake",
            //         },
            //         {
            //             menuId: 20010102,
            //             parentMenuId: 200101,
            //             title: "AlfaF1Stake",
            //             icon: "icon-alfa-romeo",
            //         },
            //         {
            //             menuId: 20010103,
            //             parentMenuId: 200101,
            //             title: "EvertonFootballClub",
            //             icon: "icon-soccer",
            //         },
            //         {
            //             menuId: 20010104,
            //             parentMenuId: 200101,
            //             title: "EnyimbaFootballClub",
            //             icon: "icon-enyimba",
            //         },
            //         {
            //             menuId: 20010105,
            //             parentMenuId: 200101,
            //             title: "KunAguero",
            //             icon: "icon-aguero",
            //         },
            //         {
            //             menuId: 20010106,
            //             parentMenuId: 200101,
            //             title: "Gabigol",
            //             icon: "icon-gabigol",
            //         },
            //         {
            //             menuId: 20010107,
            //             parentMenuId: 200101,
            //             title: "IsraelAdesanya",
            //             icon: "icon-mma",
            //         },
            //         {
            //             menuId: 20010108,
            //             parentMenuId: 200101,
            //             title: "AlexaGrasso",
            //             icon: "icon-mma",
            //         },
            //         {
            //             menuId: 20010109,
            //             parentMenuId: 200101,
            //             title: "GilbertBurns",
            //             icon: "icon-mma",
            //         },
            //         {
            //             menuId: 20010110,
            //             parentMenuId: 200101,
            //             title: "JailtonAlmeida",
            //             icon: "icon-almeida",
            //         },
            //         {
            //             menuId: 20010111,
            //             parentMenuId: 200101,
            //             title: "BoxinginJapan",
            //             icon: "icon-boxing",
            //         },
            //         {
            //             menuId: 20010112,
            //             parentMenuId: 200101,
            //             title: "FittipaldiBrothers",
            //             icon: "icon-motorsport",
            //         },
            //         {
            //             menuId: 20010113,
            //             parentMenuId: 200101,
            //             title: "BrazilRugbyLeague",
            //             icon: "icon-rugby-league",
            //         },
            //         {
            //             menuId: 20010114,
            //             parentMenuId: 200101,
            //             title: "UFC",
            //             icon: "icon-ufc",
            //         },
            //     ],
            // },
            // {
            //     menuId: 200201,
            //     parentMenuId: null,
            //     title: "ResponsibleGambling",
            //     icon: "icon-verify",
            // },
            // {
            //     menuId: 200301,
            //     parentMenuId: null,
            //     title: "LiveSupport",
            //     icon: "icon-support",
            // },
            // {
            //     menuId: 200501,
            //     parentMenuId: null,
            //     menuPath: "financial",
            //     title: "RLB Financial",
            //     icon: "icon-chart",
            // },
            // {
            //     menuId: 200601,
            //     parentMenuId: null,
            //     menuPath: "lottery",
            //     title: "RLB Lottery",
            //     icon: "icon-locks",
            // },
            // {
            //     menuId: 200401,
            //     parentMenuId: null,
            //     title: "Language",
            //     icon: "icon-language",
            //     disabled: true,
            //     renderType: "language",
            //     children: [
            //         {
            //             menuId: 20040101,
            //             parentMenuId: 200401,
            //             title: "English",
            //             icon: "en",
            //         },
            //         // {
            //         //     menuId: 20040102,
            //         //     parentMenuId: 200401,
            //         //     title: "日本語",
            //         //     icon: "jp",
            //         // },
            //         // {
            //         //     menuId: 20040103,
            //         //     parentMenuId: 200401,
            //         //     title: "中文",
            //         //     icon: "zh",
            //         // },
            //         // {
            //         //     menuId: 20040104,
            //         //     parentMenuId: 200401,
            //         //     title: "Português",
            //         //     icon: "pt",
            //         // },
            //     ],
            // },
        ],
    },
];

const casinoMenuData: MenuProps[] = [
    {
        carteId: 3001,
        items: [
            {
                menuId: 300101,
                parentMenuId: null,
                title: "Favourites",
                menuPath: "casino/favourites",
                disabled: true,
                icon: "icon-favourite",
            },
            {
                menuId: 300102,
                parentMenuId: null,
                title: "Recent",
                menuPath: "casino/recent",
                disabled: true,
                icon: "icon-recent",
            },
            {
                menuId: 300104,
                parentMenuId: null,
                title: "My bets",
                disabled: true,
                icon: "icon-my-bet",
                onClick: () => openMyBets(1),
            },
            // {
            //     menuId: 300103,
            //     parentMenuId: null,
            //     title: "Challenges",
            //     icon: "icon-archery",
            // },
        ],
    },
    {
        carteId: 4001,
        title: "Games",
        items: [
            {
                menuId: 400101,
                parentMenuId: null,
                title: "Originals",
                menuPath: "casino/group/stake-originals",
                icon: "icon-fire",
            },
            {
                menuId: 4001013,
                parentMenuId: null,
                title: "Mini Games",
                menuPath: "casino/group/mini-games",
                icon: "icon-stake-exclusive",
            },
            {
                menuId: 400108,
                parentMenuId: null,
                title: "EnhancedRTP",
                menuPath: "casino/group/enhanced-rtp",
                icon: "icon-enhanced-rtp",
            },
            // {
            //     menuId: 400102,
            //     parentMenuId: null,
            //     title: "StakeExclusives",
            //     icon: "icon-stake-exclusive",
            // },
            {
                menuId: 400103,
                parentMenuId: null,
                title: "Slots",
                menuPath: "casino/group/slots",
                icon: "icon-provider-slots",
            },
            {
                menuId: 400104,
                parentMenuId: null,
                title: "LiveCasino",
                menuPath: "casino/group/live-casino",
                icon: "icon-provider-live-dealers",
            },
            // {
            //     menuId: 400105,
            //     parentMenuId: null,
            //     title: "GameShows",
            //     icon: "icon-rewards",
            // },
            {
                menuId: 400106,
                parentMenuId: null,
                title: "NewReleases",
                menuPath: "casino/group/new-releases",
                icon: "icon-vip-boost",
            },
            // {
            //     menuId: 400107,
            //     parentMenuId: null,
            //     title: "FeatureBuyin",
            //     icon: "icon-popular",
            // },
            // {
            //     menuId: 400109,
            //     parentMenuId: null,
            //     title: "TableGames",
            //     menuPath: "casino/group/table-game",
            //     icon: "icon-provider-table-game",
            // },
            // {
            //     menuId: 400110,
            //     parentMenuId: null,
            //     title: "Blackjack",
            //     menuPath: "casino/group/blackjack",
            //     icon: "icon-provider-blackjack",
            // },
            // {
            //     menuId: 400111,
            //     parentMenuId: null,
            //     title: "Baccarat",
            //     menuPath: "casino/group/baccarat",
            //     icon: "icon-provider-baccarat",
            // },
            // {
            //     menuId: 400112,
            //     parentMenuId: null,
            //     title: "Roulette",
            //     menuPath: "casino/group/roulette",
            //     icon: "icon-provider-roulette",
            // },
        ],
    },
    // {
    //     carteId: 5001,
    //     items: [
    //         {
    //             menuId: 500101,
    //             parentMenuId: null,
    //             title: "Providers",
    //             icon: "icon-group-providers",
    //             menuPath: "casino/collection/provider",
    //         },
    //     ],
    // },
];

const sportMenuData: MenuProps[] = [
    {
        carteId: 6001,
        items: [
            {
                menuId: 600101,
                parentMenuId: null,
                title: "LiveEvents",
                icon: "icon-in-play",
            },
            {
                menuId: 600102,
                parentMenuId: null,
                title: "StartingSoon",
                icon: "icon-starting-soon",
            },
            {
                menuId: 600103,
                parentMenuId: null,
                title: "MyBets",
                icon: "icon-betslip",
            },
        ],
    },
    {
        carteId: 7001,
        title: "SportsTop",
        items: [
            {
                menuId: 700101,
                parentMenuId: null,
                title: "Soccer",
                icon: "icon-soccer",
                children: [
                    {
                        menuId: 70010101,
                        parentMenuId: 700101,
                        title: "LiveUpcoming",
                        icon: "icon-in-play",
                    },
                    {
                        menuId: 70010102,
                        parentMenuId: 700101,
                        title: "Outrights",
                        icon: "icon-starting-soon",
                    },
                    {
                        menuId: 70010103,
                        parentMenuId: 700101,
                        title: "MajorLeagueSoccer",
                        icon: "icon-international",
                    },
                    {
                        menuId: 70010104,
                        parentMenuId: 700101,
                        title: "EuropaLeague",
                        icon: "icon-international",
                    },
                    {
                        menuId: 70010105,
                        parentMenuId: 700101,
                        title: "EuropaConferenceLeague",
                        extraIcon: <img src="https://flagicons.lipis.dev/flags/1x1/gb.svg" alt="" className="w-3.5 h-3.5" />,
                    },
                ],
            },
            {
                menuId: 700102,
                parentMenuId: null,
                title: "Tennis",
                icon: "icon-tennis",
                children: [
                    {
                        menuId: 70010201,
                        parentMenuId: 700102,
                        title: "LiveUpcoming",
                        icon: "icon-in-play",
                    },
                    {
                        menuId: 70010202,
                        parentMenuId: 700102,
                        title: "Outrights",
                        icon: "icon-starting-soon",
                    },
                    {
                        menuId: 70010203,
                        parentMenuId: 700102,
                        title: "International1",
                        icon: "icon-international",
                    },
                    {
                        menuId: 70010204,
                        parentMenuId: 700102,
                        title: "International2",
                        icon: "icon-international",
                    },
                    {
                        menuId: 70010205,
                        parentMenuId: 700102,
                        title: "International3",
                        icon: "icon-international",
                    },
                ],
            },
            {
                menuId: 700103,
                parentMenuId: null,
                title: "Baseball",
                icon: "icon-baseball",
                children: [
                    {
                        menuId: 70010301,
                        parentMenuId: 700103,
                        title: "LiveUpcoming",
                        icon: "icon-in-play",
                    },
                    {
                        menuId: 70010302,
                        parentMenuId: 700103,
                        title: "Outrights",
                        icon: "icon-starting-soon",
                    },
                    {
                        menuId: 70010303,
                        parentMenuId: 700103,
                        title: "MLB",
                        extraIcon: <img src="https://flagicons.lipis.dev/flags/1x1/us.svg" alt="" className="w-3.5 h-3.5" />,
                    },
                    {
                        menuId: 70010304,
                        parentMenuId: 700103,
                        title: "NPB",
                        extraIcon: <img src="https://flagicons.lipis.dev/flags/1x1/kr.svg" alt="" className="w-3.5 h-3.5" />,
                    },
                    {
                        menuId: 70010305,
                        parentMenuId: 700103,
                        title: "KBOLeague",
                        extraIcon: <img src="https://flagicons.lipis.dev/flags/1x1/kr.svg" alt="" className="w-3.5 h-3.5" />,
                    },
                ],
            },
            {
                menuId: 700104,
                parentMenuId: null,
                title: "Basketball",
                icon: "icon-basketball",
                children: [
                    {
                        menuId: 70010401,
                        parentMenuId: 700104,
                        title: "LiveUpcoming",
                        icon: "icon-in-play",
                    },
                    {
                        menuId: 70010402,
                        parentMenuId: 700104,
                        title: "Outrights",
                        icon: "icon-starting-soon",
                    },
                    {
                        menuId: 70010403,
                        parentMenuId: 700104,
                        title: "IntercontinentalCup",
                        extraIcon: <img src="https://flagicons.lipis.dev/flags/1x1/us.svg" alt="" className="w-3.5 h-3.5" />,
                    },
                    {
                        menuId: 70010404,
                        parentMenuId: 700104,
                        title: "ClubFriendlyGames",
                        icon: "icon-international",
                    },
                    {
                        menuId: 70010405,
                        parentMenuId: 700104,
                        title: "BBL",
                        icon: "icon-starting-soon",
                        extraIcon: <img src="https://flagicons.lipis.dev/flags/1x1/fr.svg" alt="" className="w-3.5 h-3.5" />,
                    },
                ],
            },
            {
                menuId: 700105,
                parentMenuId: null,
                title: "AmericanFootball",
                icon: "icon-american-football",
                children: [
                    {
                        menuId: 70010501,
                        parentMenuId: 700105,
                        title: "LiveUpcoming",
                        icon: "icon-in-play",
                    },
                    {
                        menuId: 70010502,
                        parentMenuId: 700105,
                        title: "Outrights",
                        icon: "icon-starting-soon",
                    },
                    {
                        menuId: 70010503,
                        parentMenuId: 700105,
                        title: "NFL",
                        extraIcon: <img src="https://flagicons.lipis.dev/flags/1x1/us.svg" alt="" className="w-3.5 h-3.5" />,
                    },
                    {
                        menuId: 70010504,
                        parentMenuId: 700105,
                        title: "NCAA",
                        extraIcon: <img src="https://flagicons.lipis.dev/flags/1x1/us.svg" alt="" className="w-3.5 h-3.5" />,
                    },
                    {
                        menuId: 70010505,
                        parentMenuId: 700105,
                        title: "CFL",
                        extraIcon: <img src="https://flagicons.lipis.dev/flags/1x1/ca.svg" alt="" className="w-3.5 h-3.5" />,
                    },
                ],
            },
        ],
    },
    {
        carteId: 8001,
        items: [
            {
                menuId: 800101,
                parentMenuId: null,
                title: "AllEsports",
                icon: "icon-e-sport",
                children: [
                    {
                        menuId: 80010101,
                        parentMenuId: 800101,
                        title: "RainbowSix",
                        icon: "icon-rainbow-six",
                    },
                    {
                        menuId: 80010102,
                        parentMenuId: 800101,
                        title: "Dota2",
                        icon: "icon-dota-2",
                    },
                    {
                        menuId: 80010103,
                        parentMenuId: 800101,
                        title: "HALO",
                        icon: "icon-halo",
                    },
                    {
                        menuId: 80010104,
                        parentMenuId: 800101,
                        title: "Warcraft3",
                        icon: "icon-warcraft-3",
                    },
                    {
                        menuId: 80010105,
                        parentMenuId: 800101,
                        title: "MobileLegends",
                        icon: "icon-mobile-legends",
                    },
                    {
                        menuId: 80010106,
                        parentMenuId: 800101,
                        title: "Valorant",
                        icon: "icon-valorant",
                    },
                    {
                        menuId: 80010107,
                        parentMenuId: 800101,
                        title: "Starcraft1",
                        icon: "icon-starcraft-1",
                    },
                    {
                        menuId: 80010109,
                        parentMenuId: 800101,
                        title: "Starcraft2",
                        icon: "icon-starcraft-2",
                    },
                    {
                        menuId: 80010110,
                        parentMenuId: 800101,
                        title: "LeagueLegends",
                        icon: "icon-league-of-legends",
                    },
                    {
                        menuId: 80010111,
                        parentMenuId: 800101,
                        title: "CSGO",
                        icon: "icon-counter-strike",
                    },
                    {
                        menuId: 80010112,
                        parentMenuId: 800101,
                        title: "eCricket",
                        icon: "icon-ecricket",
                    },
                    {
                        menuId: 80010113,
                        parentMenuId: 800101,
                        title: "FIFA",
                        icon: "icon-fifa",
                    },
                    {
                        menuId: 80010114,
                        parentMenuId: 800101,
                        title: "NBA2K",
                        icon: "icon-nba2k",
                    },
                ],
            },
        ],
    },
    {
        carteId: 9001,
        items: [
            {
                menuId: 900101,
                parentMenuId: null,
                title: "AllSports",
                icon: "icon-basketball",
                children: [
                    {
                        menuId: 90010101,
                        parentMenuId: 900101,
                        title: "AussieRules",
                        icon: "icon-aussie-rules",
                    },
                    {
                        menuId: 90010102,
                        parentMenuId: 900101,
                        title: "Cricket",
                        icon: "icon-cricket",
                    },
                    {
                        menuId: 90010103,
                        parentMenuId: 900101,
                        title: "Baseball",
                        icon: "icon-baseball",
                    },
                    {
                        menuId: 90010104,
                        parentMenuId: 900101,
                        title: "IceHockey",
                        icon: "icon-ice-hockey",
                    },
                    {
                        menuId: 90010105,
                        parentMenuId: 900101,
                        title: "Floorball",
                        icon: "icon-floorball",
                    },
                    {
                        menuId: 90010106,
                        parentMenuId: 900101,
                        title: "AlpineSkiing",
                        icon: "icon-alpine-skiing",
                    },
                    {
                        menuId: 90010107,
                        parentMenuId: 900101,
                        title: "Darts",
                        icon: "icon-darts",
                    },
                    {
                        menuId: 90010108,
                        parentMenuId: 900101,
                        title: "Pesapallo",
                        icon: "icon-pesapallo",
                    },
                    {
                        menuId: 90010109,
                        parentMenuId: 900101,
                        title: "StockCarRacing",
                        icon: "icon-stock-car-racing",
                    },
                    {
                        menuId: 90010110,
                        parentMenuId: 900101,
                        title: "GaelicHurling",
                        icon: "icon-gaelic-hurling",
                    },
                    {
                        menuId: 90010111,
                        parentMenuId: 900101,
                        title: "GaelicFootball",
                        icon: "icon-gaelic-football",
                    },
                    {
                        menuId: 90010112,
                        parentMenuId: 900101,
                        title: "Golf",
                        icon: "icon-golf",
                    },
                    {
                        menuId: 90010113,
                        parentMenuId: 900101,
                        title: "SkiJumping",
                        icon: "icon-ski-jumping",
                    },
                    {
                        menuId: 90010114,
                        parentMenuId: 900101,
                        title: "Biathlon",
                        icon: "icon-biathlon",
                    },
                    {
                        menuId: 90010115,
                        parentMenuId: 900101,
                        title: "Basketball",
                        icon: "icon-basketball",
                    },
                    {
                        menuId: 90010116,
                        parentMenuId: 900101,
                        title: "AmericanFootball",
                        icon: "icon-american-football",
                    },
                    {
                        menuId: 90010117,
                        parentMenuId: 900101,
                        title: "MotorcycleRacing",
                        icon: "icon-motorcycle-racing",
                    },
                    {
                        menuId: 90010118,
                        parentMenuId: 900101,
                        title: "Volleyball",
                        icon: "icon-volleyball",
                    },
                    {
                        menuId: 90010119,
                        parentMenuId: 900101,
                        title: "TableTennis",
                        icon: "icon-table-tennis",
                    },
                    {
                        menuId: 90010120,
                        parentMenuId: 900101,
                        title: "Boxing",
                        icon: "icon-boxing",
                    },
                    {
                        menuId: 90010121,
                        parentMenuId: 900101,
                        title: "BeachSoccer",
                        icon: "icon-beach-soccer",
                    },
                    {
                        menuId: 90010122,
                        parentMenuId: 900101,
                        title: "Handball",
                        icon: "icon-handball",
                    },
                    {
                        menuId: 90010123,
                        parentMenuId: 900101,
                        title: "Waterpolo",
                        icon: "icon-waterpolo",
                    },
                    {
                        menuId: 90010124,
                        parentMenuId: 900101,
                        title: "PoliticsEntertainment",
                        icon: "icon-politics-entertainment",
                    },
                    {
                        menuId: 90010125,
                        parentMenuId: 900101,
                        title: "Tennis",
                        icon: "icon-tennis",
                    },
                    {
                        menuId: 90010126,
                        parentMenuId: 900101,
                        title: "Futsal",
                        icon: "icon-futsal",
                    },
                    {
                        menuId: 90010127,
                        parentMenuId: 900101,
                        title: "Formula1",
                        icon: "icon-formula-1",
                    },
                    {
                        menuId: 90010128,
                        parentMenuId: 900101,
                        title: "Rugby",
                        icon: "icon-rugby",
                    },
                    {
                        menuId: 90010129,
                        parentMenuId: 900101,
                        title: "CrossCountry",
                        icon: "icon-cross-country",
                    },
                    {
                        menuId: 90010130,
                        parentMenuId: 900101,
                        title: "Snooker",
                        icon: "icon-snooker",
                    },
                    {
                        menuId: 90010131,
                        parentMenuId: 900101,
                        title: "Cycling",
                        icon: "icon-cycling",
                    },
                    {
                        menuId: 90010132,
                        parentMenuId: 900101,
                        title: "MMA",
                        icon: "icon-mma",
                    },
                    {
                        menuId: 90010133,
                        parentMenuId: 900101,
                        title: "Soccer",
                        icon: "icon-soccer",
                    },
                ],
            },
        ],
    },
    {
        carteId: 10001,
        items: [
            {
                menuId: 1000101,
                parentMenuId: null,
                title: "Odds",
                icon: "icon-language",
                renderType: "odds",
                children: [
                    {
                        menuId: 100010101,
                        parentMenuId: 1000101,
                        title: "Decimal",
                        icon: "decimal",
                    },
                    {
                        menuId: 100010102,
                        parentMenuId: 1000101,
                        title: "Fractional",
                        icon: "fractional",
                    },
                    {
                        menuId: 100010103,
                        parentMenuId: 1000101,
                        title: "American",
                        icon: "american",
                    },
                    {
                        menuId: 100010104,
                        parentMenuId: 1000101,
                        title: "Indonesian",
                        icon: "indonesian",
                    },
                    {
                        menuId: 100010105,
                        parentMenuId: 1000101,
                        title: "HongKong",
                        icon: "hongkong",
                    },
                    {
                        menuId: 100010105,
                        parentMenuId: 1000101,
                        title: "Malaysian",
                        icon: "malaysian",
                    },
                ],
            },
        ],
    },
];

const lotteryMenuData: MenuProps[] = [
    {
        carteId: 14001,
        items: [
            {
                menuId: 1400101,
                parentMenuId: null,
                title: "My Bets",
                icon: "icon-betslip",
                onClick: () => openMyBets(),
            },
        ],
    },
];

const furnaceMenuData: MenuProps[] = [
    {
        carteId: 14001,
        items: [],
    },
];

const Menu: React.FC<{open: boolean; menuType?: "casino" | "sports" | "lottery" | "furnace"}> = ({open, menuType}) => {
    const isLogin = useAppSelector((state) => state.user.isLogin);
    const token = useAppSelector((state) => state.user.user?.token);
    useLiveChat();
    let menuData = isLogin ? [{...baseMenuData[0], items: [profileMenu, ...baseMenuData[0].items]}, ...baseMenuData.slice(1)] : baseMenuData;
    const casinoMenu = useMemo(() => {
        casinoMenuData[0].items[0].disabled = !token;
        casinoMenuData[0].items[1].disabled = !token;
        casinoMenuData[0].items[2].disabled = !token;
        return casinoMenuData;
    }, [token]);
    lotteryMenuData[0].items[0].disabled = !token;
    if (menuType === "casino") menuData = [...casinoMenu, ...menuData];
    if (menuType === "sports") menuData = [...sportMenuData, ...menuData];
    if (menuType === "lottery") menuData = [...lotteryMenuData, ...menuData];
    if (menuType === "furnace") menuData = [...furnaceMenuData, ...menuData];
    return (
        <AnimatePresence>
            <motion.div layout="position" className={open ? "px-2" : ""} initial={{opacity: 0}} animate={{opacity: 1}} key={open ? "large" : "small"}>
                {menuData.map((item) => (
                    <Expand carteId={item.carteId} items={item.items} title={open ? item.title : undefined} key={item.carteId} open={open}></Expand>
                ))}
            </motion.div>
        </AnimatePresence>
    );
};

export default Menu;
