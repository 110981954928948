import {Outlet, useLocation} from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import {useAppDispatch, useAppSelector} from "store";
import Footer from "./Footer";
import Tabbar from "./Tabbar";
import {useEffect} from "react";
import apiAuth from "api/auth";
import {closeDialog} from "store/slices/dialog";
import {loginSuccess} from "store/slices/user";
import {useIntl} from "react-intl";
import {toast} from "react-toastify";

const MainLayout = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const isMobile = useAppSelector((state) => state.config.isMobile);
    const isLogin = useAppSelector((state) => state.user.isLogin);
    const location = useLocation();
    const isSports = location.pathname.includes("/sports");

    const handleCredentialResponse = async (response: {credential: string}) => {
        const res = await apiAuth.googleLogin({
            credential: response.credential,
        });
        if (res.status === 200 && res.data.code === 0) {
            dispatch(loginSuccess(res.data.data));
            dispatch(closeDialog());
            return;
        }
        toast.error(intl.$t({id: `Google_LOGIN_ERROR_${res.data.code}`}));
    };

    useEffect(() => {
        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: "316095246777-jedlire8dsivhgiqh3vo4g96jao6888n.apps.googleusercontent.com",
                callback: handleCredentialResponse,
            });
            if (!isLogin) {
                window.google.accounts.id.prompt();
            }
        }
    }, [isLogin]);

    if (isMobile) {
        return (
            <div className="fixed top-0 bottom-0 left-0 right-0 flex w-full">
                <Header />
                <main className="mt-[60px] mb-[68px] flex-1 overflow-y-scroll overflow-x-hidden" id="app-root">
                    <div className={`w-full ${isSports ? "" : "px-[3vw]"}`}>
                        <div className="relative">
                            <Outlet />
                        </div>
                    </div>
                    <Footer />
                </main>
                <Tabbar />
            </div>
        );
    }
    return (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex">
            <Header />
            {!isSports && <Sidebar />}
            <main className="mt-[60px] flex-1 scrollY overflow-x-hidden" id="app-root">
                <div className={`w-full relative ${isSports ? "" : "px-[3vw]"}`}>
                    <div className={`relative ${isSports ? "" : "page"}`}>
                        <Outlet />
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    );
};

export default MainLayout;
