import client from "./client";

const login = (email: string, password: string) =>
    client.post("/login", {
        type: 1,
        email,
        password,
    });

const register = (email: string, password: string, promoCode?: string, inviteCode?: string) =>
    client.post("/register", {
        ...(promoCode ? {promoCode: promoCode} : {inviteCode: inviteCode || "-1"}),
        type: 1,
        email,
        password,
        langId: 2,
    });

const getBalance = () => client.post("/getCoin", {type: 0});

const getUserInfo = () => client.post("/getUserData");

const getVerificationCode = (email: string) => client.post("/getVerificationCode", {type: "1", account: email});

const getVerificationCodeMobile = (account: string) => client.post("/getVerificationCode", {type: 2, account});

const forgetPassword = ({email, password, code}: {email: string; password: string; code: string}) =>
    client.post("/forgetPassword", {type: "1", account: email, code, password});

const userSendVerifyEmail = (email: string) => client.post("/userSendVerifyEmail", {langId: 2, email});

const userVerifyEmailByCode = (email: string, code: number) => client.post("/userVerifyEmailByCode", {email, code});

const userBindMobile = (account: string, code: string) => client.post("/userBindMobile", {type: 2, account, code});

const apiUser = {
    login,
    register,
    getBalance,
    getUserInfo,
    getVerificationCode,
    forgetPassword,
    userSendVerifyEmail,
    getVerificationCodeMobile,
    userVerifyEmailByCode,
    userBindMobile,
};

export default apiUser;
