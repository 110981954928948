import {useState, useEffect} from "react";
import CurrencyList from "./currencyList";
import SvgIcon from "ui-component/SvgIcon";
import {useAppSelector} from "store";
import {getCurrencyIcon} from "utils/help";
import * as Popover from "@radix-ui/react-popover";
import AnimateButton from "ui-component/AnimateButton";
import {MoneyType} from "types";
import apiWallet from "api/wallet";
import {useCopyToClipboard} from "react-use";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {dispatch} from "store";
import {showDialog} from "store/slices/dialog";

const BuyCrypto = () => {
    const navigate = useNavigate();
    const {$t} = useIntl();
    const [{value}, copyToClipboard] = useCopyToClipboard();

    const isMobile = useAppSelector((state) => state.config.isMobile);

    const [currentCurrency, setCurrentCurrency] = useState(null);
    const [isNetworkOpen, setIsNetworkOpen] = useState(false);
    const [networkList, setNetworkList] = useState([]);
    const [selNetwork, setSelNetwork] = useState("");
    const [address, setAddress] = useState("");
    const [qrCode, setQrCode] = useState("");
    const [rate, setRate] = useState(0);

    const currencyChangeHandler = (selCurrency) => {
        setCurrentCurrency(selCurrency);
    };

    useEffect(() => {
        if (currentCurrency) {
            const list = currentCurrency.payChannel.split("/");
            setNetworkList(list);
            setSelNetwork(list[0]);
        }
    }, [currentCurrency]);

    useEffect(() => {
        const reqeustAddress = async () => {
            const {status, data} = await apiWallet.getWalletAddress(Number(currentCurrency.tokenId), selNetwork);
            if (status === 200 && data.code === 0) {
                setAddress(data.data?.address || "");
                setQrCode(data.data?.qrCode || "");
            }
        };
        if (selNetwork && currentCurrency) reqeustAddress();
    }, [selNetwork, currentCurrency]);

    useEffect(() => {
        if (value) toast.success($t({id: "Copied"}));
    }, [value, $t]);

    useEffect(() => {
        if (currentCurrency?.tokenId) {
            apiWallet.getSwapRate(MoneyType.USD, Number(currentCurrency.tokenId)).then((res) => {
                const rate = JSON.parse(res.data.data).data[0].rate;
                setRate(rate);
            });
        }
    }, [currentCurrency]);

    const goTransactions = () => {
        dispatch(showDialog(null));
        navigate("/transactions/deposits");
    };

    return (
        <div className="text-sm pt-3 sm:pt-4">
            {!currentCurrency && (
                <>
                    <div className="text-base text-white font-bold">{$t({id: "BuyCryptoOptions"})}</div>
                    <div className="w-full mt-2">
                        <CurrencyList currencyChange={currencyChangeHandler} type="deposit" />
                    </div>
                </>
            )}
            {currentCurrency && (
                <>
                    <div>
                        <div className="flex items-center">
                            <SvgIcon
                                dataKey="icon-back1"
                                className="fill-[#B1BAD2] w-4 h-4 mr-1 cursor-pointer"
                                onClick={() => {
                                    setCurrentCurrency(null);
                                }}
                            />
                            <img src={getCurrencyIcon(currentCurrency.tokenId)} alt="" className="w-5 h-5" />
                            <div className="ml-1 text-white text-base font-bold">
                                {$t({id: "Buy"})}&nbsp;{currentCurrency.tokenName}
                            </div>
                            {!isMobile && (
                                <div className="flex-1 text-end text-[#5EFB65] text-sm font-normal cursor-pointer" onClick={goTransactions}>
                                    {$t({id: "ViewTransactions"})}
                                </div>
                            )}
                        </div>
                        {isMobile && (
                            <div className="mt-3 text-end text-[#5EFB65] text-sm font-normal" onClick={goTransactions}>
                                {$t({id: "ViewTransactions"})}
                            </div>
                        )}
                    </div>
                    <div className="w-full bg-[#203743] rounded h-9 mt-4 pl-3 pr-2 flex items-center justify-between">
                        <div className="text-white text-sm font-semibold">{$t({id: "ChooseNetwork"})}</div>
                        <div className="w-40 sm:w-52 h-[1.625rem] bg-[#0E212E] rounded">
                            <div className="w-full h-full">
                                <Popover.Root
                                    onOpenChange={(open) => {
                                        setIsNetworkOpen(open);
                                    }}
                                    open={isNetworkOpen}>
                                    <Popover.Trigger className="w-full h-full">
                                        <div className="w-full flex items-center justify-between px-2 cursor-pointer">
                                            <div className="text-[#9DB8C6] text-sm font-normal uppercase">{selNetwork}</div>
                                            <SvgIcon dataKey="icon-down1" className="w-3 h-3" />
                                        </div>
                                    </Popover.Trigger>
                                    <Popover.Portal>
                                        <Popover.Content className="bg-[#304553] rounded overflow-hidden z-[101] w-full" style={{width: "100%"}}>
                                            <Popover.Arrow className="fill-[#304553]" />
                                            <div className="w-40 sm:w-52 flex flex-col gap-3 text-white font-semibold p-3">
                                                {networkList.map((v, key) => {
                                                    return (
                                                        <div
                                                            key={key}
                                                            className="w-full h-5 flex items-center cursor-pointer"
                                                            onClick={() => {
                                                                setSelNetwork(v);
                                                                setIsNetworkOpen(false);
                                                            }}>
                                                            {v}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Popover.Content>
                                    </Popover.Portal>
                                </Popover.Root>
                            </div>
                        </div>
                    </div>
                    <div className="w-full bg-[#203743] rounded mt-2 p-3">
                        <div className="w-full flex">
                            <div className="flex-1 pr-6 text-[#9DB8C6] text-xs font-normal">
                                {$t({id: "SendAmountToAddress"}, {A: currentCurrency.tokenName})}
                            </div>
                            <img src={`data:image/jpg;base64,${qrCode}`} alt="" className="w-20 h-20 flex-shrink-0" />
                        </div>
                        <div className="mt-1">
                            <div className="text-white text-sm font-semibold">{$t({id: "DepositAddress"})}</div>
                            <div className="mt-2 w-full h-8 flex justify-between">
                                <div className="flex-1 bg-[#0E212E] rounded flex items-center pl-2 text-[#9DB8C6] text-xs font-normal">{address}</div>
                                <AnimateButton>
                                    <SvgIcon
                                        dataKey="icon-walletCopy"
                                        className="w-8 h-8 ml-2 cursor-pointer"
                                        onClick={() => {
                                            copyToClipboard(address);
                                        }}
                                    />
                                </AnimateButton>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2 p-3 bg-[#203743] rounded">
                        <div className="w-full h-8 flex items-center justify-between">
                            <div className="flex-1 h-full bg-[#0E212E] rounded flex items-center pl-2">
                                <img src={getCurrencyIcon(MoneyType.USD)} alt="" className="w-5 h-5" />
                                <div className="pl-2 text-white text-sm font-semibold">100</div>
                            </div>
                            <div className="w-8 h-full flex items-center justify-center text-white text-sm font-semibold flex-shrink-0">=</div>
                            <div className="flex-1 h-full bg-[#0E212E] rounded flex items-center pl-2">
                                <img src={getCurrencyIcon(currentCurrency.tokenId)} alt="" className="w-5 h-5" />
                                <div className="pl-2 text-white text-sm font-semibold">{(100 * Number(rate)).toFixed(2)}</div>
                            </div>
                        </div>
                        <div className="mt-1 text-[#9DB8C6] text-center text-xs font-normal">{$t({id: "ValueMayChange"}, {A: currentCurrency.tokenName})}</div>
                    </div>
                    {/* <div className="mt-4 flex items-center justify-end">
                        <div className="text-[#9DB8C6] text-xs font-normal">{$t({id: "DonNotHaveCrypto"})}</div>
                        <div
                            className="pl-2 text-white text-sm font-semibold cursor-pointer"
                            onClick={() => {
                                console.log('')
                            }}>
                            <AnimateButton>{$t({id: "BuyCrypto"})}</AnimateButton>
                        </div>
                    </div> */}
                </>
            )}
        </div>
    );
};

export default BuyCrypto;
