import {useState, useEffect} from "react";
import useCurrencyList from "hooks/useCurrency";
import {getCurrencyIcon} from "utils/help";
import SvgIcon from "ui-component/SvgIcon";
import {useAppSelector} from "store";
import {useDebounce} from "react-use";
import {CurrencyItem} from "types/config";
import {useIntl} from "react-intl";

interface Props {
    currencyChange: (selCurrency: CurrencyItem) => void;
    type: "deposit" | "withdraw";
}

const CurrencyList = ({type, currencyChange}: Props) => {
    const {$t} = useIntl();
    const {currencyList} = useCurrencyList();
    const depositCurrency = currencyList.filter((c) => c.canDeposit === 1);
    const withdrawCurrency = currencyList.filter((c) => c.canWithdraw === 1);

    const isMobile = useAppSelector((state) => state.config.isMobile);
    const [selCurrency, setSelCurrency] = useState(null);
    const [isShowMoreCurrency, setIsShowMoreCurrency] = useState(false);

    const [keyword, setKeyword] = useState("");
    const [searchCurrencyList, setSearchCurrencyList] = useState([]);

    useEffect(() => {
        if (selCurrency) {
            currencyChange(selCurrency);
        }
    }, [selCurrency, currencyChange]);

    useDebounce(
        () => {
            const tempList = type === "deposit" ? depositCurrency : withdrawCurrency;
            if (!keyword) {
                return setSearchCurrencyList(tempList);
            }
            if (keyword) {
                const list = tempList.filter((v) => v.tokenName.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) !== -1);
                setSearchCurrencyList(list);
            }
        },
        100,
        [keyword],
    );

    const currencyListFront = searchCurrencyList.slice(0, 5);
    const currencyListLater = searchCurrencyList.slice(5, 10);

    const getOtherCurrencyIcon = () => {
        if (currencyListLater.length === 0) return null;
        const iconList = [];
        currencyListLater.forEach((v) => {
            const obj = {icon: getCurrencyIcon(v.tokenId)};
            iconList.push(obj);
        });
        return (
            <div className="w-full h-5 flex items-center pl-[10px]">
                {iconList.map((v, key) => {
                    return <img key={key} src={v.icon} className="w-5 h-5 -ml-[10px]" alt="" />;
                })}
            </div>
        );
    };

    // const getOtherCurrencyName = () => {
    //     if (currencyListLater.length === 0) return "";
    //     let str = "";
    //     currencyListLater.forEach((v) => {
    //         str = str + v.tokenName + "/";
    //     });
    //     return str.slice(0, -1);
    // };

    return (
        <>
            {!isShowMoreCurrency && (
                <div className="w-full grid grid-cols-3 sm:grid-cols-4 gap-2">
                    {currencyListFront.map((v, key) => {
                        return (
                            <div
                                key={key}
                                className="w-full h-[4.8125rem] bg-[#304553] rounded flex flex-col items-center justify-center gap-1 cursor-pointer"
                                onClick={() => {
                                    setSelCurrency(v);
                                }}>
                                <img src={getCurrencyIcon(v.tokenId)} className="w-5 h-5" alt="" />
                                <div className="text-sm font-semibold text-white">{v.tokenName}</div>
                            </div>
                        );
                    })}
                    {currencyListLater.length > 0 ? (
                        <div
                            className="w-full h-[4.8125rem] bg-[#304553] rounded flex flex-col items-center justify-center gap-1 cursor-pointer"
                            onClick={() => {
                                setIsShowMoreCurrency(true);
                            }}>
                            <div>{getOtherCurrencyIcon()}</div>
                            <div className="text-sm font-semibold text-white">{$t({id: "Others"})}</div>
                            {/* <div className="text-[#9DB8C6] text-xs font-normal w-full px-2 truncate">{getOtherCurrencyName()}</div> */}
                        </div>
                    ) : null}
                </div>
            )}
            {isShowMoreCurrency && (
                <div>
                    <div className="flex items-center">
                        <SvgIcon
                            dataKey="icon-back1"
                            className="fill-[#B1BAD2] w-4 h-4 mr-1"
                            onClick={() => {
                                setIsShowMoreCurrency(false);
                            }}
                        />
                        <div>{getOtherCurrencyIcon()}</div>
                        <div className="ml-1 text-white text-base font-bold">{$t({id: "DepositCryptoOthers"})}</div>
                        {!isMobile && <div className="flex-1 text-end text-[#5EFB65] text-sm font-normal">{$t({id: "ViewTransactions"})}</div>}
                    </div>
                    {isMobile && <div className="mt-3 text-end text-[#5EFB65] text-sm font-normal">{$t({id: "ViewTransactions"})}</div>}
                    <div className="mt-2 sm:mt-4">
                        <div className="w-full h-9 sm:h-10 flex items-center bg-[#0E212E] rounded px-2 sm:px-3" style={{border: "1px solid #26404D"}}>
                            <SvgIcon dataKey="icon-search1" className="w-5 h-5 fill-white" />
                            <input
                                type="text"
                                className="flex-1 min-w-0 h-full bg-transparent outline-none px-2 text-white font-semibold text-sm"
                                placeholder={$t({id: "SearchCrypto"})}
                                value={keyword}
                                onChange={(e) => {
                                    setKeyword(e.target.value);
                                }}
                            />
                        </div>
                        <div className="w-full max-h-[18rem] sm:max-h-[22.75rem] bg-[#304553] rounded p-2 sm:p-3 scrollY flex flex-col gap-2 sm:gap-3">
                            {searchCurrencyList.map((v, key) => {
                                return (
                                    <div
                                        key={key}
                                        className="w-full h-5 flex cursor-pointer"
                                        onClick={() => {
                                            setSelCurrency(v);
                                        }}>
                                        <img src={getCurrencyIcon(v.tokenId)} className="w-5 h-5" alt="" />
                                        <div className="pl-1 text-white text-base font-semibold">{v.tokenName}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CurrencyList;
