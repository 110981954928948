import {useMemo} from "react";
import {useAppSelector} from "store";

const useAllow = () => {
    const {emailStatus, mobileStatus} = useAppSelector((state) => state.user.user);
    const platformConfig = useAppSelector((state) => state.config.platformConfig);

    const allowWithdraw = useMemo(() => {
        if (platformConfig.withdrawEmailStatus === 1 && emailStatus !== 1) {
            return false;
        }
        if (platformConfig.withdrawMobileStatus === 1 && mobileStatus !== 1) {
            return false;
        }
        return true;
    }, [emailStatus, mobileStatus, platformConfig.withdrawEmailStatus, platformConfig.withdrawMobileStatus]);

    return {
        allowWithdraw,
    };
};

export default useAllow;
