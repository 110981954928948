// ==============================|| LOADER ||============================== //
import "styles/Loader.scss";
const Loader = () => {
    return (
        <div className="wrap svelte-16xn2i7">
            <div className="content svelte-16xn2i7">
                <div className="animation-container svelte-16dd2c0">
                    <div className="animation-wrapper svelte-16dd2c0">
                        <div className="animation-scale animation-scale-left-icons svelte-16dd2c0"></div>
                        <div className="animation-scale animation-scale-center-icons svelte-16dd2c0"></div>
                        <div className="animation-scale animation-scale-right-icons svelte-16dd2c0"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
