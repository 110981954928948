import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {getURL, getComboPath} from "utils/game";
import {GameInfo} from "types/game";

const useGameClick = () => {
    const navigate = useNavigate();

    const handleGameClick = useCallback(
        (game: GameInfo) => {
            if (game.gameInlineType === 1) {
                navigate(getURL(game));
            } else {
                navigate(`/game-detail/${getComboPath(game)}`, {state: game});
            }

            const bodyStyle = document.body.style;
            bodyStyle.overflow = "auto";
        },
        [navigate],
    );

    return {handleGameClick};
};

export default useGameClick;
