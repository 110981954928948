import {ActInfo} from "types/roulette";
import {getCurrencyIcon} from "utils/help";

export const ReportTable = ({actInfo, length}: {actInfo: ActInfo; length: number}) => {
    return (
        <div className="flex flex-col rounded-md bg-[#203743] px-2 py-3 items-center mt-4 gap-4 mb-4 w-full">
            {Array.from({length}).map((_, idx) => {
                return (
                    <div className="flex items-center text-sm w-full" key={idx}>
                        <div className="text-[#9DB8C6] font-normal w-1/3">User{parseInt((Math.random() * 100000000).toString())}</div>
                        <div className="text-white font-normal max-sm:ml-10 sm:w-1/3 sm:text-center">Just Withdrew</div>
                        <div className={"font-bold text-base text-right flex-1 text-[#5EFB65]"}>+{actInfo.targetAmount}</div>
                        <img src={getCurrencyIcon(actInfo.tokenId || 1)} className="w-4 h-4 ml-1" />
                    </div>
                );
            })}
        </div>
    );
};
