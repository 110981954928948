import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface DialogState {
    currentDialogID: string | null;
    dialogStacks: string[];
    eventDetailID: string;
    eventDetaiImgUrl: string;
}

const initialState: DialogState = {
    currentDialogID: null,
    dialogStacks: [],
    eventDetailID: "",
    eventDetaiImgUrl: "",
};

export const dialog = createSlice({
    name: "dialog",
    initialState,
    reducers: {
        showDialog: (state, action: PayloadAction<string | null>) => {
            if (state.currentDialogID === action.payload) return;
            if (state.currentDialogID !== null) {
                state.dialogStacks.push(state.currentDialogID);
            }
            state.currentDialogID = action.payload;
        },
        closeDialog: (state) => {
            state.currentDialogID = null;
            state.dialogStacks = [];
        },
        setEventDetailID: (state, action: PayloadAction<string | null>) => {
            state.eventDetailID = action.payload;
        },
        setEventDetaiImgUrl: (state, action: PayloadAction<string | null>) => {
            state.eventDetaiImgUrl = action.payload;
        },
    },
});

export const {showDialog, closeDialog, setEventDetailID, setEventDetaiImgUrl} = dialog.actions;

export default dialog.reducer;
