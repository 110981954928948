import React, {useEffect} from "react";
import {motion, AnimatePresence} from "framer-motion";
import {backdropVariants, modalVariants} from "utils/constants";
import {useAppSelector} from "store";
import {dispatch} from "store";
import {showDialog} from "store/slices/dialog";
import {LoginType} from "types/dialog";

import DialogLogin from "views/dialog/login/index";
import DialogWallet from "views/dialog/wallet/index";
import DialogLogout from "views/dialog/logout/index";
import DialogWalletSettings from "views/dialog/walletSettings/index";
import ReconnectSocketModal from "views/dialog/ReconnectSocketModal";
import CreateCampaign from "views/dialog/createCampaign/index";
import EventDetail from "views/dialog/eventDetail/index";

interface Props {
    isOpen: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
}

const Dialog = ({isOpen, onClose, children}: Props) => {
    const currentDialogID = useAppSelector((state) => state.dialog.currentDialogID);

    const closeHandler = () => {
        dispatch(showDialog(null));
        onClose && onClose();
    };

    const getDialogContent = () => {
        if (currentDialogID === LoginType.SIGNIN || currentDialogID === LoginType.SIGNUP) {
            return <DialogLogin />;
        }
        if (currentDialogID === "wallet") {
            return <DialogWallet />;
        }
        if (currentDialogID === "reconnectSocket") {
            return <ReconnectSocketModal />;
        }
        if (currentDialogID === "logout") {
            return <DialogLogout />;
        }
        if (currentDialogID === "walletSettings") {
            return <DialogWalletSettings />;
        }
        if (currentDialogID === "createCampaign") {
            return <CreateCampaign />;
        }
        if (currentDialogID === "eventDetail") {
            return <EventDetail />;
        }
        return children || null;
    };

    useEffect(() => {
        document.body.style.overflow = isOpen ? "hidden" : "auto";
    }, [isOpen]);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed left-0 top-0 right-0 bottom-0 flex items-center justify-center p-4 z-[101]"
                    style={{background: "rgba(14,18,36,.7)"}}
                    variants={backdropVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    onClick={closeHandler}>
                    <motion.div
                        className={`w-full min-w-[200px] max-w-[500px] overflow-x-hidden overflow-y-auto max-h-[calc(100%-4em)] ${
                            currentDialogID === "walletSettings" ? "absolute top-20" : ""
                        }`}
                        variants={modalVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        onClick={(e) => e.stopPropagation()}>
                        {getDialogContent()}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default Dialog;
