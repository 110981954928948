// third-party
import {motion} from "framer-motion";
import SvgIcon from "./SvgIcon";
import {getGameLogoUrl} from "utils/help";
import useGameClick from "hooks/useGameClick";
import {useAppSelector} from "store";

const AllGallery = (props) => {
    const {CarouselList, GameType, iconKey, gameItemHandler = undefined} = props;
    const {handleGameClick} = useGameClick();
    const isMobile = useAppSelector((state) => state.config.isMobile);
    return (
        <div className="allgallery w-full h-full pt-2.5 relative">
            {iconKey && GameType ? (
                <div className="grid grid-flow-col justify-between h-[2.27rem]">
                    <div className="grid grid-flow-col gap-0 justify-center items-center h-full">
                        <SvgIcon dataKey={iconKey} className="w-4 h-4 fill-current hover:text-white text-gray-200" />
                        <span className="ml-2 text-white">{GameType}</span>
                    </div>
                </div>
            ) : null}
            <div
                className={`grid grid-cols-3 gap-x-1 gap-y-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 sm:gap-y-2 sm:gap-x-1 md:gap-y-4 xl:gap-x-2 xl:gap-y-4 2xl: gap-4`}>
                {CarouselList &&
                    CarouselList.length > 0 &&
                    CarouselList.map((item, index) => {
                        return (
                            // min-h-[10rem]
                            <motion.div
                                key={index}
                                whileHover={{y: isMobile ? 0 : -10}}
                                initial={{y: 0}}
                                className="relative cursor-pointer"
                                onClick={() => {
                                    gameItemHandler && gameItemHandler();
                                    handleGameClick(item);
                                }}>
                                <div className="relative">
                                    <img className="rounded-[0.5rem] w-full h-full" loading="lazy" src={getGameLogoUrl(item)} draggable="false" />
                                    <motion.div
                                        className="grid grid-flow-row grid-cols-1 gap-2 hover:text-white justify-start absolute top-0 left-0 w-full h-full p-5 hover:bg-blue-500 hover:bg-opacity-80 rounded-[0.5rem]"
                                        initial={{opacity: 0}}
                                        whileHover={{opacity: 1, transition: {duration: 0.3}}}>
                                        {!isMobile ? (
                                            <>
                                                <div className="min-w-[5.5rem] h-6 text-[1rem] overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                    {item.gameName}
                                                </div>
                                                <div className="grid justify-center items-center">
                                                    <SvgIcon dataKey="icon-play" className="w-6 h-6 fill-current hover:text-white" />
                                                </div>
                                                <div className="text-[1rem]">{item.providerName}</div>
                                            </>
                                        ) : null}
                                    </motion.div>
                                </div>
                            </motion.div>
                        );
                    })}
            </div>
        </div>
    );
};
export default AllGallery;
