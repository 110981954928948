import * as PIXI from "pixi.js";
import * as TWEEDLE from "tweedle.js";
import PddWheelApp, { PddWheelAction_G2H5 } from "./PddWheelApp";
import GameScene from "../GameScene";
import I18NLabel from "../Utils/I18NLabel";
import I18NMgr from "../Utils/I18NMgr";

enum AudioEffects {
	BUTTON = "click.mp3",
	TICK = "tick.mp3",
	WINNERS = "winners.mp3"
}

type tRotationState = "idle" | "prepare" | "rotating";

export class PddWheelScene extends GameScene<PddWheelApp> {
	private bglight: PIXI.Sprite = null;
	private gamebg: PIXI.Sprite = null;
	private wheellayer: PIXI.Container = null;
	private wheelPanel: PIXI.Container = null;
	private wheel: PIXI.Sprite = null;
	private rewards: PIXI.Container = null;
	private lights: PIXI.Container = null;
	private spinBtnPanel: PIXI.Container = null;
	private spinBtn: PIXI.Sprite = null;
	private spinBtn1: PIXI.Sprite = null;
	private spinInfo: PIXI.Text = null;
	private getMoreBtn: I18NLabel = null;
	private underline: PIXI.Sprite = null;
	private deadlinePanel: PIXI.Container = null;
	private deadlineText: PIXI.Text = null;
	private rule: PIXI.Sprite = null;
	private lefttimeTitleText: I18NLabel = null;
	private lefttimeText: PIXI.Text = null;
	private pointer: PIXI.Sprite = null;
	private diamond: PIXI.Sprite = null;
	private ribbon: PIXI.Sprite = null;
	private lighton: string = "light1.png";
	private lightoff: string = "light2.png";
	private leftCountText: PIXI.Text = null;

	private countdownTimer: any = null;

	private rotationState: tRotationState = 'prepare';

	private wheelConfig: any = null;

	protected gameApp: PddWheelApp = null;

	releaseScene() {
		super.releaseScene();
	}

	secondFormat(second: number) {
		let h: any = Math.floor(second / 3600);
		let m: any = Math.floor((second / 60) % 60);
		let s: any = Math.floor(second % 60);
		if (h < 10) {
			h = `0${h}`;
		}
		if (m < 10) {
			m = `0${m}`;
		}
		if (s < 10) {
			s = `0${s}`;
		}
		return `${h}:${m}:${s}`;
	};

	secondFormatWithDay(second: number) {
		let d: any = Math.floor(second / (24 * 3600));
		let h: any = Math.floor((second / 3600) % 24);
		let m: any = Math.floor((second / 60) % 60);
		let s: any = Math.floor(second % 60);

		if (d < 10) {
			d = `0${d}`;
		}

		if (h < 10) {
			h = `0${h}`;
		}
		if (m < 10) {
			m = `0${m}`;
		}
		if (s < 10) {
			s = `0${s}`;
		}
		return `${d}     ${h}     ${m}    ${s}`;
	};

	refreshWheel() {
		if (!this.wheelConfig) return;
		this.leftCountText.text = `${this.wheelConfig.count}`;
		let awardCount = 8;
		this.segUnit = 360 / awardCount;
		for (let i = 0; i < awardCount; i++) {
			let item = new PIXI.Sprite;
			item.anchor.set(0.5, 0.5);
			item.position.set(0, 0);
			item.angle = i * 360 / awardCount - 90;
			item.name = `item_${i}`;
			this.rewards.addChild(item);

			let icon = new PIXI.Sprite;
			icon.anchor.set(0.5, 0.5);
			icon.position.set(80, 0);
			icon.angle = 90;
			item.addChild(icon);

			let label = new PIXI.Text();
			label.style = {
				fill: "white",
				fontSize: "18px",
				fontWeight: "700",
			}
			label.anchor.set(0.5, 0.5);
			label.position.set(80, 0);
			label.angle = 90;
			item.addChild(label);

			if (this.wheelConfig.awards[i].type == 1) {
				label.text = this.wheelConfig.awards[i].price;
			}
			else {
				icon.texture = PIXI.Texture.from(this.wheelConfig.awards[i].icon);
			}
		}
	}

	load(app: PddWheelApp) {
		this.gameApp = app;
		super.init(false, 3);

		this.bglight = PIXI.Sprite.from(this.getTexture("light.png"));
		this.bglight.anchor.set(0.5, 0.5);
		this.bglight.position.set(0, 0);
		this.bglight.scale.set(0.5);
		this.container.addChild(this.bglight);

		this.gamebg = PIXI.Sprite.from(this.getTexture("bg.png"));
		this.gamebg.anchor.set(0.5, 0.5);
		this.gamebg.position.set(0, 0);
		this.gamebg.scale.set(0.5);
		this.container.addChild(this.gamebg);

		this.wheellayer = new PIXI.Container;
		this.wheellayer.position.set(0, 30);
		this.container.addChild(this.wheellayer);

		this.wheelPanel = new PIXI.Container;
		this.wheelPanel.position.set(0, 0);
		this.wheelPanel.scale.set(1, 1);
		this.wheellayer.addChild(this.wheelPanel);

		this.wheel = PIXI.Sprite.from(this.getTexture("spinbg.png"));
		this.wheel.scale.set(0.5);
		this.wheel.anchor.set(0.5, 0.5);
		this.wheel.position.set(0, 0);
		this.wheelPanel.addChild(this.wheel);

		this.rewards = new PIXI.Container;
		this.rewards.position.set(0, 0);
		this.wheelPanel.addChild(this.rewards);

		this.lights = new PIXI.Container;
		this.lights.position.set(-2);
		this.wheelPanel.addChild(this.lights);

		let l1 = PIXI.Sprite.from(this.getTexture("light1.png"));
		l1.scale.set(0.5);
		l1.anchor.set(0.5);
		l1.position.set(0);
		l1.visible = true;
		this.lights.addChild(l1);

		let l2 = PIXI.Sprite.from(this.getTexture("light2.png"));
		l2.scale.set(0.5);
		l2.anchor.set(0.5);
		l2.position.set(0);
		l1.visible = false;
		this.lights.addChild(l2);

		this.pointer = PIXI.Sprite.from(this.getTexture("pointer.png"));
		this.pointer.scale.set(0.5);
		this.pointer.position.set(0, -10);
		this.pointer.anchor.set(0.5, 0.5);
		this.pointer.interactive = true;
		this.pointer.cursor = "pointer";
		this.wheellayer.addChild(this.pointer);

		this.diamond = PIXI.Sprite.from(this.getTexture("diamond.png"));
		this.diamond.scale.set(0.5);
		this.diamond.position.set(0, -112);
		this.diamond.anchor.set(0.5, 0.5);
		this.wheellayer.addChild(this.diamond);

		this.ribbon = new PIXI.Sprite();
		this.ribbon.scale.set(0.5, 0.5);
		this.ribbon.anchor.set(0.5, 0.5);
		this.ribbon.position.set(0, 185);
		this.wheellayer.addChild(this.ribbon);

		this.spinBtnPanel = new PIXI.Container;
		this.spinBtnPanel.position.set(0, 0);
		this.wheellayer.addChild(this.spinBtnPanel);

		this.spinBtn = new PIXI.Sprite();
		this.spinBtn.scale.set(0.5);
		this.spinBtn.position.set(0, 0);
		this.spinBtn.anchor.set(0.5, 0.5);
		this.spinBtn.interactive = false;
		this.spinBtn.cursor = "pointer";
		this.spinBtnPanel.addChild(this.spinBtn);

		this.leftCountText = new PIXI.Text();
		this.leftCountText.style = {
				fill: "white",
				fontSize: "36px",
				fontWeight: "700",
		}
		this.leftCountText.text = "";
		this.leftCountText.anchor.set(0.5);
		this.leftCountText.position.set(0);
		this.wheellayer.addChild(this.leftCountText);

		this.pointer
			.on("pointerdown", (ev: PIXI.FederatedPointerEvent) => {
				this.onSpin();
			})
			.on("pointerup", () => {
				this.spinBtnPanel.scale.set(1, 1);
			})
			.on("pointerupoutside", () => {
				this.spinBtnPanel.scale.set(1, 1);
			});

		this.effect = true;
	}

	private count = 0;

	private segmentCur: number = 0;
	private segUnit: number = 0;

	update() {
		this.count++;
		if (this.count % 30 == 0) {
			this.count = 0;
			this.lights.children[0].visible = !this.lights.children[0].visible;
			this.lights.children[1].visible = !this.lights.children[0].visible;
		}

		if (this.rotationState == "rotating") {
			let s = Math.ceil(Math.abs(this.wheelPanel.angle / this.segUnit));
			if (s != this.segmentCur) {
				this.playEffect(AudioEffects.TICK);
			}
			this.segmentCur = s;
		}
	}

	loadAudio() {
		this.initAudio(AudioEffects.BUTTON);
		this.initAudio(AudioEffects.TICK);
		this.initAudio(AudioEffects.WINNERS);
	}

	onSpin() {
		if (!!this.effect) {
			this.playEffect(AudioEffects.BUTTON);
		}
		if (this.rotationState != "idle") return;
		if (!this.wheelConfig) return;
		let leftTimes = !!this.wheelConfig.count ? this.wheelConfig.count : 0;
		if (leftTimes <= 0) {
			this.gameApp.postMessage(PddWheelAction_G2H5.noCount, { });
			return;
		};

		this.rotationState = "prepare";
		this.gameApp.postMessage(PddWheelAction_G2H5.spinWheel, { wheelid: this.wheelConfig.wheel_id, version: this.wheelConfig.version });
	}

	handleSwitch(msg: any) {
	}

	handleSpinFail() {
		this.rotationState = "idle";
	}

	handleWheelData(msg: any) {
		this.rotationState = "idle";
		this.wheelConfig = msg;
		if (!this.wheelConfig) return;
		this.wheelPanel.angle = 0;
		this.refreshWheel();
	}

	handleSpin() {
		this.playEffect(AudioEffects.BUTTON);
		this.onSpin();
	}

	handleSpinReply(msg: any) {
		if (!this.wheelConfig) {
			this.rotationState = "idle";
			return;
		}

		let idx = this.wheelConfig.awards.findIndex(award => award.id == msg.award_id);
		if (idx == -1) {
			this.rotationState = "idle";
			return;
		}
		this.rotationState = "rotating";

		let awardCount = this.wheelConfig.awards.length;
		let angle = ((awardCount - idx) * 360 / awardCount + 360 * 2);
		this.wheelPanel.angle = 0;

		new TWEEDLE.Tween(this.wheelPanel).to({ angle: angle }, 4000).easing(TWEEDLE.Easing.Quintic.Out).onComplete(() => {
			this.playEffect(AudioEffects.WINNERS);

			this.wheelConfig.count = msg.count;
			this.gameApp.postMessage(PddWheelAction_G2H5.result, {amount:msg.amount});
			this.refreshWheel();
			this.rotationState = 'idle';

			// if (this.wheelConfig.awards[idx].type == 1) {
			// 	this.gameApp.postMessage(PddWheelAction_G2H5.result, { isCurrency: true, rewardName: this.wheelConfig.awards[idx].currency_id, currencyType: this.wheelConfig.awards[idx].currency_id, currencyAmount: this.wheelConfig.awards[idx].price });
			// }
			// else {
			// 	this.gameApp.postMessage(PddWheelAction_G2H5.result, { isCurrency: false, rewardName: this.wheelConfig.awards[idx].price, currencyType: this.wheelConfig.awards[idx].currency_id, currencyAmount: this.wheelConfig.awards[idx].price });
			// }

		}).start();
	}

	switchLang() {
	}
}
