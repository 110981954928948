import client from "./client";

const getUserInfo = () => client.post("/getUserData");

const requestPlatformConfig = (data: {promoCode: string; inviteCode: string}) => client.post("/getPlatformConfig", data);

const getWebSocketUrl = () => client.post("/getWebSocketUrl");

const ghostLogin = () => client.post("/ghostLogin");

const getVipMonthData = () => client.post("/getVipMonthData");

const getVipMonthRewards = (tokenId, type) => client.post("/getVipMonthRewards", {tokenId, type});

const getUserChargeAmountUsd = () => client.post("/getUserChargeAmountUsd");

const getWithdrawLimit = (type = 0) => client.post("/getWithdrawLimit", {type});

const googleLogin = (data: {credential?: string; accessToken?: string; inviteCode?: string; promoCode?: string}) => client.post("/googleLogin", data);

const metaMaskLogin = (data: {publicAddress: string; signature: string; inviteCode?: string; promoCode?: string}) => client.post("/metamask/login", data);

const getMetaMaskNonce = (publicAddress: string) => client.get("/metamask/nonce", {params: {publicAddress}});

const getBeeAuthUrl = () => client.post("/beenetwork/getAuthUrl");

const beeLogin = (data: {code: string; state: string; inviteCode?: string; promoCode?: string}) => client.post("/beenetwork/beeLogin", data);

const tgLogin = (data: {
    auth_date?: string;
    first_name?: string;
    last_name?: string;
    photo_url?: string;
    hash?: string;
    id?: string;
    inviteCode?: string;
    promoCode?: string;
}) => client.post("/tgLogin", data);

const apiAuth = {
    requestPlatformConfig,
    getUserInfo,
    ghostLogin,
    googleLogin,
    getUserChargeAmountUsd,
    tgLogin,
    metaMaskLogin,
    getMetaMaskNonce,
    getVipMonthData,
    getVipMonthRewards,
    getWebSocketUrl,
    getBeeAuthUrl,
    beeLogin,
    getWithdrawLimit,
};

export default apiAuth;
