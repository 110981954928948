import {useState} from "react";
import AnimateButton from "ui-component/AnimateButton";
import SvgIcon from "ui-component/SvgIcon";
import {Formik, Form, Field, ErrorMessage, useFormikContext} from "formik";
import * as Yup from "yup";
import apiUser from "api/user";
import md5 from "md5";
import {dispatch, useAppSelector} from "store";
import {useIntl} from "react-intl";
import {toast} from "react-toastify";
import {loginSuccess} from "store/slices/user";
import {showDialog} from "store/slices/dialog";
import Loader from "ui-component/Loader";
import google from "assets/images/google.png";
import GoogleLogin from "./GoogleLogin";
import MetaMaskLogin from "./MetaMaskLogin";
import TelegramLogin from "./TelegramLogin";
import {useKeyPressEvent} from "react-use";

interface Props {
    onChangeLoginType?: (type: string) => void;
}

const SignUp = ({onChangeLoginType}: Props) => {
    const {$t} = useIntl();

    const [showPass, setShowPass] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const inviteCode = useAppSelector((state) => state.config.inviteCode);
    const promoCode = useAppSelector((state) => state.config.promoCode);

    Yup.addMethod(Yup.string, "customPassword", function () {
        return this.test("customPassword", $t({id: "YourPasswordLeast8Long"}), function (value) {
            if (!value) return true;
            const hasUpperCase = /[A-Z]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            const hasNumber = /\d/.test(value);
            const isLengthValid = value.length >= 8 && value.length <= 16;
            return hasUpperCase && hasLowerCase && hasNumber && isLengthValid;
        });
    });

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email($t({id: "EmailDomainNotSpported"}))
            .required($t({id: "ThisFieldIsRequired"})),
        password: Yup.string()
            .matches(/[A-Z]/, $t({id: "PasswordIncludeLowerUpperCharacter"}))
            .matches(/[a-z]/, $t({id: "PasswordIncludeLowerUpperCharacter"}))
            .matches(/\d/, $t({id: "PasswordIncludeOneNumber"}))
            .required($t({id: "ThisFieldIsRequired"}))
            .min(8, $t({id: "YourPasswordLeast8Long"})),
        agree: Yup.boolean().isTrue($t({id: "PleaseReadAndAccept"})),
    });

    const initialValues = {
        email: "",
        password: "",
        isInviteCode: (inviteCode && inviteCode.length > 0) || (promoCode && promoCode.length > 0),
        inviteCode: inviteCode || promoCode,
        agree: false,
    };

    const getErrorElement = (errorMessage: string) => {
        return (
            <div className="flex items-center pt-2 pb-1 text-[#F2708A] text-xs">
                <SvgIcon dataKey="icon-error" className="w-3 h-3 mr-2" style={{fill: "#F2708A"}} />
                <span>{errorMessage}</span>
            </div>
        );
    };

    const getPassErrorElement = (errorMessage: string, values: {password: string}) => {
        const password = values.password;
        const hasUpperCaseLowerCase = /[A-Z]/.test(password) && /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const has8Str = /^.{8,}$/.test(password);

        return (
            <div>
                <div className="flex items-center pt-2 pb-1 text-[#F2708A] text-xs">
                    <SvgIcon dataKey="icon-error" className="w-3 h-3 mr-2" style={{fill: "#F2708A"}} />
                    <span>{errorMessage}</span>
                </div>
                <div className="text-xs mt-1 flex items-center">
                    <SvgIcon dataKey="icon-check" className="w-3 h-3 mr-2" style={{fill: hasUpperCaseLowerCase ? "#00b801" : "#b1bad3"}} />
                    {$t({id: "IncludesLowerUpperCase"})}
                </div>
                <div className="text-xs mt-1 flex items-center">
                    <SvgIcon dataKey="icon-check" className="w-3 h-3 mr-2" style={{fill: hasNumber ? "#00b801" : "#b1bad3"}} />
                    {$t({id: "AtLeast1Number"})}
                </div>
                <div className="text-xs mt-1 flex items-center">
                    <SvgIcon dataKey="icon-check" className="w-3 h-3 mr-2" style={{fill: has8Str ? "#00b801" : "#b1bad3"}} />
                    {$t({id: "Minimum8Characters"})}
                </div>
            </div>
        );
    };

    const handleSubmit = async (values: {agree: boolean; email: string; password: string; isInviteCode: boolean; inviteCode: string}) => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            const {email, password, inviteCode} = values;

            // When the invitation code entered by the user contains letters, pass the parameter promoCode
            let inviteCodeParam = inviteCode;
            let promoCodeParam = promoCode;
            if (inviteCodeParam.length > 0 && /[a-zA-Z]/.test(inviteCodeParam)) {
                promoCodeParam = inviteCodeParam;
                inviteCodeParam = "";
            }

            const res = await apiUser.register(email, md5(password), promoCodeParam, inviteCodeParam);
            const {status, data} = res;
            if (status === 200 && data.code === 0) {
                dispatch(loginSuccess(data.data));
                dispatch(showDialog(null));
                return;
            }
            if (data.code === 101) {
                toast.error($t({id: "SystemError"}));
                return;
            }
            toast.error(data.code ? $t({id: `REGISTER_ERROR_${data.code}`}) : data.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleLogin = () => {
        const googleLoginWrapper: HTMLElement = document.getElementById("custom-google-button");
        if (googleLoginWrapper) {
            const googleLoginWrapperButton: HTMLElement = googleLoginWrapper.querySelector("div[role=button]");
            if (googleLoginWrapperButton) {
                googleLoginWrapperButton.click();
            }
        }
    };

    const FormHandler = () => {
        const {values} = useFormikContext();

        useKeyPressEvent("Enter", (event) => {
            const isValid = validationSchema.isValidSync({email: values["email"] || "", password: values["password"] || ""});
            if (isValid) setCurrentStep(2);

            event.preventDefault();
        });

        return null;
    };

    return (
        <div className="font-semibold">
            <div className="text-lg text-white text-center font-semibold">{$t({id: "CreateAccount"})}</div>
            <div className="text-center text-base text-[#b1bad3] mt-4 relative">
                {currentStep === 2 && (
                    <div className="absolute left-0 top-0 w-5 h-5 flex items-center justify-center" style={{border: "1px solidd red"}}>
                        <AnimateButton>
                            <SvgIcon
                                dataKey="icon-back"
                                className="w-4 h-4"
                                style={{fill: "#b1bad3"}}
                                onClick={() => {
                                    setCurrentStep(1);
                                }}
                            />
                        </AnimateButton>
                    </div>
                )}
                {$t({id: "Step"})} {currentStep}/2：{currentStep === 1 ? $t({id: "FillOutYourDetails"}) : $t({id: "ReadAcceptTermsConditions"})}
            </div>

            <div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({values}) => (
                        <Form autoComplete="off">
                            {currentStep === 1 && (
                                <>
                                    <div className="mt-4">
                                        <div>
                                            <label htmlFor="email">
                                                {$t({id: "Email"})}
                                                <span className="text-[#F2708A] ml-1">*</span>
                                            </label>
                                        </div>

                                        <Field
                                            type="text"
                                            name="email"
                                            autocomplete="off"
                                            className="mt-1 w-full h-[40px] text-white bg-[#0f212e] rounded outline-none p-2 border-2 border-solid border-[#2f4553] focus:border-[#557086]"
                                        />
                                        <input type="text" style={{display: "none"}} />
                                        <ErrorMessage name="email">{getErrorElement}</ErrorMessage>
                                    </div>

                                    <div className="mt-4">
                                        <div>
                                            <label htmlFor="password">
                                                {$t({id: "Password"})}
                                                <span className="text-[#F2708A] ml-1">*</span>
                                            </label>
                                        </div>
                                        <div className="relative">
                                            <Field
                                                type={showPass ? "text" : "password"}
                                                name="password"
                                                autocomplete="new-password"
                                                className="mt-1 w-full h-[40px] text-white bg-[#0f212e] rounded outline-none p-2 border-2 border-solid border-[#2f4553] focus:border-[#557086]"
                                            />
                                            <input type="text" style={{display: "none"}} />
                                            <div className="absolute top-1 right-0 w-12 h-10 flex items-center justify-center">
                                                <AnimateButton>
                                                    <SvgIcon
                                                        dataKey="icon-eye"
                                                        className=" w-4 h-4"
                                                        style={{fill: showPass ? "#b1bad3" : "#ffffff"}}
                                                        onClick={() => {
                                                            setShowPass(!showPass);
                                                        }}
                                                    />
                                                </AnimateButton>
                                            </div>
                                        </div>

                                        <ErrorMessage name="password">{(errorMessage) => getPassErrorElement(errorMessage, values)}</ErrorMessage>
                                    </div>
                                    <div className="mt-4">
                                        <label>
                                            <Field type="checkbox" name="isInviteCode" className="bg-transparent" style={{border: "1px solid red"}} />
                                            {$t({id: "Code"})}({$t({id: "Optional"})})
                                        </label>
                                    </div>
                                    {values.isInviteCode && (
                                        <div className="mt-4">
                                            <Field
                                                type="text"
                                                name="inviteCode"
                                                disabled={inviteCode || promoCode}
                                                className="mt-1 w-full h-[40px] text-white bg-[#0f212e] rounded outline-none p-2 border-2 border-solid border-[#2f4553] focus:border-[#557086]"
                                            />
                                        </div>
                                    )}

                                    <AnimateButton>
                                        <button
                                            type="button"
                                            className="w-full h-12 mt-4 flex font-semibold items-center justify-center text-[#05080a] text-base bg-[#00e701] rounded"
                                            style={{boxShadow: "0rem .0625rem .1875rem #00000033 , 0rem .0625rem .125rem #0000001f"}}
                                            onClick={() => {
                                                if (!validationSchema.isValidSync({email: values.email, password: values.password})) return;
                                                setCurrentStep(2);
                                            }}>
                                            {$t({id: "Continue"})}
                                        </button>
                                    </AnimateButton>
                                    <div className="w-full h-5 flex items-center justify-center mt-4">
                                        <div className="w-16 h-[1px] bg-[#2f4553]"></div>
                                        <div className="text-sm text-[#b1bad3] px-4">{$t({id: "OrUse"})}</div>
                                        <div className="w-16 h-[1px] bg-[#2f4553]"></div>
                                    </div>
                                    {/* <div className="w-full h-10 mt-4">
                                        <AnimateButton>
                                            <button
                                                onClick={handleGoogleLogin}
                                                className="w-28 mx-auto bg-[#2f4553] rounded flex items-center justify-center py-3 px-4"
                                                style={{boxShadow: "0rem .0625rem .1875rem #00000033 , 0rem .0625rem .125rem #0000001f"}}>
                                                <img src={google} className="w-4 h-4" alt="" />
                                            </button>
                                        </AnimateButton>
                                    </div> */}
                                    <div className="w-full mt-4 grid grid-cols-3 gap-2">
                                        <AnimateButton>
                                            <MetaMaskLogin />
                                        </AnimateButton>
                                        <AnimateButton>
                                            <GoogleLogin />
                                        </AnimateButton>
                                        <AnimateButton>
                                            <TelegramLogin />
                                        </AnimateButton>
                                    </div>
                                </>
                            )}
                            {currentStep === 2 && (
                                <>
                                    <div className="mt-4 w-full max-h-[50vh] bg-[#213743] p-4 rounded text-start text-sm text-[#b1bad3] overflow-x-hidden overflow-y-auto">
                                        <p className="text-white text-lg font-semibold">{$t({id: "TermsAndConditions"})}</p>
                                        <p className="mt-4">
                                            1.1 A9.game is owned and operated by Medium Rare, N.V., a company with head office at Korporaalweg 10, Willemstad,
                                            Curaçao. Stake is licensed and regulated by the Government of Curaçao under the gaming license 8048/JAZ issued to
                                            Antillephone. Some credit card payment processing are handled by its wholly owned subsidiary, Medium Rare Limited.
                                        </p>
                                        <p className="mt-4">
                                            1.2(THE “WEBSITE”), YOU ENTER INTO A CONTRACT WITH MEDIUM RARE N.V., AND AGREE TO BE BOUND BY (I) THESE TERMS AND
                                            CONDITIONS; (II) OUR PRIVACY POLICY; (III) OUR COOKIES POLICY AND (IV) THE RULES APPLICABLE TO OUR BETTING OR GAMING
                                            PRODUCTS AS FURTHER REFERENCED IN THESE TERMS AND CONDITIONS (“TERMS AND CONDITIONS” OR “AGREEMENT”), AND THE
                                            BETTING AND/OR GAMING SPECIFIC RULES, AND ARE DEEMED TO HAVE ACCEPTED AND UNDERSTOOD ALL THE TERMS.
                                        </p>
                                        <p className="mt-4">
                                            1.3 A9.game is owned and operated by Medium Rare, N.V., a company with head office at Korporaalweg 10, Willemstad,
                                            Curaçao. Stake is licensed and regulated by the Government of Curaçao under the gaming license 8048/JAZ issued to
                                            Antillephone. Some credit card payment processing are handled by its wholly owned subsidiary, Medium Rare Limited.
                                        </p>
                                        <p className="mt-4">
                                            1.4(THE “WEBSITE”), YOU ENTER INTO A CONTRACT WITH MEDIUM RARE N.V., AND AGREE TO BE BOUND BY (I) THESE TERMS AND
                                            CONDITIONS; (II) OUR PRIVACY POLICY; (III) OUR COOKIES POLICY AND (IV) THE RULES APPLICABLE TO OUR BETTING OR GAMING
                                            PRODUCTS AS FURTHER REFERENCED IN THESE TERMS AND CONDITIONS (“TERMS AND CONDITIONS” OR “AGREEMENT”), AND THE
                                            BETTING AND/OR GAMING SPECIFIC RULES, AND ARE DEEMED TO HAVE ACCEPTED AND UNDERSTOOD ALL THE TERMS.
                                        </p>
                                    </div>
                                    <div className="mt-4">
                                        <label>
                                            <Field type="checkbox" name="agree" className="bg-transparent" style={{border: "1px solid red"}} />
                                            {$t({id: "IHaveReadAndAgreeTermsConditions"})}
                                        </label>
                                        <ErrorMessage name="agree">{getErrorElement}</ErrorMessage>
                                    </div>
                                    <AnimateButton>
                                        <button
                                            type="submit"
                                            className="w-full h-12 mt-4 flex font-semibold items-center justify-center text-[#05080a] text-base bg-[#00e701] rounded"
                                            style={{boxShadow: "0rem .0625rem .1875rem #00000033 , 0rem .0625rem .125rem #0000001f"}}>
                                            {isLoading ? <Loader /> : $t({id: "PlayNow"})}
                                        </button>
                                    </AnimateButton>
                                </>
                            )}
                            <FormHandler />
                        </Form>
                    )}
                </Formik>
            </div>

            <div className="mt-4 text-sm flex items-center justify-center">
                {$t({id: "AlreadyHaveAccount"})}&nbsp;
                <AnimateButton>
                    <span
                        className="text-white font-semibold cursor-pointer"
                        onClick={() => {
                            onChangeLoginType && onChangeLoginType("signin");
                        }}>
                        {$t({id: "SignIn"})}
                    </span>
                </AnimateButton>
            </div>
        </div>
    );
};

export default SignUp;
