import React from "react";

interface Props {
    fill?: string;
}

const SmallLoading = ({fill = "#3BC117"}: Props) => {
    return (
        <div className="small-loading h-full w-full">
            <svg viewBox="0 0 84 24" fill={fill}>
                <circle cx="18" cy="12" r="6"></circle>
                <circle cx="18" cy="12" r="6"></circle>
                <circle cx="42" cy="12" r="6"></circle>
                <circle cx="66" cy="12" r="6"></circle>
            </svg>
        </div>
    );
};

export default SmallLoading;
