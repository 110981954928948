import {FC, ReactNode, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useAppDispatch, useAppSelector} from "store";
import {closeDialog} from "store/slices/dialog";
import {showDialog} from "store/slices/dialog";
import {CurrencySymbol} from "utils/constants";
import {LoginType} from "types/dialog";
import {MoneyType} from "types";
// import {showVipCheck} from "components/dialogs/VipCheck";
import apiPromotion from "api/promotion";
import {useNavigate} from "react-router-dom";
import SvgIcon from "ui-component/SvgIcon";
import useCurrencyList from "hooks/useCurrency";
import {WalletType} from "types/config";
import {setWalletType} from "store/slices/config";

const Box: FC<{children: ReactNode; footer: ReactNode}> = ({children, footer}) => {
    return (
        <div className="px-4 sm:px-6 pt-4 sm:pt-6 relative flex flex-col">
            <div className="overflow-y-auto">{children}</div>
            <div className="py-6 flex items-center justify-center sticky bottom-0 bg-[#192C38]">{footer}</div>
        </div>
    );
};

const EvevtDetail = () => {
    const {$t} = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {currencyList} = useCurrencyList();
    const eventDetailID = useAppSelector((state) => state.dialog.eventDetailID);
    const eventDetaiImgUrl = useAppSelector((state) => state.dialog.eventDetaiImgUrl);
    const stateIsLogin = useAppSelector((state) => state.user.isLogin);
    const moneyType = useAppSelector((state) => state.user.userCurrency);
    const [hasGetData, setHasGetData] = useState(false);
    const [activeData7MinRecharge, setActiveData7MinRecharge] = useState({
        minAmount: 0,
        maxRewardAmount: 0,
        withDrawBetRate: 0,
        maxRewardRate: 0,
        minRewardRate: 0,
    });
    const [activeDataRecharge, setActiveDataRecharge] = useState([]);
    const [activeDataPiggy, setActiveDataPiggy] = useState({effectTime: "", levelList: {}});
    const [activeDataAffiliate, setActiveDataAffiliate] = useState({
        validInviteRewardDepositAmount: "",
        inviteRewardAmount: "",
    });

    const showWalletIfLogin = () => {
        if (!stateIsLogin) {
            dispatch(showDialog(LoginType.SIGNIN));
            return;
        }
        dispatch(showDialog("wallet"));
        dispatch(setWalletType(WalletType.DEPOSIT));
    };

    const getMoneyTypeSymbol = (_moneyType = moneyType) => {
        return CurrencySymbol[_moneyType + ""] || "$";
    };

    const loginBefore = (callback): void => {
        if (!stateIsLogin) {
            dispatch(showDialog(LoginType.SIGNIN));
            return;
        }
        callback && callback();
    };

    const getRechargeAmount = (activeData, level: string): string => {
        return activeData?.levelList[level]?.depositAmount;

        const depositAmount = activeData?.levelList[level]?.depositAmount;
        const depositTokenId = activeData?.levelList[level]?.depositTokenId;
        const tokenId = getMoneyTypeSymbol(depositTokenId);
        return tokenId + depositAmount;
        return depositAmount;
    };

    const getActivationBonus = (activeData, level: string): string => {
        return activeData?.levelList[level]?.stageRewardAmount;

        const stageRewardAmount = activeData?.levelList[level]?.stageRewardAmount;
        const stageRewardTokenid = activeData?.levelList[level]?.stageRewardTokenid;
        const tokenId = getMoneyTypeSymbol(stageRewardTokenid);
        return tokenId + stageRewardAmount;
        return stageRewardAmount;
    };

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    useEffect(() => {
        if (eventDetailID === "EvevtDetailActive7MinRecharge") {
            const payLoad = {
                tokenId: moneyType === MoneyType.IB || moneyType === MoneyType.GB ? MoneyType.USDT : moneyType,
                type: 1,
            };
            apiPromotion.queryChargActivityList(payLoad).then(({status, data}) => {
                if (status === 200 && data.code === 0) {
                    setActiveData7MinRecharge(data?.data?.firstChargeActivityVo || {});
                    setHasGetData(true);
                }
            });
        }
        if (eventDetailID === "EvevtDetailActiveRecharge") {
            const payLoad = {
                tokenId: moneyType === MoneyType.IB || moneyType === MoneyType.GB ? MoneyType.USDT : moneyType,
                type: 0,
            };
            apiPromotion.queryChargActivityList(payLoad).then(({status, data}) => {
                if (status === 200 && data.code === 0) {
                    const chargeActivityVos = data?.data?.chargeActivityVos || [];
                    const arr = chargeActivityVos.slice(0, 4);
                    setActiveDataRecharge(arr);
                    setHasGetData(true);
                }
            });
        }
        if (eventDetailID === "EvevtDetailActivePiggy") {
            const payLoad = {
                tokenId: moneyType === MoneyType.IB || moneyType === MoneyType.GB ? MoneyType.USDT : moneyType,
                type: 2,
            };
            apiPromotion.queryChargActivityList(payLoad).then(({status, data}) => {
                if (status === 200 && data.code === 0) {
                    setActiveDataPiggy(data.data.activityCheckInVo || {});
                    setHasGetData(true);
                }
            });
        }
        if (eventDetailID === "EvevtDetailActiveVip") {
            setHasGetData(true);
        }
        if (eventDetailID === "EvevtDetailActiveAffiliate") {
            const payLoad = {
                tokenId: moneyType === MoneyType.IB || moneyType === MoneyType.GB ? MoneyType.USDT : moneyType,
                type: 3,
            };
            apiPromotion.queryChargActivityList(payLoad).then(({status, data}) => {
                if (status === 200 && data.code === 0) {
                    setActiveDataAffiliate(data.data.affiliateAchivRewardVo || {});
                    setHasGetData(true);
                }
            });
        }
        if (eventDetailID === "EvevtDetailActivePragmaticDropWins") {
            setHasGetData(true);
        }
    }, [eventDetailID, moneyType]);

    const renderContent = () => {
        const getOrderTitle = (type) => {
            let orderTitle = "";
            switch (type) {
                case 1:
                    orderTitle = $t({id: "Deposit1st"});
                    break;
                case 2:
                    orderTitle = $t({id: "Deposit2nd"});
                    break;
                case 3:
                    orderTitle = $t({id: "Deposit3rd"});
                    break;
                case 4:
                    orderTitle = $t({id: "Deposit4"});
                    break;
                default:
                    break;
            }
            return orderTitle;
        };
        if (!hasGetData) return null;
        if (eventDetailID === "EvevtDetailActive7MinRecharge") {
            return (
                <>
                    <img src={eventDetaiImgUrl} alt="" className="w-full block min-h-[6.25rem] rounded" />
                    <Box
                        footer={
                            <div
                                className="flex items-center justify-center h-11 px-12 text-white text-base font-bold cursor-pointer"
                                style={{
                                    borderRadius: "5px",
                                    background: "#007CEF",
                                }}
                                onClick={() => {
                                    dispatch(closeDialog());
                                    loginBefore(showWalletIfLogin);
                                }}>
                                {$t({id: "DepositNow"})}
                            </div>
                        }>
                        <div>
                            <div className="text-base font-bold text-white">{$t({id: "LimitedTimeWelcomeBonus100"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t(
                                        {id: "LimitedTimeWelcomeBonus100Desc"},
                                        {
                                            A: <span className="text-[#FFD205]">100% welcome bonus</span>,
                                        },
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-base font-bold text-white">{$t({id: "DetailsAndRules"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t(
                                        {id: "LimitedTimeWelcomeBonus100Rule1"},
                                        {
                                            A: (
                                                <span className="text-[#FFD205]">
                                                    {/* {getMoneyTypeSymbol()} */}
                                                    {activeData7MinRecharge.minAmount}
                                                    &nbsp;{currencyList.find((v) => v.tokenId === moneyType).tokenName}
                                                </span>
                                            ),
                                        },
                                    )}
                                </div>
                            </div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t(
                                        {id: "LimitedTimeWelcomeBonus100Rule2"},
                                        {
                                            A: (
                                                <span className="text-[#FFD205]">
                                                    {activeData7MinRecharge.maxRewardAmount}
                                                    &nbsp;{currencyList.find((v) => v.tokenId === moneyType).tokenName}
                                                </span>
                                            ),
                                        },
                                    )}
                                </div>
                            </div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">{$t({id: "LimitedTimeWelcomeBonus100Rule3"})}</div>
                            </div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t(
                                        {id: "LimitedTimeWelcomeBonus100Rule4"},
                                        {
                                            A: (
                                                <span className="text-[#FFD205]">
                                                    {activeData7MinRecharge.minAmount +
                                                        activeData7MinRecharge.minAmount * activeData7MinRecharge.minRewardRate ===
                                                    0
                                                        ? 0
                                                        : Math.ceil(
                                                              (activeData7MinRecharge.minAmount +
                                                                  activeData7MinRecharge.minAmount *
                                                                      activeData7MinRecharge.minRewardRate *
                                                                      activeData7MinRecharge.withDrawBetRate) /
                                                                  (activeData7MinRecharge.minAmount +
                                                                      activeData7MinRecharge.minAmount * activeData7MinRecharge.minRewardRate),
                                                          )}
                                                </span>
                                            ),
                                        },
                                    )}
                                </div>
                            </div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t(
                                        {id: "LimitedTimeWelcomeBonus100Rule5"},
                                        {
                                            A: <span className="text-[#FFD205]"> {activeData7MinRecharge.maxRewardRate * 100}% </span>,
                                        },
                                    )}
                                </div>
                            </div>
                        </div>
                    </Box>
                </>
            );
        } else if (eventDetailID === "EvevtDetailActiveRecharge") {
            return (
                <>
                    <img src={eventDetaiImgUrl} alt="" className="w-full block min-h-[6.25rem] rounded" />
                    <Box
                        footer={
                            <div
                                className="flex items-center justify-center h-11 px-12 text-white text-base font-bold cursor-pointer"
                                style={{
                                    borderRadius: "5px",
                                    background: "#007CEF",
                                }}
                                onClick={() => {
                                    dispatch(closeDialog());
                                    loginBefore(showWalletIfLogin);
                                }}>
                                {$t({id: "DepositNow"})}
                            </div>
                        }>
                        <div>
                            <div className="text-base font-bold text-white">{$t({id: "UpTo360Cashback"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t(
                                        {id: "UpTo360CashbackDesc"},
                                        {
                                            A: <br />,
                                        },
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-base font-bold text-white">
                                {$t(
                                    {id: "UpTo360CashbackRule"},
                                    {
                                        A: <span className="text-[#FFD205]">{currencyList.find((v) => v.tokenId === moneyType).tokenName} </span>,
                                    },
                                )}
                            </div>
                            {activeDataRecharge.length > 0 &&
                                activeDataRecharge.map((v, key) => {
                                    return (
                                        <div key={key} className="flex mt-2">
                                            <div
                                                className="bg-[#FFD205] mr-2 flex-shrink-0"
                                                style={{
                                                    borderRadius: "10px",
                                                    marginTop: "4px",
                                                    width: "4px",
                                                    height: "12px",
                                                }}></div>
                                            <div className="text-base text-[#98A7B5] font-normal">
                                                {getOrderTitle(v.type)}
                                                <div>
                                                    {$t(
                                                        {id: "DepositMorethan"},
                                                        {
                                                            A: (
                                                                <span className="text-[#FFD205]">
                                                                    {/* {getMoneyTypeSymbol()} */}
                                                                    {v.minAmount}
                                                                    &nbsp;{currencyList.find((v) => v.tokenId === moneyType).tokenName}
                                                                </span>
                                                            ),
                                                        },
                                                    )}
                                                </div>
                                                {$t(
                                                    {id: "ReceiveBonusAB"},
                                                    {
                                                        A: <span className="text-[#FFD205]"> {v.minRewardRate * 100}% </span>,
                                                        B: <span className="text-[#FFD205]"> {v.maxRewardRate * 100}% </span>,
                                                    },
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </Box>
                </>
            );
        } else if (eventDetailID === "EvevtDetailActivePiggy") {
            return (
                <>
                    <img src={eventDetaiImgUrl} alt="" className="w-full block min-h-[6.25rem] rounded" />
                    <Box
                        footer={
                            <div
                                className="flex items-center justify-center h-11 px-12 text-white text-base font-bold cursor-pointer"
                                style={{
                                    borderRadius: "5px",
                                    background: "#007CEF",
                                }}
                                onClick={() => {
                                    dispatch(closeDialog());
                                    // loginBefore(showVipCheck);
                                }}>
                                {$t({id: "Activate"})}
                            </div>
                        }>
                        <div>
                            <div className="text-base font-bold text-white">{$t({id: "RakebackBonus"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t({id: "RakebackBonusDesc1"})}
                                    <div>
                                        {$t(
                                            {id: "RakebackBonusDesc2"},
                                            {
                                                A: (
                                                    <span className="text-[#FFD205]">
                                                        {getRechargeAmount(activeDataPiggy, "1")}
                                                        &nbsp;{currencyList.find((v) => v.tokenId === moneyType).tokenName}
                                                    </span>
                                                ),
                                            },
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-base font-bold text-white">{$t({id: "RakebackBonusRule"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t({id: "RakebackBonusRule1"})}
                                    <div>
                                        {$t(
                                            {id: "RakebackBonusRule11"},
                                            {
                                                A: <span className="text-[#FFD205]"> {activeDataPiggy.effectTime} </span>,
                                            },
                                        )}
                                    </div>
                                    <div>
                                        {$t(
                                            {id: "RakebackBonusRule12"},
                                            {
                                                A: <span className="text-[#FFD205]">*Level 1: </span>,
                                                B: (
                                                    <span className="text-[#FFD205]">
                                                        {getRechargeAmount(activeDataPiggy, "1")}
                                                        &nbsp;{currencyList.find((v) => v.tokenId === moneyType).tokenName}
                                                    </span>
                                                ),
                                            },
                                        )}
                                    </div>
                                    <div>
                                        {$t(
                                            {id: "RakebackBonusRule12"},
                                            {
                                                A: <span className="text-[#FFD205]">*Level 2: </span>,
                                                B: (
                                                    <span className="text-[#FFD205]">
                                                        {getRechargeAmount(activeDataPiggy, "2")}
                                                        &nbsp;{currencyList.find((v) => v.tokenId === moneyType).tokenName}
                                                    </span>
                                                ),
                                            },
                                        )}
                                    </div>
                                    <div>
                                        {$t(
                                            {id: "RakebackBonusRule12"},
                                            {
                                                A: <span className="text-[#FFD205]">*Level 3: </span>,
                                                B: (
                                                    <span className="text-[#FFD205]">
                                                        {getRechargeAmount(activeDataPiggy, "3")}
                                                        &nbsp;{currencyList.find((v) => v.tokenId === moneyType).tokenName}
                                                    </span>
                                                ),
                                            },
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t({id: "RakebackBonusRule2"})}
                                    <div>{$t({id: "RakebackBonusRule21"})}</div>
                                    <div>
                                        {$t(
                                            {id: "RakebackBonusRule22"},
                                            {
                                                A: "*Level 1",
                                                B: (
                                                    <span className="text-[#FFD205]">
                                                        {getActivationBonus(activeDataPiggy, "1")}
                                                        &nbsp;{currencyList.find((v) => v.tokenId === moneyType).tokenName}
                                                    </span>
                                                ),
                                            },
                                        )}
                                    </div>
                                    <div>
                                        {$t(
                                            {id: "RakebackBonusRule22"},
                                            {
                                                A: "*Level 2",
                                                B: (
                                                    <span className="text-[#FFD205]">
                                                        {getActivationBonus(activeDataPiggy, "2")}
                                                        &nbsp;{currencyList.find((v) => v.tokenId === moneyType).tokenName}
                                                    </span>
                                                ),
                                            },
                                        )}
                                    </div>
                                    <div>
                                        {$t(
                                            {id: "RakebackBonusRule22"},
                                            {
                                                A: "*Level 3",
                                                B: (
                                                    <span className="text-[#FFD205]">
                                                        {getActivationBonus(activeDataPiggy, "3")}
                                                        &nbsp;{currencyList.find((v) => v.tokenId === moneyType).tokenName}
                                                    </span>
                                                ),
                                            },
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t(
                                        {id: "RakebackBonusRule3"},
                                        {
                                            A: <span className="text-[#FFD205]"> 300% </span>,
                                        },
                                    )}
                                </div>
                            </div>
                        </div>
                    </Box>
                </>
            );
        } else if (eventDetailID === "EvevtDetailActiveVip") {
            return (
                <>
                    <img src={eventDetaiImgUrl} alt="" className="w-full block min-h-[6.25rem] rounded" />
                    <Box
                        footer={
                            <div
                                className="flex items-center justify-center h-11 px-12 text-white text-base font-bold cursor-pointer"
                                style={{
                                    borderRadius: "5px",
                                    background: "#007CEF",
                                }}
                                onClick={() => {
                                    dispatch(closeDialog());
                                    navigate("/vip-club");
                                }}>
                                {$t({id: "ViewVIPPage"})}
                            </div>
                        }>
                        <div>
                            <div className="text-base font-bold text-white">{$t({id: "VIPLoyaltyProgramRakeback"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">{$t({id: "VIPLoyaltyProgramRakebackDesc"})}</div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-base font-bold text-white">{$t({id: "DetailsBonuses"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t(
                                        {id: "DetailsBonusesRule1"},
                                        {
                                            A: <span className="text-[#FFD205]">{$t({id: "AllWagersConvertedUSD"})}</span>,
                                        },
                                    )}
                                </div>
                            </div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t({id: "DetailsBonusesRule2"})}
                                    <div className="mt-1">
                                        {$t(
                                            {id: "DetailsBonusesRule21"},
                                            {
                                                A: <span className="text-[#FFD205]">$15,000</span>,
                                                B: <span className="text-[#FFD205]">(1 PLD=1 USD)</span>,
                                            },
                                        )}
                                    </div>
                                    <div className="mt-1">
                                        {$t(
                                            {id: "DetailsBonusesRule22"},
                                            {
                                                A: <span className="text-[#FFD205]">1%</span>,
                                            },
                                        )}
                                    </div>
                                    <div className="mt-1">{$t({id: "DetailsBonusesRule23"})}</div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </>
            );
        } else if (eventDetailID === "EvevtDetailActiveAffiliate") {
            return (
                <>
                    <img src={eventDetaiImgUrl} alt="" className="w-full block min-h-[6.25rem] rounded" />
                    <Box
                        footer={
                            <div
                                className="flex items-center justify-center h-11 px-12 text-white text-base font-bold cursor-pointer"
                                style={{
                                    borderRadius: "5px",
                                    background: "#007CEF",
                                }}
                                onClick={() => {
                                    dispatch(closeDialog());
                                    navigate("/affiliate");
                                }}>
                                {$t({id: "Affiliate"})}
                            </div>
                        }>
                        <div>
                            <div className="text-base font-bold text-white">{$t({id: "ReferAndEarn"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">{$t({id: "ReferAndEarnDesc"})}</div>
                            </div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t(
                                        {id: "ReferAndEarnDesc1"},
                                        {
                                            A: <span className="text-[#FFD205]">{$t({id: "DespiteMakeCommission"})}</span>,
                                        },
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-base font-bold text-white">{$t({id: "UnlimitedInvitationBonus"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t(
                                        {id: "UnlimitedInvitationBonus1"},
                                        {
                                            A: (
                                                <span className="text-[#FFD205]">
                                                    &nbsp;
                                                    {/* {getMoneyTypeSymbol()} */}
                                                    {activeDataAffiliate.validInviteRewardDepositAmount}
                                                    &nbsp;{currencyList.find((v) => v.tokenId === moneyType).tokenName}
                                                </span>
                                            ),
                                            B: (
                                                <span className="text-[#FFD205]">
                                                    {/* {getMoneyTypeSymbol()} */}
                                                    {activeDataAffiliate.inviteRewardAmount}
                                                    &nbsp;{currencyList.find((v) => v.tokenId === moneyType).tokenName}
                                                </span>
                                            ),
                                        },
                                    )}
                                </div>
                            </div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">{$t({id: "UnlimitedInvitationBonus2"})}</div>
                            </div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">{$t({id: "UnlimitedInvitationBonus3"})}</div>
                            </div>
                        </div>
                        <div>
                            <div className="text-base font-bold text-white">{$t({id: "HowToInvite"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    {$t(
                                        {id: "HowToInvite1"},
                                        {
                                            A: <span className="text-[#FFD205]">{$t({id: "Share"})}</span>,
                                            B: <span className="text-[#FFD205]">{$t({id: "Affiliate"})}</span>,
                                        },
                                    )}
                                </div>
                            </div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">{$t({id: "HowToInvite2"})}</div>
                            </div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">{$t({id: "HowToInvite3"})}</div>
                            </div>
                        </div>
                    </Box>
                </>
            );
        } else if (eventDetailID === "EvevtDetailActivePragmaticDropWins") {
            const gameList = [
                {
                    img: "https://static.playing.io/game/cover/openbox/pragmatic-play/bf697db55a5643f2a3b9683468c642ad.png",
                    url: "/game-detail/gates-of-olympus-pragmatic-play-35762",
                },
                {
                    img: "https://static.playing.io/game/cover/openbox/pragmatic-play/26a105e80e9e4d0cb41efdb0b24cdc08.png",
                    url: "/game-detail/sweet-bonanza-pragmatic-play-35878",
                },
                {
                    img: "https://cdn.softswiss.net/i/s3/pragmaticexternal/SugarRush1.png",
                    url: "/game-detail/sugar-rush-pragmatic-play-23527",
                },
                {
                    img: "https://static.playing.io/game/cover/openbox/pragmatic-play/ee5c10394e554d588d5c06eb790a2d3a.png",
                    url: "/game-detail/the-dog-house-megaways-pragmatic-play-35886",
                },
                {
                    img: "https://static.playing.io/game/cover/openbox/pragmatic-play/2d42ad91012f4714a1e20033701e3a04.png",
                    url: "/game-detail/starlight-princess-pragmatic-play-35871",
                },
                {
                    img: "https://static.playing.io/game/cover/openbox/pragmatic-play/d28425d0a1f94ab19a9d1674f77a39d1.png",
                    url: "/game-detail/big-bass-bonanza-pragmatic-play-35689",
                },
                {
                    img: "https://static.playing.io/game/cover/openbox/pragmatic-play/cdbaa4213e8e41889c5940a23f082beb.png",
                    url: "/game-detail/gems-bonanza-pragmatic-play-35764",
                },
                {
                    img: "https://static.playing.io/game/cover/openbox/pragmatic-play/322fcbf82d464b27b03b1b20e9139584.png",
                    url: "/game-detail/wild-wild-riches-pragmatic-play-35918",
                },
                {
                    img: "https://static.playing.io/game/cover/openbox/pragmatic-play/cbad87f029914d1881b7fe7050ad6afc.png",
                    url: "/game-detail/juicy-fruits-pragmatic-play-35798",
                },
                {
                    img: "https://static.playing.io/game/cover/openbox/pragmatic-play/a83d7f2eb6bf4eaa8c81af21bfb6ad70.png",
                    url: "/game-detail/fruit-party-pragmatic-play-35758",
                },
            ];
            return (
                <>
                    <img src={eventDetaiImgUrl} alt="" className="w-full block min-h-[6.25rem] rounded" />
                    <Box
                        footer={
                            <div
                                className="flex items-center justify-center h-11 px-12 text-white text-base font-bold cursor-pointer"
                                style={{
                                    borderRadius: "5px",
                                    background: "#007CEF",
                                }}
                                onClick={() => {
                                    dispatch(closeDialog());
                                    navigate("/casino?tab=all&keyword=Pragmatic Play");
                                }}>
                                {$t({id: "DropsWinsGames"})}
                            </div>
                        }>
                        <div>
                            <div className="text-base font-bold text-white">{$t({id: "SeptemberShowdown"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">{$t({id: "SeptemberShowdownDesc"})}</div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-base font-bold text-white">{$t({id: "GeneralRules"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    <div>
                                        {$t(
                                            {id: "GeneralRules1"},
                                            {
                                                A: <span className="text-[#FFD205]">{$t({id: "PromotionDropsDownStartTime"})}</span>,
                                                B: <span className="text-[#FFD205]">{$t({id: "PromotionDropsDownEndTime"})}</span>,
                                            },
                                        )}
                                    </div>

                                    <div>{$t({id: "GeneralRules2"})}</div>
                                    <div>{$t({id: "GeneralRules3"})}</div>
                                    <div>
                                        {$t(
                                            {id: "GeneralRules4"},
                                            {
                                                A: <span className="text-[#FFD205]">€0.50</span>,
                                            },
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-base font-bold text-white">{$t({id: "RankingPrizeDistribution"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    <div>{$t({id: "RankingPrizeDistribution1"})}</div>
                                    <div>{$t({id: "RankingPrizeDistribution2"})}</div>
                                    <div>
                                        {$t(
                                            {id: "RankingPrizeDistribution3"},
                                            {
                                                A: <span className="text-[#FFD205]">€255,000</span>,
                                            },
                                        )}
                                    </div>
                                    <div>
                                        {$t(
                                            {id: "RankingPrizeDistribution4"},
                                            {
                                                A: <span className="text-[#FFD205]">€25,000</span>,
                                            },
                                        )}
                                    </div>
                                    <div>
                                        {$t(
                                            {id: "RankingPrizeDistribution5"},
                                            {
                                                A: <span className="text-[#FFD205]">€10,000</span>,
                                            },
                                        )}
                                    </div>
                                    <div>
                                        {$t(
                                            {id: "RankingPrizeDistribution6"},
                                            {
                                                A: <span className="text-[#FFD205]">€5,000</span>,
                                            },
                                        )}
                                    </div>
                                    <div>
                                        {$t(
                                            {id: "RankingPrizeDistribution7"},
                                            {
                                                A: <span className="text-[#FFD205]">€2,000</span>,
                                            },
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-base font-bold text-white">{$t({id: "TermsConditions"})}</div>
                            <div className="flex mt-2">
                                <div
                                    className="bg-[#FFD205] mr-2 flex-shrink-0"
                                    style={{
                                        borderRadius: "10px",
                                        marginTop: "4px",
                                        width: "4px",
                                        height: "12px",
                                    }}></div>
                                <div className="text-base text-[#98A7B5] font-normal">
                                    <div>{$t({id: "TermsConditions1"})}</div>
                                    <div>{$t({id: "TermsConditions2"})}</div>
                                    <div>{$t({id: "TermsConditions3"})}</div>
                                    <div>{$t({id: "TermsConditions4"})}</div>
                                    <div>{$t({id: "TermsConditions5"})}</div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="w-full rounded-[5px] bg-[#3A3748] p-3 sm:p-4">
                                <div className="text-base sm:text-xl font-bold text-white flex items-center justify-center italic">
                                    <SvgIcon dataKey="icon_StarFull" style={{fill: "#FFD205"}} className="!w-4 !h-4 mr-3 sm:!w-5 sm:!h-5 sm:mr-4" />
                                    {$t({id: "ParticipatingGamesToWin"})}
                                    <SvgIcon dataKey="icon_StarFull" style={{fill: "#FFD205"}} className="!w-4 !h-4 ml-3 sm:!w-5 sm:!h-5 sm:ml-4" />
                                </div>
                                <div className="w-full mt-3 sm:mt-4 grid grid-cols-5 grid-rows-2 gap-2 sm:gap-3">
                                    {gameList.map((v, key) => {
                                        return (
                                            <div
                                                key={key}
                                                className="w-full cursor-pointer"
                                                onClick={() => {
                                                    dispatch(closeDialog());
                                                    navigate(v.url);
                                                }}>
                                                <img src={v.img} className="w-full object-contain" style={{borderRadius: 5}} />
                                                {/* <img src={v.img} alt="" className="w-full  rounded-[5px] object-contain" /> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </Box>
                </>
            );
        } else {
            return null;
        }
    };

    return (
        <div className="!px-6 sm:h-[650px] bg-[#192C38] rounded-lg overflow-hidden flex flex-col">
            <div className="flex justify-between items-center sticky top-0 py-6 bg-[#192C38]">
                <div className="text-lg font-bold text-white">{$t({id: "Event Detail"})}</div>
                <SvgIcon
                    dataKey="icon-close"
                    className="w-4 h-4 fill-white cursor-pointer"
                    onClick={() => {
                        dispatch(closeDialog());
                    }}
                />
            </div>
            <div className="flex-1 scrollY">{renderContent()}</div>
        </div>
    );
};

export default EvevtDetail;
