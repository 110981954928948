import I18NLabel from "./I18NLabel";
import I18NSprite from "./I18NSprite";

export enum Languages{
    English = 0, 
    Portuguese,
    Spanish,
    japan,
}

export default class I18NMgr {
    private static instance:I18NMgr = null;
    private labels:I18NLabel[] = [];
    private sprites:I18NSprite[] = [];
    private langs:string = "en";

    private i18nStringTables:any = {};
    private curStringTable:any = null;

    public static get Instance(){
        if(!I18NMgr.instance){
            I18NMgr.instance = new I18NMgr;
        }

        return I18NMgr.instance;
    }

    public set StringTables(v:any){
        this.i18nStringTables = v;
    }

    public get StringTables(){
        return this.i18nStringTables;
    }

    public get Langs(){
        return this.langs;
    }

    public getText(id:string, args:string[] = []):string{
        if(!this.i18nStringTables) return id;
        if(!this.curStringTable){
            this.curStringTable = this.i18nStringTables[this.langs];
        }

        if(!this.curStringTable) return id;

        let str = this.curStringTable[id];

        if(!!args.length){
            for (let i = 0; i < args.length; i++) {
                let reg = new RegExp("\\{" + i + "\\}", "gm");
                str = str.replace(reg, args[i]);
            }
        }

        return str;
    }

    add(element:I18NLabel|I18NSprite){
        if(element instanceof I18NLabel){
            this.labels.push(element);
        }
        else if(element instanceof I18NSprite){
            this.sprites.push(element);
        }
    }

    remove(element:I18NLabel|I18NSprite){
        if(element instanceof I18NLabel){
            let index = this.labels.indexOf(element);
            if(-1 != index){
                this.labels.splice(index, 1);
            }
        }
        else if(element instanceof I18NSprite){
            let index = this.sprites.indexOf(element);
            if(-1 != index){
                this.sprites.splice(index, 1);
            }
        }
    }

    switch(lang:string){
        this.langs = lang;
        this.curStringTable = this.i18nStringTables[this.langs];

        this.labels.forEach(e => {e.refresh();});
        // this.sprites.forEach(e => {e.refresh(lang);});
    }
}
