import {MoneyType} from "types/index";
import client from "./client";

const getWalletAddress = (type: MoneyType, protocol: string) => client.post("/getWalletAddress", {type, protocol});

const withDrawByCrypto = (type: number, protocol: string, amount: number, dstwalletaddr: string) =>
    client.post("/withDraw", {type, amount, dstwalletaddr, protocol});

const getSwapRate = (type: number, dstType: number) => client.post("/getSwapRate", {type, dstType});

const apiWallet = {
    getWalletAddress,
    withDrawByCrypto,
    getSwapRate,
};

export default apiWallet;
