import client from "./client";

const queryChargeLog = (data: any) =>
    client.post("/queryChargeLog", {
        type: 0,
        pageId: data.pageId,
        itemCount: 10,
        beginTime: 0,
        endTime: 0,
    });
const queryDrawLog = (data: any) =>
    client.post("/queryDrawLog", {
        type: 0,
        pageId: data.pageId,
        itemCount: 10,
        beginTime: 0,
        endTime: 0,
    });
const queryUserBetHistoryList = (data: any) =>
    client.post("/queryUserBetHistoryList", {
        type: 0,
        pageId: data.pageId,
        pageCount: 10,
    });

const transactionsApi = {
    queryChargeLog,
    queryDrawLog,
    queryUserBetHistoryList,
};
export default transactionsApi;
